import styled from 'styled-components';

import { Card } from '@hedgehog/ui/cards';
import { Paragraph } from '@hedgehog/ui/typography';

const ErrorMessage = styled(Card)`
  ${({ theme }) => ({
    radius: theme.radius.normal,
    paddingTop: theme.spacing.small,
    paddingBottom: theme.spacing.small,
    backgroundColor: theme.colors.errorLight,
  })}
`;

export interface ModalErrorNoteProps {
  error?: string;
}

export const ModalErrorNote = ({ error }: ModalErrorNoteProps): JSX.Element => (
  <ErrorMessage>
    <Paragraph small color="grey500">
      {error || 'Something went wrong, try again later.'}
    </Paragraph>
  </ErrorMessage>
);
