import { useRef } from 'react';
import styled from 'styled-components';

import { ContentQuestionBlock } from '@hedgehog/shared/types';
import { useAutoscroll } from '@hedgehog/ui/autoscroll';
import { Card } from '@hedgehog/ui/cards';

import { useContentParameters } from '../../ContentContext';

import { QuestionAnswer } from './question-answer.component';
import { QuestionComment } from './question-comment.component';
import { QuestionContent } from './question-content.component';
import { AnswerOptions, QuestionHeading } from './styles';

const Block = styled(Card)``;

const ContainMany = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  & > ${AnswerOptions}, & > ${QuestionHeading} {
    padding: 1.5rem 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

type CMSQuestionBlockProps = {
  block: ContentQuestionBlock;
  comments?: boolean;
};

export const CMSQuestionBlock = ({
  block,
  comments,
}: CMSQuestionBlockProps): JSX.Element => {
  const { onQuestionCommentClick: onAddCommentClick, comments: quizComments } =
    useContentParameters();
  const blockRef = useRef<HTMLDivElement>(null);
  const [scrollToNext] = useAutoscroll(blockRef);

  const areCommentsActive = quizComments && comments;

  const { question } = block;
  const { questions, description, markdown } = question;

  const handleAddCommentClick = (): void => {
    if (onAddCommentClick) onAddCommentClick(question);
  };

  const contentProps = question.markdown
    ? { type: 'markdown' as const, markdown }
    : { type: 'standard' as const, questions, description };

  return (
    <Block ref={blockRef}>
      <ContainMany>
        <QuestionContent {...contentProps} />
      </ContainMany>
      <ContainMany>
        {question.answers.map((answer) => (
          <QuestionAnswer
            key={`${question.id}_${answer.id}`}
            questionId={question.id}
            answer={answer}
            onClick={scrollToNext}
          />
        ))}
      </ContainMany>
      {areCommentsActive && (
        <QuestionComment
          content={quizComments[question.id] || ''}
          onClick={handleAddCommentClick}
        />
      )}
    </Block>
  );
};
