import {
  SizeVariant,
  ThemeBorder,
  ThemePadding,
  ThemeShadow,
  Unit,
} from './theme.interface';

export function convertHexToRgb(
  hex: string,
): [number, number, number] | [number, number, number, number] {
  const channels = hex
    .replace(/^#/, '')
    .match(/^([a-f0-9]{1,2})([a-f0-9]{1,2})([a-f0-9]{1,2})$/i);

  if (!channels) throw new Error('Invalid HEX color');

  const [_, red, green, blue, alpha] = channels.map((channel) =>
    parseInt(channel.length > 1 ? channel : `${channel}${channel}`, 16),
  );

  return alpha ? [red, green, blue, alpha] : [red, green, blue];
}

/**
 * @description
 *  This function calculates the perceived brightness of a color, in the range 0 - 255,
 * it might be useful to decide which text color to display on a specific color.
 * - https://www.nbdtech.com/Blog/archive/2008/04/27/Calculating-the-Perceived-Brightness-of-a-Color.aspx
 * - https://www.w3.org/TR/AERT/#color-contrast
 * @param red
 * @param green
 * @param blue
 * @returns
 */
export function brightness(red: number, green: number, blue: number): number {
  return Math.sqrt(
    red * red * 0.241 + green * green * 0.691 + blue * blue * 0.068,
  );
}

export function isLight(hex: string): boolean {
  const [red, green, blue] = convertHexToRgb(hex);
  return brightness(red, green, blue) > 130;
}

export function isDark(hex: string): boolean {
  const [red, green, blue] = convertHexToRgb(hex);
  return brightness(red, green, blue) < 130;
}

export function convertToNumber(value: unknown, fallbackTo = 0): number {
  return !isNaN(Number(value)) ? Number(value) : fallbackTo;
}

export function convertPixelsToRem(value: unknown, base = 16): Unit {
  const [pixels] = `${value}`.split('px');
  return `${convertToNumber(pixels, base) / base}rem`;
}

export function convertRemToPixels(value: unknown, base = 16): Unit {
  const [rem] = `${value}`.split('rem');
  return `${convertToNumber(rem, 1) * base}px`;
}

export function ratioToHex(value: unknown): string {
  const hex = Math.round(255 * convertToNumber(value, 0)).toString(16);
  return hex.length > 1 ? hex : `0${hex}`;
}

export function parseShadow(...shadows: ThemeShadow[]): string | undefined {
  if (!shadows) return undefined;
  return shadows
    .flatMap((shadows) => shadows)
    .map(
      (shadow) =>
        `${shadow.x} ${shadow.y} ${shadow.blur} ${shadow.spread} ${shadow.color}`,
    )
    .join(', ');
}

export function convertShortcutToSize(shorthand: string): SizeVariant {
  const dictionary: Record<string, SizeVariant> = {
    xxxs: 'xxxsmall',
    xxs: 'xxsmall',
    xs: 'xsmall',
    md: 'normal',
    lg: 'large',
    xlg: 'xlarge',
    xxlg: 'xxlarge',
    xxxlg: 'xxxlarge',
  };
  return dictionary[shorthand] || 'normal';
}

export function parsePadding(padding: ThemePadding): string {
  const { x, y } = padding;
  if (!x && !y) return '';
  if (!x && y) return y;
  if (x && !y) return x;
  return `${y} ${x}`;
}

export function parseBorder(
  border: ThemeBorder,
  extend: Partial<ThemeBorder> = {},
): string {
  const { width, style, color } = { ...border, ...extend };
  return `${width} ${style} ${color}`;
}

export const parseUnitToNumber = (value: Unit): number => {
  const [_, digits, unit] = value.match(/(\d+(?:[.]\d+))(\w+)?/) || [];
  return unit === 'rem'
    ? convertToNumber(digits, 0) * 16
    : convertToNumber(digits, 0);
};

export function parseBorderToThemeShadow(border: ThemeBorder): ThemeShadow {
  const { width, color } = { ...border };
  return {
    x: '0',
    y: '0',
    blur: '0',
    spread: width,
    color: color,
  };
}

export function parseBorderToShadow(
  border: ThemeBorder,
  extend: Partial<ThemeBorder> & { outset?: boolean } = {},
): string {
  const { outset = false } = extend;
  const shadow = parseBorderToThemeShadow({ ...border, ...extend });
  return `${outset ? '' : 'inset'} ${parseShadow(shadow)}`;
}
