import { useClientInvestmentMetricsQuery } from '@hedgehog/data-access/partners';
import { UUID } from '@hedgehog/shared/types';
import { BlockContainer } from '@hedgehog/ui/layouts';
import { List } from '@hedgehog/ui/lists';
import { Loader } from '@hedgehog/ui/loaders';
import { currencies } from '@hedgehog/utils/formats';

import { ClientDataListItem } from '../../components';

type ClientProfileInvestmentMetricsBlockProps = {
  partnerId: UUID;
  clientId: UUID;
};

export const ClientProfileInvestmentMetricsBlock = ({
  partnerId,
  clientId,
}: ClientProfileInvestmentMetricsBlockProps) => {
  const { data: metrics } = useClientInvestmentMetricsQuery({
    partnerId,
    clientId,
  });

  if (!metrics) {
    return <Loader />;
  }

  return (
    <BlockContainer>
      <List>
        <ClientDataListItem
          label="Total investments"
          value={metrics.numberOfOrders}
          icon="book"
        />
        <ClientDataListItem
          label="Number of tokens"
          value={metrics.numberOfTokens}
          icon="token"
        />
        <ClientDataListItem
          label="Value of investments held"
          value={currencies.formatMoney(metrics.totalInvestmentAmount.amount)}
          icon="money"
        />
        <ClientDataListItem
          label="Yield payments earned to date"
          value="$0"
          icon="bill"
        />
      </List>
    </BlockContainer>
  );
};
