import styled from 'styled-components';

import { FormDivider, FormDividerProps } from './form-divider.component';

type FormSpacingTokenProps = FormDividerProps;

export const FormSpacingToken = styled(FormDivider)<FormSpacingTokenProps>`
  border: none;
  margin-top: 0;
`;
