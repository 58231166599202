import { QueryResult } from '@hedgehog/browser/shared/utils';

import {
  CapitalCall,
  useCapitalCallsBlockchainQuery,
} from './use-capital-calls-query.hook';

export const useCapitalCallBlockchainQuery = (
  capitalCallId: number,
): QueryResult<CapitalCall | null> & {
  refresh: () => Promise<QueryResult<CapitalCall>>;
} => {
  const { data, error, loading, refresh } = useCapitalCallsBlockchainQuery();
  const handleRefresh = () =>
    refresh().then(() => ({
      data:
        data?.find(
          (capitalCall: CapitalCall) => capitalCall.id === capitalCallId,
        ) ?? null,
      error,
      loading,
    }));

  if (isNaN(capitalCallId)) {
    return {
      data: null,
      loading,
      error,
      refresh: handleRefresh,
    };
  }

  return {
    data:
      data?.find(
        (capitalCall: CapitalCall) => capitalCall.id === capitalCallId,
      ) ?? null,
    error,
    loading,
    refresh: handleRefresh,
  };
};
