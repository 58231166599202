import { mergeDeep } from '@apollo/client/utilities';
import { useState } from 'react';
import styled from 'styled-components';

import { CapitalCallNoticeIssuedDataSchema } from '@hedgehog/shared/blockchain-types';
import { CheckboxInput } from '@hedgehog/ui/inputs';

import { NoticeForm, NoticeFormData } from '../../components';

const NoticeWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

const NoticeActions = styled.div`
  margin-top: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export interface CapitalCallNoticeContentProps {
  defaultValues?: Partial<CapitalCallNoticeIssuedDataSchema>;
  onDataChange?: (data: CapitalCallNoticeIssuedDataSchema) => void;
}

export const CapitalCallNoticeContent = ({
  defaultValues = {},
  onDataChange,
}: CapitalCallNoticeContentProps): JSX.Element => {
  const [data, setData] = useState<CapitalCallNoticeIssuedDataSchema>(
    mergeDeep(
      {
        email: { from: '', send: true },
        notice: { subject: '', content: '' },
      },
      defaultValues,
    ),
  );

  const handleFormChange = (formData: NoticeFormData) => {
    setData({
      email: {
        send: false,
        from: formData.from,
      },
      notice: {
        subject: formData.subject,
        content: formData.message,
      },
    });

    if (onDataChange) onDataChange(data);
  };

  const handleCheckboxChange = (value: boolean) => {
    setData({
      ...data,
      email: {
        ...data.email,
        send: value,
      },
    });

    if (onDataChange) onDataChange(data);
  };

  return (
    <NoticeWrapper>
      <NoticeForm
        onChange={handleFormChange}
        defaultValues={{
          from: data.email.from,
          subject: data.notice.subject,
          message: data.notice.content,
        }}
      />
      <NoticeActions>
        <CheckboxInput
          name="send"
          disabled
          value={data.email.send}
          label="Check If you'd like to send this notice together with a capital call"
          onChange={handleCheckboxChange}
        />
      </NoticeActions>
    </NoticeWrapper>
  );
};
