import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { WriteContractResult } from '@wagmi/core';
import { useEffect } from 'react';
import { Address } from 'viem';
import { useAccount, useContractWrite, usePrepareContractWrite } from 'wagmi';

import { useCacheEntry } from '@hedgehog/browser/shared/utils';

export interface UseFundWriteProps {
  address?: Address;
  functionName: string;
  args: Array<bigint | number | string | Address>;
  scopeKey: string;
  enabled?: boolean;
}

export type UseFundWriteReturn = [
  (() => Promise<WriteContractResult>) | undefined,
  {
    prepareContractWrite: {
      isLoading: boolean;
      isError: boolean;
      error: Error | null;
    };
    contractWrite: {
      data: WriteContractResult | undefined;
      isLoading: boolean;
      isError: boolean;
      error: Error | null;
    };
  },
];

export const useFundWrite = ({
  address,
  functionName,
  args,
  scopeKey,
  enabled = true,
}: UseFundWriteProps): UseFundWriteReturn => {
  const { address: clientAddress } = useAccount();
  const [entry, { clear }] = useCacheEntry(scopeKey);
  const {
    refetch,
    config,
    isLoading: isPrepareLoading,
    isError: isPrepareError,
    error: prepareError,
  } = usePrepareContractWrite({
    abi: IFund.abi,
    address,
    functionName,
    args,
    enabled,
    scopeKey,
  });

  const {
    writeAsync: write,
    isLoading: isWriteLoading,
    isError: isWriteError,
    error: writeError,
    data,
  } = useContractWrite(config);

  const handleWrite = write
    ? async (...args: Parameters<typeof write>): ReturnType<typeof write> => {
        clear();
        return write(...args);
      }
    : undefined;

  useEffect(() => {
    console.log(`Client address changed to ${clientAddress}, refetching...`);
    // Need to run simulate every time the connected wallet changes because the result
    // might be different.
    refetch();
  }, [address, clientAddress]);

  return [
    handleWrite,
    {
      prepareContractWrite: {
        isError: isPrepareError,
        isLoading: isPrepareLoading,
        error: prepareError,
      },
      contractWrite: {
        data,
        isError: isWriteError,
        isLoading: isWriteLoading,
        error: writeError,
      },
    },
  ];
};
