import { FormEvent, ReactNode, useState } from 'react';
import styled from 'styled-components';

import {
  SignupTermsCheckbox,
  usePassword,
} from '@hedgehog/browser/shared/auth';
import {
  AcceptPartnerTeamInvitationRequest,
  useCountriesQuery,
} from '@hedgehog/data-access/partners';
import { useAppData } from '@hedgehog/data-access/partners';
import { TCountry, TCountryAlpha3Code } from '@hedgehog/shared/types';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { CountryPickerModal } from '@hedgehog/ui/countries';
import {
  PasswordInput,
  TextInput,
  MenuButtonInput,
  RadioInputGroup,
  RadioInput,
} from '@hedgehog/ui/inputs';
import { Heading } from '@hedgehog/ui/typography';
import { getAllStateNames, getStateCode } from '@hedgehog/utils/states';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const FormSubmitButton = styled(PrimaryButton)`
  align-self: center;
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SignupPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  gap: 1rem;
`;

const SignupStatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 300px;
  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
`;

type PartnerAcceptTeamInvitationFormProps = {
  values: AcceptPartnerTeamInvitationRequest;
  errorMessage?: string;
  loading?: boolean;
  onChange: (values: AcceptPartnerTeamInvitationRequest) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
};

export const PartnerAcceptTeamInvitationForm = ({
  values,
  errorMessage,
  loading = false,
  onChange,
  onSubmit,
}: PartnerAcceptTeamInvitationFormProps) => {
  const { data: countries } = useCountriesQuery();
  const { featureFlags } = useAppData();
  const [accepted, setAccepted] = useState(false);
  const {
    valid: isPasswordValid,
    errors: passwordErrors,
    dirty: isPasswordDirty,
    enableValidation,
  } = usePassword({
    password: values.password,
  });

  const isFormValid =
    accepted && values.firstName && values.lastName && isPasswordValid;

  const allowedCountries = JSON.parse(
    featureFlags.length > 0
      ? featureFlags.filter(
          (flag) => flag.name === 'signup_region_allowlist',
        )[0].value || '[]'
      : '[]',
  );
  const supportedCountries = allowedCountries as TCountryAlpha3Code[];

  const handleChange = (key: keyof AcceptPartnerTeamInvitationRequest) => {
    return (newValue: AcceptPartnerTeamInvitationRequest[typeof key]) => {
      onChange({
        ...values,
        [key]: newValue,
      });
    };
  };

  const handleCountryChange = (value?: TCountry): void => {
    onChange({
      ...values,
      ['country']: value ? value.code : 'GBR',
    });
  };

  const handleStateChange = (value?: string): void => {
    onChange({
      ...values,
      ['state']: value || 'Alabama',
    });
  };

  return (
    <Form onSubmit={onSubmit}>
      <Heading level="h4">Sign up to Hedgehog</Heading>
      <FormSection>
        <TextInput
          name="firstName"
          value={values.firstName}
          placeholder="First name"
          label="First name"
          onChange={handleChange('firstName')}
        />
        <TextInput
          name="lastName"
          value={values.lastName}
          placeholder="Last name"
          label="Last name"
          onChange={handleChange('lastName')}
        />
        <MenuButtonInput
          name="country"
          value={values.country}
          placeholder="Choose your country"
          label="Country of residence"
        >
          {(close: () => void): ReactNode => (
            <CountryPickerModal
              countries={countries?.filter((c) =>
                supportedCountries.includes(c.code),
              )}
              onChange={(value?: TCountry): void => {
                handleCountryChange(value);
                close();
              }}
            />
          )}
        </MenuButtonInput>

        {values.country === 'USA' && (
          <MenuButtonInput
            name="state"
            value={values.state}
            placeholder="Choose your state"
            label="State"
          >
            {(close: () => void): ReactNode => (
              <SignupPopupContainer>
                <Heading level="h5">Choose your State:</Heading>
                <SignupStatesContainer>
                  <RadioInputGroup
                    onChange={(checked, _values): void => {
                      const [check] = checked;
                      handleStateChange(_values[check]);
                      close();
                    }}
                  >
                    {getAllStateNames().map((stateName: string) => (
                      <RadioInput
                        key={stateName}
                        name="radio-states"
                        id={getStateCode(stateName)}
                        value={stateName}
                      />
                    ))}
                  </RadioInputGroup>
                </SignupStatesContainer>
              </SignupPopupContainer>
            )}
          </MenuButtonInput>
        )}

        <PasswordInput
          autoCompleteOff
          label="Password"
          name="password"
          value={values.password}
          placeholder="Create a password"
          hint="At least 8 characters, one capital, one number"
          onChange={handleChange('password')}
          onBlur={enableValidation}
          errors={passwordErrors}
          checked={isPasswordValid && isPasswordDirty}
        />
      </FormSection>

      <FormSection>
        <SignupTermsCheckbox
          name="accepted"
          value={accepted}
          onChange={setAccepted}
        />
      </FormSection>

      {errorMessage}

      <FormSubmitButton
        fluid
        type="submit"
        disabled={!isFormValid}
        loading={loading}
      >
        Proceed
      </FormSubmitButton>
    </Form>
  );
};
