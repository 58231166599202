import { ethers } from 'ethers';

import { QueryResult } from '@hedgehog/browser/shared/utils';
import { convertToNumber } from '@hedgehog/ui/themes';
import { currencies } from '@hedgehog/utils/formats';

import {
  CapitalCall,
  useCapitalCallsBlockchainQuery,
} from '../use-capital-calls-query.hook';
import { useFundsBlockchainQuery } from '../use-funds-query.hook';

export interface CapitalCallStatistics {
  id: number;
  totalAmount: number;
  totalAmountInUsd: string;
  settledTotalAmount: number;
  settledTotalAmountInUsd: string;
}

type Group<T> = { [key: number]: T };

export interface FundStatistics {
  id: number;
  name: string;
  totalAmount: number;
  totalAmountInUsd: string;
  settledTotalAmount: number;
  settledTotalAmountInUsd: string;
  capitalCalls: {
    activeCount: number;
    closedCount: number;
    callIds: number[];
    calls: Group<CapitalCallStatistics>;
  };
}

export interface UseFundsStatisticsOutput {
  totalAmount: number;
  totalAmountInUsd: string;
  settledTotalAmount: number;
  settledTotalAmountInUsd: string;
  fundsCount: number;
  capitalCallsCount: number;
  fundIds: number[];
  funds: Group<FundStatistics>;
}

const convertFromEthersToNumber = (value: ethers.BigNumber): number =>
  convertToNumber(ethers.utils.formatEther(value), 0);

export const useFundsStatistics = (): QueryResult<UseFundsStatisticsOutput> => {
  const {
    data: calls,
    loading: callsLoading,
    error: callsError,
  } = useCapitalCallsBlockchainQuery();
  const {
    data: funds,
    loading: fundsLoading,
    error: fundsError,
  } = useFundsBlockchainQuery();

  if (!calls || !funds) {
    return {
      data: null,
      loading: fundsLoading || callsLoading,
      error: fundsError || callsError,
    };
  }

  const callsByFundId = calls.reduce<Record<string, CapitalCall[]>>(
    (acc, call) => ({
      ...acc,
      [call.fundId]: [...(acc[call.fundId] ?? []), call],
    }),
    {},
  );

  const fundsWithCalls = funds.map((fund) => ({
    ...fund,
    capitalCalls: callsByFundId[fund.id] ?? [],
  }));

  const aggregatedFunds = fundsWithCalls.reduce<Group<FundStatistics>>(
    (acc, fund): Group<FundStatistics> => {
      const closedCount = fund.capitalCalls.reduce(
        (acc, call) => (call.amount.eq(call.settled) ? acc + 1 : 0),
        0,
      );
      const activeCount = fund.capitalCalls.length - closedCount;

      const calls = fund.capitalCalls.reduce<Group<CapitalCallStatistics>>(
        (acc, call) => {
          const totalAmount = convertFromEthersToNumber(call.amount);
          const settledTotalAmount = convertFromEthersToNumber(call.settled);
          return {
            ...acc,
            [call.id]: {
              id: call.id,
              totalAmount,
              totalAmountInUsd: currencies.formatMoney(totalAmount),
              settledTotalAmount,
              settledTotalAmountInUsd:
                currencies.formatMoney(settledTotalAmount),
            },
          };
        },
        {},
      );

      const totalAmount = fund.capitalCalls.reduce(
        (sum, { id }) => sum + (calls[id].totalAmount ?? 0),
        0,
      );
      const settledTotalAmount = fund.capitalCalls.reduce(
        (sum, { id }) => sum + (calls[id].settledTotalAmount ?? 0),
        0,
      );

      return {
        ...acc,
        [fund.id]: {
          id: fund.id,
          name: fund.fundName,
          totalAmount,
          totalAmountInUsd: currencies.formatMoney(totalAmount),
          settledTotalAmount,
          settledTotalAmountInUsd: currencies.formatMoney(settledTotalAmount),
          capitalCalls: {
            activeCount,
            closedCount,
            callIds: fund.capitalCalls.map(({ id }) => id),
            calls,
          },
        },
      };
    },
    {},
  );

  const { totalAmount, settledTotalAmount } = funds.reduce(
    (acc, { id }) => ({
      totalAmount: acc.totalAmount + aggregatedFunds[id].totalAmount,
      settledTotalAmount:
        acc.settledTotalAmount + aggregatedFunds[id].settledTotalAmount,
    }),
    { totalAmount: 0, settledTotalAmount: 0 },
  );

  return {
    data: {
      totalAmount,
      totalAmountInUsd: currencies.formatMoney(totalAmount),
      settledTotalAmount,
      settledTotalAmountInUsd: currencies.formatMoney(settledTotalAmount),
      fundsCount: funds.length,
      capitalCallsCount: calls.length,
      fundIds: funds.map(({ id }) => id),
      funds: aggregatedFunds,
    },
    loading: fundsLoading || callsLoading,
    error: fundsError || callsError,
  };
};
