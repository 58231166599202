export const HeartIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.24264 7.24264L8 14L14.7574 7.24264C15.553 6.44699 16 5.36786 16 4.24264V4.05234C16 1.8143 14.1857 0 11.9477 0C10.7166 0 9.55233 0.559588 8.78331 1.52086L8 2.5L7.21669 1.52086C6.44767 0.559587 5.28338 0 4.05234 0C1.8143 0 0 1.8143 0 4.05234V4.24264C0 5.36786 0.44699 6.44699 1.24264 7.24264Z"
      fill="currentColor"
    />
  </svg>
);

export default HeartIcon;
