import styled, { CSSObject, RuleSet } from 'styled-components';

import { InfoCircleIcon } from '@hedgehog/ui/icons';
import { useModal } from '@hedgehog/ui/modals';
import { shimmerGradient } from '@hedgehog/ui/themes';
import { Heading, Label } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import {
  HighlightTooltipModal,
  HighlightTooltipModalProps,
} from './highlight-tooltip-modal.component';

const HighlightWrapper = styled.div<{ clickable?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ clickable }): string => (clickable ? 'cursor: pointer;' : '')}
`;

const HighlightTitle = styled(Label)``;

const HighlightValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  ${({ theme }): CSSObject => ({ color: theme.colors.grey200 })}
`;

export type HighlightProps = StandardProps<
  {
    title: string;
    description: string;
    tooltip?: HighlightTooltipModalProps;
    loading?: boolean;
  },
  never
>;

export const Highlight = styled(
  ({
    title,
    description,
    tooltip,
    loading,
    ...props
  }: HighlightProps): JSX.Element => {
    const { openAndResolveWithSubmitValue: openModal } = useModal(
      ({ submitModal }) => (
        <HighlightTooltipModal
          title={tooltip ? tooltip.title : ''}
          description={tooltip ? tooltip.description : ''}
          onClose={submitModal}
        />
      ),
      { throwOnAbort: false },
    );

    const handleClick = (): void => {
      if (tooltip) openModal();
    };

    return (
      <HighlightWrapper onClick={handleClick} {...props}>
        <HighlightTitle>{title}</HighlightTitle>
        <HighlightValueWrapper>
          <Heading level="h5" loading={loading}>
            {description}
          </Heading>
          {tooltip && <IconWrapper>{<InfoCircleIcon />}</IconWrapper>}
        </HighlightValueWrapper>
      </HighlightWrapper>
    );
  },
)``;
