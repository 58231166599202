export const LockIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 9V6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6V9H18V24H0V9H3ZM6 6C6 4.34315 7.34315 3 9 3C10.6569 3 12 4.34315 12 6V9H6V6ZM7.5 19.5V13.5H10.5V19.5H7.5Z"
      fill="currentColor"
    />
  </svg>
);

export default LockIcon;
