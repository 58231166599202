import styled from 'styled-components';

import { PortfolioCard } from '@hedgehog/browser/shared/order/feature-portfolio';
import { OrderStatus } from '@hedgehog/data-access/partners-types';
import { AssetCategory } from '@hedgehog/shared/types';

import {
  getStatusText,
  getStatusEmojiOrIcon,
  getFooterText,
} from '../../helpers';

export interface PortfolioOverviewProps {
  totalAmount?: number;
  differenceAmount?: number;
  orders?: PortfolioOrder[];
  className?: string;
  explorerUrl?: string;
}

export interface TenantTokens {
  contractAddress: string;
  balance: string;
  vesting: {
    startDate: string;
    endDate: string;
    amount: string;
  }[];
}

export interface PortfolioOrder {
  id: string;
  currency: 'USD' | 'GBP';
  amount: number;
  quantity: number;
  status?: OrderStatus;
  asset?: PortfolioOrderAsset;
  transferConfirmed?: boolean;
}

export interface PortfolioOrderAsset {
  name: string;
  category: AssetCategory;
}

const Content = styled.div`
  display: flex;
  flex-flow: column nowrap;

  padding: 1rem 0;
  gap: 1.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const PortfolioOverview = (
  props: PortfolioOverviewProps,
): JSX.Element | null => {
  const { orders = [], className } = props;

  return orders.length < 1 ? null : (
    <Wrapper className={className}>
      <Content>
        {orders.map(
          (order) =>
            order.asset && (
              <PortfolioCard
                key={order.id}
                name={order.asset.name}
                category={order.asset.category}
                footerText={getFooterText(order)}
                statusText={getStatusText(order)}
                {...getStatusEmojiOrIcon(order)}
              />
            ),
        )}
      </Content>
    </Wrapper>
  );
};
