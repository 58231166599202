import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectAuthToken, useSelector } from '@hedgehog/data-access/partners';

import { StandardProps } from '../../types';

export const UnauthenticatedGuard = (props: StandardProps): JSX.Element => {
  const navigate = useNavigate();
  const token = useSelector(selectAuthToken);

  useEffect(() => {
    if (!token) return;
    navigate('/dashboard', { replace: true });
  }, [token]);

  return <>{props.children}</>;
};
