import { useState } from 'react';
import { UserOperationEventEvent } from 'userop/dist/typechain/EntryPoint';

import { useFundManagerUserOperation } from './use-fund-manager-contract.hook';

type FnParams = {
  fundHashedBlockchainRef: string;
  address: string;
};

type Fn = (params: FnParams) => Promise<void>;

type Result = {
  isLoading: boolean;
  error: string | undefined;
  success: boolean;
  userOp: UserOperationEventEvent | undefined;
};

export const useAuthoriseTeamMemberUserOperation = (): [Fn, Result] => {
  const userOperation = useFundManagerUserOperation({
    functionName: 'authoriseTeamMember',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);
  const [userOp, setUserOp] = useState<UserOperationEventEvent | undefined>(
    undefined,
  );

  const fn = async ({
    fundHashedBlockchainRef,
    address,
  }: FnParams): Promise<void> => {
    try {
      setIsLoading(true);
      const userOp = await userOperation([fundHashedBlockchainRef, address]);
      console.log('Successfully created userOp', userOp);
      setUserOp(userOp);
      setSuccess(Boolean(userOp.args.success));
      if (!userOp.args.success) {
        setError('UserOp failed to execute');
      }
    } catch (err) {
      console.log('failed to authorise team member', err);
      setError(
        `Failed to authorise team member: ${
          err instanceof Error ? err.message : 'unknown error'
        }`,
      );
    } finally {
      setIsLoading(false);
    }
  };

  return [fn, { isLoading, error, success, userOp }];
};
