import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

import * as animations from '@hedgehog/utils/animations';
import { screens } from '@hedgehog/utils/sizes';

export const defaultAnimationTimeMs = 300;

const fadeOut = css<{ maxHeight?: number }>`
  ${animations.unwrapOut};
  ${animations.flyOut};

  @media (max-width: ${screens.medium}px) {
    animation-name: unwrap-out;
  }

  @media (min-width: ${screens.medium}px) {
    animation-name: fly-out;
  }

  animation-timing-function: ease-out;
  animation-duration: ${defaultAnimationTimeMs}ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const fadeIn = css<{ maxHeight?: number }>`
  ${animations.unwrapIn};
  ${animations.flyIn};

  @media (max-width: ${screens.medium}px) {
    animation-name: unwrap-in;
  }
  @media (min-width: ${screens.medium}px) {
    animation-name: fly-in;
  }

  animation-timing-function: ease-in;
  animation-duration: ${defaultAnimationTimeMs}ms;
  animation-delay: 0ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const BottomSheetContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: flex-end;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  pointer-events: none;

  @media (min-width: ${screens.medium}px) {
    align-items: center;
    justify-content: center;
  }
`;

export const MaxHeight = '85vh';

const BottomSheetInnerWrapper = styled.div<{
  closing: boolean;
  maxHeight?: number;
}>`
  max-height: ${MaxHeight};
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  background-color: transparent;
  pointer-events: all;

  ${({ closing }) => (closing ? fadeOut : fadeIn)};

  @media (min-width: ${screens.medium}px) {
    justify-content: center;
  }
`;

export const BottomSheetContent = styled.div`
  position: relative;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  background-color: white;
  display: flex;
  justify-content: center;

  @media (min-width: ${screens.medium}px) {
    border-radius: 1rem;
    max-width: 23.4375rem;
  }

  @media only screen and (max-width: ${screens.medium}px) {
    max-width: unset !important;
  }
`;

interface BottomSheetProps {
  closing?: boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
}

export const BottomSheet = ({
  closing,
  children,
  className,
}: BottomSheetProps): JSX.Element => {
  return (
    <BottomSheetContainer className={className}>
      <BottomSheetInnerWrapper closing={closing || false}>
        <BottomSheetContent>{children}</BottomSheetContent>
      </BottomSheetInnerWrapper>
    </BottomSheetContainer>
  );
};

export default BottomSheet;
