import { ReactNode } from 'react';
import styled from 'styled-components';

import { Chunk } from '@hedgehog/ui/icons';
import { useModal } from '@hedgehog/ui/modals';
import { Label, Heading } from '@hedgehog/ui/typography';

import { AssetHighlightModal } from '../asset-highlight-modal/asset-highlight-modal';

const StyledAssetHighlight = styled.div<{ clickable: boolean }>`
  ${({ clickable }): string =>
    clickable ? 'cursor: pointer;' : 'pointer-events: none;'}
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

const ValueWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChunkIcon = styled(Chunk)`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`;

type AssetHighlightProps = {
  label: string;
  value: string;
  tooltip?: { title: string; content: ReactNode | string };
};

export const AssetHighlight = ({
  label,
  value,
  tooltip,
}: AssetHighlightProps): JSX.Element => {
  const { openAndResolveWithSubmitValue: openModal } = useModal(
    ({ submitModal }) => (
      <AssetHighlightModal
        title={tooltip?.title || ''}
        description={tooltip?.content || ''}
        onClose={submitModal}
      />
    ),
  );

  const openDetails = async (): Promise<void> => {
    try {
      await openModal();
    } catch (err) {
      // NOOP
    }
  };

  return (
    <StyledAssetHighlight
      clickable={!!tooltip}
      onClick={openDetails}
      role="presentation"
    >
      <Label color="grey400">{label}</Label>
      <ValueWrapper>
        <Heading level="h5">{value}</Heading>
        {tooltip && <ChunkIcon color="shadow200" size="m" icon="info-circle" />}
      </ValueWrapper>
    </StyledAssetHighlight>
  );
};
