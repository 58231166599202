import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useFundsBlockchainQuery } from '../hooks';
import { useLazyFundsQuery } from '../store/apis';

import { useAppData } from './app-data.provider';

type FundsProviderContextProperties = {
  funds: any[];
  loading: boolean;
};

export const FundsProviderContext =
  createContext<FundsProviderContextProperties>({
    funds: [],
    loading: false,
  });

export const useCurrentFunds = (): FundsProviderContextProperties => {
  const context = useContext(FundsProviderContext);
  if (context === undefined) {
    throw new Error('useFunds must be used within a FundsProviderContext');
  }
  return context;
};

type FundsProviderProps = {
  children: ReactNode;
};

export const FundsProvider = ({
  children,
}: FundsProviderProps): JSX.Element => {
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';

  // Hedgehog database query
  const [loadFundsFromHedgehog, { isFetching: hedgehogFundsFetching }] =
    useLazyFundsQuery();

  // Blockchain query
  const { data: blockchainFunds, loading: blockchainFundsFetching } =
    useFundsBlockchainQuery();

  const [mergedFunds, setMergedFunds] = useState<
    {
      blockchainId: number;
      id: string;
      name: string;
      description: string;
      country: string;
      currency: string;
      size: number;
      thumbnail: string;
      hashedBlockchainReference: string;
    }[]
  >();

  useEffect(() => {
    if (partnerId && blockchainFunds) {
      loadFundsData();
    }
  }, [blockchainFunds, partnerId]);

  const loadFundsData = async (): Promise<void> => {
    const hedgehogFunds = await loadFundsFromHedgehog({ partnerId }).unwrap();

    const fundsWithBlockchainId = blockchainFunds?.map((blockchainFund) => {
      const fund = hedgehogFunds?.find(
        (f) => f.hashedBlockchainReference === blockchainFund.hedgehogFundRef,
      );

      return {
        blockchainId: blockchainFund.id,
        hashedBlockchainReference: blockchainFund.hedgehogFundRef,
        id: fund?.id || '',
        name: fund?.name || '',
        description: fund?.description || '',
        size: fund?.size || 0,
        thumbnail: fund?.thumbnail || '',
        country: fund?.country || '',
        currency: fund?.currency || '',
      };
    });

    setMergedFunds(fundsWithBlockchainId?.filter((f) => f.name));
  };

  return (
    <FundsProviderContext.Provider
      value={{
        funds: mergedFunds || [],
        loading: hedgehogFundsFetching || blockchainFundsFetching,
      }}
    >
      {children}
    </FundsProviderContext.Provider>
  );
};
