import { isValidElement, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

const SideTile = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
`;

const SideTilePlaceholder = styled.div`
  flex: 0 0 auto;
  min-width: 2.5rem;
  min-height: 2.5rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  min-height: 2.5rem;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.xsmall,
  })}
`;

export type HeaderProps = StandardProps<{
  left?: ReactNode | ReactNode[];
  right?: ReactNode | ReactNode[];
}>;

export const Header = styled(
  ({ left, right, children, className }: HeaderProps): JSX.Element => {
    return (
      <HeaderWrapper className={className}>
        {left ? (
          isValidElement(left) ? (
            <SideTile>{left}</SideTile>
          ) : (
            <SideTilePlaceholder />
          )
        ) : undefined}
        {children}
        {right ? (
          isValidElement(right) ? (
            <SideTile>{right}</SideTile>
          ) : (
            <SideTilePlaceholder />
          )
        ) : undefined}
      </HeaderWrapper>
    );
  },
)`
  ${({ theme }): CSSObject => ({
    backgroundColor: theme.colors.white,
  })}
`;
