import { useState } from 'react';
import styled from 'styled-components';

import { PartnerAuthPage } from '@hedgehog/browser/partners/shared/layouts';
import {
  ForgotPasswordForm,
  ForgotPasswordFormData,
} from '@hedgehog/browser/shared/auth';
import { useAuth } from '@hedgehog/data-access/contexts';
import { HeaderWithBack } from '@hedgehog/ui/headers';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Disclaimer = styled(Paragraph)`
  text-align: center;
`;

export const ForgotPasswordPage = (): JSX.Element => {
  const { forgotPassword } = useAuth();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [done, setDone] = useState<boolean>(false);
  const [formData, setFormData] = useState<ForgotPasswordFormData>({
    email: '',
  });

  const handleSubmit = async (): Promise<void> => {
    const { email } = formData;
    try {
      await forgotPassword(email);
      setDone(true);
    } catch (err: unknown) {
      setErrorMessage('Something went wrong, try again later');
    }
  };

  return (
    <PartnerAuthPage>
      <HeaderWithBack>
        <Heading level="h5">Forgot password</Heading>
      </HeaderWithBack>
      <ForgotPasswordForm
        sent={done}
        data={formData}
        errorMessage={errorMessage}
        onChange={setFormData}
        onSubmit={handleSubmit}
      />
      <Disclaimer small color="grey400">
        You will receive an email with a link to reset your password.
      </Disclaimer>
    </PartnerAuthPage>
  );
};

export default ForgotPasswordPage;
