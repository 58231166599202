import { useEffect } from 'react';
import styled from 'styled-components';

import { useFundsStatistics } from '@hedgehog/data-access/partners';
import { shimmerGradient } from '@hedgehog/ui/themes';

const FundsTable = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const Table = styled.table`
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  border-collapse: collapse;
`;

const TableHeaderRow = styled.tr``;

const TableHeader = styled.th`
  text-align: left;
  padding: 1rem 0;
`;

const TableBody = styled.tbody`
  overflow: auto;
`;

const TableCell = styled.td`
  padding: 1rem 0;
`;

const TableRow = styled.tr<{ loading?: boolean }>`
  border-top: 1px solid;
  border-bottom: 1px solid;

  ${({ theme }) => ({
    borderTopColor: theme.colors.grey100,
    borderBottomColor: theme.colors.grey100,
  })}

  & ${TableCell} span {
    ${({ loading }) => (loading ? shimmerGradient : '')}
  }
`;

export interface FundsLadderboardProps {
  loading?: boolean;
  onLoaded?: () => void;
}

export const FundsLadderboard = ({
  loading: parentLoading = false,
  onLoaded = () => void 0,
}: FundsLadderboardProps) => {
  const { data: statistics, loading: statisticsLoading } = useFundsStatistics();

  useEffect(() => {
    if (statistics && !statisticsLoading) {
      onLoaded();
    }
  }, [statisticsLoading]);

  const loading = parentLoading || statisticsLoading;
  return (
    <FundsTable>
      <Table>
        <TableHeaderRow>
          <TableHeader>Name</TableHeader>
          <TableHeader>Active Calls</TableHeader>
          <TableHeader>Closed Calls</TableHeader>
          <TableHeader>Total Amount</TableHeader>
          <TableHeader>Settled Amount</TableHeader>
        </TableHeaderRow>
        <TableBody>
          {!loading && statistics ? (
            statistics.fundIds
              .map((id) => statistics.funds[id])
              .filter(({ totalAmount }) => totalAmount > 0)
              .sort((a, b) => b.totalAmount - a.totalAmount)
              .slice(0, 3)
              .map((fund) => (
                <TableRow key={fund.id}>
                  <TableCell>{fund.name}</TableCell>
                  <TableCell>{fund.capitalCalls.activeCount}</TableCell>
                  <TableCell>{fund.capitalCalls.closedCount}</TableCell>
                  <TableCell>{fund.totalAmountInUsd}</TableCell>
                  <TableCell>{fund.settledTotalAmountInUsd}</TableCell>
                </TableRow>
              ))
          ) : (
            <TableRow key="placeholder" loading={loading}>
              <TableCell>
                <span>placeholder</span>
              </TableCell>
              <TableCell>
                <span>000</span>
              </TableCell>
              <TableCell>
                <span>000</span>
              </TableCell>
              <TableCell>
                <span>000,000,000.00</span>
              </TableCell>
              <TableCell>
                <span>000,000,000.00</span>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </FundsTable>
  );
};
