import React from 'react';
import { createPortal } from 'react-dom';
import { Link, Outlet, To } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { depth } from '@hedgehog/utils/sizes';

const ModalContainer = styled.div`
  position: fixed;
  z-index: ${depth.modal};
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
`;

const ModalOverlay = styled.div<{ closing?: boolean }>`
  display: block;
  height: 100vh;
  width: 100vw;
  ${({ theme }): CSSProp => ({ backgroundColor: theme.colors.shadow800 })};

  opacity: ${({ closing }): number => (closing ? 0 : 1)};
  will-change: opacity;
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
`;

export interface ModalWrapperProps {
  onBackdropClick?: () => void;
  backdropTo?: To;
  closing?: boolean;
  children?: React.ReactNode | React.ReactNode[];
}

export const ModalWrapper = ({
  onBackdropClick,
  backdropTo,
  closing = false,
  children,
}: ModalWrapperProps): JSX.Element => {
  const portalElement = document.getElementById('portal');
  const anchorProps = backdropTo
    ? {
        as: Link,
        to: backdropTo,
      }
    : {};

  return createPortal(
    <ModalContainer>
      <ModalOverlay
        onClick={onBackdropClick}
        closing={closing}
        {...anchorProps}
      />
      <React.Suspense
        fallback={
          <LoadingContainer>
            <Loader />
          </LoadingContainer>
        }
      >
        {children ?? <Outlet />}
      </React.Suspense>
    </ModalContainer>,
    portalElement!,
  );
};
