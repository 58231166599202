export const LinkedinIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.111 2.00089H2.88549C2.40222 1.99544 2.0059 2.38257 2 2.86585V13.134C2.0057 13.6175 2.40198 14.005 2.88549 13.9999H13.111C13.5955 14.0059 13.9933 13.6185 14 13.134V2.86511C13.9931 2.38087 13.5952 1.99377 13.111 2.00008"
      fill="#0A66C2"
    />
    <path
      d="M10.5909 12H12.3508L12.3516 8.88538C12.3516 7.35636 12.0227 6.18103 10.2389 6.18103C9.55703 6.15567 8.91647 6.50809 8.57197 7.09815H8.54832V6.32225H6.85881V11.9998H8.61872V9.19115C8.61872 8.45051 8.75896 7.7332 9.67558 7.7332C10.5792 7.7332 10.5909 8.58072 10.5909 9.23908V12Z"
      fill="white"
    />
    <path
      d="M3.85156 4.52327C3.85166 5.0883 4.309 5.54627 4.87304 5.54617C5.14391 5.54612 5.40366 5.43829 5.59516 5.24639C5.78665 5.05449 5.89421 4.79424 5.89416 4.5229C5.89406 3.95787 5.43672 3.4999 4.87268 3.5C4.30863 3.5001 3.85146 3.95823 3.85156 4.52327Z"
      fill="white"
    />
    <path d="M3.99126 12H5.753V6.32225H3.99126V12Z" fill="white" />
  </svg>
);

export default LinkedinIcon;