import React, { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { Header } from '@hedgehog/ui/headers';
import { ThemeColorName } from '@hedgehog/ui/themes';
import { screens, spacing } from '@hedgehog/utils/sizes';

import { PageWidthBase } from './page-base.styles';

type PageProps = {
  header?: ReactNode | ReactNode[];
  backgroundColor?: ThemeColorName | string;
  sidebarPage?: boolean;
  className?: string;
  children?: ReactNode | ReactNode[];
};

const PageMain = styled.main<PageProps>`
  width: 100%;
  flex: 1;
  padding-bottom: 8rem;
`;

const PageContent = styled.div<PageProps>`
  display: flex;
  flex-direction: column;
  min-height: 100%;

  ${PageWidthBase}

  & > *:not(:last-child) {
    ${({ theme }): CSSProp => ({ marginBottom: theme.spacing.xxlarge })};
  }

  & > ${Header} {
    margin-bottom: 2.5rem;
  }

  padding: ${({ sidebarPage }): string =>
    sidebarPage ? '2rem 2.5rem' : '1.5rem 4rem'};
  @media only screen and (max-width: ${screens.small}px) {
    padding: 1rem 1.5rem;
  }
`;

export const Page = React.forwardRef(
  (
    {
      header,
      backgroundColor = 'background',
      sidebarPage,
      className,
      children,
    }: PageProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <PageMain
        backgroundColor={backgroundColor}
        className={className}
        ref={ref}
        data-cy="page"
      >
        <PageContent sidebarPage={sidebarPage}>
          {header}
          {children}
        </PageContent>
      </PageMain>
    );
  },
);
