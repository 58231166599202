import styled from 'styled-components';

import { useUpdateTeamMemberMutation } from '@hedgehog/data-access/partners';
import { BottomSheet } from '@hedgehog/ui/modals';

import { Deactivate, Reactivate } from '../../components';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  gap: 1.5rem;
  text-align: center;
`;

export enum ActivationState {
  Active = 'active',
  Inactive = 'inactive',
}

export type ActivationModalProps = {
  state: ActivationState;
  firstName: string;
  lastName: string;
  teamMemberId: string;
  partnerId: string;
  submitModal: () => void;
  cancel: () => void;
};

export const TeamMemberActivationModal = ({
  firstName,
  lastName,
  teamMemberId,
  partnerId,
  state,
  submitModal,
  cancel,
}: ActivationModalProps): JSX.Element => {
  const [updateTeamMember, { isLoading }] = useUpdateTeamMemberMutation();
  const disableTeamMember = async (disabled: boolean): Promise<void> => {
    await updateTeamMember({
      teamMemberId,
      partnerId,
      body: {
        disabled,
      },
    });
    submitModal();
  };

  return (
    <BottomSheet>
      <Content>
        {state === ActivationState.Active ? (
          <Deactivate
            firstName={firstName}
            disable={(): Promise<void> => disableTeamMember(true)}
            loading={isLoading}
            cancel={cancel}
          />
        ) : (
          <Reactivate
            firstName={firstName}
            lastName={lastName}
            enable={(): Promise<void> => disableTeamMember(false)}
            loading={isLoading}
            cancel={cancel}
          />
        )}
      </Content>
    </BottomSheet>
  );
};
