export const AngleDown = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6474 9.52455L16.6478 9.52494C16.6947 9.57142 16.7319 9.62672 16.7573 9.68765C16.7827 9.74859 16.7957 9.81394 16.7957 9.87994C16.7957 9.94595 16.7827 10.0113 16.7573 10.0722C16.732 10.133 16.6948 10.1883 16.6481 10.2347C16.648 10.2348 16.6479 10.2349 16.6478 10.2349L12.3585 14.4743L12.3585 14.4743L12.3549 14.4778C12.3085 14.5247 12.2532 14.5619 12.1922 14.5873C12.1313 14.6127 12.066 14.6257 11.9999 14.6257C11.9339 14.6257 11.8686 14.6127 11.8077 14.5873C11.7467 14.5619 11.6914 14.5247 11.6449 14.4778L11.6435 14.4764L7.4035 10.2364L7.40351 10.2364L7.40205 10.2349C7.35518 10.1885 7.31799 10.1332 7.2926 10.0722C7.26722 10.0113 7.25415 9.94595 7.25415 9.87994C7.25415 9.81394 7.26722 9.74858 7.2926 9.68765C7.31799 9.62673 7.35518 9.57142 7.40205 9.52494L7.40245 9.52455C7.49613 9.43142 7.62286 9.37915 7.75495 9.37915C7.88683 9.37915 8.01336 9.43125 8.107 9.5241C8.10715 9.52425 8.1073 9.5244 8.10745 9.52455L11.6464 13.0635L11.9975 13.4146L12.351 13.066L15.941 9.52597L15.9424 9.52455C16.0361 9.43142 16.1629 9.37915 16.2949 9.37915C16.427 9.37915 16.5538 9.43142 16.6474 9.52455Z"
      stroke="currentColor"
    />
  </svg>
);

export default AngleDown;
