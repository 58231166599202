import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAppData, useClientQuery } from '@hedgehog/data-access/partners';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm } from '../../../../../components';
import { useMarketBidParams } from '../../../../../containers';

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const MarketBidCreatedPage = (): JSX.Element => {
  const [{ bidPrice, clientId }] = useMarketBidParams();
  const { activePartnerId } = useAppData();
  const { data: client } = useClientQuery({
    clientId: clientId || '',
    partnerId: activePartnerId || '',
  });
  const navigate = useNavigate();

  // TODO: We should externalise this as a partner config
  const commission = 0.1;
  const transferFee = 2_000;

  return (
    <ModalForm
      heading="Your bid has been submitted"
      actions={
        <PrimaryButton onClick={() => navigate('/market')}>Done</PrimaryButton>
      }
    >
      <Row>
        <Paragraph color="grey300">On behalf of</Paragraph>
        <Paragraph>
          {client?.user?.firstName} {client?.user?.lastName}
        </Paragraph>
      </Row>
      <Row>
        <Paragraph color="grey300">Buyer market commission</Paragraph>
        <Paragraph>
          {currencies.formatMoney(bidPrice ? bidPrice * commission : 0)}
        </Paragraph>
      </Row>
      <Row>
        <Paragraph color="grey300">Legal entity transfer fee</Paragraph>
        <Paragraph>{currencies.formatMoney(transferFee)}</Paragraph>
      </Row>
      <Row>
        <Paragraph color="grey300">Total to pay</Paragraph>
        <Heading level="h6" color="neutral">
          {currencies.formatMoney(
            bidPrice ? bidPrice * (1 + commission) + transferFee : transferFee,
          )}
        </Heading>
      </Row>
    </ModalForm>
  );
};
