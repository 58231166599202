import { createSelector } from 'reselect';

import { UUID } from '@hedgehog/shared/types';

import { RootStore } from '../../reducer';
import { Selector } from '../../utils/selector';

import { SettingsReducerState } from './settings.slice';

const selectState = (state: RootStore): SettingsReducerState => state.settings;

export const selectBaseApiUrl: Selector<string> = createSelector(
  selectState,
  (state) => state.apiBaseUrl,
);

export const selectActivePartnerId: Selector<UUID | undefined> = createSelector(
  selectState,
  (state) => state.activePartnerId,
);
