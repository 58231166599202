import { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { PrimaryButton } from '@hedgehog/ui/buttons';
import { EmailInput, PasswordInput } from '@hedgehog/ui/inputs';
import { Note, Paragraph } from '@hedgehog/ui/typography';

const Form = styled.form`
  flex: 1 0 auto;
`;

const FormInputs = styled.div`
  > * {
    padding-bottom: 1rem;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
`;

const FormFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const NoteLink = styled(Note)`
  text-align: right;
  margin-top: -1.25rem;

  & > * {
    color: inherit;
  }
`;

export interface SignInFormData {
  email?: string;
  password?: string;
}

export interface SignInFormScreenProps {
  onSubmit?: (formData: Required<SignInFormData>) => void;
  forgotPasswordPath: string;
  defaultEmail?: string | null;
  error?: string | null;
  loading?: boolean;
}

export const SignInForm = (props: SignInFormScreenProps): JSX.Element => {
  const [form, updateForm] = useState({
    email: props.defaultEmail || '',
    password: '',
  });

  const handleEmailChange = (value: string): void =>
    updateForm((state) => ({ ...state, email: value }));

  const handlePasswordChange = (value: string): void =>
    updateForm((state) => ({ ...state, password: value }));

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (form.email && form.password) {
      if (props.onSubmit) props.onSubmit(form);
    }
  };

  const isSubmitDisabled = !form.email || !form.password || props.loading;

  return (
    <Form onSubmit={handleSubmit}>
      <FormInputs>
        <EmailInput
          name="email"
          value={form.email}
          placeholder="Your@email.here"
          label="Email Address"
          onChange={handleEmailChange}
        />
        <PasswordInput
          name="password"
          value={form.password}
          placeholder="Type your password"
          label="Password"
          onChange={handlePasswordChange}
        />
        <NoteLink color="grey400">
          <Link to={props.forgotPasswordPath}>Forgot password?</Link>
        </NoteLink>
        {props.error && <Paragraph>{props.error}</Paragraph>}
      </FormInputs>
      <FormFooter>
        <PrimaryButton
          fluid
          type="submit"
          disabled={isSubmitDisabled}
          loading={props.loading}
        >
          Sign in
        </PrimaryButton>
      </FormFooter>
    </Form>
  );
};
