import { useEffect, useState } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';

import { useFundManagerContract } from './use-fund-manager-contract.hook';

type Result = {
  data: {
    hash: string;
    commitment: number;
    drawn: number;
  }[];
  loading: boolean;
};

export const useLimitedPartnersBlockchainQuery = (): Result => {
  const fundManagerContract = useFundManagerContract();
  const { address } = useAccountAbstraction();
  const [data, setData] = useState<
    {
      hash: string;
      commitment: number;
      drawn: number;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!address) return;
    setLoading(true);
    fundManagerContract.callStatic['listLimitedPartners(uint32)'](0)
      .then((result: any[]) => {
        console.debug('listLimitedPartners', result);
        // merge result[0] and result[1] into an array of objects
        const data = result[0].map((hash: string, index: number) => ({
          hash,
          commitment: result[1][index].toNumber(),
          drawn: result[2][index].toNumber(),
        }));
        setData(data);
      })
      .finally(() => setLoading(false));
  }, [fundManagerContract, address]);

  return {
    data,
    loading,
  };
};

export const useLimitedPartnersLazyBlockchainQuery = () => {
  const fundManagerContract = useFundManagerContract();
  const [data, setData] = useState<
    {
      hash: string;
      commitment: number;
      drawn: number;
    }[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetch = async ({ fundId }: { fundId: number }) => {
    setLoading(true);
    const result = await fundManagerContract.callStatic[
      'listLimitedPartners(uint32)'
    ](fundId);
    console.debug('listLimitedPartners', result);
    // merge result[0] and result[1] into an array of objects
    const data = result[0].map((hash: string, index: number) => ({
      hash,
      commitment: result[1][index].toNumber(),
      drawn: result[2][index].toNumber(),
    }));
    setData(data);
    setLoading(false);
  };

  return {
    data,
    loading,
    fetch,
  };
};
