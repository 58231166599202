import { useState } from 'react';
import ReactDOM from 'react-dom';
import styled, { CSSProp } from 'styled-components';

import {
  useAppData,
  useAssetQuery,
  useAssetRound,
  useAssetRoundClass,
} from '@hedgehog/data-access/partners';
import { AssetRoundClassStatus, UUID } from '@hedgehog/shared/types';
import { PrimaryButton, SecondaryButton } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { CheckCircleIcon } from '@hedgehog/ui/icons';
import { useModal } from '@hedgehog/ui/modals';
import { Heading5, Label } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';
import { screens } from '@hedgehog/utils/sizes';

import { CreateOrderModal } from '../create-order-modal/create-order-modal.container';

const FloatingFooter = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
  z-index: 2;
`;

const Container = styled.div`
  padding: 0 1.5rem 2.5rem;
  background: transparent;
  box-shadow: none;

  @media only screen and (min-width: ${screens.medium}px) {
    padding: 0;
    border-radius: 0;
  }
`;

const ClosedIcon = styled.div`
  ${({ theme }): CSSProp => ({
    color: theme.colors.success,
  })}
  height: 1.5rem;
  width: 1.5rem;
`;

const RoundClosed = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const ButtonNoWrap = styled.button`
  white-space: nowrap;
`;

const RoundLoading = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const OrderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  ${({ theme }): CSSProp => ({
    background: theme.colors.white,
  })}
  box-sizing: border-box;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid rgba(0, 0, 20, 0.1);
  border-radius: 1rem;
  box-shadow: 0 6px 40px 0 rgba(0, 0, 0, 0.15);

  > *:first-child:not(${RoundLoading}) {
    margin-left: 0.5rem;
  }

  > *:last-child:not(${RoundLoading}) {
    flex: 0 0 0;
  }
`;

const AssetPriceDetails = styled.div`
  display: block;
`;

const ButtonWrapper = styled.div`
  display: flex;
  > *:first-child {
    margin-right: 0.5rem;
  }
`;

type AssetFooterProps = {
  assetId: UUID;
  className?: string;
};

export const AssetFooter = ({
  assetId,
  className,
}: AssetFooterProps): JSX.Element | null => {
  const env = useEnvironment();
  const [copied, setCopied] = useState<boolean>(false);
  const { activePartner } = useAppData();
  const { data: asset } = useAssetQuery({
    assetId,
    partnerId: activePartner ? activePartner.id : '',
  });
  const round = useAssetRound(assetId);
  const roundClass = useAssetRoundClass(assetId);

  if (!asset || !roundClass || !round) {
    return null;
  }

  const [thumbnail] = asset.thumbnails || [];
  const { openAndResolveWithSubmitValue } = useModal((modalProps) => (
    <CreateOrderModal
      {...modalProps}
      assetName={asset.name}
      assetThumbnail={
        thumbnail ? thumbnail.thumbnailSrc || thumbnail.src : undefined
      }
      assetRoundClassId={roundClass.id}
      pricePerToken={roundClass.pricePerToken}
      minimumInvestmentAmount={roundClass.minimumInvestmentAmount}
      totalInvestedAmount={round.totalInvestedAmount}
      totalAvailableAmount={round.totalAmount}
      currency={roundClass.currency}
    />
  ));

  const handleCopyUrl = async () => {
    const domain =
      env.type === 'local'
        ? 'http://localhost:3000'
        : window.location.origin.replace('partners', 'app');
    await navigator.clipboard.writeText(`${domain}/assets/${asset.path}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 3000);
  };

  const renderCopyButton = (): JSX.Element => {
    return (
      <ButtonNoWrap as={SecondaryButton} onClick={handleCopyUrl} type="button">
        {copied ? 'Copied!' : 'Copy URL'}
      </ButtonNoWrap>
    );
  };

  const renderContent = (): JSX.Element | null => {
    if (!roundClass) return null;
    switch (roundClass?.status) {
      case AssetRoundClassStatus.LIVE:
      case AssetRoundClassStatus.REGISTER_INTEREST:
        return (
          <>
            <AssetPriceDetails>
              <Label color="grey300">Token price</Label>
              <Heading5>
                {currencies.formatMoney(roundClass.pricePerToken, {
                  currency: roundClass.currency,
                })}
              </Heading5>
            </AssetPriceDetails>
            <ButtonWrapper>
              {renderCopyButton()}
              <ButtonNoWrap
                as={PrimaryButton}
                onClick={openAndResolveWithSubmitValue}
                type="button"
              >
                Initiate Investment
              </ButtonNoWrap>
            </ButtonWrapper>
          </>
        );
      case AssetRoundClassStatus.FUNDED:
      case AssetRoundClassStatus.ONGOING:
      case AssetRoundClassStatus.PAID_OUT:
        return (
          <>
            <RoundClosed>
              <ClosedIcon>{<CheckCircleIcon />}</ClosedIcon>
              <Label color="grey300">Investment is closed</Label>
            </RoundClosed>
            {renderCopyButton()}
          </>
        );
      case AssetRoundClassStatus.COMING_SOON:
      default:
        return (
          <>
            <RoundClosed>
              <ClosedIcon>{<CheckCircleIcon />}</ClosedIcon>
              <Label color="grey300">Coming soon</Label>
            </RoundClosed>
            {renderCopyButton()}
          </>
        );
    }
  };

  const container = document.getElementById('layout-footer');
  if (!container) return null;
  return ReactDOM.createPortal(
    <FloatingFooter className={className}>
      <Container>
        <OrderWrapper>{renderContent()}</OrderWrapper>
      </Container>
    </FloatingFooter>,
    container,
  );
};
