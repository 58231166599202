import { gql } from '@apollo/client';

export const GET_AVAILABLE_VOTES = gql`
  query GetAvailableVotes {
    getAvailableVotes {
      votesRemaining
      votesCast
    }
  }
`;
