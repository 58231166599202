import { gql } from '@apollo/client';

export const GET_ONBOARDING_STATUS = gql`
  query GetOnboardingStatus($assetId: ID) {
    onboardingProgress(input: { assetId: $assetId }) {
      canInvest
      status {
        identityCheck
        verification
        taxForm
        accreditation
        assessmentCheck
        declaration
      }
    }
  }
`;
