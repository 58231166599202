import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAppData,
  useConfirmBidMutation,
} from '@hedgehog/data-access/partners';
import {
  PrimaryLinkButton,
  SecondaryLightLinkButton,
} from '@hedgehog/ui/buttons';
import { Chunk } from '@hedgehog/ui/icons';
import { Note } from '@hedgehog/ui/typography';

import { MarketTransferActions, ModalForm } from '../../../../../components';
import {
  useMarketBidLoaders,
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';
import {
  MarketTransferAction,
  MarketTransferStatus,
  MarketTransferStep,
} from '../../../../../types';

import { STEP_FIXTURES } from './fixture';

const HeaderIcon = styled(Chunk)<{ success?: boolean }>`
  margin: 1rem auto;
  width: 100%;
  max-width: 5rem;
  height: 100%;
  max-height: 5rem;

  color: green;
  color: ${({ theme, success }): string =>
    success ? theme.colors.success : theme.colors.black};
`;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
`;

export const MarketBidAcceptConfirmedPage = (): JSX.Element => {
  const { activePartnerId } = useAppData();
  const { navigateTo, close } = useNavigateToBidPage();
  const { clientId } = useParams();
  const [{ origin, ...params }] = useMarketBidParams();
  const { areFundsLoading, areClientsLoading } = useMarketBidLoaders();

  const routes: Record<'explore' | 'market' | 'client', string> = {
    ['explore']: '/explore',
    ['market']: '/market',
    ['client']: `/clients/${clientId}/secondary-market`,
  };

  const [confirmBid, { data, isLoading: isConfirmBidLoading }] =
    useConfirmBidMutation({});
  const [steps, setSteps] = useState(STEP_FIXTURES);

  const setStepState = (step: MarketTransferStep) => {
    setSteps((state) => {
      const updatedSteps = state.map((stateStep) => {
        if (stateStep.action === step.action) {
          return step;
        }
        return stateStep;
      });
      return updatedSteps;
    });
  };

  const onSettlePaymentClicked = async () => {
    if (!params.fundId) return;
    if (!activePartnerId) return;

    setStepState({
      action: MarketTransferAction.PaymentSettled,
      status: MarketTransferStatus.InProgress,
    });

    const response = await confirmBid({
      partnerId: activePartnerId,
      bidId: `${params.bidId}`,
      fundId: params.fundId,
    });

    setStepState({
      action: MarketTransferAction.PaymentSettled,
      status: MarketTransferStatus.Success,
    });
    setStepState({
      action: MarketTransferAction.TransferFinalised,
      status: MarketTransferStatus.InProgress,
    });
    setTimeout(() => {
      setStepState({
        action: MarketTransferAction.TransferFinalised,
        status: MarketTransferStatus.Success,
      });
    }, 3200);
  };

  const transferComplete = steps.every(
    (step) => step.status === MarketTransferStatus.Success,
  );
  const isLoading = areClientsLoading || areFundsLoading || isConfirmBidLoading;

  return (
    <ModalForm
      heading={transferComplete ? 'Transfer complete' : 'Transfer in progress'}
      actions={
        transferComplete ? (
          <PrimaryLinkButton to={routes[origin]}>Done</PrimaryLinkButton>
        ) : (
          <ActionWrapper>
            <Note>You can close this while the transfer is in progress</Note>
            <SecondaryLightLinkButton to={routes[origin]}>
              Close
            </SecondaryLightLinkButton>
          </ActionWrapper>
        )
      }
      closeModal={() => close()}
    >
      <HeaderIcon
        icon={transferComplete ? 'check-circle' : 'swap'}
        success={transferComplete}
      />
      <MarketTransferActions
        isLoading={isLoading}
        disabled={areClientsLoading || areFundsLoading}
        actions={steps}
        onSettlePaymentClicked={onSettlePaymentClicked}
      />
    </ModalForm>
  );
};
