import { useEffect } from 'react';
import styled from 'styled-components';

import { useClientsQuery } from '@hedgehog/data-access/partners';
import { Highlight } from '@hedgehog/ui/cms';

const ClientsOverview = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  gap: 1rem;

  & > ${Highlight} {
    flex: 0 0 auto;
  }
`;

export interface FundsClientsMetricProps {
  partnerId: string;
  loading?: boolean;
  onLoaded?: () => void;
}

export const FundsClientsMetric = ({
  partnerId,
  loading: parentLoading = false,
  onLoaded = () => void 0,
}: FundsClientsMetricProps): JSX.Element => {
  const { data: response, isFetching } = useClientsQuery(
    {
      partnerId,
      limit: 100,
      offset: 0,
    },
    {
      skip: !partnerId,
    },
  );

  const organisations = response?.data?.filter(
    ({ type }) => type === 'ORGANISATION',
  );
  const individuals = response?.data?.filter(
    ({ type }) => type === 'INDIVIDUAL',
  );

  useEffect(() => {
    if (response) {
      onLoaded();
    }
  }, [isFetching]);

  const loading = parentLoading || isFetching || !partnerId;
  return (
    <ClientsOverview>
      <Highlight
        title="Unique clients"
        description={`${response?.paging.total ?? '000'}`}
        loading={loading}
      />
      <Highlight
        title="Organisations amount"
        description={`${organisations?.length ?? '000'}`}
        loading={loading}
      />
      <Highlight
        title="Individuals amount"
        description={`${individuals?.length ?? '000'}`}
        loading={loading}
      />
    </ClientsOverview>
  );
};
