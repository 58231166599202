import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Heading } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

const Gutters = css`
  ${({ theme }) => ({ padding: `0 ${theme.spacing.xlarge}` })}
`;

const BreadcrumbsLayoutWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }) => ({
    '& > *:first-child': {
      borderRadius: theme.radius.xlarge,
    },
  })}
`;

const Topbar = styled.div<{ rounded?: boolean; withDivider?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;

  ${Gutters};
  ${({ theme }) => ({
    paddingTop: theme.spacing.large,
    paddingBottom: theme.spacing.large,
    backgroundColor: theme.colors.white,
    borderBottom: `${theme.border.small.width} ${theme.border.small.style} ${theme.border.small.color}`,
  })}
`;

const TopbarActions = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const BreadcrumbsLayoutContent = styled.div<BreadcrumbsLayoutProps>`
  flex: 1 1 100%;
  overflow: auto;
  min-height: 100%;

  ${Gutters};
  ${({ theme }) => ({
    backgroundColor: theme.colors.white,
  })}
`;

type BreadcrumbsLayoutProps = StandardProps<{
  title?: ReactNode | string;
  actions?: ReactNode | ReactNode[];
  rounded?: boolean;
  hideTopbar?: boolean;
  withDivider?: boolean;
  children?: ReactNode;
  className?: string;
}>;

export const BreadcrumbsLayout = ({
  title,
  actions,
  rounded = false,
  hideTopbar = false,
  withDivider = true,
  children,
  className,
}: BreadcrumbsLayoutProps): JSX.Element => {
  return (
    <BreadcrumbsLayoutWrapper className={className}>
      {title && (
        <Topbar rounded={rounded} withDivider={withDivider}>
          {typeof title === 'string' ? (
            <Heading level="h3">{title}</Heading>
          ) : (
            title
          )}
          {actions && <TopbarActions>{actions}</TopbarActions>}
        </Topbar>
      )}
      <BreadcrumbsLayoutContent
        hideTopbar={hideTopbar}
        withDivider={withDivider}
      >
        {children ? children : <Outlet />}
      </BreadcrumbsLayoutContent>
    </BreadcrumbsLayoutWrapper>
  );
};
