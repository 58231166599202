import { UUID } from '@hedgehog/shared/types';

import { AutoscrollState } from './autoscroll.context';

type ActionType = 'mount' | 'unmount' | 'next' | 'previous';

type Action<T extends ActionType = ActionType> = {
  type: T;
  element?: HTMLElement;
  fromElementId?: UUID;
  scrollElementId?: UUID;
};

export default function reducer(
  state: AutoscrollState,
  action: Action,
): AutoscrollState {
  const { elements } = state;
  switch (action.type) {
    case 'next': {
      if (!action.fromElementId) return state;
      const indexFrom = state.order.indexOf(action.fromElementId);
      if (indexFrom === -1) return state;
      const overflows = indexFrom >= state.order.length - 1;
      if (overflows) return state;
      const nextIndex = indexFrom + 1;
      return {
        ...state,
        currentIndex: nextIndex,
        currentElement: elements[state.order[nextIndex]],
      };
    }
    case 'previous': {
      if (!action.fromElementId) return state;
      const indexFrom = state.order.indexOf(action.fromElementId);
      if (indexFrom <= 0) return state;
      const prevIndex = indexFrom - 1;
      return {
        ...state,
        currentIndex: prevIndex,
        currentElement: elements[state.order[prevIndex]],
      };
    }
    case 'mount': {
      if (!action.element) return state;
      if (!action.scrollElementId) return state;
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.scrollElementId]: action.element,
        },
        order: [...state.order, action.scrollElementId],
      };
    }
    case 'unmount': {
      if (!action.scrollElementId) return state;
      const newElements = {
        ...elements,
      };
      delete newElements[action.scrollElementId];
      return {
        ...state,
        elements: newElements,
        order: state.order.filter((id) => id !== action.scrollElementId),
      };
    }
    default:
      return state;
  }
}
