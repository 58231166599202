import styled from 'styled-components';

import {
  CardListItemContent,
  CardListItem,
} from '@hedgehog/browser/shared/funds/feature-marketplace';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm, ModalFormContent } from '../../../../components';
import {
  useMarketListingBalance,
  useMarketListingParams,
  useNavigateToListingPage,
} from '../../../../containers';

const CustomModalForm = styled(ModalForm)`
  & ${ModalFormContent} {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
  }
`;

const ListingSection = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
`;

export const MarketListingPositionConfirmPage = (): JSX.Element => {
  const [params] = useMarketListingParams();
  const { navigateTo, close } = useNavigateToListingPage();
  const { error = null } = {};

  const [balance] = useMarketListingBalance();

  return (
    <CustomModalForm
      heading="Confirm listing"
      submitModal={() => navigateTo(`create/terms-conditions`)}
      closeModal={() => close()}
      backModal={() => navigateTo('create/position')}
      submitButtonText="Continue"
      closeButtonText="Close"
      error={error ? 'Something went wrong' : undefined}
      notes={<Paragraph small>Listing will be live for 2 weeks</Paragraph>}
    >
      <CardListItem
        title={params.fund?.name || ''}
        body={[
          ['Unfunded commitment', currencies.formatMoney(balance.commitment)],
          ['Contributions', currencies.formatMoney(balance.contribution)],
        ]}
        footer={[
          [
            'Net Asset Value',
            <Paragraph key="net-asset-value">
              <strong>{currencies.formatMoney(balance.contribution)}</strong>
            </Paragraph>,
          ],
        ]}
      />

      <ListingSection>
        <Heading level="h5">
          Hold {Math.round((1 - params.positionRatio) * 100)}%
        </Heading>
        <CardListItemContent
          body={[
            [
              'Unfunded commitment',
              currencies.formatMoney(
                balance.commitment * (1 - params.positionRatio),
              ),
            ],
            [
              'Net Asset Value',
              currencies.formatMoney(
                balance.contribution * (1 - params.positionRatio),
              ),
            ],
          ]}
        />
      </ListingSection>

      <ListingSection>
        <Heading level="h5">
          Sell {Math.round(params.positionRatio * 100)}%
        </Heading>
        <CardListItemContent
          body={[
            [
              'Unfunded commitment',
              currencies.formatMoney(balance.commitment * params.positionRatio),
            ],
            [
              'Net Asset Value',
              currencies.formatMoney(
                balance.contribution * params.positionRatio,
              ),
            ],
          ]}
          footer={[
            ['Asking price', currencies.formatMoney(params.positionPrice)],
            ['Seller market commission', `- ${currencies.formatMoney(1_050)}`],
            [
              'Estimated net receivable',
              <Heading level="h4" key="total">
                {currencies.formatMoney(params.positionPrice - 1_050)}
              </Heading>,
            ],
          ]}
        />
      </ListingSection>
    </CustomModalForm>
  );
};
