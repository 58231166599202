import { MouseEventHandler, ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { parsePadding } from '@hedgehog/ui/themes';

export interface SelectOptionProps {
  onClick?: MouseEventHandler<HTMLDivElement>;
  children?: ReactNode | ReactNode[];
  disabled?: true;
  className?: string;
}

export const SelectOption = styled((props: SelectOptionProps): JSX.Element => {
  return (
    <div tabIndex={1} className={props.className} onClick={props.onClick}>
      {props.children}
    </div>
  );
})<SelectOptionProps>`
  ${({ theme }): CSSProp => ({
    padding: parsePadding(theme.inputs.padding),
    ...theme.typography.body,
  })}
  font-weight: inherit;
  padding: 1rem 1rem;
  margin: 0 -1rem;
  user-select: none;
  cursor: pointer;
  line-height: 1;
  flex: 1 1;

  &:not(:last-child) {
    border-bottom: 1px solid transparent;
    ${({ theme }): CSSProp => ({
      borderBottomColor: theme.colors.shadow100,
    })}
  }

  ${({ theme, disabled }): CSSProp =>
    disabled
      ? { cursor: 'inherit' }
      : { '&:focus,&:hover': { backgroundColor: theme.colors.grey200 } }}
`;
