import { gql } from '@apollo/client';

export const UPDATE_WALLET = gql`
  mutation UpdateWallet($address: String!) {
    updateWalletAddress(args: { address: $address }) {
      ... on UpdateWalletAddressPayload {
        id
        userId
        address
        type
        chain
      }

      ... on UpdateWalletAddressError {
        code
        message
      }
    }
  }
`;
