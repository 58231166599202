import { gql } from '@apollo/client';

export const GET_UPCOMING_ASSETS = gql`
  query GetUpcomingAssets {
    availableAssets(args: { status: [UPCOMING] }) {
      id
      underlyingAssetCount
      title
      address
      status
      minimumInvestmentAmount
      fundedAt
      isTenantToken
      thumbnails {
        src
        kind
        alt
        thumbnailSrc
      }
      votes {
        totalVotes
        userVotes
      }
      themes {
        category
      }
    }
  }
`;
