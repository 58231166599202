import { ContentTitleBodyBlock } from '@hedgehog/shared/types';
import { VSpace } from '@hedgehog/ui/layouts';
import { Heading, MarkdownText } from '@hedgehog/ui/typography';

type CMSTitleBodyBlockProps = {
  block: ContentTitleBodyBlock;
};

export const CMSTitleBodyBlock = ({
  block,
}: CMSTitleBodyBlockProps): JSX.Element => {
  const { title, description } = block;
  return (
    <VSpace spacing="small">
      {title && <Heading level="h6">{title}</Heading>}
      {description && <MarkdownText>{description}</MarkdownText>}
    </VSpace>
  );
};
