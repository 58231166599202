import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { PartnerAuthPage } from '@hedgehog/browser/partners/shared/layouts';
import {
  ConfirmPasswordForm,
  ConfirmPasswordFormData,
} from '@hedgehog/browser/shared/auth';
import { useAuth } from '@hedgehog/data-access/contexts';
import { Heading } from '@hedgehog/ui/typography';

export const ConfirmPasswordPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { confirmPassword } = useAuth();
  const [searchParams] = useSearchParams();

  const handleSubmit = async (formData: Required<ConfirmPasswordFormData>) => {
    try {
      const code = searchParams.get('code');
      const email = searchParams.get('email');
      if (!(code && email)) return navigate({ pathname: '/auth/sign-in' });
      await confirmPassword({
        code,
        email: decodeURIComponent(email),
        newPassword: formData.password,
      });
      return navigate({
        pathname: '/auth/sign-in',
        search: createSearchParams({
          email,
          source: 'forgot-password',
        }).toString(),
      });
    } catch (err: unknown) {
      return;
    }
  };

  return (
    <PartnerAuthPage>
      <Heading level="h4">Forgot password</Heading>
      <ConfirmPasswordForm onSubmit={handleSubmit} />
    </PartnerAuthPage>
  );
};

export default ConfirmPasswordPage;
