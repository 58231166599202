import { useParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { AssetCard } from '@hedgehog/ui/assets';
import { parseBorderToShadow } from '@hedgehog/ui/themes';
import { Label, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

type FundListItemProps = {
  loading?: boolean;
  id: string;
  size?: number;
  name: string;
  thumbnailSrc: string;
  blockchainId: string;
};

const ActiveOrNotFundCard = styled(AssetCard)<{ active?: boolean }>`
  ${({ active, theme }): CSSObject => ({
    ['&, &:hover']: {
      boxShadow: active
        ? parseBorderToShadow(theme.border.normal, {
            color: theme.colors.secondary,
            outset: false,
          })
        : undefined,
    },
  })}
`;

const CaptionWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  gap: 0.25rem;
`;

const Group = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const FundListItem = ({
  id,
  size,
  name,
  thumbnailSrc,
  blockchainId,
  loading,
}: FundListItemProps) => {
  const { fundId } = useParams();

  const caption = (
    <CaptionWrapper>
      <Group>
        <Label color="grey400">Fund size</Label>
        <Paragraph loading={loading}>
          {currencies.formatMoney((size ?? 0) / 100)}
        </Paragraph>
      </Group>
      <Group>
        <Label color="grey400">Fund blockchain id</Label>
        <Paragraph loading={loading}>{blockchainId}</Paragraph>
      </Group>
    </CaptionWrapper>
  );

  return (
    <ActiveOrNotFundCard
      loading={loading}
      active={fundId === id}
      key={id}
      to={`${id}/overview`}
      type="compact"
      title={name}
      caption={caption}
      thumbnails={thumbnailSrc ? [thumbnailSrc] : []}
    />
  );
};
