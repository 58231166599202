import { ethers } from 'ethers';
import React from 'react';
import { UserOperationBuilder } from 'userop/dist/builder';
import { UserOperationEventEvent } from 'userop/dist/typechain/EntryPoint';

import { HedgehogUserAccountUserOperationBuilder } from '@hedgehog/blockchain/erc4337';

export type SendUserOperationCallback = (
  account: HedgehogUserAccountUserOperationBuilder,
) => UserOperationBuilder;

export type SendUserOperationFn = (
  callback: SendUserOperationCallback,
) => Promise<UserOperationEventEvent | null>;

export interface AccountAbstractionContext {
  // The address of the current user's smart contract wallet
  address?: string;
  jsonRpcProvider?: ethers.providers.StaticJsonRpcProvider;
  userOpBuilder?: HedgehogUserAccountUserOperationBuilder;
  sendUserOperation: SendUserOperationFn;
  clear: () => void;
  loading: boolean;
}

export const context = React.createContext<AccountAbstractionContext>({
  address: undefined,
  jsonRpcProvider: undefined,
  userOpBuilder: undefined,
  loading: false,
  clear: () => void 0,
  sendUserOperation: (): Promise<UserOperationEventEvent | null> => {
    throw new Error(
      'AccountAbstractionContext must be within a AccountAbstractionProvider',
    );
  },
});
