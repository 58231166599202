import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import {
  BreadcrumbsLayout,
  BreadcrumbsLayoutContent,
} from '@hedgehog/browser/partners/shared/layouts';
import { useCurrentFund } from '@hedgehog/data-access/partners';
import { Divider } from '@hedgehog/ui/assets';
import { Tabs } from '@hedgehog/ui/tabs';
import { Heading } from '@hedgehog/ui/typography';

import { TabLink } from '../../../components';

const Layout = styled(BreadcrumbsLayout)`
  & ${BreadcrumbsLayoutContent} {
    padding: 2rem;
  }
`;

const FundHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

const FundBody = styled.div``;

export const FundPage = (): JSX.Element | null => {
  const { id, name } = useCurrentFund();

  const loading = !(name && id);
  return (
    <Layout>
      <FundHeader>
        <Heading level="h3" loading={loading}>
          {loading ? 'placeholder' : name}
        </Heading>

        <Divider />

        <Tabs>
          <TabLink loading={loading} to={`/funds/funds/${id}/overview`}>
            Overview
          </TabLink>
          <TabLink loading={loading} to={`/funds/funds/${id}/team-members`}>
            Team Members
          </TabLink>
        </Tabs>
      </FundHeader>
      <FundBody>
        <Outlet />
      </FundBody>
    </Layout>
  );
};
