import styled, { CSSObject } from 'styled-components';

import { Button } from '@hedgehog/ui/buttons';
import { Note } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { CurrencyCoreInput } from '../CurrencyInput/currency-core.input';

export const QuantityWrapper = styled.label`
  background: ${({ theme }): string => theme.colors.grey100};
  padding: ${({ theme }): string => theme.spacing.xsmall};
  border-radius: ${({ theme }): string => theme.radius.large};

  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  color: ${({ theme }): string => theme.colors.black};

  position: relative;
`;

const QuantityButton = styled(Button)<{ disabled: boolean }>`
  display: flex;
  max-height: 2rem;
  max-width: 2rem;
  padding: 0.625rem;
  color: inherit;

  ${({ disabled, theme }): CSSObject => ({
    backgroundColor: theme.colors.white,
    '& svg': {
      opacity: disabled ? 0.2 : 1,
    },
    '&:first-of-type': {
      marginRight: theme.spacing.small,
    },
    '&:last-of-type': {
      marginLeft: theme.spacing.small,
    },
  })}

  & svg {
    opacity: ${({ disabled }): string => (disabled ? '0.2' : '1')};
  }
`;

const QuantityAmountContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  gap: 0;
`;

const QuantityNote = styled(Note)`
  margin-top: -0.25rem;
`;

export interface QuantityInputProps {
  className?: string;
  value?: number;
  name?: string;
  min?: number;
  max?: number;
  onChange: (value?: number) => void;
  noteFormat?: ((quantity: number) => string) | false;
  disabled?: boolean;
  hideButtons?: boolean;
  increment?: number;
  currency?: currencies.Currency;
  suffix?: string;
  placeholder?: string;
}

export const QuantityInput = ({
  min,
  max,
  onChange,
  value,
  name = 'token-quantity-input',
  noteFormat,
  increment = 1,
  currency,
  suffix,
  className,
  placeholder,
  disabled = false,
  hideButtons = false,
}: QuantityInputProps): JSX.Element => {
  const numericalValue = value || 0;

  const updateQuantity = (nextQuantity?: number): void => {
    if (nextQuantity === undefined) {
      return onChange(0);
    }

    if (max !== undefined && max < nextQuantity) {
      if (onChange) onChange(max);
      return onChange(max);
    }

    if (min !== undefined && min > nextQuantity) {
      if (onChange) onChange(min);
      return onChange(min);
    }

    if (onChange) onChange(nextQuantity);
    return onChange(nextQuantity);
  };

  return (
    <QuantityWrapper className={className} htmlFor={name}>
      {!hideButtons && (
        <QuantityButton
          onClick={(): void => updateQuantity(numericalValue - increment)}
          onKeyDown={(): void => updateQuantity(numericalValue - increment)}
          role="presentation"
          className="Clickable"
          data-testid="Asset-minus-button"
          disabled={(min !== undefined && min >= numericalValue) || disabled}
          tabIndex={-1}
          leadingIcon="minus-circle"
        />
      )}
      <QuantityAmountContainer>
        <CurrencyCoreInput
          id={name}
          name={name}
          value={value}
          onChange={onChange}
          currency={currency}
          suffix={suffix}
          placeholder={placeholder}
        />
        {noteFormat && noteFormat(numericalValue) && (
          <QuantityNote color="primary">
            {noteFormat(numericalValue)}
          </QuantityNote>
        )}
      </QuantityAmountContainer>
      {!hideButtons && (
        <QuantityButton
          onClick={(): void => updateQuantity(numericalValue + increment)}
          onKeyDown={(): void => updateQuantity(numericalValue + increment)}
          role="presentation"
          className="Clickable"
          data-testid="Asset-plus-button"
          disabled={(max !== undefined && max <= numericalValue) || disabled}
          tabIndex={-1}
          leadingIcon="plus-circle"
        />
      )}
    </QuantityWrapper>
  );
};
