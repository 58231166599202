import { useState } from 'react';

import {
  TPartner,
  useSendClientWelcomeMutation,
} from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import { Button } from '@hedgehog/ui/buttons';

const BUTTON_COOLDOWN_MS = 4000;

export const ClientWelcomeEmailButton = ({
  client,
  partner,
  onClick,
}: {
  client: PartnerClient | undefined;
  partner: TPartner | undefined;
  onClick?: () => void;
}) => {
  const [sendClientWelcome, { isLoading }] = useSendClientWelcomeMutation();
  const [isChecked, setIsChecked] = useState(false);

  const text = isChecked ? 'Email sent' : 'Send Welcome Email';
  const icon = isChecked ? 'check-circle' : 'send';
  const iconColor = isChecked ? 'success' : 'grey400';
  const disabled = isChecked || !client || !partner;

  const _onClick = () => {
    if (disabled) return;
    if (onClick) onClick();
    setIsChecked(true);
    sendClientWelcome({
      partnerId: partner.id,
      clientId: client.id,
    });

    // TODO: clear timeout when component unmounts
    setTimeout(() => setIsChecked(false), BUTTON_COOLDOWN_MS);
  };

  return (
    <Button
      disabled={disabled}
      onClick={_onClick}
      trailingIcon={icon}
      trailingColor={iconColor}
      loading={isLoading}
      small
    >
      {text}
    </Button>
  );
};
