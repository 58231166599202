import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAppData } from '@hedgehog/data-access/partners';
import { Title } from '@hedgehog/ui/typography';

import {
  ClientProfilePersonalInformationBlock,
  ClientProfileInvestmentMetricsBlock,
} from '../../containers';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const ClientProfilePage = (): JSX.Element | null => {
  const { activePartner } = useAppData();
  const { clientId } = useParams();

  if (!activePartner || !clientId) {
    return null;
  }

  return (
    <Page>
      <Title>Personal information</Title>
      <ClientProfilePersonalInformationBlock
        partnerId={activePartner.id}
        clientId={clientId}
      />
      <Title>Investment overview</Title>
      <ClientProfileInvestmentMetricsBlock
        partnerId={activePartner.id}
        clientId={clientId}
      />
    </Page>
  );
};
