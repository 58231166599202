import styled, { CSSObject } from 'styled-components';

import { Button } from '@hedgehog/ui/buttons';
import { Icon } from '@hedgehog/ui/icons';
import { BottomSheet, BottomSheetContent } from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Modal = styled(BottomSheet)`
  overflow: unset;

  & ${BottomSheetContent} {
    max-width: 23.4375rem;
  }
`;

const StyledIcon = styled(Icon)`
  ${({ theme }): CSSObject => ({
    marginTop: '0.5rem',
    backgroundColor: theme.colors.success,
    borderRadius: '2rem',
  })}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  gap: 1.5rem;
  text-align: center;
`;

export type FormSuccessModalProps = {
  title: string;
  description?: string;
  onClose: () => void;
};

export const FormSuccessModal = ({
  title,
  description,
  onClose,
}: FormSuccessModalProps): JSX.Element => {
  return (
    <Modal>
      <Content>
        <StyledIcon icon="check-circle" size="l" color="white" />
        <Heading level="h3">{title}</Heading>
        <Paragraph>{description}</Paragraph>
        <Button onClick={onClose}>Got it</Button>
      </Content>
    </Modal>
  );
};
