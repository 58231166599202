import { useClientKycQuery } from '@hedgehog/data-access/partners';
import { KycCheckStatus, UUID } from '@hedgehog/shared/types';
import { ActionCard } from '@hedgehog/ui/cards';
import { Icon } from '@hedgehog/ui/icons';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

type ClientKycProps = {
  partnerId: UUID;
  clientId: UUID;
};

const tick = (
  <Icon
    icon="check-circle"
    size="s"
    backgroundColor="transparent"
    color="success"
  />
);

const cross = (
  <Icon
    icon="cross-circle"
    size="s"
    backgroundColor="transparent"
    color="error"
  />
);

const IconStatus = ({ status }: { status?: KycCheckStatus }) => {
  switch (status) {
    case KycCheckStatus.approved:
      return tick;
    case KycCheckStatus.rejected:
    case KycCheckStatus.retry:
      return cross;
    default:
      return null;
  }
};

const Status = ({ status }: { status?: KycCheckStatus }): JSX.Element => {
  const formattedStatus = (
    (status?.[0].toUpperCase() || '') + status?.slice(1)
  ).replace('_', ' ');
  const color = status === KycCheckStatus.rejected ? 'error' : 'grey400';
  return <Paragraph color={color}>{formattedStatus}</Paragraph>;
};

const KycItem = ({
  status,
  title,
}: {
  title: string;
  status?: KycCheckStatus;
}) => (
  <ActionCard
    type={status === KycCheckStatus.approved ? 'light' : 'default'}
    title={title}
    caption={<Status status={status} />}
    trailing={<IconStatus status={status} />}
  />
);

export const ClientKyc = ({ partnerId, clientId }: ClientKycProps) => {
  const { data, isFetching } = useClientKycQuery({
    partnerId,
    clientId,
  });

  if (isFetching || !data) {
    return <Loader />;
  }

  return (
    <>
      <Heading level="h5">
        {data.canInvest ? 'Passed KYC' : 'KYC in progress'}
      </Heading>
      <KycItem title="Verification" status={data.status.verification} />
      <KycItem
        title="Personal information"
        status={data.status.identityCheck}
      />
      <KycItem title="Investor assessment" status={data.status.assessment} />
      <KycItem title="Tax form" status={data.status.taxForm} />
    </>
  );
};
