import { ElementType } from 'react';
import { default as ReactMarkdown } from 'react-markdown';
import { default as remarkBreaks } from 'remark-breaks';
import { default as remarkGfm } from 'remark-gfm';
import styled, { css, CSSObject } from 'styled-components';

export const MarkdownTypography = css`
  h1 {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.h1 })}
  }
  h2 {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.h2 })}
  }
  h3 {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.h3 })}
  }
  h4 {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.h4 })}
  }
  h5 {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.h5 })}
  }
  h6 {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.h6 })}
  }
  p,
  li {
    ${({ theme: { typography } }): CSSObject => ({ ...typography.body })}
    margin-top: 0;
  }
`;

interface MarkdownProps {
  className?: string;
  children: string;
}

export const Markdown = styled(
  <T extends ElementType>({
    children,
    className,
    ...restProps
  }: MarkdownProps &
    Omit<
      React.ComponentPropsWithoutRef<T>,
      keyof MarkdownProps
    >): JSX.Element => {
    return (
      // Prop spreading required here as this is a polymorphic component, and we do not know
      // what props the "as" component has. Essentially, whatever props the component "as"
      // has, we should be able to pipe those props to this component.
      <ReactMarkdown
        className={className}
        remarkPlugins={[remarkGfm, remarkBreaks]}
        {...restProps}
      >
        {children}
      </ReactMarkdown>
    );
  },
)`
  display: flex;
  flex-flow: column nowrap;

  ${MarkdownTypography}

  & *:first-child {
    margin-top: 0;
  }

  & *:last-child {
    margin-bottom: 0;
  }
`;

export const MarkdownText = Markdown;
