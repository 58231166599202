export type SourceKind = 'image' | 'video';

export type MimeType = 'video/mp4' | 'video/webm' | 'image/jpeg' | 'image/png';

export type Source = {
  src: string;
  kind: SourceKind;
  alt?: string | null;
  heading?: string | null;
  text?: string | null;
  thumbnailSrc?: string | null;
};

export type ImageSource = Source;

export type VideoSource = Source & {
  thumbnailSrc: string;
};

export interface ResourceSourceMap {
  image: ImageSource;
  video: VideoSource;
}

export type ResourceSource<SK extends SourceKind = SourceKind> =
  ResourceSourceMap[SK];

export function isVideoResource(
  value: unknown,
): value is ResourceSource<'video'> {
  return (
    typeof value === 'object' &&
    value !== null &&
    (value as { kind: string }).kind === 'video'
  );
}
