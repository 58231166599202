import { css } from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';

export const hoverAppearance = css<{ $hoverColor?: ThemeColorName | string }>`
  box-shadow: inset 0 0 0 4px
    ${({ theme, $hoverColor }): string =>
      theme.colors[$hoverColor ?? ''] || theme.colors.grey100};
`;

export const alternativeHoverAppearance = css<{
  $hoverColor?: ThemeColorName | string;
}>`
  box-shadow: inset 0 0 0 4px
    ${({ theme, $hoverColor }): string =>
      theme.colors[$hoverColor ?? ''] || theme.colors.shadow200};
`;
