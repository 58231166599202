import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import {
  useCapitalCallNoticesBlockchainQuery,
  useCapitalCallBlockchainQuery,
  useFundBlockchainQuery,
  useAppData,
  useClientsQuery,
} from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import { CustomCard } from '@hedgehog/ui/cards';
import { ProgressBar } from '@hedgehog/ui/charts';
import { InputGroup } from '@hedgehog/ui/inputs';
import { Pill } from '@hedgehog/ui/pills';
import { Heading, Label, Paragraph, Span } from '@hedgehog/ui/typography';
import { currencies, weiToUsd } from '@hedgehog/utils/formats';

import { Table, TableHeader } from '../../../components';
import { CapitalCallNoticeSettleButton } from '../../../containers';

const Card = styled(CustomCard)`
  padding: 2rem;
`;

const FieldGroups = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ProgressData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
`;

const PendingPill = styled(Pill)`
  ${({ theme }) => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.grey100,
  })}
`;

const SettledPill = styled(Pill)`
  ${({ theme }) => ({
    backgroundColor: theme.colors.tree200,
  })}
`;

export const CapitalCallPage = () => {
  const params = useParams();
  const { activePartner } = useAppData();
  const capitalCallId = parseInt(params.capitalCallId || '');
  const { data: capitalCall, loading: callsLoading } =
    useCapitalCallBlockchainQuery(capitalCallId);
  const { data: notices, loading: noticesloading } =
    useCapitalCallNoticesBlockchainQuery({
      capitalCallId,
    });
  const { data: fund, loading: fundLoading } = useFundBlockchainQuery(
    capitalCall?.fundId || 0,
  );
  const { data: partnerClients, isFetching } = useClientsQuery(
    {
      partnerId: activePartner ? activePartner.id : '',
    },
    {
      skip: !activePartner,
    },
  );
  const [totalSettled, setTotalSettled] = useState<ethers.BigNumber>(
    ethers.BigNumber.from(0),
  );

  useEffect(() => {
    if (notices) {
      // calculate settled amount by summing the amount for all of the notices where dateSettled is > 0
      const settledAmount = notices.reduce<ethers.BigNumber>((acc, notice) => {
        if (notice.dateSettled) {
          return acc.add(notice.amount);
        }
        return acc;
      }, ethers.BigNumber.from(0));

      setTotalSettled(settledAmount);
    }
  }, [notices]);

  const isLoading = fundLoading || noticesloading || callsLoading;
  return (
    <PageColumnLayout title="Capital call">
      <Card>
        <FieldGroups>
          <InputGroup>
            <Label color="grey400">Fund</Label>
            <Paragraph loading={isLoading}>
              {fund?.fundName ?? 'placeholder'}
            </Paragraph>
          </InputGroup>
          <InputGroup>
            <Label color="grey400">Call Date</Label>
            <Paragraph loading={isLoading}>July 14 2023</Paragraph>
          </InputGroup>
          <InputGroup>
            <Label color="grey400">Notice reference</Label>
            <Paragraph loading={isLoading}>
              Call for GreenTech, Q1 2023
            </Paragraph>
          </InputGroup>
        </FieldGroups>
        <ProgressContainer>
          <ProgressData>
            <InputGroup>
              <Label color="grey400">Total paid</Label>
              <Heading level="h3" color="success" loading={isLoading}>
                {currencies.formatMoney(weiToUsd(totalSettled.toString()), {
                  minimumFractionDigits: 2,
                })}
              </Heading>
            </InputGroup>
            <InputGroup>
              <Label color="grey400">Total commitment</Label>
              <Heading level="h3" loading={isLoading}>
                {capitalCall
                  ? currencies.formatMoney(
                      weiToUsd(capitalCall.amount.toString()),
                      {
                        minimumFractionDigits: 2,
                      },
                    )
                  : '0,000,000.00'}
              </Heading>
            </InputGroup>
          </ProgressData>
          <ProgressBar
            value={
              capitalCall
                ? weiToUsd(totalSettled.toString()) /
                  weiToUsd(capitalCall.amount.toString())
                : 0
            }
            color="success"
          />
        </ProgressContainer>
      </Card>
      <Card>
        <Heading level="h6">Investors</Heading>
        <Table>
          <thead>
            <tr>
              <TableHeader>
                <Paragraph small>Client</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Amount</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Status</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Action</Paragraph>
              </TableHeader>
            </tr>
          </thead>
          <tbody>
            {notices &&
              fund &&
              notices.map((notice) => {
                const { limitedPartnerHedgehogRef } = notice;
                const client: PartnerClient | undefined =
                  partnerClients?.data.find(
                    (entity) => entity.codeHash === limitedPartnerHedgehogRef,
                  );
                return (
                  <tr key={limitedPartnerHedgehogRef}>
                    <td>
                      {client?.user
                        ? `${client.user.firstName} ${client.user.lastName}`
                        : limitedPartnerHedgehogRef}
                    </td>
                    <td>
                      {currencies.formatMoney(
                        weiToUsd(notice.amount.toString()),
                        {
                          minimumFractionDigits: 2,
                        },
                      )}
                    </td>
                    <td>
                      {notice.dateSettled ? (
                        <SettledPill>Paid</SettledPill>
                      ) : (
                        <PendingPill>Pending</PendingPill>
                      )}
                    </td>
                    <td>
                      {!notice.dateSettled && (
                        <CapitalCallNoticeSettleButton
                          fundRef={fund.hedgehogFundRef}
                          notice={notice}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            {isLoading && (
              <tr>
                <td>
                  <Span loading>First Name</Span> <Span loading>Last Name</Span>
                </td>
                <td>
                  <Span loading>0,000.00</Span>
                </td>
                <td>
                  <Span loading>Placeholder</Span>
                </td>
                <td>
                  <Span loading>Action</Span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card>
    </PageColumnLayout>
  );
};
