import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useAppData } from '@hedgehog/data-access/partners';

import { ClientKyc } from '../../containers';

const Page = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const ClientKycPage = (): JSX.Element | null => {
  const { activePartner } = useAppData();
  const { clientId } = useParams();

  if (!activePartner || !clientId) {
    return null;
  }

  return (
    <Page>
      <ClientKyc partnerId={activePartner.id} clientId={clientId} />
    </Page>
  );
};
