import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { LinkOrNotProps } from '@hedgehog/ui/cards';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

type AlignProperty = 'flex-start' | 'flex-end' | 'space-between';

export const CardListItemRowBase = styled.div<{ align?: AlignProperty }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  ${({ align }): CSSProp => ({
    justifyContent: align ? align : undefined,
  })}
`;

export type RowContent =
  | [string, string]
  | [string, ReactNode]
  | [ReactNode, ReactNode]
  | ReactNode;

export type RowContentObject = {
  content: RowContent;
  align?: AlignProperty;
};

export type CardListItemRowProps = StandardProps<
  {
    content: RowContentObject | RowContent;
    loading?: boolean;
  } & LinkOrNotProps
>;

export const CardListItemRow = styled(
  ({
    content,
    loading = false,
    ...linkProps
  }: CardListItemRowProps): JSX.Element => {
    const [label, value, standalone] = Array.isArray(content)
      ? content
      : [undefined, undefined, content];

    const isStandaloneObject =
      standalone && typeof standalone === 'object' && 'content' in standalone;
    return (
      <CardListItemRowBase
        as={linkProps.href ? 'a' : linkProps.to ? Link : undefined}
        align={
          isStandaloneObject
            ? standalone.align
            : standalone
            ? 'flex-end'
            : undefined
        }
        {...linkProps}
      >
        {typeof label === 'string' ? (
          <Paragraph small weight="400" color="grey400">
            {label}
          </Paragraph>
        ) : (
          label
        )}
        {typeof value === 'string' ? (
          <Paragraph weight="400" loading={loading}>
            {value}
          </Paragraph>
        ) : (
          value
        )}
        {standalone
          ? typeof standalone === 'object' && 'content' in standalone
            ? standalone.content
            : standalone
          : null}
      </CardListItemRowBase>
    );
  },
)``;
