import {
  useClientInvestmentMetricsQuery,
  useClientOrdersQuery,
} from '@hedgehog/data-access/partners';
import { Loader } from '@hedgehog/ui/loaders';
import { StandardProps } from '@hedgehog/ui/utils';

import { PortfolioOverview } from '../../patterns';

export interface ClientPortfolioProps {
  partnerId: string;
  clientId: string;
}

export const ClientPortfolio = ({
  clientId,
  partnerId,
  ...props
}: StandardProps<ClientPortfolioProps>): JSX.Element => {
  const credentials = {
    partnerId,
    clientId,
  };
  const { data: orders, isFetching: fetchingOrders } =
    useClientOrdersQuery(credentials);
  const { data: metrics } = useClientInvestmentMetricsQuery(credentials);

  if (fetchingOrders || !metrics) {
    return <Loader />;
  }

  return (
    <PortfolioOverview
      totalAmount={metrics ? metrics.totalInvestmentAmount.amount : 0}
      differenceAmount={metrics ? metrics.totalYield.amount : 0}
      orders={orders}
      {...props}
    />
  );
};
