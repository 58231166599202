import { TokenHolderBalance } from '@hedgehog/data-access/partners';
import { currencies } from '@hedgehog/utils/formats';

type CreateIncomeReportBreakdownTableRowProps = {
  totalIncomeAmount: number;
  totalSupply: number;
  balance: TokenHolderBalance;
};

export const CreateIncomeReportBreakdownTableRow = ({
  totalIncomeAmount,
  totalSupply,
  balance,
}: CreateIncomeReportBreakdownTableRowProps): JSX.Element => {
  return (
    <tr key={balance.address}>
      <td>
        {balance.userFirstName && balance.userLastName
          ? `${balance.userFirstName} ${balance.userLastName}`
          : balance.address}
      </td>
      <td>
        {totalSupply
          ? currencies.formatMoney(
              (totalIncomeAmount * balance.tokensHeld) / totalSupply,
              {
                minimumFractionDigits: 2,
              },
            )
          : 'N/A'}
      </td>
    </tr>
  );
};
