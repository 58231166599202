import { ComponentProps } from 'react';
import styled, { CSSProp } from 'styled-components';

import { FundamentalsTheme } from '@hedgehog/ui/themes';

export type FormDividerProps = ComponentProps<'div'> & {
  spacing?: keyof FundamentalsTheme['spacing'];
};

export const FormDivider = styled.div<FormDividerProps>`
  border-bottom: 1px solid;
  ${({ theme, spacing = 'xxlarge' }): CSSProp => ({
    borderBottomColor: theme.colors.grey200,
    margin: `${theme.spacing[spacing]} 0`,
  })}
`;
