import { CSSObject, css, keyframes } from 'styled-components';

const shimmerKeyframes = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

export const shimmerGradient = css`
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    animation-duration: 1800ms;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: ${shimmerKeyframes};
    animation-timing-function: linear;
    ${({ theme }): CSSObject => ({
      background: theme.colors.grey100,
    })}
    ${({ theme }): CSSObject => ({
      background: `linear-gradient(to right, ${theme.colors.grey100} 4%, ${theme.colors.grey200} 38%, ${theme.colors.grey100} 54%)`,
    })}
  background-size: 1000px 640px;
  }
`;
