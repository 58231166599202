import { ContentMarkdownBlock } from '@hedgehog/shared/types';
import { MarkdownText } from '@hedgehog/ui/typography';

type CMSMarkdownProps = {
  block: ContentMarkdownBlock;
};

export const CMSMarkdownBlock = ({ block }: CMSMarkdownProps): JSX.Element => {
  const { content } = block;
  return <MarkdownText>{content}</MarkdownText>;
};
