import styled from 'styled-components';

import { Icon } from '@hedgehog/ui/icons';
import { StandardProps } from '@hedgehog/ui/utils';

import { Pill } from '../Pill/Pill';

const StyledPill = styled(Pill)`
  ${({ theme }) => ({
    backgroundColor: 'transparent',
    color: theme.colors.grey500,
    border: `1px solid ${theme.colors.grey300}`,
    borderRadius: '0.375rem',
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.25rem 0.375rem 0.25rem 0.3758rem',
    fontWeight: 400,
  })}
`;

const StyledIcon = styled(Icon)`
  ${({ theme }) => ({
    backgroundColor: 'transparent',
    width: '1rem',
    height: '1rem',
  })}
`;

type FilterPillProps = StandardProps<
  {
    text: string;
    onClose: () => void;
  },
  never
>;

export const FilterPill = ({
  text,
  onClose,
  className,
}: FilterPillProps): JSX.Element => {
  return (
    <StyledPill className={className}>
      {text} <StyledIcon icon="cross-circle" onClick={onClose} />
    </StyledPill>
  );
};
