import styled, { css, useTheme } from 'styled-components';

import { ContentMapBlock } from '@hedgehog/shared/types';
import { GalleryImage } from '@hedgehog/ui/galleries';
import { CircleIcon } from '@hedgehog/ui/icons';

import { useContentParameters } from '../../ContentContext';

const Map = styled.div`
  height: 11.25rem;
`;

const StyledGalleryImage = styled(GalleryImage)`
  max-width: none;
  overflow: visible;
  filter: grayscale(1);

  transition: box-shadow 200ms ease-out;
  box-shadow: 0 0 0 0 rgba(0, 4, 24, 0), 0 0 0 0 rgba(0, 0, 8, 0);
  :hover {
    box-shadow: 0 4px 24px 0 rgba(0, 4, 24, 0.1), 0 0 8px 0 rgba(0, 0, 8, 0.15);
  }
`;

const MapAnchor = styled.a`
  position: relative;
  display: block;
`;

const pulse = css`
  @keyframes pulse-out {
    0% {
      box-shadow: 0px 0px 0px 0.25rem rgba(0, 0, 0, 0.2);
    }

    100% {
      box-shadow: 0px 0px 0px 1rem rgba(0, 0, 0, 0);
    }
  }

  animation: pulse-out 2s infinite ease-in;
  animation-direction: alternate-reverse;
`;

const MapPin = styled(CircleIcon)`
  ${pulse}

  display: block;
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-sizing: content-box;
  width: 1rem;
  height: 1rem;
  max-width: 1rem;
  max-height: 1rem;
  border-radius: 50%;

  will-change: box-shadow;
`;

type CMSMapBlockProps = {
  block: ContentMapBlock;
};

export const CMSMapBlock = ({ block }: CMSMapBlockProps): JSX.Element => {
  const theme = useTheme();
  const { imageUrl, mapUrl } = block;
  const { theme: colorName } = useContentParameters();
  return (
    <Map>
      <MapAnchor href={mapUrl} target="_blank" rel="noreferrer noopener">
        <StyledGalleryImage src={imageUrl} />
        <MapPin
          fill={(colorName && theme.colors[colorName]) || 'currentColor'}
          stroke={theme.colors.white}
        />
      </MapAnchor>
    </Map>
  );
};
