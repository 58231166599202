import { BigNumberish, BytesLike, ethers } from 'ethers';
import { UserOperationBuilder, UserOperationMiddlewareFn } from 'userop';
import { EntryPoint } from 'userop/dist/typechain';
import {
  HedgehogUserAccountFactory,
  HedgehogUserAccountFactory__factory,
  HedgehogUserAccount as HedgehogUserAccountImpl,
  HedgehogUserAccount__factory,
} from '../../typechain-types';
import { EntryPoint__factory } from '@account-abstraction/contracts';
import {
  EOASignature,
  estimateUserOperationGas,
} from 'userop/dist/preset/middleware';

export class HedgehogUserAccountUserOperationBuilder extends UserOperationBuilder {
  private signer: ethers.Wallet;
  private provider: ethers.providers.JsonRpcProvider;
  private entryPoint: EntryPoint;
  private factory: HedgehogUserAccountFactory;
  private initCode: string;
  proxy: HedgehogUserAccountImpl;

  private constructor(
    signer: ethers.Wallet,
    ERC4337NodeRpc: string,
    entryPoint: string,
    factory: string,
  ) {
    super();
    this.signer = signer;
    this.provider = new ethers.providers.JsonRpcProvider(ERC4337NodeRpc);
    this.entryPoint = EntryPoint__factory.connect(entryPoint, this.provider);
    this.factory = HedgehogUserAccountFactory__factory.connect(
      factory,
      this.provider,
    );
    this.initCode = '0x';
    this.proxy = HedgehogUserAccount__factory.connect(
      ethers.constants.AddressZero,
      this.provider,
    );
  }

  private resolveAccount: UserOperationMiddlewareFn = async (ctx) => {
    ctx.op.nonce = await this.entryPoint.getNonce(ctx.op.sender, 0);
    ctx.op.initCode = ctx.op.nonce.eq(0) ? this.initCode : '0x';
  };

  public static async init(
    signer: ethers.Wallet,
    ERC4337NodeRpc: string,
    entryPoint: string,
    factory: string,
    paymasterMiddleware?: UserOperationMiddlewareFn,
  ): Promise<HedgehogUserAccountUserOperationBuilder> {
    const instance = new HedgehogUserAccountUserOperationBuilder(
      signer,
      ERC4337NodeRpc,
      entryPoint,
      factory,
    );

    try {
      instance.initCode = await ethers.utils.hexConcat([
        instance.factory.address,
        instance.factory.interface.encodeFunctionData('createAccount', [
          await instance.signer.getAddress(),
          ethers.BigNumber.from(0),
        ]),
      ]);
      await instance.entryPoint.callStatic.getSenderAddress(instance.initCode);

      throw new Error('getSenderAddress: unexpected result');
    } catch (error: any) {
      const addr = error?.errorArgs?.sender;
      if (!addr) throw error;

      instance.proxy = HedgehogUserAccount__factory.connect(
        addr,
        instance.provider,
      );
    }

    const base = instance
      .useDefaults({
        sender: instance.proxy.address,
        preVerificationGas: '0x30d40',
        verificationGasLimit: '0x30d40',
        callGasLimit: '0x30d40',
        maxFeePerGas: '0x30d40',
        maxPriorityFeePerGas: '0x30d40',
        signature: await instance.signer.signMessage(
          ethers.utils.arrayify(ethers.utils.keccak256('0xdead')),
        ),
      })
      .useMiddleware(instance.resolveAccount);

    const withPM = paymasterMiddleware
      ? base.useMiddleware(paymasterMiddleware)
      : base.useMiddleware(estimateUserOperationGas(instance.provider));

    return withPM.useMiddleware(EOASignature(instance.signer));
  }

  execute(to: string, value: BigNumberish, data: BytesLike) {
    return this.setCallData(
      this.proxy.interface.encodeFunctionData('execute', [to, value, data]),
    );
  }

  executeBatch(to: Array<string>, data: Array<BytesLike>) {
    return this.setCallData(
      this.proxy.interface.encodeFunctionData('executeBatch', [to, data]),
    );
  }
}
