import { gql } from '@apollo/client';

export const GET_SUBSCRIPTION_AGREEMENT = gql`
  query SubscriptionAgreement($orderId: ID!) {
    subscriptionAgreement(args: { orderId: $orderId }) {
      agreement {
        userId
        orderId
        envelopeId
        status
      }
      subscriptionAgreementUrl
    }
  }
`;
