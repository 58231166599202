import styled, { CSSProp, css } from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';

export const LiveIcon = styled.span<{ color?: ThemeColorName }>`
  position: relative;
  display: inline-block;

  padding: 0.4375rem;
  box-sizing: border-box;

  ${({ color: colorName, theme }): CSSProp => {
    const color = theme.colors[colorName ?? 'secondary'];
    return css`
      &:after {
        content: '';
        display: block;
        background-color: ${color};

        width: 0.625rem;
        height: 0.625rem;
        max-width: 0.625rem;
        max-height: 0.625rem;
        border-radius: 50%;
      }

      &:before {
        position: absolute;
        border-radius: 50%;
        content: '';
        background-color: ${color};
        transform: scale(1);
        animation: pulse 2s infinite;
        width: 1.5rem;
        height: 1.5rem;
        top: 0;
        left: 0;
      }
    `;
  }}
`;
