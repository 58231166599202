import React from 'react';

import {
  EventObject,
  EventObjectAction,
  PageCategory,
  PageName,
} from './types';

export interface AnalyticsContextProps {
  track: <E extends EventObject, EA extends EventObjectAction<E>>(
    object: E,
    action: EA,
    properties?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) => void;
  page: <PC extends PageCategory, PN extends PageName<PC>>(
    category: PC,
    name: PN,
    properties?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) => void;
  identify: (
    id: string | Record<string, unknown>,
    traits?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) => void;
}

export const AnalyticsContext = React.createContext<AnalyticsContextProps>({
  track: () => null,
  page: () => null,
  identify: () => null,
});
