import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { ZeroDevWalletProvider } from '@hedgehog/browser/shared/account-abstraction';
import {
  useAppData,
  usePartnerSettingsQuery,
} from '@hedgehog/data-access/partners';
import { PrimaryButton, SecondaryButton } from '@hedgehog/ui/buttons';
import { Column, MobileColumn } from '@hedgehog/ui/layouts';
import { useModal } from '@hedgehog/ui/modals';
import { fromBreakpoint } from '@hedgehog/ui/themes';

import {
  ClientRewardsModal,
  ClientsList,
  CreateClientModal,
} from '../../containers';

const ClientsColumn = styled(Column)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  ${({ theme }) => ({
    flexGrow: 0,
    ...fromBreakpoint(theme.breakpoints.tablet, { flexBasis: '24rem' }),
  })}
`;

export const ClientsPage = (): JSX.Element => {
  const { openAndResolveWithSubmitValue: showCreateModal } = useModal(
    (modalProps) => (
      <ZeroDevWalletProvider>
        <CreateClientModal {...modalProps} />
      </ZeroDevWalletProvider>
    ),
  );
  const { openAndResolveWithSubmitValue: showRewardsModal } = useModal(
    (modalProps) => <ClientRewardsModal {...modalProps} />,
  );

  const { activePartner } = useAppData();

  const { data: partnerSettings } = usePartnerSettingsQuery({
    partnerId: activePartner?.id ?? '',
  });

  return (
    <PageColumnLayout
      title="Clients"
      actions={
        <>
          <PrimaryButton
            small
            icon="plus-circle"
            onClick={() => showCreateModal()}
          >
            Add Client
          </PrimaryButton>

          {partnerSettings?.REWARDS && (
            <SecondaryButton
              small
              icon="plus-circle"
              onClick={() => showRewardsModal()}
            >
              Rewards
            </SecondaryButton>
          )}
        </>
      }
    >
      <ClientsColumn size="minmax(min-content, 20rem)">
        <ClientsList search />
      </ClientsColumn>
      <MobileColumn match="/clients/:clientId/*">
        <Outlet />
      </MobileColumn>
    </PageColumnLayout>
  );
};
