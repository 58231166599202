import { useContext, useMemo } from 'react';

import context from './context';

export type UseMarketListingLoadersOutput = {
  areClientsLoading: boolean;
  areFundsLoading: boolean;
};

export const useMarketListingLoaders = (): UseMarketListingLoadersOutput => {
  const { clientsLoading, fundsLoading } = useContext(context);

  return useMemo(
    () => ({
      areClientsLoading: clientsLoading,
      areFundsLoading: fundsLoading,
    }),
    [clientsLoading, fundsLoading],
  );
};
