import { DeepPartial } from '@reduxjs/toolkit';
import deepmerge from 'deepmerge';

import { lightTheme, oakglenTheme } from './presets';
import { Theme } from './theme.interface';

export const presets = {
  oakglen: oakglenTheme,
  light: lightTheme,
};

export type PresetThemeKey = keyof typeof presets;

export const isThemeKey = (theme: string): theme is PresetThemeKey => {
  return Object.keys(presets).includes(theme);
};

export const convertToThemeKey = (theme: string): PresetThemeKey => {
  return isThemeKey(theme) ? theme : 'light';
};

export interface useThemeWithOverridesProps {
  theme?: PresetThemeKey;
  overrides?: DeepPartial<Theme> | null;
}

const mergeWithoutNulls = (
  a: { [x: string]: any } | null,
  b: { [x: string]: any } | null,
): { [x: string]: any } => {
  if (a === null || b === null) return {};
  if (typeof a !== 'object' || typeof b !== 'object') return b;
  const keys = new Set([...Object.keys(a), ...Object.keys(b)]);
  return Array.from(keys).reduce((acc, key) => {
    return { ...acc, [key]: b[key] ?? a[key] };
  }, {});
};

export const useThemeWithOverrides = ({
  theme = 'light',
  overrides = {},
}: useThemeWithOverridesProps): Theme => {
  return deepmerge(presets[theme], overrides ?? {}, {
    customMerge: (key) => {
      if (key === 'colors') {
        return mergeWithoutNulls;
      }
      return;
    },
  }) as Theme;
};
