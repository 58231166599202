export const BusinessIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0.0529785H0V14.053H2V10.053H4V14.053H14V8.05298H10V0.0529785ZM4 2.05298H2V4.05298H4V2.05298ZM8 2.05298H6V4.05298H8V2.05298ZM6 6.05298H8V8.05298H6V6.05298ZM10 10.053H12V12.053H10V10.053Z"
      fill="currentColor"
    />
  </svg>
);
