import { useEnvironment } from '@hedgehog/ui/environment';

import { TokenHolderBalance } from '../types';

import {
  CreateIncomeReportRequest,
  useCreateIncomeReportUserOperation,
} from './use-create-income-report-user-op.hook';
import { useRecipientBalances } from './use-recipient-balances.hook';

type ReportIncomeFn = (
  amount: number,
  title: string,
  description: string,
) => Promise<void>;

interface Metadata {
  success: boolean;
  submitting: boolean;
  balances: TokenHolderBalance[] | undefined;
  totalSupply: number | undefined;
}

export const useCreateIncomeReport = ({
  myWalletAddress,
  partnerId,
  assetId,
}: {
  myWalletAddress: string;
  partnerId: string;
  assetId: string;
}): [ReportIncomeFn, Metadata] => {
  const {
    tenantToken: { address: tenantTokenContractAddress },
  } = useEnvironment();

  const { balances, totalSupply } = useRecipientBalances(
    myWalletAddress || '',
    partnerId,
    assetId || '',
  );
  const [createIncomeReport, { success, loading: submitting }] =
    useCreateIncomeReportUserOperation();

  const reportIncome: ReportIncomeFn = async (
    amount: number,
    title: string,
    description: string,
  ) => {
    if (!balances?.length) return;
    if (!totalSupply) return;

    await createIncomeReport({
      reports: balances
        .filter((balance) => balance.tokensHeld !== 0)
        .map((balance): CreateIncomeReportRequest => {
          return {
            tokenAddress: tenantTokenContractAddress,
            beneficiaryAddress: balance.address,
            amount: Math.floor(
              ((amount * balance.tokensHeld) / totalSupply) * 100,
            ), // x100 to convert to the lowest denomination
            currency: 1, // USD in solidity enum
            title,
            description,
          };
        }),
    });
  };

  return [reportIncome, { balances, totalSupply, success, submitting }];
};
