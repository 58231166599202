import { MouseEventHandler } from 'react';
import styled, { CSSObject } from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { Chunk } from '../chunk/chunk.component';
import { IconType, icons } from '../icons';

const Container = styled.div<Omit<AvatarProps, 'icon'>>`
  ${({
    size,
    color,
    backgroundColor = '',
    backgroundOpacity,
    theme,
  }): CSSObject => {
    let computedBackgroundColor =
      theme.colors[backgroundColor] || theme.colors.secondary;

    if (backgroundOpacity) {
      computedBackgroundColor = `color-mix(in srgb, ${computedBackgroundColor} ${backgroundOpacity}, transparent)`;
    }

    return {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      width: size === 's' ? '2rem' : size === 'm' ? '2.5rem' : '3.5rem',
      height: size === 's' ? '2rem' : size === 'm' ? '2.5rem' : '3.5rem',
      color: color ? theme.colors[color] : theme.colors.white,
      backgroundColor: computedBackgroundColor,
    };
  }};
`;

export type AvatarSize = 's' | 'm' | 'l';

type IconAvatarProps = {
  icon?: IconType | string;
  src?: never;
};

type ImageAvatarProps = {
  src?: string;
  icon?: never;
};

export type AvatarProps = StandardProps<
  {
    size?: AvatarSize;
    backgroundColor?: ThemeColorName;
    backgroundOpacity?: string;
    color?: ThemeColorName;
    onClick?: MouseEventHandler<HTMLDivElement>;
  } & (IconAvatarProps | ImageAvatarProps)
>;

export const Avatar = styled(
  ({
    icon: iconTypeOrText,
    src: srcOrNothing,
    size = 'm',
    color,
    backgroundColor,
    className,
    ...otherProps
  }: AvatarProps): JSX.Element | null => {
    return (
      <Container
        size={size}
        color={color}
        backgroundColor={backgroundColor}
        className={className}
        {...otherProps}
      >
        {srcOrNothing && <Chunk src={srcOrNothing} size={size} />}
        {iconTypeOrText &&
          (iconTypeOrText in icons ? (
            <Chunk icon={iconTypeOrText as IconType} size={size} />
          ) : (
            <Heading level={size === 's' ? 'h7' : 'h6'}>
              {iconTypeOrText}
            </Heading>
          ))}
      </Container>
    );
  },
)``;
