import styled, { CSSObject } from 'styled-components';

import {
  useAppData,
  useOnboardingMetricsQuery,
} from '@hedgehog/data-access/partners';

import { ClientOnboardingMetric } from '../../components';

const DashboardWidgetTileContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  overflow-x: scroll;
  padding-left: 1.5rem;
  padding-right: 8rem;

  & > div {
    flex-basis: 0;
    flex-grow: 1;
  }

  ${({ theme }): CSSObject => ({
    [`@media only screen and (min-width: ${theme.breakpoints.desktop}px)`]: {
      overflowX: 'unset',
      paddingLeft: 'unset',
      paddingRight: 'unset',
    },
  })}
`;

export const OnboardingMetrics = (): JSX.Element => {
  const { activePartner } = useAppData();
  const { data: metrics, isLoading } = useOnboardingMetricsQuery(
    activePartner?.id || '',
    {
      skip: !activePartner?.id,
      refetchOnMountOrArgChange: true,
    },
  );

  const totalClients = metrics?.totalClients || 0;
  const signedNda = metrics?.signedNda || 0;
  const acceptedJurisdictionWarning = metrics?.acceptedJurisdictionWarning || 0;
  const choseInvestorType = metrics?.choseInvestorType || 0;
  const requestedInfo = metrics?.requestedInfo || 0;
  return (
    <DashboardWidgetTileContainer>
      <ClientOnboardingMetric
        loading={isLoading}
        title="Clients"
        metric={totalClients}
        progress={1}
        clientsRoute="/clients"
      />
      <ClientOnboardingMetric
        showProgress
        loading={isLoading}
        title="Signed NDA"
        metric={signedNda}
        progress={totalClients ? signedNda / totalClients : 0}
        clientsRoute="/clients?declarations=NDA"
        clientsDropOffRoute="/clients?missingDeclarations=NDA"
      />
      <ClientOnboardingMetric
        showProgress
        loading={isLoading}
        title="Signed investor letter"
        metric={acceptedJurisdictionWarning}
        progress={
          totalClients && signedNda
            ? acceptedJurisdictionWarning / signedNda
            : 0
        }
        clientsRoute="/clients?declarations=JURISDICTION_WARNING"
        clientsDropOffRoute="/clients?declarations=NDA&missingDeclarations=JURISDICTION_WARNING"
      />
      <ClientOnboardingMetric
        showProgress
        loading={isLoading}
        title="Selected investor type"
        metric={choseInvestorType}
        progress={
          totalClients && acceptedJurisdictionWarning
            ? choseInvestorType / acceptedJurisdictionWarning
            : 0
        }
        clientsRoute="/clients?declarations=INVESTOR_TYPE"
        clientsDropOffRoute="/clients?declarations=JURISDICTION_WARNING&missingDeclarations=INVESTOR_TYPE"
      />
      <ClientOnboardingMetric
        showProgress
        loading={isLoading}
        title="Requested info"
        metric={requestedInfo}
        progress={
          totalClients && choseInvestorType
            ? requestedInfo / choseInvestorType
            : 0
        }
        clientsRoute="/clients?requestedDocs=true"
        clientsDropOffRoute="/clients?declarations=INVESTOR_TYPE&requestedDocs=false"
      />
    </DashboardWidgetTileContainer>
  );
};
