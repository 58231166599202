export const UsersIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2.5C8 3.88071 6.88071 5 5.5 5C4.11929 5 3 3.88071 3 2.5C3 1.11929 4.11929 0 5.5 0C6.88071 0 8 1.11929 8 2.5Z"
      fill="currentColor"
    />
    <path d="M3 7C1.34315 7 0 8.34315 0 10V13H8V7H3Z" fill="currentColor" />
    <path
      d="M13 7H10V13H16V10C16 8.34315 14.6569 7 13 7Z"
      fill="currentColor"
    />
    <path
      d="M12 5C13.1046 5 14 4.10457 14 3C14 1.89543 13.1046 1 12 1C10.8954 1 10 1.89543 10 3C10 4.10457 10.8954 5 12 5Z"
      fill="currentColor"
    />
  </svg>
);

export default UsersIcon;
