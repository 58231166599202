import {
  FetchArgs,
  FetchBaseQueryArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query/fetchBaseQuery';
import { BaseQueryFn, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';

import { RootStore } from '../reducer';
import { logout } from '../reducers';

import { isRequestErrorResponse } from './is-request-response-error.guard';

const prepareHeaders: FetchBaseQueryArgs['prepareHeaders'] = (
  headers,
  { getState },
) => {
  const authToken = (getState() as RootStore).auth.token;
  if (authToken) {
    headers.set('Authorization', authToken);
  }
  return headers;
};

export const createBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const version = (extraOptions as { version: string })?.version || 'v1';
  const baseUrl = (api.getState() as RootStore).settings.apiBaseUrl + version;
  const fetch = fetchBaseQuery({
    baseUrl,
    prepareHeaders,
  });
  const result = await fetch(args, api, extraOptions);
  if (isRequestErrorResponse(result.error) && result.error.status === 403) {
    api.dispatch(logout());
  }
  return result;
};
