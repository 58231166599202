export const SettingsIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 0.0529785H7L6.44943 2.25526C5.9349 2.39252 5.44758 2.59645 4.9973 2.85724L3.05026 1.68901L1.63605 3.10323L2.80427 5.05026C2.54348 5.50055 2.33954 5.98787 2.20228 6.50241L0 7.05298V9.05298L2.20228 9.60355C2.33954 10.1181 2.54347 10.6054 2.80427 11.0557L1.63605 13.0027L3.05026 14.4169L4.99729 13.2487C5.44757 13.5095 5.93489 13.7134 6.44943 13.8507L7 16.053H9L9.55057 13.8507C10.0651 13.7134 10.5524 13.5095 11.0027 13.2487L12.9498 14.4169L14.364 13.0027L13.1957 11.0557C13.4565 10.6054 13.6605 10.1181 13.7977 9.60355L16 9.05298V7.05298L13.7977 6.50241C13.6605 5.98787 13.4565 5.50056 13.1957 5.05027L14.364 3.10322L12.9498 1.68901L11.0027 2.85724C10.5524 2.59645 10.0651 2.39252 9.55057 2.25526L9 0.0529785ZM8 10.053C9.10457 10.053 10 9.15755 10 8.05298C10 6.94841 9.10457 6.05298 8 6.05298C6.89543 6.05298 6 6.94841 6 8.05298C6 9.15755 6.89543 10.053 8 10.053Z"
      fill="currentColor"
    />
  </svg>
);
