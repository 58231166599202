import { ForwardedRef, forwardRef } from 'react';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { ThemeColorName, shimmerGradient } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

const CustomNote = styled.p<NoteProps>`
  ${({ color, align, theme: { colors, typography } }): CSSObject => ({
    ...typography.note,
    textAlign: align,
    color: color ? colors[color] : undefined,
  })}

  ${({ loading }): CSSProp => (loading ? shimmerGradient : {})}

  letter-spacing: 0;
  margin: 0;
`;

export type NoteProps = StandardProps<{
  loading?: boolean;
  color?: ThemeColorName;
  align?: 'left' | 'center' | 'right';
  /**
   * @deprecated
   * Use {color} instead
   */
  textColor?: ThemeColorName;
}>;

export const Note = forwardRef(
  (
    { textColor, color = textColor, align, className, children }: NoteProps,
    ref: ForwardedRef<HTMLParagraphElement>,
  ): JSX.Element => {
    return (
      <CustomNote ref={ref} color={color} align={align} className={className}>
        {children}
      </CustomNote>
    );
  },
);
