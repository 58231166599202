import { useAppData, useClientQuery } from '@hedgehog/data-access/partners';
import { PartnerClientInvestorType } from '@hedgehog/data-access/partners-types';
import { Tag } from '@hedgehog/ui/tags';
import { StandardProps } from '@hedgehog/ui/utils';

export type ClientHeaderTagsProps = StandardProps<
  {
    clientId: string;
  },
  never
>;

const investorTypeMap: Record<PartnerClientInvestorType, string> = {
  ['EVERYDAY']: 'Everyday',
  ['SOPHISTICATED']: 'Sophisticated',
  ['HIGH_NET_WORTH']: 'Hight Net Worth',
  ['ELECTIVE_PROFESSIONAL']: 'Elective Professional',
};

export const ClientHeaderTags = ({
  clientId,
}: ClientHeaderTagsProps): JSX.Element => {
  const { activePartner } = useAppData();
  const { data: client, isLoading } = useClientQuery(
    {
      partnerId: activePartner?.id || '',
      clientId,
    },
    {
      skip: !activePartner,
    },
  );

  return (
    <>
      {client?.user?.country && (
        <Tag loading={isLoading}>{client.user.country}</Tag>
      )}
      {client?.investorType && (
        <Tag loading={isLoading}>
          {investorTypeMap[client.investorType] ?? 'Unknown'}
        </Tag>
      )}
    </>
  );
};
