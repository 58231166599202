import { gql } from '@apollo/client';

import { ContentBlockDataFragment } from '../content';

export const GET_TOPIC = gql`
  query GetTopic($slug: String!) {
    topic(args: { slug: $slug }) {
      id
      courseId
      slug
      title
      durationInMinutes
      progress
      content {
        ...ContentBlockDataFragment
      }
      upNext {
        id
        slug
        title
        progress
      }
    }
  }
  ${ContentBlockDataFragment}
`;
