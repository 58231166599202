import { useEffect, useState } from 'react';

import {
  createPasswordValidation,
  InvalidPasswordReason,
  PasswordValidationOptions,
} from '@hedgehog/utils/validation';

export interface UsePasswordParams {
  password: string;
  options?: PasswordValidationOptions;
}

export type UsePasswordReturn = {
  valid: boolean;
  invalid: boolean;
  dirty: boolean;
  pristine: boolean;
  errors: InvalidPasswordReason[];
  enableValidation: () => void;
  disableValidation: () => void;
};

export const usePassword = ({
  password,
  options,
}: UsePasswordParams): UsePasswordReturn => {
  const validatePassword = createPasswordValidation(options);
  const [dirty, setDirty] = useState(false);
  const [enabled, toggle] = useState<boolean>(false);
  const [errors, changeErrors] = useState<InvalidPasswordReason[]>([]);

  const enableValidation = (): void => toggle(true);
  const disableValidation = (): void => toggle(false);

  useEffect(() => {
    if (!enabled) return;
    if (!dirty) setDirty(true);

    changeErrors(validatePassword(password));
  }, [password, enabled]);

  return {
    errors,
    dirty,
    pristine: !dirty,
    invalid: errors.length > 0,
    valid: errors.length === 0,
    enableValidation,
    disableValidation,
  };
};
