import { ReactNode } from 'react';
import styled, { CSSObject, CSSProp, css } from 'styled-components';

import {
  Button,
  OutlineButton,
  PrimaryButton,
  SmallButton,
} from '@hedgehog/ui/buttons';
import { HeaderButton, HeaderWithClose } from '@hedgehog/ui/headers';
import { BottomSheet, BottomSheetContent } from '@hedgehog/ui/modals';
import { toBreakpoint } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { spacing } from '@hedgehog/utils/sizes';

import { ModalErrorNote } from '../modal-error-note/modal-error-note.component';

const ModalWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`;

const ModalSheet = styled(BottomSheet)`
  overflow: unset;

  & ${BottomSheetContent} {
    display: flex;
    max-height: 90vh;
    max-width: 40rem;
    overflow: hidden;
    flex: 1 1 100%;
  }
`;

const CommonMargins = css`
  padding: 1.5rem;
`;

const Topbar = styled(HeaderWithClose)`
  ${CommonMargins};

  flex: 0 0 auto;
  max-height: 5rem;
  border-bottom: 1px solid;
  background-color: unset;

  ${({ theme }): CSSProp => ({
    borderBottomColor: theme.colors.grey200,
  })}
`;

export const ModalFormContent = styled.div`
  ${CommonMargins};

  flex: 1 1 auto;
  overflow-y: auto;
`;

const Footbar = styled.div`
  ${CommonMargins};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;

  flex: 0 0 auto;
  border-top: 1px solid;
  ${({ theme }): CSSProp => ({
    borderTopColor: theme.colors.grey200,
  })}
  gap: ${spacing.xs};

  & > ${SmallButton} {
    flex: 0 0 auto;
    max-width: unset;
    width: auto;
  }
`;

export const FootbarActions = styled.div`
  display: flex;
  flex-flow: row wrap;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.xsmall,
    ...toBreakpoint(theme.breakpoints.tablet, {
      width: '100%',
      flexFlow: 'column-reverse wrap',
    }),
  })}
`;

const FootbarNotes = styled.div`
  ${({ theme }): CSSObject => ({
    gap: theme.spacing.xsmall,
  })}
`;

export type ModalProps = StandardProps<{
  submitModal?: () => void;
  cancelModal?: () => void;
  closeModal?: () => void;
  backModal?: () => void;
  modalSubmitted?: boolean;
  disabled?: boolean;
  loading?: boolean;
  heading?: string;
  actions?: ReactNode[] | ReactNode;
  notes?: ReactNode[] | ReactNode;
  submitButtonText?: string;
  closeButtonText?: string;
  secondActionButtonText?: string;
  error?: string;
  footbar?: boolean;
}>;

export const ModalForm = styled(
  ({
    submitModal,
    cancelModal,
    closeModal,
    backModal,
    modalSubmitted,
    disabled,
    loading,
    heading,
    actions,
    notes,
    submitButtonText,
    closeButtonText,
    secondActionButtonText,
    error,
    children,
    className,
    footbar = true,
  }: ModalProps): JSX.Element => {
    const predefinedNotes = error ? (
      <ModalErrorNote error={error} />
    ) : undefined;

    const handleSubmit = () => {
      if (submitModal) submitModal();
    };

    const handleClose = () => {
      if (closeModal) closeModal();
    };

    const handleCancel = () => {
      if (cancelModal) cancelModal();
    };

    const predefinedActions = !modalSubmitted ? (
      <>
        {secondActionButtonText && (
          <OutlineButton disabled onClick={handleCancel}>
            {secondActionButtonText}
          </OutlineButton>
        )}

        {submitButtonText && (
          <PrimaryButton
            onClick={handleSubmit}
            disabled={disabled}
            loading={loading}
          >
            {submitButtonText}
          </PrimaryButton>
        )}
        {!submitButtonText && closeButtonText && (
          <Button onClick={handleClose}>{closeButtonText}</Button>
        )}
      </>
    ) : (
      <OutlineButton onClick={handleClose}>{closeButtonText}</OutlineButton>
    );

    return (
      <ModalSheet className={className}>
        <ModalWrapper>
          {!heading ? null : (
            <Topbar
              onClose={handleClose}
              left={
                backModal ? (
                  <HeaderButton onClick={backModal} icon="arrow-left" />
                ) : undefined
              }
            >
              <Heading level="h5">{heading}</Heading>
            </Topbar>
          )}
          <ModalFormContent>{children}</ModalFormContent>
          {footbar && (
            <Footbar>
              <FootbarNotes>{notes ? notes : predefinedNotes}</FootbarNotes>

              <FootbarActions>
                {actions ? actions : predefinedActions}
              </FootbarActions>
            </Footbar>
          )}
        </ModalWrapper>
      </ModalSheet>
    );
  },
)``;
