import { gql } from '@apollo/client';

export const GET_ASSET_ROUND_RETURNS = gql`
  query GetAssetRoundClassReturns($roundClassId: String!) {
    assetRoundClassReturns(roundClassId: $roundClassId) {
      id
      roundClassId
      price {
        initial
        final
      }
      period {
        actual
        projected
      }
      order {
        id
        status
      }
      income {
        yield
        gain
      }
    }
  }
`;
