import { MouseEvent, MouseEventHandler } from 'react';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import {
  NativeMobileExperiences,
  sendMessageToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';

import { HeaderButton } from './header-button.component';
import { Header, HeaderProps } from './header.component';

const EmptyIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;
`;

export type HeaderWithBackProps = {
  to?: To;
  onBack?: MouseEventHandler;
} & Omit<HeaderProps, 'left'>;

export const HeaderWithBack = styled(
  ({
    onBack,
    to,
    right = <EmptyIcon />,
    ...headerProps
  }: HeaderWithBackProps): JSX.Element => {
    const isNativeExperience = useIsNativeMobileExperience(
      NativeMobileExperiences.PAGE_HEADER_CLOSE,
    );

    const handleClick = (event: MouseEvent): void => {
      if (isNativeExperience) {
        sendMessageToApp('navigation.goBack');
        return;
      }
      onBack && onBack(event);
    };

    return (
      <Header
        left={
          <HeaderButton icon="arrow-left" to={to || -1} onClick={handleClick} />
        }
        right={right}
        {...headerProps}
      />
    );
  },
)``;
