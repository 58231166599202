import styled, { CSSProp, css } from 'styled-components';

import { ratioToHex } from '@hedgehog/ui/themes';

import { Button } from '../Button/button.component';
import { ButtonBase } from '../button-base.component';
import { LinkButton } from '../LinkButton/link-button.component';
import { alternativeHoverAppearance } from '../styles';

const PrimaryBase = css`
  &${ButtonBase} {
    ${({ theme }): CSSProp => ({
      color: theme.colors.white,
      backgroundColor: theme.colors.primary,
    })}

    &[disabled]:not([disabled='false']) {
      ${({ theme }): CSSProp => ({
        backgroundColor: `${theme.colors.primary}${ratioToHex(0.2)}`,
      })};
    }

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      ${alternativeHoverAppearance}
    }
  }
`;

export const PrimaryButton = styled(Button).attrs({ $hoverColor: 'shadow100' })`
  ${PrimaryBase};
`;

export const PrimaryLinkButton = styled(LinkButton).attrs({
  $hoverColor: 'shadow100',
})`
  ${PrimaryBase};
`;
