import { DeepPartial } from '@reduxjs/toolkit';
import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import {
  CSSProp,
  ThemeProvider as InternalThemeProvider,
  createGlobalStyle,
} from 'styled-components';

import { Theme } from './theme.interface';
import {
  PresetThemeKey,
  useThemeWithOverrides,
} from './use-theme-with-overrides.hook';

const GlobalStyleVariables = createGlobalStyle`
  html {
    ${({ theme }): CSSProp => ({
      '--color-primary': theme.colors.primary,
      '--color-secondary': theme.colors.secondary,
    })};
  }
`;

export interface ThemeProviderProps {
  theme?: PresetThemeKey;
  overrides?: DeepPartial<Theme>;
  children?: ReactNode | ReactNode[];
}

export const ThemeProvider = ({
  theme: themeName = 'light',
  overrides = {},
  children,
}: ThemeProviderProps): JSX.Element => {
  const theme = useThemeWithOverrides({ theme: themeName, overrides });

  return (
    <InternalThemeProvider theme={theme}>
      <Helmet>
        <meta name="theme-color" content={theme.colors.white} />
      </Helmet>
      <GlobalStyleVariables />
      {children}
    </InternalThemeProvider>
  );
};
