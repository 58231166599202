import styled, { CSSProp, css } from 'styled-components';

import { Button } from '../Button/button.component';
import { LinkButton } from '../LinkButton/link-button.component';
import { alternativeHoverAppearance } from '../styles';

export const OutlineBase = css`
  color: inherit;
  border: 2px solid ${({ theme }) => theme.colors.black};
  background-color: transparent; // default background color in browsers (required for anchors)

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: transparent;
    ${alternativeHoverAppearance}
  }
`;

export const OutlineLightBase = css`
  color: inherit;
  border: 2px solid;
  ${({ theme }): CSSProp => ({ borderColor: theme.colors.shadow100 })}
  background-color: transparent; // default background color in browsers (required for anchors)

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: transparent;
    ${alternativeHoverAppearance}
  }
`;

export const OutlineButton = styled(Button)`
  ${OutlineBase}
`;
export const OutlineLinkButton = styled(LinkButton)`
  ${OutlineBase}
`;

export const OutlineLightButton = styled(Button)`
  ${OutlineLightBase}
`;

export const OutlineLightLinkButton = styled(LinkButton)`
  ${OutlineLightBase}
`;
