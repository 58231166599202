interface CircleIconProps {
  fill?: string;
  stroke?: string;
  className?: string;
}

export const CircleIcon = (props: CircleIconProps): JSX.Element => (
  <svg
    className={props.className}
    width="100%"
    height="100%"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10"
      cy="10"
      r="9"
      fill={props.fill || 'currentColor'}
      stroke={props.stroke || 'currentColor'}
      stroke-width="2"
    />
  </svg>
);
