import React, { ForwardedRef, ReactNode } from 'react';

import { Paragraph } from '@hedgehog/ui/typography';

import { BlockContainer } from '../BlockContainer/BlockContainer';

type EmptyStateContainerProps = {
  children: ReactNode;
};

export const EmptyStateContainer = React.forwardRef(
  (
    { children }: EmptyStateContainerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <BlockContainer ref={ref}>
        <Paragraph color="grey400">{children}</Paragraph>
      </BlockContainer>
    );
  },
);
