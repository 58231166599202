import styled from 'styled-components';

import { Icon } from '@hedgehog/ui/icons';
import { shimmerGradient } from '@hedgehog/ui/themes';
import { Heading, HeadingProps } from '@hedgehog/ui/typography';

const Name = styled(Heading)`
  display: flex;
  align-items: center;
  ${({ theme }) => ({
    gap: theme.spacing.xxsmall,
  })}
`;

const SuccessIcon = styled(Icon)`
  width: 1rem;
  height: 1rem;
  ${({ theme }) => ({
    color: theme.colors.success,
    backgroundColor: 'transparent',
  })};
`;

const Span = styled.span<{ loading?: boolean }>`
  ${({ loading }) => (loading ? shimmerGradient : '')}
`;

type ClientNameProps = {
  firstName: string;
  lastName: string;
  level: HeadingProps['level'];
  loading?: boolean;
  canInvest?: boolean;
  className?: string;
};

export const ClientName = ({
  firstName,
  lastName,
  level,
  canInvest = false,
  loading = false,
  className,
}: ClientNameProps) => {
  return (
    <Name level={level} className={className}>
      <Span loading={loading}>{firstName}</Span>{' '}
      <Span loading={loading}>{lastName}</Span>
      {canInvest && <SuccessIcon icon="check-circle" size="s" />}
    </Name>
  );
};
