import { gql } from '@apollo/client';

export const GET_ASSET = gql`
  query GetAsset($path: String!) {
    asset(args: { path: $path }) {
      id
      title
      address
      path
      category
      assetCategory
      underlyingAssetCount
      isTenantToken
      themes {
        category
      }
      sponsorId
      sponsor {
        id
        name
        slug
      }
      thumbnails {
        src
        kind
        alt
        thumbnailSrc
      }
      pages {
        id
        slug
        title
        order
      }
      details {
        assetSummary {
          title
          address
          resources {
            src
            kind
            alt
            thumbnailSrc
            heading
            text
          }
        }
      }
      currentRound {
        id
        totalAmount
        fundedTotalAmount
        settings {
          investorLimitPct
          investorLimitAmount
        }
        roundClass {
          id
          status
          pricePerToken
          currency
          minimumInvestmentAmount
          hasTaxLeakage
          liveAt
          fundedAt
          ongoingAt
          paidOutAt
          createdAt
          updatedAt
          metrics {
            id
            metricType
            value
          }
          isTokenized
        }
      }
    }
    order(args: { path: $path }) {
      id
      quantity
      amount
      status
    }
  }
`;
