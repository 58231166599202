import { ReactNode } from 'react';
import styled, { CSSObject, RuleSet } from 'styled-components';

import { ThemeColorName, shimmerGradient } from '@hedgehog/ui/themes';

export const CustomHeading = styled.h1<HeadingProps>`
  margin: 0;

  ${({ align, color, level, theme }): CSSObject => ({
    ...theme.typography[level ? level : 'h1'],
    color: color ? theme.colors[color] : undefined,
    textAlign: align,
  })}
  ${({ fontSize }): CSSObject => (fontSize ? { fontSize } : {})}
  ${({ loading }): RuleSet<object> | '' => (loading ? shimmerGradient : '')}
`;

export interface HeadingProps {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7';
  color?: ThemeColorName;
  align?: 'left' | 'center' | 'right';
  fontSize?: string;
  className?: string;
  loading?: boolean;
  children?: ReactNode | ReactNode[];
  onClick?: () => void;
}

export const Heading = styled(
  ({
    level,
    children,
    loading = false,
    ...props
  }: HeadingProps): JSX.Element => {
    return (
      <CustomHeading
        loading={loading}
        as={level || 'h1'}
        level={level}
        {...props}
      >
        {children}
      </CustomHeading>
    );
  },
)``;

export const Heading1 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h1" {...props} />
);
export const Heading2 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h2" {...props} />
);
export const Heading3 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h3" {...props} />
);
export const Heading4 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h4" {...props} />
);
export const Heading5 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h5" {...props} />
);
export const Heading6 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h6" {...props} />
);
export const Heading7 = (props: Omit<HeadingProps, 'level'>): JSX.Element => (
  <Heading level="h7" {...props} />
);
