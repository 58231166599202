export const CrossIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.17172 8L0.585938 3.41421L3.41436 0.585785L8.00015 5.17157L12.5859 0.585785L15.4144 3.41421L10.8286 8L15.4144 12.5858L12.5859 15.4142L8.00015 10.8284L3.41436 15.4142L0.585938 12.5858L5.17172 8Z"
      fill="currentColor"
    />
  </svg>
);

export default CrossIcon;
