import { GetCountriesResponse } from '@hedgehog/data-access/country-types';

import { baseApi } from './base.api';

export const countriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    countries: builder.query<GetCountriesResponse['data'], void>({
      query: () => 'countries',
      transformResponse: (response: GetCountriesResponse) => response.data,
    }),
  }),
});

export const { useCountriesQuery, useLazyCountriesQuery } = countriesApi;
