import { ContentBlockType } from '@hedgehog/data-access/graphql';
import {
  ContentMarkdownBlock,
  ContentTitleBodyBlock,
  ContentKeyValueListBlock,
  ContentBulletListBlock,
  ContentTimelineBlock,
  ContentTenantsBlock,
  ContentTabsBlock,
  ContentRisksAndMitigationsBlock,
  ContentYoutubeBlock,
  ContentMapBlock,
  ContentDownloadActionCardsBlock,
  ContentGalleryBlock,
  ContentQuestionBlock,
  ContentHighlightCardBlock,
  ContentDisclaimerBlock,
  ContentBlockData,
  ContentMetricsBlock,
} from '@hedgehog/shared/types';

import {
  CMSBulletListBlock,
  CMSDisclaimerBlock,
  CMSDownloadCardsBlock,
  CMSGalleryBlock,
  CMSHighlightCardBlock,
  CMSKeyValueListBlock,
  CMSMapBlock,
  CMSMarkdownBlock,
  CMSMetricsBlock,
  CMSQuestionBlock,
  CMSRisksAndMitigationsBlock,
  CMSTabsBlock,
  CMSTenantsBlock,
  CMSTimelineBlock,
  CMSTitleBodyBlock,
  CMSYoutubeBlock,
} from './components';

export interface ContentBlockProps {
  loading?: boolean;
  type: ContentBlockType;
  data: ContentBlockData;
}

export const ContentBlock = ({
  loading,
  data,
  type,
}: ContentBlockProps): JSX.Element => {
  switch (type) {
    case ContentBlockType.Markdown:
      return <CMSMarkdownBlock block={data as ContentMarkdownBlock} />;
    case ContentBlockType.TitleAndBody:
      return <CMSTitleBodyBlock block={data as ContentTitleBodyBlock} />;
    case ContentBlockType.KeyValueList:
      return <CMSKeyValueListBlock block={data as ContentKeyValueListBlock} />;
    case ContentBlockType.BulletList:
      return <CMSBulletListBlock block={data as ContentBulletListBlock} />;
    case ContentBlockType.Timeline:
      return <CMSTimelineBlock block={data as ContentTimelineBlock} />;
    case ContentBlockType.Tenants:
      return <CMSTenantsBlock block={data as ContentTenantsBlock} />;
    case ContentBlockType.Tabs:
      return <CMSTabsBlock block={data as ContentTabsBlock} />;
    case ContentBlockType.RisksAndMitigations:
      return (
        <CMSRisksAndMitigationsBlock
          block={data as ContentRisksAndMitigationsBlock}
        />
      );
    case ContentBlockType.Youtube:
      return <CMSYoutubeBlock block={data as ContentYoutubeBlock} />;
    case ContentBlockType.Map:
      return <CMSMapBlock block={data as ContentMapBlock} />;
    case ContentBlockType.DownloadActionCards:
      return (
        <CMSDownloadCardsBlock
          loading={loading}
          block={data as ContentDownloadActionCardsBlock}
        />
      );
    case ContentBlockType.Gallery:
      return <CMSGalleryBlock block={data as ContentGalleryBlock} />;
    case ContentBlockType.Question:
      return <CMSQuestionBlock block={data as ContentQuestionBlock} comments />;
    case ContentBlockType.HighlightCard:
      return (
        <CMSHighlightCardBlock block={data as ContentHighlightCardBlock} />
      );
    case ContentBlockType.Disclaimer:
      return <CMSDisclaimerBlock block={data as ContentDisclaimerBlock} />;
    case ContentBlockType.Metrics:
      return <CMSMetricsBlock block={data as unknown as ContentMetricsBlock} />;
    default:
      return <></>;
  }
};
