import { useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useMediaQuery } from '@hedgehog/data-access/hooks';
import {
  useAppData,
  useAssetPagesQuery,
  useAssetsQuery,
} from '@hedgehog/data-access/partners';

export const AssetPageNavigateTo = (): JSX.Element => {
  const { activePartnerId: partnerId = '' } = useAppData();
  const theme = useTheme();
  const navigate = useNavigate();

  const assetIdMatch = useMatch('/assets/:assetId');
  const overviewMatch = useMatch('/assets/:assetId/overview');
  const comingSoonMatch = useMatch('/assets/:assetId/coming-soon');
  const slugMatch = useMatch('/assets/:assetId/:slug');
  const { matches: isTabletOrAbove } = useMediaQuery(
    `only screen and (min-width: ${theme.breakpoints.tablet}px)`,
  );

  const { params = {} } = assetIdMatch ||
    slugMatch || { params: { assetId: null } };

  const { data: assets, ...assetsQuery } = useAssetsQuery(
    { partnerId },
    { skip: !partnerId },
  );

  const { data: pages, ...pagesQuery } = useAssetPagesQuery(
    {
      assetId: params.assetId ?? '',
      partnerId,
    },
    { skip: !params.assetId || !partnerId },
  );

  useEffect(() => {
    if (!isTabletOrAbove) return;
    if (params.assetId) {
      if (overviewMatch) return;
      if (comingSoonMatch) return;
      if (slugMatch) return;
      if (!pages) return;
      if (pagesQuery.isFetching) return;
      const assetPages = [...pages].sort((a, b) => a.order - b.order);
      const slug = `${params.assetId}/${assetPages[0]?.slug}`;
      if (slug) navigate(`/assets/${slug}`);
    } else if (assets) {
      if (assetsQuery.isFetching) return;
      const [primary] = assets;
      navigate(`/assets/${primary.id}`);
    }
  }, [
    isTabletOrAbove,
    assetIdMatch,
    slugMatch,
    assets,
    pages,
    pagesQuery.isFetching,
  ]);

  return <Outlet />;
};
