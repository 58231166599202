import { ethers } from 'ethers';
import { useEffect } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import {
  QueryResult,
  useCachedCallback,
  useQueryResult,
} from '@hedgehog/browser/shared/utils';

import { useFundManagerContract } from './use-fund-manager-contract.hook';

export type CapitalCallNotice = {
  id: number;
  limitedPartnerHedgehogRef: string;
  capitalCallId: number;
  amount: ethers.BigNumber;
  date: number;
  dueDate: number;
  dateAcknowledged: number;
  dateSettled: number;
  data: string;
};

type Params = {
  capitalCallId: number;
};

export const useCapitalCallNoticesBlockchainQuery = ({
  capitalCallId,
}: Params): QueryResult<CapitalCallNotice[]> => {
  const fundManagerContract = useFundManagerContract();
  const { address, jsonRpcProvider } = useAccountAbstraction();
  const [state, controls] = useQueryResult<CapitalCallNotice[]>();

  const fetchCapitalCallNotices = useCachedCallback(
    async (): Promise<CapitalCallNotice[]> => {
      // fetch all capital call notice events for the given capital call id
      const oldIssuedEvents = await jsonRpcProvider!.getLogs({
        address: fundManagerContract.address,
        fromBlock: 0,
        toBlock: 'latest',
        topics: [
          ethers.utils.id(
            'CapitalCallNoticeIssued(uint32,uint32,bytes32,bytes32,uint256,uint256,uint256,uint256,bytes)',
          ),
          null,
          ethers.utils.hexZeroPad(ethers.utils.hexlify(capitalCallId), 32),
        ],
      });

      const newIssuedEvents = await jsonRpcProvider!.getLogs({
        address: fundManagerContract.address,
        fromBlock: 0,
        toBlock: 'latest',
        topics: [
          ethers.utils.id(
            'CapitalCallNoticeIssuedWithIsFund(uint32,uint32,bytes32,bytes32,uint256,uint256,uint256,uint256,bytes,bool)',
          ),
          null,
          ethers.utils.hexZeroPad(ethers.utils.hexlify(capitalCallId), 32),
        ],
      });

      const issuedEvents = [...oldIssuedEvents, ...newIssuedEvents];

      const decodedIssuedEvents = issuedEvents.map((event) =>
        fundManagerContract.interface.parseLog(event),
      );

      const notices = decodedIssuedEvents.map(
        async ({
          args: {
            id,
            capitalCallId,
            amount,
            limitedPartnerHedgehogRef,
            date,
            dueDate,
            data,
          },
        }) => {
          // check for settled events for the notice
          const settledEvents = await jsonRpcProvider!.getLogs({
            address: fundManagerContract.address,
            fromBlock: 0,
            toBlock: 'latest',
            topics: [
              ethers.utils.id('CapitalCallNoticeSettled(uint32,uint256)'),
              ethers.utils.hexZeroPad(ethers.utils.hexlify(id), 32),
            ],
          });

          const decodedSettledEvents = settledEvents.map((event) =>
            fundManagerContract.interface.parseLog(event),
          );

          return {
            id,
            capitalCallId,
            amount,
            limitedPartnerHedgehogRef,
            date: date.toNumber(),
            dueDate: dueDate.toNumber(),
            dateAcknowledged: 0,
            dateSettled:
              decodedSettledEvents.length > 0
                ? decodedSettledEvents[0].args['date'].toNumber()
                : 0,
            data: ethers.utils.toUtf8String(data),
          };
        },
      );

      return Promise.all(notices);
    },
    { key: `capitalCallNoticesQuery(${capitalCallId})` },
  );

  const fetch = async (): Promise<void> => {
    try {
      if (!address) return;
      if (!jsonRpcProvider) return;

      controls.setLoading(true);

      const notices = await fetchCapitalCallNotices();

      controls.setData(notices);
      controls.setError(null);
    } catch (error) {
      controls.setData(null);
      controls.setError(error);
    } finally {
      controls.setLoading(false);
    }
  };

  useEffect(() => {
    if (!address) return;
    if (!fundManagerContract) return;

    fetch();
  }, [fetchCapitalCallNotices, fundManagerContract, address]);

  return state;
};
