import * as Sentry from '@sentry/react';
import styled from 'styled-components';

import {
  ContentDownloadActionCardsBlock,
  ContentDownloadCardsBlockItem,
} from '@hedgehog/shared/types';
import { ActionCard } from '@hedgehog/ui/cards';
import { spacing } from '@hedgehog/utils/sizes';

import { useCMSAnalyticsTrack, useCMSDocumentClick } from '../../providers';

const GapContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.md};
`;

type CMSDownloadCardsBlockProps = {
  loading?: boolean;
  block: ContentDownloadActionCardsBlock;
};

const extractMetadataFromUrl = (url: string): Record<string, string> => {
  const metadata: Record<string, string> = {};
  const assetRegex = /assets\/(.*?)\//;

  try {
    const assetRegexMatches = url.match(assetRegex);
    if (assetRegexMatches) {
      metadata['asset'] = assetRegexMatches[1];
    }
    return metadata;
  } catch (error) {
    Sentry.captureMessage(`Could not extract Download metadata from url`);
    return metadata;
  }
};

export const CMSDownloadCardsBlock = ({
  loading = false,
  block,
}: CMSDownloadCardsBlockProps): JSX.Element => {
  const track = useCMSAnalyticsTrack();
  const { onDocumentClick } = useCMSDocumentClick();
  const { items } = block;

  const handleClick = async (
    item: ContentDownloadCardsBlockItem,
  ): Promise<void> => {
    const urlMetadata = extractMetadataFromUrl(item.url);
    const metadata = {
      title: item.title,
      url: item.url,
      ...urlMetadata,
    };
    track('Download', 'Clicked', metadata);
    onDocumentClick && onDocumentClick(item);
  };

  return (
    <GapContainer>
      {items
        .filter((item) => item.url)
        .map((item) => {
          return (
            <a
              key={item.url}
              href={item.url}
              target="_blank"
              rel="noreferrer"
              download
            >
              <ActionCard
                leadingIcon="download"
                loading={loading}
                title={item.title}
                caption={item.information}
                onClick={(): Promise<void> => handleClick(item)}
              />
            </a>
          );
        })}
    </GapContainer>
  );
};
