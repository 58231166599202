import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UUID } from '@hedgehog/shared/types';

import { TPartner } from '../../../types';
import { partnersApi } from '../../apis';

export interface SettingsReducerState {
  apiBaseUrl: string;
  activePartnerId?: UUID;
}

const initialState: SettingsReducerState = {
  apiBaseUrl: '',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addMatcher(
      partnersApi.endpoints.partners.matchFulfilled,
      (state: SettingsReducerState, action: PayloadAction<TPartner[]>) => {
        // The first partner in the partners array will be considered the active partner. This assumption can be
        // made because the system currently is limited to associating a user with only 1 partner.
        if (action.payload?.length) {
          state.activePartnerId = action.payload[0].id;
        }
      },
    );
  },
});

export const settingsReducer = settingsSlice.reducer;
