import { useParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAppData, useTeamMemberQuery } from '@hedgehog/data-access/partners';
import { RoleCapability } from '@hedgehog/shared/types';
import { Button } from '@hedgehog/ui/buttons';
import { GenericCard } from '@hedgehog/ui/cards';
import { useModal } from '@hedgehog/ui/modals';
import { Pill } from '@hedgehog/ui/pills';
import { Heading, Label } from '@hedgehog/ui/typography';

import { ActivationState, TeamMemberActivationModal } from '..';
import { TeamMemberDetail } from '../../components';
import { Restrict } from '../restrict/restrict.container';

const Container = styled(GenericCard)`
  height: 100%;
`;

const TeamMemberHeader = styled.div`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    gap: theme.spacing.xxsmall,
    justifyContent: 'space-between',
  })}
`;

const TeamMemberBody = styled.div`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
  })}
`;

const Labels = styled.div`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    gap: theme.spacing.small,
  })}
`;

const ActivationBtn = styled(Button)`
  margin-top: 0.5rem;
  width: fit-content;
`;

export const TeamMemberDetails = (): JSX.Element => {
  const { teamMemberId } = useParams();
  const { activePartner, teamMember: userTeamMember } = useAppData();

  const { data: teamMember } = useTeamMemberQuery({
    teamMemberId: teamMemberId || '',
    partnerId: activePartner?.id || '',
  });

  const { openAndResolveWithSubmitValue: showActivationModal } = useModal(
    ({ submitModal, cancelModal }) => (
      <TeamMemberActivationModal
        state={
          teamMember?.disabledAt
            ? ActivationState.Inactive
            : ActivationState.Active
        }
        firstName={teamMember?.firstName || ''}
        lastName={teamMember?.lastName || ''}
        submitModal={submitModal}
        cancel={cancelModal}
        teamMemberId={teamMemberId || ''}
        partnerId={activePartner?.id || ''}
      />
    ),
  );

  const activationVerb = teamMember?.disabledAt ? 'Reactivate' : 'Deactivate';

  return (
    <Container
      title={
        <TeamMemberHeader>
          <Heading level="h4">
            {teamMember?.firstName} {teamMember?.lastName}
          </Heading>
          <Labels>
            <Pill small borderRadius="4px" color="grey100">
              <Label color="grey400">{teamMember?.role?.name}</Label>
            </Pill>
            {teamMember?.disabledAt ? (
              <Pill small color="errorLight" borderRadius="4px">
                <Label color="error">Inactive</Label>
              </Pill>
            ) : null}
          </Labels>
        </TeamMemberHeader>
      }
      info={
        <TeamMemberBody>
          <TeamMemberDetail name={'Email'} value={teamMember?.email || ''} />
          <TeamMemberDetail
            name={'Invited by'}
            value={teamMember?.invitedBy || 'Hedgehog'}
          />
          <TeamMemberDetail
            name={'Member since'}
            value={
              teamMember
                ? new Date(teamMember.createdAt.toString()).toLocaleDateString(
                    'en-gb',
                    {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    },
                  )
                : ''
            }
          />
          {teamMember?.disabledAt ? (
            <TeamMemberDetail
              name={'Deactivated'}
              color="error"
              value={
                teamMember
                  ? new Date(
                      teamMember.disabledAt.toString(),
                    ).toLocaleDateString('en-gb', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })
                  : ''
              }
            />
          ) : null}
          <Restrict capability={RoleCapability.ADMIN}>
            {userTeamMember?.teamMemberId !== teamMemberId && (
              <ActivationBtn small onClick={showActivationModal}>
                {activationVerb} member...
              </ActivationBtn>
            )}
          </Restrict>
        </TeamMemberBody>
      }
    />
  );
};
