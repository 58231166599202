import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useCurrentFunds } from './funds.provider';

type FundProviderContextProperties = {
  id?: string;
  name?: string;
  description?: string;
  hashedBlockchainReference?: string;
  blockchainId?: number;
};

export const FundProviderContext = createContext<FundProviderContextProperties>(
  {
    id: undefined,
    name: undefined,
    description: undefined,
    hashedBlockchainReference: undefined,
  },
);

export const useCurrentFund = (): FundProviderContextProperties => {
  const context = useContext(FundProviderContext);
  if (context === undefined) {
    throw new Error('useFund must be used within a FundProviderContext');
  }
  return context;
};

type FundProviderProps = {
  children: ReactNode;
};

export const FundProvider = ({ children }: FundProviderProps): JSX.Element => {
  // Load the current fund id from url params
  const { fundId } = useParams();

  // Load all of the funds using the funds provider
  const { funds } = useCurrentFunds();

  const [fundData, setFundData] = useState<FundProviderContextProperties>({
    id: undefined,
    name: undefined,
    description: undefined,
    hashedBlockchainReference: undefined,
  });

  useEffect(() => {
    if (!funds?.length) return;
    const fund = funds.find((fund) => fund.id === fundId);
    setFundData(fund);
  }, [funds, fundId]);

  return (
    <FundProviderContext.Provider
      value={{
        ...fundData,
      }}
    >
      {children}
    </FundProviderContext.Provider>
  );
};
