import { TokenHolderBalance } from '@hedgehog/data-access/partners';
import { Paragraph } from '@hedgehog/ui/typography';

import { Table, TableHeader } from '../../components';

import { CreateIncomeReportBreakdownTableRow } from './create-income-report-breakdown-table-row.container';

type CreateIncomeReportBreakdownTableProps = {
  totalIncomeAmount: number;
  totalSupply: number;
  balances: TokenHolderBalance[];
  className?: string;
};

export const CreateIncomeReportBreakdownTable = ({
  totalIncomeAmount,
  totalSupply,
  balances,
  className,
}: CreateIncomeReportBreakdownTableProps): JSX.Element => {
  return (
    <Table className={className}>
      <thead>
        <tr>
          <TableHeader>
            <Paragraph small>Beneficiary</Paragraph>
          </TableHeader>
          <TableHeader>
            <Paragraph small>Amount</Paragraph>
          </TableHeader>
        </tr>
      </thead>
      <tbody>
        {balances.map((balance) => {
          return (
            <CreateIncomeReportBreakdownTableRow
              key={balance.address}
              totalIncomeAmount={totalIncomeAmount}
              totalSupply={totalSupply}
              balance={balance}
            />
          );
        })}
      </tbody>
    </Table>
  );
};
