import Select, { Props } from 'react-select';
import styled, {
  CSSObject,
  CSSProp,
  createGlobalStyle,
} from 'styled-components';

import { Any } from '@hedgehog/shared/types';
import { parseBorderToShadow } from '@hedgehog/ui/themes';

import { ErrorNote, HintNote } from '../Input/Input';
import { InputLabel } from '../input-label';
import InputGroup from '../InputGroup/InputGroup';

const SelectorMenuStyle = createGlobalStyle`
  .react-select__option {
    &.react-select__option--is-focused {
      background-color: ${({ theme }): string => theme.colors.grey100};
    }
    &.react-select__option--is-selected {
      background-color: ${({ theme }): string => theme.colors.secondary};
    }
  }
`;

type SelectorInputProps = Omit<Props, 'onChange'> & {
  ariaLabel?: string;
  label?: string;
  hint?: string;
  errors?: string[];
  onChange?: (value: Any) => void;
};

export const SelectorInput = styled(
  ({
    placeholder = 'Select...',
    label,
    ariaLabel = 'dropdown-selector',
    name,
    hint,
    isClearable = true,
    onChange,
    errors,
    className,
    menuPortalTarget = typeof document !== 'undefined' ? document.body : null,
    menuPosition = 'fixed',
    ...props
  }: SelectorInputProps) => {
    return (
      <InputGroup className={className}>
        <SelectorMenuStyle />
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <Select
          {...props}
          aria-label={ariaLabel}
          isClearable={isClearable}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          classNamePrefix="react-select"
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={menuPortalTarget}
          menuPosition={menuPosition}
        />
        {hint && <HintNote>{hint}</HintNote>}
        {errors &&
          errors.map((error) => <ErrorNote key={error}>{error}</ErrorNote>)}
      </InputGroup>
    );
  },
)`
  .react-select__control {
    height: 3rem;
    border: none;

    ${({ theme }): CSSProp => ({
      backgroundColor: theme.colors.grey100,
    })}

    &.react-select__control--is-focused {
      // important is needed to override react-select styles
      outline: 1px solid
        ${({ theme }): string => theme.colors.secondary}!important;
    }

    ${({ errors, theme }): CSSObject => {
      const boxShadow = parseBorderToShadow(theme.inputs.border, {
        color: errors?.length ? theme.colors.error : 'transparent',
      });

      return {
        boxShadow,
        color: errors?.length ? theme.colors.error : theme.colors.black,
        backgroundColor: theme.colors.grey100,
        borderRadius: theme.radius.normal,
        ...theme.inputs.typography,
        fontWeight: 400,
        '&:focus, &:hover': {
          boxShadow: parseBorderToShadow(theme.inputs.border, {
            color: errors?.length ? theme.colors.error : theme.colors.shadow100,
          }),
        },
        '&:disabled': {
          color: theme.colors.shadow100,
        },
      };
    }}
  }

  .react-select__value-container {
    padding: 0 1rem;
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
  }

  .react-select__placeholder,
  .react-select__single-value {
    margin-left: 0;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__multi-value {
    border-radius: 0.5rem;
  }
`;
