import styled from 'styled-components';

import { HeaderWithClose } from '@hedgehog/ui/headers';
import { VSpace } from '@hedgehog/ui/layouts';
import { BottomSheet } from '@hedgehog/ui/modals';
import { Heading, MarkdownText, Note } from '@hedgehog/ui/typography';

const Container = styled(VSpace)`
  overflow-y: auto;
`;

const ModalMarkdown = styled(MarkdownText)`
  margin: 0;
  p {
    margin-bottom: 1rem;
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 2rem 1.5rem;

  ${HeaderWithClose} {
    margin-bottom: 1.5rem;
  }
`;

type CMSDisclaimerModalProps = {
  title?: string;
  content?: string;
  footnote?: string;
  onClose: () => void;
};

export const CMSDisclaimerModal = ({
  title,
  content,
  footnote,
  onClose,
}: CMSDisclaimerModalProps): JSX.Element => {
  return (
    <BottomSheet>
      <ModalContent>
        <HeaderWithClose onClose={onClose}>
          <Heading level="h5">{title}</Heading>
        </HeaderWithClose>
        <Container spacing="xs">
          {content && <ModalMarkdown>{content}</ModalMarkdown>}
          {footnote && (
            <Note>
              <ModalMarkdown>{footnote}</ModalMarkdown>
            </Note>
          )}
        </Container>
      </ModalContent>
    </BottomSheet>
  );
};

export default CMSDisclaimerModal;
