import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ZeroDevProvider } from '@hedgehog/browser/shared/account-abstraction';
import { CacheManager } from '@hedgehog/browser/shared/utils';
import { AuthProvider } from '@hedgehog/data-access/contexts';
import { AppDataProvider } from '@hedgehog/data-access/partners';
import { EnvironmentProvider } from '@hedgehog/ui/environment';
import { ModalProvider } from '@hedgehog/ui/modals';

import { PartnerThemeProvider } from './containers';
import { environment } from './environments/environment';
import { Router } from './router/router';

export const App = (): JSX.Element => {
  return (
    <EnvironmentProvider environment={environment}>
      <ZeroDevProvider>
        <BrowserRouter>
          <AppDataProvider>
            <AuthProvider>
              <PartnerThemeProvider>
                <CacheManager>
                  <ModalProvider>
                    <Router />
                  </ModalProvider>
                  <ToastContainer
                    position="bottom-right"
                    pauseOnFocusLoss
                    pauseOnHover
                    autoClose={5000}
                    theme="light"
                  />
                </CacheManager>
              </PartnerThemeProvider>
            </AuthProvider>
          </AppDataProvider>
        </BrowserRouter>
      </ZeroDevProvider>
    </EnvironmentProvider>
  );
};
