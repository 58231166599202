import { cloneElement, ReactElement } from 'react';
import styled, { CSSObject } from 'styled-components';

import {
  ContentBlock as ContentBlockDataType,
  ContentDownloadCardsBlockItem,
} from '@hedgehog/shared/types';
import { StandardProps } from '@hedgehog/ui/utils';

import { ContentBlock } from './content-block.component';
import { Answers, Comments, ContentContext } from './ContentContext';
import { CMSAnalyticsTrackProvider } from './providers/cms-analytics-track.provider';
import { CMSDocumentClickProvider } from './providers/cms-document-click.provider';

export type ContentCMSProps = StandardProps<
  {
    loading?: boolean;
    content:
      | (
          | ContentBlockDataType
          | ReactElement
          | { __typename?: 'ContentBlock' | undefined }
        )[];
  } & (
    | {
        comments: Comments;
        answers: Answers;
        onQuestionCommentClick?: (comment: any) => void;
        onQuestionAnswerClick?: (qId: string, aId: string, answer: any) => void;
      }
    | { comments?: undefined }
    | { answers?: undefined }
  ) & {
      onAnalyticsTrack?: (
        object: any,
        action: any,
        properties?: Record<string, unknown>,
        options?: Record<string, unknown>,
      ) => void;
    } & {
      onDocumentClick?: (document: ContentDownloadCardsBlockItem) => void;
    },
  never
>;

export const ContentTheme = styled.article`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({
    color: theme.colors.grey400,
    '& > *:not(:last-child)': { marginBottom: '3rem' },
    '& *:is(h1,h2,h3,h4,h5,h6)': { color: theme.colors.black },
  })}
`;

const isContentBlock = (block: any): block is ContentBlockDataType =>
  typeof block === 'object' && block.data && block.type;

export const ContentCMS = ({
  content = [],
  loading = false,
  onAnalyticsTrack,
  onDocumentClick,
  className,
  ...commentProps
}: ContentCMSProps): JSX.Element => {
  return (
    <ContentContext.Provider
      value={{
        ...commentProps,
        theme: 'secondary',
      }}
    >
      <CMSAnalyticsTrackProvider onAnalyticsTrack={onAnalyticsTrack}>
        <CMSDocumentClickProvider onDocumentClick={onDocumentClick}>
          <ContentTheme className={className}>
            {content.map((contentBlockOrReactNode, index) => {
              if (isContentBlock(contentBlockOrReactNode)) {
                const { type, data } = contentBlockOrReactNode;
                const key = `${type}_${index}`;
                return (
                  <ContentBlock
                    loading={loading}
                    type={type}
                    data={data}
                    key={key}
                  />
                );
              }
              return cloneElement(contentBlockOrReactNode as ReactElement, {
                key: `static_${index}`,
              });
            })}
          </ContentTheme>
        </CMSDocumentClickProvider>
      </CMSAnalyticsTrackProvider>
    </ContentContext.Provider>
  );
};
