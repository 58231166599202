export const waitForWebkitMessage = <R = unknown, K extends string = string>(
  eventName: K,
): Promise<R> => {
  const { webkit_broadcaster } = window;
  if (!webkit_broadcaster) throw new Error('No webkit_broadcaster found');
  return new Promise((resolve, reject) => {
    if (!webkit_broadcaster)
      return reject(new Error('No webkit_broadcaster found'));
    const handleTokenChange = (value: R): void => {
      resolve(value);
    };
    webkit_broadcaster.once(eventName, handleTokenChange);
  });
};
