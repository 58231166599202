import styled from 'styled-components';

import { LiveIcon } from '@hedgehog/ui/icons';

import { StatusText } from './styles';

const Live = styled(LiveIcon)`
  margin-bottom: -0.125rem;
  margin-left: 0.25rem;
`;

export const AssetLiveStatus = (): JSX.Element => (
  <StatusText color="primary">
    Live <Live color="primary" />
  </StatusText>
);
