import { useEffect } from 'react';
import styled from 'styled-components';

import { useFundsStatistics } from '@hedgehog/data-access/partners';
import { Highlight } from '@hedgehog/ui/cms';

const StyledMetrics = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const OverviewHighlights = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
  justify-content: space-between;
`;

export interface FundsMetricProps {
  loading?: boolean;
  onLoaded?: () => void;
}

export const FundsMetric = ({
  loading: parentLoading,
  onLoaded = () => void 0,
}: FundsMetricProps): JSX.Element => {
  const { data: statistics, loading: statisticsLoading } = useFundsStatistics();

  useEffect(() => {
    if (statistics && !statisticsLoading) {
      onLoaded();
    }
  }, [statisticsLoading]);

  const loading = parentLoading || statisticsLoading;
  return (
    <StyledMetrics>
      <OverviewHighlights>
        <Highlight
          title="Total Called Amount (in USD)"
          description={statistics?.totalAmountInUsd ?? '0,000,000.00$'}
          loading={loading}
        />
        <Highlight
          title="Total Settled Amount (in USD)"
          description={statistics?.settledTotalAmountInUsd ?? '0,000,000.00$'}
          loading={loading}
        />
        <Highlight
          title="Funds count"
          description={`${statistics?.fundsCount ?? '000'}`}
          loading={loading}
        />
        <Highlight
          title="Capital Calls count"
          description={`${statistics?.capitalCallsCount ?? '000'}`}
          loading={loading}
        />
      </OverviewHighlights>
    </StyledMetrics>
  );
};
