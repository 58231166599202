import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  BreadcrumbsList,
  ListQueryInput,
} from '@hedgehog/browser-partners-shared-lists';
import { useDebounce } from '@hedgehog/data-access/hooks';
import {
  useAppData,
  useLazyTeamMembersQuery,
} from '@hedgehog/data-access/partners';
import { TeamMember } from '@hedgehog/data-access/partners-types';

const ScrollableList = styled(BreadcrumbsList)`
  overflow: hidden;

  ${ListQueryInput} {
    position: sticky;
    top: 0;
    z-index: 10;
  }
`;

export const TeamMembersList = (): JSX.Element => {
  const { activePartner } = useAppData();
  const { teamMemberId } = useParams();
  const [query, setQuery] = useState<string>('');
  const debouncedQuery = useDebounce(query, 500);
  const [fetchTeamMembers, { data: teamMembers, isLoading }] =
    useLazyTeamMembersQuery();

  useEffect(() => {
    if (!activePartner?.id) return;
    fetchTeamMembers({
      partnerId: activePartner.id,
      query: debouncedQuery,
    });
  }, [activePartner?.id, debouncedQuery]);

  const subheading = (teamMember: TeamMember): string =>
    teamMember.disabledAt ? 'Inactive' : teamMember.role?.name || 'No Role';

  return (
    <ScrollableList
      search={query}
      onSearch={setQuery}
      loading={isLoading}
      items={
        teamMembers
          ? teamMembers.map((teamMember: TeamMember) => ({
              id: teamMember.teamMemberId,
              heading: teamMember.firstName + ' ' + teamMember.lastName,
              subheading: subheading(teamMember),
              loading: false,
              to: `${teamMember.teamMemberId}`,
              active: teamMember.teamMemberId === teamMemberId,
            }))
          : []
      }
    />
  );
};
