import styled from 'styled-components';

import { CheckboxInput } from '@hedgehog/ui/inputs';

import {
  termsUrl as defaultTerms,
  privacyPolicyUrl as defaultPrivacyPolicyUrl,
} from '../pages/content';

type SignupTermsCheckboxProps = {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
  termsUrl?: string;
  privacyPolicyUrl?: string;
};

const StyledCheckboxLabel = styled.span`
  font-size: 0.875rem;
`;

export const SignupTermsCheckbox = ({
  name,
  value,
  onChange,
  termsUrl,
  privacyPolicyUrl,
}: SignupTermsCheckboxProps): JSX.Element => {
  return (
    <CheckboxInput
      label={
        <StyledCheckboxLabel>
          I accept the{' '}
          <a href={termsUrl || defaultTerms} target="_blank" rel="noreferrer">
            Terms of Service
          </a>{' '}
          and the{' '}
          <a
            href={privacyPolicyUrl || defaultPrivacyPolicyUrl}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </StyledCheckboxLabel>
      }
      name={name}
      value={value}
      onChange={(): void => onChange(!value)}
    />
  );
};

export default SignupTermsCheckbox;
