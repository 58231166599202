export const XCircleIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM8.58579 11.4142L13.1716 16L8.58579 20.5858L11.4142 23.4142L16 18.8284L20.5858 23.4142L23.4142 20.5858L18.8284 16L23.4142 11.4142L20.5858 8.58579L16 13.1716L11.4142 8.58579L8.58579 11.4142Z"
      fill="currentColor"
    />
  </svg>
);

export default XCircleIcon;
