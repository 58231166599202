export const ChevronDownIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5608 2.81065L6.00011 8.37131L0.439453 2.81065L2.56077 0.689331L6.00011 4.12867L9.43945 0.689331L11.5608 2.81065Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronDownIcon;
