import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useCurrentFunds } from '@hedgehog/data-access/partners';

import { FundListItem } from '../fund-list-item/fund-list-item.container';

const ListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.625rem;
`;

export const FundList = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const { funds, loading } = useCurrentFunds();

  useEffect(() => {
    if (location.pathname !== '/funds/funds') return;
    if (loading) return;
    if (!funds?.length) return;
    navigate(`${funds[0].id}/overview`);
  }, [loading, funds, location.pathname]);

  return (
    <ListContainer>
      {funds.length ? (
        funds.map((fund) => (
          <FundListItem
            key={fund.id}
            id={fund.id}
            name={fund.name}
            thumbnailSrc={fund.thumbnail}
            blockchainId={fund.blockchainId}
            size={fund.size}
          />
        ))
      ) : (
        <FundListItem
          loading={true}
          key="placeholder"
          id="placeholder"
          name="placeholder"
          thumbnailSrc=""
          blockchainId="-1"
          size={1}
        />
      )}
    </ListContainer>
  );
};
