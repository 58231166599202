import styled from 'styled-components';
import { formatEther, zeroAddress } from 'viem';

import {
  Listing,
  useAppData,
  useFundListings,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import { Heading } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { ListingListItem } from '../listing-list-item/listing-list-item.container';

const Wrapper = styled.div`
  max-height: 100%;
  height: 100%;
  width: 100%;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

const ListContainer = styled(InnerWrapper)`
  margin-bottom: 1rem;
`;

const toListItem =
  (
    loading: boolean,
    address: string,
    showExpiry = true,
    createBidRedirectTo: string | null = 'listings/:listingId/bids/create',
  ) =>
  (listing: Listing) =>
    (
      <ListingListItem
        key={listing.listingId}
        loading={loading}
        address={address}
        createBidRedirectTo={createBidRedirectTo || ''}
        amount={{
          unfundedCommitment: Number(formatEther(listing.commitment)),
          contribution: Number(formatEther(listing.contribution)),
          price: Number(formatEther(listing.price)),
        }}
        listingId={Number(listing.listingId)}
        showExpiry={showExpiry}
      />
    );

export type ListingsListProps = StandardProps<{ fundId: string }>;

export const ListingsList = ({ fundId }: ListingsListProps): JSX.Element => {
  const { activePartnerId } = useAppData();

  const { data: funds, isLoading: areFundsLoading } = useFundsV2Query({
    partnerId: activePartnerId || '',
  });
  const fund = funds?.find((f: FundV2) => f.fundId === fundId);

  const address = fund?.address;

  const {
    data: listings,
    isError,
    isLoading,
  } = useFundListings({ fund, watch: true });

  const liveListings = listings
    .filter((listing: Listing) => listing.status === 'open')
    .map(toListItem(isLoading || areFundsLoading, address || zeroAddress));

  const closedListings = listings
    .filter(
      (listing: Listing) =>
        listing.status === 'closed' ||
        listing.status === 'settled' ||
        listing.status === 'withdrawn',
    )
    .map(
      toListItem(
        isLoading || areFundsLoading,
        address || zeroAddress,
        false,
        null,
      ),
    );

  return (
    <Wrapper>
      <InnerWrapper>
        {/* These checks won't be neccessary once we get the types working from Wagmi */}
        {isLoading || areFundsLoading ? (
          <ListingListItem
            loading={isLoading || areFundsLoading}
            address={zeroAddress}
            listingId={0}
            amount={{
              unfundedCommitment: 1_000_000,
              contribution: 100_000,
              price: 100_000,
            }}
          />
        ) : (
          <>
            <ListContainer>
              <Heading level="h5">Live Listings</Heading>
              {liveListings.length
                ? liveListings
                : 'There are no live listings for this fund'}
            </ListContainer>
            <ListContainer>
              <Heading level="h5">Closed Listings</Heading>
              {closedListings.length
                ? closedListings
                : 'There are no closed listings for this fund'}
            </ListContainer>
          </>
        )}
      </InnerWrapper>
    </Wrapper>
  );
};
