export const PlayIcon = (): JSX.Element => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.25 6H9V18H11.25L17.25 12L11.25 6Z"
      fill="currentColor"
    />
  </svg>
);

export default PlayIcon;
