import { format, parseISO, isBefore } from 'date-fns';
import { ReactNode } from 'react';
import styled from 'styled-components';

import { Heading, Label } from '@hedgehog/ui/typography';

import {
  BulletListItem,
  BulletListItemProps,
} from '../BulletListItem/BulletListItem';
import List from '../List/List';

const Entry = styled(BulletListItem)`
  position: relative;
  border-bottom: none;
  padding: 0;
  margin: 1rem 0 1.75rem 0;

  // Line between icons
  &:not(:last-child):after {
    position: absolute;
    content: '';
    width: 0.125rem;
    background-color: ${({ theme }): string => theme.colors.grey200};
    border-radius: 0.25rem;

    height: calc(100% - 1rem);
    top: calc(2.75rem);
    left: 0.875rem;
    transform: translateX(50%);
  }
`;

export type TimelineEntry = {
  label: string;
  icon: BulletListItemProps['icon'];
  date: string;
  addon?: ReactNode;
};

type TimelineProps = {
  entries: TimelineEntry[];
  timestampFormat?: string;
};

export const Timeline = ({
  entries,
  timestampFormat = 'yyyy',
}: TimelineProps): JSX.Element => {
  return (
    <List>
      {entries.map((entry) => {
        const hasHappened = isBefore(new Date(entry.date), new Date());
        return (
          <Entry
            key={entry.label}
            icon={entry.icon}
            iconColor={hasHappened ? 'black' : 'shadow100'}
            iconBackgroundColor="grey100"
          >
            <Label color="grey400">
              {format(parseISO(entry.date), timestampFormat)}
            </Label>
            <Heading level="h6">{entry.label}</Heading>
            {entry.addon}
          </Entry>
        );
      })}
    </List>
  );
};

export default Timeline;
