import { gql } from '@apollo/client';

export const GET_USER_ACTION_DATA = gql`
  query GetUserActionData {
    investorTypeDeclaration {
      value
      expired
    }
    assessmentProgress {
      status
    }
    partnerSettings {
      name
      enabled
    }
    user {
      createdAt
      country
      partner {
        slug
      }
    }
    declarations {
      type
      expired
    }
    verificationProgress {
      status
    }
    identityCheckProgress {
      reasons
      status
    }
    taxFormProgress {
      status
      url
    }
  }
`;
