import { gql } from '@apollo/client';

export const ASSET_DOCUMENT_DOWNLOADED = gql`
  mutation AssetDocumentDownloaded(
    $assetRoundClassId: String!
    $fileName: String!
    $fileUrl: String!
  ) {
    assetDocumentDownloaded(
      args: {
        assetRoundClassId: $assetRoundClassId
        fileName: $fileName
        fileUrl: $fileUrl
      }
    )
  }
`;
