import { ReactNode } from 'react';
import styled from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';
import { fontSizes, lineHeight } from '@hedgehog/utils/sizes';

const CustomTitle = styled.p<TitleProps>`
  font-size: ${fontSizes.title};
  line-height: ${lineHeight.title};
  font-weight: 600;
  letter-spacing: 0;
  margin: 0;
  ${({ theme, color }) => ({ color: color ? theme.colors[color] : undefined })}
`;

export interface TitleProps {
  textColor?: ThemeColorName;
  children?: ReactNode;
  className?: string;
}

/**
 * @deprecated
 * Only used in v1 of the design system
 */
export const Title = ({ children, ...props }: TitleProps): JSX.Element => {
  return <CustomTitle {...props}>{children}</CustomTitle>;
};
