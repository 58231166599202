import { gql } from '@apollo/client';

export const UPDATE_USER_DETAILS = gql`
  mutation UpdateUserDetails(
    $sourceOfFunds: SourceOfFunds
    $dob: String!
    $postcode: String!
    $addressLine1: String!
    $addressLine2: String!
    $town: String!
    $taxResidency: String!
    $taxIdentifier: String!
  ) {
    updateUser(
      args: {
        sourceOfFunds: $sourceOfFunds
        dob: $dob
        postcode: $postcode
        addressLine1: $addressLine1
        addressLine2: $addressLine2
        town: $town
        taxResidency: $taxResidency
        taxIdentifier: $taxIdentifier
      }
    ) {
      id
    }
  }
`;
