import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { selectAuthToken, useSelector } from '@hedgehog/data-access/partners';

import { StandardProps } from '../../types';

export const AuthenticatedGuard = (
  props: StandardProps,
): JSX.Element | null => {
  const navigate = useNavigate();
  const token = useSelector(selectAuthToken);

  useEffect(() => {
    if (token) return;
    navigate('/auth', { replace: true });
  }, [token]);

  if (!token) {
    return null;
  }

  return <>{props.children}</>;
};
