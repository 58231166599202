import styled from 'styled-components';

import {
  useAppData,
  usePartnerEventsQuery,
} from '@hedgehog/data-access/partners';
import { PartnerEvent } from '@hedgehog/data-access/partners-types';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph } from '@hedgehog/ui/typography';

import { ClientListItem } from '../../components';

const Content = styled.div`
  padding: 1rem 0.5rem;
`;

const StyledLoader = styled(Loader)`
  margin: auto;
`;

// TODO: 🌳 On-boarding configurability tech debt: https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
// NB. This util lets us query the client events to compute the 'canInvest' property
// for a given client ID. For Oakglen I assume we only care that they've signed the NDA and chosen investor type.
// However, the PartnerClient type includes a 'canInvest' property, computed by PartnerClientService.hasClientPassedKyc().
// Clearly, each partner's 'canInvest' criteria will be different, and we probably want to drive this
// via configuration rather than code.
const EventsQuery = (events: PartnerEvent[]) => {
  const forClient = (clientId: string) =>
    events.filter((e) => e.clientId === clientId);

  const hasMessage = (message: RegExp) => (event: PartnerEvent) =>
    event.message.toLowerCase().match(message);

  return function canInvest(clientId: string) {
    const clientEvents = forClient(clientId);
    return (
      clientEvents.some(hasMessage(/signed nda/)) &&
      clientEvents.some(hasMessage(/declared investor type/))
    );
  };
};

export const PartnerEventsList = (): JSX.Element => {
  const { activePartner } = useAppData();
  const partnerId = activePartner?.id;
  const { data, isLoading } = usePartnerEventsQuery(partnerId || '', {
    skip: !partnerId,
  });

  if (isLoading) {
    return <StyledLoader />;
  }

  const canInvest = EventsQuery(data || []);

  return (
    <Content>
      {data?.map((event: PartnerEvent) => (
        <ClientListItem
          key={`${event.clientId}_${event.eventDate}`}
          to={`/clients/${event.clientId}`}
          firstName={event.partnerClient?.user?.firstName ?? ''}
          lastName={event.partnerClient?.user?.lastName ?? ''}
          canInvest={canInvest(event.clientId) ?? false}
          createdAt={event.eventDate ?? ''}
        >
          <Paragraph>{event.message}</Paragraph>
        </ClientListItem>
      ))}
    </Content>
  );
};
