import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  MouseEventHandler,
  RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useNumberFormat } from './use-number-format.hook';

export type UseContentEditableParams = {
  ref: RefObject<HTMLInputElement>;
  value: number | undefined;
  min?: number;
  max?: number;
  precision?: number;
  delimiter?: string;
};

export const useContentEditable = ({
  precision = 2,
  min = 0,
  max = Infinity,
  delimiter = ',',
  ref,
  ...props
}: UseContentEditableParams) => {
  const [focused, setFocused] = useState(false);
  const [pristine, setPristine] = useState<boolean>(true);
  const [touched, setTouched] = useState<boolean>(false);
  const [{ value, contentValue }, { matchPattern, ...valueControls }] =
    useNumberFormat({
      value: props.value,
      precision: Number(precision),
      delimiter,
    });
  const anchorPositionRef = useRef<number>(-1);

  const updateValue = (
    newValue: string,
    caretInit?: { delta?: number },
  ): void => {
    const { current: inputElement } = ref;

    if (!inputElement) return;
    if (pristine) setPristine(false);
    const { selectionStart, selectionEnd } = inputElement;

    const { contentValue: newContentValue } =
      valueControls.updateWithFormattedValue(newValue);
    const delta = (newContentValue?.length ?? 0) - (contentValue?.length ?? 0);
    const offset = (Math.abs(delta) - 1) * Math.sign(delta);

    setTimeout(() => {
      inputElement.selectionStart =
        (selectionStart ?? 0) + (caretInit?.delta ?? 0) + offset;
      inputElement.selectionEnd =
        (selectionEnd ?? 0) + (caretInit?.delta ?? 0) + offset;
    }, 0);
  };

  const handleInput: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { target } = event;
    const { value: newValue } = target;

    const { fullMatch } = matchPattern(`${newValue ?? ''}`);
    if (!fullMatch) {
      updateValue(contentValue ?? '');
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (contentValue !== newValue) {
      updateValue(newValue.replace(/[ ,]{2,}/gi, ''));
    }
  };

  const handleKeydown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key } = event;
    const { current: inputElement } = ref;
    if (!inputElement) return;

    const { selectionStart, selectionEnd } = inputElement;
    const [anchorOffset, focusOffset] = [
      selectionStart ?? 0,
      selectionEnd ?? 0,
    ];

    switch (key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Alt':
      case 'Control':
      case 'Delete':
      case 'Tab': {
        if (touched) {
          anchorPositionRef.current = contentValue?.length ?? -1;
        }
        break;
      }
      case 'Meta': {
        break;
      }
      case 'Backspace': {
        const contentBeforeUpdate = contentValue
          ? contentValue.substring(0, anchorOffset) +
            contentValue.substring(focusOffset)
          : '';
        const [precedingChar, succeedingChar] = [
          contentBeforeUpdate[anchorOffset - 1],
          contentBeforeUpdate[anchorOffset],
        ];

        if (precedingChar === '.') {
          const candidateValue =
            contentBeforeUpdate.substring(0, anchorOffset - 1 - 1) +
            contentBeforeUpdate.substring(focusOffset - 1);

          updateValue(candidateValue, { delta: -1 });

          event.preventDefault();
          event.stopPropagation();
        }
        break;
      }
      case '.': {
        const contentBeforeUpdate = contentValue ?? '';
        if (contentBeforeUpdate[anchorOffset - 1] === '.') {
          updateValue(contentBeforeUpdate, { delta: 1 });
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      }
      default: {
        const { metaKey } = event;
        const contentBeforeUpdate = contentValue ?? '';

        if (metaKey) {
          return;
        }
        if (/[0-9.]/gi.test(key)) {
          const candidateValue =
            contentBeforeUpdate.substring(0, anchorOffset) +
            key +
            contentBeforeUpdate.substring(focusOffset);

          const { fullMatch, decimal } = matchPattern(candidateValue);
          if (!fullMatch) {
            event.preventDefault();
            event.stopPropagation();
            return;
          }

          if (decimal.length > +precision) {
            const candidateValue = `${contentBeforeUpdate.substring(
              0,
              anchorOffset,
            )}${key}${contentBeforeUpdate.substring(focusOffset + 1)}`;
            const { integer, decimal } = matchPattern(candidateValue);

            updateValue(`${integer}.${decimal.slice(0, +precision)}`, {
              delta: 1,
            });
            event.preventDefault();
            event.stopPropagation();
          }
        } else {
          event.preventDefault();
          event.stopPropagation();
        }
        break;
      }
    }
  };

  const handleDoubleClick: MouseEventHandler = (): void => {
    const selection = window.getSelection();

    if (!selection) return;
    if (!selection.anchorNode) return;

    const { current: inputElement } = ref;
    if (!inputElement) return;

    inputElement.selectionStart = 0;
    inputElement.selectionEnd = inputElement?.value.length;
  };

  const handleFocus: FocusEventHandler = (): void => {
    setFocused(true);
  };

  const handleBlur: FocusEventHandler = (): void => {
    setFocused(false);
    const { fullMatch, trimmedFullMatch } = matchPattern(
      contentValue ?? '0.00',
    );

    if (fullMatch) valueControls.updateWithRawValue(+trimmedFullMatch);
  };

  const handlePaste: ClipboardEventHandler = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ): void => {
    const { clipboardData } = event;
    const content = clipboardData.getData('text/plain');

    const { current: inputElement } = ref;
    if (!inputElement) return;
    const [anchorOffset, focusOffset] = [
      inputElement.selectionStart ?? 0,
      inputElement.selectionEnd ?? 0,
    ];

    const currentContent = contentValue ?? '';
    const candidateContent = `${currentContent.substring(
      0,
      anchorOffset,
    )}${content}${currentContent.substring(focusOffset)}`;

    const { fullMatch, formattedFullMatch } = matchPattern(candidateContent);
    event.preventDefault();
    event.stopPropagation();
    if (!fullMatch) {
      return;
    }

    updateValue(formattedFullMatch);
  };

  useLayoutEffect(() => {
    const selection = window.getSelection();
    const { current: inputElement } = ref;

    if (!focused) return;
    if (!selection) return;
    if (!inputElement) return;

    if (!touched) {
      setTouched(true);
      setTimeout(() => {
        inputElement.selectionStart = 0;
        inputElement.selectionEnd = inputElement.value.length;
      }, 0);
      return;
    }

    inputElement.selectionStart = inputElement.value.length;
    inputElement.selectionEnd = inputElement.value.length;
  }, [focused]);

  return useMemo(
    () => ({
      value,
      contentValue,
      flags: {
        focused,
        touched,
        pristine,
        dirty: !pristine,
      },
      properties: {
        style: {},
      },
      listeners: {
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: handleKeydown,
        onInput: handleInput,
        onPaste: handlePaste,
        onDoubleClick: handleDoubleClick,
      },
    }),
    [value, contentValue, focused, touched, pristine],
  );
};
