import { ReadContractResult } from '@wagmi/core';
import { useEffect } from 'react';
import { useContractReads } from 'wagmi';

import { useCacheEntry } from '@hedgehog/browser/shared/utils';

import { UseFundReadParams } from './use-fund-read.hook';

export interface UseFundReadsParams {
  contracts: Omit<UseFundReadParams, 'watch' | 'enabled'>[];
  scopeKey: string;
  enabled?: boolean;
}

export const useFundReads = ({
  contracts,
  scopeKey,
  enabled = true,
}: UseFundReadsParams): [
  () => Promise<any>,
  {
    data: ReadContractResult[] | undefined;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
  },
] => {
  const [entry] = useCacheEntry(scopeKey);
  const {
    data,
    refetch,
    isLoading,
    isFetching,
    isFetchedAfterMount,
    isError,
    error,
  } = useContractReads({
    contracts,
    scopeKey,
    enabled,
  });

  useEffect(() => {
    refetch();
  }, [entry?.validatedAt]);

  return [
    refetch,
    {
      data,
      isLoading: isFetchedAfterMount ? isLoading : isFetching,
      isError,
      error,
    },
  ];
};
