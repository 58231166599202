export enum AssetRoundClassStatus {
  UPCOMING = 'UPCOMING',
  COMING_SOON = 'COMING_SOON',
  REGISTER_INTEREST = 'REGISTER_INTEREST',
  LIVE = 'LIVE',
  FUNDED = 'FUNDED',
  ONGOING = 'ONGOING',
  PAID_OUT = 'PAID_OUT',
  CANCELLED = 'CANCELLED',
}
