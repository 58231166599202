import { ReactNode, createContext, useContext, useRef } from 'react';
import { Chain } from 'viem';
import { polygon, sepolia } from 'viem/chains';
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';

import { useEnvironment } from '@hedgehog/ui/environment';

interface ZeroDevContext {
  projectId?: string;
  chains: Chain[];
  config: any;
}

const context = createContext<ZeroDevContext>({} as ZeroDevContext);

export interface ZeroDevProviderProps {
  children?: ReactNode | ReactNode[];
}

export const ZeroDevProvider = ({
  children,
}: ZeroDevProviderProps): JSX.Element => {
  const {
    accountAbstraction: {
      zerodev: { projectId },
      alchemy: { apiKey },
    },
    production: isProduction,
  } = useEnvironment();

  const {
    current: { chains, publicClient: _publicClient },
  } = useRef(
    configureChains(
      [isProduction ? polygon : sepolia],
      [alchemyProvider({ apiKey })],
    ),
  );

  const { current: config } = useRef(
    createConfig({
      autoConnect: true,
      publicClient: _publicClient,
    }),
  );

  const contextValue: ZeroDevContext = {
    projectId,
    chains,
    config,
  };

  return (
    <WagmiConfig config={config}>
      <context.Provider value={contextValue}>{children}</context.Provider>
    </WagmiConfig>
  );
};

export const useZeroDev = (): ZeroDevContext => {
  return useContext(context);
};
