import { gql } from '@apollo/client';

export const SUBSCRIBE_ASSET_WAITLIST = gql`
  mutation SubscribeAssetWaitlist($slug: String!) {
    subscribeToAssetWaitlist(slug: $slug)
  }
`;

// NB. This isn't actually used anywhere except tests :/
export const UNSUBSCRIBE_ASSET_WAITLIST = gql`
  mutation UnsubscribeAssetWaitlist($slug: String!) {
    unsubscribeFromAssetWaitlist(slug: $slug)
  }
`;
