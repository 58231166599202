import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { RatioInput } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { ListingQuantityItem } from '../listing-quantity-item/listing-quantity-item.component';

type Quantity = {
  ratio?: number;
  max: number;
};

const ListingQuantityWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 2.5rem;
`;

const ListingQuantityBlock = styled.div`
  flex: 1 0;
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export type ListingQuantityInputProps = StandardProps<{
  loading?: boolean;
  askingPrice: number;
  contributions: Quantity;
  unfundedCommitment: Quantity;
  onUnfundedCommitmentClick?: () => void;
  onAskingPriceChange?: (value: number) => void;
}>;

const useAmountAndRatio = (params: Quantity) => {
  const [ratio, setRatio] = useState(params.ratio ?? 1);

  return [{ amount: params.max * ratio, ratio }, setRatio] as const;
};

export const ListingQuantityInput = ({
  loading = false,
  askingPrice,
  contributions,
  unfundedCommitment,
  onUnfundedCommitmentClick,
  onAskingPriceChange,
}: ListingQuantityInputProps): JSX.Element => {
  const [{ amount: unfundedCommitmentAmount, ratio: unfundedCommitmentRatio }] =
    useAmountAndRatio({
      ratio: unfundedCommitment.ratio,
      max: unfundedCommitment.max,
    });

  const handleAskingPriceChange = (value: number): void => {
    if (onAskingPriceChange) {
      onAskingPriceChange(value);
    }
  };

  useEffect(() => {
    if (contributions.max !== askingPrice) {
      handleAskingPriceChange(contributions.max);
    }
  }, [contributions.max]);

  return (
    <ListingQuantityWrapper>
      <ListingQuantityBlock>
        <Paragraph small>Unfunded commitment to sell</Paragraph>
        <ListingQuantityItem
          loading={loading}
          onClick={onUnfundedCommitmentClick}
          max={unfundedCommitment.max}
          ratio={unfundedCommitmentRatio}
        />
      </ListingQuantityBlock>
      <ListingQuantityBlock>
        <Paragraph small>Asking price</Paragraph>
        <RatioInput
          loading={loading}
          id="price"
          value={askingPrice}
          base={
            (contributions.max / unfundedCommitment.max) *
            unfundedCommitmentAmount
          }
          onChange={(value): void => handleAskingPriceChange(value)}
        />
      </ListingQuantityBlock>
    </ListingQuantityWrapper>
  );
};
