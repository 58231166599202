import { css } from 'styled-components';

export const unwrapOut = css<{ maxHeight?: number }>`
  @keyframes fade-out {
    0% {
      max-height: ${(props) => props.maxHeight || 85}vh;
    }

    100% {
      max-height: 0vh;
    }
  }
`;
