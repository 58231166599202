import { sendMessageToApp } from './mobile-message-handler';
import { waitForWebkitMessage } from './wait-for-webkit-message.helper';

export interface CognitoTokens {
  accessToken: string;
  refreshToken: string;
}

const throwOnDidAppear = async (): Promise<never> => {
  await waitForWebkitMessage<void>('webview.didAppear');
  throw new Error(
    'WebView: "webview.didAppear" event was fired; this indicates that the WebView has switched back to this page.',
  );
};

const retryOnDidAppear = async (
  attempts = { current: 0, max: 3 },
): Promise<never> =>
  throwOnDidAppear().catch(() => {
    if (attempts.current > attempts.max) return throwOnDidAppear();
    sendMessageToApp('jwt.expired');
    return retryOnDidAppear({
      current: attempts.current + 1,
      max: attempts.max,
    });
  });

export const askMobileAppForCognitoTokens =
  async (): Promise<CognitoTokens> => {
    const waitForCredentialsUpdate = waitForWebkitMessage<CognitoTokens>(
      'auth.credentials.update',
    );

    sendMessageToApp('jwt.expired');
    const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
      await Promise.race([waitForCredentialsUpdate, retryOnDidAppear()]);

    return {
      accessToken: newAccessToken || '',
      refreshToken: newRefreshToken || '',
    };
  };
