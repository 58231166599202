import styled, { CSSProp } from 'styled-components';

import { ContentDisclaimerBlock } from '@hedgehog/shared/types';
import { InfoCircleIcon } from '@hedgehog/ui/icons';
import { BlockContainer } from '@hedgehog/ui/layouts';
import { useModal } from '@hedgehog/ui/modals';
import { MarkdownText } from '@hedgehog/ui/typography';

import CMSDisclaimerModal from './cms-disclaimer-modal.component';

const Container = styled(BlockContainer)`
  display: flex;
  flex-flow: row nowrap;
  cursor: pointer;
  align-items: center;
  padding: 1rem;
`;

const Content = styled(MarkdownText)`
  flex: 1 1 auto;
  margin: 0 0.5rem 0 0;
`;

const ContentIcon = styled.div`
  flex: 0 0 auto;

  max-height: 1rem;
  max-width: 1rem;
  ${({ theme }): CSSProp => ({
    color: theme.colors.grey200,
  })}
`;

export interface CMSDisclaimerBlockProps {
  block: ContentDisclaimerBlock;
}

export const CMSDisclaimerBlock = ({
  block,
}: CMSDisclaimerBlockProps): JSX.Element | null => {
  const { openAndResolveWithSubmitValue } = useModal(({ submitModal }) => (
    <CMSDisclaimerModal
      title={block.modal?.title}
      content={block.modal?.content}
      footnote={block.modal?.footnote}
      onClose={submitModal}
    />
  ));

  const handleClick = async (): Promise<void> => {
    if (!block.modal) return;
    try {
      await openAndResolveWithSubmitValue();
    } catch (error) {
      /* NOOP */
    }
  };

  return (
    <Container onClick={handleClick}>
      <Content>{block.content}</Content>
      <ContentIcon>
        <InfoCircleIcon />
      </ContentIcon>
    </Container>
  );
};
