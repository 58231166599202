export const passwordMessages: Record<InvalidPasswordReason, string> = {
  minimum_characters: 'Minimum 8 characters',
  required_uppercase: 'Use capitals',
  required_lowercase: 'Use lower case characters',
  required_digit: 'Use digits',
};

const CHARNUM_REGEX = /.{8,}/;
const UPPERCASE_REGEX = /[A-Z]/;
const LOWERCASE_REGEX = /[a-z]/;
const DIGIT_REGEX = /[0-9]/;

export type InvalidPasswordReason =
  | 'minimum_characters'
  | 'required_uppercase'
  | 'required_lowercase'
  | 'required_digit';

export type PasswordValidationOptions = {
  [K in InvalidPasswordReason]?: boolean;
};

export function createPasswordValidation(
  options: PasswordValidationOptions = {},
): (value: string) => InvalidPasswordReason[] {
  const optionsWithDefaults: PasswordValidationOptions = {
    required_digit: true,
    required_lowercase: true,
    required_uppercase: true,
    minimum_characters: true,
    ...options,
  };
  return function validatePassword(value) {
    const hints: InvalidPasswordReason[] = [];

    if (optionsWithDefaults.minimum_characters && !CHARNUM_REGEX.test(value)) {
      hints.push('minimum_characters');
    }
    if (
      optionsWithDefaults.required_uppercase &&
      !UPPERCASE_REGEX.test(value)
    ) {
      hints.push('required_uppercase');
    }
    if (optionsWithDefaults.required_digit && !DIGIT_REGEX.test(value)) {
      hints.push('required_digit');
    }
    if (
      optionsWithDefaults.required_lowercase &&
      !LOWERCASE_REGEX.test(value)
    ) {
      hints.push('required_lowercase');
    }

    return hints;
  };
}
