export const AtSymbolIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32 "
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28H24V32H16C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16V24H16C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16V20H28V16C28 9.37258 22.6274 4 16 4ZM20 20V16C20 13.7909 18.2091 12 16 12C13.7909 12 12 13.7909 12 16C12 18.2091 13.7909 20 16 20H20Z"
      fill="currentColor"
    />
  </svg>
);

export default AtSymbolIcon;
