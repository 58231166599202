import { baseApi } from './base.api';

export const partnerSettingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    // TODO: Fix the type of the response
    partnerSettings: builder.query<any, { partnerId: string }>({
      query: ({ partnerId }) => ({
        url: `partners/${partnerId}/settings`,
      }),
    }),
  }),
});

export const { usePartnerSettingsQuery, useLazyPartnerSettingsQuery } =
  partnerSettingsApi;
