import {
  AccreditationStatus,
  InvestorType,
  KycCheckStatus,
  OnboardingDeclarationStatus,
} from '@hedgehog/data-access/graphql';

import { HedgehogDocusignProcess, OnboardingActionStatus } from '../types';

export interface ParseKycCheckParams {
  status: Record<
    'assessment' | 'verification' | 'identityCheck' | 'taxForm',
    KycCheckStatus
  > &
    Record<'accreditation', AccreditationStatus> &
    Record<'declaration', OnboardingDeclarationStatus> &
    Record<'investorType', InvestorType | null>;
  country: 'USA' | 'GBR' | 'CHE' | string;
}
export interface OverrideKycStatus {
  country: 'USA' | 'GBR' | 'CHE' | string;
  statusOverride: OnboardingActionStatus;
}

export const isLegacy = (
  params: ParseKycCheckParams | OverrideKycStatus,
): params is ParseKycCheckParams => !!(params as ParseKycCheckParams).status;

const defaultMapping: Record<KycCheckStatus, OnboardingActionStatus> = {
  [KycCheckStatus.approved]: 'completed',
  [KycCheckStatus.rejected]: 'failed',
  [KycCheckStatus.retry]: 'retry',
  [KycCheckStatus.processing]: 'inprogress',
  [KycCheckStatus.stale]: 'stale',
  [KycCheckStatus.not_started]: 'pending',
};

export function parseInvestorType({
  status,
}: ParseKycCheckParams): OnboardingActionStatus {
  return status.investorType ? ('completed' as const) : ('pending' as const);
}

export function parseAssessmentStatus({
  status,
}: ParseKycCheckParams): OnboardingActionStatus {
  return (
    {
      ...defaultMapping,
      [KycCheckStatus.not_started]: 'pending' as const,
    }[status.assessment] ?? 'pending'
  );
}

export function parseIdentityCheckStatus({
  status,
  country,
}: ParseKycCheckParams): OnboardingActionStatus {
  const isVerificationCookingOrFinished = [
    KycCheckStatus.processing,
    KycCheckStatus.approved,
  ].includes(status.verification);

  if (!isVerificationCookingOrFinished) {
    return 'blocked';
  }

  return (
    { ...defaultMapping, stale: 'pending' as const }[status.identityCheck] ??
    'pending'
  );
}

export function parseInvestorConfirmationStatus({
  status,
  country,
}: ParseKycCheckParams): OnboardingActionStatus {
  const identityCheckStatus = parseIdentityCheckStatus({ status, country });
  const isIdentityCheckNotFinished = ['retry', 'failed', 'pending'].includes(
    identityCheckStatus,
  );

  const wasVerificationFinished = [
    KycCheckStatus.processing,
    KycCheckStatus.approved,
  ].includes(status.verification);

  const isDeclarationAccepted =
    status.declaration === OnboardingDeclarationStatus.ACCEPTED;

  if (isDeclarationAccepted) return 'completed';
  if (isIdentityCheckNotFinished) return 'blocked';
  if (!wasVerificationFinished) return 'blocked';

  return 'pending';
}

export function parseVerificationStatus({
  status,
  country,
}: ParseKycCheckParams): OnboardingActionStatus {
  // For tenant token and when the user is USA, then this action is blocked until the
  // eligibility assessment is started
  const doesCountryRequireEligibilityAssessment = ['USA'].includes(country);
  const hasEligibilityAssessmentStarted = [
    KycCheckStatus.not_started,
    KycCheckStatus.retry,
    KycCheckStatus.rejected,
  ].includes(status.assessment);

  if (
    doesCountryRequireEligibilityAssessment &&
    hasEligibilityAssessmentStarted
  )
    return 'blocked';

  return defaultMapping[status.verification] ?? 'pending';
}

export function parseAccreditationStatus({
  status,
  country,
}: ParseKycCheckParams): OnboardingActionStatus {
  const searchParams = new URLSearchParams(window.location.search);
  const wasAccreditationRecentlySigned =
    searchParams.get('docusignEvent') === 'completed' &&
    searchParams.get('hedgehogProcess') ===
      HedgehogDocusignProcess.ACCREDITATION_VERIFICATION;
  const isVerificationProcessingOrApproved = [
    KycCheckStatus.processing,
    KycCheckStatus.approved,
  ].includes(status.verification);
  const identityCheckStatus = parseIdentityCheckStatus({ status, country });
  const isIdentityCheckNotFinished = ['retry', 'failed', 'pending'].includes(
    identityCheckStatus,
  );

  if (wasAccreditationRecentlySigned) return 'inprogress';
  if (!isVerificationProcessingOrApproved) return 'blocked';
  if (isIdentityCheckNotFinished) return 'blocked';

  const statuses: Partial<Record<AccreditationStatus, OnboardingActionStatus>> =
    {
      [AccreditationStatus.DECLINED]: 'failed',
      [AccreditationStatus.APPROVED]: 'completed',
      [AccreditationStatus.IN_PROGRESS]: 'inprogress',
    };

  return statuses[status.accreditation] ?? 'pending';
}

export function parseTaxFormStatus({
  status,
  country,
}: ParseKycCheckParams): OnboardingActionStatus {
  const isVerificationCookingOrFinished = [
    KycCheckStatus.processing,
    KycCheckStatus.approved,
  ].includes(status.verification);
  const accreditationStatus = parseAccreditationStatus({ status, country });
  const isAccreditationUnfinished = ['blocked', 'pending'].includes(
    accreditationStatus,
  );

  const identityCheckStatus = parseIdentityCheckStatus({ status, country });
  const isIdentityCheckStuck = [
    'pending',
    'retry',
    'failed',
    'blocked',
  ].includes(identityCheckStatus);

  if (isIdentityCheckStuck || !isVerificationCookingOrFinished) {
    return 'blocked';
  }

  if (
    country === 'CHE' &&
    status.declaration === OnboardingDeclarationStatus.ACCEPTED
  ) {
    return 'blocked';
  } else if (
    country === 'USA' &&
    (isIdentityCheckStuck || isAccreditationUnfinished)
  ) {
    return 'blocked';
  }
  // TODO: if we need to block the tax form before the assessment is approved we can uncomment this
  // } else if (
  //   country === 'GBR' &&
  //   status.assessment !== KycCheckStatus.approved
  // ) {
  //   return 'blocked';
  // }

  return defaultMapping[status.taxForm] ?? 'pending';
}
