import {
  GetPartnerEventsResponse,
  GetPartnerResponse,
  GetPartnersResponse,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import {
  AcceptPartnerTeamInvitationRequest,
  AcceptPartnerTeamInvitationResponse,
} from '../../types';

import { baseApi } from './base.api';

export const partnersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    partners: builder.query<GetPartnersResponse['data'], void>({
      query: () => ({
        url: `partners`,
      }),
      transformResponse: (response: GetPartnersResponse) => response.data,
    }),
    partner: builder.query<GetPartnerResponse['data'], UUID>({
      query: (partnerId) => ({
        url: `partners/${partnerId}`,
      }),
      transformResponse: (response: GetPartnerResponse) => response.data,
    }),
    acceptTeamInvitation: builder.mutation<
      AcceptPartnerTeamInvitationResponse['data'],
      AcceptPartnerTeamInvitationRequest & {
        partnerId: UUID;
      }
    >({
      query: ({ partnerId, ...body }) => ({
        url: `partners/${partnerId}/users`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: AcceptPartnerTeamInvitationResponse) =>
        response.data,
    }),
    partnerEvents: builder.query<GetPartnerEventsResponse['data'], UUID>({
      query: (partnerId) => ({
        url: `partners/${partnerId}/events`,
      }),
      transformResponse: (response: GetPartnerEventsResponse) => response.data,
    }),
  }),
});

export const {
  usePartnersQuery,
  usePartnerQuery,
  usePartnerEventsQuery,
  useAcceptTeamInvitationMutation,
} = partnersApi;
