import { ethers } from 'ethers';
import { useEffect } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import {
  QueryResult,
  useCachedCallback,
  useQueryResult,
} from '@hedgehog/browser/shared/utils';

import { useAppData } from '../providers';

import { useFundManagerContract } from './use-fund-manager-contract.hook';

export type Fund = {
  hedgehogPartnerRef: string;
  hedgehogFundRef: string;
  id: number;
  fundName: string;
  country: string;
  currency: string;
  limitedPartnerHashes: string[];
  limitedPartnerCommitments: number[];
  limitedPartnerTypes: number[];
  limitedPartnerFundIds: number[];
  limitedPartnerDrawn: number[];
};

export const useFundsBlockchainQuery = (): QueryResult<Fund[]> => {
  const fundManagerContract = useFundManagerContract();
  const { address, loading: accountLoading } = useAccountAbstraction();

  const [{ data, error, loading }, controls] = useQueryResult<Fund[]>();

  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';

  const cachableFundsBlockchainQuery = useCachedCallback(
    async (partnerId: string) => {
      // calculate partnerRef using partner id
      const partnerRef = ethers.utils.keccak256(
        ethers.utils.toUtf8Bytes(partnerId),
      );

      const result = await fundManagerContract.callStatic['listFunds(bytes32)'](
        partnerRef,
      );

      return result;
    },
    { key: 'fundsBlockchainQuery' },
  );

  const handleFundsBlockchainQuery = async (partnerId: string) => {
    try {
      controls.setLoading(true);
      const result = await cachableFundsBlockchainQuery(partnerId);

      if (result.length === 0) return controls.setData([]);
      const funds = result.map((fund: any) => ({
        hedgehogPartnerRef: fund.hedgehogPartnerRef,
        hedgehogFundRef: fund.hedgehogFundRef,
        id: fund.id,
        fundName: ethers.utils.toUtf8String(fund.fundName),
        country: ethers.utils.parseBytes32String(fund.country),
        currency: ethers.utils.parseBytes32String(fund.currency),
        limitedPartnerHashes: fund.limitedPartnerHashes,
        limitedPartnerCommitments: fund.limitedPartnerCommitments,
        limitedPartnerTypes: fund.limitedPartnerTypes,
        limitedPartnerFundIds: fund.limitedPartnerFundIds,
        limitedPartnerDrawn: fund.limitedPartnerDrawn,
      }));

      controls.setData(funds);
      controls.setError(null);
      return { data, error, loading };
    } catch (error) {
      controls.setError(error);
      controls.setData(null);
      return { data, error, loading };
    } finally {
      controls.setLoading(false);
    }
  };

  useEffect(() => {
    if (!address) return;
    if (!fundManagerContract) return;
    if (!partnerId) return;

    handleFundsBlockchainQuery(partnerId);
  }, [cachableFundsBlockchainQuery, fundManagerContract, address]);

  return {
    data,
    error,
    loading: loading || accountLoading,
  };
};
