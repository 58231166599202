import queryString from 'query-string';
import { FormEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AuthQueryParams,
  PartnerAuthPage,
} from '@hedgehog/browser/partners/shared/layouts';
import {
  useAcceptTeamInvitationMutation,
  AcceptPartnerTeamInvitationRequest,
} from '@hedgehog/data-access/partners';

import { PartnerAcceptTeamInvitationForm } from '../../../containers';

export const PartnerAcceptTeamInvitationPage = () => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const { partnerId, code } = queryString.parse(search) as AuthQueryParams;

  const [acceptPartnerTeamInvitation, { error, isLoading }] =
    useAcceptTeamInvitationMutation();
  const [state, setState] = useState<AcceptPartnerTeamInvitationRequest>({
    code: '',
    country: '',
    state: '',
    firstName: '',
    lastName: '',
    password: '',
  });

  useEffect(() => {
    if (!code) {
      navigate('/auth/sign-in', { replace: true });
    }
  }, [code]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    if (!partnerId) return;
    if (!code) return;
    event.preventDefault();
    acceptPartnerTeamInvitation({
      ...state,
      partnerId,
      code,
    });
  };

  return (
    <PartnerAuthPage>
      <PartnerAcceptTeamInvitationForm
        values={state}
        loading={isLoading}
        errorMessage={error ? 'Something went wrong, please try again' : ''}
        onChange={setState}
        onSubmit={handleSubmit}
      />
    </PartnerAuthPage>
  );
};

export default PartnerAcceptTeamInvitationPage;
