import { ChangeEvent, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { CheckMarkIcon } from '@hedgehog/ui/icons';

import { InputProps } from '../Input/Input';

const StyledCheckbox = styled.label<{ align: 'top' | 'center' }>`
  display: flex;
  align-items: ${(props): string => props.align};
  text-align: left;
`;

const StyledInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  width: 1px;
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;

  &:disabled ~ * {
    opacity: 0.4;
  }

  &:not(:disabled):focus + i,
  &:not(:disabled):hover + i {
    padding: 0;
    border-width: 4px;
  }

  &:checked + i > svg {
    opacity: 1;
  }
`;

const StyledIcon = styled.i`
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  width: auto;
  height: auto;
  max-width: 1.5rem;
  max-height: 1.5rem;
  line-height: 1;
  padding: 2px;
  border: 2px solid ${(props): string => props.theme.colors.shadow100};
  border-radius: 0.5rem;
  box-sizing: border-box;
  overflow: hidden;

  background-color: ${(props): string => props.theme.colors.grey100};

  svg {
    width: 100%;
    height: 100%;
    margin: 2px;
    opacity: 0;
    fill: currentColor;
  }
`;

const StyledLabel = styled.span`
  display: inline-block;
  margin-left: 1rem;
  ${({ theme }): CSSObject => ({ ...theme.typography.body })}
`;

export type CheckboxInputProps = Omit<
  InputProps,
  'label' | 'value' | 'checked' | 'placeholder' | 'autoCompleteOff'
> & {
  label: ReactNode;
  value?: boolean;
  onChange?: (value: boolean) => void;
  align?: 'top' | 'center';
};

export const CheckboxInput = ({
  name,
  label,
  value,
  disabled,
  required,
  onChange,
  onFocus,
  onBlur,
  onClick,
  align = 'center',
}: CheckboxInputProps): JSX.Element => {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };
  return (
    <StyledCheckbox htmlFor={name} align={align}>
      <StyledInput
        id={name}
        data-testid={name}
        name={name}
        type="checkbox"
        checked={value}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
      />
      <StyledIcon>{<CheckMarkIcon />}</StyledIcon>
      {label && <StyledLabel>{label}</StyledLabel>}
    </StyledCheckbox>
  );
};

export default CheckboxInput;
