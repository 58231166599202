import {
  GetPartnerClientKycProgressRequest,
  GetPartnerClientKycProgressResponse,
} from '@hedgehog/data-access/partners-types';

import { baseApi } from './base.api';

export const clientKycApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    clientKyc: builder.query<
      GetPartnerClientKycProgressResponse,
      GetPartnerClientKycProgressRequest
    >({
      query: ({ partnerId, clientId, ...otherParams }) => ({
        url: `partners/${partnerId}/clients/${clientId}/kycStatus`,
        params: otherParams,
      }),
    }),
  }),
});

export const { useClientKycQuery, useLazyClientKycQuery } = clientKycApi;
