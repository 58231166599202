import { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import { InputBase } from '../Input/input.base';
import InputGroup from '../InputGroup/InputGroup';

const ControlInput = styled.div<{ errors?: string[] }>`
  ${InputBase}
  display: flex;
  height: auto;
  padding: 0.75rem 1rem;
`;
const TextAreaInput = styled.textarea`
  flex: 1 1 100%;
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  resize: none;
  height: auto;
  ${({ theme: { typography } }): CSSObject => ({ ...typography.body_small })};
  outline: none;
  overflow: hidden;
  min-height: 3rem;
`;

const CustomLabel = styled.label`
  font-size: 0.625rem;
  font-weight: 400;
  text-transform: uppercase;

  margin-bottom: 0.125rem;
  ${({ theme: { colors } }): CSSObject => ({
    color: colors.grey400,
  })}
`;

export type TextAreaProps = {
  autosize?: boolean;
  label?: string;
  onChange?: (value: string) => void;
} & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange'>;

export const TextArea = forwardRef(
  (
    { autosize, label, onChange, ...props }: TextAreaProps,
    ref: ForwardedRef<HTMLTextAreaElement>,
  ): JSX.Element => {
    const [value, setValue] = useState(props.value);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const handleContentChange = (element: HTMLTextAreaElement): void => {
      setValue(element.value);
      if (onChange) onChange(element.value);
    };

    useEffect(() => {
      const elementRef = ref || textareaRef;
      if (elementRef instanceof Function) return;
      const { current: textareaElement } = elementRef;
      if (!textareaElement) return;
      if (autosize) {
        textareaElement.style.height = '0';
        textareaElement.style.height = `${textareaElement.scrollHeight}px`;
      }
    }, [value]);

    return (
      <InputGroup>
        {label && <CustomLabel htmlFor={props.name}>{label}</CustomLabel>}
        <ControlInput>
          <TextAreaInput
            id={props.name}
            onChange={(event): void => handleContentChange(event.target)}
            ref={ref || textareaRef}
            {...props}
          />
        </ControlInput>
      </InputGroup>
    );
  },
);
