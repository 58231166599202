import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useEffect, useMemo, useState } from 'react';
import {
  Abi,
  Address,
  Hex,
  decodeAbiParameters,
  getAddress,
  zeroAddress,
} from 'viem';
import { useContractRead } from 'wagmi';

import { useAppData } from '../../providers';
import { useFundsV2Query } from '../../store/apis';
import { Bid, BidStatus, BidStatusLiteral } from '../../types';

export type UseBidOutput = {
  data?: Bid;
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
};

export const useBid = ({
  fundId,
  listingId,
  bidId,
}: {
  fundId: string;
  listingId: number;
  bidId: number;
}): UseBidOutput => {
  const [bid, setBid] = useState<UseBidOutput['data']>();

  const { activePartnerId } = useAppData();
  const { data: funds, isLoading: areFundsLoading } = useFundsV2Query({
    partnerId: activePartnerId || '',
  });
  const fund = funds?.find((f) => f.fundId === fundId);

  const {
    data: bids,
    isLoading: areBidsLoading,
    isRefetching: areBidsRefetching,
    isFetched: areBidsFetched,
    refetch: getBids,
  } = useContractRead({
    address: getAddress(fund?.address || zeroAddress),
    abi: IFund.abi as Abi,
    functionName: 'getBids',
    args: [BigInt(listingId)],
    enabled: false,
  });

  const {
    data: listings,
    isLoading: areListingsLoading,
    isRefetching: areListingsRefetching,
    isFetched: areListingsFetched,
    refetch: getListings,
  } = useContractRead({
    address: getAddress(fund?.address || zeroAddress),
    abi: IFund.abi as Abi,
    functionName: 'getListings',
    args: [],
    enabled: false,
  });

  useEffect(() => {
    if (!fund) return;
    load();
  }, [fund, bids, listings]);

  const load = async (): Promise<void> => {
    // TODO: We should be able to use `getBid` here, rather than have to do `getBids` and filter.
    await getBids();
    await getListings();

    const bid = (
      bids as { id: number; bidder: Address; price: bigint; status: number }[]
    ).find((b) => b.id === bidId);

    if (!bid) return;

    // Extract the properties from the bid
    const { id, bidder, price, status } = bid;

    // Find the listing that matches the bid and decode the listing data
    const [listingIds, listingData] = listings as [bigint[], Hex[]];

    const [
      commitment = BigInt(0),
      contribution = BigInt(0),
      askingPrice = BigInt(0),
      listingStatus = 0,
    ] = decodeAbiParameters(
      [
        {
          name: 'commitmentTokenAmount',
          type: 'uint256',
        },
        {
          name: 'contributionTokenAmount',
          type: 'uint256',
        },
        {
          name: 'price',
          type: 'uint256',
        },
        {
          name: 'status',
          type: 'uint8',
        },
      ],
      listingData[listingIds.map(BigInt).indexOf(BigInt(listingId))],
    );

    setBid({
      id,
      bidder,
      fundId,
      fundName: fund?.name || '',
      fundDescription: fund?.description || '',
      commitment: commitment,
      contribution: contribution,
      bidPrice: price,
      listingPrice: askingPrice,
      status: BidStatus[status] as BidStatusLiteral,
    });
  };

  const isLoading =
    areFundsLoading ||
    areBidsLoading ||
    areListingsLoading ||
    areBidsRefetching ||
    areListingsRefetching;

  const isFetched = areBidsFetched && areListingsFetched;

  return useMemo(
    () => ({
      data: bid,
      isLoading,
      isFetched,
      isError: false,
    }),
    [bid, isLoading, isFetched],
  );
};
