import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';

const CustomLabel = styled.label<LabelProps>`
  ${({ color, theme: { colors, typography } }): CSSObject => ({
    ...typography.label,
    color: color ? colors[color] : undefined,
  })}

  ${({ uppercase }): CSSObject => ({
    textTransform: uppercase ? 'uppercase' : 'none',
  })}
`;

export interface LabelProps {
  color?: ThemeColorName | string;
  className?: string;
  htmlFor?: string;
  children?: ReactNode | ReactNode[];
  uppercase?: boolean;
  /**
   * @deprecated
   * Use {color} instead
   */
  textColor?: string;
}

export const Label = ({
  textColor,
  color = textColor,
  children,
  className,
  htmlFor,
  uppercase = true,
}: LabelProps): JSX.Element => {
  return (
    <CustomLabel
      htmlFor={htmlFor}
      color={color}
      className={className}
      uppercase={uppercase}
    >
      {children}
    </CustomLabel>
  );
};
