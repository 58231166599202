import { RefObject, createContext, createRef } from 'react';

import { UUID } from '@hedgehog/shared/types';

export interface AutoscrollState {
  currentIndex: number;
  currentElement: HTMLElement | null;
  containerRef: RefObject<HTMLElement>;
  elements: Record<UUID, HTMLElement>;
  order: UUID[];
  next: (fromElementId: UUID) => void;
  previous: (fromElementId: UUID) => void;
  mount: (scrollElementId: UUID, element: HTMLElement) => void;
  unmount: (scrollElementId: UUID) => void;
  scrollTop: (offset: number, smoothly?: boolean) => void;
}

export const defaultValue: AutoscrollState = {
  currentIndex: 0,
  currentElement: null,
  containerRef: createRef<HTMLElement>(),
  elements: {},
  order: [],
  mount: () => {
    /* NOOP */
  },
  unmount: () => {
    /* NOOP */
  },
  next: () => {
    /* NOOP */
  },
  previous: () => {
    /* NOOP */
  },
  scrollTop: () => {
    /* NOOP */
  },
};

export const AutoscrollContext = createContext({ ...defaultValue });
