import { gql } from '@apollo/client';

export const GET_TENANT_TOKEN_BALANCE = gql`
  query GetTenantTokenBalance {
    tokenBalance {
      ... on TokenBalancePayload {
        contract
        balance
        vesting {
          amount
          startDate
          endDate
          contractAddress
          beneficiaryAddress
        }
      }
      ... on TokenBalanceError {
        code
        message
      }
    }
  }
`;
