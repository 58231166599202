import { Dispatch, useReducer } from 'react';

import { ModalState } from '../modal.types';

export interface ModalReducerState {
  modals: ModalState[];
}

export type ModalReducerType = 'modal:open' | 'modal:close' | 'modal:close:all';

export type ModalReducerAction =
  | ModalOpenAction
  | ModalCloseAction
  | ModalCloseAllAction;

export interface ModalActionBase {
  type: ModalReducerType;
  payload?: object;
}

export interface ModalCloseAllAction extends ModalActionBase {
  type: 'modal:close:all';
}

export interface ModalOpenAction<P = any> extends ModalActionBase {
  type: 'modal:open';
  payload: ModalState<P>;
}

export interface ModalCloseAction extends ModalActionBase {
  type: 'modal:close';
  payload: ModalState['componentRef'];
}

export function useModalReducer(): [
  ModalReducerState,
  Dispatch<ModalReducerAction>,
] {
  return useReducer(
    (previousState: ModalReducerState, action: ModalReducerAction) => {
      switch (action.type) {
        case 'modal:open': {
          return {
            ...previousState,
            modals: [...previousState.modals, action.payload],
          };
        }

        case 'modal:close': {
          const { modals } = previousState;
          const targetIndex = modals.findIndex(
            (modal) => modal.componentRef === action.payload,
          );

          if (targetIndex === -1) {
            return previousState;
          }
          return {
            ...previousState,
            modals: [
              ...modals.slice(0, targetIndex),
              ...modals.slice(targetIndex + 1),
            ],
          };
        }

        case 'modal:close:all': {
          return {
            ...previousState,
            modals: [],
          };
        }

        default: {
          return previousState;
        }
      }
    },
    { modals: [] },
  );
}
