import {
  GetPartnerClientEventsRequest,
  GetPartnerClientEventsResponse,
} from '../../types';

import { baseApi } from './base.api';

export const clientEventsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    clientEvents: builder.query<
      GetPartnerClientEventsResponse['data'],
      GetPartnerClientEventsRequest
    >({
      query: ({ partnerId, clientId, ...otherParams }) => ({
        url: `partners/${partnerId}/clients/${clientId}/events`,
        params: otherParams,
      }),
      transformResponse: (response: GetPartnerClientEventsResponse) =>
        response.data,
    }),
  }),
});

export const { useClientEventsQuery, useLazyClientEventsQuery } =
  clientEventsApi;
