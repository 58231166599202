import { gql } from '@apollo/client';

export const UPDATE_ORDER = gql`
  mutation UpdateOrder($id: ID!, $quantity: Int!) {
    updateOrder(args: { id: $id, quantity: $quantity }) {
      id
      quantity
      amount
    }
  }
`;
