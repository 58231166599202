import { mergeDeep } from '@apollo/client/utilities';

import { lightTheme } from './theme.light';

export const oakglenTheme = mergeDeep(lightTheme, {
  colors: {
    primary: '#85AF5D',
    secondary: '#304917',
  },
  sidebar: {
    backgroundImage: `url(https://assets.hedgehog-invest.com/highlands2-1920x1280.jpg) center/cover`,
  },
});
