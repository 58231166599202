export const MailIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 3.3585V12H16V3.35849L8 8.3585L0 3.3585Z" fill="currentColor" />
    <path d="M16 1V0H0V1L8 6L16 1Z" fill="currentColor" />
  </svg>
);

export default MailIcon;
