import styled, { CSSObject } from 'styled-components';

import { BrandLogotype } from '@hedgehog/ui/layouts';
import { Paragraph } from '@hedgehog/ui/typography';

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  align-self: flex-end;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.xsmall })}
`;

const BrandParagaph = styled(Paragraph)`
  padding-top: 0.3125em;
`;

const BrandHedgehog = styled(BrandLogotype)`
  padding: 0;
  max-height: 1.25rem;
`;

export const NavbarFooter = (): JSX.Element => {
  return (
    <Wrapper>
      <BrandParagaph small color="grey400">
        Powered by
      </BrandParagaph>
      <BrandHedgehog />
    </Wrapper>
  );
};
