import { TailSpin } from 'react-loader-spinner';
import styled, { useTheme, CSSObject } from 'styled-components';

export type LoaderProps = {
  height?: number;
  color?: string;
  ariaLabel?: string;
  className?: string;
  center?: boolean;
};

export const Loader = styled(
  ({
    height = 35,
    color = 'primary',
    ariaLabel = 'loading-indicator',
    className,
  }: LoaderProps): JSX.Element => {
    const { colors } = useTheme();
    return (
      <TailSpin
        wrapperClass={className}
        data-testid="loader"
        height={height}
        width={height}
        color={colors[color] || color}
        ariaLabel={ariaLabel}
      />
    );
  },
)`
  ${({ center }): CSSObject => ({
    justifyContent: center ? 'center' : '',
  })};
`;
