import {
  MarketTransferAction,
  MarketTransferStatus,
  MarketTransferStep,
} from '../../../../../types';

const buyerActor = {
  firstName: 'David',
  lastName: 'Jones',
};

const sellerActor = {
  firstName: 'John',
  lastName: 'Smith',
};

export const STEP_FIXTURES: MarketTransferStep[] = [
  {
    action: MarketTransferAction.BuyerTermsAndConditions,
    status: MarketTransferStatus.Success,
    completedAt: new Date(Date.now() - 1000 * 60 * 60 * 24 * 2),
    actor: buyerActor,
  },
  {
    action: MarketTransferAction.SellerTermsAndConditions,
    status: MarketTransferStatus.Success,
    completedAt: new Date(Date.now() - 1000 * 60 * 60 * 24),
    actor: sellerActor,
  },
  {
    action: MarketTransferAction.AssetInEscrow,
    status: MarketTransferStatus.Success,
  },
  {
    action: MarketTransferAction.PaymentSettled,
    status: MarketTransferStatus.Pending,
  },
  {
    action: MarketTransferAction.TransferFinalised,
    status: MarketTransferStatus.Pending,
  },
];
