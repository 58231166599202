export const TimerIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.5999 0V6.4H14.3999V0H17.5999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.3855 2.94357L22.1855 8.48613L19.4143 6.88613L22.6143 1.34357L25.3855 2.94357Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30.6566 9.38563L25.1141 12.5856L23.5141 9.81435L29.0566 6.61435L30.6566 9.38563Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32 17.6L25.6 17.6L25.6 14.4L32 14.4L32 17.6Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.0567 25.3857L23.514 22.1856L25.114 19.4143L30.6567 22.6144L29.0567 25.3857Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6145 30.6565L19.4145 25.114L22.1857 23.514L25.3857 29.0565L22.6145 30.6565Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.4001 32L14.4001 25.6L17.6001 25.6L17.6001 32L14.4001 32Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.61446 29.0564L9.81446 23.5139L12.5857 25.1139L9.38574 30.6564L6.61446 29.0564Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.34336 22.6144L6.88592 19.4144L8.48592 22.1857L2.94336 25.3857L1.34336 22.6144Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M-1.67628e-07 14.3999L6.4 14.3999L6.4 17.5999L0 17.5999L-1.67628e-07 14.3999Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.94326 6.61428L8.48599 9.81438L6.88599 12.5857L1.34326 9.38556L2.94326 6.61428Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.38554 1.34345L12.5855 6.88601L9.81426 8.48601L6.61426 2.94345L9.38554 1.34345Z"
      fill="currentColor"
    />
  </svg>
);
