import CurrencyFormat, { Values } from 'react-currency-format';
import styled, { CSSObject } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import { CustomInput } from '../Input/Input';

const Input = styled(CustomInput)`
  outline: none;
  height: 2rem;

  ${({ theme }): CSSObject => ({
    textAlign: 'center',
    ...theme.typography['h6'],
  })};
`;

export type CurrencyCoreInputProps = StandardProps<{
  name: string;
  value?: number | null;
  onChange: (value: number) => void;
  currency?: currencies.Currency;
  suffix?: string;
  prefix?: string;
  thousandSeparator?: boolean;
  fixedDecimalScale?: boolean;
  decimalScale?: number;
  placeholder?: string;
  id?: string;
  label?: string;
}>;

export const CurrencyCoreInput = ({
  name,
  value,
  onChange,
  currency,
  suffix,
  thousandSeparator = true,
  fixedDecimalScale = true,
  decimalScale = 0, // default is 0 decimal places
  placeholder = 'Enter amount',
  label,
  id,
  ...currencyProps
}: CurrencyCoreInputProps): JSX.Element => {
  const handleChange = ({ floatValue }: Values): void => {
    onChange(isNaN(floatValue) ? 0 : floatValue);
  };
  return (
    <Input
      as={CurrencyFormat}
      id={id}
      name={name}
      value={value !== undefined ? value : ''}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale}
      prefix={currency ? currencies.symbols[currency] : ''}
      suffix={suffix}
      onValueChange={handleChange}
      placeholder={placeholder}
      {...currencyProps}
    />
  );
};
