import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { HeaderButton } from './header-button.component';
import { Header, HeaderProps } from './header.component';

export type HeaderWithManyProps = {
  onMany?: MouseEventHandler;
} & HeaderProps;

export const HeaderWithMany = styled(
  ({ onMany, right, ...headerProps }: HeaderWithManyProps): JSX.Element => {
    return (
      <Header
        right={
          <>
            {right}
            <HeaderButton icon="more" onClick={onMany} />
          </>
        }
        {...headerProps}
      />
    );
  },
)``;
