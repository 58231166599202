export const EditIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.5858 7.41421L0 22V30H8L22.5858 15.4142L14.5858 7.41421Z"
      fill="currentColor"
    />
    <path
      d="M17.4142 4.58579L25.4142 12.5858L28.3431 9.65685C29.404 8.59599 30 7.15715 30 5.65685C30 2.53266 27.4673 0 24.3431 0C22.8429 0 21.404 0.595989 20.3431 1.65686L17.4142 4.58579Z"
      fill="currentColor"
    />
  </svg>
);

export default EditIcon;
