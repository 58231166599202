import { useEffect, useMemo, useState } from 'react';

const reverse = (str: string): string => str.split('').reverse().join('');

const createNumberPattern = (delimiter: string): RegExp =>
  new RegExp(`^([0-9${delimiter}]*)[.]{0,1}(?:([0-9]*))?$`);

export type NumberFormatOptions = {
  value?: number;
  precision?: number;
  delimiter?: string;
};

export type MatchPatternResult = {
  fullMatch: string | null;
  integer: string;
  decimal: string;
  trimmedInteger: string;
  trimmedDecimal: string;
  trimmedFullMatch: string;
  formattedInteger: string;
  formattedDecimal: string;
  formattedFullMatch: string;
};

export type UseNumberFormatOutput = {
  value: number | undefined;
  contentValue: string | undefined;
};
export type UseNumberFormatTuple = [
  UseNumberFormatOutput,
  {
    matchPattern: (value: string) => MatchPatternResult;
    updateWithRawValue: (newValue: number) => UseNumberFormatOutput;
    updateWithFormattedValue: (newValue: string) => UseNumberFormatOutput;
    updateWithoutFormattingValue: (newValue: string) => UseNumberFormatOutput;
  },
];

export const useNumberFormat = ({
  value = undefined,
  precision = 2,
  delimiter = ',',
}: NumberFormatOptions): UseNumberFormatTuple => {
  const formatInteger = (integer: string): string => {
    const reversedInteger = reverse(integer);
    const reversedGroups = reversedInteger.match(/([0-9]{1,3})/gi) ?? [];
    return reverse(reversedGroups.join(delimiter));
  };

  const formatDecimal = (decimal: string): string => {
    const precisionLongSuffix = '0'.repeat(+precision);
    return `${decimal}${precisionLongSuffix}`.substring(0, +precision);
  };

  const matchPattern = (value: string): MatchPatternResult => {
    const numberPattern = createNumberPattern(delimiter);
    const [fullMatch = null, integer = '0', decimal = '0'] =
      value.match(numberPattern) ?? [];

    const trimmedInteger =
      integer
        .replace(new RegExp(`${delimiter}`, 'gi'), '')
        .replace(/^[0]+/, '')
        .trim() || '0';
    const trimmedDecimal = decimal
      .replace(new RegExp(`${delimiter}`, 'gi'), '')
      .trim()
      .substring(0, precision);

    const formattedInteger = formatInteger(trimmedInteger);
    const formattedDecimal = formatDecimal(trimmedDecimal);

    return {
      fullMatch,
      integer,
      decimal,
      trimmedInteger,
      trimmedDecimal,
      trimmedFullMatch: precision
        ? `${trimmedInteger}.${trimmedDecimal}`
        : trimmedInteger,
      formattedInteger,
      formattedDecimal,
      formattedFullMatch: precision
        ? `${formattedInteger}.${formattedDecimal}`
        : formattedInteger,
    };
  };

  const fromRawToFormattedValue = (value: number): string => {
    const { formattedFullMatch } = matchPattern(`${value}`);

    return formattedFullMatch;
  };

  const [actualValue, setActualValue] = useState<number | undefined>(0);
  const [contentValue, setContentValue] = useState<string | undefined>(
    value !== undefined ? fromRawToFormattedValue(value) : '0.00',
  );

  const updateWithRawValue = (
    newValue: number,
  ): { value: number; contentValue: string } => {
    const { formattedFullMatch } = matchPattern(`${newValue}`);
    const value = newValue;
    const contentValue = formattedFullMatch;

    setActualValue(newValue);
    setContentValue(formattedFullMatch);

    return { value, contentValue };
  };

  const updateWithFormattedValue = (
    newValue: string,
  ): { value: number; contentValue: string } => {
    const { formattedFullMatch, trimmedFullMatch } = matchPattern(
      `${newValue}`,
    );
    const value = parseFloat(trimmedFullMatch);
    const contentValue = formattedFullMatch;

    setActualValue(value);
    setContentValue(contentValue);

    return { value, contentValue };
  };

  const updateWithoutFormattingValue = (newValue: string) => {
    const { trimmedFullMatch } = matchPattern(`${newValue}`);
    const value = parseFloat(trimmedFullMatch);
    const contentValue = newValue;

    setActualValue(parseFloat(trimmedFullMatch));
    setContentValue(contentValue);

    return { value, contentValue };
  };

  useEffect(() => {
    if (value !== actualValue) {
      updateWithRawValue(value ?? 0);
    }
  }, [value]);

  return useMemo(
    () =>
      [
        { value: actualValue, contentValue },
        {
          matchPattern,
          updateWithRawValue,
          updateWithFormattedValue,
          updateWithoutFormattingValue,
        },
      ] as const,
    [actualValue, contentValue],
  );
};
