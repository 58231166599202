import { convertPixelsToRem } from '../convert.helpers';
import { Unit } from '../theme.interface';

import rawTokens from './tokens.json';

export type FigmaSet<
  K extends keyof typeof rawTokens = keyof typeof rawTokens,
> = K extends `$${string}` ? never : K;

export type FigmaBorderKey = keyof typeof rawTokens.border;
export type FigmaColorKey = keyof typeof rawTokens.colors;
export type FigmaOpacityKey = keyof typeof rawTokens.opacity;
export type FigmaRadiusKey = keyof typeof rawTokens.radius;
export type FigmaShadowKey = keyof typeof rawTokens.shadow;
export type FigmaSpacingKey = keyof typeof rawTokens.spacing;
export type FigmaTypographyKey = keyof typeof rawTokens.typography;

export type FigmaTokenType =
  | 'color'
  | 'typography'
  | 'spacing'
  | 'border'
  | 'radius'
  | 'shadow'
  | 'opacity';

export type FigmaPixels = `${number}`;

export type FigmaToken<T extends FigmaTokenType = FigmaTokenType> = {
  type: T;
  value: FigmaTokenSchema[T];
};

export interface FigmaTokenSchema {
  color: FigmaColorSchema;
  typography: FigmaTypographySchema;
  spacing: FigmaSpacingSchema;
  border: FigmaBorderSchema;
  radius: FigmaBorderRadiusSchema;
  shadow: FigmaShadowSchema;
  opacity: FigmaOpacitySchema;
}

export interface FigmaTypographySchema {
  fontFamily: string;
  fontWeight: string;
  lineHeight: string;
  letterSpacing: string;
  fontSize: string;
}

export interface FigmaBorderSchema {
  width: FigmaPixels;
  color: string;
  style: string;
}

export interface FigmaShadowSchema {
  x: FigmaPixels;
  y: FigmaPixels;
  blur: FigmaPixels;
  spread: FigmaPixels;
  color: string;
  type: 'dropShadow';
}

export type FigmaOpacitySchema = '0' | '1' | `0.${number}`;

export type FigmaSpacingSchema = string;

export type FigmaBorderRadiusSchema = string;

export type FigmaColorSchema = string;

export type FigmaTokens = Record<FigmaSet, Record<string, FigmaToken>>;

function flattenVariables<T extends FigmaTokenType, O extends object>(
  _type: T,
  obj: O,
): Record<keyof O, FigmaTokenSchema[T]> {
  return Object.entries(obj).reduce(
    (acc, [key, { value }]) => ({ ...acc, [key]: value }),
    {},
  ) as Record<keyof O, FigmaTokenSchema[T]>;
}

export const tokens = {
  colors: flattenVariables('color', rawTokens.colors),
  typography: flattenVariables('typography', rawTokens.typography),
  spacing: flattenVariables('spacing', rawTokens.spacing),
  border: flattenVariables('border', rawTokens.border),
  radius: flattenVariables('radius', rawTokens.radius),
  shadow: flattenVariables('shadow', rawTokens.shadow),
  opacity: flattenVariables('opacity', rawTokens.opacity),
};

export const isFigmaColorKey = (key: unknown): key is FigmaColorKey => {
  return !!tokens.colors[key as FigmaColorKey];
};

export const parseColor = (key: string): string =>
  isFigmaColorKey(key) ? tokens.colors[key] : key;

export const parsePixels = (value: string | number, base?: number): Unit =>
  convertPixelsToRem(value, base);

export const parseWeight = (
  value: unknown,
  options: { fallback: number } = { fallback: 500 },
): number =>
  typeof value === 'string'
    ? {
        Bold: 700,
        SemiBold: 600,
        Medium: 500,
        Regular: 400,
      }[value] || options.fallback
    : options.fallback;

export const parseWeightProperty = <T extends object, K extends keyof T>(
  obj: T,
  options: { propertyNames: K[] } = { propertyNames: ['fontWeight' as K] },
): T & { [key in K]?: number } => {
  return options.propertyNames.reduce(
    (source, propertyName) => ({
      ...source,
      [propertyName]: parseWeight(source[propertyName]),
    }),
    obj,
  );
};

export const parsePixelsProperty = <T extends object, K extends keyof T>(
  obj: T,
  options: {
    base?: number;
    propertyNames: K[];
  },
): T & { [key in K]?: Unit } => {
  return options.propertyNames.reduce(
    (source, propertyName) => ({
      ...source,
      [propertyName]: convertPixelsToRem(source[propertyName], options.base),
    }),
    obj,
  );
};

export const parseColorProperty = <T extends object, K extends keyof T>(
  obj: T,
  propertyName: K,
): T => {
  const key = obj[propertyName];
  return {
    ...obj,
    [propertyName]: isFigmaColorKey(key) ? parseColor(key) : obj[propertyName],
  };
};
