import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styled from 'styled-components';

import { ActionCard, ActionCardTrailing, LinkProps } from '@hedgehog/ui/cards';
import { Icon } from '@hedgehog/ui/icons';
import { Paragraph } from '@hedgehog/ui/typography';

import { ClientName } from '../client-name/client-name.component';

dayjs.extend(relativeTime);

export interface ClientListItemProps {
  firstName: string;
  lastName: string;
  createdAt: string;
  canInvest?: boolean;
  active?: boolean;
  loading?: boolean;
}

export const ClientCardListItem = styled(ActionCard)<
  Pick<ClientListItemProps, 'loading' | 'active'>
>`
  position: relative;
  border: none;

  &:first-child::after {
    content: none;
  }

  ${({ active, theme }) => ({
    backgroundColor: active ? theme.colors.grey100 : theme.colors.white,
    [`${ActionCardTrailing} ${Icon}`]: {
      backgroundColor: active ? theme.colors.white : theme.colors.grey100,
    },
  })};

  &:after {
    position: absolute;
    bottom: 0;
    width: 100%;

    ${({ theme }) => ({
      height: theme.border.small.width,
      backgroundColor: theme.colors.grey100,
    })};
  }
`;

const ClientParagraph = styled(Paragraph)`
  margin-top: 0.25rem;
`;

export const ClientListItem = ({
  firstName,
  lastName,
  canInvest,
  createdAt,
  active = false,
  loading,
  children,
  ...props
}: ClientListItemProps & LinkProps): JSX.Element | null => {
  return (
    <ClientCardListItem
      type="light"
      loading={loading}
      title={
        <>
          <ClientName
            loading={loading}
            level="h6"
            firstName={firstName}
            lastName={lastName}
            canInvest={canInvest}
          />
          {children}
        </>
      }
      caption={
        <ClientParagraph small loading={loading} color="grey400">
          {dayjs().to(dayjs(createdAt))}
        </ClientParagraph>
      }
      active={active}
      trailingIcon="chevron-right"
      {...props}
    />
  );
};
