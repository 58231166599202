import { useEffect, useState } from 'react';

export const useOnScreen = (
  ref: React.MutableRefObject<HTMLElement | null>,
  externalDependencies: unknown[] = [],
): boolean => {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = new IntersectionObserver(([entry]) =>
    setIntersecting(entry.isIntersecting),
  );

  useEffect(() => {
    if (!ref.current) return;
    const { current: element } = ref;

    observer.observe(element);
    // Remove the observer as soon as the component is unmounted
    return () => {
      observer.disconnect();
    };
  }, [...externalDependencies]);

  return isIntersecting;
};
