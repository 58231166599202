import { gql } from '@apollo/client';

export const GET_COURSE = gql`
  query GetCourse($slug: String!) {
    course(args: { slug: $slug }) {
      id
      slug
      title
      imageUrl
      durationInMinutes
      upNext {
        id
        slug
      }
      topics {
        id
        slug
        title
        summary
        durationInMinutes
        progress
      }
    }
  }
`;
