import { CSSProp, css } from 'styled-components';

export type ResponsiveMode = 'desktop' | 'mobile' | 'desktop-and-mobile';

export type VisibleAtProps = { visibleAt?: ResponsiveMode };

export const visibleAtStyle = css<VisibleAtProps>`
  ${({ theme, visibleAt }): CSSProp =>
    visibleAt === 'desktop'
      ? {
          [`@media only screen and (max-width: ${theme.breakpoints.normal}px)`]:
            { display: 'none' },
        }
      : visibleAt === 'mobile'
      ? {
          [`@media only screen and (min-width: ${theme.breakpoints.normal}px)`]:
            { display: 'none' },
        }
      : {}}
`;
