export const SendIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 2H10L32 14V18L10 30H4L6 18H16V14H6L4 2Z" fill="currentColor" />
  </svg>
);
