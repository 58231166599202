export const PhoneIcon = (): JSX.Element => (
  <svg
    viewBox="0 0 14 14"
    width="100%"
    height="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 4V0H6V4L3.5 6.5L7.5 10.5L10 8H14V14H10C4.47715 14 0 9.52285 0 4Z"
      fill="currentColor"
    />
  </svg>
);

export default PhoneIcon;
