import styled, { CSSProp, css } from 'styled-components';

import { ThemeColorName, ratioToHex } from '@hedgehog/ui/themes';

import { Button } from '../Button/button.component';
import { LinkButton } from '../LinkButton/link-button.component';
import { hoverAppearance } from '../styles';

const SecondaryButtonBase = css<{ $hoverColor?: ThemeColorName }>`
  ${({ theme }): CSSProp => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.black,
  })}

  &[disabled]:not([disabled='false']) {
    ${({ theme }): CSSProp => ({
      backgroundColor: `${theme.colors.black}${ratioToHex(0.2)}`,
    })};
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    ${hoverAppearance}
  }
`;

export const SecondaryButton = styled(Button).attrs({
  $hoverColor: 'light400',
})`
  ${SecondaryButtonBase}
`;

export const SecondaryLinkButton = styled(LinkButton).attrs({
  $hoverColor: 'light400',
})`
  ${SecondaryButtonBase}
`;
