import { useEffect, useState } from 'react';
import styled, { CSSObject } from 'styled-components';

import {
  getWalletIndex,
  useZeroDevWallet,
} from '@hedgehog/browser/shared/account-abstraction';
import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import {
  isRequestErrorResponse,
  useCreateClientMutation,
  useCreateClientOrderMutation,
  useFeatureFlagsQuery,
  usePartnerSettingsQuery,
  useUpdateClientWalletMutation,
} from '@hedgehog/data-access/partners';
import { useAppData } from '@hedgehog/data-access/partners';
import { CreatePartnerClientRequest } from '@hedgehog/data-access/partners-types';
import { Button } from '@hedgehog/ui/buttons';
import { Icon } from '@hedgehog/ui/icons';
import { BottomSheetContent } from '@hedgehog/ui/modals';
import { Heading4 } from '@hedgehog/ui/typography';

import { FormConfirm, ModalForm } from '../../components';
import {
  CreateClientForm,
  CreateClientFormData,
  initialFormData,
} from '../create-client-form/create-client-form.container';

import { ConfirmationMessage } from './confirmation-message';

export interface CreateClientModalProps {
  submitModal: () => void;
  cancelModal: () => void;
}

const necessaryProperties: (keyof CreateClientFormData)[] = [
  'firstName',
  'lastName',
  'email',
  'confirmEmail',
  'country',
];

const Modal = styled(ModalForm)`
  & ${BottomSheetContent} {
    // Warning: If this is removed then you will experience the bug described in HOG-3012.
    overflow: unset;
  }
`;

const StyledIcon = styled(Icon)`
  ${({ theme }): CSSObject => ({
    marginTop: '0.5rem', // additional to standard 1.5rem of container
    backgroundColor: theme.colors.success,
    borderRadius: '2rem',
  })}
`;

const ConfirmationContainer = styled.div`
  ${(): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    alignItems: 'center',
    marginBottom: '-0.5rem',
  })}
`;

export const CreateClientModal = (
  props: CreateClientModalProps,
): JSX.Element => {
  const { activePartner } = useAppData();
  const [createPartnerClient, { data: createdClient, isLoading, error }] =
    useCreateClientMutation();
  const [createPartnerClientOrder] = useCreateClientOrderMutation();
  const { data: settings } = usePartnerSettingsQuery({
    partnerId: activePartner?.id ?? '',
  });
  const [formData, updateFormData] = useState<CreateClientFormData>({
    ...initialFormData,
  });
  const [disabled, toggleDisabled] = useState(true);
  const [updateClientWallet] = useUpdateClientWalletMutation();

  const handleChange = (newFormData: CreateClientFormData) =>
    updateFormData(newFormData);

  const { data: featureFlags } = useFeatureFlagsQuery();

  const { autoloadWallet, address } = useZeroDevWallet();

  const handleSubmit = async () => {
    if (!activePartner) return;
    const { investorType, partnerClientType, assetRoundClassIds, ...user } =
      formData;
    const request: CreatePartnerClientRequest = {
      ...user,
      sendInvitationEmail:
        assetRoundClassIds.length > 0 || !!formData.sendInvitationEmail,
      investorType,
      partnerClientType,
    };

    // Create the partner client. Capture the response so that we can create the orders for the client.
    const partnerClient = await createPartnerClient({
      partnerId: activePartner.id,
      ...request,
    }).unwrap();

    // Create the orders for the client, allowing the client to invest in the asset.
    await Promise.all(
      assetRoundClassIds.map((assetRoundClassId) => {
        return createPartnerClientOrder({
          partnerId: activePartner.id,
          clientId: partnerClient.id,
          quantity: 0,
          assetRoundClassId,
        }).catch(() => {
          /**
           * Nothing to do in this case because creating a client and adding orders is not an atomic operation.
           */
        });
      }),
    );

    // If PARTNER_MANAGED_CONTRACT_ACCOUNTS is enabled, then connect to the clients account. This will cause
    // `address` to be loaded.
    const feature = featureFlags?.find(
      (flag) => flag.name === FeatureFlagNames.partners_client_managed_wallets,
    );

    if (feature?.enabled && feature.value?.includes(activePartner.slug)) {
      const walletIndex = BigInt(
        getWalletIndex({
          id: partnerClient.id,
          createdAt: partnerClient.createdAt,
        }),
      );
      autoloadWallet({
        index: walletIndex,
      });
    }
  };

  const handleClose = () => {
    props.submitModal();
  };

  useEffect(() => {
    const { country } = formData;
    const validatedProperties = [...necessaryProperties];
    const isStandaloneOnboarding = !settings?.INVESTMENTS && settings?.KYC;
    const hasSetInvestorType = settings?.SET_INVESTOR_TYPE;

    if (country === 'GBR' && !isStandaloneOnboarding && hasSetInvestorType) {
      validatedProperties.push('investorType');
    }

    if (country === 'USA') {
      validatedProperties.push('state');
    } else {
      // Disabled for tenant token project
      //validatedProperties.push('accredited');
    }

    const hasNecessaryFields = validatedProperties.every(
      (propertyName) => !!formData[propertyName],
    );

    toggleDisabled(!hasNecessaryFields);
  }, [formData]);

  useEffect(() => {
    if (address) {
      updateClientWallet({
        partnerId: activePartner?.id ?? '',
        clientId: createdClient?.id ?? '',
        address,
      });
    }
  }, [address]);

  const isConfirmed = !!createdClient?.user;

  return (
    <Modal
      submitModal={handleSubmit}
      closeModal={handleClose}
      modalSubmitted={!!createdClient}
      disabled={disabled}
      loading={isLoading}
      heading={isConfirmed ? undefined : 'Add Client'}
      submitButtonText="Add Client"
      closeButtonText="Close"
      footbar={!isConfirmed}
      error={
        error
          ? isRequestErrorResponse(error)
            ? error.data.message
            : 'Something went wrong'
          : undefined
      }
    >
      {createdClient?.user ? (
        <FormConfirm
          heading={
            <>
              <StyledIcon icon="check-circle" size="l" color="white" />
              <Heading4>Client added!</Heading4>
            </>
          }
          subheading={
            <ConfirmationContainer>
              <ConfirmationMessage
                welcomeEmailSent={
                  formData.assetRoundClassIds.length > 0 ||
                  !!formData.sendInvitationEmail
                }
                firstName={createdClient.user?.firstName}
                email={createdClient.user?.email}
              />
              <Button onClick={handleClose}>Got it</Button>
            </ConfirmationContainer>
          }
        />
      ) : (
        <CreateClientForm
          partnerName={activePartner?.name || ''}
          onChange={handleChange}
        />
      )}
    </Modal>
  );
};
