import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { decodeAbiParameters, getAddress, parseEther, zeroAddress } from 'viem';
import { useContractRead } from 'wagmi';

import { useAppData, useFundsV2Query } from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';

import { useMarketBidParams } from './use-market-bid-params.hook';

export type UseMarketBidListingOutput = [
  {
    fundId: string;
    listingId: string;
    fundName: string;
    fundDescription: string;
    unfundedCommitmentAmount: bigint;
    contributionAmount: bigint;
    priceAmount: bigint;
  } | null,
  { isLoading: boolean; isError: boolean },
];

export const useMarketBidListing = (): UseMarketBidListingOutput => {
  const { activePartnerId } = useAppData();
  const [{ listingId, fundId }] = useMarketBidParams();
  const { data: funds } = useFundsV2Query({ partnerId: activePartnerId || '' });
  const { address, name, description } =
    funds?.find((fund: FundV2) => fund.fundId === fundId) || {};

  const { data, ...rest } = useContractRead({
    address: getAddress(address || zeroAddress),
    abi: IFund.abi,
    functionName: 'getListings',
    args: [],
  });
  if (!data) {
    return [null, rest];
  }

  const [listingIds, listingHexData] = (data ?? []) as [
    bigint[],
    `0x${string}`[],
  ];
  const currentIndex = listingIds.indexOf(BigInt(listingId ?? 0));

  if (currentIndex === -1) {
    return [null, rest];
  }

  const [
    unfundedCommitmentAmount = BigInt(0),
    contributionAmount = BigInt(0),
    priceAmount = BigInt(0),
  ] = decodeAbiParameters(
    [
      {
        name: 'commitment',
        type: 'uint256',
      },
      {
        name: 'contribution',
        type: 'uint256',
      },
      {
        name: 'price',
        type: 'uint256',
      },
    ],
    listingHexData[currentIndex],
  );

  return [
    {
      fundId: fundId!,
      listingId: listingId!,
      fundName: name ?? '',
      fundDescription: description ?? '',
      unfundedCommitmentAmount: unfundedCommitmentAmount,
      contributionAmount: contributionAmount,
      priceAmount,
    },
    rest,
  ];
};
