import { css, CSSObject } from 'styled-components';

import { parseBorderToShadow } from '@hedgehog/ui/themes';

export const InputBase = css<{ errors?: string[] }>`
  display: block;
  box-sizing: border-box;
  text-overflow: ellipsis;

  width: 100%;
  height: 3rem;
  border: none;

  ${({ errors, theme }): CSSObject => {
    const boxShadow = parseBorderToShadow(theme.inputs.border, {
      color: errors?.length ? theme.colors.error : 'transparent',
    });

    return {
      boxShadow,
      color: errors?.length ? theme.colors.error : theme.colors.black,
      backgroundColor: theme.colors.grey100,
      borderRadius: theme.radius.normal,
      ...theme.inputs.typography,
      fontWeight: 400,
      '&:focus, &:hover': {
        boxShadow: parseBorderToShadow(theme.inputs.border, {
          color: errors?.length ? theme.colors.error : theme.colors.shadow100,
        }),
      },
      '&:disabled': {
        color: theme.colors.shadow100,
      },
    };
  }}

  -webkit-appearance: none;
  outline: none;
  transition: border 100ms ease-in-out;

  &:focus-within {
    outline: 1px solid ${({ theme }): string => theme.colors.secondary};
  }
`;

export const InputReset = css`
  display: block;
  box-sizing: border-box;
  font-size: inherit;
  font-weight: inherit;
  text-overflow: ellipsis;

  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
`;
