import React, { ReactNode } from 'react';
import styled from 'styled-components';

const CustomTabs = styled.div<TabsProps>`
  display: flex;
  flex-flow: row wrap;
  gap: 0.25rem;
  border-radius: 0.5rem;
  background-color: ${({ appearance, theme }) =>
    appearance === 'compact' ? theme.colors.grey100 : 'transparent'};
  padding: ${({ appearance }) => (appearance === 'compact' ? '0.25rem' : '0')};
`;

export type TabAppearance = 'normal' | 'compact';

type TabsProps = {
  appearance?: TabAppearance;
  small?: boolean;
  children: ReactNode;
  className?: string;
};

export const Tabs = ({
  appearance = 'normal',
  small,
  children,
  className,
}: TabsProps): JSX.Element => {
  const newChildren = React.Children.map<ReactNode, ReactNode>(
    children,
    (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          appearance,
          small,
        } as any);
      }
      return null;
    },
  );
  return (
    <CustomTabs appearance={appearance} className={className}>
      {newChildren}
    </CustomTabs>
  );
};
