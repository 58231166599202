import { useLocalStorageValue } from '@hedgehog/data-access/hooks';

import { NativeMobileExperiences } from './native-mobile-experiences.enum';

export const LOCALSTORAGE_MOBILE_NATIVE_KEY = 'native_mobile_experiences';

export const useIsNativeMobileExperience = (
  experience: NativeMobileExperiences,
): boolean => {
  // Get the value from localstorage. This will update automatically if the storage value changes.
  const nativeMobileExperiences = useLocalStorageValue(
    LOCALSTORAGE_MOBILE_NATIVE_KEY,
  );

  // Parse the value from a string into an array of strings.
  const experiences: Array<string> = JSON.parse(
    nativeMobileExperiences || '[]',
  );

  // The local storage value must be an array of strings.
  if (!Array.isArray(experiences)) {
    return false;
  }

  // Find the experience (if it exists).
  return !!experiences.find((value) => value === experience);
};
