export const MoreIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 16C20 18.2091 18.2091 20 16 20C13.7909 20 12 18.2091 12 16C12 13.7909 13.7909 12 16 12C18.2091 12 20 13.7909 20 16Z"
      fill="currentColor"
    />
    <path
      d="M8 16C8 18.2091 6.20914 20 4 20C1.79086 20 0 18.2091 0 16C0 13.7909 1.79086 12 4 12C6.20914 12 8 13.7909 8 16Z"
      fill="currentColor"
    />
    <path
      d="M32 16C32 18.2091 30.2091 20 28 20C25.7909 20 24 18.2091 24 16C24 13.7909 25.7909 12 28 12C30.2091 12 32 13.7909 32 16Z"
      fill="currentColor"
    />
  </svg>
);

export default MoreIcon;
