import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { formatDuration, differenceInDays, addDays } from 'date-fns';
import { generatePath } from 'react-router-dom';
import { formatEther, getAddress, zeroAddress } from 'viem';
import { useContractRead } from 'wagmi';

import { BidStatus } from '@hedgehog/data-access/partners';
import { SecondaryLinkButton } from '@hedgehog/ui/buttons';
import { LinkOrNotProps } from '@hedgehog/ui/cards';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import { BidderListItem, CardListItem } from '../../components';

type ListingListItemAmount = {
  unfundedCommitment: number;
  contribution: number;
  price: number;
};

export type ListingListItemProps = StandardProps<
  {
    address: string;
    listingId: number;
    fundId?: string;
    title?: string;
    subtitle?: string;
    amount: ListingListItemAmount;
    loading?: boolean;
    expiresAt?: Date;
    createBidRedirectTo?: string;
    manageBidRedirectTo?: string;
    showBids?: boolean;
    showExpiry?: boolean;
  } & LinkOrNotProps
>;

export const ListingListItem = ({
  address,
  amount,
  listingId,
  fundId,
  createBidRedirectTo,
  manageBidRedirectTo,
  showBids = true,
  loading = false,
  showExpiry = true,
  expiresAt = addDays(new Date(), 14),
  ...cardProps
}: ListingListItemProps): JSX.Element => {
  const {
    data,
    isLoading: areBidsLoading,
    isError,
  } = useContractRead({
    address: getAddress(address || zeroAddress),
    abi: IFund.abi,
    functionName: 'getBids',
    args: [BigInt(listingId)],
  });

  const bids = (data || []) as {
    bidder: string;
    price: bigint;
    id: number;
    status: number;
  }[];
  const notRejectedBids = bids.filter(
    ({ status }) => ![BidStatus.rejected, BidStatus.withdrawn].includes(status),
  );
  const basicPathParams = {
    fundId,
    listingId: `${listingId}`,
  };

  return (
    <CardListItem
      {...cardProps}
      body={[
        [
          'Unfunded commitment',
          currencies.formatMoney(amount.unfundedCommitment),
        ],
        ['Net Asset Value', currencies.formatMoney(amount.contribution)],
      ]}
      footer={[
        <>
          <Heading level="h5" color="grey400">
            Asking price
          </Heading>
          <Heading level="h5" loading={loading}>
            {currencies.formatMoney(amount.price)}
          </Heading>
        </>,
        createBidRedirectTo && (
          <SecondaryLinkButton
            to={
              createBidRedirectTo
                ? generatePath(createBidRedirectTo, basicPathParams)
                : undefined
            }
          >
            Make a bid
          </SecondaryLinkButton>
        ),
        showExpiry && (
          // Hardcoded 7 days from current time for now until we implement expiry in the contract
          <Paragraph loading={loading}>
            Listings expires in{' '}
            {formatDuration({
              days: differenceInDays(expiresAt, new Date()),
            })}
          </Paragraph>
        ),
      ]}
      accordionTitle={<Paragraph small>Bids</Paragraph>}
      accordion={
        showBids && notRejectedBids.length > 0
          ? notRejectedBids
              .sort(
                (a, b) =>
                  Number(formatEther(b.price)) - Number(formatEther(a.price)),
              )
              .map((bid, index) => (
                <BidderListItem
                  loading={areBidsLoading}
                  to={
                    manageBidRedirectTo
                      ? generatePath(manageBidRedirectTo, {
                          ...basicPathParams,
                          bidId: bid.id,
                          bidderAddress: bid.bidder,
                        })
                      : undefined
                  }
                  key={bid.bidder}
                  highest={index === 0}
                  bidderAddress={bid.bidder}
                  offeredAmount={Number(formatEther(bid.price))}
                />
              ))
          : null
      }
    />
  );
};
