import styled, { CSSObject, CSSProp } from 'styled-components';

import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Heading } from '@hedgehog/ui/typography';

export const AnswerButton = styled(SecondaryButton).attrs({
  $hoverColor: 'shadow100',
})<{
  active: boolean;
}>`
  ${({ theme, active }): CSSObject => ({
    width: '100%',
    maxWidth: 'unset',
    backgroundColor: active ? theme.colors.black : theme.colors.white,
    color: active ? theme.colors.white : theme.colors.black,
    border: 'none',
  })};
`;

export const AnswerOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
`;

export const QuestionHeading = styled(Heading)`
  font-weight: 500;
  margin-top: 0;

  ${({ theme }): CSSProp => ({
    color: theme.colors.black,
  })}
`;
