import styled from 'styled-components';
import { formatEther } from 'viem';

import { LinkButton } from '@hedgehog/ui/buttons';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

const ListingCardWrapper = styled(Card)`
  width: 100%;

  & > ${CardContent} {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
`;

const BidSummary = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
`;

export type LiveListingWidgetProps = StandardProps<{
  bidCount: number;
  price: number;
}>;

export const LiveListingWidget = ({
  bidCount,
  price,
  ...props
}: LiveListingWidgetProps): JSX.Element => {
  const bidOrBids = bidCount === 1 ? 'bid' : 'bids';

  return (
    <ListingCardWrapper {...props}>
      <Paragraph weight="bold">Live Listing</Paragraph>
      <BidSummary>
        <LinkButton to="../secondary-market" small color="grey300">
          {bidCount} {bidOrBids}
        </LinkButton>
        <Paragraph weight="bold" color="neutral">
          {currencies.formatMoney(Number(formatEther(BigInt(price))))}
        </Paragraph>
      </BidSummary>
    </ListingCardWrapper>
  );
};
