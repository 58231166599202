import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledList = styled.ul`
  position: relative;
  width: 100%;
  padding-left: 0;
  margin: 0;
`;

type ListProps = {
  children: ReactNode;
  className?: string;
};

export const List = ({ children, className }: ListProps): JSX.Element => {
  return <StyledList className={className}>{children}</StyledList>;
};

export default List;
