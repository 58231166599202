import { gql } from '@apollo/client';

export const CREATE_DECLARATION = gql`
  mutation CreateDeclaration(
    $type: DeclarationType!
    $investorType: InvestorType
  ) {
    declare(args: { type: $type, investorType: $investorType }) {
      type
    }
  }
`;
