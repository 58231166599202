import styled from 'styled-components';
import { formatEther, getAddress, zeroAddress } from 'viem';

import {
  CardListItem,
  ListingListItem,
} from '@hedgehog/browser/shared/funds/feature-marketplace';
import {
  ListingStatus,
  useClientListings,
} from '@hedgehog/data-access/partners';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const ListingsContainer = styled.div`
  display: grid;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export interface ClientLiveListingsProps {
  isLoading?: boolean;
  clientAddress?: string;
}

export const ClientLiveListings = ({
  isLoading,
  clientAddress,
}: ClientLiveListingsProps): JSX.Element | null => {
  const { data: listings, isLoading: areListingsLoading } = useClientListings({
    clientAddress: getAddress(clientAddress || zeroAddress),
    status: [ListingStatus.open],
  });

  return (
    <>
      <Heading level="h5">Live Listings</Heading>

      <ListingsContainer>
        {isLoading || areListingsLoading ? (
          <CardListItem
            loading={true}
            title="Placeholder Title"
            body={[
              [
                'Unfunded commitment',
                <Paragraph loading={true}>1 000 000</Paragraph>,
              ],
              [
                'Net Asset Value',
                <Paragraph loading={true}>100 000</Paragraph>,
              ],
            ]}
            footer={[
              <>
                <Heading level="h5" color="grey400">
                  Asking price
                </Heading>
                <Heading level="h5" loading={true}>
                  100 000
                </Heading>
              </>,
              <Paragraph loading={true}>Placeholder</Paragraph>,
            ]}
          />
        ) : listings?.length > 0 ? (
          listings.map((listing) => (
            <ListingListItem
              key={`${listing.fundContractAddress}-${listing.listingId}`}
              loading={isLoading || areListingsLoading}
              title={listing.fundName}
              listingId={Number(listing.listingId)}
              fundId={listing.fundId}
              manageBidRedirectTo="funds/:fundId/listings/:listingId/bids/:bidId/overview"
              address={listing.fundContractAddress || zeroAddress}
              amount={{
                unfundedCommitment: Number(formatEther(listing.commitment)),
                contribution: Number(formatEther(listing.contribution)),
                price: Number(formatEther(listing.price)),
              }}
            />
          ))
        ) : (
          <Paragraph>This client doesn't have any live listings.</Paragraph>
        )}
      </ListingsContainer>
    </>
  );
};
