import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: 2.5rem;
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 20rem;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

export type JumbotronPageProps = StandardProps<object>;

export const JumbotronPage = ({
  className,
  children,
}: JumbotronPageProps): JSX.Element => (
  <Container>
    <Wrapper className={className}>
      <InnerWrapper>{children || <Outlet />}</InnerWrapper>
    </Wrapper>
  </Container>
);
