import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { styles } from '@hedgehog/ui/buttons';
import { Card, CardContent } from '@hedgehog/ui/cards';
import { Heading, Note } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

const Wrapper = styled(Card).attrs<{
  $hoverColor?: string;
}>({ $hoverColor: 'shadow200' })`
  & > ${CardContent} {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    pointer-events: none;
  }

  cursor: pointer;
  &:hover,
  &:focus {
    ${styles.hoverAppearance}
  }
`;

export type ListingQuantityItemProps = StandardProps<{
  loading?: boolean;
  max?: number;
  ratio?: number;
  highlight?: 'ratio' | 'value';
  onClick?: MouseEventHandler<HTMLDivElement>;
}>;

export const ListingQuantityItem = ({
  max = 1,
  ratio = 1,
  highlight = 'ratio',
  onClick,
  loading = false,
}: ListingQuantityItemProps): JSX.Element => {
  const valueInUsd = currencies.formatMoney(ratio * max);
  const percentage = `${ratio * 100}%`;

  const title = highlight === 'ratio' ? percentage : valueInUsd;
  const note = highlight === 'ratio' ? valueInUsd : percentage;
  return (
    <Wrapper onClick={onClick}>
      <Heading level="h4" loading={loading}>
        {title}
      </Heading>
      <Note loading={loading}>{note}</Note>
    </Wrapper>
  );
};
