import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useEffect, useMemo } from 'react';
import { Abi, Address, formatEther, getAddress, zeroAddress } from 'viem';
import { useContractReads } from 'wagmi';

import {
  useClientQuery,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';

export type FundPosition = {
  commitment: string;
  contribution: string;
  fundId: string;
  name: string;
  description: string;
  address: Address | null;
};

export interface UseClientFundPositionsOutput {
  clientWalletAddress?: Address | null;
  positions: FundPosition[];
  isError: boolean;
  isLoading: boolean;
  error: Error | null;
}

export const useClientFundPositions = ({
  clientId,
  partnerId,
}: {
  clientId: string;
  partnerId: string;
}): UseClientFundPositionsOutput => {
  const { data: client } = useClientQuery({ partnerId, clientId });
  const { data: funds = [] } = useFundsV2Query({ partnerId });

  const clientWalletAddress = getAddress(
    client?.wallets.find(
      (wallet) => wallet.type === 'PARTNER_MANAGED_CONTRACT_ACCOUNT',
    )?.address ?? zeroAddress,
  );
  const configs = funds
    .filter((fund) => !!fund?.address)
    .map(({ address }) => getAddress(address ?? zeroAddress))
    .map((fundContractAddress) => ({
      address: fundContractAddress,
      abi: IFund.abi as Abi,
      functionName: 'balanceOf',
      args: [clientWalletAddress ?? zeroAddress],
    }));

  const {
    data: balances = [],
    isError,
    isLoading,
    error,
    refetch: refetchBalances,
  } = useContractReads({
    contracts: configs,
    enabled: false, // will only run when refetchBalances is called
  });

  useEffect(() => {
    if (!clientWalletAddress) return;
    refetchBalances();
  }, [clientWalletAddress]);

  const positions = balances
    .map((listing, i) => {
      if (listing.status !== 'success') return null;
      const [commitment, contribution] = listing.result as [string, string];
      if (Number(commitment) === 0) return null;
      if (Number(contribution) === 0) return null;
      const fund = funds[i] as FundV2;
      return {
        ...fund,
        commitment: formatEther(BigInt(commitment)),
        contribution: formatEther(BigInt(contribution)),
        address: fund.address ? (getAddress(fund.address) as Address) : null,
      };
    })
    .filter((position): position is FundPosition => !!position);

  return useMemo(
    () => ({
      clientWalletAddress,
      positions,
      isError,
      isLoading,
      error,
    }),
    [balances, clientWalletAddress],
  );
};
