import { useState } from 'react';
import styled from 'styled-components';

import { PrimaryButton } from '@hedgehog/ui/buttons';
import { TextInput } from '@hedgehog/ui/inputs';
import { currencies } from '@hedgehog/utils/formats';

const Form = styled.form`
  padding-top: 2rem;
`;

export interface CreateFundFormData {
  name: string;
  description: string;
  country: string;
  currency: string;
  size: string | number;
  thumbnail: string;
}

export interface CreateFundFormProps {
  onSubmit: (data: CreateFundFormData) => void;
  isLoading?: boolean;
}

export const CreateFundForm = ({
  onSubmit,
  isLoading,
}: CreateFundFormProps): JSX.Element => {
  const [formData, setFormData] = useState<CreateFundFormData>({
    name: '',
    description: '',
    country: '',
    currency: '',
    size: '',
    thumbnail: '',
  });

  const createFormControl = (name: keyof CreateFundFormData) => ({
    name,
    value: formData[name],
    onChange: (value: string) =>
      setFormData((state) => ({ ...state, [name]: value })),
  });

  const handleSubmit = (e: Event) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      size: parseInt(`${formData.size}`) * 100,
    });
  };

  const fromFormatToNumber = (formattedValue: string): number =>
    Number(formattedValue.replace('$', '').replace(/,/g, '')) ?? 0;

  const format = (value: number | string) =>
    currencies.formatMoney(Number(value), {
      maximumFractionDigits: 0,
    });

  const handleSizeChange = (amount: string): void => {
    const cleanAmount = fromFormatToNumber(amount);
    setFormData({ ...formData, size: `${cleanAmount}` });
  };

  return (
    <Form>
      <TextInput label="Name" {...createFormControl('name')} />
      <TextInput label="Description" {...createFormControl('description')} />
      <TextInput label="Country" {...createFormControl('country')} />
      <TextInput label="Currency" {...createFormControl('currency')} />
      <TextInput
        label="size"
        name="size"
        value={format(formData.size)}
        onChange={(size) => handleSizeChange(size)}
      />
      <TextInput label="Thumbnail URL" {...createFormControl('thumbnail')} />

      <br />

      <PrimaryButton type="submit" onClick={handleSubmit} disabled={isLoading}>
        Save
      </PrimaryButton>
    </Form>
  );
};
