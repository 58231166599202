import { useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  isRequestErrorResponse,
  useClientsQuery,
  useCreateClientOrderMutation,
} from '@hedgehog/data-access/partners';
import { useAppData } from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import { AssetCard, AssetCardTokenPrice } from '@hedgehog/ui/assets';
import { Button, PrimaryButton } from '@hedgehog/ui/buttons';
import { QuantityInput, SelectorInput } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';
import { spacing } from '@hedgehog/utils/sizes';

import { ModalErrorNote, ModalForm, FormConfirm } from '../../components';

const InlineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  & > :nth-child(1) {
    flex: 1 1 40%;
  }
  & > :nth-child(2) {
    flex: 1 1 60%;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: ${spacing.xxl};
`;

interface CreateOrderModalProps {
  submitModal: () => void;
  cancelModal: () => void;
  assetName: string;
  assetThumbnail?: string;
  assetRoundClassId: string;
  pricePerToken: number;
  minimumInvestmentAmount: number;
  totalInvestedAmount?: number;
  totalAvailableAmount: number;
  currency?: currencies.Currency;
}

export const ORDER_BUY_LIMIT_PCT = 0.1;
export const ORDER_BUY_LIMIT_AMOUNT = 100000;

export const CreateOrderModal = ({
  submitModal,
  cancelModal,
  pricePerToken,
  minimumInvestmentAmount,
  assetName,
  assetThumbnail,
  totalInvestedAmount,
  totalAvailableAmount,
  assetRoundClassId,
  currency,
}: CreateOrderModalProps): JSX.Element | null => {
  const { activePartner } = useAppData();
  const [quantity, setQuantity] = useState<number>();
  const [limit, setLimit] = useState<number>(1);
  const [client, setClient] = useState<PartnerClient>();
  const { clients = [] } = useClientsQuery(
    {
      partnerId: activePartner?.id || '',
    },
    {
      selectFromResult: ({ data }) => ({
        clients: data?.data,
        paging: data?.paging,
      }),
      skip: !activePartner,
    },
  );
  const [
    createPartnerClientOrder,
    {
      isLoading: createOrderLoading,
      error: createOrderError,
      isSuccess: submitted,
    },
  ] = useCreateClientOrderMutation();

  useEffect(() => {
    // Cannot do validation if no totalInvestedAmount available
    if (typeof totalInvestedAmount !== 'number') {
      cancelModal();
    }
  }, []);

  // TODO: all this validation is repeated in investor app and in backend.
  // Find a way to share validation rules across all three
  useEffect(() => {
    // Not above available amount
    const roundTokensLeft = Math.floor(
      (totalAvailableAmount - (totalInvestedAmount || 0)) / pricePerToken,
    );

    // 100k limit
    const limitByPrice = Math.floor(ORDER_BUY_LIMIT_AMOUNT / pricePerToken);

    // 10% limit
    const limitBySize =
      Math.floor(totalAvailableAmount * ORDER_BUY_LIMIT_PCT) / pricePerToken;

    setLimit(Math.floor(Math.min(limitBySize, limitByPrice, roundTokensLeft)));
  }, [pricePerToken, totalAvailableAmount, totalInvestedAmount]);

  const handleSubmit = async () => {
    if (!activePartner) return;
    if (!client) return;

    createPartnerClientOrder({
      partnerId: activePartner?.id,
      clientId: client.id,
      quantity: quantity || 0,
      assetRoundClassId,
    });
  };

  const handleClose = () => {
    submitModal();
  };

  return (
    <ModalForm
      closeModal={cancelModal}
      modalSubmitted={submitted}
      heading="Initiate Order"
      actions={
        !submitted ? (
          <PrimaryButton
            trailingIcon="send"
            trailingSize="small"
            onClick={handleSubmit}
            loading={createOrderLoading}
            disabled={!client}
          >
            Send
          </PrimaryButton>
        ) : (
          <Button onClick={handleClose}>Close</Button>
        )
      }
      notes={
        createOrderError ? (
          <ModalErrorNote
            error={
              isRequestErrorResponse(createOrderError)
                ? createOrderError.data.message
                : ''
            }
          />
        ) : (
          <Paragraph small color="grey400">
            Double check if it is the right client, asset, and values.
          </Paragraph>
        )
      }
    >
      {submitted ? (
        <FormConfirm
          imageSrc="https://assets.hedgehog-invest.com/images/sign.png"
          heading="Investment Initiated"
          subheading={
            <Paragraph>
              We sent {client?.user?.firstName} an email to download the app and
              complete the investment: <strong>{client?.user?.email}</strong>.
            </Paragraph>
          }
          note="If they can't find it, they might want to check their spam folder."
        />
      ) : (
        <FormWrapper>
          <AssetCard
            type="compact"
            title={assetName}
            thumbnails={assetThumbnail ? [assetThumbnail] : []}
            caption={
              <AssetCardTokenPrice
                label="Minimum investment"
                amount={minimumInvestmentAmount}
                currency={currency}
              />
            }
            action={
              <Button small onClick={handleClose}>
                Choose another
              </Button>
            }
          />
          <InlineWrapper>
            <Paragraph>On behalf of</Paragraph>
            <SelectorInput
              placeholder="Select a client"
              ariaLabel="client-selector"
              name="client"
              options={clients.map((item) => ({
                ...item,
                label: `${item.user?.firstName} ${item.user?.lastName}`,
                value: item.id,
              }))}
              onChange={(newValue: PartnerClient) => setClient(newValue)}
            />
          </InlineWrapper>
          <InlineWrapper>
            <Paragraph>Choose token amount</Paragraph>
            <QuantityInput
              min={0}
              max={limit}
              value={quantity}
              onChange={setQuantity}
            />
          </InlineWrapper>
        </FormWrapper>
      )}
    </ModalForm>
  );
};
