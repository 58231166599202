import { AnyAction, combineReducers } from 'redux';

import { baseApi } from './apis/base.api';
import { settingsReducer, authReducer } from './reducers';

const appReducer = combineReducers({
  settings: settingsReducer,
  auth: authReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export type RootStore = ReturnType<typeof appReducer>;

const reducer = (
  state: RootStore | undefined,
  action: AnyAction,
): RootStore => {
  return appReducer(state, action);
};

export default reducer;
