import { ForwardedRef, forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Card, CardContent, CardThumbnails } from '@hedgehog/ui/cards';
import { ThinProgressBar } from '@hedgehog/ui/charts';
import { Heading } from '@hedgehog/ui/typography';

import { AssetLargeCardProps } from './asset-card.interface';
import { CardAction, CardBody, CardCaption, CardIcons } from './styles';

const CardProgress = styled(ThinProgressBar)`
  width: 100%;
`;

const CardTitle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.normal })}
`;

export const AssetLargeCard = styled(
  forwardRef(
    (
      {
        title,
        caption,
        action,
        body,
        thumbnails = [],
        categories: categoryOrCategories = [],
        partners: partnerOrPartners = [],
        loading = false,
        className,
        ...cardCrops
      }: AssetLargeCardProps,
      ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>,
    ): JSX.Element => {
      const partners = Array.isArray(partnerOrPartners)
        ? partnerOrPartners
        : [partnerOrPartners];
      const categories = Array.isArray(categoryOrCategories)
        ? categoryOrCategories
        : [categoryOrCategories];

      return (
        <Card ref={ref} className={className} {...cardCrops} withShadow>
          <CardTitle>
            {(categories.length > 0 || partners.length > 0) && (
              <CardIcons icons={partners} themes={categories} />
            )}
            <Heading level="h3" loading={loading}>
              {title}
            </Heading>
          </CardTitle>

          <CardProgress color="grey100" value={1} />

          {(caption || body || action) && (
            <CardBody>
              {caption && <CardCaption>{caption}</CardCaption>}
              {body && body}
              {action && <CardAction>{action}</CardAction>}
            </CardBody>
          )}

          <CardThumbnails
            loading={loading}
            images={thumbnails}
            limit={thumbnails.length}
          />
        </Card>
      );
    },
  ),
)`
  ${({ theme }): CSSObject => ({
    [`& ${CardContent}`]: {
      gap: theme.spacing.normal,
    },
  })}
`;
