import { format, parseISO } from 'date-fns';

import { useClientQuery } from '@hedgehog/data-access/partners';
import { UUID } from '@hedgehog/shared/types';
import { BlockContainer } from '@hedgehog/ui/layouts';
import { List } from '@hedgehog/ui/lists';
import { Loader } from '@hedgehog/ui/loaders';
import { formatSourceOfFunds } from '@hedgehog/utils/formats';

import { ClientDataListItem } from '../../components';

type ClientProfilePersonalInformationBlockProps = {
  partnerId: UUID;
  clientId: UUID;
};

export const ClientProfilePersonalInformationBlock = ({
  partnerId,
  clientId,
}: ClientProfilePersonalInformationBlockProps) => {
  const { data: client, isFetching } = useClientQuery({
    partnerId,
    clientId,
  });

  if (isFetching || !client?.user) {
    return <Loader />;
  }

  const {
    email,
    dob,
    addressLine1,
    addressLine2,
    town,
    postcode,
    sourceOfFunds,
    taxIdentifier,
    taxResidency,
  } = client.user;
  return (
    <BlockContainer>
      <List>
        <ClientDataListItem label="Email" value={email} icon="at-symbol" />
        <ClientDataListItem
          label="Date of birth"
          value={dob ? format(parseISO(dob), 'dd MMM YYY') : ''}
          icon="calendar"
        />
        <ClientDataListItem
          label="Address"
          value={[addressLine1, addressLine2, town, postcode]
            .filter(Boolean)
            .join(', ')}
          icon="home"
        />
        <ClientDataListItem
          label="Source of funds"
          value={sourceOfFunds ? formatSourceOfFunds(sourceOfFunds) : ''}
          icon="money"
        />
        <ClientDataListItem
          label="Tax residency"
          value={taxResidency}
          icon="map-pin"
        />
        <ClientDataListItem
          label="National insurance number"
          value={taxIdentifier}
          icon="user"
        />
      </List>
    </BlockContainer>
  );
};
