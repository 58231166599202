import { ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { useQueryResult, QueryResult } from '@hedgehog/browser/shared/utils';
import {
  ContractIssuance,
  ContractRawIssuance,
} from '@hedgehog/shared/blockchain/resident-token';
import { StandardProps } from '@hedgehog/ui/utils';

import { getContractIssuances } from '../../helpers';
import {
  throwContractNotAvailableYet,
  throwWalletNotAvailableYet,
} from '../../throwers';

import { ContractContext } from './contract.context';

export type ContractProviderProps = StandardProps<{
  address: string;
  abi: object[];
}>;

export const ContractProvider = ({
  address: contractAddress,
  abi: contractAbi,
  children,
}: ContractProviderProps): JSX.Element => {
  const { address: walletAddress, jsonRpcProvider } = useAccountAbstraction();
  const [contract, setContract] = useState<ethers.Contract | null>(null);
  const [issuances, issuancesControls] = useQueryResult<ContractIssuance[]>();

  useEffect(() => {
    if (!contractAddress) return;
    if (!contractAbi) return;

    setContract(
      new ethers.Contract(contractAddress, contractAbi, jsonRpcProvider),
    );
  }, [contractAddress, contractAbi, jsonRpcProvider]);

  const context = useMemo(() => {
    const getIssuances = async (
      forAddress?: string,
    ): Promise<QueryResult<ContractIssuance[]>> => {
      try {
        issuancesControls.setLoading(true);
        if (!contract) throwContractNotAvailableYet();
        if (!walletAddress) throwWalletNotAvailableYet();

        const rawIssuances = await getContractIssuances<ContractRawIssuance>(
          contract,
          forAddress || walletAddress,
        );
        const issuances = ContractIssuance.fromRaw(rawIssuances);
        issuancesControls.setData(issuances);
        return { data: issuances, error: null, loading: false };
      } catch (err) {
        issuancesControls.setError(err);
        return { data: null, error: err, loading: false };
      } finally {
        issuancesControls.setLoading(false);
      }
    };

    return {
      contract: { data: contract, loading: !contract, error: null },
      issuances,
      getIssuances,
    };
  }, [contract, issuances, walletAddress, jsonRpcProvider]);

  return (
    <ContractContext.Provider value={context}>
      {children}
    </ContractContext.Provider>
  );
};
