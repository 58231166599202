import jwtDecode from 'jwt-decode';

export function hasTokenExpired(token: string): boolean {
  try {
    const { exp: expiredTimeInSeconds } = jwtDecode<{ exp: number }>(token);
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    return currentTimeInSeconds >= expiredTimeInSeconds;
  } catch (error) {
    return true;
  }
}
