import { ReactNode, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { CSSProp, styled, useTheme } from 'styled-components';

import { Loader } from '@hedgehog/ui/loaders';
import { isLight, shimmerGradient } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import {
  StandardProps,
  visibleAtStyle,
  ResponsiveMode,
} from '@hedgehog/ui/utils';

import { ColumnLayout } from '../column-layout/column-layout.component';
import { Column } from '../column-layout/column.component';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';

import { JumbotronTopbar } from './jumbotron-topbar.component';

const JumbotronWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  margin: 0 1rem 1rem;

  ${({ theme }): CSSProp => ({
    [`@media only screen and (min-width: ${theme.breakpoints.normal}px)`]: {
      margin: '0 4rem 4rem',
    },
  })}
`;

const JumbotronInnerWrapper = styled(ColumnLayout)`
  grid-gap: 1rem;
  ${({ theme }): CSSProp => ({
    [`@media only screen and (min-width: ${theme.breakpoints.normal}px)`]: {
      gridTemplateColumns: '50% 50%',
      gridTemplateRows: '100%',
    },
  })}
`;

const JumbotronColumn = styled(Column)<{ visibleAt?: ResponsiveMode }>`
  display: flex;
  max-width: 100%;
  overflow: hidden;

  ${visibleAtStyle}
`;

const Jumbotron = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  gap: 1.5rem;
  padding: 0rem 1rem;

  ${({ theme }): CSSProp => ({
    [`@media only screen and (min-width: ${theme.breakpoints.normal}px)`]: {
      padding: '2rem 2rem 3rem',
    },
  })}

  ${({ theme }): CSSProp => ({
    borderRadius: theme.radius.large,
    backgroundColor: theme.colors.secondary,
    [`@media only screen and (max-width: ${theme.breakpoints.normal - 1}px)`]: {
      [`${Heading}, ${Paragraph}`]: {
        display: 'none',
      },
    },
  })}
`;

const JumbotronImageWrapper = styled.div`
  flex: 0 1;
  padding: 2.5rem;
  min-width: 15rem;
  max-width: 20rem;
`;

const JumbotronImage = styled.img<{ loading?: boolean }>`
  flex: 0 0 auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  ${({ loading }): CSSProp => (loading ? shimmerGradient : null)}
`;

export interface JumbotronVisibleAtParams {
  topbar?: ResponsiveMode;
  jumbotron?: ResponsiveMode;
  actions?: ResponsiveMode;
}

export type JumbotronLayoutProps = StandardProps<{
  jumbotronUrl?: string;
  jumbotronTitle?: string;
  jumbotronCaption?: string;
  links?: ReactNode[] | ReactNode;
  loading?: boolean;
  logotypeUrl?: string;
  visibleAt?: JumbotronVisibleAtParams;
}>;

export const JumbotronLayout = ({
  logotypeUrl,
  links,
  loading = false,
  visibleAt,
  jumbotronUrl,
  jumbotronTitle,
  jumbotronCaption,
  className,
  children,
}: JumbotronLayoutProps): JSX.Element => {
  const { colors } = useTheme();

  return (
    <JumbotronWrapper className={className}>
      <JumbotronTopbar
        visibleAt={visibleAt}
        logotypeUrl={logotypeUrl}
        loading={loading}
        actions={links}
      />
      <JumbotronInnerWrapper gridTemplateColumns="1fr">
        <JumbotronColumn
          visibleAt={visibleAt?.jumbotron ?? 'desktop-and-mobile'}
        >
          <Jumbotron>
            {jumbotronUrl && (
              <JumbotronImageWrapper>
                <JumbotronImage src={jumbotronUrl} loading={loading} />
              </JumbotronImageWrapper>
            )}
            {jumbotronTitle && (
              <Heading
                level="h2"
                loading={loading}
                color={isLight(colors.secondary) ? 'black' : 'white'}
              >
                {jumbotronTitle}
              </Heading>
            )}
            {jumbotronCaption && (
              <Paragraph
                loading={loading}
                color={isLight(colors.secondary) ? 'shadow600' : 'light600'}
              >
                {jumbotronCaption}
              </Paragraph>
            )}
          </Jumbotron>
        </JumbotronColumn>
        <Column>
          <Suspense
            fallback={
              <LoadingContainer>
                <Loader />
              </LoadingContainer>
            }
          ></Suspense>
          {children || <Outlet />}
        </Column>
      </JumbotronInnerWrapper>
    </JumbotronWrapper>
  );
};
