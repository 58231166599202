import { SourceOfFunds } from '@hedgehog/data-access/graphql';

export const SourceOfFundsLabels: Record<SourceOfFunds, string> = {
  [SourceOfFunds.SALARY]: 'Salary',
  [SourceOfFunds.SAVINGS]: 'Savings',
  [SourceOfFunds.PENSION]: 'Pension',
  [SourceOfFunds.INHERITANCE_AND_GIFTS]: 'Inheritance and gifts',
  [SourceOfFunds.BUSINESS_PROFITS]: 'Business profits',
  [SourceOfFunds.INVESTMENT_PROCEEDS]: 'Investment proceeds',
  [SourceOfFunds.BANK_LOAN]: 'Bank loan',
};

export const formatSourceOfFunds = (value: SourceOfFunds): string => {
  return SourceOfFundsLabels[value];
};
