import styled, { CSSObject } from 'styled-components';

import {
  convertToNumber,
  parseBorder,
  shimmerGradient,
} from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';
import { clamp } from '@hedgehog/utils/numbers';

const CardThumbnailsWrapper = styled.div<{
  loading?: boolean;
  columns?: number;
}>`
  display: grid;
  width: 100%;
  height: 100%;
  max-height: 10.25rem;

  ${({ columns = 1 }): CSSObject => ({
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: '0.5rem',
  })}

  ${({ loading }) => (loading ? shimmerGradient : '')}
`;

export const CardThumbnail = styled.div<{
  src?: string;
  forceFullWidthImage?: boolean;
}>`
  background-position: center;
  background-size: cover;
  min-height: 10.25rem;
  height: 100%;
  ${({ src, forceFullWidthImage, theme }): CSSObject => ({
    backgroundImage: src
      ? `url(${src})`
      : `https://assets.hedgehog-invest.com/pattern.png`,
    '&:first-child:last-child': {
      padding: forceFullWidthImage
        ? undefined
        : `0 ${theme.cards.padding.x} ${theme.cards.padding.y}`,
      margin: forceFullWidthImage
        ? undefined
        : `0 -${theme.cards.padding.x} -${theme.cards.padding.y}`,
    },
    '&:not(:first-child:last-child)': {
      border: parseBorder(theme.border.small, { color: theme.colors.grey100 }),
      borderRadius: theme.radius.normal,
    },
    borderRadius: forceFullWidthImage ? theme.radius.normal : '',
    border: forceFullWidthImage
      ? parseBorder(theme.border.small, { color: theme.colors.grey100 })
      : undefined,
  })}
`;

export type CardThumbnailsProps = StandardProps<{
  images?: string[];
  limit?: number | `${number}`;
  children?: never;
  loading?: boolean;
  forceFullWidthImage?: boolean;
}>;

export const CardThumbnails = styled(
  ({
    images = [],
    limit = images.length,
    className,
    loading = false,
    forceFullWidthImage = false,
  }: CardThumbnailsProps): JSX.Element => (
    <CardThumbnailsWrapper
      loading={loading}
      className={className}
      columns={clamp(1, convertToNumber(limit, 1))(images.length)}
    >
      {images.slice(0, convertToNumber(limit, 1)).map((src) => (
        <CardThumbnail
          key={src}
          src={src}
          forceFullWidthImage={forceFullWidthImage}
        />
      ))}
    </CardThumbnailsWrapper>
  ),
)``;
