import * as Sentry from '@sentry/react';
import { useState } from 'react';

import { useTokenIncomeUserOperation } from './use-token-income-contract.hook';

type CallbackParams = {
  reports: CreateIncomeReportRequest[];
};

export type CreateIncomeReportRequest = {
  tokenAddress: string;
  beneficiaryAddress: string;
  title: string;
  description: string;
  currency: number;
  amount: number;
};

type Callback = (params: CallbackParams) => Promise<void>;

type Result = {
  loading: boolean;
  error: string | undefined;
  success: boolean;
};

export const useCreateIncomeReportUserOperation = (): [Callback, Result] => {
  const userOperation = useTokenIncomeUserOperation({
    functionName: 'createIncomeReports',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const callback = async ({ reports }: CallbackParams): Promise<void> => {
    let userOp;
    try {
      setLoading(true);
      try {
        userOp = await userOperation([
          reports.map(({ tokenAddress }) => tokenAddress),
          reports.map(({ beneficiaryAddress }) => beneficiaryAddress),
          reports.map(({ title }) => title),
          reports.map(({ description }) => description),
          reports.map(({ currency }) => currency),
          reports.map(({ amount }) => amount),
        ]);
        console.log('Successfully created userOp', userOp);
        setSuccess(true);
      } catch (err) {
        console.log('failed to report income payment', err);
        Sentry.captureException(err);
        setError('Failed to report income payment');
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }

    return userOp;
  };

  return [callback, { loading, error, success }];
};
