import { useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { getWalletIndex } from '@hedgehog/browser/shared/account-abstraction';
import {
  useAppData,
  useClientsQuery,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import { ModalWrapper } from '@hedgehog/ui/modals';
import { StandardProps } from '@hedgehog/ui/utils';

import context, { MarketListingContextType } from './context';
import { useMarketListingBalanceFactory } from './use-market-listing-balance.hook';
import { useMarketListingParamsFactory } from './use-market-listing-params.hook';

export type MarketListingModalProps = StandardProps<{ rootPath: string }>;

export const useNavigateTo = () => {
  const { navigateTo } = useContext(context);
  return navigateTo;
};

export const MarketListingModal = ({
  rootPath,
  children,
}: MarketListingModalProps): JSX.Element => {
  const { activePartnerId } = useAppData();
  const [balance, balanceControls] = useMarketListingBalanceFactory();
  const [params, paramsControls] = useMarketListingParamsFactory({ rootPath });

  const { clients = [], isLoading: clientsLoading } = useClientsQuery(
    {
      partnerId: activePartnerId || '',
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        clients: data?.data,
        paging: data?.paging,
        ...rest,
      }),
      skip: !activePartnerId,
    },
  );

  const { funds = [], isLoading: fundsLoading } = useFundsV2Query(
    {
      partnerId: activePartnerId || '',
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        funds: data,
        ...rest,
      }),
      skip: !activePartnerId,
    },
  );

  useEffect(() => {
    if (funds.length === 0) return;
    const selectedFund = funds.find(
      (fund: FundV2) => fund.fundId === params.fundId,
    );

    console.debug(
      'you are now interacting with following fund address',
      selectedFund?.address,
    );
    paramsControls.updateParamsWithFundAddress({
      fund: selectedFund,
      address: selectedFund?.address ?? '',
    });
  }, [params.fundId, funds]);

  useEffect(() => {
    if (clients.length === 0) return;
    const client = clients.find(({ id }) => id === params.clientId);
    if (!client) return;
    const walletIndex = BigInt(
      getWalletIndex({ id: client.id, createdAt: client.createdAt }),
    );
    const { address = '' } =
      client.wallets.find(
        ({ type }) => type === 'PARTNER_MANAGED_CONTRACT_ACCOUNT',
      ) || {};

    if (params.clientWalletAddress !== address) {
      paramsControls.updateParams({
        clientWalletAddress: address,
        clientWalletIndex: walletIndex,
      });
    }
  }, [params.clientId, clients]);

  const value: MarketListingContextType = useMemo(
    () => ({
      balance,
      params,
      clientsLoading,
      fundsLoading,
      ...balanceControls,
      ...paramsControls,
    }),
    [balance, params],
  );

  return (
    <context.Provider value={value}>
      <ModalWrapper backdropTo="../..">{children ?? <Outlet />}</ModalWrapper>
    </context.Provider>
  );
};
