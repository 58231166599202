import { ReactNode } from 'react';
import styled from 'styled-components';

import { AssetCategory } from '@hedgehog/shared/types';
import { StatusBadge } from '@hedgehog/ui/badges';
import { Card as UiCard } from '@hedgehog/ui/cards';
import { ThinProgressBar } from '@hedgehog/ui/charts';
import { LayeredIcons } from '@hedgehog/ui/icons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Card = styled(UiCard)`
  padding: 1rem;

  & > div {
    gap: 1rem;
  }
`;

const CardHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 0.5rem;
`;

const CardProgressBar = styled(ThinProgressBar)``;

const CardFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export type PortfolioCardProps = {
  name: ReactNode | string;
  category?: AssetCategory;
  footerText?: string;
  statusText?: string;
  progress?: number;
} & (PortfolioCardWithEmojiProps | PortfolioCardWithIconProps);

export interface PortfolioCardWithIconProps {
  statusEmoji?: never;
  statusIcon?: ReactNode;
}

export interface PortfolioCardWithEmojiProps {
  statusEmoji?: string;
  statusIcon?: never;
}

export const PortfolioCard = (props: PortfolioCardProps): JSX.Element => {
  return (
    <Card>
      <CardHeader>
        <LayeredIcons themes={props.category ? [props.category] : []} />
        <Heading level="h6">{props.name}</Heading>
      </CardHeader>
      <CardProgressBar color={'secondary'} value={props.progress || 0} />
      <CardFooter>
        <Paragraph color="grey300" small>
          {props.footerText || ''}
        </Paragraph>
        <StatusBadge text={props.statusText || ''} emoji={props.statusEmoji} />
      </CardFooter>
    </Card>
  );
};
