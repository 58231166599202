import { useEffect, useMemo, useState } from 'react';

import {
  Asset,
  AssetRound,
  AssetRoundClass,
} from '@hedgehog/data-access/asset-types';
import { useAppData, useAssetsQuery } from '@hedgehog/data-access/partners';
import { UUID } from '@hedgehog/shared/types';
import { SelectorInput } from '@hedgehog/ui/inputs';
import { Loader } from '@hedgehog/ui/loaders';

type AssetSelectorInputProps = {
  isMulti?: boolean;
  name: string;
  onChange?: (value: UUID[]) => void;
  // countryCode is used to filter the round classes by country
  countryCode?: string;
  placeholder?: string;
};

export const AssetSelectorInput = ({
  isMulti = true,
  placeholder = 'Select assets...',
  name,
  countryCode,
  onChange,
}: AssetSelectorInputProps): JSX.Element => {
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';
  const { data: assets, isLoading } = useAssetsQuery(
    { partnerId },
    { skip: !partnerId },
  );
  const [assetIds, setAssetIds] = useState<UUID[]>([]);

  const filteredClasses = useMemo(() => {
    if (!assets) return undefined;
    const selectedAssets = assets.filter((asset) =>
      assetIds.some((id) => id === asset.id),
    );

    let classes = selectedAssets
      // Get all the round classes for the selected assets
      .reduce((previousClasses: AssetRoundClass[], asset: Asset) => {
        return [
          ...previousClasses,
          ...asset.rounds.reduce(
            (previous: AssetRoundClass[], round: AssetRound) => {
              return [...previous, ...round.roundClasses];
            },
            [],
          ),
        ];
      }, []);

    if (countryCode) {
      classes = classes.filter(
        (entity: AssetRoundClass) => entity.country === countryCode,
      );
    }
    return classes;
  }, [countryCode, assets, assetIds]);

  useEffect(() => {
    if (!filteredClasses) return;
    onChange && onChange(filteredClasses.map((entity) => entity.id));
  }, [filteredClasses]);

  const handleOnChange = (newValue?: Asset | Asset[]) => {
    // The user cleared the entire input
    if (!newValue) {
      return setAssetIds([]);
    }

    // The user selected many assets value.
    if (Array.isArray(newValue)) {
      return setAssetIds(newValue.map((asset) => asset.id));
    }

    // The user is selecting one asset only so replace.
    return setAssetIds([newValue.id]);
  };

  if (isLoading || !assets) {
    return <Loader />;
  }

  return (
    <SelectorInput
      isMulti={isMulti}
      name={name}
      placeholder={placeholder}
      options={assets.map((asset) => ({
        ...asset,
        label: asset.name,
        value: asset.id,
      }))}
      onChange={handleOnChange}
    />
  );
};
