import styled, { CSSObject } from 'styled-components';

import {
  parseBorderToShadow,
  parsePadding,
  shimmerGradient,
} from '@hedgehog/ui/themes';

export interface TagProps {
  active?: true;
  type?: 'dark' | 'light';
  loading?: boolean;
}

export const Tag = styled.span<TagProps>`
  ${({ active = false, type = 'dark', theme }): CSSObject => ({
    ...theme.typography.body_small,
    color: type === 'light' ? theme.colors.light800 : theme.colors.black,
    padding: parsePadding({ x: theme.spacing.xsmall, y: theme.spacing.xsmall }),
    borderRadius: theme.radius.normal,

    boxShadow: parseBorderToShadow(theme.border.small, {
      color: type === 'light' ? theme.colors.light400 : theme.colors.shadow200,
    }),

    ...(active
      ? {
          cursor: 'pointer',
          '&:hover': {
            boxShadow: parseBorderToShadow(theme.border.normal, {
              color:
                type === 'light'
                  ? theme.colors.light400
                  : theme.colors.shadow200,
            }),
          },
          [`&:active,&:focused`]: {
            boxShadow: parseBorderToShadow(theme.border.normal, {
              color:
                type === 'light'
                  ? theme.colors.light800
                  : theme.colors.shadow600,
            }),
          },
        }
      : {}),
  })}

  ${({ loading = false }) => loading && shimmerGradient}
`;
