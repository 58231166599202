import { createContext, ReactNode, useMemo } from 'react';

export interface EnvironmentProviderProps {
  environment: Record<string, any>;
  children?: ReactNode | ReactNode;
}

export interface EnvironmentContext {
  [key: string]: any;
}

export const EnvironmentContext = createContext<EnvironmentContext>({});

export const EnvironmentProvider = ({
  environment,
  children,
}: EnvironmentProviderProps): JSX.Element => {
  const context = useMemo(() => environment, [environment]);

  return (
    <EnvironmentContext.Provider value={context}>
      {children}
    </EnvironmentContext.Provider>
  );
};
