import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useEffect, useState } from 'react';
import {
  Abi,
  Address,
  Hex,
  decodeAbiParameters,
  formatEther,
  getAddress,
  parseEther,
  zeroAddress,
} from 'viem';
import { useContractReads, usePublicClient } from 'wagmi';

import { FundV2 } from '@hedgehog/data-access/partners-types';

import { useAppData } from '../../providers';
import { useFundsV2Query } from '../../store/apis';
import {
  BidStatus,
  BidStatusLiteral,
  Listing,
  ListingStatus,
  ListingStatusLiteral,
} from '../../types';

export type UseClientListingsOutput = {
  data: Listing[];
  isLoading: boolean;
  isError: boolean;
};

export const useClientListings = ({
  clientAddress,
  status,
}: {
  clientAddress: Address;
  status?: ListingStatus[];
}): UseClientListingsOutput => {
  const [listings, setListings] = useState<UseClientListingsOutput['data']>([]);
  const { activePartnerId } = useAppData();
  const { data: funds } = useFundsV2Query({ partnerId: activePartnerId || '' });
  const publicClient = usePublicClient();
  const [areListingsLoading, setListingsLoading] = useState(false);
  const fundConfigs = funds?.map((fund) => ({
    address: getAddress(fund?.address ?? zeroAddress),
    abi: IFund.abi as Abi,
    functionName: 'getListings',
    args: [getAddress(clientAddress)],
    enabled: !!clientAddress && !!fund?.address,
  }));

  // This will check for listings created by `clientAddress` for each fund
  const {
    data,
    isLoading: areContractsLoading,
    isFetching: areContractsFetching,
    isFetchedAfterMount: areContractsFetchedAfterMount,
    ...rest
  } = useContractReads({
    scopeKey: 'funds/listings',
    contracts: fundConfigs ?? [],
    watch: true,
    enabled:
      !!fundConfigs &&
      !!clientAddress &&
      clientAddress !== getAddress(zeroAddress),
  });

  const fetchListingsWithBids = async (
    fund: FundV2,
    listingId: bigint,
    listingHexData: Hex,
  ): Promise<Listing> => {
    const [
      commitment = BigInt(0),
      contribution = BigInt(0),
      price = BigInt(0),
      status = 0,
    ] = decodeAbiParameters(
      [
        {
          name: 'commitmentTokenAmount',
          type: 'uint256',
        },
        {
          name: 'contributionTokenAmount',
          type: 'uint256',
        },
        {
          name: 'price',
          type: 'uint256',
        },
        {
          name: 'status',
          type: 'uint8',
        },
      ],
      listingHexData,
    );

    const bids = (await publicClient.readContract({
      address: getAddress(fund?.address ?? zeroAddress),
      abi: IFund.abi,
      functionName: 'getBids',
      args: [listingId],
    })) as { id: number; bidder: Address; price: bigint; status: number }[];

    return {
      listingId: listingId,
      fundId: fund.fundId,
      fundName: fund.name,
      fundDescription: fund.description,
      fundContractAddress: getAddress(fund.address ?? zeroAddress),
      commitment,
      contribution,
      price,
      status: ListingStatus[status] as ListingStatusLiteral,
      bids: bids.map((bid) => ({
        ...bid,
        fundId: fund.fundId,
        fundName: fund.name,
        fundDescription: fund.description,
        commitment,
        contribution,
        bidPrice: bid.price,
        listingPrice: price,
        status: BidStatus[bid.status] as BidStatusLiteral,
      })),
    };
  };

  const loadListings = async (): Promise<void> => {
    if (!funds) return;
    if (!data) return;

    const listings = await Promise.all(
      data.map(async (fundData, fundIndex: number) => {
        if (!fundData || fundData.error) return [];

        const [listingIds, listingHexData] = (
          fundData as {
            result: Array<Array<bigint | Hex>>;
          }
        ).result;

        const listings = await Promise.all(
          listingIds.map(async (id, index) => {
            if (!listingHexData[index] || listingHexData[index] === '0x')
              return null;

            return fetchListingsWithBids(
              funds[fundIndex],
              id as bigint,
              listingHexData[index] as Hex,
            );
          }),
        );

        return listings.filter(Boolean) as Listing[];
      }),
    );

    if (status !== undefined && status !== null && status.length > 0) {
      setListings(
        listings
          .flat()
          .filter((listing) => status.includes(ListingStatus[listing.status])),
      );
      return;
    }

    setListings(listings.flat());
  };

  useEffect(() => {
    if (!funds) return;
    if (!data) return;
    try {
      setListingsLoading(true);
      loadListings();
    } catch (error) {
      console.error(error);
    } finally {
      setListingsLoading(false);
    }
  }, [data]);

  const isLoading =
    areListingsLoading ||
    areContractsLoading ||
    (areContractsFetchedAfterMount ? false : areContractsFetching);

  if (!data) {
    return { data: [], isLoading, ...rest };
  }

  return {
    data: listings,
    isLoading,
    ...rest,
  };
};
