import { createSelector } from 'reselect';

import { RootStore } from '../../reducer';
import { Selector } from '../../utils/selector';

import { AuthReducerState } from './auth.slice';

const selectState = (state: RootStore): AuthReducerState => state.auth;

export const selectAuthToken: Selector<AuthReducerState['token']> =
  createSelector(selectState, (state) => state.token);
