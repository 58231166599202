import { useContext } from 'react';

import { AccountAbstractionContext, context } from '../providers';

export const useAccountAbstraction = (): AccountAbstractionContext => {
  const {
    address,
    jsonRpcProvider,
    userOpBuilder,
    loading,
    sendUserOperation,
    clear,
  } = useContext(context);

  return {
    address,
    jsonRpcProvider,
    userOpBuilder,
    loading,
    sendUserOperation,
    clear,
  };
};
