import { useAppData, useClientsQuery } from '@hedgehog/data-access/partners';
import { SelectorInput } from '@hedgehog/ui/inputs';
import { StandardProps } from '@hedgehog/ui/utils';

export type ClientAutocompleteProps = StandardProps<
  {
    label?: string;
    placeholder?: string;
    ariaLabel?: string;
    name?: string;
    onChange?: (value: any) => void;
  },
  never
>;

export const ClientAutocomplete = ({
  onChange,
  ...inputProps
}: ClientAutocompleteProps): JSX.Element => {
  const { activePartnerId } = useAppData();
  const { clients = [] } = useClientsQuery(
    {
      partnerId: activePartnerId || '',
    },
    {
      selectFromResult: ({ data }) => ({
        clients: data?.data,
        paging: data?.paging,
      }),
      skip: !activePartnerId,
    },
  );

  const handleChange = (value: any): void => {
    if (onChange) onChange(value);
  };

  return (
    <SelectorInput
      {...inputProps}
      options={clients.map((item) => ({
        ...item,
        label: `${item.user?.firstName} ${item.user?.lastName}`,
        value: item.id,
      }))}
      onChange={handleChange}
    />
  );
};
