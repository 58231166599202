import styled from 'styled-components';

import { useCurrentFund } from '@hedgehog/data-access/partners';

import { FundTeamMembers } from '../../../containers';

const Container = styled.div`
  padding: 1rem 0rem 1rem 0rem;
`;

export const FundTeamMembersPage = (): JSX.Element => {
  const { hashedBlockchainReference } = useCurrentFund();

  return (
    <Container>
      {hashedBlockchainReference && (
        <FundTeamMembers fundHashedBlockchainRef={hashedBlockchainReference} />
      )}
    </Container>
  );
};
