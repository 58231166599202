import { CognitoUserPool } from 'amazon-cognito-identity-js';

import { useEnvironment } from '@hedgehog/ui/environment';

export const useCognitoUserPool = (): CognitoUserPool => {
  const {
    cognito: { clientId: cognitoClientId, userPoolId: cognitoUserPoolId },
  } = useEnvironment();
  return new CognitoUserPool({
    UserPoolId: cognitoUserPoolId,
    ClientId: cognitoClientId || '',
  });
};
