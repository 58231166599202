import styled, { CSSObject } from 'styled-components';

import { CardContent } from '@hedgehog/ui/cards';
import { fromBreakpoint } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';
import { Widget } from '@hedgehog/ui/widgets';

import { OnboardingMetrics, PartnerEventsList } from '../../containers';

const EventsWidget = styled(Widget)`
  flex-grow: 1;
  flex-shrink: 1;
  ${({ theme }): CSSObject => ({
    padding: 0,
    borderRadius: 0,

    ...fromBreakpoint(theme.breakpoints.tablet, {
      borderRadius: '1rem',
    }),

    [`& > ${CardContent}`]: {
      padding: 0,
    },
  })};
`;

const CustomHeading = styled(Heading)`
  flex: 0 0 auto;
  ${({ theme }): CSSObject => ({
    paddingLeft: '1.5rem',

    ...fromBreakpoint(theme.breakpoints.tablet, {
      paddingLeft: 0,
    }),
  })}
`;

const Row = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
`;

const MetricsRow = styled(Row)`
  flex: 0 0 auto;
`;

const EventsRow = styled(Row)`
  flex-grow: 1;
  flex-shrink: 1;
  gap: 1.5rem;
`;

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const DashboardMetricsPage = (): JSX.Element => {
  return (
    <Container>
      <MetricsRow>
        <OnboardingMetrics />
      </MetricsRow>

      <EventsRow>
        <CustomHeading level="h6">Events</CustomHeading>
        <EventsWidget>
          <PartnerEventsList />
        </EventsWidget>
      </EventsRow>
    </Container>
  );
};

export default DashboardMetricsPage;
