import { IFundManager } from '@hedgehoginvest/funds';
import { ethers } from 'ethers';
import { useMemo } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { Any } from '@hedgehog/shared/types';
import { useEnvironment } from '@hedgehog/ui/environment';

type UserOperationCallback = (values?: Any[]) => Promise<Any>;

export const useFundManagerContract = (): ethers.Contract => {
  // Get fund manager contract address from environment, this is the same for everyone.
  const {
    fundManager: { address },
  } = useEnvironment();
  if (!address) {
    throw new Error(
      'Expected FundManager contract address to be defined in environment',
    );
  }

  const { jsonRpcProvider } = useAccountAbstraction();

  return useMemo(() => {
    return new ethers.Contract(address, IFundManager.abi, jsonRpcProvider);
  }, [address]);
};

export type UseFundManagerUserOperationParams = {
  functionName: string;
};

export const useFundManagerUserOperation = ({
  functionName,
}: UseFundManagerUserOperationParams): UserOperationCallback => {
  const { sendUserOperation } = useAccountAbstraction();
  const contract = useFundManagerContract();
  return useMemo(() => {
    return (values: Any[] = []): Promise<Any> => {
      return sendUserOperation((account) => {
        return account.execute(
          contract.address,
          0,
          contract.interface.encodeFunctionData(functionName, values),
        );
      });
    };
  }, [sendUserOperation, functionName]);
};
