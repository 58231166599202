import { useEffect, useState } from 'react';

export const useLocalStorageValue = (key: string): string | undefined => {
  const initialValue = localStorage.getItem(key) || undefined;
  const [value, setValue] = useState<string | undefined>(initialValue);

  useEffect(() => {
    const onUpdate = (): void =>
      setValue(localStorage.getItem(key) || undefined);

    window.addEventListener('storage', onUpdate);
    return () => {
      window.removeEventListener('storage', onUpdate);
    };
  }, []);

  return value;
};
