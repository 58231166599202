import styled from 'styled-components';

import { Heading3, Heading6 } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

const BalanceHolder = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
`;

const BalanceTotalAmount = styled(Heading3)``;

const BalanceDifference = styled(Heading6)<{ gain?: true; loss?: true }>`
  color: ${({ theme, gain, loss }): string =>
    gain
      ? theme.colors.success
      : loss
      ? theme.colors.error
      : theme.colors.black};
`;

export type PortfolioBalanceProps = StandardProps<
  {
    totalAmount?: number;
    differenceAmount?: number;
    loading?: boolean;
  },
  never
>;

const { formatMoney } = currencies;
export const PortfolioBalance = ({
  totalAmount = 0,
  differenceAmount = 0,
  loading = false,
  ...props
}: PortfolioBalanceProps): JSX.Element => {
  return (
    <BalanceHolder className={props.className}>
      <BalanceTotalAmount loading={loading}>
        {formatMoney(totalAmount || 0, { currency: 'USD' })}
      </BalanceTotalAmount>
      <BalanceDifference
        gain={differenceAmount > 0 || undefined}
        loss={differenceAmount < 0 || undefined}
        loading={loading}
      >
        {/* Removing difference for secondary market demo */}
        {/* {differenceAmount > 0 ? '+' : differenceAmount ? '-' : ''}
        {formatMoney(differenceAmount || 0, {
          currency: 'USD',
          signDisplay: 'never',
        })} */}
      </BalanceDifference>
    </BalanceHolder>
  );
};
