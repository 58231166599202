import styled, { CSSObject } from 'styled-components';

import { CountdownStopIcon } from '@hedgehog/ui/icons';

import { StatusText } from './styles';

const Countdown = styled(CountdownStopIcon)`
  ${({ theme }): CSSObject => ({
    color: theme.colors.grey300,
    width: '1.25rem',
    margin: '0 6px',
  })}
`;

export const AssetComingSoonStatus = (): JSX.Element => {
  return (
    <>
      <StatusText>Spring 2024</StatusText>
      <Countdown />
    </>
  );
};
