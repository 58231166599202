import { EnvironmentVariables } from './environment.type';

export const environment: EnvironmentVariables = {
  type: 'staging',
  production: false,
  baseUrl: 'https://partners.qa.hedgehog-invest.com',
  api: {
    url: 'https://api.qa.hedgehog-invest.com/',
  },
  cognito: {
    clientId: '57bimqo6vn9a3htrs5hn2r5ur2',
    userPoolId: 'eu-west-2_qQ9NZN9g7',
    userPoolUrl: 'https://qa-hedgehog-invest.auth.eu-west-2.amazoncognito.com',
  },
  web3auth: {
    clientId:
      'BB4YRmY57Jsb7xBKb8q4376eX25E79nXmEDKsKguVNCrMMBRu64bXMJ96F8nP69WKFx-OlzaE0O16-h1oSyIGOM',
    network: 'testnet',
    verifier: 'hedgehog-partner-portal-staging',
  },
  polygon: {
    chainId: '0xaa36a7',
  },
  tenantToken: {
    address: '0x15d884529cf6C29E0dD35A0851F08B3941391377',
  },
  sentry: {
    dsn: 'https://736b57ca0e114a01a1fc80e00801ca2c@o1179604.ingest.sentry.io/4504928354762752',
  },
  blockchain: {
    explorer: 'https://sepolia.etherscan.io/',
    chainId: '0xaa36a7',
    rpcUrl:
      'https://eth-sepolia.g.alchemy.com/v2/1EYNZY_LZsbNpzUzfFOguhtdHEzFHUCL',
  },
  erc4337: {
    entryPointAddress: '0x5FF137D4b0FDCD49DcA30c7CF57E578a026d2789',
    accountFactoryAddress: '0xb3A5e2ADae7974619BA1aE8aF03397D8311bf7c8',
    bundlerUrl:
      'https://rpc.zerodev.app/api/v2/bundler/f901b57a-e0df-4920-999d-29f630eff706',
    paymasterUrl:
      'https://rpc.zerodev.app/api/v2/paymaster/f901b57a-e0df-4920-999d-29f630eff706',
  },
  fundManager: {
    address: '0x6DB7529816E42a59b760Ddd91Ac9CF7b5FbC1C20',
  },
  contracts: {
    tokenIncome: { address: '0x092e82532b0cED85cbC627b277f103ED345f2a69' },
  },
  accountAbstraction: {
    zerodev: {
      projectId: 'f901b57a-e0df-4920-999d-29f630eff706',
    },
    alchemy: {
      apiKey: '1EYNZY_LZsbNpzUzfFOguhtdHEzFHUCL',
    },
  },
};
