import { ContentBulletListBlock } from '@hedgehog/shared/types';
import { BulletListItem, List } from '@hedgehog/ui/lists';
import { Heading, MarkdownText } from '@hedgehog/ui/typography';

type CMSBulletListBlockProps = {
  block: ContentBulletListBlock;
};

export const CMSBulletListBlock = ({
  block,
}: CMSBulletListBlockProps): JSX.Element => {
  const { items } = block;
  return (
    <List>
      {items.map((item) => {
        return (
          <BulletListItem
            key={item.title}
            icon={item.icon}
            iconColor="black"
            iconBackgroundColor="grey100"
          >
            <Heading level="h6">{item.title}</Heading>
            <MarkdownText>{item.description}</MarkdownText>
          </BulletListItem>
        );
      })}
    </List>
  );
};
