import styled, { CSSProp } from 'styled-components';

import { Heading, Note, Paragraph } from '@hedgehog/ui/typography';

const Separator = styled.div`
  border-bottom: 1px solid;
  ${({ theme }): CSSProp => ({ borderBottomColor: theme.colors.shadow200 })}
  margin: 0 0.5rem 1rem 0.5rem;
`;

export const TaxationDisclaimer = (): JSX.Element => {
  return (
    <>
      <Separator />
      <Heading level="h6">Non-US investor taxation</Heading>
      <Paragraph>
        The projected/estimated return figures have been adjusted to take into
        account the estimated impact of US federal corporation taxes and
        withholding taxes on non-US token holders (such taxes, “Applicable US
        Taxes”); local and state taxes may also apply.
      </Paragraph>
      <Paragraph>
        Any token returns will also be subject to individual taxes and
        reporting, depending on the jurisdiction and individual circumstances of
        the non-US token holder, which is not reflected in the estimated return
        figures.
      </Paragraph>
      <Paragraph>
        The actual impact of Applicable US Taxes may be more or less than
        anticipated and may change during the investment term. No assurance is
        made that the estimated impact of Applicable US taxes is correct.
      </Paragraph>
      <Note>
        This information is being provided for informational purposes only and
        is subject to certain assumptions, which may change. It is not intended
        to provide, and should not be relied on, for tax, legal or other
        financial advice. A more detailed summary of the tax matters which may
        affect tokens is contained in the Token Issuance Disclosure Document. If
        you have any questions, or require any investment or other advice, you
        should contact your legal, financial or other professional advisors.
      </Note>
    </>
  );
};
