import styled, { css, CSSObject, CSSProp } from 'styled-components';

import { ratioToHex } from '@hedgehog/ui/themes';

import { Button } from '../Button/button.component';
import { ButtonBase } from '../button-base.component';
import { LinkButton } from '../LinkButton/link-button.component';

export const ButtonSmallTypographyBase = css`
  ${({ theme }): CSSObject => {
    const { fontSize, fontWeight, lineHeight, letterSpacing, fontFamily } =
      theme.typography.button_small;

    return { fontSize, fontWeight, lineHeight, letterSpacing, fontFamily };
  }}
`;

export const SmallButtonBase = css<{ fluid?: boolean }>`
  min-height: unset;
  ${ButtonSmallTypographyBase}

  &[disabled]:not([disabled='false']) {
    ${({ theme }): CSSProp => ({
      backgroundColor: `${theme.colors.grey100}${ratioToHex(0.6)}`,
    })};
  }
`;

/**
 * @deprecated Use [small] property instead at any other button variant.
 */
export const SmallButton = styled(Button).attrs({ $hoverColor: 'shadow100' })`
  &${ButtonBase} {
    ${SmallButtonBase}
  }
`;
/**
 * @deprecated Use [small] property instead at any other button variant.
 */
export const SmallLinkButton = styled(LinkButton).attrs({
  $hoverColor: 'shadow100',
})`
  &${ButtonBase} {
    ${SmallButtonBase}
  }
`;
