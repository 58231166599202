import { useEffect } from 'react';
import styled from 'styled-components';
import { getAddress } from 'viem';

import { useZeroDevWallet } from '@hedgehog/browser/shared/account-abstraction';
import {
  useAcceptBid,
  useAppData,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import { Card } from '@hedgehog/ui/cards';
import { Paragraph, MarkdownText } from '@hedgehog/ui/typography';

import { ModalForm } from '../../../../../components';
import {
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';

const Strong = styled.strong`
  ${({ theme }) => ({ color: theme.colors.black })}
`;

export const MarketBidAcceptTermsConditionsPage = (): JSX.Element => {
  const [params] = useMarketBidParams();
  const { navigateTo, close } = useNavigateToBidPage();

  // Get the fund contract address
  const { activePartnerId } = useAppData();
  const { data: funds, isLoading: areFundsFetching } = useFundsV2Query({
    partnerId: activePartnerId || '',
  });
  const address =
    funds?.find((fund: FundV2) => fund.fundId === params.fundId)?.address || '';

  const [
    acceptBid,
    {
      prepareContractWrite: {
        isLoading: isPrepareAcceptBidLoading,
        isError: isPrepareAcceptBidError,
        error: prepareAcceptBidError,
      },
      contractWrite: { isLoading: isAcceptBidLoading },
    },
  ] = useAcceptBid({
    fundAddress: getAddress(address),
    bidId: Number(params.bidId) || 0,
    enabled: !!address,
  });

  const { autoloadWallet } = useZeroDevWallet();

  useEffect(() => {
    // Connect to the client's wallet
    autoloadWallet({
      index: params.clientWalletIndex,
    });
  }, []);

  const handleAccept = async () => {
    if (!acceptBid) return;
    // TODO: We should do this asynchronusly
    await acceptBid();
    // Navigate to the confirmed page once the tx has succeeded
    navigateTo('accept/confirmed');
  };

  return (
    <ModalForm
      heading="Marketplace terms and conditions"
      submitButtonText="Accept and Continue"
      submitModal={handleAccept}
      backModal={() => navigateTo('overview')}
      closeModal={() => close()}
      loading={isAcceptBidLoading}
    >
      <Card>
        <Paragraph color="grey500">
          I declare that I am a self-certified sophisticated investor for the
          purposes of the restriction on promotion of non-mainstream pooled
          investments.
        </Paragraph>
        <Paragraph color="grey500">
          <Strong>
            I accept that the investments to which the promotions will relate
            may expose me to a significant risk of losing all of the money or
            other property invested.
          </Strong>{' '}
          I am aware that it is open to me to seek advice from someone who
          specialises in advising on non mainstream pooled investments.
        </Paragraph>
      </Card>

      <MarkdownText>
        {`**I understand that this means:**

1. I can receive promotional communications made by a person who is authorised by the Financial Conduct Authority which relate to investment activity in non mainstream pooled investments;
2. The investments to which the promotions will relate may expose me to a significant risk of losing all of the property invested.

**I am a self-certified sophisticated investor because at least one of the following applies:**

1. I am a member of a network or syndicate of business angels and have been so for at least the last six months prior to today;
2. I have made more than one investment in an unlisted company in the two years prior to the date below;
3. I am  working, or have worked in the two years prior to the date below, in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises;
4. I am currently, or have been in the two years prior to the date below, a director of a company with an annual turnover of at least £1m.`}
      </MarkdownText>
    </ModalForm>
  );
};
