import { gql } from '@apollo/client';

import { ContentBlockDataFragment } from '../content';

export const GET_ASSESSMENT = gql`
  query GetAssessment {
    assessmentProgress {
      status
    }
    getAssessment {
      id
      country
      pages {
        id
        assessmentId
        nextAssessmentPageId
        name
        content {
          ...ContentBlockDataFragment
        }
      }
    }
  }
  ${ContentBlockDataFragment}
`;
