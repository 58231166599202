import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { LinkOrNotProps } from '@hedgehog/ui/buttons';
import { shimmerGradient } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

const Wrapper = styled.div`
  display: flex;
  max-height: 2rem;
`;

const InnerWrapper = styled.div<{ loading?: boolean }>`
  display: flex;
  ${({ loading }): CSSProp => (loading ? shimmerGradient : {})}
`;

const Logo = styled.img`
  flex: 0 0 auto;
  display: inline-block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

export type LogotypeProps = StandardProps<
  {
    src?: string;
    loading?: boolean;
  } & LinkOrNotProps,
  never
>;

export const Logotype = styled(
  ({ src, loading = false, ...props }: LogotypeProps): JSX.Element => (
    <Wrapper as={props.to ? Link : 'a'} {...props}>
      <InnerWrapper loading={loading}>
        <Logo src={src} />
      </InnerWrapper>
    </Wrapper>
  ),
)``;
