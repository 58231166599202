import { gql } from '@apollo/client';

export const GET_ACCREDITATION = gql`
  query GetAccreditation {
    accreditation {
      id
      userId
      status
      verificationDocumentStatus
      verificationDocumentUploadURL
      accreditationCountry
    }
  }
`;
