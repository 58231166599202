import { useMemo } from 'react';

import { CacheEntry } from './cache-manager.provider';
import { useCacheManager } from './use-cache-manager.hook';

export interface UseCacheEntryControls<V = unknown> {
  set: (value: V, options?: CacheOptions) => void;
  get: () => CacheEntry<V> | null;
  getValue: () => V | null;
  clear: () => void;
}

export interface CacheOptions {
  lifetimeInMs?: number;
}

export const useCacheEntry = <V = unknown>(
  key: string,
): [CacheEntry<V> | null, UseCacheEntryControls<V>] => {
  const { set, get, clear } = useCacheManager();
  const entry = get<V>(key);
  return useMemo(
    () => [
      entry,
      {
        set: (value: V, { lifetimeInMs } = {}) => set(key, value, lifetimeInMs),
        get: (): CacheEntry<V> | null => get<V>(key) ?? null,
        getValue: (): V | null => get<V>(key)?.value ?? null,
        clear: () => clear(key),
      },
    ],
    [entry?.validatedAt],
  );
};
