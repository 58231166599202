export const ShareIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 10H10L10 22H14L14 10L20 10V8L12 0L4 8L4 10Z"
      fill="currentColor"
    />
    <path d="M0 32L24 32V16H18V26H6L6 16H0V32Z" fill="currentColor" />
  </svg>
);
