import {
  RefObject,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';

export type RadioGroupContext = {
  readonly values: Record<string, string>;
  readonly checked: string[];
  readonly refs: RefObject<HTMLInputElement>[];

  mount: (ref: RefObject<HTMLInputElement>) => void;
  unmount: (ref: RefObject<HTMLInputElement>) => void;
  check: (name: string) => void;
};

export const context = createContext<RadioGroupContext>({
  values: {},
  checked: [],
  refs: [],
  mount: () => void 0,
  unmount: () => void 0,
  check: () => void 0,
});

export const useRadioValueController = (
  ref: RefObject<HTMLInputElement>,
): [string[], () => void] => {
  const { checked, check, mount, unmount } = useContext(context);

  useEffect(() => {
    mount(ref);
    return () => unmount(ref);
  }, []);

  const handleCheck = (): void => {
    const { current } = ref;

    if (current) check(current.name);
  };

  return useMemo(() => [checked, handleCheck], [checked]);
};
