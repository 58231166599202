import { useAppData, useAssetQuery } from '@hedgehog/data-access/partners';
import { UUID } from '@hedgehog/shared/types';
import { AssetCard } from '@hedgehog/ui/assets';
import { Button } from '@hedgehog/ui/buttons';
import { Loader } from '@hedgehog/ui/loaders';

type ClientTimelineEntryProps = {
  assetId: UUID;
};

export const ClientTimelineEntry = ({
  assetId,
}: ClientTimelineEntryProps): JSX.Element => {
  const { activePartner } = useAppData();
  const { data: asset } = useAssetQuery(
    { assetId: assetId || '', partnerId: activePartner?.id || '' },
    { skip: !assetId || !activePartner?.id },
  );
  if (!asset) {
    return <Loader />;
  }
  return (
    <AssetCard
      to={`/assets/${asset.id}`}
      type="compact"
      title={asset.name}
      action={<Button small>View</Button>}
      thumbnails={(asset.thumbnails || [])
        .slice(0, asset.underlyingAssetCount)
        .map((thumbnail) => thumbnail.src)}
    />
  );
};
