export enum AssetCategory {
  RESIDENTIAL = 'RESIDENTIAL',
  HOUSING = 'HOUSING',
  INDUSTRIAL = 'INDUSTRIAL',
  OFFICE = 'OFFICE',
  CONTENT_STUDIO = 'CONTENT_STUDIO',
  RENEWABLE = 'RENEWABLE',
  BIOMEDICAL_LIFE_SCIENCES = 'BIOMEDICAL_LIFE_SCIENCES',
  TENANT_TOKEN = 'TENANT_TOKEN',
}
