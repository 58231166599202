import { gql } from '@apollo/client';

export const GET_COURSES = gql`
  query GetCourses {
    courses {
      id
      slug
      title
      imageUrl
      upNext {
        id
        slug
        progress
        title
      }
      topics {
        id
        slug
        progress
      }
    }
  }
`;
