import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { exchangeCognitoAuthCode } from '@hedgehog/browser/shared/auth';
import { login, useDispatch } from '@hedgehog/data-access/partners';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { environment } from '../../../environments/environment';

export const SocialSigninSuccessPage = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const cognitoUserPoolUrl = environment.cognito.userPoolUrl;
  const cognitoClientId = environment.cognito.clientId;
  const appUrl = environment.baseUrl;

  useEffect(() => {
    const getTokens = async () => {
      const code = searchParams.get('code');

      if (code && appUrl && cognitoClientId && cognitoUserPoolUrl) {
        const tokensResponse = await exchangeCognitoAuthCode({
          appUrl,
          redirectUrl: 'auth/sign-in/callback',
          cognitoClientId,
          cognitoUserPoolUrl,
          cognitoAuthCode: code,
        });

        dispatch(
          login({
            authToken: tokensResponse.id_token,
          }),
        );
      }
    };
    getTokens();
  }, [searchParams, cognitoClientId, appUrl]);

  return (
    <LoadingContainer>
      <Loader />
    </LoadingContainer>
  );
};

export default SocialSigninSuccessPage;
