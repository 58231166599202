import { mergeDeep } from '@apollo/client/utilities';
import { useState } from 'react';
import styled from 'styled-components';

import { EmailInput, TextArea, TextInput } from '@hedgehog/ui/inputs';

const CustomTextArea = styled(TextArea)`
  min-height: 5rem;
  max-height: 10rem;
`;

export interface NoticeFormData {
  from: string;
  subject: string;
  message: string;
}

export interface NoticeFormProps {
  defaultValues?: Partial<NoticeFormData>;
  onChange?: (formData: NoticeFormData) => void;
}

export const NoticeForm = ({
  defaultValues = {},
  onChange,
}: NoticeFormProps): JSX.Element => {
  const [formData, setFormData] = useState(
    mergeDeep(
      {
        from: '',
        subject: '',
        message: '',
      },
      defaultValues,
    ),
  );

  const createChangeHandler = (name: keyof NoticeFormData) => ({
    name,
    onChange: (value: string): void => {
      const newFormData = { ...formData, [name]: value };
      setFormData(newFormData);
      if (onChange) {
        onChange(newFormData);
      }
    },
  });

  return (
    <>
      <EmailInput
        label="From Address"
        value={formData.from}
        placeholder='Type email address that will appear as "from" for the recipients...'
        {...createChangeHandler('from')}
      />
      <TextInput
        label="Subject"
        value={formData.subject}
        placeholder="Type your notice subject..."
        {...createChangeHandler('subject')}
      />
      <CustomTextArea
        label="Message"
        autosize={true}
        value={formData.message}
        placeholder="Type your notice message..."
        {...createChangeHandler('message')}
      />
    </>
  );
};
