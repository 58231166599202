export const GoogleIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7197 6.85762H13.2498V6.83342H7.99984V9.16675H11.2965C10.8156 10.525 9.52321 11.5001 7.99984 11.5001C6.06696 11.5001 4.49984 9.93296 4.49984 8.00008C4.49984 6.06721 6.06696 4.50008 7.99984 4.50008C8.89205 4.50008 9.70375 4.83666 10.3218 5.38646L11.9718 3.7365C10.9299 2.76554 9.53634 2.16675 7.99984 2.16675C4.77838 2.16675 2.1665 4.77862 2.1665 8.00008C2.1665 11.2215 4.77838 13.8334 7.99984 13.8334C11.2213 13.8334 13.8332 11.2215 13.8332 8.00008C13.8332 7.60896 13.7929 7.22717 13.7197 6.85762Z"
      fill="#FFC107"
    />
    <path
      d="M2.83936 5.28496L4.7559 6.6905C5.27448 5.40658 6.5304 4.50008 8.0001 4.50008C8.89231 4.50008 9.70402 4.83666 10.3221 5.38646L11.972 3.7365C10.9302 2.76554 9.5366 2.16675 8.0001 2.16675C5.75952 2.16675 3.81644 3.43171 2.83936 5.28496Z"
      fill="#FF3D00"
    />
    <path
      d="M7.99977 13.8333C9.50652 13.8333 10.8756 13.2567 11.9107 12.319L10.1053 10.7912C9.51964 11.2348 8.79164 11.5 7.99977 11.5C6.48252 11.5 5.19422 10.5325 4.70889 9.18237L2.80664 10.648C3.77206 12.5371 5.73264 13.8333 7.99977 13.8333Z"
      fill="#4CAF50"
    />
    <path
      d="M13.7199 6.85746H13.25V6.83325H8V9.16659H11.2967C11.0657 9.81904 10.646 10.3817 10.1047 10.7915C10.105 10.7912 10.1053 10.7912 10.1055 10.7909L11.911 12.3186C11.7832 12.4347 13.8333 10.9166 13.8333 7.99992C13.8333 7.60879 13.7931 7.227 13.7199 6.85746Z"
      fill="#1976D2"
    />
  </svg>
);
