import { Outlet, useParams } from 'react-router-dom';

import { useAppData, useFundsV2Query } from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import { Widget } from '@hedgehog/ui/widgets';

export const MarketFundsPage = (): JSX.Element => {
  const { activePartnerId } = useAppData();
  const { fundId } = useParams();

  const { data: funds, isLoading } = useFundsV2Query({
    partnerId: activePartnerId || '',
  });
  const fund = funds?.find((f: FundV2) => f.fundId === fundId);

  return (
    <Widget title={isLoading ? 'placeholder' : fund?.name} loading={isLoading}>
      <Outlet />
    </Widget>
  );
};
