export const MoneyIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0H6V2H5.5C3.567 2 2 3.567 2 5.5C2 7.433 3.567 9 5.5 9H6V12H5.49999C4.84688 12 4.29126 11.5826 4.08534 11H2.03543C2.27805 12.6961 3.73675 14 5.49999 14H6V16H8V14V12V9V7V4V2V0ZM10 2V4H10.5C11.1531 4 11.7087 4.4174 11.9146 5H13.9646C13.7219 3.30385 12.2632 2 10.5 2H10ZM10 7V9H10.5C11.3284 9 12 9.67157 12 10.5C12 11.3284 11.3284 12 10.5 12H10V14H10.5C12.433 14 14 12.433 14 10.5C14 8.567 12.433 7 10.5 7H10ZM5.5 4H6V7H5.5C4.67157 7 4 6.32843 4 5.5C4 4.67157 4.67157 4 5.5 4Z"
      fill="currentColor"
    />
  </svg>
);

export default MoneyIcon;
