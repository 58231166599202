import { useNavigate } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { ActionCard, ActionCardTrailing } from '@hedgehog/ui/cards';
import { Icon } from '@hedgehog/ui/icons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

export interface BreadcrumbsListItemProps {
  heading: string;
  subheading?: string;
  active?: boolean;
  loading?: boolean;
  to?: string;
}

const ListItem = styled(ActionCard)<
  Pick<BreadcrumbsListItemProps, 'loading' | 'active'>
>`
  ${({ active, theme }): CSSObject => ({
    position: 'relative',
    border: 'none',
    backgroundColor: active ? theme.colors.grey100 : theme.colors.white,
    [`${ActionCardTrailing} ${Icon}`]: {
      backgroundColor: active ? theme.colors.white : theme.colors.grey100,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '1px',
      backgroundColor: theme.colors.grey100,
    },
  })};
`;

export const BreadcrumbsListItem = ({
  heading,
  subheading,
  active,
  loading,
  to,
  ...props
}: BreadcrumbsListItemProps): JSX.Element => {
  const navigate = useNavigate();

  const handleClick = (): void => {
    if (!to) return;
    navigate(to);
  };

  return (
    <ListItem
      type="light"
      loading={loading}
      title={
        <>
          <Heading loading={loading} level="h6">
            {heading}
          </Heading>
          <Paragraph loading={loading}>{subheading}</Paragraph>
        </>
      }
      active={active}
      trailingIcon="chevron-right"
      onClick={handleClick}
      {...props}
    />
  );
};
