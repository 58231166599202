import styled from 'styled-components';

import { ContentKeyValueListBlock } from '@hedgehog/shared/types';
import { VSpace } from '@hedgehog/ui/layouts';
import { List, ListItem } from '@hedgehog/ui/lists';
import { Title, MarkdownText } from '@hedgehog/ui/typography';

import { CMSTitleBodyBlock } from '../cms-title-body-block/cms-title-body-block.component';

const MetricRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type CMSKeyValueListBlockProps = {
  block: ContentKeyValueListBlock;
};

export const CMSKeyValueListBlock = ({
  block,
}: CMSKeyValueListBlockProps): JSX.Element => {
  const { title, description, items } = block;
  return (
    <VSpace spacing="small">
      <CMSTitleBodyBlock
        block={{
          title,
          description,
        }}
      />
      <List>
        {items.map((item) => (
          <ListItem key={item.label}>
            <MetricRow>
              <MarkdownText>{item.label}</MarkdownText>
              <Title>{item.value || '--'}</Title>
            </MetricRow>
          </ListItem>
        ))}
      </List>
    </VSpace>
  );
};
