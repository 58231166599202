export const ArrowUpIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C3.58172 3.86258e-07 -3.86258e-07 3.58172 0 8C3.86258e-07 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 -3.86258e-07 8 0ZM4 8H7L7 13H9V8L12 8V7L8 3L4 7V8Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowUpIcon;
