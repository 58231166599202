export type MarketListingCreateLocationState = {
  contribution: number;
  commitment: number;
};

export const clientIdSearchParamKey = `client_id`;
export const fundIdSearchParamKey = `fund_id`;
export const listingIdSearchParamKey = `listing_id`;
export const bidIdParamKey = `bid_id`;

export const bidPriceParamKey = `bid_price`;
export const positionRatioSearchParamKey = `position_ratio`;
export const positionPriceSearchParamKey = `position_price`;
export const clientWalletIndexSearchParamKey = `client_wallet_index`;
export const clientWalletAddressSearchParamKey = `client_wallet_address`;

export type MarketSearchParamKey =
  | typeof clientIdSearchParamKey
  | typeof fundIdSearchParamKey
  | typeof listingIdSearchParamKey
  | typeof bidIdParamKey
  | typeof bidPriceParamKey
  | typeof positionRatioSearchParamKey
  | typeof positionPriceSearchParamKey
  | typeof clientWalletIndexSearchParamKey
  | typeof clientWalletAddressSearchParamKey;
