import {
  ChangeEvent,
  FocusEvent,
  ReactNode,
  KeyboardEvent,
  forwardRef,
  ForwardedRef,
  WheelEventHandler,
  MouseEventHandler,
} from 'react';
import styled, { CSSObject } from 'styled-components';

import { Icon, IconType } from '@hedgehog/ui/icons';
import { parsePadding } from '@hedgehog/ui/themes';
import { Note } from '@hedgehog/ui/typography';

import { InputLabel } from '../input-label';
import { InputGroup } from '../InputGroup/InputGroup';

import { InputBase, InputReset } from './input.base';

type InputModeType =
  | 'text'
  | 'none'
  | 'search'
  | 'tel'
  | 'url'
  | 'email'
  | 'numeric'
  | 'decimal';

type Props = {
  name: string;
  type: string;
  value?: string | number | readonly string[];
  label?: string;
  ariaLabel?: string;
  disabled?: boolean;
  required?: boolean;
  trailingIcon?: IconType | null;
  trailing?: ReactNode | null;
  placeholder?: string;
  autoCompleteOff?: boolean;
  inputMode?: InputModeType;
  checked?: boolean;
  readOnly?: boolean;
  errors?: string[];
  hint?: string;
  hiddenCursor?: boolean;
  onKeyDown?: (event: KeyboardEvent) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLSelectElement>) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onWheel?: WheelEventHandler<HTMLInputElement>;
  className?: string;
};

export type InputProps = Omit<Props, 'type' | 'onChange'> &
  Pick<JSX.IntrinsicElements['input'], 'role' | 'autoComplete'>;

const CustomNote = styled(Note)`
  font-size: 0.75rem;
  margin-top: 0.125rem;
`;

export const HintNote = styled(CustomNote)`
  ${({ theme: { colors } }): CSSObject => ({ color: colors.grey400 })}
`;

export const ErrorNote = styled(CustomNote)`
  ${({ theme: { colors } }): CSSObject => ({ color: colors.error })}
`;

export const ControlWrapper = styled.div<{ errors?: string[] }>`
  ${InputBase};
  display: flex;
  flex-flow: row nowrap;
  position: relative;
`;

const ControlIcons = styled.div`
  flex: 0 0 auto;
  display: flex;
  position: absolute;
  align-items: center;
  height: 3rem;
  width: 3rem;
  right: 0;
  top: 0;
  pointer-events: none;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.xxsmall,
  })}
`;

export const CustomInput = styled.input<
  Pick<InputProps, 'checked' | 'errors' | 'hiddenCursor' | 'trailingIcon'>
>`
  ${InputReset};
  border-radius: 0.5rem;
  flex: 1 1 auto;

  ${({ theme }): CSSObject => ({
    padding: parsePadding(theme.inputs.padding),
  })}

  ${({ hiddenCursor }): string =>
    hiddenCursor ? 'caret-color: transparent;' : ''}
`;

export const Input = forwardRef(
  (
    {
      name,
      label,
      ariaLabel,
      type,
      value,
      disabled,
      required,
      checked,
      readOnly,
      errors,
      hint,
      placeholder,
      autoCompleteOff,
      inputMode,
      trailing,
      trailingIcon,
      hiddenCursor,
      onKeyDown,
      onChange,
      onFocus,
      onBlur,
      onClick,
      onWheel,
      className,
      ...inputProps
    }: Props,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    return (
      <InputGroup onClick={onClick} className={className}>
        {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
        <ControlWrapper>
          <CustomInput
            aria-label={ariaLabel}
            ref={ref}
            id={name}
            name={name}
            type={type}
            value={value}
            disabled={disabled}
            required={required}
            readOnly={readOnly}
            errors={errors}
            placeholder={placeholder}
            autoComplete={autoCompleteOff ? 'off' : 'on'}
            inputMode={inputMode}
            hiddenCursor={hiddenCursor}
            checked={checked}
            trailingIcon={trailingIcon}
            onKeyDown={onKeyDown}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onWheel={onWheel}
            className={className}
            {...inputProps}
          />
          <ControlIcons>
            {trailingIcon ? (
              <Icon size="s" icon={trailingIcon} />
            ) : trailing ? (
              trailing
            ) : null}
            {checked && <Icon icon="check-circle" color="success" size="m" />}
            {errors && errors.length !== 0 && (
              <Icon icon="alert-circle" color="error" size="m" />
            )}
          </ControlIcons>
        </ControlWrapper>
        {hint && <HintNote>{hint}</HintNote>}
        {errors &&
          errors.map((error) => <ErrorNote key={error}>{error}</ErrorNote>)}
      </InputGroup>
    );
  },
);

export default Input;
