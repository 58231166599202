import React from 'react';
import styled from 'styled-components';

import { screens } from '@hedgehog/utils/sizes';

const spacingSizes = {
  tiny: {
    mobile: 12,
    tablet: 20,
  },
  xs: {
    mobile: 16,
    tablet: 24,
  },
  small: {
    mobile: 24,
    tablet: 40,
  },
  regular: {
    mobile: 48,
    tablet: 80,
  },
};

const VerticalSpace = styled.div<VSpaceStylingProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props): string =>
    props.spacing ? `${spacingSizes[props.spacing].mobile}px` : '48px'};

  @media only screen and (min-width: ${screens.medium}) {
    gap: ${(props): string =>
      props.spacing ? `${spacingSizes[props.spacing].tablet}px` : '80px'};
  }
`;

interface VSpaceStylingProps {
  spacing?: keyof typeof spacingSizes;
}

interface VSpaceProps extends VSpaceStylingProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}

export const VSpace = (props: VSpaceProps): JSX.Element => {
  const { children, className, spacing } = props;
  return (
    <VerticalSpace spacing={spacing} className={className}>
      {children}
    </VerticalSpace>
  );
};
