import { ForwardedRef, forwardRef } from 'react';

import { Note, NoteProps } from '@hedgehog/ui/typography';

export const RISK_WARNINGS_URL =
  'https://www.hedgehog-invest.com/risk-warnings';

export const RiskWarningText = forwardRef(
  (props: NoteProps, ref: ForwardedRef<HTMLParagraphElement>): JSX.Element => (
    <Note ref={ref} {...props}>
      Don’t invest unless you’re prepared to lose all the money you invest. This
      is a high-risk investment and you are unlikely to be protected if
      something goes wrong.{' '}
      <a href={RISK_WARNINGS_URL}>Take 2 mins to learn more</a>
    </Note>
  ),
);
