export const RepeatIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8.05298H9V4.87278L5.15728 4.10424C4.98683 4.07015 4.81343 4.05298 4.63961 4.05298C3.18179 4.05298 2 5.23477 2 6.69259V9.05298H0V6.69259C0 4.1302 2.07722 2.05298 4.63961 2.05298C4.94514 2.05298 5.24992 2.08316 5.54951 2.14308L9 2.83317V0.0529785H10L14 4.05298L10 8.05298Z"
      fill="currentColor"
    />
    <path
      d="M16 7.05298V9.41337C16 11.9758 13.9228 14.053 11.3604 14.053C11.0549 14.053 10.7501 14.0228 10.4505 13.9629L7 13.2728V16.053H6L2 12.053L6 8.05298H7V11.2332L10.8427 12.0017C11.0132 12.0358 11.1866 12.053 11.3604 12.053C12.8182 12.053 14 10.8712 14 9.41337V7.05298H16Z"
      fill="currentColor"
    />
  </svg>
);
