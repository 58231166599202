import { toast } from 'react-toastify';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { useCacheValidate } from '@hedgehog/browser/shared/utils';
import {
  CapitalCallNotice,
  useSettleCapitalCallNoticeUserOperation,
} from '@hedgehog/data-access/partners';
import { SmallButton } from '@hedgehog/ui/buttons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

type CapitalCallNoticeSettleButtonProps = {
  fundRef: string;
  notice: CapitalCallNotice;
};

export const CapitalCallNoticeSettleButton = ({
  fundRef,
  notice,
}: CapitalCallNoticeSettleButtonProps): JSX.Element => {
  const { address } = useAccountAbstraction();
  const [settleNotice, { loading }] = useSettleCapitalCallNoticeUserOperation();
  const clear = useCacheValidate();

  const handleSettle = async () => {
    const noticeSettlement$ = settleNotice({
      fundRef,
      capitalCallNoticeId: notice.id,
    });

    toast.promise(
      noticeSettlement$,
      {
        pending: {
          render: () => (
            <Paragraph small color="grey500">
              Talking to the blockchain, please stand by.
            </Paragraph>
          ),
        },
        success: {
          render: () => {
            return (
              <Paragraph small color="grey500">
                The capital call notice was successfully settled.
              </Paragraph>
            );
          },
        },
        error: {
          render: () => (
            <>
              <Heading level="h7">Sorry, something went wrong.</Heading>
              <Paragraph small color="grey500">
                {
                  "Your capital call couldn't get settled. Please try again later."
                }
              </Paragraph>
            </>
          ),
        },
      },
      {
        pauseOnFocusLoss: false,
        pauseOnHover: false,
      },
    );
    await noticeSettlement$;
    clear('capitalCallsBlockchainQuery');
    clear(`capitalCallNoticesQuery(${notice.capitalCallId})`);
  };

  return (
    <SmallButton
      small
      onClick={handleSettle}
      disabled={!address}
      loading={loading}
    >
      Settle
    </SmallButton>
  );
};
