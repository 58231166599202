import {
  UpdateBlockchainAddressRequest,
  UpdateBlockchainAddressResponse,
} from '@hedgehog/data-access/partners-types';

import { baseApi } from './base.api';

export const updateWalletAddressApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateWalletAddress: builder.mutation<
      UpdateBlockchainAddressResponse['data'],
      UpdateBlockchainAddressRequest
    >({
      query: ({ address }) => ({
        url: 'team-member/address',
        method: 'POST',
        body: { address },
      }),
      transformResponse: (response: UpdateBlockchainAddressResponse) =>
        response.data,
    }),
  }),
});

export const { useUpdateWalletAddressMutation } = updateWalletAddressApi;
