import { gql } from '@apollo/client';

export const GET_IDENTITY_CHECK = gql`
  query GetIdentityCheck {
    identityCheckProgress {
      reasons
      status
    }
  }
`;
