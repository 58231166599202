import { To } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import { CardListItemRow } from '../card-list-item/card-list-item-row.component';

export type BidderListItemProps = StandardProps<{
  highest?: boolean;
  bidderAddress: string;
  bidderFullName?: string;
  offeredAmount?: number;
  loading?: boolean;
  to?: To;
}>;

const BidderCardListItemRow = styled(CardListItemRow)`
  ${({ theme }): CSSProp => ({ color: theme.colors.black })};
`;

export const BidderListItem = ({
  highest = false,
  loading = false,
  to,
  bidderAddress,
  bidderFullName,
  offeredAmount = 0,
}: BidderListItemProps): JSX.Element => {
  return (
    <BidderCardListItemRow
      loading={loading}
      to={to}
      content={[
        <Paragraph small color="grey400" loading={loading}>
          {bidderFullName ? bidderFullName : 'Annonymous'}{' '}
          <span>
            {`(${bidderAddress.substring(0, 5)}...${bidderAddress.substring(
              bidderAddress.length - 3,
            )})`}
          </span>
        </Paragraph>,
        <Paragraph small weight={highest ? 500 : 100} loading={loading}>
          {loading ? 'placeholder' : currencies.formatMoney(offeredAmount)}
        </Paragraph>,
      ]}
    />
  );
};
