import type { Property } from 'csstype';
import { ReactNode } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import {
  ColumnLayoutContext,
  useColumnLayoutContext,
} from './column-layout.context';
import { ColumnRef } from './column-layout.reducer';
import { Column } from './column.component';

const lastColumnContentAlignment = css<ColumnLayoutProps>`
  & ${Column}:last-child {
    ${({ lastColumnContentAlignment }): string =>
      lastColumnContentAlignment
        ? 'align-items: center; justify-content: center;'
        : ''}
  }
`;

function parseColumnsToTemplate(
  columns: ColumnRef[],
): Property.GridTemplateColumns<string | number> {
  return columns.map(({ settings }) => settings?.size ?? '1fr').join(' ');
}

const ColumnHolder = styled.div<ColumnLayoutProps & { columns: ColumnRef[] }>`
  flex: 1 1 100%;
  display: grid;
  margin: 0;

  ${({ columns, gridTemplateColumns }): CSSObject => ({
    gridTemplateColumns: gridTemplateColumns
      ? gridTemplateColumns
      : parseColumnsToTemplate(columns),
  })}
  ${lastColumnContentAlignment}
`;

export interface ColumnLayoutProps {
  children?: ReactNode | ReactNode[];
  gridTemplateColumns?: Property.GridTemplateColumns<string | number>;
  className?: string;
  desktopOnly?: boolean;
  lastColumnContentAlignment?: 'center' | null;
}

export const ColumnLayout = styled((props: ColumnLayoutProps): JSX.Element => {
  const context = useColumnLayoutContext();
  const { children, ...columnLayoutProps } = props;

  return (
    <ColumnLayoutContext.Provider value={context}>
      <ColumnHolder columns={context.columns} {...columnLayoutProps}>
        {children}
      </ColumnHolder>
    </ColumnLayoutContext.Provider>
  );
})``;
