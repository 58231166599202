import { MouseEventHandler } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { CSSProp, styled } from 'styled-components';

import { fromBreakpoint, toBreakpoint } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';
import { depth } from '@hedgehog/utils/sizes';

import { Column } from './column.component';

const MobileColumnWrapper = styled(Column)<{ hidden?: boolean }>`
  display: flex;
  flex-flow: row nowrap;

  &::before {
    content: '';
    display: block;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    ${({ theme }): CSSProp => ({
      zIndex: depth.layout - 1,
      background: theme.colors.shadow600,
    })}
  }

  ${({ theme, hidden }): CSSProp => ({
    display: hidden ? 'none' : undefined,
    ...fromBreakpoint(theme.breakpoints.tablet, {
      [`&::before`]: {
        display: 'none',
      },
    }),
  })};
`;

const MobileColumnInnerWrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
  max-height: 100%;
  ${({ theme }): CSSProp => ({
    ...toBreakpoint(theme.breakpoints.tablet, {
      display: 'flex',
      position: 'fixed',
      width: '100vw',
      height: '80vh',
      maxHeight: '80vh',
      overflow: 'none',
      borderRadius: theme.radius.large,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: depth.layout,
      backgroundColor: theme.colors.white,
    }),
  })}
`;

export const MobileColumn = ({
  match,
  patterns,
  fallbackTo = '../..',
  children,
}: StandardProps<
  (
    | {
        /**
         * @deprecated Use `patterns` instead.
         */
        match: string;
        patterns?: never;
      }
    | {
        match?: never;
        patterns: string[];
      }
  ) & {
    fallbackTo?: string;
  }
>): JSX.Element => {
  const { pathname } = useLocation();
  patterns = patterns ?? [match!];
  const matches = patterns.map((pattern) => matchPath(pattern, pathname));

  const navigate = useNavigate();
  const handleOuterClick: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(fallbackTo, { relative: 'path' });
  };

  const handleInnerClick: MouseEventHandler = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <MobileColumnWrapper
      onClick={handleOuterClick}
      hidden={!matches.some(Boolean)}
    >
      <MobileColumnInnerWrapper onClick={handleInnerClick}>
        {children}
      </MobileColumnInnerWrapper>
    </MobileColumnWrapper>
  );
};
