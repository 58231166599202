import { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

const LineBreak = styled.div`
  border-top: 0.25px solid;
  ${({ theme }): CSSProp => ({ borderTopColor: theme.colors.grey300 })}
  width: 100%;
  height: 0;
`;

const LineBreakContainer = styled.div`
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  width: 100%;
  text-transform: uppercase;
`;

export interface FormLineBreakProps {
  children?: ReactNode | ReactNode[];
}

export const FormLineBreak = (props: FormLineBreakProps): JSX.Element => {
  return (
    <LineBreakContainer>
      <LineBreak />
      {props.children}
      <LineBreak />
    </LineBreakContainer>
  );
};
