export const spacing = {
  xxxs: '0.25rem' as const, // 4px
  xxs: '0.5rem' as const, // 8px
  xs: '0.75rem' as const, // 12px
  sm: '0.875rem' as const, // 14px
  md: '1rem' as const, // 16px
  lg: '1.125rem' as const, // 18px
  xl: '1.375rem' as const, // 22px
  xxl: '1.5rem' as const, // 24px
  xxxl: '1.75rem' as const, // 28px
  xxxxl: '2rem' as const, // 32px
  xxxxxl: '3rem' as const, // 48px
  xxxxxxl: '4rem' as const, // 64px
};

export type spacingNames = keyof typeof spacing;

export default spacing;
