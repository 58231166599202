import { ReactNode } from 'react';
import styled from 'styled-components';

import { Icon } from '@hedgehog/ui/icons';
import { Heading } from '@hedgehog/ui/typography';

type AccordionHeaderProps = {
  collapsed: boolean;
  title: ReactNode | ReactNode[];
  className?: string;
  openIcon?: string;
  closeIcon?: string;
  onClick?: () => void;
};

const TitleWrapper = styled.div`
  margin-right: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const AccordionHeader = ({
  collapsed,
  title,
  onClick,
  className,
  openIcon = 'chevron-down',
  closeIcon = 'chevron-up',
}: AccordionHeaderProps): JSX.Element => {
  return (
    <Wrapper className={className} onClick={onClick}>
      <TitleWrapper>
        {typeof title === 'string' ? (
          <Heading level="h6">{title}</Heading>
        ) : (
          title
        )}
      </TitleWrapper>
      <Icon
        icon={collapsed ? openIcon : closeIcon}
        size="s"
        color="black"
        backgroundColor="white"
      />
    </Wrapper>
  );
};
