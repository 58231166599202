import { useEffect } from 'react';
import styled from 'styled-components';
import { getAddress, parseEther } from 'viem';
import { usePublicClient } from 'wagmi';

import { useZeroDevWallet } from '@hedgehog/browser/shared/account-abstraction';
import {
  useAppData,
  useCreateListing,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import { Card } from '@hedgehog/ui/cards';
import { Paragraph, MarkdownText } from '@hedgehog/ui/typography';

import { ModalForm } from '../../../../components';
import {
  useMarketListingBalance,
  useMarketListingParams,
  useNavigateToListingPage,
} from '../../../../containers';

const Strong = styled.strong`
  ${({ theme }) => ({ color: theme.colors.black })}
`;

export const MarketListingTermsConditionsCreatePage = (): JSX.Element => {
  const [balance] = useMarketListingBalance();
  const [params] = useMarketListingParams();
  const { close, navigateTo } = useNavigateToListingPage();

  // Get the fund contract address
  const { activePartnerId } = useAppData();
  const { data: funds } = useFundsV2Query({ partnerId: activePartnerId || '' });
  const address =
    funds?.find((fund: FundV2) => fund.fundId === params.fundId)?.address || '';

  const [
    createListing,
    {
      contractWrite: { isError, error: createListingError, isLoading },
    },
  ] = useCreateListing({
    address: getAddress(address),
    commitment: parseEther(`${balance.commitment * params.positionRatio}`),
    contribution: parseEther(`${balance.contribution * params.positionRatio}`),
    price: parseEther(`${params.positionPrice}`),
  });

  // We use the public client to watch for transaction receipts which will tell us
  // when the tx has been included in a block.
  const publicClient = usePublicClient();

  const { autoloadWallet, address: unlockedWalletAddress } = useZeroDevWallet();

  useEffect(() => {
    // Connect to the client's wallet
    autoloadWallet({
      index: params.clientWalletIndex,
    });
  }, []);

  const onSubmit = async () => {
    if (!createListing) return;

    // send userop
    const { hash } = await createListing();

    if (!isError) {
      // wait for receipt
      await publicClient.waitForTransactionReceipt({
        hash,
      });

      // navigate to created page
      navigateTo('created');
    } else {
      console.log('Error creating listing', createListingError);
    }
  };

  return (
    <ModalForm
      heading="Marketplace terms and conditions"
      submitButtonText="Submit listing"
      submitModal={onSubmit}
      backModal={() => navigateTo('create/position/confirm')}
      closeModal={() => close()}
      loading={isLoading}
      disabled={!unlockedWalletAddress}
    >
      <Card>
        <Paragraph color="grey500">
          I declare that I am a self-certified sophisticated investor for the
          purposes of the restriction on promotion of non-mainstream pooled
          investments.
        </Paragraph>
        <Paragraph color="grey500">
          <Strong>
            I accept that the investments to which the promotions will relate
            may expose me to a significant risk of losing all of the money or
            other property invested.
          </Strong>{' '}
          I am aware that it is open to me to seek advice from someone who
          specialises in advising on non mainstream pooled investments.
        </Paragraph>
      </Card>

      <MarkdownText>
        {`**I understand that this means:**

1. I can receive promotional communications made by a person who is authorised by the Financial Conduct Authority which relate to investment activity in non mainstream pooled investments;
2. The investments to which the promotions will relate may expose me to a significant risk of losing all of the property invested.

**I am a self-certified sophisticated investor because at least one of the following applies:**

1. I am a member of a network or syndicate of business angels and have been so for at least the last six months prior to today;
2. I have made more than one investment in an unlisted company in the two years prior to the date below;
3. I am  working, or have worked in the two years prior to the date below, in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises;
4. I am currently, or have been in the two years prior to the date below, a director of a company with an annual turnover of at least £1m.`}
      </MarkdownText>
    </ModalForm>
  );
};
