import { useContext } from 'react';

import {
  CacheManagerContext,
  CacheManagerContextType,
} from './cache-manager.provider';

export const useCacheManager = (): CacheManagerContextType => {
  const context = useContext(CacheManagerContext);

  if (!context) {
    throw new Error("Couldn't use CacheManager as it's not provided.");
  }

  return context;
};
