import { emailMessages, InvalidEmailReason } from '@hedgehog/utils/validation';

import { InputProps } from '../Input/Input';
import TextInput from '../TextInput/TextInput';

type EmailInputProps = Omit<InputProps, 'errors'> & {
  errors?: InvalidEmailReason[];
  onChange?: (value: string) => void;
};

export const EmailInput = ({
  name,
  label,
  ariaLabel,
  value,
  disabled,
  required,
  checked,
  readOnly,
  errors,
  hint,
  placeholder,
  autoCompleteOff,
  trailingIcon,
  onChange,
  onFocus,
  onBlur,
  onClick,
}: EmailInputProps): JSX.Element => {
  return (
    <TextInput
      type="email"
      name={name}
      label={label}
      ariaLabel={ariaLabel}
      value={value}
      disabled={disabled}
      required={required}
      checked={checked}
      readOnly={readOnly}
      errors={errors?.map((errorKey) => emailMessages[errorKey])}
      hint={hint}
      placeholder={placeholder}
      autoCompleteOff={autoCompleteOff}
      trailingIcon={trailingIcon}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
    />
  );
};
