import styled from 'styled-components';

import { AssetRoundClassStatus } from '@hedgehog/shared/types';
import { Paragraph } from '@hedgehog/ui/typography';

import {
  AssetLiveStatus,
  AssetIssuingStatus,
  AssetComingSoonStatus,
  AssetOngoingStatus,
  AssetPaidOutStatus,
  AssetPendingStatus,
  AssetPreRegistrationStatus,
} from './statuses';

const StatusContainer = styled.div<{ progress?: number }>`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;

  // Progress bar
  &:after {
    width: ${({ progress }): string =>
      progress ? `${Math.round(progress * 100)}%` : '0%'};
    background-color: rgba(255, 255, 255, 1);
  }
`;

const Label = styled(Paragraph)`
  opacity: 0.8;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`;

const StatusMessage = ({
  status,
}: {
  status?: AssetRoundClassStatus;
}): JSX.Element => {
  switch (status) {
    case AssetRoundClassStatus.LIVE: {
      return <AssetLiveStatus />;
    }
    case AssetRoundClassStatus.REGISTER_INTEREST: {
      return <AssetPreRegistrationStatus />;
    }
    case AssetRoundClassStatus.FUNDED: {
      return <AssetIssuingStatus />;
    }
    case AssetRoundClassStatus.COMING_SOON: {
      return <AssetComingSoonStatus />;
    }
    case AssetRoundClassStatus.ONGOING: {
      return <AssetOngoingStatus />;
    }
    case AssetRoundClassStatus.PAID_OUT: {
      return <AssetPaidOutStatus />;
    }
    default: {
      return <AssetPendingStatus />;
    }
  }
};

export interface AssetStatusProps {
  status?: AssetRoundClassStatus;
  label?: string;
  progress?: number;
  className?: string;
}

export const AssetStatus = ({
  status,
  progress = 0,
  label = 'Investment status',
  className,
}: AssetStatusProps): JSX.Element => {
  return (
    <StatusContainer progress={progress} className={className}>
      <Label small>{label}</Label>
      <StatusWrapper>
        <StatusMessage status={status} />
      </StatusWrapper>
    </StatusContainer>
  );
};
