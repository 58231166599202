import { ethers } from 'ethers';

import { decodeBytesToMetadata, encodeMetadataToBytes } from '../helpers';
import {
  ContractRawIssuance,
  TransactionMetadata,
  TransactionType,
} from '../types';

export class ContractIssuance<T extends TransactionType = TransactionType> {
  #raw: ContractRawIssuance;

  get confirmed(): boolean {
    return this.#raw.confirmed;
  }
  get data(): TransactionMetadata<T> {
    return decodeBytesToMetadata(this.#raw.data);
  }
  get partition(): string {
    return this.#raw.partition;
  }
  get amount(): number {
    return Number(ethers.utils.formatEther(this.#raw.amount));
  }
  get timestamp(): number {
    return this.#raw.timestamp.toNumber() * 1e3;
  }
  get vestingDurationSeconds(): number {
    return this.#raw.vestingDurationSeconds.toNumber() * 1e3;
  }
  get vestingContractAddress(): string {
    return this.#raw.vestingContractAddress;
  }
  constructor(raw: ContractRawIssuance) {
    this.#raw = raw;
  }

  toString(): string {
    return `ContractIssuance<${this.data.type}> {${JSON.stringify(
      {
        amount: this.amount,
        data: this.data,
      },
      null,
      1,
    )}}`;
  }

  static from<T extends TransactionType = TransactionType>(
    issuance: Omit<ContractIssuance<T>, '#raw'>,
  ): ContractIssuance {
    return new ContractIssuance<T>({
      confirmed: issuance.confirmed,
      partition: issuance.partition,
      vestingContractAddress: issuance.vestingContractAddress,
      data: encodeMetadataToBytes(issuance.data),
      amount: ethers.utils.parseEther(String(issuance.amount)),
      timestamp: ethers.BigNumber.from(issuance.timestamp),
      vestingDurationSeconds: ethers.BigNumber.from(
        issuance.vestingDurationSeconds,
      ),
    });
  }

  static fromRaw<T extends TransactionType = TransactionType>(
    ...rawIssuances: (ContractRawIssuance | ContractRawIssuance[])[]
  ): ContractIssuance<T>[] {
    return rawIssuances
      .flatMap((rawIssuanceOrRawIssuances) => rawIssuanceOrRawIssuances)
      .map((raw) => new ContractIssuance<T>(raw));
  }
}
