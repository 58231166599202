import { ethers } from 'ethers';
import { useEffect, useMemo, useState } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import {
  QueryResult,
  useCachedCallback,
  useQueryResult,
} from '@hedgehog/browser/shared/utils';
import { Any } from '@hedgehog/shared/types';

import { useAppData } from '../providers';

import { CapitalCallNotice } from './use-capital-call-notices-query.hook';
import { useFundManagerContract } from './use-fund-manager-contract.hook';

export type CapitalCall = {
  id: number;
  fundId: number;
  amount: ethers.BigNumber;
  settled: ethers.BigNumber;
  data: string;
};

export const useCapitalCallsBlockchainQuery = (): QueryResult<CapitalCall[]> & {
  refresh: () => Promise<QueryResult<CapitalCall[]>>;
} => {
  const fundManagerContract = useFundManagerContract();
  const { address, jsonRpcProvider } = useAccountAbstraction();
  const [{ data, error, loading }, controls] = useQueryResult<CapitalCall[]>();

  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : null;

  const queryForCapitalCalls = useCachedCallback(
    async (partnerId: string) => {
      // calculate partnerRef using partner id
      const partnerRef = ethers.utils.keccak256(
        ethers.utils.toUtf8Bytes(partnerId),
      );

      const result: Any[] = await fundManagerContract.callStatic[
        'listCapitalCalls(bytes32)'
      ](partnerRef);

      const capitalCalls = await Promise.all(
        result.map(
          async ({
            id: capitalCallId,
            fundId: capitalCallFundId,
            amount: capitalCallAmount,
            data: capitalCallData,
          }): Promise<CapitalCall> => {
            // load all of the notice events for the capital call
            const oldIssuedEvents = await jsonRpcProvider!.getLogs({
              address: fundManagerContract.address,
              fromBlock: 0,
              toBlock: 'latest',
              topics: [
                ethers.utils.id(
                  'CapitalCallNoticeIssued(uint32,uint32,bytes32,bytes32,uint256,uint256,uint256,uint256,bytes)',
                ),
                null,
                ethers.utils.hexZeroPad(
                  ethers.utils.hexlify(capitalCallId),
                  32,
                ),
              ],
            });

            const newIssuedEvents = await jsonRpcProvider!.getLogs({
              address: fundManagerContract.address,
              fromBlock: 0,
              toBlock: 'latest',
              topics: [
                ethers.utils.id(
                  'CapitalCallNoticeIssuedWithIsFund(uint32,uint32,bytes32,bytes32,uint256,uint256,uint256,uint256,bytes,bool)',
                ),
                null,
                ethers.utils.hexZeroPad(
                  ethers.utils.hexlify(capitalCallId),
                  32,
                ),
              ],
            });

            const issuedEvents = [...oldIssuedEvents, ...newIssuedEvents];

            const decodedIssuedEvents = issuedEvents.map((event) =>
              fundManagerContract.interface.parseLog(event),
            );

            const notices: CapitalCallNotice[] = await Promise.all(
              decodedIssuedEvents.map(
                async ({
                  args: {
                    id,
                    capitalCallId,
                    amount,
                    limitedPartnerHedgehogRef,
                    date,
                    dueDate,
                    data,
                  },
                }) => {
                  // check for settled events for the notice
                  const settledEvents = await jsonRpcProvider!.getLogs({
                    address: fundManagerContract.address,
                    fromBlock: 0,
                    toBlock: 'latest',
                    topics: [
                      ethers.utils.id(
                        'CapitalCallNoticeSettled(uint32,uint256)',
                      ),
                      ethers.utils.hexZeroPad(ethers.utils.hexlify(id), 32),
                    ],
                  });

                  const decodedSettledEvents = settledEvents.map((event) =>
                    fundManagerContract.interface.parseLog(event),
                  );

                  return {
                    id,
                    capitalCallId,
                    amount,
                    limitedPartnerHedgehogRef,
                    date,
                    dueDate,
                    dateAcknowledged: 0,
                    dateSettled:
                      decodedSettledEvents.length > 0
                        ? decodedSettledEvents[0].args['date'].toNumber()
                        : 0,
                    data: ethers.utils.toUtf8String(data),
                  };
                },
              ),
            );

            return {
              id: capitalCallId,
              fundId: capitalCallFundId,
              amount: capitalCallAmount,
              settled:
                notices.length > 0
                  ? notices.reduce<ethers.BigNumber>(
                      (acc: ethers.BigNumber, notice) => {
                        return notice.dateSettled
                          ? acc.add(notice.amount)
                          : acc;
                      },
                      ethers.BigNumber.from(0),
                    )
                  : ethers.BigNumber.from(0),
              data: ethers.utils.toUtf8String(capitalCallData),
            };
          },
        ),
      );

      return capitalCalls;
    },
    { key: 'capitalCallsBlockchainQuery' },
  );

  const handleCapitalCallsQuery = async (
    partnerId: string,
  ): Promise<QueryResult<CapitalCall[]>> => {
    try {
      controls.setLoading(true);
      const calls = await queryForCapitalCalls(partnerId);

      controls.setData(calls);
      controls.setError(null);
      return { data, error, loading };
    } catch (error) {
      controls.setError(error);
      controls.setData(null);
      return { data, error, loading };
    } finally {
      controls.setLoading(false);
    }
  };

  useEffect(() => {
    if (!address) return;
    if (!jsonRpcProvider) return;
    if (!partnerId) return;
    handleCapitalCallsQuery(partnerId);
  }, [queryForCapitalCalls, fundManagerContract, address]);

  return useMemo(
    () => ({
      data,
      loading,
      error,
      refresh: () => handleCapitalCallsQuery(partnerId || ''),
    }),
    [data, loading, error],
  );
};
