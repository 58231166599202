import { MouseEventHandler } from 'react';
import { To } from 'react-router-dom';
import styled, { css, CSSObject } from 'styled-components';

import {
  convertShortcutToSize,
  parseBorderToShadow,
  parseShadow,
} from '@hedgehog/ui/themes';
import { spacingNames } from '@hedgehog/utils/sizes';

export const CardBase = css<{ outline?: boolean }>`
  display: flex;
  ${({ outline, theme: { border, cards, colors } }): CSSObject => ({
    padding: `${cards.padding.y} ${cards.padding.x}`,
    borderRadius: cards.radius,
    backgroundColor: outline ? 'transparent' : colors.grey100,
    boxShadow: outline
      ? parseBorderToShadow(border.small, { color: colors.shadow100 })
      : undefined,
    color: colors.black,
  })};

  overflow: hidden;
  transition: box-shadow 150ms;
`;

/**
 * This is a base for card interaction styles.
 *
 * Initially it was only defining box-shadow on hover, but it was not enough.
 * Images that were supposed to be overlapped by box-shadow weren't and
 * because of that we had to add an after pseudo-element that supposed
 * to be at the top-level and allow us to define designated behaviour.
 */
export const CardInteractionBase = css<CardWrapperProps>`
  position: relative;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    ${({ theme: { cards } }): CSSObject => ({ borderRadius: cards.radius })}
  }
  ${({ disabled, theme: { border } }): CSSObject => ({
    cursor: disabled ? undefined : 'pointer',
    pointerEvents: disabled ? 'none' : undefined,

    '&:hover:after': {
      boxShadow: !disabled
        ? parseBorderToShadow(border.large, { outset: false })
        : undefined,
    },
  })}
`;

export const CardShadowBase = css`
  ${({ theme }): CSSObject => ({ boxShadow: parseShadow(theme.shadow.large) })}
`;

export interface CardWrapperProps {
  disabled?: boolean;
  href?: string;
  to?: To;
  withShadow?: true;
  fitHeight?: true;
  outline?: boolean;
  onClick?: MouseEventHandler;
}

export const CardWrapper = styled.div<CardWrapperProps>`
  ${CardBase};

  ${({ fitHeight }): string | false => !!fitHeight && `height: fit-content`};

  ${({ withShadow }): typeof CardShadowBase | undefined =>
    withShadow && CardShadowBase}

  ${({ href, to, onClick }): typeof CardInteractionBase | undefined =>
    href || to || onClick ? CardInteractionBase : undefined}
`;

export const CardContent = styled.div<{ space?: spacingNames }>`
  flex: 1 1 100%;
  display: flex;
  max-width: 100%;
  flex-flow: column nowrap;

  ${({ space, theme }): CSSObject => ({
    gap: space ? theme.spacing[convertShortcutToSize(space)] : undefined,
  })}
`;
