import { gql } from '@apollo/client';

export const CONFIRM_PASSWORD = gql`
  mutation ConfirmPassword(
    $email: String!
    $verificationCode: String!
    $newPassword: String!
  ) {
    confirmPassword(
      args: {
        email: $email
        verificationCode: $verificationCode
        newPassword: $newPassword
      }
    ) {
      user {
        id
        email
        country
        createdAt
        firstName
      }
      token
    }
  }
`;
