import { Outlet } from 'react-router-dom';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import {
  LimitedPartnerCommitment,
  useAddLimitedPartnersUserOperation,
  useCurrentFunds,
} from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { Column } from '@hedgehog/ui/layouts';
import { useModal } from '@hedgehog/ui/modals';

import { AddFundClientsModal, FundClientsList } from '../../../containers';
import { ImportLimitedPartnerRow } from '../../../containers/add-fund-clients-form/csv-parser.helpers';

export const FundClientsPage = () => {
  const [addLimitedPartner] = useAddLimitedPartnersUserOperation();

  const { funds, loading: fundsLoading } = useCurrentFunds();

  const handleSuccess = async (
    fundRef: string,
    fundId: number,
    rows: ImportLimitedPartnerRow[],
    partnerClients: PartnerClient[],
  ) => {
    // Add each partner client as a limited partner
    const commitments = rows
      .map(({ data }): LimitedPartnerCommitment | null => {
        const partnerClient = partnerClients.find(
          (entity) => entity.user?.email === data.email,
        );
        if (!partnerClient) return null;
        if (!partnerClient.codeHash) return null;
        return {
          limitedPartnerHash: partnerClient.codeHash,
          commitment: data.commitment,
          limitedPartnerType: partnerClient.type === 'INDIVIDUAL' ? 0 : 1,
          limitedPartnerFundId: data.fundId
            ? parseInt(data.fundId.toString())
            : fundId,
        };
      })
      .filter(Boolean) as LimitedPartnerCommitment[];

    const result = await addLimitedPartner({
      fundRef,
      fundId,
      commitments: commitments,
    });

    // Intentionally log the result to the console
    console.log('addLimitedPartner result', result);
  };

  const { openAndResolveWithSubmitValue: openModal } = useModal(
    (modalProps) => (
      <AddFundClientsModal
        {...modalProps}
        onSuccess={handleSuccess}
        funds={funds}
      />
    ),
  );

  return (
    <PageColumnLayout
      title="Clients"
      actions={
        <PrimaryButton
          onClick={openModal}
          disabled={fundsLoading}
          loading={fundsLoading}
        >
          Add clients
        </PrimaryButton>
      }
    >
      <Column>
        <FundClientsList />
      </Column>
      <Column>
        <Outlet />
      </Column>
    </PageColumnLayout>
  );
};
