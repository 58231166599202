import { InvestorType } from '@hedgehog/data-access/graphql';

export const content = {
  'High Net Worth Investor': [
    <>You have an annual income of £100,000 or more</>,
    <>
      <b>Or</b> you&apos;ve had net assets of £250,000 or more (excluding your
      primary residence) during the past 12 months
    </>,
  ],
  'Sophisticated Investor': [
    <>You&apos;ve invested in an unlisted company in the past year</>,
    <>
      <b>Or</b> previously worked in the private equity sector
    </>,
    <>
      <b>Or</b> you&apos;re a director of a company with annual turnover of at
      least £1m
    </>,
    <>
      <b>Or</b> you&apos;re a member of a business angels syndicate
    </>,
  ],
  'Everyday Investor': [
    <>
      You have not invested more than 10% of your net assets in non-readily
      realisable securities within the last 12 months
    </>,
    <>
      <b>And</b> you&apos;ll not invest more than 10% of your net assets in
      non-readily realisable securities within the next 12 months
    </>,
  ],
} as const;

export type InvestorFullName = keyof typeof content;

export const investorType: Record<InvestorFullName, InvestorType> = {
  'High Net Worth Investor': InvestorType.HIGH_NET_WORTH,
  'Sophisticated Investor': InvestorType.SOPHISTICATED,
  'Everyday Investor': InvestorType.EVERYDAY,
};

export const types = Object.keys(content) as InvestorFullName[];
export const hedgehogSupportedInvestors: InvestorFullName[] = [
  'High Net Worth Investor',
  'Sophisticated Investor',
];

export const termsUrl = 'https://hedgehog-invest.com/terms-of-service';
export const privacyPolicyUrl = 'https://hedgehog-invest.com/privacy-policy';
