export const TwitterIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7715 5.48947C12.7788 5.59797 12.7788 5.70646 12.7788 5.81595C12.7788 9.15224 10.3023 13 5.7738 13V12.998C4.43608 13 3.12614 12.607 2 11.8661C2.19452 11.8901 2.39001 11.9021 2.58598 11.9026C3.69458 11.9036 4.77148 11.5221 5.64363 10.8196C4.59013 10.7991 3.6663 10.0947 3.34357 9.06625C3.71261 9.13924 4.09287 9.12424 4.45509 9.02275C3.30652 8.78477 2.4802 7.74983 2.4802 6.54791C2.4802 6.53691 2.4802 6.52641 2.4802 6.51591C2.82243 6.7114 3.20561 6.81989 3.59756 6.83189C2.51578 6.09044 2.18233 4.61453 2.83559 3.4606C4.08556 5.038 5.9298 5.99694 7.90957 6.09844C7.71115 5.22149 7.98221 4.30255 8.62182 3.68609C9.61341 2.73015 11.1729 2.77915 12.1051 3.79558C12.6564 3.68409 13.1849 3.4766 13.6685 3.18262C13.4847 3.76708 13.1001 4.26355 12.5862 4.57903C13.0742 4.52004 13.551 4.38604 14 4.18156C13.6695 4.68952 13.2531 5.132 12.7715 5.48947Z"
      fill="#1D9BF0"
    />
  </svg>
);

export default TwitterIcon;
