import {
  GetAssetOrdersRequest,
  GetAssetOrdersResponse,
} from '@hedgehog/data-access/asset-types';

import { baseApi } from './base.api';

export const assetOrdersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    assetOrders: builder.query<
      GetAssetOrdersResponse['data'],
      GetAssetOrdersRequest
    >({
      query: ({ partnerId, assetId }) => ({
        url: `partners/${partnerId}/assets/${assetId}/orders`,
      }),
      transformResponse: (response: GetAssetOrdersResponse) => response.data,
    }),
  }),
});

export const { useAssetOrdersQuery } = assetOrdersApi;
