import { gql } from '@apollo/client';

export const GET_ASSET_REGIONS = gql`
  query GetAssetRegions {
    allAssetRegions {
      id
      name
      iconSrc
      votes {
        totalVotes
        userVotes
      }
    }
  }
`;
