import * as Papa from 'papaparse';
import { v4 as uuidv4 } from 'uuid';

import { Any, PartnerClientType } from '@hedgehog/shared/types';

export type ImportLimitedPartnerData = {
  email: string;
  commitment: number;
  name: string;
  surname: string;
  country: string;
  type: PartnerClientType;
  fundId?: number;
};

export type ImportCellError = {
  row: number;
  column: string;
  error: string;
};

export type ImportLimitedPartnerRow = {
  id: string;
  data: ImportLimitedPartnerData;
  errors?: ImportCellError[];
};

export const transformFile = async (
  fileText: string,
): Promise<ImportLimitedPartnerRow[]> => {
  const parsed = Papa.parse<Any>(fileText, {
    header: true,
    skipEmptyLines: true,
  });
  if (parsed.errors.length) {
    throw new Error(parsed.errors[0].message);
  }

  return parsed.data.map((row, index): ImportLimitedPartnerRow => {
    const { email, commitment, name, surname, country, fundId } = row;
    const type =
      typeof row.type === 'string' ? row.type.trim().toUpperCase() : null;
    const sanitisedType =
      ['INDIVIDUAL', 'ORGANISATION'].indexOf(type) !== -1 ? type : 'INDIVIDUAL';
    const errors: ImportCellError[] = [];
    if (!email) {
      errors.push({
        row: index,
        column: 'email',
        error: 'Email is required',
      });
    }
    if (!name) {
      errors.push({
        row: index,
        column: 'name',
        error: 'Name is required',
      });
    }
    if (!country) {
      errors.push({
        row: index,
        column: 'country',
        error: 'Country is required',
      });
    }
    if (['GBR', 'USA', 'CHE'].indexOf(country) === -1) {
      errors.push({
        row: index,
        column: 'country',
        error: 'Country must be GBR, USA or CHE',
      });
    }
    const data = {
      email,
      commitment: parseInt(commitment, 10),
      name,
      surname,
      country,
      type: sanitisedType,
      fundId,
    };
    return {
      id: uuidv4(),
      data,
      errors,
    };
  });
};
