import Emoji from 'react-emoji-render';
import styled from 'styled-components';

export const StatusEmoji = styled(Emoji)`
  font-weight: bold;
  & > span {
    font-size: 1.5rem;
  }
`;

export default StatusEmoji;
