export const ChevronUpIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.49989 4.87132L9.93923 8.31066L12.0605 6.18934L6.49989 0.628677L0.939227 6.18934L3.06055 8.31066L6.49989 4.87132Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronUpIcon;
