import { useParams } from 'react-router-dom';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { AssetPage } from '@hedgehog/data-access/asset-types';
import { useAppData, useAssetQuery } from '@hedgehog/data-access/partners';
import { ContentCMS } from '@hedgehog/ui/cms';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { AssetPageTab } from '../../components';

export const AssetDynamicTabPage = () => {
  const track = useAnalyticsTrack();
  const { assetId = '', slug } = useParams();
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';
  const { data: asset } = useAssetQuery(
    { assetId, partnerId },
    { skip: !assetId || !partnerId },
  );
  const page = asset?.pages.find((entity: AssetPage) => entity.slug === slug);

  if (!asset) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (!page) {
    return <EmptyStateContainer>Page not found</EmptyStateContainer>;
  }

  return (
    <AssetPageTab>
      <ContentCMS
        content={page.content}
        onAnalyticsTrack={track}
        onDocumentClick={(doc) => open(doc.url)}
      />
    </AssetPageTab>
  );
};
