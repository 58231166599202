import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ListingsList } from '@hedgehog/browser/shared/funds/feature-marketplace';

const Wrapper = styled.div`
  max-height: 100%;
  overflow: hidden;
`;

export const MarketFundsListingPage = (): JSX.Element => {
  const { fundId } = useParams();
  return (
    <>
      <Wrapper>
        <ListingsList fundId={fundId ?? ''} />
      </Wrapper>
      <Outlet />
    </>
  );
};
