import { gql } from '@apollo/client';

export const CLAIM_ORDER = gql`
  mutation ClaimOrder($orderId: ID!) {
    claimOrder(input: { orderId: $orderId }) {
      order {
        id
        status
      }
    }
  }
`;
