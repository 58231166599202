import styled, { CSSObject } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

export const BrandName = styled(
  ({ className }: StandardProps<object, never>): JSX.Element => (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox="0 0 196 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7737 12.8498H5.78051V0.583008H0V32.2683H5.78051V18.3721H20.7737V32.2683H26.5542V0.583008H20.7737V12.8498Z"
        fill="currentColor"
      />
      <path
        d="M39.6457 10.677C33.0975 10.677 28.8073 15.2035 28.8073 21.8574C28.8073 28.5114 33.233 32.9473 40.0522 32.9473C44.4327 32.9473 47.2327 30.9104 49.0842 29.1903L46.2843 25.1618C45.1101 26.3387 43.0328 28.0134 40.0522 28.0134C36.8458 28.0134 34.4975 26.1576 34.1814 22.9438H49.7616V21.8574C49.7616 14.7056 45.9682 10.677 39.6457 10.677ZM39.3748 15.1583C42.0844 15.1583 43.9811 16.6973 44.1618 19.3679H34.3168C34.7684 16.4709 36.7103 15.1583 39.3748 15.1583Z"
        fill="currentColor"
      />
      <path
        d="M61.8668 32.9473C65.2538 32.9473 67.5118 31.4988 68.8215 29.3261H68.9118V32.2683H74.4213V0.583008H68.9118V14.2982H68.8215C67.5118 12.1255 65.2538 10.677 61.8668 10.677C56.4024 10.677 51.8412 14.6603 51.8412 21.8122C51.8412 28.964 56.4024 32.9473 61.8668 32.9473ZM63.2668 27.6966C59.9249 27.6966 57.6217 25.3428 57.6217 21.8122C57.6217 18.2815 59.9249 15.9278 63.2668 15.9278C66.6086 15.9278 68.9118 18.2815 68.9118 21.8122C68.9118 25.3428 66.6086 27.6966 63.2668 27.6966Z"
        fill="currentColor"
      />
      <path
        d="M88.8767 42.0003C95.1088 42.0003 99.2635 39.6917 99.3538 32.2683V11.356H93.8443V14.2982H93.754C92.4443 12.1255 90.1863 10.677 86.7993 10.677C81.3349 10.677 76.7737 14.6603 76.7737 21.8122C76.7737 28.8735 81.2897 32.8115 86.7541 32.8115C90.2766 32.8115 92.4443 31.2725 93.754 29.3261H93.8443V31.2725C93.8443 35.4369 92.354 36.7495 88.6509 36.7495C86.8896 36.7495 84.4058 36.4327 81.3801 35.7085L80.3865 41.0044C83.4123 41.5929 86.5283 42.0003 88.8767 42.0003ZM88.1993 27.6966C84.8574 27.6966 82.5542 25.3428 82.5542 21.8122C82.5542 18.2815 84.8574 15.9278 88.1993 15.9278C91.5411 15.9278 93.8443 18.2815 93.8443 21.8122C93.8443 25.3428 91.5411 27.6966 88.1993 27.6966Z"
        fill="currentColor"
      />
      <path
        d="M112.571 10.677C106.022 10.677 101.732 15.2035 101.732 21.8574C101.732 28.5114 106.158 32.9473 112.977 32.9473C117.358 32.9473 120.158 30.9104 122.009 29.1903L119.209 25.1618C118.035 26.3387 115.958 28.0134 112.977 28.0134C109.771 28.0134 107.422 26.1576 107.106 22.9438H122.687V21.8574C122.687 14.7056 118.893 10.677 112.571 10.677ZM112.3 15.1583C115.009 15.1583 116.906 16.6973 117.087 19.3679H107.242C107.693 16.4709 109.635 15.1583 112.3 15.1583Z"
        fill="currentColor"
      />
      <path
        d="M125.012 0.583008V32.2683H130.566V22.0385C130.566 17.512 132.599 15.8372 135.398 15.8372C138.153 15.8372 139.734 17.2857 139.734 20.6806V32.2683H145.289V19.3679C145.289 13.2119 141.947 10.677 137.431 10.677C134.36 10.677 132.102 12.035 130.657 14.3435H130.566V0.583008H125.012Z"
        fill="currentColor"
      />
      <path
        d="M159.32 10.677C152.682 10.677 147.759 15.2488 147.759 21.8122C147.759 28.3756 152.682 32.9473 159.32 32.9473C165.959 32.9473 170.881 28.3756 170.881 21.8122C170.881 15.2488 165.959 10.677 159.32 10.677ZM159.32 15.9278C162.707 15.9278 165.146 18.2363 165.146 21.8122C165.146 25.3881 162.707 27.6966 159.32 27.6966C155.933 27.6966 153.45 25.3881 153.45 21.8122C153.45 18.2363 155.933 15.9278 159.32 15.9278Z"
        fill="currentColor"
      />
      <path
        d="M184.899 42.0003C191.131 42.0003 195.286 39.6917 195.376 32.2683V11.356H189.866V14.2982H189.776C188.466 12.1255 186.208 10.677 182.821 10.677C177.357 10.677 172.796 14.6603 172.796 21.8122C172.796 28.8735 177.312 32.8115 182.776 32.8115C186.299 32.8115 188.466 31.2725 189.776 29.3261H189.866V31.2725C189.866 35.4369 188.376 36.7495 184.673 36.7495C182.912 36.7495 180.428 36.4327 177.402 35.7085L176.409 41.0044C179.434 41.5929 182.55 42.0003 184.899 42.0003ZM184.221 27.6966C180.88 27.6966 178.576 25.3428 178.576 21.8122C178.576 18.2815 180.88 15.9278 184.221 15.9278C187.563 15.9278 189.866 18.2815 189.866 21.8122C189.866 25.3428 187.563 27.6966 184.221 27.6966Z"
        fill="currentColor"
      />
    </svg>
  ),
)`
  width: auto;
  height: auto;
  min-width: 7.5em;
  max-width: 7.5em;
  min-height: 2em;
  max-height: 2em;
  ${({ theme }): CSSObject => ({ color: theme.colors.black })};
`;
