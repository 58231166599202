import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { ProgressBar } from '@hedgehog/ui/charts';
import { Span } from '@hedgehog/ui/typography';
import { currencies, weiToUsd } from '@hedgehog/utils/formats';

const StyledTable = styled.table`
  border-collapse: collapse;
`;

const StyledTableHeader = styled.th`
  text-align: left;
  padding: 1rem 0;
`;

const StyledTableRow = styled.tr`
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  ${({ theme }): CSSProp => ({
    borderTopColor: theme.colors.grey100,
    borderBottomColor: theme.colors.grey100,
  })}
`;

const StyledTableData = styled.td`
  padding: 1rem 0;
`;

const StyledTableProgressData = styled.td`
  width: 50%;
  padding: 1rem 1rem 1rem 0;
`;

export interface CapitalCallParams {
  id: number;
  fund: string;
  committed: ethers.BigNumber;
  settled: ethers.BigNumber;
}

export interface CapitalCallsTableProps {
  calls: CapitalCallParams[];
  loading?: boolean;
}

export const CapitalCallsTable = ({
  calls,
  loading,
}: CapitalCallsTableProps) => {
  if (!loading && calls.length === 0)
    return <div>No capital calls were found.</div>;
  return (
    <StyledTable>
      <StyledTableHeader>Fund</StyledTableHeader>
      <StyledTableHeader>Progress</StyledTableHeader>
      <StyledTableHeader>Called</StyledTableHeader>
      <StyledTableHeader>Received</StyledTableHeader>
      {loading ? (
        <StyledTableRow>
          <StyledTableData>
            <Span loading={loading}>Placeholder</Span>
          </StyledTableData>
          <StyledTableProgressData>
            <ProgressBar value={0} color="success" />
          </StyledTableProgressData>
          <StyledTableData>
            <Span loading={loading}>0,000,000.00</Span>
          </StyledTableData>
          <StyledTableData>
            <Span loading={loading}>0,000,000.00</Span>
          </StyledTableData>
        </StyledTableRow>
      ) : (
        calls.map((call) => (
          <StyledTableRow key={call.id}>
            <StyledTableData>
              <Link to={`/funds/capital-calls/${call.id}`}>{call.fund}</Link>
            </StyledTableData>
            <StyledTableProgressData>
              <ProgressBar
                value={
                  weiToUsd(call.settled.toString()) /
                  weiToUsd(call.committed.toString())
                }
                color="success"
              />
            </StyledTableProgressData>
            <StyledTableData>
              {currencies.formatMoney(weiToUsd(call.committed.toString()), {
                minimumFractionDigits: 2,
              })}
            </StyledTableData>
            <StyledTableData>
              {currencies.formatMoney(weiToUsd(call.settled.toString()), {
                minimumFractionDigits: 2,
              })}
            </StyledTableData>
          </StyledTableRow>
        ))
      )}
    </StyledTable>
  );
};
