import styled, { CSSProp } from 'styled-components';

import { ThemeColorName, parseBorder } from '@hedgehog/ui/themes';

export const CardListItemDivider = styled.div<{ colorName?: ThemeColorName }>`
  display: block;
  width: 100%;
  ${({ colorName, theme }): CSSProp => ({
    borderBottom: parseBorder(theme.border.small),
    borderBottomColor: theme.colors[colorName ?? 'grey100'],
  })}
`;
