import React from 'react';
import styled from 'styled-components';

import { ResourceSource } from '@hedgehog/shared/types';
import { Gallery } from '@hedgehog/ui/galleries';
import { screens } from '@hedgehog/utils/sizes';

type AssetGalleryProps = {
  thumbnails?: ResourceSource[];
  className?: string;
};

export const AssetGallery = styled(
  ({ thumbnails = [], className }: AssetGalleryProps): JSX.Element => {
    return (
      <div className={className}>
        <Gallery resources={thumbnails} />
      </div>
    );
  },
)`
  @media only screen and (max-width: ${screens.medium}px) {
    margin-right: -1.5rem;
    margin-left: -1.5rem;

    .flicking-camera {
      margin-left: 1.5rem;
    }
  }
`;
