import styled, { CSSObject } from 'styled-components';

export interface TeamMemberDetailsProps {
  name: string;
  value: string;
  color?: string;
}

const Container = styled.div`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.small,
  })}
`;

const Name = styled.span`
  ${({ theme, color }): CSSObject => ({
    color: theme.colors[color || ''] || theme.colors.grey500,
  })}
`;

const Value = styled.span`
  ${({ theme, color }): CSSObject => ({
    color: theme.colors[color || ''] || theme.colors.black,
    fontWeight: 500,
  })}
`;

export const TeamMemberDetail = ({
  name,
  value,
  color,
}: TeamMemberDetailsProps): JSX.Element => {
  return (
    <Container>
      <Name color={color}>{name}</Name>
      <Value color={color}>{value}</Value>
    </Container>
  );
};
