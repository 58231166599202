export const AppleIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5894 7.07767C18.5087 7.12821 16.5872 8.19461 16.5872 10.5591C16.6778 13.2556 19.0121 14.2013 19.0521 14.2013C19.0121 14.2518 18.6997 15.4895 17.7744 16.7869C17.04 17.9045 16.225 19.0313 14.9873 19.0313C13.81 19.0313 13.3873 18.2864 12.0289 18.2864C10.5701 18.2864 10.1573 19.0313 9.04034 19.0313C7.80265 19.0313 6.92722 17.8441 6.15284 16.737C5.14681 15.288 4.29172 13.0141 4.26153 10.8308C4.24118 9.6738 4.463 8.53652 5.02606 7.57052C5.82078 6.22192 7.23959 5.30646 8.789 5.27627C9.97615 5.23624 11.0327 6.09133 11.7572 6.09133C12.4515 6.09133 13.7496 5.27627 15.2183 5.27627C15.8522 5.27692 17.5427 5.46789 18.5894 7.07767ZM11.6568 5.04527C11.4455 3.98871 12.0289 2.93214 12.5723 2.25817C13.2666 1.44311 14.3632 0.889893 15.3088 0.889893C15.3692 1.94646 14.9866 2.98267 14.3028 3.73736C13.6892 4.55242 12.6327 5.16602 11.6568 5.04527Z"
      fill="currentColor"
    />
  </svg>
);
