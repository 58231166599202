import { gql } from '@apollo/client';

export const GET_ORDER = gql`
  query GetOrder(
    $id: String
    $assetRoundId: String
    $assetRoundClassId: String
  ) {
    order(
      args: {
        id: $id
        assetRoundId: $assetRoundId
        assetRoundClassId: $assetRoundClassId
      }
    ) {
      id
      quantity
      amount
      status
      transferConfirmed
      asset {
        id
        title
        address
        path
        category
        isTenantToken
        underlyingAssetCount
        currentRound {
          roundClass {
            status
            id
            currency
            metrics {
              id
              metricType
              value
            }
          }
        }
        themes {
          name
          category
        }
        thumbnails {
          src
          thumbnailSrc
          kind
          alt
        }
        details {
          assetOverview {
            keyFacts {
              annualIncomePct
              investmentPeriodYrs
              internalRateOfReturnPct
              investmentHeldYear
              nextPaymentDate
            }
          }
        }
      }
      bankDetails {
        paymentReference
        name
        address
        beneficiary
        accountNumber
        routingNumber
        swift
      }
      transactions {
        paymentReference
        direction
        requestedBy
        type
        title
        quantity
        createdAt
        hidden
      }
    }
  }
`;
