export const ArrowRight = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8L16 1.20797e-06L20 1.03312e-06L32 12L20 24L16 24L16 16L-3.49691e-07 16L-6.03983e-07 8L16 8Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowRight;
