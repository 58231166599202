export const ScribeIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M19.5 0L24 4.5L15 13.5H10.5V9L19.5 0Z" fill="currentColor" />
    <path
      d="M6.75 10.5C3.02208 10.5 0 13.5221 0 17.25C0 20.9779 3.02208 24 6.75 24H7.5C10.7364 24 12.9989 22.1199 14.7475 20.6474C16.6609 19.036 17.9065 18 19.5 18C20.3284 18 21 18.6716 21 19.5C21 20.3284 20.3284 21 19.5 21V24C21.9853 24 24 21.9853 24 19.5C24 17.0147 21.9853 15 19.5 15C16.7683 15 14.723 16.7349 13.1179 18.0964L13.1167 18.0974C13.0144 18.1842 12.9139 18.2694 12.815 18.3526C11.0011 19.8801 9.51363 21 7.5 21H6.75C4.67893 21 3 19.3211 3 17.25C3 15.1789 4.67893 13.5 6.75 13.5H7.5V10.5H6.75Z"
      fill="currentColor"
    />
  </svg>
);

export default ScribeIcon;
