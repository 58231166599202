import { Outlet, useParams } from 'react-router-dom';

import { useAppData, useClientQuery } from '@hedgehog/data-access/partners';

import { ClientLiveListings } from '../containers/client-live-listings.container';
import { ClientPastListings } from '../containers/client-past-listings.container';
import { ClientPendingListings } from '../containers/client-pending-listings.container';

export const ClientSecondaryMarketPage = (): JSX.Element | null => {
  const { activePartnerId } = useAppData();
  const { clientId } = useParams();

  const { data: client, isLoading } = useClientQuery({
    partnerId: activePartnerId || '',
    clientId: clientId || '',
  });
  const { address } = client?.wallets.find(
    (w) => w.type === 'PARTNER_MANAGED_CONTRACT_ACCOUNT',
  ) || { address: '' };

  return (
    <>
      <ClientPendingListings clientAddress={address} isLoading={isLoading} />
      <ClientLiveListings clientAddress={address} isLoading={isLoading} />
      <ClientPastListings clientAddress={address} isLoading={isLoading} />

      <Outlet />
    </>
  );
};
