import React, {
  HTMLAttributes,
  KeyboardEventHandler,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { styled } from 'styled-components';

import { AnyFixLater } from '@hedgehog/shared/types';
import { Chunk, IconType } from '@hedgehog/ui/icons';
import { Loader } from '@hedgehog/ui/loaders';
import { ThemeColorName } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { ButtonBase, LoaderContainer } from '../button-base.component';

type ButtonType = 'button' | 'submit';

export type ButtonProps = StandardProps<
  {
    type?: ButtonType;
    disabled?: boolean;
    loading?: boolean;
    className?: string;
    icon?: IconType;
    leadingIcon?: IconType;
    trailingIcon?: IconType;
    leadingSize?: 'small' | 'medium' | 'large';
    trailingSize?: 'small' | 'medium' | 'large';
    trailingColor?: ThemeColorName;
    children?: ReactNode | ReactNode[];
    fluid?: boolean;
    small?: boolean;
    onClick?: MouseEventHandler<HTMLElement> | AnyFixLater;
    onKeyDown?: KeyboardEventHandler<HTMLElement>;
    $hoverColor?: ThemeColorName;
  } & HTMLAttributes<HTMLButtonElement>
>;

export const Button = styled(
  React.forwardRef(
    (
      props: ButtonProps,
      ref: React.ForwardedRef<HTMLButtonElement>,
    ): JSX.Element => {
      const {
        children,
        type = 'button',
        disabled = false,
        loading = false,
        icon,
        leadingIcon = icon,
        trailingColor = 'inherit',
        $hoverColor = 'shadow100',
        ...buttonProps
      } = props;

      return (
        <ButtonBase
          ref={ref}
          type={type}
          disabled={disabled}
          $isLoading={loading}
          $hoverColor={$hoverColor}
          {...buttonProps}
        >
          {loading && (
            <LoaderContainer>
              <Loader color="currentColor" height={20} />
            </LoaderContainer>
          )}
          {leadingIcon && <Chunk size="s" icon={leadingIcon} color="inherit" />}
          {children && <span>{children}</span>}
          {props.trailingIcon && (
            <Chunk size="s" icon={props.trailingIcon} color={trailingColor} />
          )}
        </ButtonBase>
      );
    },
  ),
)``;
