export const CopyIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H7.5V3H3V7.5H0V0Z" fill="currentColor" />
    <path d="M12 4.5H4.5V12H12V4.5Z" fill="currentColor" />
  </svg>
);

export default CopyIcon;
