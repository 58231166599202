import { ForwardedRef, ReactNode, forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { parsePadding, ratioToHex } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { CardThumbnails } from '../..';
import { Card, LinkProps } from '../card/card.component';
import { CardContent } from '../card/styles';

export const GenericCardBody = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-flow: column nowrap;
`;

export const GenericCardFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const Divider = styled.div`
  ${({ theme: { colors, opacity } }): CSSObject => ({
    backgroundColor: `${colors.black}${ratioToHex(opacity[10])}`,
    height: '1px',
  })}
`;

export const CustomCard = styled(Card)<GenericCardProps>`
  ${({ theme: { cards, colors, spacing } }): CSSObject => ({
    padding: parsePadding(cards.generic.padding),
    paddingBottom: '1rem',
    background: colors.white,

    [`${CardContent}`]: {
      gap: spacing.normal,
    },
    [`${GenericCardBody}`]: {
      gap: spacing.normal,
    },
    [`${GenericCardFooter}`]: {
      gap: spacing.normal,
    },
  })}

  ${CardContent} {
    justify-content: space-between;
  }
`;

const CustomParagraph = styled(Paragraph)`
  ${({ theme: { colors } }): CSSObject => ({
    color: colors.grey500,
  })}
`;

export type GenericCardProps = StandardProps<
  {
    title?: ReactNode | string;
    description?: ReactNode | string;
    infoText?: string;
    info?: ReactNode;
    imageSrc?: string;
    actionButton?: ReactNode;
    withShadow?: true;
  } & LinkProps
>;

export const GenericCard = forwardRef(
  (
    {
      title,
      description,
      infoText,
      info,
      imageSrc,
      actionButton,
      children,
      ...cardProps
    }: GenericCardProps,
    ref: ForwardedRef<HTMLAnchorElement>,
  ): JSX.Element => (
    <CustomCard ref={ref} {...cardProps}>
      <GenericCardBody>
        {typeof title === 'string' ? (
          <Heading level="h4">{title}</Heading>
        ) : (
          title
        )}
        {typeof description === 'string' ? (
          <Paragraph>{description}</Paragraph>
        ) : (
          description
        )}
        {typeof infoText === 'string' ? (
          <CustomParagraph small>{infoText}</CustomParagraph>
        ) : (
          infoText
        )}
        {info && (
          <>
            <Divider />
            {info}
          </>
        )}
      </GenericCardBody>
      <GenericCardFooter>
        {imageSrc && (
          <CardThumbnails
            images={[imageSrc]}
            forceFullWidthImage={!!actionButton}
          />
        )}
        {actionButton && actionButton}
      </GenericCardFooter>
    </CustomCard>
  ),
);
