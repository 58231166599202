import { ethers } from 'ethers';
import { createContext } from 'react';

import { QueryResult } from '@hedgehog/browser/shared/utils';
import { ContractIssuance } from '@hedgehog/shared/blockchain/resident-token';

export interface ContractContextType {
  contract: QueryResult<ethers.Contract>;
  issuances: QueryResult<ContractIssuance[]>;
  getIssuances: (
    forAddress?: string,
  ) => Promise<QueryResult<ContractIssuance[]>>;
}

export const ContractContext = createContext<ContractContextType>({
  contract: { data: null, error: null, loading: false },
  issuances: { data: null, error: null, loading: false },
  getIssuances: async (forAddress?: string) => ({
    data: null,
    error: null,
    loading: false,
  }),
});
