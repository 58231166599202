import { useState, useEffect, useContext, useMemo } from 'react';
import {
  useParams,
  useSearchParams,
  useNavigate,
  generatePath,
} from 'react-router-dom';

import {
  positionRatioSearchParamKey,
  positionPriceSearchParamKey,
  clientIdSearchParamKey,
  fundIdSearchParamKey,
} from '../../pages/market/types';

import context, {
  MarketListingParams,
  MarketListingContextType,
  ClientWalletParams,
  FundContractParams,
} from './context';

export type UseMarketListingParamsFactoryProps = {
  rootPath: string;
};

export const useMarketListingParamsFactory = ({
  rootPath,
}: UseMarketListingParamsFactoryProps): [
  MarketListingParams,
  Pick<
    MarketListingContextType,
    | 'close'
    | 'navigateTo'
    | 'updateParams'
    | 'updateParamsWithClientWallet'
    | 'updateParamsWithFundAddress'
    | 'updateParamsWithPathParams'
    | 'updateParamsWithSearchParams'
  >,
] => {
  const pathParams = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const [params, setParams] = useState<MarketListingParams>({
    clientId:
      (pathParams.clientId || searchParams.get(clientIdSearchParamKey)) ??
      undefined,
    clientWalletAddress: undefined,
    clientWalletIndex: undefined,
    fundId:
      (pathParams.fundId || searchParams.get(fundIdSearchParamKey)) ??
      undefined,
    positionRatio: Number(searchParams.get(positionRatioSearchParamKey)) || 1,
    positionPrice: Number(searchParams.get(positionPriceSearchParamKey)) || 0,
  });

  const createIfChanged = <T extends keyof MarketListingParams>(
    key: T,
    candidateValue: unknown,
  ) => (params[key] !== candidateValue ? { [key]: candidateValue } : {});

  const refreshSearchParams = (parameters: typeof params) => {
    const newSearchParams: Partial<typeof params> = {};
    if (!pathParams.clientId && parameters.clientId) {
      newSearchParams.clientId = parameters.clientId;
    }
    if (!pathParams.fundId && parameters.fundId) {
      newSearchParams.fundId = parameters.fundId;
    }
    if (
      searchParams.get(positionRatioSearchParamKey) !==
      `${parameters.positionRatio}`
    ) {
      newSearchParams.positionRatio = parameters.positionRatio;
    }
    if (
      searchParams.get(positionPriceSearchParamKey) !==
      `${parameters.positionPrice}`
    ) {
      newSearchParams.positionPrice = parameters.positionPrice;
    }

    setSearchParams((prevParams) => ({
      ...prevParams,
      ...newSearchParams,
    }));
  };

  const updateParams = (patch: Partial<MarketListingParams>): void => {
    const newParams = { ...params, ...patch };
    setParams((prevParams) => ({ ...prevParams, ...newParams }));
    refreshSearchParams(newParams);
  };

  const close = (): void => {
    console.debug('close', {
      pathname: `${generatePath(rootPath, params)}`,
    });
    navigate(generatePath(rootPath, params));
  };

  const navigateTo = (path: string): void => {
    console.debug('navigateTo', {
      pathname: `${generatePath(`${rootPath}/${path}`, params)}`,
      search: `?${searchParams.toString()}`,
    });
    navigate(
      `${generatePath(
        `${rootPath}/${path}`,
        params,
      )}?${searchParams.toString()}`,
      { replace: true },
    );
  };

  const updateParamsWithClientWallet = ({
    index,
    address,
  }: ClientWalletParams): void => {
    setParams({
      ...params,
      clientWalletAddress: address,
      clientWalletIndex: index,
    });
  };

  const updateParamsWithFundAddress = ({
    fund,
    address,
  }: FundContractParams): void => {
    setParams((prevParams) => ({
      ...prevParams,
      fundContractAddress: address,
      fund,
    }));
  };

  const updateParamsWithPathParams = (
    newParams: Pick<MarketListingParams, 'clientId' | 'fundId'>,
  ): void => {
    setParams((prevParams) => ({ ...prevParams, ...newParams }));
  };

  const updateParamsWithSearchParams = (urlParams: URLSearchParams): void => {
    let newParams: Partial<MarketListingParams> = {};
    for (const [key, value] of urlParams.entries()) {
      switch (key) {
        case clientIdSearchParamKey:
          newParams = { ...newParams, ...createIfChanged('clientId', value) };
          break;
        case fundIdSearchParamKey:
          newParams = { ...newParams, ...createIfChanged('fundId', value) };
          break;
        case positionRatioSearchParamKey:
          newParams = {
            ...newParams,
            ...createIfChanged('positionRatio', Number(value)),
          };
          break;
        case positionPriceSearchParamKey:
          newParams = {
            ...newParams,
            ...createIfChanged('positionPrice', Number(value)),
          };
          break;
      }
    }

    setParams((prevParams) => ({ ...prevParams, ...newParams }));
  };

  useEffect(() => {
    updateParamsWithPathParams(pathParams);
  }, [pathParams]);

  useEffect(() => {
    updateParamsWithSearchParams(searchParams);
  }, [searchParams]);

  return [
    params,
    {
      close,
      navigateTo,
      updateParams,
      updateParamsWithPathParams,
      updateParamsWithClientWallet,
      updateParamsWithFundAddress,
      updateParamsWithSearchParams,
    },
  ];
};

export const useMarketListingParams = (): [
  MarketListingParams,
  (params: Partial<MarketListingParams>) => void,
] => {
  const { params, updateParams } = useContext(context);
  return useMemo(() => [params, updateParams], [params]);
};
