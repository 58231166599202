import styled, { CSSProp } from 'styled-components';

export const FormRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  ${({ theme }): CSSProp => ({
    gap: theme.spacing.xxlarge,
  })}

  & > * {
    flex: 1;
  }
`;
