import { useState, FocusEventHandler, MouseEventHandler } from 'react';

export const useSelectControls = (): [
  boolean,
  { inputProps: any; dropdownProps: any; close: () => void },
] => {
  const [isInputFocused, toggleInputFocus] = useState(false);
  const [isDropdownFocused, toggleDropdownFocus] = useState(false);

  const handleInputFocus: FocusEventHandler = () => toggleInputFocus(true);
  const handleInputBlur: FocusEventHandler = () => toggleInputFocus(false);
  const handleDropdownMouseEnter: MouseEventHandler = () =>
    toggleDropdownFocus(true);
  const handleDropdownMouseLeave: MouseEventHandler = () =>
    toggleDropdownFocus(false);

  const isFocused = isInputFocused || isDropdownFocused;
  return [
    isFocused,
    {
      inputProps: {
        onFocus: handleInputFocus,
        onBlur: handleInputBlur,
      },
      dropdownProps: {
        onMouseEnter: handleDropdownMouseEnter,
        onMouseLeave: handleDropdownMouseLeave,
      },
      close: (): void => {
        toggleInputFocus(false);
        toggleDropdownFocus(false);
      },
    },
  ];
};
