import { ReactNode } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { Tab } from '@hedgehog/ui/tabs';

type ClientTabProps = {
  to: string;
  small?: boolean;
  children?: ReactNode;
  loading?: boolean;
  className?: string;
};

export const TabLink = ({
  to,
  small,
  children,
  loading,
  className,
}: ClientTabProps): JSX.Element => {
  const pathMatch = useMatch(to.includes('?') ? to.split('?')[0] : to);
  const isActive = !!pathMatch;
  return (
    <Link replace to={to} className={className}>
      <Tab active={isActive} small={small} loading={loading}>
        {children}
      </Tab>
    </Link>
  );
};
