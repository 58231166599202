export const ActivityIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0H0V6H2.38197L3.88196 3L6.11803 3L9 8.76393L10.382 6H14V0Z"
      fill="currentColor"
    />
    <path
      d="M14 8H11.618L10.118 11L7.88197 11L5 5.23607L3.61803 8H0V14H14V8Z"
      fill="currentColor"
    />
  </svg>
);

export default ActivityIcon;
