import { ITokenIncome } from '@hedgehoginvest/token-income';
import { ethers } from 'ethers';
import { useMemo } from 'react';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { Any } from '@hedgehog/shared/types';
import { useEnvironment } from '@hedgehog/ui/environment';

import { UserOperationCallback } from '../types';

export const useTokenIncomeContract = (): ethers.Contract => {
  const {
    contracts: {
      tokenIncome: { address },
    },
  } = useEnvironment();
  if (!address) {
    throw new Error(
      'Expected token income contract address to be defined in the environment',
    );
  }

  const { jsonRpcProvider } = useAccountAbstraction();

  return useMemo(() => {
    console.log('token income contract address:', address);
    return new ethers.Contract(address, ITokenIncome.abi, jsonRpcProvider);
  }, [address]);
};

export type UseTokenIncomeUserOperationParams = {
  functionName: string;
};

export const useTokenIncomeUserOperation = ({
  functionName,
}: UseTokenIncomeUserOperationParams): UserOperationCallback => {
  const { sendUserOperation } = useAccountAbstraction();
  const contract = useTokenIncomeContract();
  return useMemo(() => {
    return (values: Any[] = []): Promise<Any> => {
      return sendUserOperation((account) => {
        return account.execute(
          contract.address,
          0,
          contract.interface.encodeFunctionData(functionName, values),
        );
      });
    };
  }, [sendUserOperation, functionName]);
};
