import * as Sentry from '@sentry/react';
import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js';

import { CognitoSessionTokens } from './auth.types';

export const createGoogleCognitoSession = ({
  email,
  googleIdToken,
  userPool,
}: {
  email: string;
  googleIdToken: string;
  userPool: CognitoUserPool;
}): Promise<CognitoSessionTokens> => {
  return new Promise((resolve, reject) => {
    const cognito = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
    });

    cognito.setAuthenticationFlowType('CUSTOM_AUTH');

    cognito.initiateAuth(authenticationDetails, {
      onSuccess: (result) => {
        resolve({
          accessToken: result.getIdToken().getJwtToken(),
          refreshToken: result.getRefreshToken().getToken(),
        });
      },
      onFailure: (err) => {
        reject(err);
      },
      customChallenge: (challengeParameters) => {
        // We respond to the custom challenge with the Google ID token, Cognito will
        // then check if the ID token is valid and return a session of it is or an error
        // if it is not
        cognito.sendCustomChallengeAnswer(googleIdToken, {
          onSuccess: (result) => {
            resolve({
              accessToken: result.getIdToken().getJwtToken(),
              refreshToken: result.getRefreshToken().getToken(),
            });
          },
          onFailure: (err) => {
            reject(err);
          },
        });
      },
    });
  });
};

export const createCognitoSession = (
  email: string,
  password: string,
  userPool: CognitoUserPool,
): Promise<CognitoSessionTokens> => {
  return new Promise((resolve, reject) => {
    const cognito = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognito.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    cognito.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        try {
          resolve({
            accessToken: result.getIdToken().getJwtToken(),
            refreshToken: result.getRefreshToken().getToken(),
          });
        } catch (err: unknown) {
          // There are no expected errors here, so if anything goes wrong we should report it to Sentry
          Sentry.captureException(err);
          reject(new Error('Something went wrong, please try again later.'));
        }
      },
      onFailure: (err) => {
        // Cognito has been configured to suppress user not found errors and will instead return NotAuthorizedExceptions
        // for user not found
        switch (err?.code) {
          case 'NotAuthorizedException':
            reject(new Error('Incorrect username or password.'));
            return;
          case 'UserNotConfirmedException':
            reject(
              new Error(
                'Account not confirmed. Please check your inbox for a confirmation email.',
              ),
            );
            return;
          default:
            // Unexpected errors, report these to Sentry
            Sentry.captureException(err);
            reject(new Error('Something went wrong, please try again later.'));
            return;
        }
      },
    });
  });
};

export const refreshCognitoSession = (
  email: string,
  refreshToken: string,
  userPool: CognitoUserPool,
): Promise<CognitoSessionTokens> => {
  return new Promise((resolve, reject) => {
    const cognito = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    cognito.refreshSession(
      new CognitoRefreshToken({ RefreshToken: refreshToken }),
      (err, session) => {
        if (err) return reject(err);
        resolve({
          accessToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        });
      },
    );
  });
};

export const destroyCognitoSession = (
  email: string,
  userPool: CognitoUserPool,
): void => {
  const cognito = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  cognito.signOut();
};

export const createCognitoUser = (
  email: string,
  password: string,
  userPool: CognitoUserPool,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, [], [], (err, res) => {
      if (err) {
        reject(err);
        return;
      }
      if (!res) {
        reject(new Error('expected signup result to be returned from Cognito'));
        return;
      }
      resolve(res.userSub);
    });
  });
};

export const deleteCognitoUser = (
  email: string,
  userPool: CognitoUserPool,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const cognito = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    cognito.deleteUser((err) => {
      if (err) {
        reject(err);
        return;
      }
      resolve();
    });
  });
};

export const beginCognitoUserForgotPassword = (
  email: string,
  userPool: CognitoUserPool,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const cognito = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    cognito.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    });
  });
};

export const confirmCognitoUserPassword = (
  email: string,
  code: string,
  newPassword: string,
  userPool: CognitoUserPool,
): Promise<void> => {
  return new Promise((resolve, reject) => {
    const cognito = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    cognito.confirmPassword(code, newPassword, {
      onSuccess: () => resolve(),
      onFailure: reject,
    });
  });
};
