import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

import { LocalStorageAuthKeys } from '@hedgehog/data-access/contexts';

import { LoginRequest, TAuthSession } from '../../../types';
import { partnersApi } from '../../apis';

export interface AuthReducerState {
  token?: string;
}

const setAuthToken = (state: Draft<AuthReducerState>, token?: string): void => {
  state.token = token;
  if (token) {
    localStorage.setItem(LocalStorageAuthKeys.AccessToken, token);
  } else {
    localStorage.removeItem(LocalStorageAuthKeys.AccessToken);
  }
};

const handleAcceptInvitation = (
  state: Draft<AuthReducerState>,
  action: PayloadAction<TAuthSession>,
): void => {
  setAuthToken(state, action.payload.idToken);
};

const initialState: AuthReducerState = {
  token: localStorage.getItem(LocalStorageAuthKeys.AccessToken) || undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (
      state: Draft<AuthReducerState>,
      action: PayloadAction<LoginRequest>,
    ) => {
      setAuthToken(state, action.payload.authToken);
    },
    logout: (state: Draft<AuthReducerState>) => {
      setAuthToken(state, undefined);
    },
  },
  extraReducers(builder) {
    builder.addMatcher(
      partnersApi.endpoints.acceptTeamInvitation.matchFulfilled,
      handleAcceptInvitation,
    );
  },
});

export const { login, logout } = authSlice.actions;

export const authReducer = authSlice.reducer;
