import { gql } from '@apollo/client';

export const CREATE_ORDER = gql`
  mutation CreateOrder(
    $type: OrderType!
    $quantity: Int!
    $assetRoundClassId: String!
  ) {
    createOrder(
      args: {
        type: $type
        quantity: $quantity
        assetRoundClassId: $assetRoundClassId
      }
    ) {
      id
    }
  }
`;
