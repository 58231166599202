import { AssetRound } from '@hedgehog/data-access/asset-types';
import { UUID } from '@hedgehog/shared/types';

import { useAppData } from '../providers';
import { useAssetQuery } from '../store/apis';

export const useAssetRound = (assetId: UUID): AssetRound | null => {
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';
  const { data: asset } = useAssetQuery(
    { assetId, partnerId },
    { skip: !assetId || !partnerId },
  );
  if (!asset || !asset?.rounds.length) return null;
  return asset.rounds[0];
};
