import { useState } from 'react';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { useAppData } from '@hedgehog/data-access/partners';
import { Divider } from '@hedgehog/ui/assets';
import { Column } from '@hedgehog/ui/layouts';
import { Widget, WidgetGrid } from '@hedgehog/ui/widgets';

import {
  FundsClientsMetric,
  FundsDistribution,
  FundsLadderboard,
  FundsMetric,
} from '../../../containers';

const MetricDivider = styled(Divider)`
  margin-top: 1rem;
`;

export const FundsDashboardPage = () => {
  const [loading, setLoading] = useState({
    statistics: true,
    clients: true,
  });
  const { activePartnerId } = useAppData();

  const isLoading = loading.statistics || loading.clients;
  return (
    <PageColumnLayout title="Dashboard">
      <Column>
        <WidgetGrid>
          <Widget medium>
            <FundsMetric
              loading={isLoading}
              onLoaded={() =>
                setLoading((state) => ({ ...state, statistics: false }))
              }
            />
            <MetricDivider />
            <FundsDistribution
              loading={isLoading}
              onLoaded={() =>
                setLoading((state) => ({ ...state, statistics: false }))
              }
            />
          </Widget>
          <Widget small>
            <FundsClientsMetric
              partnerId={activePartnerId ?? ''}
              loading={isLoading}
              onLoaded={() =>
                setLoading((state) => ({ ...state, clients: false }))
              }
            />
          </Widget>
          <Widget large>
            <FundsLadderboard
              loading={isLoading}
              onLoaded={() =>
                setLoading((state) => ({ ...state, statistics: false }))
              }
            />
          </Widget>
        </WidgetGrid>
      </Column>
    </PageColumnLayout>
  );
};
