import { gql } from '@apollo/client';

import { ContentBlockDataFragment } from '../content';

export const GET_ASSET_PAGE = gql`
  query GetAssetPage($assetId: String!, $slug: String!) {
    assetPage(args: { assetId: $assetId, slug: $slug }) {
      id
      slug
      content {
        ...ContentBlockDataFragment
      }
    }
  }
  ${ContentBlockDataFragment}
`;
