import styled from 'styled-components';

import { AssetRoundClassMetric } from '@hedgehog/data-access/graphql';
import { VSpace } from '@hedgehog/ui/layouts';
import { Markdown } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { AssetHighlight } from '../asset-highlight/asset-highlight';

import { TaxationDisclaimer } from './taxation-disclaimer';
import { AssetHighlightsTooltipContent } from './tooltip-content';

const StyledAssetHighlights = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  justify-content: space-between;

  @media only screen and (max-width: ${screens.verysmall - 1}px) {
    gap: 3rem;
  }
`;

type AssetHighlightsProps = {
  hasTaxLeakage: boolean;
  metrics?: Pick<AssetRoundClassMetric, 'metricType' | 'value'>[];
};

export const AssetHighlights = ({
  hasTaxLeakage,
  metrics = [],
}: AssetHighlightsProps): JSX.Element | null => {
  return (
    <StyledAssetHighlights>
      {metrics.map((metric) => {
        const content = AssetHighlightsTooltipContent[metric.metricType];
        return (
          <AssetHighlight
            key={content.title}
            label={content.title}
            value={metric.value}
            tooltip={
              content.tooltip || (hasTaxLeakage && content.taxLeakage)
                ? {
                    title: content.title,
                    content: (
                      <VSpace spacing="xs">
                        {content.tooltip && (
                          <div>
                            <Markdown>{content.tooltip}</Markdown>
                          </div>
                        )}
                        {hasTaxLeakage && content.taxLeakage && (
                          <TaxationDisclaimer />
                        )}
                      </VSpace>
                    ),
                  }
                : undefined
            }
          />
        );
      })}
    </StyledAssetHighlights>
  );
};
