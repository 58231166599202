import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

export type PillProps = {
  className?: string;
  onClick?: () => void;
  children: ReactNode;
  borderRadius?: string;
  color?: string;
  small?: boolean;
};

export const StyledPill = styled.span<{
  borderRadius?: string;
  small?: boolean;
}>`
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  ${({ color, theme, borderRadius, small }): CSSObject => ({
    color: theme.colors.white,
    backgroundColor: color ? theme.colors[color] : theme.colors.secondary,
    borderRadius: theme.border.normal.radius || borderRadius || '100px',
    padding: small ? '0 6px' : '8px 12px',
  })}
`;

export const Pill = ({ ...pillProps }: PillProps): JSX.Element => {
  return <StyledPill {...pillProps} />;
};
