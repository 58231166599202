import { configureStore, EnhancedStore } from '@reduxjs/toolkit';

import { baseApi } from './apis/base.api';
import reducer from './reducer';

export const createStore = (initialState = {}): EnhancedStore =>
  configureStore({
    reducer,
    devTools: true,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([baseApi.middleware]),
  });
