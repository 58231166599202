import * as baseIcons from './svgs';

// TODO update money icon
// TODO update lock icon
// TODO add unlock icon
const iconAliases = {
  account: baseIcons.AccountIcon,
  activity: baseIcons.ActivityIcon,
  'alert-circle': baseIcons.AlertCircleIcon,
  'angle-down': baseIcons.AngleDown,
  'angle-up': baseIcons.AngleUp,
  apple: baseIcons.AppleIcon,
  'arrow-down': baseIcons.ArrowDownIcon,
  'arrow-left': baseIcons.ArrowLeftIcon,
  'arrow-left-circle': baseIcons.ArrowLeftCircle,
  'arrow-right': baseIcons.ArrowRight,
  'arrow-right-full': baseIcons.ArrowRightFull,
  'arrow-up': baseIcons.ArrowUpIcon,
  asterisk: baseIcons.AsteriskIcon,
  bell: baseIcons.BellIcon,
  bill: baseIcons.BillIcon,
  book: baseIcons.BookIcon,
  building: baseIcons.BuildingIcon,
  burger: baseIcons.MenuIcon,
  calendar: baseIcons.CalendarIcon,
  chart: baseIcons.ChartIcon,
  'check-circle': baseIcons.CheckCircleIcon,
  'check-mark': baseIcons.CheckMarkIcon,
  'chevron-down': baseIcons.ChevronDownIcon,
  'chevron-left': baseIcons.ChevronLeftIcon,
  'chevron-right': baseIcons.ChevronRightIcon,
  'chevron-up': baseIcons.ChevronUpIcon,
  chunk: baseIcons.ChunkIcon,
  'circle-check': baseIcons.CircleCheck,
  circle: baseIcons.CircleIcon,
  'circle-warning': baseIcons.CircleWarning,
  clock: baseIcons.ClockIcon,
  close: baseIcons.Close,
  component: baseIcons.ComponentIcon,
  copy: baseIcons.CopyIcon,
  'corner-rule': baseIcons.CornerRuler,
  cross: baseIcons.CrossIcon,
  'countdown-stop': baseIcons.CountdownStopIcon,
  dining: baseIcons.DiningIcon,
  download: baseIcons.DownloadIcon,
  edit: baseIcons.EditIcon,
  'empty-page': baseIcons.EmptyPage,
  evergreen: baseIcons.EvergreenIcon,
  facebook: baseIcons.FacebookIcon,
  flag: baseIcons.FlagIcon,
  gift: baseIcons.GiftIcon,
  google: baseIcons.GoogleIcon,
  group: baseIcons.GroupIcon,
  heart: baseIcons.HeartIcon,
  hedgehog: baseIcons.HedgehogIcon,
  help: baseIcons.HelpIconV2,
  home: baseIcons.HomeIcon,
  hotel: baseIcons.HotelIcon,
  'info-circle': baseIcons.InfoCircleIcon,
  info: baseIcons.InfoIcon,
  'info-round': baseIcons.InfoIconRound,
  invest: baseIcons.InvestIcon,
  leaf: baseIcons.LeafIcon,
  linkedin: baseIcons.LinkedinIcon,
  location: baseIcons.LocationTargetIcon,
  lock: baseIcons.LockIcon,
  logout: baseIcons.LogoutIcon,
  menu: baseIcons.MenuIcon,
  email: baseIcons.AtSymbolIcon,
  'at-symbol': baseIcons.AtSymbolIcon,
  mail: baseIcons.MailIcon,
  map: baseIcons.MapIcon,
  'map-pin': baseIcons.MapPinIcon,
  maximize: baseIcons.MaximizeIcon,
  minus: baseIcons.Minus,
  'minus-circle': baseIcons.MinusCircle,
  money: baseIcons.MoneyIcon,
  withdraw: baseIcons.MoneyWithdraw,
  more: baseIcons.MoreIcon,
  notification: baseIcons.NotificationIcon,
  phone: baseIcons.PhoneIcon,
  play: baseIcons.PlayIcon,
  'plus-circle': baseIcons.PlusCircle,
  portfolio: baseIcons.PortfolioIcon,
  scribe: baseIcons.ScribeIcon,
  share: baseIcons.ShareIcon,
  'size-arrow': baseIcons.SizeArrow,
  'street-view': baseIcons.StreetView,
  swap: baseIcons.Swap,
  tag: baseIcons.TagIcon,
  terms: baseIcons.Terms,
  'thumbs-down': baseIcons.ThumbsDown,
  'thumbs-up': baseIcons.ThumbsUp,
  token: baseIcons.TokenIcon,
  trowel: baseIcons.Trowel,
  truck: baseIcons.TruckIcon,
  twitter: baseIcons.TwitterIcon,
  update: baseIcons.UpdateIcon,
  user: baseIcons.UserIcon,
  users: baseIcons.UsersIcon,
  video: baseIcons.VideoIcon,
  'vote-up': baseIcons.VoteUpIcon,
  wallet: baseIcons.Wallet,
  whatsapp: baseIcons.WhatsappIcon,
  'cross-circle': baseIcons.XCircleIcon,
  repeat: baseIcons.RepeatIcon,
  business: baseIcons.BusinessIcon,
  settings: baseIcons.SettingsIcon,
  'radio-button': baseIcons.RadioButtonIcon,
  send: baseIcons.SendIcon,
  timer: baseIcons.TimerIcon,
  'search-outline': baseIcons.SearchOutlineIcon,
};

export const icons = {
  ...baseIcons,
  ...iconAliases,
};

export type IconType = keyof typeof baseIcons | keyof typeof iconAliases;
