import styled, { CSSObject, RuleSet } from 'styled-components';

import {
  BreadcrumbsLayout,
  BreadcrumbsLayoutContent,
} from '@hedgehog/browser/partners/shared/layouts';
import { TextInput } from '@hedgehog/ui/inputs';
import { shimmerGradient } from '@hedgehog/ui/themes';

import { BreadcrumbsListItem } from '../breadcrumbs-list-item/breadcrumbs-list-item.component';

export interface BreadcrumbsItem {
  id: string;
  heading: string;
  subheading?: string;
  active?: boolean;
  loading?: boolean;
  to?: string;
}

const ListContainer = styled(BreadcrumbsLayout)`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    [`& > ${BreadcrumbsLayoutContent}`]: {
      padding: `0 ${theme.spacing.xxxsmall}`,
    },
  })}
`;

const Content = styled.div<{ loading?: boolean }>`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    flexGrow: '1',
    padding: theme.spacing.xxxsmall,
    marginTop: theme.spacing.xxxsmall,
  })}
  ${({ loading }): RuleSet<object> | '' => (loading ? shimmerGradient : '')}
`;

export const ListQueryInput = styled(TextInput)`
  padding: 1.5rem 1rem;
`;

export interface BreadcrumbsListProps<T extends BreadcrumbsItem> {
  items: T[];
  loading?: boolean;
  search?: string;
  className?: string;
  onSearch?: (search: string) => void;
}

export const BreadcrumbsList = ({
  search,
  onSearch,
  items,
  loading,
  className,
}: BreadcrumbsListProps<BreadcrumbsItem>): JSX.Element => {
  return (
    <ListContainer className={className}>
      {onSearch && (
        <ListQueryInput
          type="text"
          name="query"
          onChange={onSearch}
          placeholder="Type to search..."
          value={search || ''}
          trailingIcon="search-outline"
        />
      )}
      <Content loading={loading}>
        {items &&
          items.map((item: BreadcrumbsItem) => (
            <BreadcrumbsListItem key={item.id} {...item} />
          ))}
      </Content>
    </ListContainer>
  );
};
