import { Hex } from 'viem';

import {
  GetFundRequest,
  GetFundsRequest,
  GetFundsResponse,
  GetFundResponse,
  CreateFundResponse,
  CreateFundRequest,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import { baseApi } from './base.api';

export const fundsV2Api = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fundsV2: builder.query<GetFundsResponse['data'], GetFundsRequest>({
      extraOptions: { version: 'v2' },
      query: ({ partnerId }) => ({
        url: `partners/${partnerId}/funds`,
      }),
      transformResponse: (response: GetFundsResponse) => response.data,
    }),
    fund: builder.query<GetFundResponse['data'], GetFundRequest>({
      extraOptions: { version: 'v2' },
      query: ({ fundId, partnerId }) => ({
        url: `partners/${partnerId}/funds/${fundId}`,
      }),
      transformResponse: (response: GetFundResponse) => response.data,
    }),
    createFund: builder.mutation<
      CreateFundResponse['data'],
      CreateFundRequest & { partnerId: UUID }
    >({
      extraOptions: { version: 'v2' },
      query: ({ partnerId, ...body }) => ({
        url: `partners/${partnerId}/funds`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreateFundResponse) => response.data,
    }),
    confirmBid: builder.mutation<
      { hash: Hex },
      { partnerId: string; fundId: string; bidId: string }
    >({
      // TODO: Move controller to v2 module
      // extraOptions: { version: 'v2' },
      query: ({ partnerId, fundId, bidId }) => ({
        url: `partners/${partnerId}/funds/${fundId}/bids/${bidId}/confirm`,
        method: 'POST',
      }),
    }),
  }),
});

export const { useFundsV2Query, useConfirmBidMutation } = fundsV2Api;
