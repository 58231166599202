import { useMemo, useState } from 'react';
import styled, { CSSProp } from 'styled-components';

import { TCountry, TCountryAlpha3Code } from '@hedgehog/shared/types';
import { List } from '@hedgehog/ui/lists';
import { MaxHeight as BottomSheetMaxHeight } from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

import { CountryPickerListItem } from '../CountryPickerModal/CountryPickerListItem';
import { CountryPickerSearchInput } from '../CountryPickerModal/CountryPickerSearchInput';

const defaultCountries = [
  {
    code: 'ABW',
    shortcode: 'AW',
    name: 'Aruba',
  },
  {
    code: 'AFG',
    shortcode: 'AF',
    name: 'Afghanistan',
  },
  {
    code: 'AGO',
    shortcode: 'AO',
    name: 'Angola',
  },
  {
    code: 'AIA',
    shortcode: 'AI',
    name: 'Anguilla',
  },
  {
    code: 'ALA',
    shortcode: 'AX',
    name: 'Åland Islands',
  },
  {
    code: 'ALB',
    shortcode: 'AL',
    name: 'Albania',
  },
  {
    code: 'AND',
    shortcode: 'AD',
    name: 'Andorra',
  },
  {
    code: 'ARE',
    shortcode: 'AE',
    name: 'United Arab Emirates',
  },
  {
    code: 'ARG',
    shortcode: 'AR',
    name: 'Argentina',
  },
  {
    code: 'ARM',
    shortcode: 'AM',
    name: 'Armenia',
  },
  {
    code: 'ASM',
    shortcode: 'AS',
    name: 'American Samoa',
  },
  {
    code: 'ATA',
    shortcode: 'AQ',
    name: 'Antarctica',
  },
  {
    code: 'ATF',
    shortcode: 'TF',
    name: 'French Southern Territories',
  },
  {
    code: 'ATG',
    shortcode: 'AG',
    name: 'Antigua and Barbuda',
  },
  {
    code: 'AUS',
    shortcode: 'AU',
    name: 'Australia',
  },
  {
    code: 'AUT',
    shortcode: 'AT',
    name: 'Austria',
  },
  {
    code: 'AZE',
    shortcode: 'AZ',
    name: 'Azerbaijan',
  },
  {
    code: 'BDI',
    shortcode: 'BI',
    name: 'Burundi',
  },
  {
    code: 'BEL',
    shortcode: 'BE',
    name: 'Belgium',
  },
  {
    code: 'BEN',
    shortcode: 'BJ',
    name: 'Benin',
  },
  {
    code: 'BES',
    shortcode: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
  },
  {
    code: 'BFA',
    shortcode: 'BF',
    name: 'Burkina Faso',
  },
  {
    code: 'BGD',
    shortcode: 'BD',
    name: 'Bangladesh',
  },
  {
    code: 'BGR',
    shortcode: 'BG',
    name: 'Bulgaria',
  },
  {
    code: 'BHR',
    shortcode: 'BH',
    name: 'Bahrain',
  },
  {
    code: 'BHS',
    shortcode: 'BS',
    name: 'Bahamas',
  },
  {
    code: 'BIH',
    shortcode: 'BA',
    name: 'Bosnia and Herzegovina',
  },
  {
    code: 'BLM',
    shortcode: 'BL',
    name: 'Saint Barthélemy',
  },
  {
    code: 'BLR',
    shortcode: 'BY',
    name: 'Belarus',
  },
  {
    code: 'BLZ',
    shortcode: 'BZ',
    name: 'Belize',
  },
  {
    code: 'BMU',
    shortcode: 'BM',
    name: 'Bermuda',
  },
  {
    code: 'BOL',
    shortcode: 'BO',
    name: 'Bolivia (Plurinational State of)',
  },
  {
    code: 'BRA',
    shortcode: 'BR',
    name: 'Brazil',
  },
  {
    code: 'BRB',
    shortcode: 'BB',
    name: 'Barbados',
  },
  {
    code: 'BRN',
    shortcode: 'BN',
    name: 'Brunei Darussalam',
  },
  {
    code: 'BTN',
    shortcode: 'BT',
    name: 'Bhutan',
  },
  {
    code: 'BVT',
    shortcode: 'BV',
    name: 'Bouvet Island',
  },
  {
    code: 'BWA',
    shortcode: 'BW',
    name: 'Botswana',
  },
  {
    code: 'CAF',
    shortcode: 'CF',
    name: 'Central African Republic',
  },
  {
    code: 'CAN',
    shortcode: 'CA',
    name: 'Canada',
  },
  {
    code: 'CCK',
    shortcode: 'CC',
    name: 'Cocos (Keeling) Islands',
  },
  {
    code: 'CHE',
    shortcode: 'CH',
    name: 'Switzerland',
  },
  {
    code: 'CHL',
    shortcode: 'CL',
    name: 'Chile',
  },
  {
    code: 'CHN',
    shortcode: 'CN',
    name: 'China',
  },
  {
    code: 'CIV',
    shortcode: 'CI',
    name: "Côte d'Ivoire",
  },
  {
    code: 'CMR',
    shortcode: 'CM',
    name: 'Cameroon',
  },
  {
    code: 'COD',
    shortcode: 'CD',
    name: 'Congo, Democratic Republic of the',
  },
  {
    code: 'COG',
    shortcode: 'CG',
    name: 'Congo',
  },
  {
    code: 'COK',
    shortcode: 'CK',
    name: 'Cook Islands',
  },
  {
    code: 'COL',
    shortcode: 'CO',
    name: 'Colombia',
  },
  {
    code: 'COM',
    shortcode: 'KM',
    name: 'Comoros',
  },
  {
    code: 'CPV',
    shortcode: 'CV',
    name: 'Cabo Verde',
  },
  {
    code: 'CRI',
    shortcode: 'CR',
    name: 'Costa Rica',
  },
  {
    code: 'CUB',
    shortcode: 'CU',
    name: 'Cuba',
  },
  {
    code: 'CUW',
    shortcode: 'CW',
    name: 'Curaçao',
  },
  {
    code: 'CXR',
    shortcode: 'CX',
    name: 'Christmas Island',
  },
  {
    code: 'CYM',
    shortcode: 'KY',
    name: 'Cayman Islands',
  },
  {
    code: 'CYP',
    shortcode: 'CY',
    name: 'Cyprus',
  },
  {
    code: 'CZE',
    shortcode: 'CZ',
    name: 'Czechia',
  },
  {
    code: 'DEU',
    shortcode: 'DE',
    name: 'Germany',
  },
  {
    code: 'DJI',
    shortcode: 'DJ',
    name: 'Djibouti',
  },
  {
    code: 'DMA',
    shortcode: 'DM',
    name: 'Dominica',
  },
  {
    code: 'DNK',
    shortcode: 'DK',
    name: 'Denmark',
  },
  {
    code: 'DOM',
    shortcode: 'DO',
    name: 'Dominican Republic',
  },
  {
    code: 'DZA',
    shortcode: 'DZ',
    name: 'Algeria',
  },
  {
    code: 'ECU',
    shortcode: 'EC',
    name: 'Ecuador',
  },
  {
    code: 'EGY',
    shortcode: 'EG',
    name: 'Egypt',
  },
  {
    code: 'ERI',
    shortcode: 'ER',
    name: 'Eritrea',
  },
  {
    code: 'ESH',
    shortcode: 'EH',
    name: 'Western Sahara',
  },
  {
    code: 'ESP',
    shortcode: 'ES',
    name: 'Spain',
  },
  {
    code: 'EST',
    shortcode: 'EE',
    name: 'Estonia',
  },
  {
    code: 'ETH',
    shortcode: 'ET',
    name: 'Ethiopia',
  },
  {
    code: 'FIN',
    shortcode: 'FI',
    name: 'Finland',
  },
  {
    code: 'FJI',
    shortcode: 'FJ',
    name: 'Fiji',
  },
  {
    code: 'FLK',
    shortcode: 'FK',
    name: 'Falkland Islands (Malvinas)',
  },
  {
    code: 'FRA',
    shortcode: 'FR',
    name: 'France',
  },
  {
    code: 'FRO',
    shortcode: 'FO',
    name: 'Faroe Islands',
  },
  {
    code: 'FSM',
    shortcode: 'FM',
    name: 'Micronesia (Federated States of)',
  },
  {
    code: 'GAB',
    shortcode: 'GA',
    name: 'Gabon',
  },
  {
    code: 'GBR',
    shortcode: 'GB',
    name: 'United Kingdom',
  },
  {
    code: 'GEO',
    shortcode: 'GE',
    name: 'Georgia',
  },
  {
    code: 'GGY',
    shortcode: 'GG',
    name: 'Guernsey',
  },
  {
    code: 'GHA',
    shortcode: 'GH',
    name: 'Ghana',
  },
  {
    code: 'GIB',
    shortcode: 'GI',
    name: 'Gibraltar',
  },
  {
    code: 'GIN',
    shortcode: 'GN',
    name: 'Guinea',
  },
  {
    code: 'GLP',
    shortcode: 'GP',
    name: 'Guadeloupe',
  },
  {
    code: 'GMB',
    shortcode: 'GM',
    name: 'Gambia',
  },
  {
    code: 'GNB',
    shortcode: 'GW',
    name: 'Guinea-Bissau',
  },
  {
    code: 'GNQ',
    shortcode: 'GQ',
    name: 'Equatorial Guinea',
  },
  {
    code: 'GRC',
    shortcode: 'GR',
    name: 'Greece',
  },
  {
    code: 'GRD',
    shortcode: 'GD',
    name: 'Grenada',
  },
  {
    code: 'GRL',
    shortcode: 'GL',
    name: 'Greenland',
  },
  {
    code: 'GTM',
    shortcode: 'GT',
    name: 'Guatemala',
  },
  {
    code: 'GUF',
    shortcode: 'GF',
    name: 'French Guiana',
  },
  {
    code: 'GUM',
    shortcode: 'GU',
    name: 'Guam',
  },
  {
    code: 'GUY',
    shortcode: 'GY',
    name: 'Guyana',
  },
  {
    code: 'HKG',
    shortcode: 'HK',
    name: 'Hong Kong',
  },
  {
    code: 'HMD',
    shortcode: 'HM',
    name: 'Heard Island and McDonald Islands',
  },
  {
    code: 'HND',
    shortcode: 'HN',
    name: 'Honduras',
  },
  {
    code: 'HRV',
    shortcode: 'HR',
    name: 'Croatia',
  },
  {
    code: 'HTI',
    shortcode: 'HT',
    name: 'Haiti',
  },
  {
    code: 'HUN',
    shortcode: 'HU',
    name: 'Hungary',
  },
  {
    code: 'IDN',
    shortcode: 'ID',
    name: 'Indonesia',
  },
  {
    code: 'IMN',
    shortcode: 'IM',
    name: 'Isle of Man',
  },
  {
    code: 'IND',
    shortcode: 'IN',
    name: 'India',
  },
  {
    code: 'IOT',
    shortcode: 'IO',
    name: 'British Indian Ocean Territory',
  },
  {
    code: 'IRL',
    shortcode: 'IE',
    name: 'Ireland',
  },
  {
    code: 'IRN',
    shortcode: 'IR',
    name: 'Iran (Islamic Republic of)',
  },
  {
    code: 'IRQ',
    shortcode: 'IQ',
    name: 'Iraq',
  },
  {
    code: 'ISL',
    shortcode: 'IS',
    name: 'Iceland',
  },
  {
    code: 'ISR',
    shortcode: 'IL',
    name: 'Israel',
  },
  {
    code: 'ITA',
    shortcode: 'IT',
    name: 'Italy',
  },
  {
    code: 'JAM',
    shortcode: 'JM',
    name: 'Jamaica',
  },
  {
    code: 'JEY',
    shortcode: 'JE',
    name: 'Jersey',
  },
  {
    code: 'JOR',
    shortcode: 'JO',
    name: 'Jordan',
  },
  {
    code: 'JPN',
    shortcode: 'JP',
    name: 'Japan',
  },
  {
    code: 'KAZ',
    shortcode: 'KZ',
    name: 'Kazakhstan',
  },
  {
    code: 'KEN',
    shortcode: 'KE',
    name: 'Kenya',
  },
  {
    code: 'KGZ',
    shortcode: 'KG',
    name: 'Kyrgyzstan',
  },
  {
    code: 'KHM',
    shortcode: 'KH',
    name: 'Cambodia',
  },
  {
    code: 'KIR',
    shortcode: 'KI',
    name: 'Kiribati',
  },
  {
    code: 'KNA',
    shortcode: 'KN',
    name: 'Saint Kitts and Nevis',
  },
  {
    code: 'KOR',
    shortcode: 'KR',
    name: 'Korea, Republic of',
  },
  {
    code: 'KWT',
    shortcode: 'KW',
    name: 'Kuwait',
  },
  {
    code: 'LAO',
    shortcode: 'LA',
    name: "Lao People's Democratic Republic",
  },
  {
    code: 'LBN',
    shortcode: 'LB',
    name: 'Lebanon',
  },
  {
    code: 'LBR',
    shortcode: 'LR',
    name: 'Liberia',
  },
  {
    code: 'LBY',
    shortcode: 'LY',
    name: 'Libya',
  },
  {
    code: 'LCA',
    shortcode: 'LC',
    name: 'Saint Lucia',
  },
  {
    code: 'LIE',
    shortcode: 'LI',
    name: 'Liechtenstein',
  },
  {
    code: 'LKA',
    shortcode: 'LK',
    name: 'Sri Lanka',
  },
  {
    code: 'LSO',
    shortcode: 'LS',
    name: 'Lesotho',
  },
  {
    code: 'LTU',
    shortcode: 'LT',
    name: 'Lithuania',
  },
  {
    code: 'LUX',
    shortcode: 'LU',
    name: 'Luxembourg',
  },
  {
    code: 'LVA',
    shortcode: 'LV',
    name: 'Latvia',
  },
  {
    code: 'MAC',
    shortcode: 'MO',
    name: 'Macao',
  },
  {
    code: 'MAF',
    shortcode: 'MF',
    name: 'Saint Martin (French part)',
  },
  {
    code: 'MAR',
    shortcode: 'MA',
    name: 'Morocco',
  },
  {
    code: 'MCO',
    shortcode: 'MC',
    name: 'Monaco',
  },
  {
    code: 'MDA',
    shortcode: 'MD',
    name: 'Moldova, Republic of',
  },
  {
    code: 'MDG',
    shortcode: 'MG',
    name: 'Madagascar',
  },
  {
    code: 'MDV',
    shortcode: 'MV',
    name: 'Maldives',
  },
  {
    code: 'MEX',
    shortcode: 'MX',
    name: 'Mexico',
  },
  {
    code: 'MHL',
    shortcode: 'MH',
    name: 'Marshall Islands',
  },
  {
    code: 'MKD',
    shortcode: 'MK',
    name: 'North Macedonia',
  },
  {
    code: 'MLI',
    shortcode: 'ML',
    name: 'Mali',
  },
  {
    code: 'MLT',
    shortcode: 'MT',
    name: 'Malta',
  },
  {
    code: 'MMR',
    shortcode: 'MM',
    name: 'Myanmar',
  },
  {
    code: 'MNE',
    shortcode: 'ME',
    name: 'Montenegro',
  },
  {
    code: 'MNG',
    shortcode: 'MN',
    name: 'Mongolia',
  },
  {
    code: 'MNP',
    shortcode: 'MP',
    name: 'Northern Mariana Islands',
  },
  {
    code: 'MOZ',
    shortcode: 'MZ',
    name: 'Mozambique',
  },
  {
    code: 'MRT',
    shortcode: 'MR',
    name: 'Mauritania',
  },
  {
    code: 'MSR',
    shortcode: 'MS',
    name: 'Montserrat',
  },
  {
    code: 'MTQ',
    shortcode: 'MQ',
    name: 'Martinique',
  },
  {
    code: 'MUS',
    shortcode: 'MU',
    name: 'Mauritius',
  },
  {
    code: 'MWI',
    shortcode: 'MW',
    name: 'Malawi',
  },
  {
    code: 'MYS',
    shortcode: 'MY',
    name: 'Malaysia',
  },
  {
    code: 'MYT',
    shortcode: 'YT',
    name: 'Mayotte',
  },
  {
    code: 'NAM',
    shortcode: 'NA',
    name: 'Namibia',
  },
  {
    code: 'NCL',
    shortcode: 'NC',
    name: 'New Caledonia',
  },
  {
    code: 'NER',
    shortcode: 'NE',
    name: 'Niger',
  },
  {
    code: 'NFK',
    shortcode: 'NF',
    name: 'Norfolk Island',
  },
  {
    code: 'NGA',
    shortcode: 'NG',
    name: 'Nigeria',
  },
  {
    code: 'NIC',
    shortcode: 'NI',
    name: 'Nicaragua',
  },
  {
    code: 'NIU',
    shortcode: 'NU',
    name: 'Niue',
  },
  {
    code: 'NLD',
    shortcode: 'NL',
    name: 'Netherlands',
  },
  {
    code: 'NOR',
    shortcode: 'NO',
    name: 'Norway',
  },
  {
    code: 'NPL',
    shortcode: 'NP',
    name: 'Nepal',
  },
  {
    code: 'NRU',
    shortcode: 'NR',
    name: 'Nauru',
  },
  {
    code: 'NZL',
    shortcode: 'NZ',
    name: 'New Zealand',
  },
  {
    code: 'OMN',
    shortcode: 'OM',
    name: 'Oman',
  },
  {
    code: 'PAK',
    shortcode: 'PK',
    name: 'Pakistan',
  },
  {
    code: 'PAN',
    shortcode: 'PA',
    name: 'Panama',
  },
  {
    code: 'PCN',
    shortcode: 'PN',
    name: 'Pitcairn',
  },
  {
    code: 'PER',
    shortcode: 'PE',
    name: 'Peru',
  },
  {
    code: 'PHL',
    shortcode: 'PH',
    name: 'Philippines',
  },
  {
    code: 'PLW',
    shortcode: 'PW',
    name: 'Palau',
  },
  {
    code: 'PNG',
    shortcode: 'PG',
    name: 'Papua New Guinea',
  },
  {
    code: 'POL',
    shortcode: 'PL',
    name: 'Poland',
  },
  {
    code: 'PRI',
    shortcode: 'PR',
    name: 'Puerto Rico',
  },
  {
    code: 'PRK',
    shortcode: 'KP',
    name: "Korea (Democratic People's Republic of)",
  },
  {
    code: 'PRT',
    shortcode: 'PT',
    name: 'Portugal',
  },
  {
    code: 'PRY',
    shortcode: 'PY',
    name: 'Paraguay',
  },
  {
    code: 'PSE',
    shortcode: 'PS',
    name: 'Palestine, State of',
  },
  {
    code: 'PYF',
    shortcode: 'PF',
    name: 'French Polynesia',
  },
  {
    code: 'QAT',
    shortcode: 'QA',
    name: 'Qatar',
  },
  {
    code: 'REU',
    shortcode: 'RE',
    name: 'Réunion',
  },
  {
    code: 'ROU',
    shortcode: 'RO',
    name: 'Romania',
  },
  {
    code: 'RUS',
    shortcode: 'RU',
    name: 'Russian Federation',
  },
  {
    code: 'RWA',
    shortcode: 'RW',
    name: 'Rwanda',
  },
  {
    code: 'SAU',
    shortcode: 'SA',
    name: 'Saudi Arabia',
  },
  {
    code: 'SDN',
    shortcode: 'SD',
    name: 'Sudan',
  },
  {
    code: 'SEN',
    shortcode: 'SN',
    name: 'Senegal',
  },
  {
    code: 'SGP',
    shortcode: 'SG',
    name: 'Singapore',
  },
  {
    code: 'SGS',
    shortcode: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
  },
  {
    code: 'SHN',
    shortcode: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
  },
  {
    code: 'SJM',
    shortcode: 'SJ',
    name: 'Svalbard and Jan Mayen',
  },
  {
    code: 'SLB',
    shortcode: 'SB',
    name: 'Solomon Islands',
  },
  {
    code: 'SLE',
    shortcode: 'SL',
    name: 'Sierra Leone',
  },
  {
    code: 'SLV',
    shortcode: 'SV',
    name: 'El Salvador',
  },
  {
    code: 'SMR',
    shortcode: 'SM',
    name: 'San Marino',
  },
  {
    code: 'SOM',
    shortcode: 'SO',
    name: 'Somalia',
  },
  {
    code: 'SPM',
    shortcode: 'PM',
    name: 'Saint Pierre and Miquelon',
  },
  {
    code: 'SRB',
    shortcode: 'RS',
    name: 'Serbia',
  },
  {
    code: 'SSD',
    shortcode: 'SS',
    name: 'South Sudan',
  },
  {
    code: 'STP',
    shortcode: 'ST',
    name: 'Sao Tome and Principe',
  },
  {
    code: 'SUR',
    shortcode: 'SR',
    name: 'Suriname',
  },
  {
    code: 'SVK',
    shortcode: 'SK',
    name: 'Slovakia',
  },
  {
    code: 'SVN',
    shortcode: 'SI',
    name: 'Slovenia',
  },
  {
    code: 'SWE',
    shortcode: 'SE',
    name: 'Sweden',
  },
  {
    code: 'SWZ',
    shortcode: 'SZ',
    name: 'Eswatini',
  },
  {
    code: 'SXM',
    shortcode: 'SX',
    name: 'Sint Maarten (Dutch part)',
  },
  {
    code: 'SYC',
    shortcode: 'SC',
    name: 'Seychelles',
  },
  {
    code: 'SYR',
    shortcode: 'SY',
    name: 'Syrian Arab Republic',
  },
  {
    code: 'TCA',
    shortcode: 'TC',
    name: 'Turks and Caicos Islands',
  },
  {
    code: 'TCD',
    shortcode: 'TD',
    name: 'Chad',
  },
  {
    code: 'TGO',
    shortcode: 'TG',
    name: 'Togo',
  },
  {
    code: 'THA',
    shortcode: 'TH',
    name: 'Thailand',
  },
  {
    code: 'TJK',
    shortcode: 'TJ',
    name: 'Tajikistan',
  },
  {
    code: 'TKL',
    shortcode: 'TK',
    name: 'Tokelau',
  },
  {
    code: 'TKM',
    shortcode: 'TM',
    name: 'Turkmenistan',
  },
  {
    code: 'TLS',
    shortcode: 'TL',
    name: 'Timor-Leste',
  },
  {
    code: 'TON',
    shortcode: 'TO',
    name: 'Tonga',
  },
  {
    code: 'TTO',
    shortcode: 'TT',
    name: 'Trinidad and Tobago',
  },
  {
    code: 'TUN',
    shortcode: 'TN',
    name: 'Tunisia',
  },
  {
    code: 'TUR',
    shortcode: 'TR',
    name: 'Turkey',
  },
  {
    code: 'TUV',
    shortcode: 'TV',
    name: 'Tuvalu',
  },
  {
    code: 'TWN',
    shortcode: 'TW',
    name: 'Taiwan, Province of China',
  },
  {
    code: 'TZA',
    shortcode: 'TZ',
    name: 'Tanzania, United Republic of',
  },
  {
    code: 'UGA',
    shortcode: 'UG',
    name: 'Uganda',
  },
  {
    code: 'UKR',
    shortcode: 'UA',
    name: 'Ukraine',
  },
  {
    code: 'UMI',
    shortcode: 'UM',
    name: 'United States Minor Outlying Islands',
  },
  {
    code: 'URY',
    shortcode: 'UY',
    name: 'Uruguay',
  },
  {
    code: 'USA',
    shortcode: 'US',
    name: 'United States of America',
  },
  {
    code: 'UZB',
    shortcode: 'UZ',
    name: 'Uzbekistan',
  },
  {
    code: 'VAT',
    shortcode: 'VA',
    name: 'Holy See',
  },
  {
    code: 'VCT',
    shortcode: 'VC',
    name: 'Saint Vincent and the Grenadines',
  },
  {
    code: 'VEN',
    shortcode: 'VE',
    name: 'Venezuela (Bolivarian Republic of)',
  },
  {
    code: 'VGB',
    shortcode: 'VG',
    name: 'Virgin Islands (British)',
  },
  {
    code: 'VIR',
    shortcode: 'VI',
    name: 'Virgin Islands (U.S.)',
  },
  {
    code: 'VNM',
    shortcode: 'VN',
    name: 'Viet Nam',
  },
  {
    code: 'VUT',
    shortcode: 'VU',
    name: 'Vanuatu',
  },
  {
    code: 'WLF',
    shortcode: 'WF',
    name: 'Wallis and Futuna',
  },
  {
    code: 'WSM',
    shortcode: 'WS',
    name: 'Samoa',
  },
  {
    code: 'YEM',
    shortcode: 'YE',
    name: 'Yemen',
  },
  {
    code: 'ZAF',
    shortcode: 'ZA',
    name: 'South Africa',
  },
  {
    code: 'ZMB',
    shortcode: 'ZM',
    name: 'Zambia',
  },
  {
    code: 'ZWE',
    shortcode: 'ZW',
    name: 'Zimbabwe',
  },
];

const Modal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2.5rem 1.5rem;
  gap: 1rem;
  flex: 1;
  min-height: ${BottomSheetMaxHeight};

  @media (min-width: ${screens.medium}px) {
    min-height: unset;
  }
`;

const ModalHeading = styled(Heading)`
  margin: 0 0.5rem;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
  gap: 1rem;
`;

const Separator = styled.div`
  border-bottom: 1px solid;

  ${({ theme }): CSSProp => ({
    borderBottomColor: theme.colors.shadow800,
  })}
  margin: 0 0.5rem 1rem 0.5rem;
`;

const SectionText = styled(Paragraph)`
  margin: 0 0.5rem;
`;

type CountryPickerModalProps = {
  onChange?: (value?: TCountry) => void;
  countries?: TCountry[];
  // Supported countries are grouped at the top of the modal
  supported?: TCountryAlpha3Code[];
  title?: string;
};

export const CountryPickerModal = ({
  onChange,
  countries = defaultCountries as TCountry[],
  supported = [],
  title = 'Country of Residence',
}: CountryPickerModalProps): JSX.Element => {
  const [search, setSearch] = useState('');
  const filteredCountries = useMemo(() => {
    if (!search) {
      return countries;
    }
    const term = search.toLowerCase();
    return countries.filter(
      ({ name, code }) =>
        name.toLowerCase().indexOf(term) !== -1 ||
        code.toLowerCase().indexOf(term) !== -1,
    );
  }, [search, countries]);
  const supportedCountries = filteredCountries.filter(
    (country) => supported?.indexOf(country.code) !== -1,
  );
  const unsupportedCountries = filteredCountries.filter(
    (country) => supported?.indexOf(country.code) === -1,
  );

  const handleChange = (country: TCountry): void => {
    if (onChange) {
      onChange(country);
    }
  };

  return (
    <Modal>
      <ModalHeading level="h6">{title}</ModalHeading>
      <CountryPickerSearchInput value={search} onChange={setSearch} />
      <ScrollContainer>
        <List>
          {supportedCountries.map((country) => (
            <CountryPickerListItem
              key={country.code}
              country={country}
              onClick={(): void => handleChange(country)}
            />
          ))}
        </List>
        {supportedCountries.length !== 0 &&
          unsupportedCountries.length !== 0 && (
            <>
              <Separator />
              <SectionText color="grey400" small>
                Rest of the world
              </SectionText>
            </>
          )}
        <List>
          {unsupportedCountries.map((country) => (
            <CountryPickerListItem
              key={country.code}
              country={country}
              onClick={(): void => handleChange(country)}
            />
          ))}
        </List>
      </ScrollContainer>
    </Modal>
  );
};

export default CountryPickerModal;
