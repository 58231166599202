import { Tooltip } from 'react-tooltip';
import styled from 'styled-components';

import { Pill } from '@hedgehog/ui/pills';
import { Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ImportLimitedPartnerRow } from './csv-parser.helpers';

const TypePill = styled(Pill)`
  ${({ theme }) => ({
    textTransform: 'capitalize',
    backgroundColor: theme.colors.secondary,
  })}
`;

type AddFundClientRowProps = {
  row: ImportLimitedPartnerRow;
};

export const AddFundClientRow = ({ row }: AddFundClientRowProps) => {
  const { email, commitment, name, surname, country, type, fundId } = row.data;
  const errors = row.errors;
  return (
    <>
      <tr>
        <td>{email}</td>
        <td>
          {currencies.formatMoney(commitment, {
            minimumFractionDigits: 2,
          })}
        </td>
        <td>{name}</td>
        <td>{surname}</td>
        <td>{country}</td>
        <td>{type ? <TypePill>{type.toLowerCase()}</TypePill> : 'N/A'}</td>
        <td>{fundId && fundId > 0 ? fundId : 'N/A'}</td>
        <td>
          {errors && errors?.length > 0 && (
            // display red exclamation mark and tooltip for message on hover
            <Paragraph>
              <span
                role="img"
                aria-label="error"
                data-tooltip-id={`${errors[0].row}-${errors[0].column}-${errors[0].error}`}
                data-tooltip-content={errors
                  .map((error) => error.error)
                  .join('\n')}
                data-tooltip-place={'top'}
              >
                ❗
                <Tooltip
                  id={`${errors[0].row}-${errors[0].column}-${errors[0].error}`}
                />
              </span>
            </Paragraph>
          )}
        </td>
      </tr>
    </>
  );
};
