import styled from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

export const HedgehogIcon = styled(
  ({ className }: StandardProps<object, never>): JSX.Element => {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 66 40"
        className={className}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M35.6209 3.27272H29.3207V18.3371L23.6699 4.56383L17.8492 6.99794L23.5575 20.9111L13.1201 10.3735L8.66513 14.8712L19.2152 25.5225L5.57246 19.8172L3.16147 25.6937L16.9443 31.4575H2.18188V37.8182H32.8594L33.8221 37.8168C34.2436 37.8168 34.6234 37.5596 34.7834 37.1657L47.1549 6.69921L41.326 4.2616L35.6209 18.1423V3.27272Z"
          fill="currentColor"
        />
        <path
          d="M51.7898 27.7217C51.7898 29.6519 50.2399 31.2166 48.328 31.2166C46.4162 31.2166 44.8663 29.6519 44.8663 27.7217C44.8663 25.7915 46.4162 24.2267 48.328 24.2267C50.2399 24.2267 51.7898 25.7915 51.7898 27.7217Z"
          fill="currentColor"
        />
        <path
          d="M62.7528 30.6709H56.7535C56.5434 30.6709 56.354 30.7987 56.2736 30.9946L53.7708 37.0914C53.629 37.4368 53.8804 37.8167 54.2507 37.8167H60.25C60.46 37.8167 60.6494 37.6889 60.7299 37.493L63.2327 31.3962C63.3744 31.0508 63.123 30.6709 62.7528 30.6709Z"
          fill="currentColor"
        />
      </svg>
    );
  },
)`
  color: inherit;
`;

export default HedgehogIcon;
