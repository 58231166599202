import { Outlet, useParams } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useAppData, useClientQuery } from '@hedgehog/data-access/partners';
import { BreadcrumbsLayout } from '@hedgehog/ui/layouts';
import { Tabs } from '@hedgehog/ui/tabs';

import { ClientName } from '../../../components';
import { TabLink } from '../../../components/tab-link/tab-link.component';

const TabContent = styled.div`
  padding-top: 2.5rem;
`;

const TabsContainer = styled.div`
  padding: 1.25rem 1.875rem;
  // counters breadcrumbs padding
  margin: 0 -1.875rem;
  border-bottom: 1px solid;
  ${({ theme }): CSSProp => ({
    borderBottomColor: theme.colors.grey200,
  })}
`;

export const FundClientPage = () => {
  const { clientId } = useParams();
  const { activePartner } = useAppData();
  const { data: client } = useClientQuery(
    {
      partnerId: activePartner?.id || '',
      clientId: clientId || '',
    },
    {
      skip: !activePartner,
    },
  );

  if (!client?.user) {
    return null;
  }

  return (
    <BreadcrumbsLayout
      title={
        <ClientName
          level="h4"
          canInvest={client.canInvest}
          firstName={client.user.firstName}
          lastName={client.user.lastName}
        />
      }
    >
      <TabsContainer>
        <Tabs small>
          <TabLink to={`/funds/clients/${clientId}/profile`}>Profile</TabLink>
          <TabLink to={`/funds/clients/${clientId}/portfolio`}>
            Portfolio
          </TabLink>
          <TabLink to={`/funds/clients/${clientId}/transactions`}>
            Transactions
          </TabLink>
          <TabLink to={`/funds/clients/${clientId}/kyc`}>KYC</TabLink>
        </Tabs>
      </TabsContainer>
      <TabContent>
        <Outlet />
      </TabContent>
    </BreadcrumbsLayout>
  );
};
