import { ReactNode } from 'react';
import styled from 'styled-components';

import {
  TAssessmentAnswer,
  TAssessmentAnswerOption,
} from '@hedgehog/shared/types';
import { Paragraph } from '@hedgehog/ui/typography';

import { useContentParameters } from '../../ContentContext';

import { AnswerButton, AnswerOptions } from './styles';

const Answers = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 0.5rem;
`;

const Warnings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

type QuestionAnswerProps = {
  questionId: string;
  answer: TAssessmentAnswer;
  onClick?: () => void;
  onQuestionAnswerClick?: AnswerOptionSelectHandler;
};

export interface AnswerOptionSelectHandler {
  (questionId: string, answerId: string, option: TAssessmentAnswerOption): void;
}

export const QuestionAnswer = ({
  questionId,
  answer,
  onClick,
}: QuestionAnswerProps): JSX.Element => {
  const { answers, onQuestionAnswerClick } = useContentParameters();

  const isOptionActive = (option: TAssessmentAnswerOption): boolean => {
    if (!answers) return false;
    if (!answers[questionId] || !answers[questionId][answer.id]) {
      return false;
    }
    return answers[questionId][answer.id]?.value === option.value;
  };
  const handleClick = (option: TAssessmentAnswerOption): void => {
    if (onQuestionAnswerClick) {
      onQuestionAnswerClick(questionId, answer.id, option);
    }

    if (option.warning) {
      // Return so that the user is not automatically navigated down to the next question
      return;
    }
    if (onClick) onClick();
  };

  const warnings = answer.options.reduce<ReactNode[]>((options, option) => {
    const active = isOptionActive(option);
    const showWarning = Boolean(option.warning && active);
    if (!showWarning) return options;
    return [
      ...options,
      <Paragraph small color="grey500">
        {option.warning}
      </Paragraph>,
    ];
  }, []);

  return (
    <AnswerOptions>
      <Answers>
        {answer.options.map((option) => {
          const active = isOptionActive(option);
          return (
            <AnswerButton
              key={option.value}
              active={active}
              onClick={(): void => handleClick(option)}
            >
              {option.label}
            </AnswerButton>
          );
        })}
      </Answers>
      {warnings.length !== 0 && (
        <Warnings>
          {warnings.map((warning) => {
            return (
              <Paragraph small color="grey500">
                {warning}
              </Paragraph>
            );
          })}
        </Warnings>
      )}
    </AnswerOptions>
  );
};
