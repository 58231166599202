export const BuildingIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0H0V14H2V10H4V14H14V8H10V0ZM4 2H2V4H4V2ZM8 2H6V4H8V2ZM6 6H8V8H6V6ZM10 10H12V12H10V10Z"
      fill="currentColor"
    />
  </svg>
);

export default BuildingIcon;
