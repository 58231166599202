export const ChevronRightIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 11 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.17158 8L0.58579 3.41421L3.41422 0.585785L10.8284 8L3.41422 15.4142L0.58579 12.5858L5.17158 8Z"
      fill="currentColor"
    />
  </svg>
);

export default ChevronRightIcon;
