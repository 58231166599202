import { css } from 'styled-components';

export const Reset = css`
  min-width: 0;
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
`;
