import { Button, SecondaryButton } from '@hedgehog/ui/buttons';
import { Paragraph } from '@hedgehog/ui/typography';

export const Reactivate = ({
  firstName,
  lastName,
  enable,
  loading,
  cancel,
}: {
  firstName: string;
  lastName: string;
  enable: () => void;
  loading: boolean;
  cancel: () => void;
}): JSX.Element => (
  <>
    <Paragraph>
      Are you sure you want to reactivate {firstName} {lastName}?
    </Paragraph>
    <SecondaryButton fluid onClick={enable} loading={loading}>
      Reactivate member
    </SecondaryButton>
    <Button fluid onClick={cancel}>
      Cancel
    </Button>
  </>
);
