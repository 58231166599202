import { useParams } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { AssetPage } from '@hedgehog/data-access/asset-types';
import { useAppData, useAssetQuery } from '@hedgehog/data-access/partners';
import { ContentCMS } from '@hedgehog/ui/cms';
import { Gallery } from '@hedgehog/ui/galleries';
import { EmptyStateContainer, LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { AssetPageTab } from '../../components';

const AssetGallery = styled.div`
  height: 11.25rem;

  ${({ theme }): CSSObject => ({
    [`@media only screen and (max-width: ${theme.breakpoints.normal}px)`]: {
      marginRight: '-1.5rem',
    },
  })}
`;

export const AssetComingSoonTabPage = () => {
  const track = useAnalyticsTrack();
  const { assetId = '' } = useParams();
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';
  const { data: asset } = useAssetQuery(
    { assetId, partnerId },
    { skip: !assetId || !partnerId },
  );
  const page = asset?.pages.find(
    (entity: AssetPage) => entity.slug === 'coming-soon',
  );

  if (!asset) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (!page) {
    return <EmptyStateContainer>Page not found</EmptyStateContainer>;
  }

  return (
    <AssetPageTab>
      <AssetGallery>
        <Gallery resources={asset.details.assetSummary.resources || []} />
      </AssetGallery>
      <ContentCMS content={page.content} onAnalyticsTrack={track} />
    </AssetPageTab>
  );
};
