import React, { useContext, useEffect, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { AutoscrollContext } from '../autoscroll.context';

type NextFn = () => void;
type PreviousFn = () => void;

export const useAutoscroll = (
  elementRef: React.RefObject<HTMLElement>,
): [NextFn, PreviousFn] => {
  const context = useContext(AutoscrollContext);
  const id = useMemo(() => uuidv4(), []);

  useEffect(() => {
    const { current: element } = elementRef;
    if (!element || !context) {
      return () => {
        /* NOOP */
      };
    }

    // Add data attribute to element
    element.setAttribute('data-autoscroll-id', id);
    context?.mount(id, element);

    return () => {
      // Clean up
      context?.unmount(id);
      element.removeAttribute('data-autoscroll-id');
    };
  }, []);

  return [() => context.next(id), () => context.previous(id)];
};
