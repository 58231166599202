import { useNavigate } from 'react-router-dom';

import { CardListItem } from '@hedgehog/browser/shared/funds/feature-marketplace';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm } from '../../../../components';
import {
  useMarketListingBalance,
  useMarketListingParams,
  useNavigateToListingPage,
} from '../../../../containers';

export const MarketListingCreatedPage = (): JSX.Element => {
  const { navigateTo, close } = useNavigateToListingPage();
  const navigate = useNavigate();
  const [balance] = useMarketListingBalance();
  const [params] = useMarketListingParams();

  return (
    <ModalForm
      heading="Listing is live"
      submitButtonText="Done"
      submitModal={() => navigate(`/market/${params.fundId}`)}
      backModal={() => navigateTo(`create/terms-conditions`)}
      closeModal={() => close()}
    >
      <Paragraph weight={700}>
        The following listing is now live for all advisors to see.
      </Paragraph>
      <CardListItem
        title={params.fund?.name || ''}
        subtitle={params.fund?.description || ''}
        body={[
          [
            'Unfunded commitment',
            currencies.formatMoney(balance.commitment * params.positionRatio),
          ],
          [
            'Net Asset Value',
            currencies.formatMoney(balance.contribution * params.positionRatio),
          ],
        ]}
        footer={[
          <>
            <Heading level="h5" color="grey400">
              Asking price
            </Heading>
            <Heading level="h5">
              {currencies.formatMoney(params.positionPrice)}
            </Heading>
          </>,
          <Paragraph key="expires-at">Listing expires in 14 days</Paragraph>,
        ]}
      />
    </ModalForm>
  );
};
