export const ChevronLeftIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 21 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.657 15L20.8285 5.82842L15.1717 0.17157L0.343262 15L15.1717 29.8284L20.8285 24.1716L11.657 15Z"
      fill="currentColor"
    />
  </svg>
);
