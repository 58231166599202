import { useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { MarketList } from '@hedgehog/browser/shared/funds/feature-marketplace';
import { useMediaQuery } from '@hedgehog/data-access/hooks';
import { useAppData, useFundsV2Query } from '@hedgehog/data-access/partners';
import { PrimaryLinkButton } from '@hedgehog/ui/buttons';
import { Column, MobileColumn } from '@hedgehog/ui/layouts';
import { fromBreakpoint, fromBreakpointMediaQuery } from '@hedgehog/ui/themes';
import { Widget, WidgetContent } from '@hedgehog/ui/widgets';

const MarketListColumn = styled(Column)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  ${({ theme }) => ({
    flexGrow: 0,
    ...fromBreakpoint(theme.breakpoints.tablet, { flexBasis: '24rem' }),
  })}
`;

const MarketListContainer = styled(Widget)`
  padding: 0;
  height: 100%;
  & > ${WidgetContent} {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const MarketPage = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { matches: isTabletOrDesktop } = useMediaQuery(
    fromBreakpointMediaQuery(theme.breakpoints.tablet),
  );
  const { activePartnerId } = useAppData();

  const { data: funds } = useFundsV2Query({ partnerId: activePartnerId || '' });

  const hasMarketFundOpen = useMatch('/market/:fundId');
  const hasMarketFundOrMoreOpen = useMatch('/market/:fundId/*');

  useEffect(() => {
    if (!funds) return;
    if (hasMarketFundOpen) return;
    if (hasMarketFundOrMoreOpen) return;
    if (!isTabletOrDesktop) return;

    const [fund] = funds || [];
    if (!fund) return;
    navigate(`/market/${fund.fundId}`, { replace: true });
  }, [hasMarketFundOpen, hasMarketFundOrMoreOpen, isTabletOrDesktop, funds]);

  return (
    <PageColumnLayout
      title="Secondary market"
      actions={
        <PrimaryLinkButton
          to={
            hasMarketFundOpen
              ? `/market/${hasMarketFundOpen.params.fundId}/listings/create`
              : ''
          }
          disabled={!hasMarketFundOpen}
        >
          List a position
        </PrimaryLinkButton>
      }
    >
      <MarketListColumn size="minmax(min-content, 20rem)">
        <MarketListContainer>
          <MarketList />
        </MarketListContainer>
      </MarketListColumn>
      <MobileColumn
        patterns={['/market/:fundId', '/market/:fundId/*']}
        fallbackTo=".."
      >
        <Outlet />
      </MobileColumn>
    </PageColumnLayout>
  );
};
