export const Terms = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: 'scale(2)' }}
  >
    <path
      d="M28.0306 14.7194L24.2806 10.9694C24.2109 10.8997 24.1282 10.8445 24.0371 10.8069C23.9461 10.7693 23.8485 10.7499 23.75 10.75H16.25C15.8522 10.75 15.4706 10.908 15.1893 11.1893C14.908 11.4706 14.75 11.8522 14.75 12.25V13.75H13.25C12.8522 13.75 12.4706 13.908 12.1893 14.1893C11.908 14.4706 11.75 14.8522 11.75 15.25V28.75C11.75 29.1478 11.908 29.5294 12.1893 29.8107C12.4706 30.092 12.8522 30.25 13.25 30.25H23.75C24.1478 30.25 24.5294 30.092 24.8107 29.8107C25.092 29.5294 25.25 29.1478 25.25 28.75V27.25H26.75C27.1478 27.25 27.5294 27.092 27.8107 26.8107C28.092 26.5294 28.25 26.1478 28.25 25.75V15.25C28.2501 15.1515 28.2307 15.0539 28.1931 14.9629C28.1555 14.8718 28.1003 14.7891 28.0306 14.7194ZM23.75 28.75H13.25V15.25H20.4397L23.75 18.5603V26.485C23.75 26.4906 23.75 26.4953 23.75 26.5C23.75 26.5047 23.75 26.5094 23.75 26.515V28.75ZM26.75 25.75H25.25V18.25C25.2501 18.1515 25.2307 18.0539 25.1931 17.9629C25.1555 17.8718 25.1003 17.7891 25.0306 17.7194L21.2806 13.9694C21.2109 13.8997 21.1282 13.8445 21.0371 13.8069C20.9461 13.7693 20.8485 13.7499 20.75 13.75H16.25V12.25H23.4397L26.75 15.5603V25.75ZM21.5 22.75C21.5 22.9489 21.421 23.1397 21.2803 23.2803C21.1397 23.421 20.9489 23.5 20.75 23.5H16.25C16.0511 23.5 15.8603 23.421 15.7197 23.2803C15.579 23.1397 15.5 22.9489 15.5 22.75C15.5 22.5511 15.579 22.3603 15.7197 22.2197C15.8603 22.079 16.0511 22 16.25 22H20.75C20.9489 22 21.1397 22.079 21.2803 22.2197C21.421 22.3603 21.5 22.5511 21.5 22.75ZM21.5 25.75C21.5 25.9489 21.421 26.1397 21.2803 26.2803C21.1397 26.421 20.9489 26.5 20.75 26.5H16.25C16.0511 26.5 15.8603 26.421 15.7197 26.2803C15.579 26.1397 15.5 25.9489 15.5 25.75C15.5 25.5511 15.579 25.3603 15.7197 25.2197C15.8603 25.079 16.0511 25 16.25 25H20.75C20.9489 25 21.1397 25.079 21.2803 25.2197C21.421 25.3603 21.5 25.5511 21.5 25.75Z"
      fill="currentColor"
    />
  </svg>
);

export default Terms;
