import { RefObject, useEffect, useMemo, useReducer, useState } from 'react';
import styled, { CSSProp } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

import { context } from './radio-group.context';

const RadioInputs = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    border-bottom: 2px solid transparent;
    ${({ theme }): CSSProp => ({
      borderBottomColor: theme.colors.shadow100,
    })}
    padding: 1.5rem 0;
  }
`;

type RadioInputGroupProps = StandardProps<{
  multiple?: boolean;
  defaultChecked?: string[];
  values?: Record<string, boolean | string | number>;
  onChange?: (checked: string[], values: Record<string, string>) => void;
}>;

export const RadioInputGroup = ({
  multiple = false,
  defaultChecked = [],
  children,
  onChange,
}: RadioInputGroupProps): JSX.Element => {
  const [checked, setChecked] = useState<string[]>(defaultChecked);
  const [refs, dispatch] = useReducer(
    (
      state: RefObject<HTMLInputElement>[],
      action: {
        type: 'mount' | 'unmount';
        payload: RefObject<HTMLInputElement>;
      },
    ) => {
      switch (action.type) {
        case 'mount':
          return [...state, action.payload];
        case 'unmount':
          return state.filter((ref) => ref !== action.payload);
        default:
          return state;
      }
    },
    [],
  );

  const notifyAboutChange = () => {
    if (!onChange) return;
    if (!checked.length) return;
    const values = refs
      .filter((ref) => !!ref.current && checked.includes(ref.current.name))
      .reduce(
        (acc, ref) =>
          ref.current ? { ...acc, [ref.current.name]: ref.current.value } : acc,
        {},
      );

    onChange(checked, values);
  };

  useEffect(() => {
    notifyAboutChange();
  }, [checked]);

  const mount = (ref: RefObject<HTMLInputElement>): void =>
    dispatch({ type: 'mount', payload: ref });

  const unmount = (ref: RefObject<HTMLInputElement>): void =>
    dispatch({ type: 'unmount', payload: ref });

  const check = (name: string): void => {
    if (multiple) {
      return setChecked((prev) =>
        prev.includes(name)
          ? prev.filter((_name) => _name !== name)
          : [...prev, name],
      );
    }
    return setChecked([name]);
  };

  return (
    <context.Provider
      value={useMemo(
        () => ({
          values: refs
            .filter(
              (ref): ref is { current: HTMLInputElement } => !!ref.current,
            )
            .reduce(
              (acc, { current }) =>
                checked.includes(current.name)
                  ? { ...acc, [current.name]: [current.value] }
                  : acc,
              {},
            ),
          refs,
          checked,
          mount,
          unmount,
          check,
        }),
        [checked, refs],
      )}
    >
      <RadioInputs>{children}</RadioInputs>
    </context.Provider>
  );
};

export default RadioInputGroup;
