import * as Papa from 'papaparse';

import { TransactionType } from '@hedgehog/shared/blockchain/resident-token';

interface IssuanceCSVRow {
  timestamp: string;
  email: string;
  amount: string;
  issuanceType: string;
  rentLate: string;
}

export interface IssuanceRowData {
  timestamp: number;
  email: string;
  amount: number;
  type: 'rent:paid' | 'rent:late' | 'lease:renew' | 'sign-on' | null;
  vestingDurationInSeconds?: number;
}
export interface IssuanceData {
  columns: IssuanceRowData;
  errors: Partial<Record<keyof IssuanceRowData, string[] | undefined>>;
}

const MIN_TOKEN_AMOUNT = 0;
const MAX_TOKEN_AMOUNT = 1500;

const ISSUANCE_TYPES = ['rent:paid', 'rent:late', 'lease:renew', 'sign-on'];

const validateFields = (fields: string[]): void => {
  const requiredFields = ['timestamp', 'email', 'amount', 'issuanceType'];
  const missingFields = requiredFields.filter(
    (field) => !fields.includes(field),
  );

  if (missingFields.length) {
    throw new Error(
      `Missing fields: ${missingFields.join(
        ', ',
      )}, (parsed fields: ${fields.join(', ')})`,
    );
  } else if (fields.length > requiredFields.length) {
    throw new Error(
      `Unrecognized field "${fields.find(
        (field) => !requiredFields.includes(field),
      )}"`,
    );
  }
};

const validateAmount = (amount: number): string[] => {
  if (typeof amount !== 'number') {
    return ['Token amount is required'];
  }
  const tokenCount = amount;
  if (tokenCount < MIN_TOKEN_AMOUNT || tokenCount > MAX_TOKEN_AMOUNT) {
    return [
      `Token amount must be between ${MIN_TOKEN_AMOUNT} and ${MAX_TOKEN_AMOUNT}`,
    ];
  }

  return [];
};

const isValidType = (issuanceType: string): issuanceType is TransactionType =>
  ISSUANCE_TYPES.includes(issuanceType);

const validateIssuanceType = (issuanceType: string | null): string[] => {
  if (!issuanceType || !isValidType(issuanceType)) {
    return [`Issuance type must be one of {${ISSUANCE_TYPES.join(',')}}`];
  }
  return [];
};

const validateRow = (
  row: IssuanceRowData,
): Partial<Record<keyof IssuanceRowData, string[] | undefined>> => {
  return {
    amount: validateAmount(row.amount),
    type: validateIssuanceType(row.type),
  };
};

export const transform = (csvData: string): IssuanceData[] => {
  const parsed = Papa.parse<IssuanceCSVRow>(csvData, { header: true });
  if (parsed.errors.length) {
    throw new Error(parsed.errors[0].message);
  }

  validateFields(parsed.meta.fields || []);

  return parsed.data.map((row) => {
    const { timestamp, email, issuanceType, amount } = row;

    const columns = {
      timestamp: parseInt(timestamp),
      email,
      amount: parseInt(amount, 10),
      type: issuanceType as IssuanceRowData['type'],
    };

    const issuanceData: IssuanceData = {
      columns,
      errors: validateRow(columns),
    };

    return issuanceData;
  });
};

type Heading = keyof IssuanceData['columns'];

export const headings = (
  rows: IssuanceData | IssuanceData[] = [],
): Heading[] => {
  const firstRow = Array.isArray(rows) ? rows[0] : rows;
  return firstRow ? <Heading[]>Object.keys(firstRow.columns) : [];
};

export const errors = (row: IssuanceData): string[] =>
  Object.values(row.errors).flat();

export const hasErrors = (row: IssuanceData): boolean => !!errors(row).length;
