export const VoteUpIcon = ({ className = '' }): JSX.Element => (
  <svg
    className={className}
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM24 16.5V19L8 19V16.5L16 9L24 16.5Z"
      fill="currentColor"
    />
  </svg>
);

export default VoteUpIcon;
