import {
  GetTeamMemberResponse,
  GetTeamMemberRequest,
  GetTeamMembersRequest,
  GetTeamMembersResponse,
  InviteTeamMemberRequest,
  InviteTeamMemberResponse,
  UpdateTeamMemberRequest,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import { baseApi } from './base.api';

export const teamMembersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    whoami: builder.query<GetTeamMemberResponse['data'], void>({
      query: () => 'team-member',
      transformResponse: (response: GetTeamMemberResponse) => response.data,
    }),
    teamMembers: builder.query<
      GetTeamMembersResponse['data'],
      GetTeamMembersRequest
    >({
      query: ({ partnerId, query }) => ({
        url: `partners/${partnerId}/team-members`,
        params: {
          query,
        },
      }),
      transformResponse: (response: GetTeamMembersResponse) => response.data,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ teamMemberId }) => ({
                type: 'TeamMembers' as const,
                id: teamMemberId,
              })),
            ]
          : [{ type: 'TeamMembers' as const, id: 'LIST' }],
    }),
    inviteTeamMember: builder.mutation<
      InviteTeamMemberResponse,
      InviteTeamMemberRequest & { partnerId: UUID }
    >({
      query: ({ partnerId, ...body }) => ({
        url: `partners/${partnerId}/team-members`,
        method: 'POST',
        body,
      }),
    }),
    teamMember: builder.query<
      GetTeamMemberResponse['data'],
      GetTeamMemberRequest
    >({
      query: ({ partnerId, teamMemberId }) => ({
        url: `partners/${partnerId}/team-members/${teamMemberId}`,
      }),
      transformResponse: (response: GetTeamMemberResponse) => response.data,
      providesTags: (result) =>
        result ? [{ type: 'TeamMembers', id: result.teamMemberId }] : [],
    }),
    updateTeamMember: builder.mutation<
      GetTeamMemberResponse['data'],
      UpdateTeamMemberRequest
    >({
      query: ({ partnerId, teamMemberId, body }) => ({
        url: `partners/${partnerId}/team-members/${teamMemberId}`,
        method: 'PUT',
        body,
      }),
      transformResponse: (response: GetTeamMemberResponse) => response.data,
      invalidatesTags: (result) =>
        result ? [{ type: 'TeamMembers', id: result.teamMemberId }] : [],
    }),
  }),
});

export const {
  useWhoamiQuery,
  useTeamMembersQuery,
  useLazyTeamMembersQuery,
  useTeamMemberQuery,
  useInviteTeamMemberMutation,
  useUpdateTeamMemberMutation,
} = teamMembersApi;
