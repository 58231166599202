import { useCallback } from 'react';

import { AnyFunction } from '@hedgehog/shared/types';

import { useCacheEntry } from './use-cache-entry.hook';

export interface CachedCallbackInit {
  key: string;
  lifetimeInMs?: number;
}

export const useCachedCallback = <CallbackFn extends AnyFunction>(
  callbackFn: CallbackFn,
  { key, lifetimeInMs }: CachedCallbackInit,
): CallbackFn => {
  const [entry, { set, get }] = useCacheEntry(key);
  const handleCallback: AnyFunction = (...args) => {
    const cachedValue = get();

    if (
      cachedValue &&
      (lifetimeInMs
        ? cachedValue.expiresAt && cachedValue.expiresAt > Date.now()
        : true)
    ) {
      return cachedValue.value;
    }
    const value = callbackFn(...args);
    set(value, { lifetimeInMs });
    return value;
  };

  return useCallback(handleCallback as CallbackFn, [entry]);
};
