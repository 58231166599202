import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { Column, MobileColumn } from '@hedgehog/ui/layouts';
import { fromBreakpoint } from '@hedgehog/ui/themes';
import { Widget, WidgetContent } from '@hedgehog/ui/widgets';

import { AssetList } from '../../containers';

const AssetsListColumn = styled(Column)`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100%;
  ${({ theme }) => ({
    flexGrow: 0,
    ...fromBreakpoint(theme.breakpoints.tablet, { flexBasis: '24rem' }),
  })}
`;

const AssetsListContainer = styled(Widget)`
  padding: 0;
  height: 100%;
  & > ${WidgetContent} {
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
  }
`;

export const AssetsPage = (): JSX.Element => {
  return (
    <PageColumnLayout title="Assets">
      <AssetsListColumn size="minmax(min-content, 20rem)">
        <AssetsListContainer>
          <AssetList />
        </AssetsListContainer>
      </AssetsListColumn>
      <MobileColumn match="/assets/:assetId/*">
        <Outlet />
      </MobileColumn>
    </PageColumnLayout>
  );
};
