import { useEffect } from 'react';
import styled, { useTheme } from 'styled-components';

import { useFundsStatistics } from '@hedgehog/data-access/partners';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import {
  Datum,
  PieChart,
} from '../client-investor-type-metrics/pie-chart.component';

export type FundsTotalCapitalProps = StandardProps<
  { loading?: boolean; onLoaded?: () => void },
  never
>;

const FundsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  padding: 1rem;
`;

const FundsChart = styled.div`
  display: flex;
  flex: 0 0 auto;
  max-height: 14rem;
  max-width: 14rem;
`;

export const FundsDistribution = styled(
  ({
    loading: parentLoading,
    onLoaded = () => void 0,
    className,
  }: FundsTotalCapitalProps): JSX.Element => {
    const theme = useTheme();

    const { data: statistics, loading: statisticsLoading } =
      useFundsStatistics();

    useEffect(() => {
      if (statistics && !statisticsLoading) {
        onLoaded();
      }
    }, [statisticsLoading]);

    const loading = parentLoading || statisticsLoading;
    const dataset =
      statistics && !loading
        ? statistics.fundIds
            .map((id) => statistics.funds[id])
            .filter(({ totalAmount }) => totalAmount > 0)
            .reduce<Datum[]>(
              (acc, fund) => [
                ...acc,
                {
                  color: theme.colors.grey200,
                  label: `Unsettled - ${fund.name.slice(0, 16)}...`,
                  value: fund.totalAmount - fund.settledTotalAmount,
                },
                {
                  color: theme.colors.success,
                  label: `Settled - ${fund.name.slice(0, 16)}...`,
                  value: fund.settledTotalAmount,
                },
              ],
              [],
            )
        : [{ label: 'loading', value: 0, color: theme.colors.grey100 }];

    return (
      <FundsWrapper>
        <FundsChart>
          <PieChart
            className={className}
            layout={{
              autoPadding: true,
            }}
            dataset={dataset}
            plugins={{
              legend: {
                display: false,
              },
              tooltip: {
                position: 'nearest',
                callbacks: {
                  label: ({
                    dataset: {
                      data: [value],
                    },
                  }) => currencies.formatMoney(value),
                },
              },
            }}
          />
        </FundsChart>
      </FundsWrapper>
    );
  },
)``;
