import { ReactNode } from 'react';
import styled from 'styled-components';

import { Accordion } from '@hedgehog/ui/accordions';
import { Card, CardContent, LinkProps } from '@hedgehog/ui/cards';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { CardListItemContent } from './card-list-item-content.component';
import { RowContent, RowContentObject } from './card-list-item-row.component';

const CardAccordion = styled(Accordion)``;

const CustomCard = styled(Card).attrs({ outline: true })`
  & > ${CardContent} {
    gap: 1rem;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  margin-bottom: 0.5rem;
`;

export type CardListItemProps = StandardProps<
  {
    loading?: boolean;
    title?: string;
    subtitle?: string;
    askingPriceAmount?: number;
    commitmentAmount?: number;
    unfundedCommitmentAmount?: number;
    expiresAt?: Date;
    body?: (RowContentObject | RowContent | false)[];
    footer?: (RowContentObject | RowContent | false)[];
    accordion?: ReactNode | ReactNode[];
    accordionTitle?: ReactNode | ReactNode[];
    onBidClick?: () => void;
  } & LinkProps
>;

export const CardListItem = ({
  title,
  subtitle,
  onBidClick,
  accordion,
  accordionTitle,
  body = [],
  footer = [],
  loading = false,
  ...props
}: CardListItemProps): JSX.Element => (
  <CustomCard {...props}>
    <CardListItemContent
      loading={loading}
      body={[
        title && {
          align: 'flex-start' as const,
          content: (
            <HeadingWrapper>
              <Heading level="h4" loading={loading}>
                {title}
              </Heading>
              {subtitle && <Paragraph loading={loading}>{subtitle}</Paragraph>}
            </HeadingWrapper>
          ),
        },
        ...body,
      ].filter(Boolean)}
      footer={footer.filter(Boolean)}
    />
    {accordion && (
      <CardAccordion title={accordionTitle}>{accordion}</CardAccordion>
    )}
  </CustomCard>
);
