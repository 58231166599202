import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useMatch } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';
import {
  Abi,
  Hex,
  decodeAbiParameters,
  formatEther,
  getAddress,
  zeroAddress,
} from 'viem';

import {
  useAppData,
  useFundReads,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { singularOrPlural } from '@hedgehog/shared/utils/content';
import { parseBorderToShadow } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import { CardListItem } from '../../components';

export type FundsListProps = StandardProps<{
  account?: string;
}>;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

const MarketListItem = styled(CardListItem)<{ active?: boolean }>`
  ${({ active, theme }): CSSProp => ({
    boxShadow: active
      ? parseBorderToShadow(theme.border.normal, {
          outset: false,
          color: theme.colors.black,
        })
      : undefined,
  })}
`;

export const MarketList = ({ className }: FundsListProps): JSX.Element => {
  const match = useMatch('/market/:fundId');
  const { activePartnerId } = useAppData();
  const { data: funds = [], isLoading: areFundsLoading } = useFundsV2Query({
    partnerId: activePartnerId || '',
  });

  const contracts = funds?.map((fund) => ({
    abi: IFund.abi as Abi,
    address: getAddress(fund.address || zeroAddress),
    functionName: 'getListings',
    scopeKey: 'funds/listings',
    args: [],
  }));

  const [refetchFundAndListings, { data: fundsListings = [], isLoading }] =
    useFundReads({
      contracts,
      scopeKey: 'funds/listings',
    });

  const fundsWithListings = funds
    .filter(() => !!fundsListings && fundsListings.length === funds.length)
    .map((fund, fundIndex) => {
      const { result } = fundsListings[fundIndex] as {
        result: [bigint[], Hex[]];
      };
      const [ids, data] = result;
      const listings = ids.map((listingId: bigint, listingIndex: number) => {
        const [
          commitment = BigInt(0),
          contributions = BigInt(0),
          price = BigInt(0),
          status,
        ] = decodeAbiParameters(
          [
            { name: 'commitment', type: 'uint256' },
            { name: 'contributions', type: 'uint256' },
            { name: 'price', type: 'uint256' },
            { name: 'status', type: 'uint8' },
          ],
          data[listingIndex],
        );

        return {
          listingId,
          commitment,
          contributions,
          price,
          status,
        };
      });

      const askingPriceFrom = listings.length
        ? Math.min(...listings.map(({ price }) => Number(formatEther(price))))
        : 0;
      const unfundedCommitmentFrom = listings.length
        ? Math.min(
            ...listings.map(({ commitment }) =>
              Number(formatEther(commitment)),
            ),
          )
        : 0;

      return {
        fundId: fund.fundId,
        name: fund.name,
        description: fund.description,
        address: fund.address,
        listings,
        askingPriceFrom,
        unfundedCommitmentFrom,
      };
    });

  return (
    <Wrapper className={className}>
      {areFundsLoading || isLoading ? (
        <MarketListItem
          loading
          body={[
            {
              align: 'flex-start',
              content: (
                <Heading level="h5" loading={true}>
                  Fund Placeholder
                </Heading>
              ),
            },
            {
              align: 'flex-start',
              content: (
                <Paragraph weight="400" loading={true}>
                  Fund Description Placeholder
                </Paragraph>
              ),
            },
            ['Unfunded commitment from', '$000,000.00'],
            ['Asking price from', '$00,000.00'],
          ]}
        />
      ) : (
        fundsWithListings.map((fund) => (
          <MarketListItem
            key={fund.fundId}
            to={`/market/${fund.fundId}`}
            active={match?.params.fundId === fund.fundId}
            body={[
              {
                align: 'flex-start',
                content: <Heading level="h5">{fund.name}</Heading>,
              },
              {
                align: 'flex-start',
                content: <Paragraph weight="400">{fund.description}</Paragraph>,
              },
              [
                'Unfunded commitment from',
                currencies.formatMoney(fund.unfundedCommitmentFrom ?? 0),
              ],
              [
                'Asking price from',
                currencies.formatMoney(fund.askingPriceFrom ?? 0),
              ],
              `
              ${fund.listings.length}
              ${singularOrPlural(
                fund.listings.length ?? 0,
                'Listing',
                'Listings',
              )}
            `,
            ]}
            footer={[]}
          />
        ))
      )}
    </Wrapper>
  );
};
