import {
  GetPartnerClientsMetricsResponse,
  GetPartnerClientsOnboardingMetricsResponse,
  GetPartnerInvestorTypesMetricResponse,
  GetPartnerTotalInvestmentAmountResponse,
  GetPartnerYieldAccumulationResponse,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import { baseApi } from './base.api';

export const metricsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    totalInvestmentAmountMetrics: builder.query<
      GetPartnerTotalInvestmentAmountResponse['data'],
      UUID
    >({
      query: (partnerId) => ({
        url: `partners/${partnerId}/metrics/total-investment-amount`,
      }),
      transformResponse: (response: GetPartnerTotalInvestmentAmountResponse) =>
        response.data,
    }),

    yieldAccumulationMetrics: builder.query<
      GetPartnerYieldAccumulationResponse['data'],
      UUID
    >({
      query: (partnerId) => ({
        url: `partners/${partnerId}/metrics/yield-accumulation`,
      }),
      transformResponse: (response: GetPartnerYieldAccumulationResponse) =>
        response.data,
    }),
    clientsInvestmentMetrics: builder.query<
      GetPartnerClientsMetricsResponse['data'],
      UUID
    >({
      query: (partnerId) => ({
        url: `partners/${partnerId}/metrics/clients`,
      }),
      transformResponse: (response: GetPartnerClientsMetricsResponse) =>
        response.data,
    }),
    clientsInvestorTypeByCountry: builder.query<
      GetPartnerInvestorTypesMetricResponse['data'],
      UUID
    >({
      query: (partnerId) => ({
        url: `partners/${partnerId}/metrics/investor-types`,
      }),
      transformResponse: (response: GetPartnerInvestorTypesMetricResponse) =>
        response.data,
      providesTags: () => ['Metrics'],
    }),
    onboardingMetrics: builder.query<
      GetPartnerClientsOnboardingMetricsResponse['data'],
      UUID
    >({
      query: (partnerId) => ({
        url: `partners/${partnerId}/metrics/onboarding`,
      }),
      transformResponse: (
        response: GetPartnerClientsOnboardingMetricsResponse,
      ) => response.data,
    }),
  }),
});

export const {
  useTotalInvestmentAmountMetricsQuery,
  useYieldAccumulationMetricsQuery,
  useClientsInvestmentMetricsQuery,
  useClientsInvestorTypeByCountryQuery,
  useOnboardingMetricsQuery,
} = metricsApi;
