import jwtDecode from 'jwt-decode';
import React, { useState, useContext, useEffect, ReactNode } from 'react';

import {
  NativeMobileExperiences,
  askMobileAppForCognitoTokens,
  useIsNativeMobileExperience,
  sendMessageWithPayloadToApp,
} from '@hedgehog/data-access/native-mobile';

import { CognitoSessionTokens, LocalStorageAuthKeys } from './auth.types';
import {
  beginCognitoUserForgotPassword,
  confirmCognitoUserPassword,
  createCognitoSession,
  createCognitoUser,
  createGoogleCognitoSession,
  deleteCognitoUser,
  destroyCognitoSession,
  refreshCognitoSession,
} from './auth.utils';
import { useCognitoUserPool } from './hooks';
import { hasTokenExpired } from './jwt.helpers';

export interface SignInParams {
  email: string;
  password: string;
}

export interface GoogleSigninParams {
  email: string;
  googleIdToken: string;
}

export type ConfirmPasswordParams = {
  email: string;
  code: string;
  newPassword: string;
};

export interface AuthContextType {
  accessToken: string | null;
  refreshToken: string | null;
  signup: (params: SignInParams) => Promise<string>;
  exchangeGoogleSession: (
    params: GoogleSigninParams,
  ) => Promise<CognitoSessionTokens>;
  signin: (params: SignInParams) => Promise<CognitoSessionTokens>;
  signout: (callback?: () => void) => void;
  refresh: () => Promise<CognitoSessionTokens>;
  setTokens: (params: CognitoSessionTokens) => void;
  deleteUser: () => void;
  forgotPassword: (email: string) => Promise<void>;
  confirmPassword: (params: ConfirmPasswordParams) => Promise<void>;
}

const throwNotInitialized = (): never => {
  throw new Error('AuthContext was not initialized');
};

const AuthContext = React.createContext<AuthContextType>({
  accessToken: null,
  refreshToken: null,
  signup: throwNotInitialized,
  exchangeGoogleSession: throwNotInitialized,
  signin: throwNotInitialized,
  signout: throwNotInitialized,
  refresh: throwNotInitialized,
  setTokens: throwNotInitialized,
  deleteUser: throwNotInitialized,
  forgotPassword: throwNotInitialized,
  confirmPassword: throwNotInitialized,
});

export type AuthProviderProps = {
  onSignOut?: () => void;
  onSessionExpired?: () => void;
  children?: ReactNode | ReactNode[];
};

export const AuthProvider = ({
  onSignOut,
  onSessionExpired,
  children,
}: AuthProviderProps): JSX.Element => {
  const cognitoUserPool = useCognitoUserPool();
  const isWebkitEmbedded = useIsNativeMobileExperience(
    NativeMobileExperiences.DEVICE_IOS,
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem(LocalStorageAuthKeys.AccessToken),
  );
  const [refreshToken, setRefreshToken] = useState(
    localStorage.getItem(LocalStorageAuthKeys.RefreshToken),
  );

  const setTokens = ({
    accessToken,
    refreshToken,
  }: CognitoSessionTokens): void => {
    setAccessToken(accessToken);
    window.localStorage.setItem(LocalStorageAuthKeys.AccessToken, accessToken);

    if (refreshToken) {
      setRefreshToken(refreshToken);
      window.localStorage.setItem(
        LocalStorageAuthKeys.RefreshToken,
        refreshToken,
      );
    }

    sendMessageWithPayloadToApp('setTokens', {
      accessToken: accessToken,
      refreshToken: refreshToken,
    });
  };

  const signup = async ({ email, password }: SignInParams): Promise<string> => {
    return createCognitoUser(email, password, cognitoUserPool);
  };

  const exchangeGoogleSession = async ({
    email,
    googleIdToken,
  }: GoogleSigninParams): Promise<CognitoSessionTokens> => {
    const session = await createGoogleCognitoSession({
      email,
      googleIdToken,
      userPool: cognitoUserPool,
    });
    setTokens({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    });
    return session;
  };

  const signin = async ({
    email,
    password,
  }: SignInParams): Promise<CognitoSessionTokens> => {
    const session = await createCognitoSession(
      email,
      password,
      cognitoUserPool,
    );
    setTokens({
      accessToken: session.accessToken,
      refreshToken: session.refreshToken,
    });
    return session;
  };

  const refresh = async (): Promise<CognitoSessionTokens> => {
    if (!accessToken) throw new Error('expected non-empty access token');
    if (!refreshToken) throw new Error('expected non-empty refresh token');
    const { email } = jwtDecode(accessToken) as { email: string };
    const session = isWebkitEmbedded
      ? await askMobileAppForCognitoTokens()
      : await refreshCognitoSession(email, refreshToken, cognitoUserPool);
    setAccessToken(session.accessToken);
    setRefreshToken(session.refreshToken);
    return session;
  };

  const signout = (callback?: () => void): void => {
    if (accessToken) {
      const { email } = jwtDecode(accessToken) as { email: string };
      if (email && cognitoUserPool) {
        destroyCognitoSession(email, cognitoUserPool);
      }
    }
    setAccessToken(null);
    setRefreshToken(null);
    window.localStorage.removeItem(LocalStorageAuthKeys.AccessToken);
    window.localStorage.removeItem(LocalStorageAuthKeys.RefreshToken);
    window.localStorage.removeItem(LocalStorageAuthKeys.User);
    callback && callback();
    onSignOut && onSignOut();
  };

  const deleteUser = async (): Promise<void> => {
    if (!accessToken) return;
    const { email } = jwtDecode(accessToken) as { email: string };
    return deleteCognitoUser(email, cognitoUserPool);
  };

  const forgotPassword = async (email: string): Promise<void> => {
    return beginCognitoUserForgotPassword(email, cognitoUserPool);
  };

  const confirmPassword = async ({
    email,
    code,
    newPassword,
  }: ConfirmPasswordParams): Promise<void> => {
    return confirmCognitoUserPassword(
      email,
      code,
      newPassword,
      cognitoUserPool,
    );
  };

  // Sign out when access token expires
  useEffect(() => {
    if (!accessToken) return;
    const isTokenExpired = hasTokenExpired(accessToken);
    if (!isTokenExpired) return;
    onSessionExpired && onSessionExpired();
    signout();
  }, [onSessionExpired, accessToken]);

  const value: AuthContextType = {
    accessToken,
    refreshToken,
    signup,
    exchangeGoogleSession,
    signin,
    signout,
    refresh,
    deleteUser,
    setTokens,
    forgotPassword,
    confirmPassword,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => useContext(AuthContext);
