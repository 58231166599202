export const WhatsappIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 30C23.732 30 30 23.732 30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 18.9986 2.9427 21.7769 4.54789 24.0549L2.4924 28.8511C2.3146 29.2659 2.73407 29.6854 3.14893 29.5076L7.94507 27.4521C10.2231 29.0573 13.0014 30 16 30Z"
      fill="url(#paint0_linear_933_1219)"
    />
    <path
      d="M9.17148 13.816C8.92731 11.5538 10.0178 10.1621 11.169 9.68452C11.2477 9.6519 11.3334 9.64224 11.418 9.65161L12.0425 9.72081C12.2489 9.74368 12.4247 9.88121 12.4965 10.0761L13.5418 12.9106C13.6099 13.0952 13.5738 13.3021 13.4473 13.4528L12.6039 14.4575C12.4676 14.6198 12.4357 14.8466 12.5323 15.0352C13.1383 17.0747 15.4136 19.2511 17.504 20.007C17.6926 20.1036 17.9195 20.0716 18.0817 19.9354L19.0864 19.0919C19.2371 18.9654 19.4441 18.9294 19.6287 18.9975L22.4632 20.0427C22.658 20.1146 22.7956 20.2904 22.8184 20.4968L22.8876 21.1213C22.897 21.2059 22.8873 21.2916 22.8547 21.3702C22.3772 22.5214 20.9348 23.9009 18.7233 23.3678C13.5259 22.1148 9.64876 18.2377 9.17148 13.816Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_933_1219"
        x1="22"
        y1="4"
        x2="10"
        y2="26.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#56D062" />
        <stop offset="1" stopColor="#24B33B" />
      </linearGradient>
    </defs>
  </svg>
);

export default WhatsappIcon;