import { gql } from '@apollo/client';

export const GET_INCOME_REPORT = gql`
  query GetIncomeReport($reportId: ID!) {
    incomeReport(id: $reportId) {
      id
      token
      beneficiary
      title
      description
      currency
      amount
      createdAt
    }
  }
`;
