import { useContext, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { getWalletIndex } from '@hedgehog/browser/shared/account-abstraction';
import {
  useAppData,
  useClientsQuery,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import { ModalWrapper } from '@hedgehog/ui/modals';
import { StandardProps } from '@hedgehog/ui/utils';

import context from './context';
import { useMarketBidBalanceFactory } from './use-market-bid-balance.hook';
import { useMarketBidParamsFactory } from './use-market-bid-params.hook';

export const useNavigateTo = () => {
  const { navigateTo } = useContext(context);
  return navigateTo;
};

export type MarketBidsModalProps = StandardProps<{
  origin: 'explore' | 'market' | 'client';
  rootPath: string;
}>;

export const MarketBidsModal = ({
  origin,
  rootPath,
}: MarketBidsModalProps): JSX.Element => {
  const { activePartnerId } = useAppData();
  const [params, paramsControls] = useMarketBidParamsFactory({
    origin,
    rootPath,
  });
  const [balance, balanceControls] = useMarketBidBalanceFactory();

  const { clients = [], isLoading: clientsLoading } = useClientsQuery(
    {
      partnerId: activePartnerId || '',
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        clients: data?.data,
        paging: data?.paging,
        ...rest,
      }),
      skip: !activePartnerId,
    },
  );

  const { funds = [], isLoading: fundsLoading } = useFundsV2Query(
    {
      partnerId: activePartnerId || '',
    },
    {
      selectFromResult: ({ data, ...rest }) => ({
        funds: data,
        ...rest,
      }),
      skip: !activePartnerId,
    },
  );

  useEffect(() => {
    if (funds.length === 0) return;
    const { address = '' } =
      funds.find((fund: FundV2) => fund.fundId === params.fundId) ?? {};

    console.debug(
      'you are now interacting with following fund address',
      address,
    );
    paramsControls.updateParamsWithFundAddress({ address });
  }, [params.fundId, funds]);

  useEffect(() => {
    if (clients.length === 0) return;
    const client = clients.find(({ id }) => id === params.clientId);

    if (!client) return;
    const walletIndex = BigInt(
      getWalletIndex({ id: client.id, createdAt: client.createdAt }),
    );
    const { address = '' } =
      client.wallets.find(
        ({ type }) => type === 'PARTNER_MANAGED_CONTRACT_ACCOUNT',
      ) || {};

    if (params.clientWalletAddress !== address) {
      paramsControls.updateParamsWithClientWallet({
        address,
        index: walletIndex,
      });
    }
  }, [params.clientId, clients]);

  return (
    <context.Provider
      value={{
        balance,
        params,
        clientsLoading,
        fundsLoading,
        ...balanceControls,
        ...paramsControls,
      }}
    >
      <ModalWrapper backdropTo="../..">
        <Outlet />
      </ModalWrapper>
    </context.Provider>
  );
};
