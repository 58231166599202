import { gql } from '@apollo/client';

export const VOTE_ON_REGION = gql`
  mutation VoteOnRegion($regionId: String!, $votes: Int!) {
    castVotes(args: { votableId: $regionId, votes: $votes, type: REGION }) {
      ... on CastVotesOutput {
        success
        votesRemaining
        totalVotes
      }
      ... on CastVotesError {
        message
        code
      }
    }
  }
`;
