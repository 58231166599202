import { useMemo } from 'react';

import { useCacheManager } from './use-cache-manager.hook';

export function useCacheValidate(): (key: string) => void {
  const { clear } = useCacheManager();

  const handleCacheValidate = (key: string): void => {
    console.debug(`[cache]: validating cache for "${key}"`);
    clear(key);
  };

  return useMemo(() => handleCacheValidate, []);
}
