import { useContext, useMemo, useState } from 'react';

import context, {
  MarketListingBalance,
  MarketListingContextType,
} from './context';

export const useMarketListingBalanceFactory = (): [
  MarketListingBalance,
  Pick<MarketListingContextType, 'updateBalance'>,
] => {
  const [balance, setBalance] = useState<MarketListingBalance>({
    commitment: 0,
    contribution: 0,
  });

  const updateBalance = (patch: Partial<MarketListingBalance>): void => {
    const newBalance = { ...balance, ...patch };
    setBalance(newBalance);
  };

  return [balance, { updateBalance }];
};

export const useMarketListingBalance = (): [
  MarketListingBalance,
  (balance: Partial<MarketListingBalance>) => void,
] => {
  const { balance, updateBalance } = useContext(context);
  return useMemo(() => [balance, updateBalance], [balance]);
};
