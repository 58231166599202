import styled from 'styled-components';

import { LayeredIcons } from '@hedgehog/ui/icons';

/**
 * Elements that are defined here are used
 * across various Asset Cards and that also
 * make them targetable for styled-components.
 */

export const CardCaption = styled.div`
  display: flex;
  flex-flow: column nowrap;
  &:only-child {
    flex-grow: 1;
  }
`;

export const CardAction = styled.div`
  &:only-child {
    flex-grow: 1;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
`;

export const CardIcons = styled(LayeredIcons)``;
