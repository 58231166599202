import styled, { CSSObject } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

import { HedgehogIcon } from '../svgs';

type BrandLogoProps = StandardProps<object, never>;

export const BrandLogo = styled(
  ({ className }: BrandLogoProps): JSX.Element => (
    <HedgehogIcon className={className} />
  ),
)`
  width: auto;
  height: auto;
  min-width: 3em;
  max-width: 3em;
  min-height: 1.5em;
  max-height: 1.5em;
  ${({ theme }): CSSObject => ({ color: theme.colors.hedgehog })}
`;
