import { ReactNode } from 'react';
import styled, { css, CSSObject, CSSProp } from 'styled-components';

import { parsePadding, shimmerGradient } from '@hedgehog/ui/themes';
import { Paragraph, Title } from '@hedgehog/ui/typography';

import { TabAppearance } from '../Tabs/Tabs';

const Transitions = css`
  transition-property: background, color, border;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;
`;

const TabBase = styled.div<{
  loading?: boolean;
  active: boolean;
  small?: boolean;
}>`
  white-space: nowrap;
  text-align: center;
  ${({ loading }) => (loading ? shimmerGradient : '')}
`;

const TabText = styled(Paragraph)`
  font-size: inherit;
  line-height: 1em;
`;

const StyledNormalTab = styled(TabBase)`
  ${Transitions};
  font-weight: unset;
  cursor: pointer;
  box-sizing: border-box;
  border: 0.25rem solid transparent;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: capitalize;

  ${({ theme, active }): CSSObject => ({
    background: active
      ? theme.tabs.normal.active.backgroundColor
      : theme.tabs.normal.backgroundColor,
    color: active
      ? theme.tabs.normal.active.typography?.color
      : theme.tabs.normal.typography.color,
    fontSize: theme.tabs.normal.typography.fontSize,
    fontWeight: active
      ? theme.tabs.normal.active.typography?.fontWeight
      : theme.tabs.normal.typography.fontWeight,
    borderRadius: theme.tabs.normal.border.radius,
    padding: parsePadding(theme.tabs.normal.padding),
  })};

  ${({ theme, small }) =>
    small &&
    theme.tabs.normal.small.padding && {
      padding: parsePadding(theme.tabs.normal.small.padding),
    }};

  &:hover {
    ${({ theme, active }) =>
      !active && {
        borderColor: theme.tabs.normal.hover.border?.color,
      }};
  }
`;

const StyledCompactTab = styled(TabBase)<TabProps>`
  ${Transitions};

  ${({ active, theme }): CSSProp => ({
    backgroundColor: active ? theme.colors.white : 'none',
    color: active ? theme.colors.black : theme.colors.grey300,
  })};
  cursor: pointer;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  border-radius: 0.375rem;
  box-shadow: ${({ active }) =>
    active ? '0 2px 8px rgba(0, 0, 20, 0.1)' : 'none'};
  flex: 1;
`;

const IconWrapper = styled.div`
  width: 1rem;
  height: 1rem;
  color: currentColor;
`;

type TabProps = {
  appearance?: TabAppearance;
  small?: boolean;
  active?: boolean;
  icon?: ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  children: ReactNode;
  loading?: boolean;
  className?: string;
};

export const Tab = ({
  active = false,
  appearance = 'normal',
  small,
  icon,
  disabled,
  onClick,
  loading,
  children,
  className,
}: TabProps): JSX.Element => {
  const handleClick = (): void => {
    if (!disabled && onClick) {
      onClick();
    }
  };

  if (appearance === 'compact') {
    return (
      <StyledCompactTab
        small={small}
        active={active}
        appearance={appearance}
        onClick={handleClick}
        className={className}
      >
        <Title>{children}</Title>
      </StyledCompactTab>
    );
  }

  return (
    <StyledNormalTab
      small={small}
      className={className}
      active={active}
      onClick={handleClick}
      loading={loading}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      <TabText>{children}</TabText>
    </StyledNormalTab>
  );
};
