import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Paragraph } from '@hedgehog/ui/typography';
import { DashboardWidget } from '@hedgehog/ui/widgets';

const DashboardWidgetTile = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 14.5rem;

  ${({ theme }) => ({
    [`@media only screen and (min-width: ${theme.breakpoints.desktop}px)`]: {
      minWidth: 'unset',
    },
  })}
}`;

const DashboardWidgetBottomLink = styled(Link)`
  ${({ theme }) => ({
    color: theme.colors.black,
    marginTop: theme.spacing.normal,
    '&:hover': {
      textDecoration: 'underline',
    },
  })}
`;

type ClientOnboardingMetricProps = {
  title: string;
  loading: boolean;
  metric: number;
  clientsRoute: string;
  clientsDropOffRoute?: string;
  progress?: number;
  showProgress?: boolean;
};

export const ClientOnboardingMetric = ({
  title,
  loading,
  metric,
  clientsRoute,
  clientsDropOffRoute,
  progress,
  showProgress,
}: ClientOnboardingMetricProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <DashboardWidgetTile>
      <DashboardWidget
        loading={loading}
        title={title}
        metric={metric}
        progress={progress}
        showProgress={showProgress}
        onClick={() => navigate(clientsRoute)}
      />
      {(clientsDropOffRoute && progress !== undefined && progress < 1 && (
        <DashboardWidgetBottomLink to={clientsDropOffRoute}>
          <Paragraph>View drop-off</Paragraph>
        </DashboardWidgetBottomLink>
      )) ||
        ''}
    </DashboardWidgetTile>
  );
};
