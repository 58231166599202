import { ReactNode } from 'react';

import { OrderStatus } from '@hedgehog/data-access/partners-types';
import { ClockIcon } from '@hedgehog/ui/icons';
import { currencies } from '@hedgehog/utils/formats';

export interface StatusOptions {
  status?: OrderStatus;
  transferConfirmed?: boolean;
  amount?: number;
  quantity?: number;
}

export const getFooterText = ({
  amount = 0,
  quantity = 0,
}: StatusOptions): string => {
  return `${currencies.formatMoney(amount, {
    currency: 'USD',
  })} • ${quantity} token${quantity > 1 ? 's' : ''}`;
};

export const getStatusText = ({
  status,
  transferConfirmed = false,
  quantity = 1,
}: StatusOptions): string => {
  switch (status) {
    case 'OPEN':
      return 'Pending';
    case 'AGREED':
      return transferConfirmed ? 'Pending' : 'Waiting for Transfer';
    case 'FULFILLED':
    // Fallthrough
    case 'SYNCHRONISED':
      return `Issuing token${quantity > 1 ? 's' : ''}`;
    case 'ISSUED':
      return 'Ongoing';
    case 'REFUNDED':
      return 'Refunded';
    case 'COMPLETED':
      return 'Paid out';
    default:
      return '';
  }
};

export const getStatusEmojiOrIcon = ({
  status,
}: StatusOptions): { statusEmoji?: string } | { statusIcon?: ReactNode } => {
  switch (status) {
    case 'OPEN':
    case 'AGREED':
      return { statusIcon: <ClockIcon /> };

    case 'FULFILLED':
    case 'ISSUED':
    case 'SYNCHRONISED':
      return { statusEmoji: ':sparkles:' };

    case 'REFUNDED':
      return { statusEmoji: ':thumbsup:' };
    case 'COMPLETED':
      return { statusEmoji: ':tada:' };
    default:
      return {};
  }
};
