import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { PrimaryLinkButton } from '@hedgehog/ui/buttons';
import { BreadcrumbsLayout, Column } from '@hedgehog/ui/layouts';

import { FundList } from '../../../containers';

const FundsListColumn = styled(Column)`
  flex-basis: 24rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ContentColumn = styled(Column)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const FundsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 0;
`;

export const FundsFundsPage = () => {
  return (
    <PageColumnLayout
      title="Funds"
      actions={
        <PrimaryLinkButton to="/funds/funds/create">New fund</PrimaryLinkButton>
      }
    >
      <FundsListColumn size="minmax(min-content, 30rem)">
        <BreadcrumbsLayout>
          <FundsListContainer>
            <FundList />
          </FundsListContainer>
        </BreadcrumbsLayout>
      </FundsListColumn>
      <ContentColumn flexible desktopOnly>
        <Outlet />
      </ContentColumn>
    </PageColumnLayout>
  );
};
