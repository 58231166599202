export type Currency = 'EUR' | 'USD' | 'GBP' | 'CHF';

export interface CurrencyOptions {
  currency?: Currency | null;
  locale?: string;
  signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

const defaultCurrencyOptions: Required<CurrencyOptions> = {
  locale: 'en-GB',
  currency: 'USD',
  signDisplay: 'auto',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const symbols: Record<Currency, string> = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  CHF: '₣',
};

export function formatMoney(
  value: number,
  options: CurrencyOptions = {},
): string {
  const {
    locale,
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  } = {
    ...defaultCurrencyOptions,
    ...options,
  };
  return `${currency ? symbols[currency] : ''}${new Intl.NumberFormat(locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    signDisplay,
  }).format(value)}`;
}
