import { gql } from '@apollo/client';

export const GET_TAX_FORM = gql`
  query GetTaxForm {
    taxFormProgress {
      status
      url
    }
  }
`;
