import styled from 'styled-components';

import { ContentGalleryBlock } from '@hedgehog/shared/types';
import { Gallery } from '@hedgehog/ui/galleries';

const GalleryWrapper = styled.div`
  height: 11.25rem;
`;

type CMSGalleryBlockProps = {
  block: ContentGalleryBlock;
};

export const CMSGalleryBlock = ({
  block,
}: CMSGalleryBlockProps): JSX.Element | null => {
  if (!block.urls.length) {
    return null;
  }
  return (
    <GalleryWrapper>
      <Gallery resources={block.urls} />
    </GalleryWrapper>
  );
};
