export const CheckMarkIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.8287 8.82842L12.0003 27.6569L1.17188 16.8284L6.82873 11.1716L12.0003 16.3431L25.1719 3.17157L30.8287 8.82842Z"
      fill="currentColor"
    />
  </svg>
);

export default CheckMarkIcon;
