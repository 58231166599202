import {
  GetPartnerFundsResponse,
  GetPartnerFundsRequest,
  GetPartnerFundRequest,
  GetPartnerFundResponse,
  CreatePartnerFundResponse,
  CreatePartnerFundRequest,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import { baseApi } from './base.api';

/** @deprecated Our new funds endpoints will supersede this API */
export const fundsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    funds: builder.query<
      GetPartnerFundsResponse['data'],
      GetPartnerFundsRequest
    >({
      query: ({ partnerId }) => ({
        url: `partners/${partnerId}/funds`,
      }),
      transformResponse: (response: GetPartnerFundsResponse) => response.data,
    }),
    fund: builder.query<GetPartnerFundResponse['data'], GetPartnerFundRequest>({
      query: ({ fundId, partnerId }) => ({
        url: `partners/${partnerId}/funds/${fundId}`,
      }),
      transformResponse: (response: GetPartnerFundResponse) => response.data,
    }),
    createFund: builder.mutation<
      CreatePartnerFundResponse['data'],
      CreatePartnerFundRequest & { partnerId: UUID }
    >({
      query: ({ partnerId, ...body }) => ({
        url: `partners/${partnerId}/funds`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreatePartnerFundResponse) => response.data,
    }),
    deleteFund: builder.mutation<void, { partnerId: UUID; fundId: UUID }>({
      query: ({ partnerId, fundId }) => ({
        url: `partners/${partnerId}/funds/${fundId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useFundsQuery,
  useFundQuery,
  useCreateFundMutation,
  useDeleteFundMutation,
  useLazyFundsQuery,
  useLazyFundQuery,
} = fundsApi;
