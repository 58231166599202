import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  MouseEventHandler,
  ReactNode,
} from 'react';
import styled, { CSSObject, RuleSet } from 'styled-components';

import { shimmerGradient, type ThemeColorName } from '@hedgehog/ui/themes';
import {
  visibleAtStyle,
  ResponsiveMode,
  StandardProps,
} from '@hedgehog/ui/utils';

const CustomParagraph = styled.p<ParagraphProps>`
  ${({ color, weight, align, small, theme }): CSSObject => ({
    ...theme.typography[small ? 'body_small' : 'body'],
    textAlign: align,
    fontWeight: weight ? weight : undefined,
    color: color ? theme.colors[color] : 'inherit',
  })}

  margin: 0;

  ${({ loading }): RuleSet<object> | '' => (loading ? shimmerGradient : '')}
  ${visibleAtStyle}
`;

export type ParagraphProps = StandardProps<
  {
    color?: ThemeColorName | 'inherit';
    small?: boolean;
    inline?: boolean;
    align?: 'left' | 'center' | 'right';
    weight?: string | number;
    loading?: boolean;
    visibleAt?: ResponsiveMode;
  } & HTMLAttributes<HTMLParagraphElement>
>;

export const Paragraph = styled(
  forwardRef(
    (
      { color, children, ...props }: ParagraphProps,
      ref: ForwardedRef<HTMLParagraphElement>,
    ): JSX.Element => {
      return (
        <CustomParagraph ref={ref} color={color} {...props}>
          {children}
        </CustomParagraph>
      );
    },
  ),
)``;
