import { gql } from '@apollo/client';

import { ContentBlockDataFragment } from '../content';

export const GET_ARTICLE = gql`
  query GetArticle($slug: String!) {
    article(args: { slug: $slug }) {
      title
      author
      imageUrl
      slug
      category
      tags {
        tag
      }
      content {
        ...ContentBlockDataFragment
      }
    }
  }
  ${ContentBlockDataFragment}
`;
