import { MouseEventHandler } from 'react';
import { To } from 'react-router-dom';
import styled from 'styled-components';

import { HeaderButton } from './header-button.component';
import { Header, HeaderProps } from './header.component';

export type HeaderWithCloseProps = {
  to?: To | number;
  onClose?: MouseEventHandler;
} & Omit<HeaderProps, 'right'>;

export const HeaderWithClose = styled(
  ({ to, onClose, ...headerProps }: HeaderWithCloseProps): JSX.Element => {
    return (
      <Header
        right={<HeaderButton to={to} icon="cross" onClick={onClose} />}
        {...headerProps}
      />
    );
  },
)``;
