import { NREResidentToken } from '@hedgehoginvest/nre-resident-token';
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useSearchParams,
} from 'react-router-dom';

import { ContractProvider } from '@hedgehog/browser/investors/account-abstraction/data-access';
import { ClientSecondaryMarketPage } from '@hedgehog/browser/partners/clients/feature-secondary-market';
import { FundsLayout } from '@hedgehog/browser/partners/funds/shell';
import { ClientPortfolioPage } from '@hedgehog/browser/partners/portfolio/feature-portfolio';
import {
  BreadcrumbsLayout,
  NavigationLayout,
} from '@hedgehog/browser/partners/shared/layouts';
import {
  TeamMemberDetails,
  TeamMemberNavigateTo,
} from '@hedgehog/browser/partners/team';
import {
  AccountAbstractionProvider,
  ZeroDevWalletProvider,
} from '@hedgehog/browser/shared/account-abstraction';
import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import {
  FundProvider,
  FundsProvider,
  useAppData,
  useUpdateWalletAddressMutation,
} from '@hedgehog/data-access/partners';
import { LinkButton, PrimaryLinkButton } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { Paragraph } from '@hedgehog/ui/typography';
import { Widget } from '@hedgehog/ui/widgets';

import {
  AssetPageNavigateTo,
  AuthenticatedGuard,
  MarketBidsModal,
  UnauthenticatedGuard,
} from '../containers';
import { MarketListingModal } from '../containers/market-listing-modal/market-listing-modal.container';
import {
  PartnerAcceptTeamInvitationPage,
  DashboardPage,
  SignInPage,
  SocialSigninSuccessPage,
  ForgotPasswordPage,
  ConfirmPasswordPage,
  ClientsPage,
  ClientPage,
  ClientProfilePage,
  AssetsPage,
  AssetPage,
  AssetOverviewTabPage,
  AssetDynamicTabPage,
  AssetComingSoonTabPage,
  DashboardMetricsPage,
  CreateFundPage,
  ClientKycPage,
  FundClientsPage,
  FundClientPage,
  FundClientProfilePage,
  FundOverview,
  FundTeamMembersPage,
  ReportingPage,
  LogoutPage,
  TeamPage,
  MarketFundsEmptyPage,
  MarketFundsPage,
  MarketPage,
  MarketFundsListingPage,
  MarketListingTermsConditionsCreatePage,
  MarketListingPositionConfirmPage,
  MarketListingPositionCreatePage,
  MarketListingPositionRatioPage,
  MarketListingCreatedPage,
  MarketBidCreateSelectClientPage,
  MarketBidCreateSetPricePage,
  MarketBidCreateTermsConditionsPage,
  MarketListingSelectClientPage,
  MarketBidCreatedPage,
  MarketBidOverviewPage,
  MarketBidAcceptTermsConditionsPage,
  MarketBidAcceptConfirmedPage,
  MarketBidRejectConfirmPage,
} from '../pages';
import { EnterpriseLayout } from '../pages/auth/enterprise-layout/enterprise.layout';
import { ClientTimelinePage } from '../pages/clients/client-timeline.page';
import { CapitalCallPage } from '../pages/funds/capital-calls';
import { CapitalCallsPage } from '../pages/funds/capital-calls/capital-calls.page';
import { CreateCapitalCallsPage } from '../pages/funds/capital-calls/create-capital-call.page';
import { FundsDashboardPage } from '../pages/funds/dashboard/funds-dashboard.page';
import { FundPage } from '../pages/funds/funds/fund.page';
import { FundsFundsPage } from '../pages/funds/funds/funds-funds.page';

export const Router = (): JSX.Element => {
  const environment = useEnvironment();
  const [updateWalletAddress] = useUpdateWalletAddressMutation();
  const [searchParams] = useSearchParams();

  const { featureFlags } = useAppData();

  const isPartnerManageTeamMembersEnabled = featureFlags.find(
    (flag) => flag.name === FeatureFlagNames.partners_manage_team_members,
  );

  const handleAddressChange = async (address?: string): Promise<void> => {
    if (!address) return;
    console.log('my address is:', address);
    // TODO: Update the user in the database using REST
    await updateWalletAddress({ address });
  };

  return (
    <Routes>
      <Route
        path="auth"
        element={
          <UnauthenticatedGuard>
            <Outlet />
          </UnauthenticatedGuard>
        }
      >
        <Route
          element={
            <EnterpriseLayout
              visibleAt={{ jumbotron: 'desktop' }}
              links={<LinkButton>Need help?</LinkButton>}
            />
          }
        >
          <Route path="sign-in" element={<SignInPage />} />
          <Route path="forgot-password">
            <Route index element={<ForgotPasswordPage />} />
            <Route path="confirm" element={<ConfirmPasswordPage />} />
          </Route>
        </Route>

        <Route
          element={
            <EnterpriseLayout
              requirePartner
              visibleAt={{ jumbotron: 'desktop' }}
              links={
                <>
                  <Paragraph color="grey400" visibleAt="desktop">
                    Already have an account?
                  </Paragraph>
                  <PrimaryLinkButton to="/auth/sign-in">
                    Sign in
                  </PrimaryLinkButton>
                </>
              }
            />
          }
        >
          <Route
            path="accept-invitation"
            element={<PartnerAcceptTeamInvitationPage />}
          />
        </Route>

        {/* Cognito Callback URL - Social Methods use it */}
        <Route path="sign-in/callback" element={<SocialSigninSuccessPage />} />
        <Route
          path="sign-up/callback"
          element={<Navigate to="/auth/sign-in" />}
        />

        <Route index element={<Navigate to="/auth/sign-in" />} />
        <Route path="*" element={<Navigate to="/auth/sign-in" />} />
      </Route>

      {false && (
        <Route
          path="funds"
          element={
            <AuthenticatedGuard>
              <AccountAbstractionProvider onAddressChange={handleAddressChange}>
                <FundsProvider>
                  <FundsLayout />
                </FundsProvider>
              </AccountAbstractionProvider>
            </AuthenticatedGuard>
          }
        >
          <Route path="dashboard" element={<FundsDashboardPage />} />
          <Route path="clients" element={<FundClientsPage />}>
            <Route path=":clientId" element={<FundClientPage />}>
              <Route path="profile" element={<FundClientProfilePage />} />
              <Route index element={<Navigate replace to="profile" />} />
              <Route path="*" element={<Navigate replace to="profile" />} />
            </Route>
            <Route index element={<BreadcrumbsLayout />} />
            <Route path="*" element={<Navigate to="clients" />} />
          </Route>

          <Route
            path="funds"
            element={
              <FundsProvider>
                <FundsFundsPage />
              </FundsProvider>
            }
          >
            <Route path="create" element={<CreateFundPage />} />
            <Route
              path=":fundId"
              element={
                <FundProvider>
                  <FundPage />
                </FundProvider>
              }
            >
              <Route path="overview" element={<FundOverview />} />
              <Route path="team-members" element={<FundTeamMembersPage />} />
            </Route>
          </Route>

          <Route path="capital-calls">
            <Route index element={<CapitalCallsPage />} />
            <Route path="create" element={<CreateCapitalCallsPage />} />
            <Route path=":capitalCallId" element={<CapitalCallPage />} />
          </Route>
          <Route index element={<Navigate replace to="/funds/dashboard" />} />
        </Route>
      )}
      <Route
        element={
          <AuthenticatedGuard>
            <AccountAbstractionProvider onAddressChange={handleAddressChange}>
              <ContractProvider
                abi={NREResidentToken.abi}
                address={environment.tenantToken.address}
              >
                <NavigationLayout />
              </ContractProvider>
            </AccountAbstractionProvider>
          </AuthenticatedGuard>
        }
      >
        <Route index element={<Navigate replace to="/dashboard" />} />

        {/* Dashboard Section */}
        <Route element={<DashboardPage />} path="dashboard">
          <Route index element={<DashboardMetricsPage />} />
        </Route>
        {/* End of Dashboard Section */}

        {/* Clients Section */}
        <Route path="clients" element={<ClientsPage />}>
          <Route index element={<BreadcrumbsLayout />} />
          <Route
            path=":clientId"
            element={
              // Separate wallet for each client
              <ZeroDevWalletProvider>
                <ClientPage />
              </ZeroDevWalletProvider>
            }
          >
            <Route
              index
              element={
                <Navigate replace to={`profile?${searchParams.toString()}`} />
              }
            />
            <Route path="profile" element={<ClientProfilePage />} />
            <Route path="timeline" element={<ClientTimelinePage />} />
            <Route path="portfolio" element={<ClientPortfolioPage />}>
              <Route index element={<></>} />
              <Route
                path="listings"
                element={
                  <MarketListingModal rootPath="/clients/:clientId/portfolio/listings" />
                }
              >
                <Route index element={<Navigate to=".." replace />} />
                <Route path="create">
                  <Route index element={<Navigate to="position" />} />
                  <Route path="position">
                    <Route
                      index
                      element={
                        <MarketListingPositionCreatePage isFirstPage={true} />
                      }
                    />
                    <Route
                      path="ratio"
                      element={<MarketListingPositionRatioPage />}
                    />
                    <Route
                      path="confirm"
                      element={<MarketListingPositionConfirmPage />}
                    />
                  </Route>
                  <Route
                    path="terms-conditions"
                    element={<MarketListingTermsConditionsCreatePage />}
                  />
                </Route>
                <Route path="created" element={<MarketListingCreatedPage />} />
                <Route path="*" element={<Navigate to="" replace />} />
              </Route>
            </Route>
            <Route path="kyc" element={<ClientKycPage />} />
            <Route
              path="secondary-market"
              element={<ClientSecondaryMarketPage />}
            >
              <Route index element={<></>} />
              <Route path="funds/:fundId/listings/:listingId/bids">
                <Route index element={<Navigate to=".." replace />} />
                <Route
                  path=":bidId"
                  element={
                    <MarketBidsModal
                      origin="client"
                      rootPath="/clients/:clientId/secondary-market/funds/:fundId/listings/:listingId/bids/:bidId"
                    />
                  }
                >
                  <Route index element={<Navigate to=".." replace />} />
                  <Route path="overview" element={<MarketBidOverviewPage />} />
                  <Route path="accept">
                    <Route index element={<Navigate to=".." replace />} />
                    <Route
                      path="terms-conditions"
                      element={<MarketBidAcceptTermsConditionsPage />}
                    />
                    <Route
                      path="confirmed"
                      element={<MarketBidAcceptConfirmedPage />}
                    />
                    <Route path="*" element={<Navigate to="../.." replace />} />
                  </Route>
                  <Route path="reject">
                    <Route index element={<Navigate to="confirm" replace />} />
                    <Route
                      path="confirm"
                      element={<MarketBidRejectConfirmPage />}
                    />
                    <Route path="*" element={<Navigate to="../.." replace />} />
                  </Route>
                  <Route
                    path="*"
                    element={<Navigate to="overview" replace />}
                  />
                </Route>
                <Route path="*" element={<Navigate to="" replace />} />
              </Route>
              <Route path="*" element={<Navigate to="" replace />} />
            </Route>
            <Route
              path="*"
              element={
                <Navigate replace to={`profile?${searchParams.toString()}`} />
              }
            />
          </Route>

          <Route path="*" element={<Navigate to="/clients" />} />
        </Route>
        {/* End of Clients Section */}

        {/* Assets section */}
        <Route path="assets" element={<AssetsPage />}>
          <Route element={<AssetPageNavigateTo />}>
            <Route index element={<BreadcrumbsLayout />} />
            <Route path=":assetId" element={<AssetPage />}>
              <Route path="overview" element={<AssetOverviewTabPage />} />
              <Route path="coming-soon" element={<AssetComingSoonTabPage />} />
              <Route path=":slug" element={<AssetDynamicTabPage />} />
              <Route path="*" element={<Navigate replace to="" />} />
            </Route>
            <Route path="*" element={<Navigate replace to="/assets" />} />
          </Route>
        </Route>
        {/* End of assets section */}

        {/* Team Section */}
        {isPartnerManageTeamMembersEnabled && (
          <Route path="team" element={<TeamPage />}>
            <Route element={<TeamMemberNavigateTo />}>
              <Route index element={<Widget />} />
              <Route path="*" element={<Navigate replace to="/team" />} />
              <Route path=":teamMemberId" element={<TeamMemberDetails />} />
            </Route>
          </Route>
        )}
        {/* End of Team Section */}

        {/* Funds Section */}
        <Route
          path="funds"
          element={
            <AuthenticatedGuard>
              <AccountAbstractionProvider onAddressChange={handleAddressChange}>
                <FundsProvider>
                  <FundsLayout />
                </FundsProvider>
              </AccountAbstractionProvider>
            </AuthenticatedGuard>
          }
        >
          <Route path="dashboard" element={<FundsDashboardPage />} />
          <Route path="clients" element={<FundClientsPage />}>
            <Route path=":clientId" element={<FundClientPage />}>
              <Route path="profile" element={<FundClientProfilePage />} />
              <Route index element={<Navigate replace to="profile" />} />
              <Route path="*" element={<Navigate replace to="profile" />} />
            </Route>
            <Route index element={<BreadcrumbsLayout />} />
            <Route path="*" element={<Navigate replace to="clients" />} />
          </Route>

          <Route
            path="funds"
            element={
              <FundsProvider>
                <FundsFundsPage />
              </FundsProvider>
            }
          >
            <Route path="create" element={<CreateFundPage />} />
            <Route
              path=":fundId"
              element={
                <FundProvider>
                  <FundPage />
                </FundProvider>
              }
            >
              <Route path="overview" element={<FundOverview />} />
              <Route path="team-members" element={<FundTeamMembersPage />} />
            </Route>
          </Route>

          <Route path="capital-calls">
            <Route index element={<CapitalCallsPage />} />
            <Route path="create" element={<CreateCapitalCallsPage />} />
            <Route path=":capitalCallId" element={<CapitalCallPage />} />
          </Route>
          <Route index element={<Navigate replace to="/funds/dashboard" />} />
        </Route>
        {/* End of Funds Section */}

        {/* Market Section */}
        <Route
          path="market"
          element={
            <ZeroDevWalletProvider>
              <MarketPage />
            </ZeroDevWalletProvider>
          }
        >
          <Route index element={<MarketFundsEmptyPage />} />
          <Route path=":fundId" element={<MarketFundsPage />}>
            <Route element={<MarketFundsListingPage />}>
              <Route index element={<></>} />
              <Route path="listings">
                <Route
                  element={
                    <MarketListingModal rootPath="/market/:fundId/listings" />
                  }
                >
                  <Route index element={<Navigate to=".." replace />} />
                  <Route path="create">
                    <Route index element={<Navigate to="select-client" />} />
                    <Route
                      path="select-client"
                      element={<MarketListingSelectClientPage />}
                    />
                    <Route path="position">
                      <Route
                        index
                        element={<MarketListingPositionCreatePage />}
                      />
                      <Route
                        path="ratio"
                        element={<MarketListingPositionRatioPage />}
                      />
                      <Route
                        path="confirm"
                        element={<MarketListingPositionConfirmPage />}
                      />
                      <Route path="*" element={<Navigate to="" replace />} />
                    </Route>
                    <Route
                      path="terms-conditions"
                      element={<MarketListingTermsConditionsCreatePage />}
                    />
                  </Route>
                  <Route
                    path="created"
                    element={<MarketListingCreatedPage />}
                  />
                </Route>
                <Route path=":listingId">
                  <Route index element={<Navigate to="../.." replace />} />
                  <Route
                    path="bids"
                    element={
                      <MarketBidsModal
                        origin="market"
                        rootPath="/market/:fundId/listings/:listingId/bids"
                      />
                    }
                  >
                    <Route index element={<Navigate to="../../.." replace />} />
                    <Route path="create">
                      <Route index element={<Navigate to="select-client" />} />
                      <Route
                        path="select-client"
                        element={<MarketBidCreateSelectClientPage />}
                      />
                      <Route
                        path="set-price"
                        element={<MarketBidCreateSetPricePage />}
                      />
                      <Route
                        path="terms-conditions"
                        element={<MarketBidCreateTermsConditionsPage />}
                      />
                      <Route
                        path="*"
                        element={<Navigate to="../.." replace />}
                      />
                    </Route>
                    <Route path="created" element={<MarketBidCreatedPage />} />
                    <Route
                      path="*"
                      element={<Navigate to="../../.." replace />}
                    />
                  </Route>
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="" />} />
            </Route>
            <Route path="*" element={<Navigate to="/funds" />} />
          </Route>

          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Route>
        {/* End of Market Section */}

        <Route path="reporting" element={<ReportingPage />} />

        <Route path="logout" element={<LogoutPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
