import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { screens, screenNames } from '@hedgehog/utils/sizes';

export interface WrapperProps {
  min?: screenNames;
  max?: screenNames;
  className?: string;
}

const Wrapper = styled.div<WrapperProps>`
  ${({ min, max }): CSSObject => {
    const breakpoints = [
      max && `(max-width: ${screens[max]}px)`,
      min && `(min-width: ${screens[min] + 1}px)`,
    ];

    const constraints = breakpoints.filter(Boolean).join(' and ');

    return {
      width: '100%',
      [`@media only screen and ${constraints}`]: {
        display: 'none !important',
        visibility: 'hidden',
      },
    };
  }}
`;

export interface HideBreakpointProps {
  to?: screenNames;
  from?: screenNames;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const HideBreakpoint = ({
  to,
  from,
  className,
  children,
}: HideBreakpointProps): JSX.Element => {
  return (
    <Wrapper min={from} max={to} className={className}>
      {children}
    </Wrapper>
  );
};
