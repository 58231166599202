export enum NativeMobileExperiences {
  DEVICE_IOS = 'device_ios',
  INVESTING = 'investing',
  KYC_VERIFICATION = 'kyc_verification',
  PAGE_HEADER_CLOSE = 'page_header_close',
  FOOT_BAR_COMPONENT = 'foot_bar_component',
  LINK_NOTIFY = 'link_notify',
  IOS_ANALYTICS = 'ios_analytics',
  ANDROID_ANALYTICS = 'android_analytics',
  LOGOUT = 'logout',
  NATIVE_USER_ACTIONS = 'native_user_actions',
}
