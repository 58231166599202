import { format as fnsFormat, isValid, parse, parseISO } from 'date-fns';
import { useEffect, useState } from 'react';

import Input from '../Input/Input';

export type DateInputFormat =
  | 'dd/MM/yyyy'
  | 'MM/dd/yyyy'
  | 'yyyy/MM/dd'
  | 'yyyy/dd/MM';

const EU_DATE_FORMAT: DateInputFormat = 'dd/MM/yyyy';
const US_DATE_FORMAT: DateInputFormat = 'MM/dd/yyyy';
const DATE_FORMAT_DAY_END_INDEX = 2;
const DATE_FORMAT_MONTH_END_INDEX = 5;

const BACKSPACE_KEY = 'Backspace';
const ALLOWED_KEYS = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];

type DateInputProps = {
  name: string;
  label: string;
  value?: string; // ISO timestamp
  format?: DateInputFormat;
  onValidDate?: (value: Date) => void;
  onChange?: (value: Date) => void;
  onClear?: () => void;
  extraValidation?: (value: Date) => boolean;
};

const formatValue = (value: string, format: string): string => {
  if (value === '') {
    return '';
  }
  return fnsFormat(parseISO(value), format);
};

/**
 * isUSLocale will return true if we can detect the browser is in a US locale.
 */
const isUSLocale = (): boolean => {
  if (process.env['NODE_ENV'] === 'test') {
    return false;
  }
  // https://caniuse.com/?search=Intl.DateTimeFormat
  const { locale } = Intl.DateTimeFormat().resolvedOptions();
  return locale === 'en-US';
};

export const DateInput = ({
  name,
  label,
  onValidDate,
  onChange,
  value,
  onClear,
  format = isUSLocale() ? US_DATE_FORMAT : EU_DATE_FORMAT,
  extraValidation,
}: DateInputProps): JSX.Element => {
  const [input, setInput] = useState(value ? formatValue(value, format) : '');
  const [error, setError] = useState(false);
  const [valid, setValid] = useState(false);

  /**
   * parseDateInput will return parse the input and return a date. Null is returned if the input is not a valid
   * date.
   */
  const parseDateInput = (): Date | null => {
    if (!input || input.length !== format.length) {
      // The user has not yet fully entered the date.
      return null;
    }
    const parsed = parse(input, format, new Date());
    if (!isValid(parsed)) {
      return null;
    }
    if (extraValidation && !extraValidation(parsed)) {
      return null;
    }
    return parsed;
  };

  useEffect(() => {
    if (input.length !== format.length) {
      /* Clear error and checked input marks */
      setError(false);
      setValid(false);
      return;
    }

    /* Set error or checked input marks */
    const date = parseDateInput();
    if (date) {
      setValid(true);
      if (onChange) {
        onChange(date);
      }
      if (onValidDate) {
        onValidDate(date);
      }
    } else {
      setError(true);
    }
  }, [input]);

  const adjustInput = (inputValue: string): string => {
    /* Autocomple forward slashes */
    if (
      inputValue.length === DATE_FORMAT_DAY_END_INDEX ||
      inputValue.length === DATE_FORMAT_MONTH_END_INDEX
    )
      return inputValue.concat('/');

    /* Allow only the first characters */
    return inputValue.slice(0, format.length);
  };

  const removeChunk = (buf: string): string => {
    if (buf.length === 0) return '';

    /* if string end with a forward backslash, remove it */
    const adjustedBuf =
      buf[buf.length - 1] === '/' ? buf.slice(0, buf.length - 1) : buf;

    const lastSlashIndex = adjustedBuf.lastIndexOf('/');

    if (lastSlashIndex === -1) {
      /* If no forward backslashes, remove the whole input */
      return '';
    }

    return adjustedBuf.slice(0, lastSlashIndex + 1);
  };

  const onKeyDownHandler = (key: string): void => {
    if (key === BACKSPACE_KEY) {
      /* Remove DD, MM or YYYY when pressing backspace */
      setInput((currentInput) => removeChunk(currentInput));

      /* When deleting, call onClear callback */
      if (onClear) onClear();
    }

    if (ALLOWED_KEYS.includes(key)) {
      /* Only numbers allowed as input */
      /* Insertion allowed only at the end of the input string */
      setInput((currentInput) => adjustInput(currentInput.concat(key)));
    }
  };

  return (
    <Input
      autoCompleteOff
      type="text"
      name={name}
      label={label}
      placeholder={format?.toUpperCase()}
      inputMode="numeric"
      errors={error ? ['Incorrect date'] : []}
      checked={valid}
      hint={
        input !== '' && !error && input.length !== format.length
          ? format?.toUpperCase()
          : ''
      }
      value={input}
      onKeyDown={(event): void => {
        onKeyDownHandler(event.key);
      }}
      onChange={(): void => {
        /* Suppress warning: provided a `value` prop to a form field without an `onChange` handler */
      }}
      onBlur={(): void => {
        if (!parseDateInput()) setError(true);
      }}
      onFocus={(): void => {
        setError(false);
      }}
    />
  );
};

export default DateInput;
