import { useState } from 'react';

export interface QueryResult<T> {
  data: T | null;
  error: unknown | null;
  loading: boolean;
}

export interface QueryControls<T> {
  setData: (data: T | null) => void;
  setError: (error: unknown | null) => void;
  setLoading: (loading: boolean) => void;
}

export type QueryResultTuple<T> = [QueryResult<T>, QueryControls<T>];

export type LazyQueryResultTuple<T, V> = [
  (variables: V) => Promise<QueryResult<T>>,
  QueryResult<T>,
];

export const useQueryResult = <T,>(): QueryResultTuple<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown | null>(null);
  return [
    { data, loading, error },
    { setData, setLoading, setError },
  ];
};
