import Flicking from '@egjs/react-flicking';
import styled from 'styled-components';

import { Tenant } from '@hedgehog/shared/types';

import { AssetTenantListItem } from '../AssetTenantListItem/AssetTenantListItem';

type AssetTenantListProps = {
  tenants: Tenant[];
  align?: 'prev' | 'center' | 'next';
  className?: string;
};

const StyledFlicking = styled(Flicking)`
  margin-left: -1.5rem;
  margin-right: -1.5rem;

  & > *:first-child {
    padding-left: 1.5rem;
    gap: 0.5rem;
  }
`;

export const AssetTenantList = ({
  tenants,
  align = 'prev',
  className,
}: AssetTenantListProps): JSX.Element => {
  return (
    <StyledFlicking
      renderOnlyVisible
      horizontal
      align={align}
      className={className}
    >
      {tenants.map((tenant) => (
        // Note: We get an TypeError: The provided value is not of type 'Element'.
        // on tests without this div. Why?
        <div key={tenant.name}>
          <AssetTenantListItem tenant={tenant} />
        </div>
      ))}
    </StyledFlicking>
  );
};
