import { useState } from 'react';
import styled from 'styled-components';

import {
  isRequestErrorResponse,
  useAppData,
  useInviteTeamMemberMutation,
} from '@hedgehog/data-access/partners';
import { InviteTeamMemberRequest } from '@hedgehog/data-access/partners-types';
import { EmailInput, ToggleInput } from '@hedgehog/ui/inputs';
import { Form, FormSpacingToken, VSpace } from '@hedgehog/ui/layouts';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import {
  createEmailValidation,
  InvalidEmailReason,
} from '@hedgehog/utils/validation';

import { ModalForm } from '../../components';
import { FormSuccessModal } from '../../components/form-success-modal/form-success-modal.component';

const Capability = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export type FormErrors = {
  email: InvalidEmailReason[];
  confirmEmail: InvalidEmailReason[];
};

type FormState = InviteTeamMemberRequest & {
  confirmEmail: string;
};

export type InviteTeamMemberModalProps = {
  submitModal: () => void;
  cancelModal: () => void;
};

export const InviteTeamMemberModal = ({
  cancelModal,
}: InviteTeamMemberModalProps): JSX.Element => {
  const { activePartner } = useAppData();
  const [inviteTeamMember, { isLoading, isSuccess, error }] =
    useInviteTeamMemberMutation();
  const [formErrors, setFormErrors] = useState<FormErrors>({
    email: [],
    confirmEmail: [],
  });
  const [request, setRequest] = useState<FormState>({
    email: '',
    confirmEmail: '',
    isAdmin: true,
  });
  const validateEmail = createEmailValidation({});
  const validateConfirmEmail = createEmailValidation({
    subaddress: false,
    invalid_format: false,
    no_match: true,
  });
  const disabled = !(
    request.email &&
    request.confirmEmail &&
    formErrors.email.length === 0 &&
    formErrors.confirmEmail.length === 0
  );

  const createInputControl = <PropertyName extends keyof FormState>(
    propertyName: PropertyName,
  ) => ({
    name: propertyName,
    value: request[propertyName],
    onChange: (value?: FormState[PropertyName]) => {
      setRequest((prevState) => ({
        ...prevState,
        [propertyName]: value,
      }));
    },
  });

  const handleSubmit = async () => {
    if (!activePartner) return;
    inviteTeamMember({
      partnerId: activePartner.id,
      ...request,
    });
  };

  const handleEmailBlur = (): void => {
    if (request.email) {
      setFormErrors((prevState) => ({
        ...prevState,
        email: validateEmail(request.email),
      }));
    }
    if (request.confirmEmail) {
      setFormErrors((prevState) => ({
        ...prevState,
        confirmEmail: validateConfirmEmail(request.email, request.confirmEmail),
      }));
    }
  };

  if (isSuccess) {
    return (
      <FormSuccessModal
        title="Team member invited!"
        description={`An invitation has been sent to ${request.email}`}
        onClose={cancelModal}
      />
    );
  }

  return (
    <ModalForm
      submitModal={handleSubmit}
      closeModal={cancelModal}
      disabled={disabled}
      loading={isLoading}
      heading="Add Team Member"
      submitButtonText="Add Team member"
      closeButtonText="Close"
      error={
        error
          ? isRequestErrorResponse(error)
            ? error.data.message
            : 'Something went wrong'
          : undefined
      }
    >
      <Form onSubmit={handleSubmit}>
        <VSpace spacing="xs">
          <EmailInput
            label="Email Address"
            ariaLabel="email-input"
            errors={formErrors.email}
            onBlur={handleEmailBlur}
            placeholder="email@example.com"
            {...createInputControl('email')}
          />
          <EmailInput
            label="Confirm Email Address"
            ariaLabel="email-confirm-input"
            errors={formErrors.confirmEmail}
            onBlur={handleEmailBlur}
            placeholder="email@example.com"
            {...createInputControl('confirmEmail')}
          />
          <FormSpacingToken spacing="normal" />
          <Heading level="h5">Assign permissions</Heading>
          <Capability>
            <Paragraph>Can invite other team members</Paragraph>
            <ToggleInput
              value={request.isAdmin}
              onChange={(active) =>
                setRequest((prevState) => ({
                  ...prevState,
                  isAdmin: active,
                }))
              }
            />
          </Capability>
        </VSpace>
      </Form>
    </ModalForm>
  );
};
