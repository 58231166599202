import { Outlet } from 'react-router-dom';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';

export const DashboardPage = (): JSX.Element => {
  return (
    <PageColumnLayout title="Dashboard">
      <Outlet />
    </PageColumnLayout>
  );
};

export default DashboardPage;
