import { createContext, useContext } from 'react';

import { ThemeColorName } from '@hedgehog/ui/themes';

type QuestionId = string;
type AnswerId = string;

export type Comments<C extends object = any> = Record<string, C>;
export type Answers<A extends object = any> = Record<
  QuestionId,
  Record<AnswerId, A>
>;
export interface ContentContextType<
  C extends object = any,
  A extends object = any,
> {
  theme?: ThemeColorName;
  comments?: Record<string, C>;
  answers?: Record<
    QuestionId,
    Record<AnswerId, { value: string; valid: boolean }>
  >;
  onQuestionCommentClick?: (comment: C) => void;
  onQuestionAnswerClick?: (qId: QuestionId, aId: AnswerId, answer: A) => void;
}

export const ContentContext = createContext<ContentContextType>({
  theme: 'secondary',
  comments: undefined,
  answers: undefined,
  onQuestionCommentClick: () => void 0,
});

export function useContentParameters<
  T extends object = any,
>(): ContentContextType<T> {
  return useContext(ContentContext);
}
