export const FacebookIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 16C30 8.26801 23.732 2 16 2C8.26801 2 2 8.26801 2 16C2 22.9878 7.1196 28.7796 13.8125 29.8299V20.0469H10.2578V16H13.8125V12.9156C13.8125 9.40687 15.9026 7.46875 19.1005 7.46875C20.6322 7.46875 22.2344 7.74219 22.2344 7.74219V11.1875H20.469C18.7299 11.1875 18.1875 12.2667 18.1875 13.3738V16H22.0703L21.4496 20.0469H18.1875V29.8299C24.8804 28.7796 30 22.9878 30 16Z"
      fill="#1877F2"
    />
    <path
      d="M21.4496 20.0469L22.0703 16H18.1875V13.3738C18.1875 12.2667 18.7299 11.1875 20.469 11.1875H22.2344V7.74219C22.2344 7.74219 20.6322 7.46875 19.1005 7.46875C15.9026 7.46875 13.8125 9.40688 13.8125 12.9156V16H10.2578V20.0469H13.8125V29.8299C14.5253 29.9418 15.2558 30 16 30C16.7442 30 17.4747 29.9418 18.1875 29.8299V20.0469H21.4496Z"
      fill="white"
    />
  </svg>
);

export default FacebookIcon;
