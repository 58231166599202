import Flicking from '@egjs/react-flicking';
import { Portal } from '@reach/portal';
import { createRef, useEffect, useState } from 'react';
import styled, { CSSProp } from 'styled-components';

import { isVideoResource, ResourceSource } from '@hedgehog/shared/types';
import { HeaderWithClose } from '@hedgehog/ui/headers';
import { Heading } from '@hedgehog/ui/typography';
import { depth, screens } from '@hedgehog/utils/sizes';

import { IndexBar } from '../GalleryModal/IndexBar';
import { GalleryModalVideo } from '../GalleryModalVideo/GalleryModalVideo';

const ARROW_LEFT_KEY = 'ArrowLeft';
const ARROW_RIGHT_KEY = 'ArrowRight';

const FullscreenGalleryImage = styled.img`
  width: 100%;
  height: 40vh;
  object-fit: cover;

  @media (min-width: ${screens.medium}px) {
    height: 60vh;
  }
`;

const GalleryModalContainer = styled.div<{ open: boolean }>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  position: fixed;
  z-index: ${depth.modal};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  ${({ theme }): CSSProp => ({
    backgroundColor: theme.colors.black,
  })}
`;

const GalleryModalHeader = styled(HeaderWithClose)`
  width: 90%;
  background-color: transparent;
`;

const GalleryModalIndexBar = styled.div`
  width: 80%;
`;

const GalleryModalImageContainer = styled.div`
  position: relative;
`;

const GalleryModalControls = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  display: flex;

  @media (max-width: ${screens.medium}px) {
    display: none;
  }

  &:focus {
    outline: none;
  }
`;

const GalleryModalControlButton = styled.div`
  width: 100%;
  cursor: pointer;
`;

type GalleryModalProps = {
  open: boolean;
  resources: ResourceSource[];
  initialIndex?: number;
  onIndexChange: (index: number) => void;
  onClose: () => void;
};

export const GalleryModal = ({
  open,
  resources,
  initialIndex = 0,
  onIndexChange,
  onClose,
}: GalleryModalProps): JSX.Element => {
  const [imageIndex, setImageIndex] = useState(initialIndex);
  const flickingRef = createRef<Flicking>();
  const controlsRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (open) {
      flickingRef.current?.moveTo(initialIndex);
      setImageIndex(initialIndex);
      controlsRef.current?.focus();
    }
  }, [open]);

  const moveLeft = async (): Promise<void> => {
    try {
      if (imageIndex > 0) await flickingRef.current?.prev();
    } catch (err) {
      /* Flicking throws an error when interrupting an animation */
    }
  };
  const moveRight = async (): Promise<void> => {
    try {
      if (imageIndex < resources.length - 1) await flickingRef.current?.next();
    } catch (err) {
      /* Flicking throws an error when interrupting an animation */
    }
  };

  const onKeyDownHandler = async (key: string): Promise<void> => {
    if (key === ARROW_LEFT_KEY) {
      await moveLeft();
    }

    if (key === ARROW_RIGHT_KEY) {
      await moveRight();
    }
  };

  return (
    <Portal>
      <GalleryModalContainer open={open}>
        <GalleryModalHeader
          onClose={(): void => {
            onClose();
            onIndexChange(imageIndex);
          }}
        >
          <Heading level="h5" />
        </GalleryModalHeader>

        <GalleryModalImageContainer>
          <Flicking
            ref={flickingRef}
            defaultIndex={initialIndex}
            onChanged={({ index }: { index: number }): void => {
              setImageIndex(index);
            }}
          >
            {resources.map((resource, index) =>
              isVideoResource(resource) ? (
                <GalleryModalVideo
                  key={resource.src}
                  resource={resource}
                  defaultVolume={0.5}
                  autoPlay={index === imageIndex}
                />
              ) : (
                <FullscreenGalleryImage
                  key={resource.src}
                  src={resource.src}
                  alt={resource.alt || ''}
                />
              ),
            )}
          </Flicking>
          <GalleryModalControls
            ref={controlsRef}
            onKeyDown={(event): void => {
              onKeyDownHandler(event.key);
            }}
            tabIndex={0}
          >
            <GalleryModalControlButton onClick={moveLeft} />
            <GalleryModalControlButton onClick={moveRight} />
          </GalleryModalControls>
        </GalleryModalImageContainer>

        <GalleryModalIndexBar>
          <IndexBar index={imageIndex} length={resources.length} />
        </GalleryModalIndexBar>
      </GalleryModalContainer>
    </Portal>
  );
};

export default GalleryModal;
