import { Hex, LocalAccountSigner, SmartAccountSigner } from '@alchemy/aa-core';
import { ZeroDevConnector } from '@zerodev/wagmi';
import { createContext, useContext } from 'react';
import { useAccount, useDisconnect, useConnect } from 'wagmi';

import { useZeroDev } from './zerodev.provider';

interface WalletProps {
  // Index allows us to create infinite unique wallets for any given passkey. This is usefull for
  // for allowing partner users to create wallets that they manage on behalf of their clients.
  index?: bigint;
}

interface ZeroDevWalletProviderContext {
  address?: string;
  autoloadWallet: ({ index }: WalletProps) => void;
}

// Computes a unique wallet index based on the UUID of the client and the date the client was
// created. This is used for generating segregated wallets for each client using the same signing
// key.
export const getWalletIndex = ({
  id,
  createdAt,
}: {
  id: string;
  createdAt: string;
}): number => {
  // Convert UUID to binary representation
  const binaryUUID = parseInt(id.replace(/-/g, ''), 16).toString(2);

  // Extract 10 bits from the UUID
  const subsetBits = binaryUUID.substring(0, 10);

  // Convert the extracted bits to a number
  const number = parseInt(subsetBits, 2);

  const date = new Date(createdAt).getUTCMilliseconds();

  return date * number;
};

const context = createContext<ZeroDevWalletProviderContext>({
  autoloadWallet: () => ({}),
} as ZeroDevWalletProviderContext);

export interface ZeroDevWalletProviderProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const ZeroDevWalletProvider = ({
  children,
}: ZeroDevWalletProviderProps): JSX.Element => {
  const { projectId, chains } = useZeroDev();

  const { connect } = useConnect();
  const { disconnectAsync } = useDisconnect();
  const { address } = useAccount();

  const PRIVATE_KEY =
    '0xedda8276033d1d1024e8fef67c91f39041c4ea86233eab38640b8ce26e31f015' as Hex;
  const owner = LocalAccountSigner.privateKeyToAccountSigner(PRIVATE_KEY);

  const initialiseProviders = async ({
    owner,
    index,
  }: {
    owner: SmartAccountSigner;
    index: bigint;
  }): Promise<void> => {
    connect({
      connector: new ZeroDevConnector({
        chains,
        options: {
          projectId,
          owner,
          index,
        },
      }),
    });
  };

  const autoloadWallet = async ({ index }: WalletProps): Promise<void> => {
    // Disconnect existing wallet from Wagmi
    await disconnectAsync();

    // Connect the new wallet with the new index
    await initialiseProviders({
      owner: owner as SmartAccountSigner,
      index: BigInt(index || 0),
    });
  };

  const contextValue: ZeroDevWalletProviderContext = {
    address,
    autoloadWallet,
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export const useZeroDevWallet = (): ZeroDevWalletProviderContext =>
  useContext(context);
