import { MouseEventHandler } from 'react';
import styled, { CSSProp } from 'styled-components';

import { Button } from '@hedgehog/ui/buttons';
import { Paragraph } from '@hedgehog/ui/typography';

const QuestionCommentButton = styled(Button).attrs({ small: true })`
  max-width: 100%;
  background: transparent;

  & > * {
    max-width: 100%;
  }
`;

const QuestionCommentContent = styled(Paragraph)`
  ${({ theme }): CSSProp => ({ color: theme.colors.black })};
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  width: 100%;
  max-width: 100%;
`;

const QuestionEdit = styled.span`
  flex: 0 0 auto;
  display: block;
  font-weight: 600;
`;

const QuestionContent = styled.span`
  flex: 1 1 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 0.5rem;
`;

export interface QuestionCommentProps {
  content?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

export const QuestionComment = ({
  content = '',
  onClick,
}: QuestionCommentProps): JSX.Element => {
  const hasAnyContent = content.length > 0;

  return (
    <QuestionCommentButton onClick={onClick}>
      <QuestionCommentContent weight="400">
        {hasAnyContent ? (
          <>
            <QuestionContent>{content}</QuestionContent>
            <QuestionEdit>Edit</QuestionEdit>
          </>
        ) : (
          <QuestionEdit>Add comment</QuestionEdit>
        )}
      </QuestionCommentContent>
    </QuestionCommentButton>
  );
};
