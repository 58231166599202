import { ContentTimelineBlock } from '@hedgehog/shared/types';
import { VSpace } from '@hedgehog/ui/layouts';
import { Timeline, TimelineEntry } from '@hedgehog/ui/lists';

import { CMSTitleBodyBlock } from '../cms-title-body-block/cms-title-body-block.component';

type CMSTimelineBlockProps = {
  block: ContentTimelineBlock;
};

export const CMSTimelineBlock = ({
  block,
}: CMSTimelineBlockProps): JSX.Element => {
  const { title, description, items } = block;
  return (
    <VSpace spacing="small">
      <CMSTitleBodyBlock
        block={{
          title,
          description,
        }}
      />
      <Timeline
        entries={items.map((item): TimelineEntry => {
          return {
            icon: item.icon || 'calendar',
            date: `${item.year}-01-01T00:00:00.000Z`,
            label: item.content,
          };
        })}
      />
    </VSpace>
  );
};
