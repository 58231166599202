import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { Asset } from '@hedgehog/data-access/asset-types';
import {
  useAppData,
  useAssetsQuery,
  useCreateIncomeReport,
  TokenHolderBalance,
} from '@hedgehog/data-access/partners';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import {
  InputGroup,
  QuantityInput,
  SelectInput,
  TextInput,
} from '@hedgehog/ui/inputs';
import { Loader } from '@hedgehog/ui/loaders';
import { Label } from '@hedgehog/ui/typography';
import { Widget, WidgetContent, WidgetGrid } from '@hedgehog/ui/widgets';
import { currencies } from '@hedgehog/utils/formats';

import { CreateIncomeReportBreakdownTable } from '../../containers/create-income-report-breakdown-table/create-income-report-breakdown-table.container';

const NarrowWidget = styled(Widget)`
  div {
    max-width: 32rem;
  }

  ${WidgetContent} {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

const AmountInput = ({
  amount,
  disabled,
  onAmountChange,
}: {
  amount?: number;
  disabled: boolean;
  onAmountChange: (amount?: number) => void;
}): JSX.Element => (
  <InputGroup>
    <Label>Amount</Label>
    <QuantityInput
      min={0}
      value={amount}
      onChange={onAmountChange}
      disabled={disabled}
      noteFormat={() => currencies.formatMoney(amount || 0)}
    />
  </InputGroup>
);

const BeneficiariesTable = styled(CreateIncomeReportBreakdownTable)`
  min-width: 32rem;
`;

const Beneficiaries = ({
  amount,
  totalSupply,
  balances,
}: {
  amount?: number;
  totalSupply: number;
  balances: TokenHolderBalance[];
}) =>
  balances !== undefined && totalSupply !== undefined ? (
    <BeneficiariesTable
      totalIncomeAmount={amount || 0}
      totalSupply={totalSupply}
      balances={balances}
    />
  ) : (
    <Loader />
  );

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  max-height: 2.5rem;
  padding-top: 1rem;
`;

export const ReportingPage = (): JSX.Element => {
  const { activePartner: { id: partnerId = '' } = {} } = useAppData();
  const { data: assets = [], isLoading: isLoadingAssets } = useAssetsQuery(
    { partnerId },
    { skip: !partnerId },
  );

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);

  useEffect(() => {
    if (!assets.length) return;
    setSelectedAsset(assets[0]);
  }, [assets.length]);

  const { address: myWalletAddress } = useAccountAbstraction();
  const [amount, setAmount] = useState<number>();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [createIncomeReport, { balances, success, submitting, totalSupply }] =
    useCreateIncomeReport({
      myWalletAddress: myWalletAddress || '',
      partnerId,
      assetId: selectedAsset?.id || '',
    });

  const submitReportBtn = (
    <PrimaryButton
      onClick={() => createIncomeReport(amount || 0, title, description)}
      disabled={success || !balances?.length}
      loading={!balances?.length || submitting}
      trailingIcon={success ? 'check-circle' : undefined}
    >
      {success ? 'Report sent' : 'Send report'}
    </PrimaryButton>
  );

  return (
    <PageColumnLayout title="New report" actions={submitReportBtn}>
      <WidgetGrid>
        <NarrowWidget medium title="Report Details">
          {!selectedAsset ? (
            <Loader />
          ) : (
            <SelectInput
              defaultValue={selectedAsset?.name}
              disabled={assets.length === 1}
              label="Select fund"
              options={assets}
              renderKey={(asset) => asset.id}
              renderValue={(asset) => asset.name}
              renderOption={(asset) => asset.name}
              onChange={(name) => {
                setSelectedAsset(
                  assets.find((asset) => asset.name === name) as Asset,
                );
              }}
            />
          )}
          {selectedAsset && (
            <AmountInput
              amount={amount}
              disabled={success}
              onAmountChange={setAmount}
            />
          )}
        </NarrowWidget>
        <Widget medium title="Beneficiaries">
          {selectedAsset && !totalSupply && !balances && <Loader />}
          {!isLoadingAssets &&
            !selectedAsset &&
            'Choose an investment/fund above to view beneficiaries'}
          {selectedAsset && totalSupply && balances && (
            <Beneficiaries
              amount={amount}
              totalSupply={totalSupply}
              balances={balances}
            />
          )}
        </Widget>
        <NarrowWidget medium title="The message">
          <div>
            <Label htmlFor="income report title">Title</Label>
            <TextInput
              name="income report title"
              ariaLabel="income report title"
              placeholder="Enter a title"
              onChange={setTitle}
              disabled={success || submitting}
            />
          </div>
          <div>
            <Label htmlFor="income report description">Description</Label>
            <TextInput
              name="income report description"
              ariaLabel="income report description"
              placeholder="Enter a description"
              onChange={setDescription}
              disabled={success || submitting}
            />
          </div>
        </NarrowWidget>
      </WidgetGrid>
      <ButtonContainer>{submitReportBtn}</ButtonContainer>
    </PageColumnLayout>
  );
};
