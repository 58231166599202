import { gql } from '@apollo/client';

export const GET_ORDERS = gql`
  query GetOrders($status: [OrderStatus!]) {
    orders(args: { status: $status }) {
      id
      quantity
      amount
      status
      transferConfirmed
      asset {
        path
        title
        address
        category
        isTenantToken
        themes {
          category
        }
        thumbnails {
          src
          alt
          kind
          thumbnailSrc
        }
        underlyingAssetCount
      }
    }
  }
`;
