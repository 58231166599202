import React from 'react';

import { AutoscrollContext } from './autoscroll.context';
import { AutoscrollContextProps, useAutoscrollContext } from './hooks';

export interface AutoscrollProviderProps extends AutoscrollContextProps {
  children?: React.ReactNode | React.ReactNode[];
}

export const AutoscrollProvider = ({
  children,
  ...props
}: AutoscrollProviderProps): JSX.Element => {
  const contextValue = useAutoscrollContext(props);
  return (
    <AutoscrollContext.Provider value={contextValue}>
      {children}
    </AutoscrollContext.Provider>
  );
};
