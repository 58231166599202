import { ReactNode } from 'react';
import styled from 'styled-components';

import { HeaderWithClose } from '@hedgehog/ui/headers';
import { BottomSheet } from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Container = styled.div`
  overflow-y: auto;
`;

const ModalContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  padding: 2rem 1.5rem;

  ${HeaderWithClose} {
    margin-bottom: 1.5rem;
  }
`;

export interface HighlightTooltipModalProps {
  title?: string;
  description?: ReactNode;
  onClose?: () => void;
}

export const HighlightTooltipModal = ({
  title,
  description,
  onClose,
}: HighlightTooltipModalProps): JSX.Element => {
  return (
    <BottomSheet>
      <ModalContent>
        <HeaderWithClose onClose={onClose}>
          <Heading level="h5">{title}</Heading>
        </HeaderWithClose>
        <Container>
          {typeof description === 'string' ? (
            <Paragraph>{description}</Paragraph>
          ) : (
            description
          )}
        </Container>
      </ModalContent>
    </BottomSheet>
  );
};
