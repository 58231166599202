// RFC 5322 Format:
// Taken from https://stackabuse.com/validate-email-addresses-with-regular-expressions-in-javascript/
const EMAIL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

export const emailMessages: Record<InvalidEmailReason, string> = {
  invalid_format: "That doesn't look like a valid email address",
  subaddress: "Character '+' not allowed",
  no_match: "Email doesn't match",
};

export type InvalidEmailReason = 'invalid_format' | 'subaddress' | 'no_match';

export type EmailValidationOptions = { [K in InvalidEmailReason]?: boolean };

export function createEmailValidation(
  options: EmailValidationOptions = {},
): (value: string, confirmValue?: string | null) => InvalidEmailReason[] {
  const optionsWithDefaults: EmailValidationOptions = {
    invalid_format: true,
    subaddress: false,
    no_match: false,
    ...options,
  };
  return function validateEmail(value, confirmValue = null) {
    const errors: InvalidEmailReason[] = [];

    if (optionsWithDefaults.invalid_format) {
      const isValidFormat = EMAIL_REGEX.test(value);
      if (isValidFormat === false) errors.push('invalid_format');
    }
    if (optionsWithDefaults.subaddress && value.includes('+')) {
      errors.push('subaddress');
    }
    if (confirmValue !== null) {
      if (optionsWithDefaults.no_match && value !== confirmValue) {
        errors.push('no_match');
      }
    }

    return errors;
  };
}
