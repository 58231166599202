import { ReactNode } from 'react';

import { useAppData } from '@hedgehog/data-access/partners';
import {
  JumbotronLayout,
  JumbotronLayoutProps,
  JumbotronPage,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

export interface EnterpriseLayoutProps {
  visibleAt?: JumbotronLayoutProps['visibleAt'];
  links?: ReactNode[] | ReactNode;
  requirePartner?: boolean;
}

export const EnterpriseLayout = ({
  visibleAt,
  links,
  requirePartner = false,
}: EnterpriseLayoutProps): JSX.Element => {
  const { activePartner: partner } = useAppData();

  if (requirePartner && !partner) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  return (
    <JumbotronLayout
      links={links}
      visibleAt={visibleAt}
      logotypeUrl={
        partner?.resources?.logotypeLightUrl ??
        'https://assets.hedgehog-invest.com/logotype-brand.png'
      }
      jumbotronUrl={
        partner?.resources?.landing?.imageUrl ??
        'https://assets.hedgehog-invest.com/images/portfoliob.jpg'
      }
      jumbotronTitle={
        partner?.resources?.landing?.title ??
        'Easy access to real-world investments'
      }
      jumbotronCaption={
        partner?.resources?.landing?.caption ??
        'We have new, strong, and impactful investment opportunities for everyone.'
      }
    >
      <JumbotronPage />
    </JumbotronLayout>
  );
};
