import {
  ThunkAction,
  Action,
  ThunkDispatch,
  EnhancedStore,
} from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from 'react-redux';

import { createStore } from './create';

export * from './reducers';
export * from './utils/is-request-response-error.guard';

export type AppDispatch = EnhancedStore['dispatch'];
export type AppStore = EnhancedStore;
export type RootState = ReturnType<EnhancedStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;

export const useDispatch = () => useReduxDispatch<ThunkAppDispatch>();

export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const createPartnerPortalStore = (initialState = {}): EnhancedStore =>
  createStore(initialState);
