import {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { Any } from '@hedgehog/shared/types';
import { Button } from '@hedgehog/ui/buttons';
import { parseBorderToShadow, parsePadding } from '@hedgehog/ui/themes';
import { Paragraph } from '@hedgehog/ui/typography';

import { InputLabel } from '../input-label';

import { SelectOption } from './select-option.component';
import { useSelectControls } from './use-select-controls.hook';

const SelectContainer = styled.div`
  position: relative;
`;

const SelectDropdown = styled.div`
  z-index: 100;
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  max-height: 16rem;
  box-sizing: border-box;
  transition: max-height 200ms;
  position: absolute;
  top: 100%;

  &[hidden] {
    max-height: 0px;
  }
`;

const SelectDropdownContent = styled.div``;

const SelectDropdownInnerWrapper = styled.div`
  ${({ theme }): CSSProp => ({
    backgroundColor: theme.colors.grey100,
  })}
  padding: 0 calc(0.875rem + 0.25rem);
  border-radius: 0.5rem;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const SelectControl = styled.label`
  cursor: pointer;
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const SelectControlValueWrapper = styled.div<{ errors?: string[] }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  ${({ errors, theme }): CSSObject => ({
    borderRadius: theme.radius.normal,
    padding: parsePadding(theme.inputs.padding),
    boxShadow: parseBorderToShadow(theme.inputs.border, {
      color: errors?.length ? theme.colors.error : 'transparent',
    }),
    color: errors?.length ? theme.colors.error : theme.colors.black,
    backgroundColor: theme.colors.grey100,
    ...theme.typography.body,
    [`&:focus, &:hover`]: {
      borderColor: parseBorderToShadow(theme.inputs.border, {
        color: errors?.length ? theme.colors.error : theme.inputs.border.color,
      }),
    },
  })}
  width: 100%;
  box-sizing: border-box;
  height: 3rem;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  outline: none;
  transition: border 100ms ease-in-out;
`;

const SelectControlInput = styled.input<{ errors?: string[] }>`
  display: block;
  height: 0.5px;
  width: 0.5px;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
`;

const SelectClearButton = styled(Button)`
  background: transparent;
  opacity: 0.6;
  padding: 0.5rem;
`;

const SelectOptionInnerWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

export interface SelectInputProps<V = string, E = Any> {
  renderKey: (item: E) => string;
  renderValue: (item: E) => V;
  renderOption: (item: E) => ReactNode;
  options: E[];
  name?: string;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  clearable?: boolean;
  defaultValue?: V;
  onChange?: (value?: V) => void;
  className?: string;
}

let uniqueId = 0;

/**
 * @deprecated in favour of SelectorInput
 * @constructor
 */
export const SelectInput = <V = string, E = Any>({
  name,
  label,
  options,
  disabled,
  defaultValue,
  placeholder,
  clearable,
  renderKey,
  renderValue,
  renderOption,
  onChange,
  className,
}: SelectInputProps<V, E>): JSX.Element => {
  const id = useRef(`select-${name}-${uniqueId++}`);
  const [isFocused, { inputProps, dropdownProps, close }] = useSelectControls();

  const initialElementIndex = defaultValue
    ? options.map(renderValue).indexOf(defaultValue)
    : -1;
  const [currentElement, setCurrentElement] = useState(
    initialElementIndex >= 0 ? options[initialElementIndex] : null,
  );
  const [currentValue, setCurrentValue] = useState<V | undefined>(defaultValue);

  const handleClearClick = (): void => {
    if (!currentValue) return;
    setCurrentValue(undefined);
    setCurrentElement(null);
  };

  const createSelectHandler =
    (element: any): MouseEventHandler =>
    () => {
      if (disabled) return;
      setCurrentElement(element);
      setCurrentValue(renderValue(element));
      close();
    };

  useEffect(() => {
    if (onChange) onChange(currentValue);
  }, [currentValue]);

  return (
    <SelectContainer className={className}>
      <SelectControl
        htmlFor={id.current}
        className={disabled ? 'disabled' : undefined}
      >
        {label && <InputLabel>{label}</InputLabel>}
        <SelectControlValueWrapper>
          <SelectOptionInnerWrapper>
            {currentElement ? (
              renderOption(currentElement)
            ) : (
              <SelectOption disabled>
                <Paragraph color="grey400">{placeholder}</Paragraph>
              </SelectOption>
            )}
          </SelectOptionInnerWrapper>
          {clearable && currentValue ? (
            <SelectClearButton small icon="cross" onClick={handleClearClick} />
          ) : null}
        </SelectControlValueWrapper>
        <SelectControlInput
          id={id.current}
          value={currentValue}
          placeholder={placeholder}
          disabled={disabled}
          readOnly
          {...inputProps}
        />
      </SelectControl>
      <SelectDropdown hidden={isFocused ? undefined : true} {...dropdownProps}>
        <SelectDropdownInnerWrapper>
          <SelectDropdownContent>
            {options?.map((element) => (
              <SelectOption
                key={renderKey(element)}
                onClick={createSelectHandler(element)}
              >
                {renderOption(element)}
              </SelectOption>
            ))}
          </SelectDropdownContent>
        </SelectDropdownInnerWrapper>
      </SelectDropdown>
    </SelectContainer>
  );
};
