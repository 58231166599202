import { ForwardedRef, MouseEventHandler, ReactNode, forwardRef } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { Avatar, Icon, IconType } from '@hedgehog/ui/icons';
import { parseBorderToShadow, shimmerGradient } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { Card, LinkOrNotProps } from '../card/card.component';
import { CardContent } from '../card/styles';

const ActionCardContent = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`;

export const ActionCardLeading = styled.div`
  flex: 0 0 auto;
`;

export const ActionCardTrailing = styled.div`
  flex: 0 0 auto;
`;

export const ActionCardBase = css<ActionCardBaseProps>`
  ${({
    type,
    disabled,
    theme: { actions, border, colors, opacity },
  }): CSSObject => ({
    background: type === 'default' ? colors.grey100 : colors.white,
    color: colors.black,
    padding: `${actions.padding.y} ${actions.padding.x}`,
    borderRadius: actions.radius,
    boxShadow:
      type === 'light'
        ? parseBorderToShadow(border.small, { color: colors.shadow100 })
        : undefined,

    '& > *': {
      opacity: disabled && type === 'default' ? opacity[20] : 1,
    },
    '&:hover, &:focus': {
      background: type === 'light' && !disabled ? colors.grey100 : undefined,
    },
    '&:hover:after, &:focus:after': {
      borderRadius: actions.radius,
    },
  })}
`;

const ActionCardContainer = styled(Card)<ActionCardBaseProps>`
  ${ActionCardBase};

  ${({ loading }) => loading && shimmerGradient}

  ${({ disabled, to, href }): CSSObject => ({
    cursor: !disabled && (to || href) ? 'pointer' : undefined,
  })};

  & ${CardContent} {
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    ${({ theme: { spacing } }): CSSObject => ({ gap: spacing.xsmall })}
  }
`;

interface ActionCardBaseProps {
  type?: 'default' | 'light' | 'success';
  title?: string | ReactNode | ReactNode[];
  caption?: string | ReactNode | ReactNode[];
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler;
  'data-cy'?: string;

  /**
   * @deprecated Use `caption` instead
   */
  description?: string;
  /**
   * @deprecated Use `caption` instead
   */
  information?: string;
  /**
   * @deprecated Use `leadingIcon` instead
   */
  icon?: IconType;
}

type LeadingIconOrReactNode = {
  leadingIcon?: IconType;
  leading?: ReactNode | ReactNode[];
};

type TrailingIconOrReactNode = {
  trailingIcon?: IconType;
  trailing?: ReactNode | ReactNode[];
};

export type ActionCardProps = StandardProps<
  ActionCardBaseProps &
    LeadingIconOrReactNode &
    TrailingIconOrReactNode &
    LinkOrNotProps
>;

export const ActionCard = forwardRef(
  (
    {
      icon,
      information,
      description,
      type = 'default',
      title,
      caption = information || description,
      trailing,
      trailingIcon,
      leading,
      leadingIcon = icon,
      disabled = type === 'success',
      loading = false,
      ...cardProps
    }: ActionCardProps,
    ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>,
  ): JSX.Element => (
    <ActionCardContainer
      ref={ref}
      type={type}
      disabled={disabled}
      loading={loading}
      {...cardProps}
    >
      {(leadingIcon || leading) && (
        <ActionCardLeading>
          {leadingIcon ? (
            <Avatar
              icon={leadingIcon}
              size="m"
              backgroundColor={type === 'default' ? 'white' : 'grey100'}
              color="black"
            />
          ) : (
            leading
          )}
        </ActionCardLeading>
      )}

      <ActionCardContent>
        {typeof title === 'string' ? (
          <Heading loading={loading} level="h6">
            {title}
          </Heading>
        ) : (
          title
        )}
        {typeof caption === 'string' ? (
          <Paragraph loading={loading} color="grey400">
            {caption}
          </Paragraph>
        ) : (
          caption
        )}
      </ActionCardContent>

      {(trailingIcon || trailing) && (
        <ActionCardTrailing>
          {trailingIcon ? (
            <Icon
              icon={trailingIcon}
              size="s"
              backgroundColor={type === 'default' ? 'white' : 'grey100'}
              color="black"
            />
          ) : (
            trailing
          )}
        </ActionCardTrailing>
      )}
    </ActionCardContainer>
  ),
);
