import { gql } from '@apollo/client';

export const GET_ASSESSMENT_STATUS = gql`
  query GetAssessmentStatus {
    assessmentProgress {
      status
    }
    assessmentResponses {
      valid
    }
  }
`;
