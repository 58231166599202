import { css, CSSObject } from 'styled-components';

export const fontSizes = {
  ginormous: '2rem' as const, // 32px
  gigantic: '1.75rem' as const, // 28px
  enormous: '1.5rem' as const, // 24px
  huge: '1.375rem' as const, // 22px
  large: '1.125rem' as const, // 18px
  regular: '1rem' as const, // 16px
  small: '0.875rem' as const, // 14px
  tiny: '0.75rem' as const, // 12px
  miniscule: '0.6875rem' as const, // 11px

  heading1: '4rem' as const,
  heading2: '2.5rem' as const,
  heading3: '2rem' as const,
  heading4: '1.5rem' as const,
  heading5: '1.25rem' as const,
  heading6: '1rem' as const,

  body: '1rem' as const,
  bodySmall: '0.875rem' as const,
  title: '0.875rem' as const,
  label: '0.75rem' as const,
  note: '0.75rem' as const,
};

export const lineHeight = {
  heading1: '4.5rem' as const,
  heading2: '3rem' as const,
  heading3: '2.25rem' as const,
  heading4: '1.75rem' as const,
  heading5: '1.5rem' as const,
  heading6: '1.25rem' as const,
  body: '1.5rem' as const,
  bodySmall: '1rem' as const,
  title: '1rem' as const,
  label: '1rem' as const,
  note: '1rem' as const,
};
