import Flicking from '@egjs/react-flicking';
import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { isVideoResource, ResourceSource } from '@hedgehog/shared/types';
import { useModal } from '@hedgehog/ui/modals';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { screens, spacing } from '@hedgehog/utils/sizes';

import { GalleryImage } from '../GalleryImage/GalleryImage';
import { GalleryModal } from '../GalleryModal/GalleryModal';
import { GalleryVideo } from '../GalleryVideo/GalleryVideo';

const StyledFlicking = styled(Flicking)<Pick<GalleryProps, 'resources'>>`
  height: 100%;
  cursor: ${({ resources }): string =>
    resources.length === 1 ? 'pointer' : 'grab'};
`;

const StyledFlickingChild = styled.div<{ fullWidth?: boolean }>`
  width: 100%;

  ${({ fullWidth }): string | false =>
    !fullWidth &&
    `
  width: 95%;
  max-width: 20rem;
  margin-right: 0.5rem;
  `}

  @media (min-width: ${screens.medium}px) {
    ${({ fullWidth }): string | false =>
      !fullWidth &&
      `
      width: 95%;
      max-width: 25rem;
      margin-right: 0.5rem;
  `}
  }
`;

const TextContainer = styled.div`
  > * {
    margin-top: ${spacing.md};
  }
`;

type GalleryProps = {
  resources: ResourceSource[];
  align?: 'prev' | 'center' | 'next';
  circular?: boolean;
  className?: string;
  children?: React.ReactNode | React.ReactNode[];
  cover?: JSX.Element;
};

export const Gallery = ({
  resources,
  align = 'prev',
  circular = false,
  className,
  children,
  cover,
}: GalleryProps): JSX.Element => {
  const [openSlideIndex, setOpenSlideIndex] = useState<number | null>(null);
  const flickingRef = createRef<Flicking>();

  const moveToSlide = async (index: number): Promise<void> =>
    flickingRef.current?.moveTo(index);

  const { openAndResolveWithSubmitValue } = useModal((modal) => (
    <GalleryModal
      open
      resources={resources}
      initialIndex={openSlideIndex || 0}
      onIndexChange={moveToSlide}
      onClose={(): void => {
        modal.cancelModal();
        setOpenSlideIndex(null);
      }}
    />
  ));

  useEffect(() => {
    if (resources.length === 1) {
      flickingRef.current?.disableInput();
    } else {
      flickingRef.current?.enableInput();
    }
  }, [resources]);

  useEffect(() => {
    const openSlideModal = async (): Promise<void> => {
      try {
        await openAndResolveWithSubmitValue();
      } catch (error) {
        /* NOOP */
      }
    };
    if (openSlideIndex !== null) {
      openSlideModal();
    }
  }, [openSlideIndex]);

  const openSlide = async (index: number): Promise<void> => {
    // Move to the slide. We can wait for the promise to resolve before opening the modal to show the slide change
    // animation.
    moveToSlide(index);
    // Open the slide in the fullscreen modal. A useEffect will pick up the changed value and open the modal.
    setOpenSlideIndex(index);
  };

  const Description = ({
    resource,
  }: {
    resource: ResourceSource;
  }): JSX.Element => {
    return (
      <TextContainer>
        {resource.heading && <Heading level="h6">{resource.heading}</Heading>}
        {resource.text && <Paragraph>{resource.text}</Paragraph>}
      </TextContainer>
    );
  };

  return (
    <StyledFlicking
      horizontal
      // If the sum of the panel sizes is too small, circular will not be enabled.
      // @see https://naver.github.io/egjs-flicking/Options/#circular
      circular={circular}
      // TODO assign the received `align` parameter, after an image content block will be created
      align={resources.length === 1 ? 'center' : align}
      ref={flickingRef}
      className={className}
      resources={resources}
      onChanged={({ index }: { index: number }): Promise<void> =>
        moveToSlide(index)
      }
    >
      {cover && (
        <StyledFlickingChild>
          {cover}
          <Description resource={resources[0]} />
        </StyledFlickingChild>
      )}
      {resources.map((resource, slideIndex) => {
        return isVideoResource(resource) ? (
          <StyledFlickingChild
            key={`${resource.kind}_${resource.src}_${resource.thumbnailSrc}`}
            fullWidth={resources.length === 1}
          >
            <GalleryVideo
              src={resource.thumbnailSrc || resource.src}
              onClick={(): Promise<void> => openSlide(slideIndex)}
              fullWidth={resources.length === 1}
            />
            <Description resource={resource} />
          </StyledFlickingChild>
        ) : (
          <StyledFlickingChild key={`${resource.kind}_${resource.src}`}>
            <GalleryImage
              src={resource.thumbnailSrc || resource.src}
              onClick={(): Promise<void> => openSlide(slideIndex)}
            />
            <Description resource={resource} />
          </StyledFlickingChild>
        );
      })}
      {children}
    </StyledFlicking>
  );
};

export default Gallery;
