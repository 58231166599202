export const CalendarIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 0H3V2H1V5H15V2H13V0H11V2H5V0Z" fill="currentColor" />
    <path d="M15 7H1V15H15V7Z" fill="currentColor" />
  </svg>
);

export default CalendarIcon;