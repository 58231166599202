import styled from 'styled-components';

import { MarkdownText, Paragraph } from '@hedgehog/ui/typography';
import { spacing } from '@hedgehog/utils/sizes';

import { QuestionHeading } from './styles';

type QuestionContentType = 'standard' | 'markdown';

export type QuestionContentProps = { type: QuestionContentType } & (
  | QuestionContentMarkdownProps
  | QuestionContentStandardProps
);

export interface QuestionContentMarkdownProps {
  type: 'markdown';
  markdown?: string | null;
  questions?: never;
  description?: never;
}

export interface QuestionContentStandardProps {
  type: 'standard';
  questions?: string[];
  description?: string | null;
  markdown?: never;
}

const QuestionMarkdown = styled(MarkdownText)`
  & > *:not(:last-child) {
    margin-top: 0;
    margin-bottom: ${spacing.xxl};
  }

  hr {
    width: 100%;
    border-width: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    letter-spacing: 0.5px;
    font-weight: 500;
  }

  p {
    letter-spacing: -0.25px;
  }
`;

export const QuestionContent = ({
  type,
  markdown,
  questions = [],
  description,
}: QuestionContentProps): JSX.Element => {
  return type === 'markdown' ? (
    <QuestionMarkdown>{markdown ?? ''}</QuestionMarkdown>
  ) : (
    <>
      {questions.map((text) => (
        <QuestionHeading level="h5" key={text}>
          {text}
        </QuestionHeading>
      ))}
      {description && <Paragraph>{description}</Paragraph>}
    </>
  );
};
