import styled, { CSSProp } from 'styled-components';

import { currencies } from '@hedgehog/utils/formats';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const StyledTableHeader = styled.th`
  text-align: left;
  padding: 1rem 0;
`;

const StyledTableRow = styled.tr`
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  ${({ theme }): CSSProp => ({
    borderTopColor: theme.colors.grey100,
    borderBottomColor: theme.colors.grey100,
  })}
`;

const StyledTableData = styled.td`
  padding: 1rem 0;
`;

export interface RecipientProps {
  id: string;
  name: string;
  committed: number;
  drawn: number;
  due: number;
}

export const CapitalCallRecipientsTable = ({
  recipients,
}: {
  recipients: RecipientProps[];
}) => {
  if (recipients.length === 0) return <div>No recipients were found.</div>;

  return (
    <StyledTable>
      <StyledTableHeader>Client</StyledTableHeader>
      <StyledTableHeader>Amount Committed</StyledTableHeader>
      <StyledTableHeader>Amount Drawn</StyledTableHeader>
      <StyledTableHeader>This Call</StyledTableHeader>
      {recipients.map((recipient) => (
        <StyledTableRow key={recipient.id}>
          <StyledTableData>{recipient.name}</StyledTableData>
          <StyledTableData>
            {currencies.formatMoney(recipient.committed, {
              minimumFractionDigits: 2,
            })}
          </StyledTableData>
          <StyledTableData>
            {currencies.formatMoney(recipient.drawn, {
              minimumFractionDigits: 2,
            })}
          </StyledTableData>
          <StyledTableData>
            {currencies.formatMoney(recipient.due, {
              minimumFractionDigits: 2,
            })}
          </StyledTableData>
        </StyledTableRow>
      ))}
    </StyledTable>
  );
};
