import styled from 'styled-components';

import { ContentYoutubeBlock } from '@hedgehog/shared/types';

const YouTube = styled.div`
  height: 11.25rem;
  width: 100%;
  border-radius: 1rem;
  overflow: hidden;
`;

type CMSYoutubeBlockProps = {
  block: ContentYoutubeBlock;
};

export const CMSYoutubeBlock = ({
  block,
}: CMSYoutubeBlockProps): JSX.Element => {
  const { title, url } = block;
  return (
    <YouTube>
      <iframe
        title={title}
        src={url}
        width="100%"
        height="100%"
        frameBorder="0"
      />
    </YouTube>
  );
};
