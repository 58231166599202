export const HotelIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 0H0V24H4V20H28V24H32V14C32 10.6863 29.3137 8 26 8H12C12 5.79086 10.2091 4 8 4H4V0Z"
      fill="currentColor"
    />
  </svg>
);

export default HotelIcon;
