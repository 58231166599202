import React, { MouseEventHandler } from 'react';
import { Link, RelativeRoutingType, To } from 'react-router-dom';

import {
  NativeMobileExperiences,
  sendMessageWithPayloadToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { Chunk, IconType } from '@hedgehog/ui/icons';
import { Loader } from '@hedgehog/ui/loaders';
import { ThemeColorName } from '@hedgehog/ui/themes';

import { ButtonBase, LoaderContainer } from '../button-base.component';

export type LinkButtonProps = {
  icon?: IconType;
  leadingIcon?: IconType;
  trailingIcon?: IconType;
  leadingSize?: 's' | 'm' | 'l';
  trailingSize?: 's' | 'm' | 'l';
  iconColor?: ThemeColorName;
  loading?: boolean;
  className?: string;
  fluid?: boolean;
  state?: unknown;
  replace?: boolean;
  notify?: boolean;
  small?: boolean;
  disabled?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  children?: React.ReactNode | React.ReactNode[];
  color?: ThemeColorName;
  $hoverColor?: ThemeColorName;
  onClick?: MouseEventHandler;
} & LinkOrNotProps;

export type LinkOrNotProps =
  | ExternalLinkProps
  | InternalLinkProps
  | StubLinkProps;

export interface ExternalLinkProps {
  href?: string;
  to?: undefined;
}

export interface InternalLinkProps {
  to?: To | number;
  relative?: RelativeRoutingType;
  href?: undefined;
}

export interface StubLinkProps {
  to?: undefined;
  href?: undefined;
}

export const LinkButton = React.forwardRef(
  (
    {
      children,
      icon,
      loading = false,
      leadingIcon = icon,
      leadingSize = 's',
      trailingSize = 's',
      notify,
      onClick,
      color,
      $hoverColor = 'shadow100',
      ...props
    }: LinkButtonProps,
    ref: React.ForwardedRef<HTMLAnchorElement>,
  ) => {
    const shouldNotify = useIsNativeMobileExperience(
      NativeMobileExperiences.LINK_NOTIFY,
    );
    const handleClick: MouseEventHandler = (event) => {
      if (shouldNotify && notify) {
        event.preventDefault();
        sendMessageWithPayloadToApp('navigate', props.to);
        return;
      }
      onClick && onClick(event);
    };
    return (
      <ButtonBase
        as={props.to ? Link : props.href ? 'a' : 'button'}
        ref={ref}
        color={color}
        $isLoading={loading}
        $hoverColor={$hoverColor}
        {...props}
        onClick={handleClick}
      >
        {loading && (
          <LoaderContainer>
            <Loader color="currentColor" />
          </LoaderContainer>
        )}
        {leadingIcon && (
          <Chunk size={leadingSize} icon={leadingIcon} color="inherit" />
        )}
        {children && <span>{children}</span>}
        {props.trailingIcon && (
          <Chunk
            size={trailingSize}
            icon={props.trailingIcon}
            color="inherit"
          />
        )}
      </ButtonBase>
    );
  },
);
