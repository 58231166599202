export const CircleWarning = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="20" fill="#FFB652" />
    <path
      d="M20 15C19.7348 15 19.4804 15.1054 19.2929 15.2929C19.1054 15.4804 19 15.7348 19 16V20C19 20.2652 19.1054 20.5196 19.2929 20.7071C19.4804 20.8946 19.7348 21 20 21C20.2652 21 20.5196 20.8946 20.7071 20.7071C20.8946 20.5196 21 20.2652 21 20V16C21 15.7348 20.8946 15.4804 20.7071 15.2929C20.5196 15.1054 20.2652 15 20 15ZM20 23C19.8022 23 19.6089 23.0586 19.4444 23.1685C19.28 23.2784 19.1518 23.4346 19.0761 23.6173C19.0004 23.8 18.9806 24.0011 19.0192 24.1951C19.0578 24.3891 19.153 24.5673 19.2929 24.7071C19.4327 24.847 19.6109 24.9422 19.8049 24.9808C19.9989 25.0194 20.2 24.9996 20.3827 24.9239C20.5654 24.8482 20.7216 24.72 20.8315 24.5556C20.9414 24.3911 21 24.1978 21 24C21 23.7348 20.8946 23.4804 20.7071 23.2929C20.5196 23.1054 20.2652 23 20 23ZM29.71 15.56L24.44 10.29C24.2484 10.1073 23.9948 10.0037 23.73 10H16.27C16.0052 10.0037 15.7516 10.1073 15.56 10.29L10.29 15.56C10.1073 15.7516 10.0037 16.0052 10 16.27V23.73C10.0037 23.9948 10.1073 24.2484 10.29 24.44L15.56 29.71C15.7516 29.8927 16.0052 29.9963 16.27 30H23.73C23.9948 29.9963 24.2484 29.8927 24.44 29.71L29.71 24.44C29.8927 24.2484 29.9963 23.9948 30 23.73V16.27C29.9963 16.0052 29.8927 15.7516 29.71 15.56ZM28 23.31L23.31 28H16.69L12 23.31V16.69L16.69 12H23.31L28 16.69V23.31Z"
      fill="white"
    />
  </svg>
);

export default CircleWarning;
