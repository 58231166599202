import React, { ForwardedRef, ReactNode } from 'react';
import styled, { css, CSSObject } from 'styled-components';

import { spacing } from '@hedgehog/utils/sizes';

export interface BlockContainerProps {
  direction?: 'column' | 'row';
  backgroundColor?: string;
  children?: ReactNode | ReactNode[];
  onClick?: () => void;
  className?: string;
  padding?: string;
}

export const BlockContainerStyles = css<BlockContainerProps>`
  display: flex;
  flex-direction: ${({ direction }): string | undefined => direction};
  border-radius: ${spacing.xs};

  ${({ theme, backgroundColor, padding }): CSSObject => ({
    backgroundColor: backgroundColor || theme.colors.grey100,
    borderRadius: theme.containers.block.radius,
    padding: padding || `${spacing.xxl} ${spacing.md}`,
  })}
`;

const Component = styled.div<BlockContainerProps>`
  ${BlockContainerStyles}
`;

/**
 * @deprecated
 */
export const BlockContainer = React.forwardRef(
  (
    {
      children,
      direction = 'row',
      backgroundColor,
      onClick,
      className,
      padding,
    }: BlockContainerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <Component
        ref={ref}
        className={className}
        direction={direction}
        backgroundColor={backgroundColor}
        onClick={onClick}
        padding={padding}
      >
        {children}
      </Component>
    );
  },
);
