import { FormEvent } from 'react';
import styled from 'styled-components';

import { SecondaryButton } from '@hedgehog/ui/buttons';
import { MailIcon } from '@hedgehog/ui/icons';
import { EmailInput } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';

import { AuthFormCardContainer } from '../components';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export interface ForgotPasswordFormData {
  email: string;
}

export interface ForgotPasswordFormProps {
  data: ForgotPasswordFormData;
  sent?: boolean;
  placeholder?: string;
  errorMessage?: string;
  onChange: (formData: ForgotPasswordFormData) => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
}

export const ForgotPasswordForm = ({
  data,
  sent,
  placeholder = 'Your@email.here',
  errorMessage,
  onChange,
  onSubmit,
}: ForgotPasswordFormProps): JSX.Element => {
  const isFormValid = Boolean(data.email);

  const handleChange = (key: keyof ForgotPasswordFormData) => {
    return (value: string) => {
      onChange({ ...data, [key]: value });
    };
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    onSubmit && onSubmit(event);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {sent && (
        <AuthFormCardContainer
          title="An email has been sent"
          description="Proceed through the link sent"
          icon="mail"
        />
      )}
      <EmailInput
        name="email"
        value={data.email}
        placeholder={placeholder}
        label="Email Address"
        onChange={handleChange('email')}
      />
      {errorMessage && <Paragraph>{errorMessage}</Paragraph>}
      <SecondaryButton type="submit" disabled={!isFormValid || sent}>
        Reset Password
      </SecondaryButton>
    </Form>
  );
};
