import { MouseEventHandler, ReactNode } from 'react';
import { useMatch } from 'react-router-dom';
import styled, { CSSObject } from 'styled-components';

import { LinkButton, styles } from '@hedgehog/ui/buttons';
import { Chunk, IconType } from '@hedgehog/ui/icons';
import { parsePadding } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

const StyledLinkButton = styled(LinkButton)<{
  selected?: boolean;
  $hoverColor?: string;
}>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;

  font-size: ${({ theme }): string => theme.typography.body.fontSize || '24px'};

  ${({ selected, theme }): CSSObject => ({
    fontSize: '1.5rem',
    [`@media only screen and (min-width: ${theme.breakpoints.desktop}px)`]: {
      fontSize: theme.typography.body_small.fontSize || '16px',
      position: 'sticky',
      minWidth: 'unset',
    },
    color: selected ? theme.colors.black : theme.colors.grey500,
    backgroundColor: selected ? theme.colors.grey100 : 'transparent',
    gap: theme.spacing.xsmall,
    padding: parsePadding({ x: theme.spacing.small, y: theme.spacing.small }),
    fontWeight: 400,
  })}

  ${Chunk} {
    ${({ selected, theme }): CSSObject => ({
      color: selected ? theme.colors.black : theme.colors.grey500,
      width: theme.spacing.normal,
      height: theme.spacing.normal,
    })}
  }
  &:hover,
  &:focus {
    ${styles.alternativeHoverAppearance}
  }
`;

export interface NavItemProps {
  to: string;
  icon?: IconType;
  onClick?: MouseEventHandler;
  children?: ReactNode;
}

export const NavItem = ({
  to,
  icon,
  onClick,
  children,
}: StandardProps<NavItemProps>): JSX.Element => {
  const match = !!useMatch(`${to}*`);
  return (
    <StyledLinkButton selected={match} icon={icon} to={to} onClick={onClick}>
      {children}
    </StyledLinkButton>
  );
};
