export const EvergreenIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8V6L6 0L12 6V8H9L12 11V13H7V16H5V13H0V11L3 8H0Z"
      fill="currentColor"
    />
  </svg>
);

export default EvergreenIcon;
