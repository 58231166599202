import { FormEvent, useEffect, useState } from 'react';

import {
  useAppData,
  usePartnerSettingsQuery,
} from '@hedgehog/data-access/partners';
import {
  InvestorType,
  PartnerClientType,
  SourceOfFunds,
  UUID,
} from '@hedgehog/shared/types';
import {
  CheckboxInput,
  DateInput,
  EmailInput,
  SelectorInput,
  TextInput,
} from '@hedgehog/ui/inputs';
import { Form, FormDivider, FormGroup, FormRow } from '@hedgehog/ui/layouts';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { getAllStateNames } from '@hedgehog/utils/states';
import {
  createEmailValidation,
  InvalidEmailReason,
} from '@hedgehog/utils/validation';

import { environment } from '../../environments/environment';
import { StandardProps } from '../../types';
import { AssetSelectorInput } from '../asset-selector-input/asset-selector-input';

export interface CreateClientAccreditationFormData {
  investorType?: InvestorType;
  accredited?: boolean;
}

export interface CreateClientBaseFormData {
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  country: string;
  state?: string;
  dateOfBirth?: string;
  addressLine1?: string;
  addressLine2?: string;
  town?: string;
  postcode?: string;
  sourceOfFunds?: SourceOfFunds;
  taxResidency?: string;
  taxIdentifier?: string;
  ownedKYC?: boolean;
  sendInvitationEmail?: boolean;
  partnerClientType?: PartnerClientType;
  assetRoundClassIds: UUID[];
}

export type CreateClientFormData = CreateClientAccreditationFormData &
  CreateClientBaseFormData;

export interface CreateClientFormError {
  email: InvalidEmailReason[];
  confirmEmail: InvalidEmailReason[];
}

export interface CreateClientFormProps {
  partnerName?: string;
  defaultForm?: CreateClientFormData;
  disallowSubaddress?: boolean;
  onSubmit?: (formData: CreateClientFormData) => void;
  onChange?: (formData: CreateClientFormData) => void;
}

export const initialFormData: CreateClientFormData = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  country: '',
  state: undefined,
  investorType: undefined,
  accredited: false,
  dateOfBirth: undefined,
  addressLine1: '',
  addressLine2: '',
  town: '',
  postcode: '',
  sourceOfFunds: undefined,
  taxResidency: undefined,
  taxIdentifier: '',
  ownedKYC: false,
  partnerClientType: undefined,
  assetRoundClassIds: [],
  sendInvitationEmail: false,
};

export const CreateClientForm = (
  props: StandardProps<CreateClientFormProps>,
): JSX.Element => {
  const {
    disallowSubaddress: disallowSubaddress = environment.type === 'production',
  } = props;
  const validateEmail = createEmailValidation({
    subaddress: disallowSubaddress,
  });
  const validateConfirmEmail = createEmailValidation({
    subaddress: false,
    invalid_format: false,
    no_match: true,
  });
  const [formData, changeFormData] = useState<CreateClientFormData>({
    ...initialFormData,
    ...props.defaultForm,
  });
  const [errors, changeErrors] = useState<CreateClientFormError>({
    email: [],
    confirmEmail: [],
  });
  const { activePartner } = useAppData();

  const { data: partnerSettings } = usePartnerSettingsQuery({
    partnerId: activePartner?.id ?? '',
  });

  useEffect(() => {
    if (props.onChange) props.onChange(formData);
  }, [formData]);

  const createInputControl = <PropertyName extends keyof CreateClientFormData>(
    propertyName: PropertyName,
  ) => ({
    name: propertyName,
    value: formData[propertyName],
    onChange: (value?: CreateClientFormData[PropertyName]) => {
      changeFormData((state) => ({
        ...state,
        [propertyName]: value,
      }));
    },
  });

  const handleEmailBlur = (): void =>
    changeErrors((state) => ({
      ...state,
      email: validateEmail(formData.email),
    }));

  const handleConfirmEmailBlur = (): void =>
    changeErrors((state) => ({
      ...state,
      confirmEmail: validateConfirmEmail(formData.email, formData.confirmEmail),
    }));

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (props.onSubmit) {
      props.onSubmit(formData);
    }
  };

  return (
    <Form onSubmit={handleSubmit} className={props.className}>
      <FormGroup>
        <FormRow>
          <TextInput
            label="Clients first name"
            placeholder="Type the first name"
            {...createInputControl('firstName')}
          />
          <TextInput
            label="Last Name"
            placeholder="Type the last name"
            {...createInputControl('lastName')}
          />
        </FormRow>

        <EmailInput
          label="Email Address"
          ariaLabel="email-input"
          errors={errors.email}
          onBlur={handleEmailBlur}
          placeholder="email@example.com"
          {...createInputControl('email')}
        />

        <EmailInput
          label="Confirm Email Address"
          ariaLabel="email-confirm-input"
          errors={errors.confirmEmail}
          onBlur={handleConfirmEmailBlur}
          placeholder="email@example.com"
          {...createInputControl('confirmEmail')}
        />
        <SelectorInput
          isClearable={false}
          label="Country of Residence"
          placeholder="Select a country"
          ariaLabel="country-selector"
          name="country"
          options={
            partnerSettings?.countries?.length > 0
              ? partnerSettings.countries
              : // fallback to default countries
                [
                  { value: 'GBR', label: '🇬🇧 United Kingdom' },
                  { value: 'USA', label: '🇺🇸 United States' },
                  { value: 'CHE', label: '🇨🇭 Switzerland' },
                ]
          }
          onChange={(newValue: { value: string }) => {
            changeFormData((prevState) => ({
              ...prevState,
              country: newValue.value,
            }));
          }}
        />
        {formData.country === 'USA' && (
          <SelectorInput
            isClearable={false}
            label="State"
            placeholder="Select a state"
            ariaLabel="state-selector"
            name="state"
            options={getAllStateNames().map((state) => ({
              label: state,
              value: state,
            }))}
            onChange={(newValue: { value: string }) => {
              changeFormData((prevState) => ({
                ...prevState,
                state: newValue.value,
              }));
            }}
          />
        )}

        {formData.country === 'GBR' && partnerSettings?.SET_INVESTOR_TYPE ? (
          <SelectorInput
            label="Investor type"
            placeholder="Select investor type"
            name="investorType"
            options={[
              {
                label: 'High Net Worth Investor',
                value: InvestorType.HIGH_NET_WORTH,
              },
              {
                label: 'Sophisticated Investor',
                value: InvestorType.SOPHISTICATED,
              },
            ]}
            onChange={(newValue?: { value: InvestorType }) => {
              changeFormData((prevState) => ({
                ...prevState,
                investorType: newValue?.value,
              }));
            }}
          />
        ) : (
          partnerSettings?.SET_INVESTOR_TYPE && (
            <CheckboxInput
              {...createInputControl('accredited')}
              label={<Paragraph>Tick if the investor is accredited</Paragraph>}
            />
          )
        )}
      </FormGroup>

      <FormDivider />

      {partnerSettings?.ASSIGN_CLIENT_ASSETS ? (
        <FormGroup>
          <Heading level="h5">Assign investments</Heading>
          <Paragraph>
            Configure which investments your client will be able to see and
            invest in.
          </Paragraph>
          <AssetSelectorInput
            name="assets"
            countryCode={formData.country}
            onChange={(ids: UUID[]) => {
              changeFormData((prevState) => ({
                ...prevState,
                assetRoundClassIds: ids,
              }));
            }}
          />
        </FormGroup>
      ) : (
        <FormGroup>
          <Paragraph>
            Information below can be added now or when purchasing a token.
          </Paragraph>
          <DateInput
            label="Date of Birth"
            {...createInputControl('dateOfBirth')}
            onChange={(date) =>
              changeFormData((state) => ({
                ...state,
                dateOfBirth: date.toISOString(),
              }))
            }
          />
          <TextInput
            label="Address Line 1"
            placeholder="Type the address"
            {...createInputControl('addressLine1')}
          />
          <TextInput
            label="Address Line 2"
            placeholder="Floor, apartment, etc."
            {...createInputControl('addressLine2')}
          />
          <FormRow>
            <TextInput
              label="City"
              placeholder="Type the city"
              {...createInputControl('town')}
            />
            <TextInput
              label="Postcode"
              placeholder="ABC 123"
              {...createInputControl('postcode')}
            />
          </FormRow>
          <SelectorInput
            label="Client funding source"
            placeholder="Select an option"
            name="sourceOfFunds"
            options={[
              { value: SourceOfFunds.SALARY, label: 'Salary' },
              { value: SourceOfFunds.SAVINGS, label: 'Savings' },
              { value: SourceOfFunds.PENSION, label: 'Pension' },
              {
                value: SourceOfFunds.INHERITANCE_AND_GIFTS,
                label: 'Inheritance and gifts',
              },
              {
                value: SourceOfFunds.BUSINESS_PROFITS,
                label: 'Business profits',
              },
              {
                value: SourceOfFunds.INVESTMENT_PROCEEDS,
                label: 'Investment proceeds',
              },
              { value: SourceOfFunds.BANK_LOAN, label: 'Bank loan' },
            ]}
            onChange={(newValue?: { value: SourceOfFunds }) => {
              changeFormData((prevState) => ({
                ...prevState,
                sourceOfFunds: newValue?.value,
              }));
            }}
          />
          <FormRow>
            <SelectorInput
              label="Tax resident in"
              placeholder="Select country"
              name="taxResidency"
              options={
                partnerSettings?.countries.length > 0
                  ? partnerSettings.countries
                  : // fallback to default countries
                    [
                      { value: 'GBR', label: '🇬🇧 United Kingdom' },
                      { value: 'USA', label: '🇺🇸 United States' },
                      { value: 'CHE', label: '🇨🇭 Switzerland' },
                    ]
              }
              onChange={(newValue?: { value: string }) => {
                changeFormData((prevState) => ({
                  ...prevState,
                  taxResidency: newValue?.value,
                }));
              }}
            />
            <TextInput
              label={
                formData.taxResidency === 'USA' ? 'Tin Number' : 'NI Number'
              }
              placeholder={
                formData.taxResidency === 'USA'
                  ? 'AB123456C'
                  : 'Type the NI Number'
              }
              {...createInputControl('taxIdentifier')}
            />
          </FormRow>

          <FormDivider />

          <CheckboxInput
            {...createInputControl('ownedKYC')}
            align="top"
            label={
              <Paragraph>
                I agree that KYC will be completed by {props.partnerName} and
                all clients are qualified to make investments.
              </Paragraph>
            }
          />
          <CheckboxInput
            {...createInputControl('sendInvitationEmail')}
            align="top"
            label={
              <>
                <Paragraph>
                  Send {formData.firstName} an invite to create an account.
                </Paragraph>
                <Paragraph small>
                  You can sent this later by going into {formData.firstName}’s
                  client profile
                </Paragraph>
              </>
            }
          />
        </FormGroup>
      )}
    </Form>
  );
};
