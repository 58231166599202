import { createContext, ReactNode, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { ContentDownloadCardsBlockItem } from '@hedgehog/shared/types';

type Context = {
  onDocumentClick?: (document: ContentDownloadCardsBlockItem) => void;
};

export const CMSDocumentClickContext = createContext<Context>({});

export function useCMSDocumentClick(): Context {
  return useContext(CMSDocumentClickContext);
}

type CMSDocumentClickProviderProps = {
  onDocumentClick: Context['onDocumentClick'];
  children?: ReactNode | ReactNode[];
};

export const CMSDocumentClickProvider = ({
  onDocumentClick,
  children,
}: CMSDocumentClickProviderProps): JSX.Element => {
  return (
    <CMSDocumentClickContext.Provider
      value={{
        onDocumentClick,
      }}
    >
      {children || <Outlet />}
    </CMSDocumentClickContext.Provider>
  );
};
