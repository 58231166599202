import styled from 'styled-components';

import { ContentMetricsBlock } from '@hedgehog/shared/types';
import { Card } from '@hedgehog/ui/cards';
import { MarkdownText } from '@hedgehog/ui/typography';

import { Highlight } from './highlight.component';

const StyledMetrics = styled.div`
  display: flex;
  flex: 1;
  gap: 1rem;
  justify-content: space-between;
`;

type CMSMetricsBlockProps = {
  block: ContentMetricsBlock;
};

export const CMSMetricsBlock = ({
  block,
}: CMSMetricsBlockProps): JSX.Element => {
  const { items } = block;
  return (
    <Card>
      <StyledMetrics>
        {items.map(({ title, value, tooltip }) => (
          <Highlight
            key={title}
            title={title}
            description={value}
            tooltip={
              tooltip
                ? {
                    title: tooltip.title,
                    description: <MarkdownText>{tooltip.content}</MarkdownText>,
                  }
                : undefined
            }
          />
        ))}
      </StyledMetrics>
    </Card>
  );
};
