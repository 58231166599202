import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { baseApi, logout } from '@hedgehog/data-access/partners';

export const LogoutPage = (): JSX.Element => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(baseApi.util.resetApiState());
    dispatch(logout());
  }, []);

  return <Navigate to="/" />;
};
