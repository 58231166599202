import { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

import { screens } from '@hedgehog/utils/sizes';

const StyledGalleryImage = styled.div<{ imageUrl: string }>`
  background: url(${({ imageUrl }): string => imageUrl}) no-repeat center;
  background-size: cover;
  width: 100%;
  max-width: 19.5rem;
  height: 11rem;
  margin-right: 0.5rem;
  border-radius: 1rem;

  &:last-child {
    margin-right: 0;
  }

  @media (min-width: ${screens.medium}px) {
    max-width: 25rem;
    height: 14.0625rem;
  }
`;

type GalleryImageProps = {
  src?: string;
  onClick?: () => void;
  className?: string;
  'data-testid'?: string;
};

export const GalleryImage = forwardRef(
  (
    { src, className, onClick, ...otherProps }: GalleryImageProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <StyledGalleryImage
        ref={ref}
        imageUrl={src || ''}
        onClick={onClick}
        className={className}
        data-testid={otherProps['data-testid']}
      />
    );
  },
);
