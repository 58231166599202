import styled from 'styled-components';

import { PrimaryButton } from '@hedgehog/ui/buttons';
import { StandardProps } from '@hedgehog/ui/utils';

import {
  MarketTransferAction,
  MarketTransferStatus,
  MarketTransferStep,
} from '../../types';

import { ActionItem } from './market-transfer-action.component';

const TermsAndConditions = (props: MarketTransferStep) => {
  const buyerOrSeller =
    props.action === MarketTransferAction.BuyerTermsAndConditions
      ? 'Buyer'
      : 'Seller';
  const date = props.completedAt?.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  const name = `${props.actor?.firstName} ${props.actor?.lastName}`;
  return (
    <ActionItem
      icon="terms"
      status={props.status}
      title={`${buyerOrSeller} signed transfer documents`}
      description={`Signed ${date} by ${name} on behalf of the client`}
    />
  );
};

const AssetInEscrow = (props: MarketTransferStep) => {
  return (
    <ActionItem icon="lock" status={props.status} title="Assets in escrow" />
  );
};

const SettlePaymentButton = styled(PrimaryButton)`
  margin-right: -1rem;
`;

const PaymentSettled = (
  props: MarketTransferStep & {
    onSettlePaymentClicked?: () => void;
    hasSettledPayment?: boolean;
    disabled?: boolean;
    isLoading?: boolean;
  },
) => {
  return (
    <ActionItem
      icon="money"
      status={props.status}
      title="Payment settled"
      inlineButton={
        <SettlePaymentButton
          small
          onClick={props.onSettlePaymentClicked}
          disabled={!props.onSettlePaymentClicked || props.disabled}
          loading={
            props.isLoading || props.status === MarketTransferStatus.InProgress
          }
        >
          {props.hasSettledPayment ? 'Settled' : 'Settle now'}
        </SettlePaymentButton>
      }
    />
  );
};

const TransferFinalised = (props: MarketTransferStep) => {
  return (
    <ActionItem
      icon="portfolio"
      status={props.status}
      title="Transfer of assets finalised"
    />
  );
};

const ToActionItem = (
  props: MarketTransferStep & {
    isLoading?: boolean;
    disabled?: boolean;
    hasSettledPayment?: boolean;
    onSettlePaymentClicked?: () => void;
  },
) => {
  switch (props.action) {
    case MarketTransferAction.BuyerTermsAndConditions:
    case MarketTransferAction.SellerTermsAndConditions:
      return <TermsAndConditions {...props} />;
    case MarketTransferAction.AssetInEscrow:
      return <AssetInEscrow {...props} />;
    case MarketTransferAction.PaymentSettled:
      return (
        <PaymentSettled
          onSettlePaymentClicked={props.onSettlePaymentClicked}
          hasSettledPayment={props.hasSettledPayment}
          {...props}
        />
      );

    case MarketTransferAction.TransferFinalised:
      return <TransferFinalised {...props} />;
    default:
      return null;
  }
};

const Separator = styled.hr`
  ${({ theme }) => ({
    border: `1px solid ${theme.colors.grey100}`,
    borderBottom: 'none',
    margin: '0.5rem 1rem',
  })}

  &:last-child {
    display: none;
  }
`;

export type MarketTransferActionsProps = StandardProps<
  {
    actions: MarketTransferStep[];
    onSettlePaymentClicked?: () => void;
    isLoading?: boolean;
    disabled?: boolean;
  },
  never
>;

export const MarketTransferActions = ({
  actions,
  isLoading = false,
  disabled = false,
  onSettlePaymentClicked,
}: MarketTransferActionsProps) => {
  const stepCompletedCount = actions.reduce(
    (acc, action) =>
      action.status === MarketTransferStatus.Success ? acc + 1 : acc,
    0,
  );
  const canSettlePayment = stepCompletedCount === 3;
  const hasSettledPayment = stepCompletedCount >= 4;

  return (
    <>
      {actions.map((action, index) => (
        <>
          <ToActionItem
            key={index}
            isLoading={isLoading}
            disabled={disabled}
            onSettlePaymentClicked={
              canSettlePayment ? onSettlePaymentClicked : undefined
            }
            hasSettledPayment={hasSettledPayment}
            {...action}
          />
          <Separator />
        </>
      ))}
    </>
  );
};
