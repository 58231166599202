import { ReactNode } from 'react';

import { useUserHasCapabilities } from '@hedgehog/data-access/partners';
import { RoleCapability } from '@hedgehog/shared/types';

type RestrictProps = {
  capability: RoleCapability;

  // If the user has the required capability, the children will be rendered
  children?: ReactNode | ReactNode[];

  // If the user does not have the required capability, the alternate will be rendered
  alternate?: ReactNode;
};

export const Restrict = ({
  capability,
  alternate = null,
  children,
}: RestrictProps): JSX.Element | null => {
  const ok = useUserHasCapabilities(capability);
  if (ok === undefined) return null;
  return <>{ok ? children : alternate}</>;
};
