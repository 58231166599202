export interface CognitoTokenResponse {
  id_token: string;
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export interface ExchangeCognitoAuthCodeParams {
  appUrl: string;
  redirectUrl: string;
  cognitoClientId: string;
  cognitoUserPoolUrl: string;
  cognitoAuthCode: string;
}

export const exchangeCognitoAuthCode = async (
  params: ExchangeCognitoAuthCodeParams,
): Promise<CognitoTokenResponse> => {
  const body = new URLSearchParams();
  body.append('grant_type', 'authorization_code');
  body.append('code', params.cognitoAuthCode);
  body.append('client_id', params.cognitoClientId || '');
  body.append('redirect_uri', `${params.appUrl}/${params.redirectUrl}`);

  const response = await fetch(`${params.cognitoUserPoolUrl}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body,
  });
  const payload = await response.json();

  if (`${response.status}`.startsWith('4')) {
    throw new Error(`CognitoAuthCodeError: ${payload.error}`);
  }

  return payload as CognitoTokenResponse;
};
