import styled from 'styled-components';

import { BulletListItem, BulletListItemProps } from '@hedgehog/ui/lists';
import { Label } from '@hedgehog/ui/typography';

const ItemLabel = styled(Label)`
  margin: 0;
`;

const ItemValue = styled.p`
  font-size: 1rem;
  letter-spacing: -0.01em;
  line-height: 1.125rem;
  font-weight: 600;
  margin: 0;
`;

type ClientDataListItemProps = {
  icon: BulletListItemProps['icon'];
  label: string;
  value?: string | number | null;
};

export const ClientDataListItem = ({
  icon,
  label,
  value,
}: ClientDataListItemProps): JSX.Element => {
  return (
    <BulletListItem icon={icon}>
      <ItemLabel>{label}</ItemLabel>
      <ItemValue>{value}</ItemValue>
    </BulletListItem>
  );
};
