import { Outlet, useParams, useSearchParams } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useClientQuery } from '@hedgehog/data-access/partners';
import { useAppData } from '@hedgehog/data-access/partners';
import { CardContent } from '@hedgehog/ui/cards';
import { Tabs } from '@hedgehog/ui/tabs';
import { Widget, WidgetContent } from '@hedgehog/ui/widgets';

import { ClientName, TabLink } from '../../components';
import { ClientHeaderActions, ClientHeaderTags } from '../../containers';

const ClientWidget = styled(Widget)`
  height: 100%;
  & > ${CardContent} {
    padding-bottom: 0;
  }

  ${WidgetContent} {
    display: flex;
    flex-flow: column nowrap;
    max-height: 100%;
    overflow: hidden;
  }
`;

const TabContent = styled.div`
  flex: 1 1;
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
  margin: 0 -1.5rem;
`;

const TabInnerContent = styled.div`
  padding: 2.5rem 1.5rem 0;
  display: block;
`;

const TabsContainer = styled.div`
  flex: 0 0;
  padding: 1.25rem 1.875rem;
  // counters breadcrumbs padding
  margin: 0 -1.875rem;
  border-bottom: 1px solid;
  ${({ theme }): CSSProp => ({
    borderBottomColor: theme.colors.grey200,
  })}
`;

const HeaderActionsWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  ${({ theme }) => ({ gap: theme.spacing.xsmall })}
`;

export const ClientPage = (): JSX.Element | null => {
  const { clientId } = useParams();
  const { activePartner } = useAppData();
  const { data: client } = useClientQuery(
    {
      partnerId: activePartner?.id || '',
      clientId: clientId || '',
    },
    {
      skip: !activePartner,
    },
  );
  const [searchParams] = useSearchParams();

  if (!client?.user) {
    return null;
  }

  return (
    <ClientWidget
      title={
        <ClientName
          level="h4"
          canInvest={client.canInvest}
          firstName={client.user.firstName}
          lastName={client.user.lastName}
        />
      }
      actions={
        <HeaderActionsWrapper>
          <ClientHeaderTags clientId={clientId ?? ''} />
          <ClientHeaderActions clientId={clientId ?? ''} />
        </HeaderActionsWrapper>
      }
    >
      <TabsContainer>
        <Tabs small>
          <TabLink
            to={`/clients/${clientId}/profile?${searchParams.toString()}`}
          >
            Profile
          </TabLink>
          <TabLink
            to={`/clients/${clientId}/timeline?${searchParams.toString()}`}
          >
            Timeline
          </TabLink>
          <TabLink
            to={`/clients/${clientId}/portfolio?${searchParams.toString()}`}
          >
            Portfolio
          </TabLink>
          <TabLink to={`/clients/${clientId}/kyc?${searchParams.toString()}`}>
            KYC
          </TabLink>
          <TabLink
            to={`/clients/${clientId}/secondary-market?${searchParams.toString()}`}
          >
            Secondary Market
          </TabLink>
        </Tabs>
      </TabsContainer>
      <TabContent>
        <TabInnerContent>
          <Outlet />
        </TabInnerContent>
      </TabContent>
    </ClientWidget>
  );
};
