export const FlagIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0V15H2V9H6L8 11H14V2H8L6 0H0Z" fill="currentColor" />
  </svg>
);

export default FlagIcon;
