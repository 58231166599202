import styled from 'styled-components';

import { RadioInput, RadioInputGroup } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm } from '../../../../components';
import {
  useMarketListingBalance,
  useMarketListingParams,
  useNavigateToListingPage,
} from '../../../../containers';

const TextGroup = styled.div`
  display: block;
`;

export const MarketListingPositionRatioPage = (): JSX.Element => {
  const [balance] = useMarketListingBalance();
  const [params, updateParams] = useMarketListingParams();
  const { navigateTo } = useNavigateToListingPage();

  const step = 0.1;
  const min = 0.5;
  const steps = new Array(1 / step / (1 / min) + 1)
    .fill(step)
    .map((_, index) => step * index + min)
    .sort((a, b) => b - a);

  const handleRadioChange = (
    checked: string[],
    values: Record<string, string>,
  ) => {
    const [ratio] = checked.map((checkedName) => values[checkedName]);
    if (!ratio) return;

    updateParams({ positionRatio: Number(ratio) });
  };

  return (
    <ModalForm
      heading="Position you want to sell"
      submitButtonText="Confirm"
      submitModal={() => navigateTo('create/position')}
      backModal={() => navigateTo('create/position')}
      closeModal={() => close()}
    >
      <Paragraph small>
        Selling less than 50% is not permitted for this investment
      </Paragraph>

      <RadioInputGroup
        onChange={handleRadioChange}
        defaultChecked={[`step-${params.positionRatio * 100}`]}
      >
        {steps.map((_step) => (
          <RadioInput
            key={`input-step-${_step * 100}`}
            id={`input-step-${_step * 100}`}
            value={`${_step}`}
            name={`step-${_step * 100}`}
          >
            <TextGroup>
              <Paragraph weight="700">{_step * 100}%</Paragraph>
              <Paragraph>
                {currencies.formatMoney((balance.commitment ?? 0) * _step)}
              </Paragraph>
            </TextGroup>
          </RadioInput>
        ))}
      </RadioInputGroup>
    </ModalForm>
  );
};
