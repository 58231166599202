import { ReactNode } from 'react';
import styled from 'styled-components';

const PageTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-bottom: 6rem;
`;

type AssetPageTabProps = {
  children?: ReactNode;
  className?: string;
};

export const AssetPageTab = ({ children, className }: AssetPageTabProps) => {
  return <PageTab className={className}>{children}</PageTab>;
};
