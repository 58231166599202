export const CircleCheck = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="currentColor" />
    <path
      d="M22.7099 11.21C22.617 11.1163 22.5064 11.0419 22.3845 10.9911C22.2627 10.9403 22.132 10.9142 21.9999 10.9142C21.8679 10.9142 21.7372 10.9403 21.6154 10.9911C21.4935 11.0419 21.3829 11.1163 21.29 11.21L13.84 18.67L10.7099 15.53C10.6134 15.4367 10.4995 15.3634 10.3746 15.3142C10.2498 15.265 10.1165 15.2409 9.98227 15.2432C9.84809 15.2455 9.71568 15.2743 9.5926 15.3278C9.46953 15.3812 9.35819 15.4585 9.26495 15.555C9.17171 15.6515 9.0984 15.7654 9.04919 15.8903C8.99999 16.0152 8.97586 16.1485 8.97818 16.2827C8.9805 16.4168 9.00923 16.5493 9.06272 16.6723C9.11622 16.7954 9.19343 16.9067 9.28995 17L13.13 20.84C13.2229 20.9337 13.3335 21.0081 13.4554 21.0589C13.5772 21.1096 13.7079 21.1358 13.84 21.1358C13.972 21.1358 14.1027 21.1096 14.2245 21.0589C14.3464 21.0081 14.457 20.9337 14.55 20.84L22.7099 12.68C22.8115 12.5863 22.8925 12.4727 22.9479 12.3462C23.0033 12.2197 23.0319 12.0831 23.0319 11.945C23.0319 11.8069 23.0033 11.6703 22.9479 11.5438C22.8925 11.4173 22.8115 11.3036 22.7099 11.21Z"
      fill="white"
    />
  </svg>
);

export default CircleCheck;
