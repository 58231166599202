import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import {
  useAppData,
  useClientsQuery,
  useFundsBlockchainQuery,
  useFundsQuery,
  useIssueCapitalCall,
  useLimitedPartnersLazyBlockchainQuery,
} from '@hedgehog/data-access/partners';
import { CapitalCallNoticeIssuedDataSchema } from '@hedgehog/shared/blockchain-types';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { SelectInput, TextInput } from '@hedgehog/ui/inputs';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { Widget, WidgetGrid } from '@hedgehog/ui/widgets';
import { currencies } from '@hedgehog/utils/formats';

import {
  CapitalCallRecipientsTable,
  RecipientProps,
} from '../../../components';
import { CapitalCallNoticeContent } from '../../../containers';

interface CreateCapitalCallFormData {
  hedgehogFundId?: string;
  amount: number;
}

type Fund = {
  blockchainId: number;
  id: string;
  name: string;
  ref: string;
};

const initialFormData = {
  hedgehogFundId: undefined,
  amount: 0,
};

export const CreateCapitalCallsPage = () => {
  const navigate = useNavigate();

  // State for form data and capital call notice data
  const [formData, setFormData] =
    useState<CreateCapitalCallFormData>(initialFormData);
  const [data, setData] = useState<CapitalCallNoticeIssuedDataSchema>({
    email: { from: 'no-reply@hedgehog-invest.com', send: true },
    notice: {
      subject: 'Capital call for {fund.name}',
      content: `As part of your commitment to {fund.name} we kindly request your participation in the upcoming capital call. This investment's total capital call amount is {call.totalAmount}, and we appreciate your dedication to fulfilling your share, which amounts to {call.amount}.

We kindly request that you complete your investment amount no later than {call.dueDate}.`,
    },
  });

  const [formattedAmount, setFormattedAmount] = useState<string>('');

  // Hook for issuing capital calls and getting error/result
  const [issueCapitalCall, { loading }] = useIssueCapitalCall();

  // Hook for loading limited partners from the blockchain
  const { fetch: loadLimitedPartners, data: recipients } =
    useLimitedPartnersLazyBlockchainQuery();

  // Logic to determine if form is valid for submission
  const isValidForSubmit = Boolean(
    formData.hedgehogFundId &&
      formData.amount &&
      data?.notice.content &&
      data?.notice.subject,
  );

  const { activePartner } = useAppData();

  const { data: clients } = useClientsQuery(
    {
      partnerId: activePartner ? activePartner.id : '',
      limit: 100,
      offset: 0,
    },
    {
      skip: !activePartner,
    },
  );

  const { data: funds } = useFundsQuery({
    partnerId: activePartner?.id || '',
  });

  const { data: blockchainFunds } = useFundsBlockchainQuery();

  const [filteredClients, setFilteredClients] = useState<RecipientProps[]>([]);

  useEffect(() => {
    // Load limited partners when hedgehogFundId changes
    if (formData.hedgehogFundId) {
      // find the fund data
      const fund = funds?.find((f) => f.id === formData.hedgehogFundId);

      loadLimitedPartners({
        fundId:
          // get the fundId by finding the blockchain fund that matches the hashedBlockchainReference
          blockchainFunds?.find(
            (f) => f.hedgehogFundRef === fund?.hashedBlockchainReference,
          )?.id || 1,
      });
    }
  }, [formData.hedgehogFundId]);

  useEffect(() => {
    // Update filtered clients when recipients or clients data changes
    if (recipients && clients) {
      setFilteredClients(
        recipients
          .map((recipient) => {
            const client = clients.data?.find(
              (c) => c.codeHash === recipient.hash,
            );
            return {
              id: client?.id || '',
              name: client?.user?.firstName + ' ' + client?.user?.lastName,
              committed: recipient.commitment,
              drawn: recipient.drawn,
              due:
                (recipient.commitment /
                  recipients.reduce((a, b) => a + b.commitment, 0)) *
                formData.amount,
            };
          })
          // TODO: This is just for dev/testing where we might end up with investors on the blockchain
          // that arn't in the database, we would remove this in production
          .filter((client) => client.id !== ''),
      );
    }
  }, [recipients, clients, formData]);

  // TODO: This is just for dev/testing where we might end up with funds on the blockchain
  // that arn't in the database, we would remove this in production
  const [filteredFunds, setFilteredFunds] = useState<Fund[]>([]);

  useEffect(() => {
    if (funds && blockchainFunds) {
      setFilteredFunds(
        blockchainFunds
          .map((blockchainFund) => {
            const fund = funds?.find((f) => {
              return (
                f.hashedBlockchainReference === blockchainFund.hedgehogFundRef
              );
            });
            return {
              blockchainId: blockchainFund.id,
              id: fund?.id || '',
              name: fund?.name || '',
              ref: blockchainFund.hedgehogFundRef,
            };
          })
          .filter((fund) => fund.id !== ''),
      );
    }
  }, [funds, blockchainFunds]);

  const handleAmountChange = (amount: string): void => {
    const cleanAmount = amount.replace('$', '').replace(/,/g, '');
    if (isNaN(Number(cleanAmount))) {
      return;
    }
    setFormData({ ...formData, amount: Number(cleanAmount) });
    setFormattedAmount(
      currencies.formatMoney(Number(cleanAmount), {
        maximumFractionDigits: 0,
      }),
    );
  };

  const handleSubmit = async (): Promise<void> => {
    // todo: validate data
    if (!data) {
      toast(
        <>
          <Heading level="h7">Could not issue capital call</Heading>
          <Paragraph small color="grey500">
            Fill out the form before submitting.
          </Paragraph>
        </>,
      );
      return;
    }

    const { amount } = formData;
    issueCapitalCall({
      fundRef:
        filteredFunds.find((f) => f.id === formData.hedgehogFundId)?.ref || '',
      // fundId refers to the fundId as defined on the blockchain
      fundId:
        filteredFunds.find((f) => f.id === formData.hedgehogFundId)
          ?.blockchainId || 0,
      amount,
      // data is a string that can be used to pass additional data to the blockchain, this could be JSON or any other string
      data,
    });
    navigate('/funds/capital-calls');
  };

  return (
    <PageColumnLayout
      title="Capital calls"
      actions={
        <PrimaryButton
          onClick={() => handleSubmit()}
          disabled={!isValidForSubmit}
          loading={loading}
        >
          Send capital call
        </PrimaryButton>
      }
    >
      <WidgetGrid>
        <Widget title="Call details" small>
          <SelectInput
            label="FUND"
            options={filteredFunds || []}
            renderKey={({ id }) => id}
            renderValue={({ id }) => id}
            renderOption={({ name }) => <span>{name}</span>}
            onChange={(hedgehogFundId) =>
              setFormData({ ...formData, hedgehogFundId })
            }
          />
          <TextInput
            name="amount"
            label="AMOUNT"
            value={formattedAmount}
            onChange={(amount) => handleAmountChange(amount)}
          />
        </Widget>
        <Widget medium title="Custom notice">
          <CapitalCallNoticeContent
            defaultValues={data}
            onDataChange={(changedData) => setData(changedData)}
          />
        </Widget>
        <Widget title="Recipients">
          {formData.hedgehogFundId === undefined && (
            <Paragraph>Choose a fund above to view recipients</Paragraph>
          )}
          {formData.hedgehogFundId !== undefined && recipients && (
            <CapitalCallRecipientsTable recipients={filteredClients} />
          )}
        </Widget>
      </WidgetGrid>
    </PageColumnLayout>
  );
};
