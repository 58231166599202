import { Address } from 'viem';

import { UseFundWriteReturn, useFundWrite } from './core';

export interface UseCreateBidProps {
  fundAddress: Address;
  listingId: bigint;
  price: bigint;
  enabled?: boolean;
}

export const useCreateBid = ({
  fundAddress,
  listingId,
  price,
  enabled = true,
}: UseCreateBidProps): UseFundWriteReturn => {
  return useFundWrite({
    address: fundAddress,
    functionName: 'createBid',
    scopeKey: `funds/listings`,
    args: [listingId, price],
    enabled,
  });
};
