import styled, { CSSObject } from 'styled-components';

import { Theme } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

export const Table = styled.table`
  ${({ theme }): CSSObject => ({
    border: `1px solid ${theme.colors.grey100}`,
    borderSpacing: '0',
    borderRadius: '0.625rem',
    thead: {
      color: theme.colors.black,
      backgroundColor: theme.colors.grey100,
      fontWeight: 'normal',
      textAlign: 'left',
      'th:first-child': {
        borderTopLeftRadius: '0.625rem',
      },
      'th:last-child': {
        borderTopRightRadius: '0.625rem',
      },
      th: {
        padding: `${theme.spacing.xsmall} ${theme.spacing.normal}`,
      },
    },
    tbody: {
      borderBottomRadius: '1rem',
      'tr:last-child td:first-child': {
        borderBottomLeftRadius: '1rem',
      },
      'tr:last-child td:nth-last-child(2)': {
        borderBottomRightRadius: '1rem',
      },
    },
    td: {
      fontSize: theme.typography.body_small.fontSize,
      padding: theme.spacing.normal,
      borderBottom: `1px solid ${theme.colors.grey100}`,
    },
    tr: {
      ':last-child': {
        td: {
          borderBottom: 'none',
        },
      },
    },
  })}
`;

export const TableHeader = styled.th``;

const getColor = ({
  theme,
  error,
  success,
}: {
  theme: Theme;
  error?: boolean;
  success?: boolean;
}) => {
  if (error) return theme.colors.errorLight;
  if (success) return `color-mix(in srgb, ${theme.colors.success} 25%, white)`;
  return 'none';
};

export type TableColumnCellProps = StandardProps<{
  success?: boolean;
  error?: boolean;
}>;

export const TableData = styled.td<TableColumnCellProps>`
  ${({ error, theme, success }) => ({
    backgroundColor: `${getColor({
      error,
      theme,
      success,
    })} !important`,
    '& > ul': {
      fontSize: theme.typography.note.fontSize,
      listStyle: 'none',
      padding: 0,
      margin: 'auto',
    },
  })}
`;
