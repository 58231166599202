import { gql } from '@apollo/client';

export const GET_AVAILABLE_ASSETS = gql`
  query GetAvailableAssets($status: [AssetRoundClassStatus!]!) {
    availableAssets(args: { status: $status }) {
      id
      path
      underlyingAssetCount
      title
      status
      price
      currency
      minimumInvestmentAmount
      fundedAt
      isTenantToken
      order {
        id
        status
        quantity
      }
      thumbnails {
        src
        kind
        alt
        thumbnailSrc
      }
      themes {
        category
      }
    }
  }
`;
