import styled, { CSSObject } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

import { BrandLogo } from './brand-logo.component';
import { BrandName } from './brand-name.component';

const LogotypeBrandLogo = styled(BrandLogo)`
  flex: 0 0;
  max-height: 100%;
`;

const LogotypeBrandName = styled(BrandName)`
  flex: 0 0 auto;
  @media screen and (max-width: 1023px) {
    display: none;
    visibility: hidden;
  }
`;

const LogotypeWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  // You can scale logotype by just changing fontSize
  font-size: 0.75rem;

  ${({ theme }): CSSObject => ({
    gap: theme.spacing.xsmall,
  })};

  ${LogotypeBrandLogo} {
    flex: 0 0 auto;
    min-height: 1.5em;
    max-height: 1.5em;
    min-width: auto;
    max-width: auto;
  }

  ${LogotypeBrandName} {
    flex: 0 0 auto;
    margin-bottom: -0.5em;
  }
`;

type LogotypeProps = StandardProps<object, never>;

export const Logotype = styled(({ className }: LogotypeProps): JSX.Element => {
  return (
    <LogotypeWrapper className={className}>
      <LogotypeBrandLogo />
      <LogotypeBrandName />
    </LogotypeWrapper>
  );
})``;
