import styled, { CSSObject, css } from 'styled-components';

import { AssetCategory } from '@hedgehog/shared/types';
import { parseBorder } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { resolveAssetCategoryIconType } from '../AssetCategoryIcon/AssetCategoryIcon';
import { Avatar } from '../avatar/avatar.component';
import { Chunk } from '../chunk/chunk.component';
import { IconType } from '../icons';

const applyMinMax = (size: string): CSSObject => ({
  minWidth: size,
  minHeight: size,
  maxWidth: size,
  maxHeight: size,
});

const LayeredAvatarBase = css`
  box-sizing: border-box;
  margin-left: -0.25rem;

  &:first-child {
    margin-left: 0;
  }

  & img {
    max-width: 100%;
  }
`;

export const PartnerAvatar = styled(Avatar)`
  ${LayeredAvatarBase}

  ${({ theme }): CSSObject => ({
    position: 'relative',
    border: parseBorder(theme.border.normal),
    borderColor: theme.colors.shadow100,
  })};
`;

export const ThemeAvatar = styled(Avatar)`
  ${LayeredAvatarBase}

  ${({ theme }): CSSObject => ({
    position: 'relative',
    border: parseBorder(theme.border.normal),
    borderColor: theme.colors.secondary,
  })};

  &:not(:first-of-type:last-of-type) {
    ${({ theme }): CSSObject => ({
      borderColor: theme.colors.white,
    })}
  }
`;

const LayeredIconsWrapper = styled.div<{ small?: boolean }>`
  display: flex;

  & > ${ThemeAvatar}, & > ${PartnerAvatar} {
    &:nth-child(odd):not(:first-child) {
      margin-left: -0.625rem;
    }

    &:nth-child(even) {
      z-index: 1;
      margin-top: 0.5rem;
      margin-left: -0.75rem;
    }

    &:first-child:last-child {
      margin-left: 0;
    }
  }

  & > ${ThemeAvatar}, & > ${PartnerAvatar} {
    ${({ small }): CSSObject => ({
      [`&:nth-child(odd)`]: small
        ? applyMinMax('1.25rem')
        : applyMinMax('1.625rem'),
      [`&:nth-child(even)`]: small
        ? applyMinMax('1.625rem')
        : applyMinMax('2rem'),
      [`&:first-child:last-child`]: small
        ? applyMinMax('2rem')
        : applyMinMax('2.5rem'),
    })}
  }

  & > ${ThemeAvatar} {
    ${({ small }): CSSObject => ({
      [`&:nth-child(odd) ${Chunk}`]: {
        width: small ? '0.5rem' : '0.625rem',
        height: small ? '0.5rem' : '0.625rem',
      },
      [`&:nth-child(even) ${Chunk}`]: {
        width: '0.875rem',
        height: '0.875rem',
      },
      [`&:first-child:last-child ${Chunk}`]: {
        width: small ? '0.75rem' : '1rem',
        height: small ? '0.75rem' : '1rem',
      },
    })}
  }
`;

type ImageLike = { src: string; alt?: string };

type LayeredIconsProps = StandardProps<
  {
    themes?: AssetCategory[];
    icons?: (ImageLike | IconType)[];
    maxIcons?: number;
    small?: boolean;
  },
  never
>;

export const LayeredIcons = styled(
  ({
    icons,
    themes,
    maxIcons,
    small,
    className,
  }: LayeredIconsProps): JSX.Element => {
    const iconTypes: (ImageLike | IconType)[] = [
      ...(icons || []),
      ...(themes || []).map(resolveAssetCategoryIconType),
    ];

    const iconNodes = iconTypes
      .slice(0, maxIcons)
      .map((iconTypeOrImage, index) =>
        typeof iconTypeOrImage === 'string' ? (
          <ThemeAvatar
            key={`${iconTypeOrImage}_${index}`}
            icon={iconTypeOrImage}
            backgroundColor="secondary"
          />
        ) : (
          <PartnerAvatar
            key={`${iconTypeOrImage.src}_${index}`}
            src={iconTypeOrImage.src}
            backgroundColor="grey100"
          />
        ),
      );
    return (
      <LayeredIconsWrapper small={small} className={className}>
        {iconNodes}
      </LayeredIconsWrapper>
    );
  },
)``;
