import { gql } from '@apollo/client';

export const GET_USER_REFERRALS = gql`
  query GetUserReferrals {
    referrals {
      amount
      code
    }
  }
`;
