import { useQuery } from '@apollo/client';

import {
  FeatureFlagNames,
  GetFeatureFlagsQuery as GetFeatureFlags,
  GET_FEATURE_FLAGS,
} from '@hedgehog/data-access/graphql';
import { AnyFixLater } from '@hedgehog/shared/types';

type GetFeatureFlags_featureFlags = GetFeatureFlags['featureFlags'][0];

let flags: GetFeatureFlags_featureFlags[] = [];

const hasFeature = (feature: FeatureFlagNames): boolean => {
  const flag = flags.find((flag) => flag.name === feature);
  return flag?.enabled || false;
};

const getFeature = (featureName: FeatureFlagNames): Feature => {
  const flag = flags.find((flag) => flag.name === featureName);
  const feature = {
    isEnabled: flag?.enabled || false,
  };
  if (featureName === FeatureFlagNames.asset_countdown) {
    const payload = JSON.parse(flag?.value || '{}');
    const value = typeof payload === 'string' ? JSON.parse(payload) : payload;
    return { ...feature, value };
  }
  return {
    ...feature,
    value: JSON.parse(flag?.value || '{}'),
  };
};

export interface Feature {
  isEnabled: boolean;
  value?: string | number | string[] | number[] | AnyFixLater;
}

interface UseFeatureToggle {
  hasFeature: (feature: FeatureFlagNames) => boolean;
  getFeature: (feature: FeatureFlagNames) => Feature;
  flags: GetFeatureFlags_featureFlags[];
}

export const useFeatureToggle = (): UseFeatureToggle => {
  const { data } = useQuery<GetFeatureFlags>(GET_FEATURE_FLAGS);

  flags = data?.featureFlags || [];

  return {
    hasFeature,
    getFeature,
    flags,
  };
};

export const useFeature = (featureName: FeatureFlagNames): Feature => {
  const { getFeature } = useFeatureToggle();
  return getFeature(featureName);
};

export const useFeatureFlag = (featureName: FeatureFlagNames): boolean => {
  const { hasFeature } = useFeatureToggle();
  return hasFeature(featureName);
};

export default useFeatureToggle;
