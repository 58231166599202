import { createSelector } from '@reduxjs/toolkit';

import { teamMembersApi } from './team-members.api';

export const selectTeamMemberResult = teamMembersApi.endpoints.whoami.select();

export const selectTeamMember = createSelector(
  selectTeamMemberResult,
  ({ data }) => {
    return data;
  },
);
