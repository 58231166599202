import { gql } from '@apollo/client';

export const GET_INCOME_REPORTS = gql`
  query GetIncomeReports($tokenAddress: String!) {
    incomeReports(tokenAddress: $tokenAddress) {
      id
      token
      beneficiary
      title
      description
      currency
      amount
      createdAt
    }
  }
`;
