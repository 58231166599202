import styled from 'styled-components';

import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

export type AssetCardCaptionProps = StandardProps<{
  label: string;
  value?: string;
}>;

export const AssetCardCaption = styled(
  ({
    label = 'Token price',
    value,
    className,
  }: AssetCardCaptionProps): JSX.Element => {
    return (
      <div className={className}>
        <Paragraph small color={value ? 'grey400' : 'black'}>
          {label}
        </Paragraph>
        {value && (
          <Heading level="h7" color="black">
            {value}
          </Heading>
        )}
      </div>
    );
  },
)`
  display: flex;
  flex-direction: column;
  justify-items: center;
`;
