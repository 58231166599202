import styled from 'styled-components';

import { ContentRisksAndMitigationsBlock } from '@hedgehog/shared/types';
import { VSpace } from '@hedgehog/ui/layouts';
import { List, BulletListItem } from '@hedgehog/ui/lists';
import { Heading, MarkdownText } from '@hedgehog/ui/typography';

import { CMSTitleBodyBlock } from '../cms-title-body-block/cms-title-body-block.component';

const RiskItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`;

type CMSRisksAndMitigationsBlockProps = {
  block: ContentRisksAndMitigationsBlock;
};

export const CMSRisksAndMitigationsBlock = ({
  block,
}: CMSRisksAndMitigationsBlockProps): JSX.Element => {
  const { title, description, items } = block;
  return (
    <VSpace spacing="small">
      <CMSTitleBodyBlock
        block={{
          title,
          description,
        }}
      />
      <List>
        {items.map((entry, i) => (
          <BulletListItem key={entry.risk} icon={`${i + 1}`}>
            <RiskItem>
              <Heading level="h6" color="secondary">
                {entry.risk}
              </Heading>
              <Heading level="h6" color="grey400">
                {entry.mitigation}
              </Heading>
              <MarkdownText>{entry.mitigationDetails}</MarkdownText>
            </RiskItem>
          </BulletListItem>
        ))}
      </List>
    </VSpace>
  );
};
