import { Paragraph } from '@hedgehog/ui/typography';

type ConfirmationMessageProps = {
  welcomeEmailSent: boolean;
  firstName: string;
  email: string;
};

export const ConfirmationMessage = ({
  welcomeEmailSent,
  firstName,
  email,
}: ConfirmationMessageProps): JSX.Element => {
  if (!welcomeEmailSent)
    return (
      <>
        <Paragraph>
          A welcome email was <strong>not</strong> sent to {email}
        </Paragraph>
        <Paragraph>
          You can send it later from their profile:
          <br />
          Clients &gt; Profile &gt; Send welcome email
        </Paragraph>
      </>
    );

  return (
    <>
      <Paragraph>
        We sent {firstName} an invitation to create an account:{' '}
        <strong>{email}</strong>
      </Paragraph>
      <Paragraph>
        If they can&apos;t find it, they might want to check their spam folder.
      </Paragraph>
    </>
  );
};
