import styled, { CSSProp } from 'styled-components';

export const InputLabel = styled.label`
  font-size: 0.625rem;
  font-weight: 400;
  text-transform: uppercase;

  margin-bottom: 0.125rem;
  ${({ theme: { colors } }): CSSProp => ({
    color: colors.grey400,
  })}
`;
