import React, { ForwardedRef, ReactNode } from 'react';
import styled from 'styled-components';

import { Page } from '../Page/Page';

type LoadingContainerProps = {
  children: ReactNode;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoadingContainer = React.forwardRef(
  (
    { children, ...props }: LoadingContainerProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <Page ref={ref}>
        <Container {...props}>{children}</Container>
      </Page>
    );
  },
);
