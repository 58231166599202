import styled, { CSSObject } from 'styled-components';

export interface WidgetGridProps {
  children?: React.ReactNode | React.ReactNode[];
}

const WidgetGridWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto, 1fr);
  grid-template-rows: auto;
  grid-gap: 1rem;
`;

export const WidgetGrid = styled(
  ({ children, ...props }: WidgetGridProps): JSX.Element => {
    return <WidgetGridWrapper {...props}>{children}</WidgetGridWrapper>;
  },
)``;
