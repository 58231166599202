import styled, { CSSObject } from 'styled-components';

import { ContentHighlightCardBlock } from '@hedgehog/shared/types';
import { VSpace } from '@hedgehog/ui/layouts';
import { Heading, MarkdownText } from '@hedgehog/ui/typography';

import { CMSTitleBodyBlock } from '../cms-title-body-block/cms-title-body-block.component';

const ImageContainer = styled.div<{ imageUrl: string }>`
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  width: 5rem;
  height: 5rem;
  background-size: cover;

  ${({ imageUrl }): CSSObject => ({
    background: `url(${imageUrl}) no-repeat center`,
  })}
`;

const HighlightItem = styled.div`
  display: flex;
  gap: 1rem;
`;

type CMSHighlightCardBlockProps = {
  block: ContentHighlightCardBlock;
};

export const CMSHighlightCardBlock = ({
  block,
}: CMSHighlightCardBlockProps): JSX.Element => {
  const { title, description } = block;
  return (
    <VSpace spacing="small">
      <CMSTitleBodyBlock
        block={{
          title,
          description,
        }}
      />
      {block.items.map((item) => {
        return (
          <HighlightItem key={item.title}>
            <div>
              <ImageContainer imageUrl={item.imageUrl} />
            </div>
            <div>
              {item.title && <Heading level="h6">{item.title}</Heading>}
              {item.description && (
                <MarkdownText>{item.description}</MarkdownText>
              )}
            </div>
          </HighlightItem>
        );
      })}
    </VSpace>
  );
};
