import {
  ChangeEventHandler,
  ForwardedRef,
  HTMLAttributes,
  forwardRef,
} from 'react';
import { CSSProp, styled } from 'styled-components';

import { CardBase } from '@hedgehog/ui/cards';
import { shimmerGradient } from '@hedgehog/ui/themes';
import { Note } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { CurrencyCoreInput } from '../CurrencyInput/currency-core.input';

const RatioInputWrapper = styled.label<{ outline?: never }>`
  ${CardBase}

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  &:before {
    display: block;
    content: '$';
  }
`;

const Input = styled(CurrencyCoreInput)<{
  errors?: string[];
  loading?: boolean;
}>`
  height: auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 0;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;

  ${({ loading }): CSSProp => (loading ? shimmerGradient : {})}
  ${({ theme }): CSSProp => ({ ...theme.typography.h4 })}
`;

const RatioNote = styled(Note)``;

export type RatioInputProps = StandardProps<
  {
    loading?: boolean;
    base?: number;
    value?: number;
    ratioDecimals?: number;
    onChange?: (value: number) => void;
  } & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>
>;

export const RatioInput = styled(
  (
    {
      value = 0,
      base = value,
      onChange,
      className,
      ratioDecimals = 2,
      ...inputProps
    }: RatioInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const formatter = Intl.NumberFormat('en-US', {
      style: 'percent',
      minimumFractionDigits: 0,
      maximumFractionDigits: ratioDecimals,
    });

    const handleChange = (value: number) => {
      if (onChange) onChange(value);
    };

    return (
      <RatioInputWrapper className={className} htmlFor="ratio">
        <InputWrapper>
          <Input
            id="ratio"
            name="ratio"
            value={value}
            onChange={handleChange}
            style={{ width: `${`${value}`.length + 0.5}ch` }}
            {...inputProps}
          />
        </InputWrapper>
        <RatioNote>{formatter.format(value / base)}</RatioNote>
      </RatioInputWrapper>
    );
  },
)``;
