import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { formatEther } from 'viem';

import {
  getWalletIndex,
  useZeroDevWallet,
} from '@hedgehog/browser/shared/account-abstraction';
import { CardListItem } from '@hedgehog/browser/shared/funds/feature-marketplace';
import {
  useAppData,
  useBid,
  useClientQuery,
} from '@hedgehog/data-access/partners';
import { SecondaryButton, SecondaryLightButton } from '@hedgehog/ui/buttons';
import { Heading } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm } from '../../../../../components';
import {
  useMarketBidLoaders,
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';

export const MarketBidOverviewPage = (): JSX.Element => {
  const [params] = useMarketBidParams();
  const { areClientsLoading, areFundsLoading } = useMarketBidLoaders();
  const { navigateTo, close } = useNavigateToBidPage();
  const { activePartnerId } = useAppData();
  const { clientId } = useParams();
  const { data: client } = useClientQuery(
    {
      partnerId: activePartnerId || '',
      clientId: clientId || '',
    },
    {
      skip: !activePartnerId || !clientId,
    },
  );
  const { autoloadWallet } = useZeroDevWallet();

  const {
    data: bid,
    isLoading,
    isFetched,
  } = useBid({
    fundId: params.fundId || '',
    listingId: Number(params.listingId) || 0,
    bidId: Number(params.bidId) || 0,
  });

  useEffect(() => {
    if (!client) return;

    // Connect to the client's wallet
    autoloadWallet({
      index: BigInt(
        getWalletIndex({
          id: client.id,
          createdAt: client.createdAt,
        }),
      ),
    });
  }, [client]);

  return (
    <ModalForm
      heading="Bid"
      closeModal={() => close()}
      actions={
        <>
          <SecondaryLightButton onClick={() => navigateTo('reject')}>
            Reject offer
          </SecondaryLightButton>
          <SecondaryButton
            onClick={() => navigateTo('accept/terms-conditions')}
          >
            Accept offer
          </SecondaryButton>
        </>
      }
    >
      <CardListItem
        loading={
          !isFetched || isLoading || areClientsLoading || areFundsLoading
        }
        title={bid?.fundName || 'Placeholder'}
        subtitle={bid?.fundDescription || 'This is a placeholder description'}
        body={[
          [
            'Unfunded commitment',
            currencies.formatMoney(
              Number(formatEther(BigInt(bid?.commitment || 0))),
            ),
          ],
          [
            'Net Asset Value',
            currencies.formatMoney(
              Number(formatEther(BigInt(bid?.contribution || 0))),
            ),
          ],
        ]}
        footer={[
          <>
            <Heading level="h5">Asking price</Heading>
            <Heading level="h5" loading={isLoading}>
              {currencies.formatMoney(
                Number(formatEther(BigInt(bid?.listingPrice ?? 0))),
              )}
            </Heading>
          </>,
          <>
            <Heading level="h5">Bid price</Heading>
            <Heading level="h5" loading={isLoading}>
              {currencies.formatMoney(
                Number(formatEther(BigInt(bid?.bidPrice ?? 0))),
              )}
            </Heading>
          </>,
        ]}
      />
    </ModalForm>
  );
};
