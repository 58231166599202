import { ForwardedRef, forwardRef } from 'react';
import styled, { CSSObject, RuleSet } from 'styled-components';

import { Card, CardContent } from '@hedgehog/ui/cards';
import { ThinProgressBar } from '@hedgehog/ui/charts';
import {
  parseBorder,
  parsePadding,
  shimmerGradient,
} from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';

import { AssetCompactCardProps } from './asset-card.interface';
import { CardAction, CardBody, CardCaption, CardIcons } from './styles';

const CardInnerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;

  ${({ theme }): CSSObject => ({
    paddingLeft: theme.cards.padding.x,
    gap: theme.spacing.xsmall,
  })}
`;

const CardThumbnail = styled.div<{ src?: string; loading?: boolean }>`
  flex: 0 0 auto;
  width: 100%;
  height: 100%;
  background-position: center;

  ${({ src, theme }): CSSObject => ({
    backgroundSize: src ? 'cover' : 'contain',
    backgroundImage: src
      ? `url(${src})`
      : 'url(https://assets.hedgehog-invest.com/pattern.png)',
    maxWidth: `calc(5rem - ${theme.cards.padding.x})`,
    marginTop: `-${theme.cards.padding.y}`,
    marginLeft: `-${theme.cards.padding.x}`,
    padding: parsePadding(theme.cards.padding),
    paddingRight: 0,
    borderRight: parseBorder(theme.border.small),
  })}

  ${({ loading }): RuleSet<object> | '' => (loading ? shimmerGradient : '')}
`;

const CardProgress = styled(ThinProgressBar)`
  width: 100%;
`;

const CardTitle = styled.div`
  display: flex;
  flex-flow: column nowrap;
  ${({ theme }): CSSObject => ({ gap: theme.spacing.xsmall })}
`;

export const AssetCompactCard = styled(
  forwardRef(
    (
      {
        title,
        action,
        caption,
        body,
        thumbnails = [],
        categories: categoryOrCategories = [],
        partners: partnerOrPartners = [],
        loading = false,
        className,
        ...cardProps
      }: AssetCompactCardProps,
      ref: ForwardedRef<HTMLAnchorElement | HTMLDivElement>,
    ): JSX.Element => {
      const partners = Array.isArray(partnerOrPartners)
        ? partnerOrPartners
        : [partnerOrPartners];
      const categories = Array.isArray(categoryOrCategories)
        ? categoryOrCategories
        : [categoryOrCategories];

      return (
        <Card ref={ref} className={className} {...cardProps}>
          <CardThumbnail src={thumbnails[0]} loading={loading} />
          <CardInnerWrapper>
            <CardTitle>
              {(categories.length > 0 || partners.length > 0) && (
                <CardIcons icons={partners} themes={categories} />
              )}
              <Heading loading={loading} level="h5">
                {title}
              </Heading>
            </CardTitle>

            <CardProgress color="grey100" value={1} />

            {(caption || body || action) && (
              <CardBody>
                {caption && <CardCaption>{caption}</CardCaption>}
                {body}
                {action && <CardAction>{action}</CardAction>}
              </CardBody>
            )}
          </CardInnerWrapper>
        </Card>
      );
    },
  ),
)`
  & > ${CardContent} {
    display: flex;
    flex-flow: row nowrap;
  }

  ${({ theme }): CSSObject => ({
    border: parseBorder(theme.border.small),
  })}
`;
