import styled, { CSSProp, css } from 'styled-components';

import { ratioToHex } from '@hedgehog/ui/themes';

import { Button } from '../Button/button.component';
import { LinkButton } from '../LinkButton/link-button.component';
import { hoverAppearance } from '../styles';

const SecondaryButtonBase = css`
  ${({ theme }): CSSProp => ({
    color: theme.colors.black,
    backgroundColor: theme.colors.grey100,
  })}

  &[disabled]:not([disabled='false']) {
    ${({ theme }): CSSProp => ({
      backgroundColor: `${theme.colors.black}${ratioToHex(0.2)}`,
    })};
  }

  &:focus,
  &:hover {
    ${hoverAppearance}
  }
`;

export const SecondaryLightButton = styled(Button)`
  ${SecondaryButtonBase}
`;
export const SecondaryLightLinkButton = styled(LinkButton)`
  ${SecondaryButtonBase}
`;
