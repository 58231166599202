import React, { ForwardedRef, MouseEventHandler } from 'react';
import { Link, To } from 'react-router-dom';

import { AnyFixLater } from '@hedgehog/shared/types';
import { StandardProps } from '@hedgehog/ui/utils';
import { spacingNames } from '@hedgehog/utils/sizes';

import { CardWrapper, CardContent, CardWrapperProps } from './styles';

export type LinkProps = StandardProps<
  {
    reloadDocument?: boolean;
    replace?: boolean;
    state?: AnyFixLater;
    space?: spacingNames;
  } & LinkOrNotProps
>;

export type LinkOrNotProps =
  | ExternalLinkProps
  | InternalLinkProps
  | StubLinkProps;

export interface ExternalLinkProps {
  href?: string;
  to?: never;
  target?: string;
}

export interface InternalLinkProps {
  to?: To;
  state?: object;
  href?: never;
}

export interface StubLinkProps {
  to?: never;
  href?: never;
}

export type CardProps = StandardProps<
  {
    disabled?: boolean;
    draggable?: boolean;
    onClick?: MouseEventHandler;
    withShadow?: true;
    fitHeight?: true;
  } & CardWrapperProps &
    LinkProps
>;

export const Card = React.forwardRef(function <T extends CardProps = CardProps>(
  { space, children, ...cardProps }: T,
  ref: ForwardedRef<
    T extends StubLinkProps ? HTMLDivElement : HTMLAnchorElement
  >,
): JSX.Element {
  const isAnchorLike = cardProps.to || cardProps.href;

  return (
    <CardWrapper
      as={isAnchorLike ? (cardProps.to ? Link : 'a') : 'div'}
      {...cardProps}
      ref={ref as ForwardedRef<HTMLDivElement>}
    >
      <CardContent space={space}>{children}</CardContent>
    </CardWrapper>
  );
});

export const LinkCard = Card;
