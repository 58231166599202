import { gql } from '@apollo/client';

export const GET_PARTNER = gql`
  query GetPartner($slug: String!) {
    partner(args: { slug: $slug }) {
      id
      slug
      name
      referralCode
      theme {
        colors {
          primary
          secondary
        }
      }
      settings {
        name
        enabled
        value
      }
      resources {
        faviconUrl
        termsAgreementsUrl
        privacyPolicyUrl
        logotypeLightUrl
        logotypeDarkUrl
        supportUrl
        websiteUrl
        landing {
          imageUrl
          title
          caption
        }
        pages {
          portfolio {
            placeholderUrl
          }
          notifications {
            placeholderUrl
          }
          sidebar {
            backgroundUrl
          }
        }
      }
    }
  }
`;
