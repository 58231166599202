import { MouseEventHandler } from 'react';
import styled, { CSSObject } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

import { Avatar, AvatarProps } from '../avatar/avatar.component';
import { Chunk } from '../chunk/chunk.component';

const Container = styled(Avatar)<IconProps>`
  ${({ color, backgroundColor, theme, onClick }): CSSObject => ({
    cursor: onClick && 'pointer',
    color: color ? theme.colors[color] : theme.colors.black,
    borderRadius: '0.5rem',
    backgroundColor: backgroundColor
      ? theme.colors[backgroundColor]
      : theme.colors.grey100,
  })};

  &[disabled] {
    ${({ theme }): CSSObject => ({
      backgroundColor: theme.colors.grey100,
      pointerEvents: 'none',
    })}
  }

  &:hover {
    ${({ theme, onClick }): CSSObject => ({
      boxShadow: onClick && `inset 0 0 0 0.25rem ${theme.colors['shadow100']}`,
    })}
  }

  & ${Chunk} {
    ${({ theme, size, disabled }): CSSObject => ({
      width: size === 's' ? '0.75rem' : size === 'm' ? '1rem' : '1.5rem',
      height: size === 's' ? '0.75rem' : size === 'm' ? '1rem' : '1.5rem',
      color: disabled ? theme.colors['shadow100'] : undefined,
    })};
  }
`;

export type IconProps = StandardProps<
  {
    onClick?: MouseEventHandler;
    disabled?: boolean;
  } & AvatarProps
>;

export const Icon = styled(
  ({
    icon,
    size,
    color,
    backgroundColor,
    className,
    disabled,
    src,
    ...otherProps
  }: IconProps): JSX.Element | null => {
    return (
      <Container
        icon={icon}
        size={size}
        color={color}
        backgroundColor={backgroundColor}
        className={className}
        disabled={disabled}
        {...otherProps}
      />
    );
  },
)``;
