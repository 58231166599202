import './index.css';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { StartSentry } from '@hedgehog/browser/shared/monitoring/sentry';
import { createPartnerPortalStore } from '@hedgehog/data-access/partners';

import { App } from './app';
import { APP_VERSION } from './app.version';
import { environment } from './environments/environment';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import './toasts.css';
import './fonts.css';
import './index.css';

StartSentry({
  environment: environment.type,
  dsn: environment.sentry.dsn,
  release: APP_VERSION,
});

ReactDOM.render(
  <StrictMode>
    <Provider
      store={createPartnerPortalStore({
        settings: {
          apiBaseUrl: environment.api.url,
        },
      })}
    >
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root') as HTMLElement,
);
