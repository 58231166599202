import { gql } from '@apollo/client';

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags {
    featureFlags {
      value
      enabled
      name
    }
  }
`;
