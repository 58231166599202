import Chart, { CoreChartOptions, PluginOptionsByType } from 'chart.js/auto';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

const CanvasWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
`;

const Canvas = styled.canvas`
  flex: 0 0 auto;
`;

export interface Datum {
  label: string;
  value: number;
  color: string;
}

export type PieChartProps = StandardProps<
  {
    dataset: Datum[];
    layout?: CoreChartOptions<'pie'>['layout'];
    plugins?: DeepPartial<PluginOptionsByType<'pie'>>;
  },
  never
>;

export const PieChart = styled(
  ({
    className,
    dataset,
    layout = {},
    plugins = {},
  }: PieChartProps): JSX.Element => {
    const theme = useTheme();
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const [chart, setChart] = useState<Chart<'pie'> | null>(null);

    useEffect(() => {
      const { current: canvasElement } = canvasRef;
      if (!canvasElement) return;
      const context = canvasElement.getContext('2d');
      if (!context) return;
      if (!dataset) return;

      const chartInstance = new Chart(context, {
        type: 'pie',
        options: {
          responsive: true,
          layout,
          plugins: {
            legend: {
              display: true,
              position: 'right',
              labels: {
                font: {
                  family: theme.typography.body_small.fontFamily,
                  weight: `${theme.typography.body_small.fontWeight}`,
                  lineHeight: theme.typography.body_small.lineHeight,
                  size:
                    Number(
                      theme.typography.body_small.fontSize?.split('rem')[0],
                    ) * 16,
                },
              },
            },
            ...plugins,
          },
        },
        data: {
          labels: dataset.map((datum) => datum.label),
          datasets: [
            {
              data: dataset.map((datum) => datum.value),
              backgroundColor: dataset.map((datum) => datum.color),
            },
          ],
        },
      });

      setChart(chartInstance);
      chartInstance.render();
      return () => {
        chartInstance.destroy();
      };
    }, [canvasRef, dataset]);

    useEffect(() => {
      if (chart) chart.resize();
    }, []);

    return (
      <CanvasWrapper className={className}>
        <Canvas ref={canvasRef}></Canvas>
      </CanvasWrapper>
    );
  },
)``;
