import { useAppData, useClientQuery } from '@hedgehog/data-access/partners';
import { StandardProps } from '@hedgehog/ui/utils';

import { ClientWelcomeEmailButton } from '../../components';

export type ClientHeaderActionsProps = StandardProps<
  {
    clientId: string;
  },
  never
>;

export const ClientHeaderActions = ({
  clientId,
}: ClientHeaderActionsProps): JSX.Element => {
  const { activePartner } = useAppData();
  const { data: client } = useClientQuery(
    {
      partnerId: activePartner?.id || '',
      clientId,
    },
    {
      skip: !activePartner,
    },
  );

  // TODO: Hide the welcome button if the client has already signed up

  return <ClientWelcomeEmailButton client={client} partner={activePartner} />;
};
