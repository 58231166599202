import { ReactNode } from 'react';
import { CSSProp, styled } from 'styled-components';

import {
  StandardProps,
  visibleAtStyle,
  ResponsiveMode,
} from '@hedgehog/ui/utils';

import { Logotype } from './logotype.component';

const Wrapper = styled.nav<{ visibleAt?: ResponsiveMode }>`
  padding: 1.5rem 0;
  overflow: hidden;

  ${visibleAtStyle}
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const Brands = styled.div`
  display: flex;
  flex-flow: row nowrap;

  ${Logotype}:not(:last-child):after {
    content: '';
    display: inline-block;
    height: 100%;
    margin: 0 1rem;
    border-right: 1px solid;
    ${({ theme }): CSSProp => ({ borderRightColor: theme.colors.shadow100 })}
  }
`;

const Actions = styled.div<{ visibleAt?: ResponsiveMode }>`
  flex: 0 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;
  min-height: 2.5rem;
  height: 100%;
  ${visibleAtStyle}
`;

export interface JumbotronTopbarVisibleAtParams {
  topbar?: ResponsiveMode;
  actions?: ResponsiveMode;
}

export type JumbotronTopbarProps = StandardProps<
  {
    actions?: ReactNode[] | ReactNode;
    logotypeUrl?: string;
    logotypeTo?: string;
    loading?: boolean;
    visibleAt?: JumbotronTopbarVisibleAtParams;
  },
  never
>;

export const JumbotronTopbar = ({
  actions,
  loading,
  logotypeUrl,
  logotypeTo = '/',
  visibleAt,
}: JumbotronTopbarProps): JSX.Element => (
  <Wrapper visibleAt={visibleAt?.topbar ?? 'desktop-and-mobile'}>
    <InnerWrapper>
      <Brands>
        <Logotype to={logotypeTo} loading={loading} src={logotypeUrl} />
      </Brands>

      <Actions visibleAt={visibleAt?.actions ?? 'desktop-and-mobile'}>
        {actions}
      </Actions>
    </InnerWrapper>
  </Wrapper>
);
