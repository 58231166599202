import { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { spacing, spacingNames } from '@hedgehog/utils/sizes';

type ListItemProps = {
  className?: string;
  id?: string;
  children: ReactNode | ReactNode[];
  spacingSize?: spacingNames;
  flex?: boolean;
  onClick?: () => void;
};

const StyledListItem = styled.li<{ spacingSize: spacingNames; flex?: boolean }>`
  list-style: none;
  border-bottom: 1px solid;
  ${({ theme }): CSSProp => ({ borderBottomColor: theme.colors.shadow200 })};
  padding: ${({ spacingSize }): string => spacing[spacingSize]} 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;

export const ListItem = styled(
  ({
    className,
    id,
    children,
    spacingSize,
    onClick,
    ...otherProps
  }: ListItemProps): JSX.Element => {
    return (
      <StyledListItem
        {...otherProps}
        id={id}
        className={className}
        spacingSize={spacingSize || 'md'}
        onClick={onClick}
      >
        {children}
      </StyledListItem>
    );
  },
)``;

export default ListItem;
