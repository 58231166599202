import styled from 'styled-components';

import { useCurrentFund } from '@hedgehog/data-access/partners';
import { Paragraph } from '@hedgehog/ui/typography';

const Container = styled.div`
  padding: 1rem 0rem 1rem 0rem;
`;

export const FundOverview = (): JSX.Element => {
  const { description } = useCurrentFund();

  return (
    <Container>
      <Paragraph>{description}</Paragraph>
    </Container>
  );
};
