import type { Property } from 'csstype';
import { RefObject, useReducer } from 'react';

export interface ColumnSettings {
  size?: Property.GridTemplateColumns<string | number>;
}

export interface ColumnRef {
  ref: RefObject<HTMLElement>;
  settings: ColumnSettings;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const useColumnLayoutReducer = () =>
  useReducer(
    (
      state: ColumnRef[],
      action: { type: 'mount' | 'unmount'; column: ColumnRef },
    ): ColumnRef[] => {
      const { type, column } = action;
      switch (type) {
        case 'mount': {
          return [...state, column];
        }
        case 'unmount': {
          const index = state.findIndex(
            (columns) => columns.ref.current === action.column.ref.current,
          );
          return [
            ...state.slice(0, index),
            ...state.slice(index + 1, state.length),
          ];
        }
        default: {
          return [...state];
        }
      }
    },
    [],
  );
