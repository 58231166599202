import anime from 'animejs';
import React, { useReducer, useEffect, useMemo } from 'react';

import { UUID } from '@hedgehog/shared/types';

import { AutoscrollState, defaultValue } from '../autoscroll.context';
import reducer from '../autoscroll.reducer';

export type AlignType = 'top' | 'center' | 'bottom';

export interface AutoscrollContextProps {
  align?: AlignType;
  containerRef?: React.RefObject<HTMLElement>;
  fixedOffsetInPx?: number;
}

export function useAutoscrollContext({
  align = 'top',
  containerRef = {
    current: document.scrollingElement as HTMLElement,
  },
  fixedOffsetInPx: fixedOffset = 0,
}: AutoscrollContextProps = {}): AutoscrollState {
  const [{ currentElement, currentIndex, elements, order }, dispatch] =
    useReducer(reducer, { ...defaultValue });

  const mount = (scrollElementId: UUID, element: HTMLElement): void =>
    dispatch({ type: 'mount', scrollElementId, element });
  const unmount = (scrollElementId: UUID): void =>
    dispatch({ type: 'unmount', scrollElementId });
  const previous = (fromElementId: UUID): void =>
    dispatch({ type: 'previous', fromElementId });
  const next = (fromElementId: UUID): void =>
    dispatch({ type: 'next', fromElementId });
  const scrollTop = (offset: number, smoothly = false): void => {
    const { current } = containerRef;
    if (!current) return;

    anime({
      targets: current,
      duration: smoothly ? 300 : 0,
      easing: 'easeOutCubic',
      scrollTop: offset,
    });
  };

  useEffect(() => {
    const { current: containerElement } = containerRef;

    if (!containerElement) return;
    if (!currentElement) return;

    const scrollTop: Record<AlignType, number> = {
      top: currentElement.offsetTop + fixedOffset,
      center:
        currentElement.offsetTop -
        (containerElement.clientHeight - currentElement.offsetHeight) / 2 +
        fixedOffset,
      bottom:
        currentElement.offsetTop -
        (containerElement.clientHeight - currentElement.offsetHeight) +
        fixedOffset,
    };

    anime({
      targets: containerElement,
      duration: 300,
      easing: 'easeOutCubic',
      scrollTop: scrollTop[align],
    });
  }, [currentElement, containerRef]);

  return useMemo(
    () => ({
      currentIndex,
      currentElement,
      containerRef,
      elements,
      order,
      mount,
      unmount,
      next,
      previous,
      scrollTop,
    }),
    [elements, order, currentElement, containerRef],
  );
}
