import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAddress } from 'viem';
import { useContractWrite } from 'wagmi';

import { useZeroDevWallet } from '@hedgehog/browser/shared/account-abstraction';
import { SecondaryButton, SecondaryLightButton } from '@hedgehog/ui/buttons';
import { Paragraph } from '@hedgehog/ui/typography';

import { ModalErrorNote, ModalForm } from '../../../../../components';
import {
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';

export const MarketBidRejectConfirmPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { navigateTo, close } = useNavigateToBidPage();
  const { clientId } = useParams();
  const [{ origin, fundContractAddress, clientWalletIndex }] =
    useMarketBidParams();

  const routes: Record<'explore' | 'market' | 'client', string> = {
    ['explore']: '/explore',
    ['market']: '/market',
    ['client']: `/clients/${clientId}/portfolio`,
  };

  const { autoloadWallet, address: walletAddress } = useZeroDevWallet();
  const [{ bidId }] = useMarketBidParams();

  useEffect(() => {
    autoloadWallet({
      index: clientWalletIndex,
    });
  }, []);

  const {
    writeAsync: rejectBid,
    isLoading,
    isError,
    error,
  } = useContractWrite({
    abi: IFund.abi,
    functionName: 'rejectBid',
    address: fundContractAddress ? getAddress(fundContractAddress) : undefined,
    args: [BigInt(bidId ?? -1)],
  });

  const handleRejectBid = async () => {
    try {
      if (!fundContractAddress)
        throw new Error('RejectBid: Fund contract address is not present');
      if (!walletAddress)
        throw new Error('RejectBid: Client wallet address is not present');
      await rejectBid();
      navigate(routes[origin]);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ModalForm
      heading="Confirm rejection"
      backModal={() => navigateTo('overview')}
      closeModal={() => close()}
      actions={
        <>
          <SecondaryLightButton onClick={() => navigateTo('overview')}>
            Cancel
          </SecondaryLightButton>
          <SecondaryButton onClick={handleRejectBid} loading={isLoading}>
            Reject offer
          </SecondaryButton>
        </>
      }
      notes={isError && <ModalErrorNote error={(error as any)?.shortMessage} />}
    >
      <Paragraph>
        The advisor to the bidder will be notified and will be able to submit
        more bids until the auction is closed.
      </Paragraph>
    </ModalForm>
  );
};
