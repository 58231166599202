export const LeafIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C4.47715 0 0 4.02944 0 9V15H2L2 9.58577L6.29289 5.29288L7.70711 6.70709L4 10.4142V12.9556C9.05332 12.504 13 8.66681 13 4L13 0H10Z"
      fill="currentColor"
    />
  </svg>
);

export default LeafIcon;
