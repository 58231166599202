import { gql } from '@apollo/client';

export const GET_ONBOARDING_WORKFLOWS = gql`
  query GetOnboardingWorkflows {
    onboardingWorkflows {
      postSignup {
        name
        status
        meta {
          status
        }
      }
      preInvest {
        name
        status
        meta {
          status
        }
      }
    }
  }
`;
