import { Fund, useFundsBlockchainQuery } from './use-funds-query.hook';

type Result = {
  data?: Fund;
  loading: boolean;
};

export const useFundBlockchainQuery = (fundId: number): Result => {
  const { data, loading } = useFundsBlockchainQuery();
  if (isNaN(fundId)) {
    return {
      data: undefined,
      loading,
    };
  }
  return {
    data: data?.find((fund: Fund) => fund.id === fundId),
    loading,
  };
};
