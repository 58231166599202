import { createSelector } from '@reduxjs/toolkit';

import { Country } from '@hedgehog/data-access/country-types';

import { Selector } from '../utils/selector';

import { countriesApi } from './countries.api';

export const selectCountriesResult = countriesApi.endpoints.countries.select();

export const selectCountryByCode = (
  code: string,
): Selector<Country | undefined> =>
  createSelector(selectCountriesResult, ({ data }) => {
    return data?.find(
      (country) => country.code.toLowerCase() === code.toLowerCase(),
    );
  });
