export const HelpIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 16H11.01"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.09 7.99996C8.3251 7.33163 8.78915 6.76807 9.39995 6.40909C10.0108 6.05012 10.7289 5.9189 11.4272 6.03867C12.1255 6.15844 12.7588 6.52148 13.2151 7.06349C13.6713 7.60549 13.9211 8.29148 13.92 8.99996C13.92 11 10.92 12 10.92 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HelpIcon;
