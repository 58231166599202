export const VideoIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H20V20H0V0Z" fill="currentColor" />
    <path d="M24 6L30 0H32V20H30L24 14V6Z" fill="currentColor" />
  </svg>
);


export default VideoIcon;
