export const BookIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 0H28V24H8V28H28V32H4V0H18V12L21 9L24 12V0Z"
      fill="currentColor"
    />
  </svg>
);

export default BookIcon;
