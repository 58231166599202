import { Outlet, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getAddress, zeroAddress } from 'viem';

import {
  ListingStatus,
  useAppData,
  useClientInvestmentMetricsQuery,
  useClientListings,
} from '@hedgehog/data-access/partners';

import { PortfolioBalance } from '../components';
import { ClientPortfolio, MarketPortfolio } from '../containers';
import { useClientFundPositions } from '../hooks/use-client-fund-positions.container';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ClientPortfolioPage = (): JSX.Element | null => {
  const { activePartner } = useAppData();
  const { clientId } = useParams();

  const credentials = {
    partnerId: activePartner?.id || '',
    clientId: clientId || '',
  };
  const { data: metrics } = useClientInvestmentMetricsQuery(credentials);
  const { positions, clientWalletAddress, error } =
    useClientFundPositions(credentials);

  const { data: listings, isLoading: areListingsLoading } = useClientListings({
    clientAddress: getAddress(clientWalletAddress || zeroAddress),
    status: [ListingStatus.open],
  });

  if (!(activePartner && clientId)) {
    return null;
  }

  const totalContribution = positions.reduce(
    (acc, fund) => acc + Number(fund.contribution),
    0,
  );
  const portfolioBalance =
    (metrics?.totalInvestmentAmount.amount || 0) + totalContribution;

  return (
    <Container>
      <PortfolioBalance
        loading={areListingsLoading}
        totalAmount={portfolioBalance}
        differenceAmount={metrics ? metrics.totalYield.amount : 0}
      />

      <ClientPortfolio clientId={clientId} partnerId={activePartner.id} />
      <MarketPortfolio
        positions={positions}
        listings={listings}
        error={error}
        loading={areListingsLoading}
      />
      <Outlet />
    </Container>
  );
};
