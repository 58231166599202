export const DiningIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4V0H0V5C0 6.65685 1.34315 8 3 8V14H5V8C6.65685 8 8 6.65685 8 5V0H6V4H5V0H3V4H2Z"
      fill="currentColor"
    />
    <path
      d="M13 14V0H11L9.10125 6.64563C9.03408 6.88073 9 7.12404 9 7.36855C9 8.60419 9.85166 9.64092 11 9.92375V14H13Z"
      fill="currentColor"
    />
  </svg>
);

export default DiningIcon;
