import Emoji from 'react-emoji-render';
import styled, { CSSProp } from 'styled-components';

import { TCountry } from '@hedgehog/shared/types';
import { ListItem } from '@hedgehog/ui/lists';

const CountryListItem = styled(ListItem)`
  display: flex;
  align-items: center;
  flex-direction: row;
  border: none !important;
  gap: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;

  &,
  &:first-child,
  &:last-child {
    padding: 1rem 0.5rem !important;
  }

  &:hover {
    transition: 200ms background-color ease-in-out;

    ${({ theme }): CSSProp => ({
      backgroundColor: theme.colors.grey100,
    })}
  }
`;

const EmojiContainer = styled.div`
  display: inline-block;
  font-size: 1.5rem;
`;

type CountryPickerListItemProps = {
  country: TCountry;
  onClick: () => void;
};

export const CountryPickerListItem = ({
  country,
  onClick,
}: CountryPickerListItemProps): JSX.Element => {
  return (
    <CountryListItem
      key={country.code}
      data-country={country.code}
      onClick={onClick}
    >
      <EmojiContainer>
        <Emoji text={`:flag-${country.shortcode.toLowerCase()}:`} />
      </EmojiContainer>
      <span>{country.name}</span>
    </CountryListItem>
  );
};

export default CountryPickerListItem;
