import { SyntheticEvent, useEffect, useState } from 'react';
import styled from 'styled-components';

import {
  useAppData,
  useAssetsQuery,
  useIssueClientRewardMutation,
} from '@hedgehog/data-access/partners';
import { PartnerClientIssuance } from '@hedgehog/data-access/partners-types';
import { TransactionType } from '@hedgehog/shared/blockchain/resident-token';
import { AnyFixLater } from '@hedgehog/shared/types';
import * as CSV from '@hedgehog/shared/utils/issuance-csv-transformer';
import { SecondaryButton } from '@hedgehog/ui/buttons';
import { Chunk } from '@hedgehog/ui/icons';
import { Input } from '@hedgehog/ui/inputs';
import { VSpace } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { parseBorder } from '@hedgehog/ui/themes';
import { Heading6 } from '@hedgehog/ui/typography';

import { Table, TableData, TableHeader } from '../../components';

enum IssuanceStatus {
  Review = 'Review',
  Pending = 'Pending',
  Success = 'Success',
  Error = 'Error',
}

const ErrorMessage = styled.div`
  ${({ theme }) => ({
    color: theme.colors.error,
  })}
`;

const RowControl = styled(SecondaryButton)`
  ${({ theme }) => ({
    marginLeft: theme.spacing.xxsmall,
  })}
`;

const CenteredIcon = styled(Chunk)`
  margin: auto;
`;

const getStatus = (
  row: CSV.IssuanceData,
  error: AnyFixLater,
  data?: PartnerClientIssuance,
) => {
  if (CSV.hasErrors(row) || error) {
    return IssuanceStatus.Error;
  }
  return data?.transactionHash ? IssuanceStatus.Success : IssuanceStatus.Review;
};

const getServerErrorForField = (
  error: AnyFixLater,
  field: string,
): string[] => {
  if (field === error?.data?.metadata?.field) {
    return [error?.data?.message];
  }

  return [];
};

const getServerErrorForRow = (error: AnyFixLater): string | null => {
  if (error && !error?.data?.metadata?.field) {
    return error?.data?.message;
  }

  return null;
};

const InlineError = styled.th`
  ${({ theme }) => ({
    backgroundColor: theme.colors.errorLight,
    borderTop: `1px solid ${theme.colors.error}`,
    fontSize: theme.typography.note.fontSize,
    fontWeight: 'normal',
    padding: 0,
  })}
`;

const RowSpacer = styled.tr`
  height: 2px;
`;

const Row = ({
  row,
  assetId,
  partnerId,
}: {
  row: CSV.IssuanceData;
  assetId: string;
  partnerId: string;
}) => {
  const [issueClientReward, { data, isLoading, error }] =
    useIssueClientRewardMutation();

  const issueTokens = async () => {
    const {
      columns: { timestamp, email, amount, type },
    } = row;

    if (!type) return;

    issueClientReward({
      partnerId,
      assetId,
      dryRun: false,
      email,
      amount,
      type: type as TransactionType,
      timestamp,
    });
  };

  const status = getStatus(row, error, data?.data);
  const serverErrorForRow = getServerErrorForRow(error);

  return (
    <>
      <tr key={row.columns.email}>
        {CSV.headings(row).map((key) => {
          const errors = CSV.errors(row).concat(
            getServerErrorForField(error, key),
          );
          return (
            <TableData
              key={row.columns[key]}
              // Errors can be cell-specific while success applies to the whole row
              error={!!errors.length || !!serverErrorForRow}
              success={status === IssuanceStatus.Success}
            >
              {row.columns[key]}
              {errors.length > 0 && (
                <ul>
                  {errors.map((errorMsg) => (
                    <li key={errorMsg}>{errorMsg}</li>
                  ))}
                </ul>
              )}
            </TableData>
          );
        })}
        <td rowSpan={serverErrorForRow ? 2 : 1}>
          {status === IssuanceStatus.Review && (
            <RowControl
              small
              disabled={CSV.hasErrors(row)}
              onClick={issueTokens}
              loading={isLoading}
            >
              Issue
            </RowControl>
          )}
          {status === IssuanceStatus.Success && (
            <CenteredIcon size="l" icon="check-mark" color="success" />
          )}
          {status === IssuanceStatus.Error && (
            <CenteredIcon size="l" icon="cross" color="error" />
          )}
        </td>
      </tr>
      {serverErrorForRow && (
        <>
          <InlineError scope="rowgroup" colSpan={4}>
            {serverErrorForRow}
          </InlineError>
        </>
      )}
    </>
  );
};

const StyledSelect = styled.select`
  ${({ theme }) => ({
    border: parseBorder(theme.border.normal),
    borderRadius: theme.radius.normal,
    fontFamily: theme.typography.body.fontFamily,
    fontSize: theme.typography.body.fontSize,
    padding: `${theme.spacing.xxsmall} ${theme.spacing.xsmall}`,
  })}
`;

export const ClientRewardsForm = (props: {
  activePartnerId?: string;
  assetId?: string;
}): JSX.Element | null => {
  const partnerId = props.activePartnerId || useAppData().activePartnerId || '';
  const [assetId, setAssetId] = useState<string | null>(props.assetId || null);
  const [error, setError] = useState<Error | null>(null);
  const [rewardsData, setRewardsInput] = useState<CSV.IssuanceData[]>([]);
  const { data: assets, isLoading } = useAssetsQuery(
    { partnerId },
    { skip: !!assetId || !partnerId },
  );

  useEffect(() => {
    if (!assets) return;
    const [defaultAsset] = assets;
    setAssetId(defaultAsset.id);
  }, [assets?.length]);

  if (!partnerId || isLoading) {
    return <Loader />;
  }

  const onTransform = (data: CSV.IssuanceData[]) => {
    setRewardsInput(data);
    setError(null);
  };
  const onError = (err: Error) => {
    setRewardsInput([]);
    setError(err);
  };

  const handleFileSelection = async (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const target = event.target as HTMLInputElement;

    if (target.files?.length) {
      const [file] = target.files;
      file.text().then(CSV.transform).then(onTransform).catch(onError);
    }
  };

  const selectAsset = (event: SyntheticEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    const { value } = target;
    setAssetId(value);
  };

  return (
    <VSpace spacing="tiny">
      <StyledSelect name="asset" id="asset" onChange={selectAsset}>
        {assets?.map((asset) => (
          <option key={asset.id} value={asset.id}>
            {asset.name}
          </option>
        ))}
      </StyledSelect>
      <Input
        type="file"
        name="client-rewards-input"
        onChange={handleFileSelection}
        ariaLabel="client rewards CSV input"
      />
      {error && (
        <ErrorMessage>
          <Heading6>Your CSV couldn&apos;t be loaded:</Heading6>
          {error.message}
        </ErrorMessage>
      )}
      {rewardsData.length > 0 && (
        <Table>
          <thead>
            {CSV.headings(rewardsData).map((heading) => (
              <TableHeader key={heading}>
                <Heading6>{heading}</Heading6>
              </TableHeader>
            ))}
          </thead>
          <tbody>
            {rewardsData.map((row, i) => (
              <>
                <Row
                  key={row.columns.email}
                  row={row}
                  partnerId={partnerId}
                  assetId={assetId || ''}
                />
                {i < rewardsData.length - 1 && <RowSpacer />}
              </>
            ))}
          </tbody>
        </Table>
      )}
    </VSpace>
  );
};
