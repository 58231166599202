import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { ColumnLayout, Column } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { fromBreakpoint } from '@hedgehog/ui/themes';

import { Navigation } from '../../containers';

const PageColumn = styled(Column)`
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  ${({ theme: { colors, spacing } }): CSSObject => ({
    backgroundColor: colors.grey100,
  })}
`;

const OutletWrapper = styled.div`
  flex: 1 1 100%;
  overflow: hidden;
  ${({ theme, theme: { spacing } }): CSSObject => ({
    maxHeight: `calc(100% - ${spacing.xxxlarge})`,
    paddingTop: spacing.xxxlarge,
    ...fromBreakpoint(theme.breakpoints.tablet, {
      maxHeight: `calc(100% - ${spacing.xxxlarge} - ${spacing.large})`,
      padding: `${spacing.xxxlarge} ${spacing.large}`,
      paddingBottom: spacing.large,
    }),
  })}
`;

const OutletInnerWrapper = styled.div`
  height: 100%;
  max-height: 100%;
  display: flex;
`;

const NavigationColumnLayout = styled(ColumnLayout)`
  max-height: 100%;
  overflow: hidden;

  ${({ theme }): CSSProp => ({
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content 1fr',
    ...fromBreakpoint(theme.breakpoints.desktop, {
      gridTemplateColumns: 'max-content auto',
      gridTemplateRows: '100%',
    }),
  })}
`;

export const NavigationLayout = (): JSX.Element => (
  <NavigationColumnLayout>
    <Column>
      <Navigation />
    </Column>
    <PageColumn>
      <OutletWrapper>
        <OutletInnerWrapper>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </OutletInnerWrapper>
      </OutletWrapper>
    </PageColumn>
  </NavigationColumnLayout>
);
