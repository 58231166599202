import { ReactNode } from 'react';
import styled, { CSSProp } from 'styled-components';

import { Paragraph } from '@hedgehog/ui/typography';

const List = styled.ol`
  font-size: 1rem;
  line-height: 1.5rem;
  ${({ theme }): CSSProp => ({ color: theme.colors.grey400 })}
  padding-left: 0.5rem;
  list-style: numbered;
  margin-top: 0;
  margin-left: 1rem;
  margin-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }
`;

const GreyText = styled(Paragraph).attrs({ color: 'grey400' })``;

const LatinList = styled(List)`
  list-style: lower-latin;
`;

const ListItem = styled.li`
  margin-bottom: 0.25rem;
  padding-left: 0.25rem;
`;

const ListBreakText = styled(GreyText)`
  margin: 2rem 0rem 2rem -1.5rem;
`;

export type InvestorStatement = {
  statement: ReactNode;
  learnMore: ReactNode;
};

export const SophisticatedInvestorStatement: InvestorStatement = {
  statement: (
    <>
      <Paragraph>
        I declare that I am a self-certified sophisticated investor for the
        purposes of the restriction on promotion of non mass market investments.
      </Paragraph>
      <br />
      <Paragraph>
        <b>
          I accept that the investments to which the promotions will relate may
          expose me to a significant risk of losing all of the money or other
          property invested.
        </b>{' '}
        I am aware that it is open to me to seek advice from someone who
        specialises in advising on non mass market investments.
      </Paragraph>
    </>
  ),
  learnMore: (
    <>
      <GreyText>
        <strong>I understand that this means:</strong>
      </GreyText>
      <List>
        <ListItem>
          I can receive promotional communications made by a person who is
          authorised by the Financial Conduct Authority which relate to
          investment activity in non mass market investments;
        </ListItem>
        <ListItem>
          The investments to which the promotions will relate may expose me to a
          significant risk of losing all of the property invested.
        </ListItem>
      </List>
      <GreyText>
        I am a self-certified sophisticated investor because at least one of the
        following applies:
      </GreyText>
      <List>
        <ListItem>
          I am a member of a network or syndicate of business angels and have
          been so for at least the last six months prior to today;
        </ListItem>
        <ListItem>
          I have made more than one investment in an unlisted company in the two
          years prior to the date of this statement;
        </ListItem>
        <ListItem>
          I am working, or have worked in the two years prior to the date of
          this statement, in a professional capacity in the private equity
          sector, or in the provision of finance for small and medium
          enterprises;
        </ListItem>
        <ListItem>
          I am currently, or have been in the two years prior to the date of
          this statement, a director of a company with an annual turnover of at
          least £1m.
        </ListItem>
      </List>
    </>
  ),
};

export const HighNetWorthInvestorStatement: InvestorStatement = {
  statement: (
    <>
      <Paragraph>
        I make this statement so that I can receive promotional communications
        which are exempt from the restriction on promotion of non mass market
        investments.
      </Paragraph>
      <br />
      <Paragraph>
        <b>
          I accept that the investments to which the promotions will relate may
          expose me to a significant risk of losing all of the money or other
          property invested.
        </b>{' '}
        I am aware that it is open to me to seek advice from someone who
        specialises in advising on non mass market investments.
      </Paragraph>
    </>
  ),
  learnMore: (
    <>
      <GreyText>
        <strong>I understand that this means:</strong>
      </GreyText>
      <List>
        <ListItem>
          I can receive promotional communications made by a person who is
          authorised by the Financial Conduct Authority which relate to
          investment activity in non mass market investments;
        </ListItem>
        <ListItem>
          The investments to which the promotions will relate may expose me to a
          significant risk of losing all of the property invested.
        </ListItem>
      </List>
      <GreyText>
        The exemption relates to certified high net worth investors and I
        declare that I qualify as such because at least one of the following
        applies to me:
      </GreyText>
      <List>
        <ListItem>
          I had, throughout the financial year immediately preceding the date of
          this statement, an annual income to the value of £100,000 or more.
          Annual income for these purposes does not include money withdrawn from
          my pension savings (except where the withdrawals are used directly for
          income in retirement).
        </ListItem>
        <ListItem>
          I held, throughout the financial year immediately preceding the date
          of this statement, net assets to the value of £250,000 or more. Net
          assets for these purposes do not include:
          <LatinList>
            <ListItem>
              the property which is my primary residence or any money raised
              through a loan secured on that property; or
            </ListItem>
            <ListItem>
              any rights of mine under a qualifying contract of insurance; or
            </ListItem>
            <ListItem>
              any benefits (in the form of pensions or otherwise) which are
              payable on the termination of my service or on my death or
              retirement and to which I am (or my dependants are), or may be,
              entitled; or any withdrawals from my pension savings (except where
              the withdrawals are used directly for income in retirement).
            </ListItem>
          </LatinList>
        </ListItem>
      </List>
    </>
  ),
};

export const EverydayInvestorStatement: InvestorStatement = {
  statement: (
    <>
      <Paragraph>
        I make this statement so that I can receive promotional communications
        relating to non mass market investments as a restricted investor.
      </Paragraph>
      <Paragraph>
        <br />
        <b>
          I accept that the investments to which the promotions will relate may
          expose me to a significant risk of losing all of the money or other
          property invested.
        </b>{' '}
        I am aware that it is open to me to seek advice from an authorised
        person who specialises in advising on non mass market investments.
      </Paragraph>
    </>
  ),
  learnMore: (
    <>
      <GreyText>
        <strong>I understand that this means:</strong>
      </GreyText>
      <List>
        <ListItem>
          I can receive promotional communications made by a person who is
          authorised by the Financial Conduct Authority which relate to
          investment activity in non mass market investments;
        </ListItem>
        <ListItem>
          The investments to which the promotions will relate may expose me to a
          significant risk of losing all of the property invested.
        </ListItem>
      </List>
      <GreyText>
        <strong>
          I declare that I qualify as a restricted everyday investor because:
        </strong>
      </GreyText>
      <LatinList>
        <ListItem>
          in the twelve months preceding the date of this statement, I have not
          invested more than 10% of my net assets in non mass market
          investments; and
        </ListItem>
        <ListItem>
          I undertake that in the twelve months following the date of this
          statement, I will not invest more than 10% of my net assets in non
          mass market investments.
        </ListItem>
      </LatinList>
      <GreyText>
        <strong>Net assets for these purposes do not include:</strong>
      </GreyText>
      <LatinList>
        <ListItem>
          the property which is my primary residence or any money raised through
          a loan secured on that property;
        </ListItem>
        <ListItem>
          any rights of mine under a qualifying contract of insurance; or
        </ListItem>
        <ListItem>
          any benefits (in the form of pensions or otherwise) which are payable
          on the termination of my service or on my death or retirement and to
          which I am (or my dependants are), or may be entitled; or
        </ListItem>
        <ListItem>
          any withdrawals from my pension savings (except where the withdrawals
          are used directly for income in retirement).
        </ListItem>
      </LatinList>
    </>
  ),
};

export const OakglenHighNetWorthInvestorStatement: InvestorStatement = {
  statement: (
    <Paragraph>
      I declare that I am a certified high net worth individual for the purposes
      of the Financial Services and Markets Act 2000 (Financial Promotion) Order
      2005.
    </Paragraph>
  ),
  learnMore: (
    <>
      <GreyText>
        <strong>I understand that this means:</strong>
      </GreyText>
      <List>
        <ListItem>
          I can receive financial promotions that may not have been approved by
          a person authorised by the Financial Conduct Authority;
        </ListItem>
        <ListItem>
          the content of such financial promotions may not conform to rules
          issued by the Financial Conduct Authority;
        </ListItem>
        <ListItem>
          <strong>
            by signing this statement I may lose significant rights;
          </strong>
        </ListItem>
        <ListItem>
          I may have no right to complain to either of the following:
          <LatinList>
            <ListItem>the Financial Conduct Authority; or</ListItem>
            <ListItem>the Financial Ombudsman Scheme;</ListItem>
          </LatinList>
        </ListItem>
        <ListItem>
          I may have no right to seek compensation from the Financial Services
          Compensation Scheme.
        </ListItem>

        <ListBreakText>
          I am a certified high net worth individual because{' '}
          <strong>at least one of the following applies:</strong>
        </ListBreakText>

        <ListItem>
          I had, during the financial year immediately preceding today's date,
          an annual income to the value of £100,000 or more;
        </ListItem>
        <ListItem>
          I held, throughout the financial year immediately preceding today's
          date, net assets to the value of £250,000 or more. Net assets for
          these purposes do not include:
          <LatinList>
            <ListItem>
              the property which is my primary residence or any loan secured on
              that residence;
            </ListItem>
            <ListItem>
              any rights of mine under a qualifying contract of insurance within
              the meaning of the Financial Services and Markets Act 2000
              (Regulated Activities) Order 2001; or
            </ListItem>
            <ListItem>
              any benefits (in the form of pensions or otherwise) which are
              payable on the termination of my service or on my death or
              retirement and to which I am (or my dependants are), or may be,
              entitled.
            </ListItem>
          </LatinList>
        </ListItem>
      </List>
      <Paragraph>
        <strong>
          I accept that I can lose my property and other assets from making
          investment decisions based on financial promotions.
        </strong>
      </Paragraph>
      <Paragraph>
        I am aware that it is open to me to seek advice from someone who
        specialises in advising on investments.
      </Paragraph>
    </>
  ),
};

export const OakglenSophisticatedInvestorStatement: InvestorStatement = {
  statement: (
    <Paragraph>
      I declare that I am a self-certified sophisticated investor for the
      purposes of the Financial Services and Markets Act 2000 (Financial
      Promotion) Order 2005.
    </Paragraph>
  ),
  learnMore: (
    <>
      <GreyText>
        <strong>I understand that this means:</strong>
      </GreyText>
      <List>
        <ListItem>
          I can receive financial promotions that may not have been approved by
          a person authorised by the Financial Conduct Authority;
        </ListItem>
        <ListItem>
          the content of such financial promotions may not conform to rules
          issued by the Financial Conduct Authority;
        </ListItem>
        <ListItem>
          <strong>
            by signing this statement I may lose significant rights;
          </strong>
        </ListItem>
        <ListItem>
          I may have no right to complain to either of the following:
          <LatinList>
            <ListItem>the Financial Conduct Authority; or</ListItem>
            <ListItem>the Financial Ombudsman Scheme;</ListItem>
          </LatinList>
        </ListItem>
        <ListItem>
          I may have no right to seek compensation from the Financial Services
          Compensation Scheme.
        </ListItem>

        <ListBreakText>
          I am a self-certified sophisticated investor because at least one of
          the following applies:
        </ListBreakText>

        <ListItem>
          I am a member of a network or syndicate of business angels and have
          been so for at least the last six months prior to today's date;
        </ListItem>
        <ListItem>
          I have made more than one investment in an unlisted company in the two
          years prior to today's date;
        </ListItem>
        <ListItem>
          I am working, or have worked in the two years prior to today's date,
          in a professional capacity in the private equity sector, or in the
          provision of finance for small and medium enterprises;
        </ListItem>
        <ListItem>
          I am currently, or have been in the two years prior to today's date, a
          director of a company with an annual turnover of at least £1 million.
        </ListItem>
      </List>

      <Paragraph>
        <strong>
          I accept that I can lose my property and other assets from making
          investment decisions based on financial promotions.
        </strong>
      </Paragraph>

      <Paragraph>
        I am aware that it is open to me to seek advice from someone who
        specialises in advising on investments.
      </Paragraph>
    </>
  ),
};
