import { ForwardedRef, forwardRef } from 'react';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { Tenant } from '@hedgehog/shared/types';
import { Heading } from '@hedgehog/ui/typography';

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 1rem;

  ${({ theme }): CSSProp => ({
    backgroundColor: theme.colors.grey100,
  })}
  margin-right: 0.5rem;
  gap: 1rem;

  &:last-child {
    margin-right: 0;
  }
`;

const Image = styled.div<{ imageUrl: string }>`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-size: cover;

  ${({ imageUrl }): CSSObject => ({
    background: `url(${imageUrl}) no-repeat center`,
  })}
`;

type AssetTenantListItemProps = {
  tenant: Pick<Tenant, 'name' | 'logo'>;
};

export const AssetTenantListItem = forwardRef(
  (
    { tenant }: AssetTenantListItemProps,
    ref: ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <ListItem ref={ref}>
        <Heading level="h6">{tenant.name}</Heading>
        <Image imageUrl={tenant.logo} />
      </ListItem>
    );
  },
);
