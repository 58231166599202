import { ReactNode } from 'react';
import styled from 'styled-components';

import { screens } from '@hedgehog/utils/sizes';

const PartnerAuthPageLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  @media only screen and (min-width: ${screens.medium}px) {
    flex-grow: 1;
    align-items: center;
  }
`;

const PartnerAuthPageContentLayout = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 auto;
  max-width: 20rem;
  width: 100%;
  margin: 0;
  gap: 2.5rem;
`;

type PartnerAuthPageProps = {
  children: ReactNode | ReactNode[];
};

export const PartnerAuthPage = ({
  children,
}: PartnerAuthPageProps): JSX.Element => {
  return (
    <PartnerAuthPageLayout>
      <PartnerAuthPageContentLayout>{children}</PartnerAuthPageContentLayout>
    </PartnerAuthPageLayout>
  );
};
