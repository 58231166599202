import React from 'react';
import styled, { CSSProp } from 'styled-components';

import { PlayIcon } from '@hedgehog/ui/icons';
import { screens } from '@hedgehog/utils/sizes';

export interface GalleryVideoProps {
  src: string;
  fullWidth?: boolean;
  onClick?: () => void;
}

const VideoThumbnail = styled.div<{ src: string; fullWidth: boolean }>`
  position: relative;
  background: url(${({ src }): string => src}) no-repeat center;
  background-size: cover;
  width: 100%;
  ${({ fullWidth }): string | false => !fullWidth && `max-width: 20.5rem;`}
  height: 11.25rem;
  margin-right: 0.5rem;
  border-radius: 1rem;

  @media only screen and (min-width: ${screens.medium}px) {
    height: ${({ fullWidth }): string | false =>
      fullWidth ? '21.25rem;' : '11.25rem'};
  }
`;

const Play = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0.75);

  display: flex;
  padding: 1rem;
  border-radius: 0.75rem;

  will-change: background-color;

  ${({ theme }): CSSProp => ({
    backgroundColor: theme.colors.white,
  })}
  transition: background-color 200ms;
  &:hover {
    ${({ theme }): CSSProp => ({
      backgroundColor: theme.colors.grey100,
    })}
  }

  & > svg {
    height: 1.5rem;
    max-height: 1.5rem;
    width: 1.5rem;
    max-width: 1.5rem;
  }
`;

export const GalleryVideo = React.forwardRef(
  (
    { fullWidth = false, src, onClick }: GalleryVideoProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ): JSX.Element => {
    return (
      <VideoThumbnail
        src={src}
        ref={ref}
        fullWidth={fullWidth}
        onClick={onClick}
      >
        <Play>
          <PlayIcon />
        </Play>
      </VideoThumbnail>
    );
  },
);

export default GalleryVideo;
