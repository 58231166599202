import { css } from 'styled-components';

export const flyIn = css`
  @keyframes fly-in {
    0% {
      opacity: 0;
      margin-top: 3rem;
    }

    50% {
      opacity: 1;
    }

    100% {
      margin-top: 0;
    }
  }
`;
