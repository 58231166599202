export const ChartIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0V12V16H20H32ZM31.4959 12H20V0.50407C25.6224 1.95118 30.0488 6.37757 31.4959 12Z"
      fill="currentColor"
    />
  </svg>
);

export default ChartIcon;
