import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useEffect,
  useRef,
  useState,
} from 'react';
import { styled } from 'styled-components';

import { Note } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

import { useContentEditable, useFocusRedirect } from '../hooks';
import { InputBase } from '../Input/input.base';
import { InputLabel } from '../input-label';
import { Reset } from '../styles';

export type NumberInputProps = StandardProps<
  {
    id?: string;
    label?: string;
    name?: string;
    pattern?: string;
    precision?: number | `${number}`;
    delimiter?: string;
    max?: number | `${number}`;
    min?: number | `${number}`;
    suffix?: ReactNode;
    prefix?: ReactNode;
    value?: number;
    placeholder?: string;
    onChange?: (value: number | undefined) => void;
  },
  never
>;

const FormControl = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  box-sizing: border-box;
  margin: 0.25rem 0;
  padding-bottom: 1.25rem;
`;

const FormError = styled(Note)`
  position: absolute;
  bottom: 0;
  /* font-size: 1rem; */
  line-height: 1rem;
  & > span {
    &:not(:last-child):after {
      content: ', ';
    }
  }
`;

const InputWrapper = styled.div<{ errors?: Array<string> }>`
  ${InputBase}
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0.75rem 1rem;
  height: auto;
`;

const Input = styled.input`
  ${Reset}
  width:auto;
  min-width: 1em;
  grid-area: 1 / 1;
  appearance: none;
  line-height: 1.5em;
`;

const InputShrine = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > ${Input} {
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }
`;

const InputInnerWrapper = styled.div`
  flex: 0 0 auto;
  position: relative;
  /**
    That does utilise this (trick)[https://css-tricks.com/auto-growing-inputs-textareas/]
    to make the input grow with the content.
   */
  display: inline-grid;
  vertical-align: top;
  align-items: center;
  max-width: 100%;
  &::after,
  & > ${InputShrine} {
    grid-area: 1 / 2;
    min-width: 1em;
  }

  &::after {
    content: attr(data-value);
    visibility: hidden;
    white-space: pre-wrap;
    box-sizing: border-box;
  }
`;

const Span = styled.span`
  ${Reset}
  line-height: 1.5em;
  display: block;
  user-select: none;
`;

/**
 * @description
 * A prefix to be displayed before the input. Useful for currency symbols.
 *
 * Expored so you can target it with styled-components.
 */
export const NumberInputPrefix = styled(Span)`
  padding-right: 0.25rem;
  grid-area: 1 / 1;
`;

/**
 * @description
 * A suffix to be displayed before the input. Useful for currency symbols.
 *
 * Expored so you can target it with styled-components.
 */
export const NumberInputSuffix = styled(Span)`
  padding-left: 0.25rem;
  grid-area: 1 / 3;
`;

/**
 * @description
 * A number input that formats the input into a number with a given precision. It also allows for a prefix and suffix to be displayed.
 */
export const NumberInput = styled(
  forwardRef(
    (
      {
        label = '',
        precision = 2,
        prefix,
        suffix,
        max = Infinity,
        min = -Infinity,
        placeholder = '',
        delimiter = ',',
        onChange,
        ...props
      }: NumberInputProps,
      ref: ForwardedRef<HTMLDivElement>,
    ): JSX.Element => {
      const inputRef = useRef<HTMLDivElement>(null);
      const inputContentRef = useRef<HTMLInputElement>(null);
      const { value, contentValue, flags, properties, listeners } =
        useContentEditable({
          ref: inputContentRef,
          value: props.value,
          min: Number(min),
          max: Number(max),
          precision: Number(precision),
          delimiter,
        });
      const [errors, setErrors] = useState<string[]>([]);

      useFocusRedirect({ source: inputRef, target: inputContentRef });

      useEffect(() => {
        if (onChange) onChange(value);
      }, [value]);

      useEffect(() => {
        const errors = [];
        if (value === undefined) return;

        if (value > +max) {
          errors.push(`Cannot be greater than ${max}`);
        }
        if (value < +min) {
          errors.push(`Cannot be less than ${min}`);
        }

        setErrors(errors);
      }, [value]);

      return (
        <FormControl {...props} ref={ref} data-testid={`${props.id}-wrapper`}>
          {label && <InputLabel htmlFor={props.id}>{label}</InputLabel>}
          <InputWrapper tabIndex={-1} errors={errors} ref={inputRef}>
            <InputInnerWrapper data-value={contentValue}>
              {prefix && (
                <NumberInputPrefix data-testid={`${props.id}-prefix`}>
                  {prefix}
                </NumberInputPrefix>
              )}
              <InputShrine>
                <Input
                  ref={inputContentRef}
                  type="text"
                  placeholder={placeholder}
                  {...properties}
                  {...listeners}
                  role="textbox"
                  id={props.id}
                  data-testid={props.id}
                  aria-labelledby={props.id}
                  value={contentValue}
                />
              </InputShrine>
              {suffix && (
                <NumberInputSuffix data-testid={`${props.id}-suffix`}>
                  {suffix}
                </NumberInputSuffix>
              )}
            </InputInnerWrapper>
          </InputWrapper>
          {!flags.touched && errors.length ? (
            <FormError key={errors[0]} color="error">
              {errors.map((error) => (
                <span key={error}>{error}</span>
              ))}
            </FormError>
          ) : null}
        </FormControl>
      );
    },
  ),
)``;
