import styled from 'styled-components';
import { formatEther, getAddress, zeroAddress } from 'viem';

import { CardListItem } from '@hedgehog/browser/shared/funds/feature-marketplace';
import {
  BidStatus,
  ListingStatus,
  useClientListings,
} from '@hedgehog/data-access/partners';
import { SecondaryLinkButton } from '@hedgehog/ui/buttons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export type ClientPendingListingsProps = StandardProps<{
  clientAddress: string;
  isLoading?: boolean;
}>;

export const ClientPendingListings = ({
  clientAddress,
  isLoading = false,
}: ClientPendingListingsProps): JSX.Element => {
  const { data: listings, isLoading: areListingsLoading } = useClientListings({
    clientAddress: getAddress(clientAddress || zeroAddress),
    status: [ListingStatus.closed],
  });

  // TODO: This has been added as a quick fix without needing to redeploy the contracts. After
  // the GS demo, we will fix the contract and can remove this filter.
  // (filters to return only the listings WITHOUT a settled bid)
  const filteredListings = listings?.filter(
    (listing) =>
      !listing.bids.some((bid) => BidStatus[bid.status] === BidStatus.settled),
  );

  return (
    <>
      <Heading level="h5">Processing</Heading>

      <Wrapper>
        {isLoading || areListingsLoading ? (
          <CardListItem
            loading={true}
            title="Placeholder title"
            body={[
              ['Unfunded commitment', '$1,000,000'],
              ['Net asset value', '$100,000'],
            ]}
            footer={[
              <>
                <Heading level="h5" color="grey400">
                  Asking price
                </Heading>
                <Heading level="h5" loading={true}>
                  $100,000
                </Heading>
              </>,
            ]}
          />
        ) : filteredListings.length > 0 ? (
          filteredListings.map((listing) => {
            const bid = listing.bids.find((bid) => bid.status === 'accepted');
            const bidPrice = !bid?.bidPrice ? null : (
              <>
                <Heading level="h5" color="grey400">
                  Bid price
                </Heading>
                <Heading level="h5">
                  {currencies.formatMoney(Number(formatEther(bid?.bidPrice)))}
                </Heading>
              </>
            );

            return (
              <CardListItem
                title={listing.fundName}
                body={[
                  [
                    'Unfunded commitment',
                    currencies.formatMoney(
                      Number(formatEther(listing.commitment)),
                    ),
                  ],
                  [
                    'Net asset value',
                    currencies.formatMoney(
                      Number(formatEther(listing.contribution)),
                    ),
                  ],
                ]}
                footer={[
                  <>
                    <Heading level="h5" color="grey400">
                      Asking price
                    </Heading>
                    <Heading level="h5">
                      {currencies.formatMoney(
                        Number(formatEther(listing.price)),
                      )}
                    </Heading>
                  </>,
                  bidPrice,
                  <Paragraph>Pending payment</Paragraph>,
                  <SecondaryLinkButton
                    to={`funds/${listing.fundId}/listings/${listing?.listingId}/bids/${bid?.id}/accept/confirmed`}
                  >
                    Settle now
                  </SecondaryLinkButton>,
                ]}
              />
            );
          })
        ) : (
          <Paragraph>This client doesn't have any pending listings.</Paragraph>
        )}
      </Wrapper>
    </>
  );
};
