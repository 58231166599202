import { gql } from '@apollo/client';

export const ContentBlockDataFragment = gql`
  fragment ContentBlockDataFragment on ContentBlock {
    type
    data {
      __typename
      ... on ContentTitleBodyBlock {
        title
        description
      }
      ... on ContentTenantsBlock {
        items {
          name
          type
          logo
          logoAlt
          since
          link
          description
          coverColor
        }
      }
      ... on ContentTimelineBlock {
        title
        description
        items {
          icon
          year
          content
        }
      }
      ... on ContentKeyValueListBlock {
        title
        description
        items {
          label
          value
        }
      }
      ... on ContentBulletListBlock {
        items {
          icon
          title
          description
        }
      }
      ... on ContentTabsBlock {
        title
        description
        tabs {
          name
          items {
            label
            value
          }
        }
      }
      ... on ContentRisksAndMitigationsBlock {
        title
        description
        items {
          risk
          mitigation
          mitigationDetails
        }
      }
      ... on ContentYoutubeBlock {
        title
        url
      }
      ... on ContentMapBlock {
        mapUrl
        imageUrl
      }
      ... on ContentDownloadActionCardsBlock {
        items {
          url
          title
        }
      }
      ... on ContentGalleryBlock {
        urls {
          src
          kind
          alt
          thumbnailSrc
          heading
          text
        }
      }
      ... on ContentQuestionBlock {
        question {
          id
          questions
          answers {
            id
            options {
              label
              value
              warning
              invalid
            }
          }
          description
          markdown
        }
      }
      ... on ContentHighlightCardBlock {
        title
        description
        items {
          imageUrl
          title
          description
        }
      }
      ... on ContentDisclaimerBlock {
        content
        modal {
          title
          content
          footnote
        }
      }
      ... on ContentMarkdownBlock {
        content
      }
      ... on ContentMetricsBlock {
        items {
          title
          value
          tooltip {
            title
            content
          }
        }
      }
    }
  }
`;
