export const GiftIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3V0H5C6.65685 0 8 1.34315 8 3C8 1.34315 9.34315 0 11 0H13V3H16V6H0V3H3Z"
      fill="currentColor"
    />
    <path d="M1 8H7V15H1V8Z" fill="currentColor" />
    <path d="M15 8H9V15H15V8Z" fill="currentColor" />
  </svg>
);
