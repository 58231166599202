import { getQuarter, getYear } from 'date-fns';
import Emoji from 'react-emoji-render';
import { useParams } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { Asset } from '@hedgehog/data-access/asset-types';
import { useAssetRoundClass } from '@hedgehog/data-access/partners';
import { AssetCard } from '@hedgehog/ui/assets';
import { Button } from '@hedgehog/ui/buttons';
import { parseBorderToShadow } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';
import { Paragraph } from '@hedgehog/ui/typography';

const ActiveOrNotAssetCard = styled(AssetCard)<{
  active?: boolean;
  $hoverColor?: string;
}>`
  ${({ active, theme }) => ({
    boxShadow: active
      ? parseBorderToShadow(theme.border.small, {
          color: theme.colors.grey100,
          outset: false,
        })
      : undefined,
    ['&:after, &:hover:after']: {
      boxShadow: active
        ? parseBorderToShadow(theme.border.normal, {
            color: theme.colors.grey100,
            outset: false,
          })
        : undefined,
    },
  })};
`;

type AssetListItemProps = {
  asset: Asset;
};

const AssetEmoji = styled(Emoji)`
  ${({ theme }): CSSProp => ({ marginRight: theme.spacing.xxsmall })}
`;

export const AssetListItem = ({ asset }: AssetListItemProps) => {
  const { thumbnails = [] } = asset;
  const roundClass = useAssetRoundClass(asset.id);
  const { assetId } = useParams();

  if (!roundClass) {
    return null;
  }

  const caption = roundClass.minimumInvestmentAmount ? (
    <>
      <Paragraph small weight={400}>
        <AssetEmoji text=":sparkles:" />
        Ongoing
      </Paragraph>
    </>
  ) : (
    <>
      <Paragraph small weight={400}>
        Coming soon
      </Paragraph>
      {roundClass.liveAt && (
        <Heading level="h7">
          {`${getQuarter(new Date(roundClass.liveAt))}Q ${getYear(
            new Date(roundClass.liveAt),
          )}`}
        </Heading>
      )}
    </>
  );

  return (
    <ActiveOrNotAssetCard
      key={asset.id}
      active={assetId === asset.id}
      to={`/assets/${asset.id}`}
      type="compact"
      title={asset.name}
      caption={caption}
      action={<Button small>View</Button>}
      thumbnails={thumbnails
        .slice(0, asset.underlyingAssetCount)
        .map((thumbnail) => thumbnail.src)}
    />
  );
};
