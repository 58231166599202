import { Outlet } from 'react-router-dom';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import { Restrict, TeamMembersList } from '@hedgehog/browser/partners/team';
import { RoleCapability } from '@hedgehog/shared/types';
import { PrimaryButton } from '@hedgehog/ui/buttons';
import { Column, MobileColumn } from '@hedgehog/ui/layouts';
import { useModal } from '@hedgehog/ui/modals';

import { InviteTeamMemberModal } from '../../containers';

export const TeamPage = (): JSX.Element => {
  const { openAndResolveWithSubmitValue: showCreateModal } = useModal(
    (modalProps) => <InviteTeamMemberModal {...modalProps} />,
  );
  return (
    <PageColumnLayout
      title="Team"
      actions={
        <Restrict capability={RoleCapability.ADD_TEAM_MEMBER}>
          <PrimaryButton small icon="plus-circle" onClick={showCreateModal}>
            Add team member
          </PrimaryButton>
        </Restrict>
      }
    >
      <Column size="minmax(min-content, 25rem)">
        <TeamMembersList />
      </Column>
      <MobileColumn match="/team/:teamMemberId" fallbackTo="..">
        <Outlet />
      </MobileColumn>
    </PageColumnLayout>
  );
};
