import { createContext, ReactNode, useContext } from 'react';
import { Outlet } from 'react-router-dom';

type Context = {
  onAnalyticsTrack: (
    eventType: string,
    eventName: string,
    metadata?: Record<string, unknown>,
    options?: Record<string, unknown>,
  ) => void;
};

export const CMSAnalyticsTrackContext = createContext<Context>({
  onAnalyticsTrack: () => void 0,
});

export function useCMSAnalyticsTrack(): Context['onAnalyticsTrack'] {
  const { onAnalyticsTrack } = useContext(CMSAnalyticsTrackContext);
  return onAnalyticsTrack;
}

type CMSAnalyticsTrackProviderProps = {
  onAnalyticsTrack?: Context['onAnalyticsTrack'];
  children?: ReactNode | ReactNode[];
};

export const CMSAnalyticsTrackProvider = ({
  onAnalyticsTrack = () => void 0,
  children,
}: CMSAnalyticsTrackProviderProps): JSX.Element => {
  return (
    <CMSAnalyticsTrackContext.Provider
      value={{
        onAnalyticsTrack,
      }}
    >
      {children || <Outlet />}
    </CMSAnalyticsTrackContext.Provider>
  );
};
