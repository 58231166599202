import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';

import { useQueryParams } from '@hedgehog/data-access/hooks';

export const useSourceTracking = (): void => {
  const queryParams = useQueryParams();
  const location = useLocation();
  const marketingSource = queryParams.get('marketingsource');

  if (marketingSource) {
    Cookies.set('marketingsource', marketingSource, {
      domain: 'hedgehog-invest.com',
    });
  }

  const referredBy = queryParams.get('referredBy') || Cookies.get('referredBy');
  if (referredBy) {
    localStorage.setItem('referredBy', referredBy);
  }

  // Don't update utm tags on signup_confirm page, as this page is navigated to from an email
  // This makes sure we always keep the original utm tages in the signup journey
  if (location.pathname !== '/signup_confirm') {
    const keys = Array.from(queryParams.keys());
    const utmKeys = keys.filter((key) => key.startsWith('utm_'));

    utmKeys.forEach((key) =>
      Cookies.set(key, queryParams.get(key) || '', {
        domain: 'hedgehog-invest.com',
      }),
    );
  }
};
