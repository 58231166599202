import { useState } from 'react';

import {
  useAppData,
  useCreateClientMutation,
} from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';
import { SelectInput } from '@hedgehog/ui/inputs';

import { ModalForm } from '../../components';
import { AddFundClientsForm } from '../add-fund-clients-form/add-fund-clients-form.container';
import { ImportLimitedPartnerRow } from '../add-fund-clients-form/csv-parser.helpers';
import { initialFormData } from '../create-client-form/create-client-form.container';

export type AddFundClientsModalProps = {
  funds: any[];
  submitModal: () => void;
  cancelModal: () => void;
  onSuccess: (
    fundRef: string,
    fundId: number,
    rows: ImportLimitedPartnerRow[],
    partnerClients: PartnerClient[],
  ) => void;
};

export const AddFundClientsModal = ({
  funds,
  onSuccess,
  submitModal,
  cancelModal,
}: AddFundClientsModalProps): JSX.Element => {
  const { activePartner } = useAppData();
  const [rows, setRows] = useState<ImportLimitedPartnerRow[]>([]);
  const [createPartnerClient] = useCreateClientMutation();
  const [fundId, setFundId] = useState<number>();
  const [fundRef, setFundRef] = useState<string>('');

  const createClient = async (
    row: ImportLimitedPartnerRow,
    partnerId: UUID,
  ): Promise<PartnerClient> => {
    const { email, name, surname, country, type } = row.data;
    return createPartnerClient({
      ...initialFormData,
      sendInvitationEmail: false,
      partnerId,
      partnerClientType: type,
      email,
      country,
      firstName: name,
      lastName: surname,
    }).unwrap();
  };

  const handleSubmitModal = async () => {
    if (!activePartner) throw new Error('No active partner');
    if (!fundId) throw new Error('No fund id');
    const promises = rows.map((row) => createClient(row, activePartner.id));
    const partnerClients = await Promise.all(promises);
    submitModal();
    onSuccess(fundRef, fundId, rows, partnerClients);
  };

  const handleSelectChange = async (e: number) => {
    const fund = funds.find((f) => f.blockchainId === e);
    if (!fund) return;
    setFundRef(fund.hashedBlockchainReference);
    setFundId(fund.blockchainId);
  };

  return (
    <ModalForm
      submitModal={handleSubmitModal}
      closeModal={cancelModal}
      heading="Import clients"
      footbar={rows.length !== 0}
      submitButtonText="Add clients"
      closeButtonText="Close"
      // submit button should be disabled if any of the rows have errors, or if fundId is not set
      disabled={
        rows.some((row) => row.errors && row.errors.length > 0) || !fundId
      }
    >
      <AddFundClientsForm rows={rows} onChange={setRows} />
      <br />
      <SelectInput<number>
        label="Fund"
        placeholder="Select Fund"
        options={funds}
        renderKey={({ blockchainId }) => blockchainId}
        renderValue={({ blockchainId }) => blockchainId}
        renderOption={({ name }) => name}
        onChange={(e) => e && handleSelectChange(e)}
      />
      <br />
    </ModalForm>
  );
};
