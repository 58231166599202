import { useContext, useRef } from 'react';

import { ModalContext } from './modal-provider/modal-provider.component';
import {
  ModalComponentRef,
  ModalOptions,
  ModalRejectFn,
  ModalResolveFn,
  ModalResolver,
} from './modal.types';

export interface UseModalOutput<V, P = unknown> {
  /**
   * Opens modal immediately and if there's one already
   * active, it will get dismount and rejected with ModalAbortError.
   *
   * Promise which resolves argument that submitModal has been invoked with.
   */
  openAndResolveWithSubmitValue: (initialProps?: P) => Promise<V>;
  /**
   * Closes modal immediately and rejects it with ModalAbortError.
   *
   * Promise throws ModalAbortError that can be handled in catch field.
   */
  abort: () => Promise<V | null>;
}

export function useModal<V = unknown, P = unknown>(
  componentRef: ModalComponentRef<P>,
  options: ModalOptions = { throwOnAbort: false },
): UseModalOutput<V, P> {
  const modal = useContext(ModalContext);
  const resolverRef = useRef<ModalResolver<V> | null>(null);
  const promiseRef = useRef<Promise<V> | null>(null);

  const openAndResolveWithSubmitValue = (initialProps?: P): Promise<V> => {
    promiseRef.current = new Promise((resolve, reject) => {
      const resolver = {
        resolve: resolve as ModalResolveFn,
        reject: reject as ModalRejectFn,
      };
      resolverRef.current = resolver;
      modal.open({ componentRef, resolver, options, initialProps });
    });

    return promiseRef.current;
  };

  const abort = async (): Promise<V | null> => {
    modal.closeAll();
    return promiseRef.current;
  };

  return { openAndResolveWithSubmitValue, abort };
}
