import { ethers } from 'ethers';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { UserOperationEventEvent } from 'userop/dist/typechain/EntryPoint';

import { CapitalCallNoticeIssuedDataSchema } from '@hedgehog/shared/blockchain-types';
import { useCacheValidate } from '@hedgehog/browser/shared/utils';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { useFundManagerUserOperation } from './use-fund-manager-contract.hook';

type CallbackParams = {
  fundRef: string;
  fundId: number;
  amount: number;
  data: CapitalCallNoticeIssuedDataSchema;
};

type Callback = (params: CallbackParams) => Promise<void>;

type Result = {
  loading: boolean;
  error?: string;
  userOp?: UserOperationEventEvent;
};

export const useIssueCapitalCall = (): [Callback, Result] => {
  const userOperation = useFundManagerUserOperation({
    functionName: 'issueCapitalCall(bytes32,uint32,uint256,bytes)',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [userOp, setUserOp] = useState<UserOperationEventEvent | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | undefined>(undefined);
  const clear = useCacheValidate();

  const callback = async ({
    fundRef,
    fundId,
    amount,
    data,
  }: CallbackParams): Promise<void> => {
    try {
      setLoading(true);

      const pendingOperation = userOperation([
        fundRef,
        fundId,
        // save amount in wei to avoid rounding errors
        ethers.utils.parseEther(amount.toString()),
        ethers.utils.toUtf8Bytes(JSON.stringify(data)),
      ]).then((userOp) => {
        if (userOp.args.success === false) {
          console.log(userOp);
          throw new Error('Something went wrong, please try again later.');
        }
        return userOp;
      });
      toast.promise(
        pendingOperation,
        {
          pending: {
            render: () => (
              <Paragraph small color="grey500">
                Talking to the blockchain, please stand by.
              </Paragraph>
            ),
          },
          success: {
            render: () => {
              return (
                <Paragraph small color="grey500">
                  Your capital call was successfully issued.
                </Paragraph>
              );
            },
          },
          error: {
            render: () => (
              <>
                <Heading level="h7">Sorry, something went wrong.</Heading>
                <Paragraph small color="grey500">
                  Your capital call was not created. Please try again later.
                </Paragraph>
              </>
            ),
          },
        },
        {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        },
      );
      const userOp = await pendingOperation;
      clear('capitalCallsBlockchainQuery');

      setUserOp(userOp);
    } catch (e: any) {
      console.log(e);
      if (e instanceof Error) {
        setError(e.message);
      } else {
        setError('Something went wrong, please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  return [callback, { loading, error, userOp }];
};
