import { Web3Auth } from '@web3auth/single-factor-auth';
import { ethers } from 'ethers';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Client, Presets } from 'userop';
import { UserOperationEventEvent } from 'userop/dist/typechain/EntryPoint';

import { HedgehogUserAccountUserOperationBuilder } from '@hedgehog/blockchain/erc4337';
import { useAuth } from '@hedgehog/data-access/contexts';
import {
  NativeMobileExperiences,
  sendMessageWithPayloadToApp,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';
import { useEnvironment } from '@hedgehog/ui/environment';

import { useLazyAccountWallet, useWeb3Auth } from '../../hooks';

import {
  AccountAbstractionContext,
  context,
  SendUserOperationCallback,
} from './account-abstraction.context';

export interface AccountAbstractionProviderProps {
  onAddressChange?: (address?: string) => void;
  children?: ReactNode | ReactNode[];
}

export const AccountAbstractionProvider = ({
  onAddressChange,
  children,
}: AccountAbstractionProviderProps): JSX.Element => {
  // Load all the config values from the environment file.
  const {
    blockchain: { rpcUrl },
    erc4337: {
      entryPointAddress,
      accountFactoryAddress,
      bundlerUrl,
      paymasterUrl,
    },
    production: isProduction,
  } = useEnvironment();
  const web3auth = useWeb3Auth();
  const { accessToken } = useAuth();
  const [{ connect, disconnect }, { wallet }] = useLazyAccountWallet();

  // Access current route
  const location = useLocation();
  const [address, setAddress] = useState<string>();
  const [jsonRpcProvider] = useState<ethers.providers.StaticJsonRpcProvider>(
    new ethers.providers.StaticJsonRpcProvider(rpcUrl),
  );
  const [userOpBuilder, setUserOpBuilder] =
    useState<HedgehogUserAccountUserOperationBuilder>();
  const [userOpClient, setUserOpClient] = useState<Client>();

  const iosDevice = useIsNativeMobileExperience(
    NativeMobileExperiences.DEVICE_IOS,
  );

  /**
   * Wallet initialisation
   */

  const initUserOpClient = async (): Promise<Client> => {
    const client = await Client.init(bundlerUrl, entryPointAddress);
    setUserOpClient(client);
    return client;
  };

  const initUserOpBuilder = async ({
    signer,
  }: {
    signer: ethers.Wallet;
  }): Promise<HedgehogUserAccountUserOperationBuilder> => {
    const paymasterMiddleware = Presets.Middleware.verifyingPaymaster(
      paymasterUrl,
      { type: 'payg' }, // Use the stackup.sh pay-as-you-go paymaster
    );

    const builder = await HedgehogUserAccountUserOperationBuilder.init(
      signer,
      bundlerUrl,
      entryPointAddress,
      accountFactoryAddress,
      paymasterMiddleware,
    );
    setUserOpBuilder(builder);
    setAddress(builder.getSender());
    return builder;
  };

  const clear = (): void => {
    disconnect();
    setUserOpBuilder(undefined);
  };

  const init = async (
    web3auth: Web3Auth,
  ): Promise<{
    web3auth: Web3Auth;
    client: Client;
    builder: HedgehogUserAccountUserOperationBuilder;
    rpc: ethers.providers.StaticJsonRpcProvider;
  }> => {
    const innerWallet = wallet
      ? wallet
      : await connect({
          web3auth,
        });
    if (!innerWallet) throw new Error('Unable to connect to Web3Auth network');
    const innerClient = userOpClient ? userOpClient : await initUserOpClient();
    const innerBuilder = userOpBuilder
      ? userOpBuilder
      : await initUserOpBuilder({ signer: innerWallet });

    return {
      web3auth,
      client: innerClient,
      builder: innerBuilder,
      rpc: jsonRpcProvider,
    };
  };

  /**
   * Wallet methods
   */
  const sendUserOperation = async (
    callback: SendUserOperationCallback,
    callGasLimit?: ethers.BigNumber,
  ): Promise<UserOperationEventEvent | null> => {
    if (!web3auth.instance) {
      throw new Error(
        'expected account abstraction to be initialized before executing sendUserOperation',
      );
    }
    const { client, builder } = await init(web3auth.instance);

    // Update maxPriorityFeePerGas and maxFeePerGas on the builder based on
    // current gas prices
    const feeData = {
      maxPriorityFeePerGas: ethers.BigNumber.from(0),
      maxFeePerGas: ethers.BigNumber.from(0),
    };

    // In production, we use the gas prices from the Polygon gas station, in dev and qa
    // we can use getFeeData() to get the gas prices from node
    // See https://github.com/ethers-io/ethers.js/discussions/3018#discussioncomment-2822182 for the reason we use the gas station endpoint
    // for mainnet.
    try {
      if (isProduction) {
        const gasStationGasPrices = await fetch(
          'https://gasstation-mainnet.matic.network/v2',
        ).then((res) => res.json());
        feeData.maxPriorityFeePerGas = ethers.BigNumber.from(
          gasStationGasPrices.fast.maxPriorityFee,
        );
        feeData.maxFeePerGas = ethers.BigNumber.from(
          gasStationGasPrices.fast.maxFee,
        );
      } else {
        const prices = await jsonRpcProvider.getFeeData();
        feeData.maxPriorityFeePerGas = ethers.BigNumber.from(
          prices.maxPriorityFeePerGas,
        );
        feeData.maxFeePerGas = ethers.BigNumber.from(prices.maxFeePerGas);
      }
    } catch (e) {
      console.warn('Failed to get gas prices, falling back to defaults', e);
      // fallback to reasonable defaults
      feeData.maxPriorityFeePerGas = ethers.utils.parseUnits('50', 'gwei');
      feeData.maxFeePerGas = ethers.utils.parseUnits('250', 'gwei');
    }

    builder.setMaxPriorityFeePerGas(feeData.maxPriorityFeePerGas);
    builder.setMaxFeePerGas(feeData.maxFeePerGas);
    builder.setCallGasLimit(callGasLimit || ethers.BigNumber.from(2500000));

    const res = await client.sendUserOperation(callback(builder));

    return res.wait();
  };

  useEffect(() => {
    onAddressChange && onAddressChange(address);
    if (address) {
      localStorage.setItem('ERC4337_ADDRESS', address);
      sendMessageWithPayloadToApp('accountAbstraction.address', address);
    } else {
      localStorage.removeItem('ERC4337_ADDRESS');
    }
  }, [address]);

  // Initialise Web3Auth and the userOpClient when the app loads
  useEffect(() => {
    if (!accessToken) return;
    if (!web3auth.instance) return;
    if (userOpClient) return; // Do not re-initialise if already initialised
    init(web3auth.instance).catch(console.warn);
  }, [accessToken, userOpClient, web3auth.instance]);

  // useEffect to fire when app loads and web3auth initialises, check for 'token' in localstorage and connectWeb3Auth
  // also fire when accessToken changes (i.e. user logs in)
  // We get the token from localstorage because the iOS app doesn't pass the token to the webview
  useEffect(() => {
    // For the iOS app, only perform the actions for /explore page to avoid
    // race conditions between the embedded webviews.
    if (iosDevice && location.pathname !== '/explore') return;
  }, [web3auth, Boolean(accessToken)]);

  const contextValue: AccountAbstractionContext = {
    address,
    jsonRpcProvider,
    userOpBuilder,
    sendUserOperation,
    clear,
    loading: !(address && jsonRpcProvider),
  };

  return <context.Provider value={contextValue}>{children}</context.Provider>;
};

export default AccountAbstractionProvider;
