import { Address } from 'viem';

import { UseFundWriteReturn, useFundWrite } from './core';

export interface UseCreateListingProps {
  address: Address;
  commitment: bigint;
  contribution: bigint;
  price: bigint;
}

export const useCreateListing = ({
  address,
  commitment,
  contribution,
  price,
}: UseCreateListingProps): UseFundWriteReturn => {
  return useFundWrite({
    address,
    functionName: 'createListing',
    scopeKey: 'funds/listings',
    args: [commitment, contribution, price],
  });
};
