export const formatNumber = (value: number, precision = 0): string => {
  const formatter = Intl.NumberFormat('en-US', {
    minimumFractionDigits: precision,
    maximumFractionDigits: precision,
  });
  return formatter.format(value);
};

export const clamp = (
  min: number,
  max: number,
): ((value: number) => number) => {
  return (value: number): number => {
    return Math.min(max, Math.max(value, min));
  };
};
