import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { AssetStatus } from '@hedgehog/browser/shared/assets';
import {
  AssetCategory,
  AssetRoundClassStatus,
} from '@hedgehog/data-access/graphql';
import { Header, HeaderButton } from '@hedgehog/ui/headers';
import { LayeredIcons, ThemeAvatar } from '@hedgehog/ui/icons';
import { HideBreakpoint } from '@hedgehog/ui/layouts';
import { Heading } from '@hedgehog/ui/typography';
import { screens } from '@hedgehog/utils/sizes';

const Title = styled(Heading)`
  font-size: 2rem;
  line-height: 2.25rem;
`;

const Container = styled.header`
  color: ${({ theme }): string => theme.colors.black};
`;

export const AssetThemeAvatars = styled(LayeredIcons)`
  // Only desktop, ensure the avatar border matches the card background
  @media only screen and (min-width: ${screens.medium}px) {
    ${ThemeAvatar},
    ${ThemeAvatar}:not(:first-of-type:last-of-type) {
      ${({ theme }): CSSObject => ({ borderColor: theme.colors.grey100 })};
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }): string => theme.colors['shadow100']};
`;

export const HeaderInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${Header} {
    background: none;
    padding: 0;
    margin-bottom: 1rem;
  }
`;

export interface AssetPageHeaderProps {
  title: React.ReactNode | React.ReactNode[];
  backPath?: string;
  themes: AssetCategory[];
  tenantToken?: boolean;
  status?: AssetRoundClassStatus;
  progress?: number;
  className?: string;
}

export const AssetPageHeader = ({
  title,
  backPath = '/explore',
  themes,
  tenantToken = false,
  status = AssetRoundClassStatus.LIVE,
  progress = 0,
  className,
}: AssetPageHeaderProps): JSX.Element => {
  return (
    <Container className={className}>
      <HeaderInnerWrapper>
        <Header
          left={
            <AssetThemeAvatars
              icons={
                tenantToken
                  ? [
                      {
                        alt: 'UNO',
                        src: 'https://assets.hedgehog-invest.com/partners/uno/logotype.svg',
                      },
                    ]
                  : []
              }
              themes={themes}
            />
          }
          right={
            <HideBreakpoint from="medium">
              <HeaderButton icon="cross" to={backPath} />
            </HideBreakpoint>
          }
        />
        <Title level="h3">{title}</Title>
        <Divider />
        <AssetStatus status={status} progress={progress} />
      </HeaderInnerWrapper>
    </Container>
  );
};
