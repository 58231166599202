import { gql } from '@apollo/client';

export const UPDATE_ASSET_FEEDBACK = gql`
  mutation UpdateAssetFeedback($slug: String!, $score: Int!) {
    updateAssetFeedback(args: { slug: $slug, score: $score }) {
      ... on UpdateAssetFeedbackPayload {
        success
      }
    }
  }
`;

export const REMOVE_ASSET_FEEDBACK = gql`
  mutation RemoveAssetFeedback($slug: String!) {
    removeAssetFeedback(args: { slug: $slug }) {
      ... on RemoveAssetFeedbackPayload {
        success
      }
    }
  }
`;
