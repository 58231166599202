import { AssetRoundClass } from '@hedgehog/data-access/asset-types';
import { UUID } from '@hedgehog/shared/types';

import { useSelector } from '../store';
import { selectTeamMember } from '../store/apis';

import { useAssetRound } from './use-asset-round.hook';

export const useAssetRoundClass = (assetId: UUID): AssetRoundClass | null => {
  const round = useAssetRound(assetId);
  const teamMember = useSelector(selectTeamMember);
  if (!round || !teamMember?.country) return null;

  const teamMemberCountry = teamMember.country.toLowerCase();
  const predicate = (value: AssetRoundClass) =>
    value.country.toLowerCase() === teamMemberCountry;

  return round.roundClasses.find(predicate) || null;
};
