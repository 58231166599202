import { useEffect } from 'react';
import styled from 'styled-components';
import { formatEther, getAddress, zeroAddress } from 'viem';

import {
  CardListItemContent,
  CardListItem,
  ListingQuantityInput,
} from '@hedgehog/browser/shared/funds/feature-marketplace';
import { useBalance } from '@hedgehog/data-access/partners';
import { BottomSheetContent } from '@hedgehog/ui/modals';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm, ModalFormContent } from '../../../../components';
import {
  useMarketListingBalance,
  useMarketListingLoaders,
  useMarketListingParams,
  useNavigateToListingPage,
} from '../../../../containers';

const CustomModalForm = styled(ModalForm)`
  & > ${BottomSheetContent} {
    // Warning: If this is removed then you will experience the bug described in HOG-3012.
    overflow: unset;
  }

  & ${ModalFormContent} {
    display: flex;
    flex-flow: column nowrap;
    gap: 2rem;
  }
`;

export type MarketListingPositionCreatePageProps = StandardProps<{
  isFirstPage?: boolean;
}>;

export const MarketListingPositionCreatePage = ({
  isFirstPage = false,
}: MarketListingPositionCreatePageProps): JSX.Element => {
  const { navigateTo, close } = useNavigateToListingPage();
  const [balance, updateBalance] = useMarketListingBalance();
  const { areClientsLoading, areFundsLoading } = useMarketListingLoaders();
  const [params, updateParams] = useMarketListingParams();
  const { error = null } = {};

  const [, { data: clientBalance, isLoading: isBalanceLoading }] = useBalance({
    fundAddress: getAddress(params.fundContractAddress || zeroAddress),
    clientAddress: getAddress(params.clientWalletAddress || zeroAddress),
  });

  useEffect(() => {
    if (!clientBalance) return;
    updateBalance({
      commitment: Number(formatEther(clientBalance?.commitment ?? 0)),
      contribution: Number(formatEther(clientBalance?.contribution ?? 0)),
    });
  }, [clientBalance]);

  const isLoading = isBalanceLoading || areClientsLoading || areFundsLoading;

  return (
    <CustomModalForm
      loading={isLoading}
      submitModal={() => navigateTo(`create/position/confirm`)}
      backModal={() =>
        isFirstPage ? undefined : navigateTo('create/select-client')
      }
      closeModal={() => close()}
      heading="List a position"
      submitButtonText="Continue"
      error={error ? 'Something went wrong' : undefined}
    >
      <CardListItem
        loading={isLoading}
        title={params.fund?.name}
        body={[
          ['Unfunded commitment', currencies.formatMoney(balance.commitment)],
          ['Contributions', currencies.formatMoney(balance.contribution)],
        ]}
        footer={[
          [
            'Net Asset Value',
            <Paragraph key="nav">
              <strong>{currencies.formatMoney(balance.contribution)}</strong>
            </Paragraph>,
          ],
        ]}
      />

      <ListingQuantityInput
        loading={isLoading}
        contributions={{
          max: balance.contribution,
        }}
        unfundedCommitment={{
          max: balance.commitment,
          ratio: params.positionRatio,
        }}
        askingPrice={params.positionPrice}
        onAskingPriceChange={(value: number) => {
          updateParams({ positionPrice: value });
        }}
        onUnfundedCommitmentClick={() => {
          navigateTo(`create/position/ratio`);
        }}
      />

      <CardListItemContent
        loading={isLoading}
        body={[
          [
            'Unfunded commitment for sale',
            currencies.formatMoney(balance.commitment * params.positionRatio),
          ],
          [
            'Net Asset Value',
            currencies.formatMoney(balance.contribution * params.positionRatio),
          ],
        ]}
        footer={[
          [
            'Asking price',
            <Paragraph key="net-asset-value">
              <strong>{currencies.formatMoney(params.positionPrice)}</strong>
            </Paragraph>,
          ],
        ]}
      />
    </CustomModalForm>
  );
};
