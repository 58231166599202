import * as Sentry from '@sentry/react';

import { CustomWindow } from '@hedgehog/browser/shared/utils';

declare let window: CustomWindow;

export interface StartSentryProps {
  dsn: string;
  environment: string;
  release?: string;
}

export const StartSentry = ({
  dsn,
  environment,
  release,
}: StartSentryProps): void => {
  // Don't initialise Sentry from local dev
  // Don't initialise Sentry from synthetic tests
  const isNotDevelopment = environment !== 'local';
  const isNotSyntheticTest = !window._DATADOG_SYNTHETICS_BROWSER;
  if (isNotDevelopment && isNotSyntheticTest) {
    Sentry.init({
      dsn,
      environment,
      integrations: [
        new Sentry.BrowserTracing({
          // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
          // Regex allows hedgehog-invest.com and all subdomains
          tracePropagationTargets: [/https:\/\/.*\.hedgehog-invest\.com/],
        }),
      ],
      release,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
  }
};
