export const MapPinIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6V6.29266C0 7.72154 0.486301 9.10788 1.37892 10.2236L6 16L10.6211 10.2236C11.5137 9.10788 12 7.72154 12 6.29266V6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
      fill="currentColor"
    />
  </svg>
);

export default MapPinIcon;
