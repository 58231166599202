import { useEffect } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { useMediaQuery } from '@hedgehog/data-access/hooks';
import {
  useAppData,
  useTeamMembersQuery,
} from '@hedgehog/data-access/partners';

export const TeamMemberNavigateTo = (): JSX.Element => {
  const { activePartnerId } = useAppData();
  const navigate = useNavigate();
  const theme = useTheme();
  const { matches: isTabletOrAbove } = useMediaQuery(
    `only screen and (min-width: ${theme.breakpoints.tablet}px)`,
  );

  const teamMemberMatch = useMatch('/team/:teamMemberId');
  const { data: teamMembers, ...teamMembersQuery } = useTeamMembersQuery(
    { partnerId: activePartnerId ?? '' },
    { skip: !activePartnerId },
  );

  useEffect(() => {
    if (!isTabletOrAbove) return;
    if (teamMemberMatch) return;
    if (teamMembersQuery.isFetching) return;
    if (!teamMembers) return;
    if (teamMembers.length === 0) return;
    const [primary] = teamMembers;

    navigate(`/team/${primary.teamMemberId}`);
  }, [teamMembers, teamMemberMatch, teamMembersQuery]);

  return <Outlet />;
};
