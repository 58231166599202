import {
  GetAssetsRequest,
  GetAssetsResponse,
  GetAssetRequest,
  GetAssetResponse,
  GetAssetPagesResponse,
  GetAssetPagesRequest,
} from '@hedgehog/data-access/asset-types';

import { baseApi } from './base.api';

export const assetsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    assets: builder.query<GetAssetsResponse['data'], GetAssetsRequest>({
      query: ({ partnerId }) => ({
        url: `partners/${partnerId}/assets`,
      }),
      transformResponse: (response: GetAssetsResponse) => response.data,
    }),
    asset: builder.query<GetAssetResponse['data'], GetAssetRequest>({
      query: ({ assetId, partnerId }) => ({
        url: `partners/${partnerId}/assets/${assetId}`,
      }),
      transformResponse: (response: GetAssetResponse) => response.data,
    }),
    assetPages: builder.query<
      GetAssetPagesResponse['data'],
      GetAssetPagesRequest
    >({
      query: ({ assetId, partnerId }) => ({
        url: `partners/${partnerId}/assets/${assetId}/pages`,
      }),
      transformResponse: (response: GetAssetPagesResponse) => response.data,
    }),
  }),
});

export const { useAssetsQuery, useAssetQuery, useAssetPagesQuery } = assetsApi;
