import {
  tokens,
  parsePixelsProperty,
  parsePixels,
  parseColorProperty,
  parseWeightProperty,
} from '../figma';
import { Theme, ThemeColors } from '../theme.interface';

const colors: ThemeColors & { [key: string]: string } = {
  black: tokens.colors.black,
  white: tokens.colors.white,
  grey100: tokens.colors.grey_1,
  grey200: tokens.colors.grey_2,
  grey300: tokens.colors.grey_3,
  grey400: tokens.colors.grey_4,
  grey500: tokens.colors.grey_5,
  shadow100: tokens.colors.tr_dark_10,
  shadow200: tokens.colors.tr_dark_20,
  shadow400: tokens.colors.tr_dark_40,
  shadow600: tokens.colors.tr_dark_60,
  shadow800: tokens.colors.tr_dark_80,
  light100: tokens.colors.tr_light_10,
  light200: tokens.colors.tr_light_20,
  light400: tokens.colors.tr_light_40,
  light600: tokens.colors.tr_light_60,
  light800: tokens.colors.tr_light_80,
  primary: tokens.colors.primary,
  secondary: tokens.colors.secondary,
  success: tokens.colors.success,
  successLight: tokens.colors.success_light,
  error: tokens.colors.error,
  errorLight: tokens.colors.error_light,
  positive: tokens.colors.positive,
  negative: tokens.colors.negative,
  neutral: tokens.colors.neutral,
  hedgehog: '#FA5528',
  transparent: 'transparent',
  inherit: 'inherit',
  /**
   * Temporary, about to be removed
   * onced fully migrated to light
   * theme.
   */
  peach: tokens.colors.secondary,
  skyDark: tokens.colors.secondary,
  sky200: tokens.colors.secondary,
  magentaDark: tokens.colors.secondary,
  earth: tokens.colors.secondary,
  tree200: tokens.colors.secondary,
  lavender: tokens.colors.secondary,
};

const typography = {
  h1: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h1, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ), // not defined yet
  h2: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h2, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  h3: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h3, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  h4: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h4, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  h5: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h5, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  h6: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h6, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  h7: parseWeightProperty(
    parsePixelsProperty(tokens.typography.h7, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  body: parseWeightProperty(
    parsePixelsProperty(tokens.typography.body, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  body_small: parseWeightProperty(
    parsePixelsProperty(tokens.typography.body_small, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  button: parseWeightProperty(
    parsePixelsProperty(tokens.typography.button, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  button_small: parseWeightProperty(
    parsePixelsProperty(tokens.typography.button_small, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  note: parseWeightProperty(
    parsePixelsProperty(tokens.typography.note, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
  label: parseWeightProperty(
    parsePixelsProperty(tokens.typography.label, {
      propertyNames: ['fontSize', 'lineHeight'],
    }),
  ),
};

const spacing = {
  xxxsmall: parsePixels(tokens.spacing.spacing_4),
  xxsmall: parsePixels(tokens.spacing.spacing_4),
  xsmall: parsePixels(tokens.spacing.spacing_8),
  small: parsePixels(tokens.spacing.spacing_12),
  normal: parsePixels(tokens.spacing.spacing_16),
  large: parsePixels(tokens.spacing.spacing_24),
  xlarge: parsePixels(tokens.spacing.spacing_32),
  xxlarge: parsePixels(tokens.spacing.spacing_40),
  xxxlarge: parsePixels(tokens.spacing.spacing_48),
};

const border = {
  small: parsePixelsProperty(
    parseColorProperty(tokens.border.border_1, 'color'),
    { propertyNames: ['width'] },
  ),
  normal: parsePixelsProperty(
    parseColorProperty(tokens.border.border_2, 'color'),
    { propertyNames: ['width'] },
  ),
  large: parsePixelsProperty(
    parseColorProperty(tokens.border.border_4, 'color'),
    { propertyNames: ['width'] },
  ),
  active: parsePixelsProperty(
    parseColorProperty(tokens.border.border_active, 'color'),
    { propertyNames: ['width'] },
  ),
  special: parsePixelsProperty(
    parseColorProperty(tokens.border.border_special_light, 'color'),
    { propertyNames: ['width'] },
  ),
};

const shadow = {
  normal: parsePixelsProperty(
    parseColorProperty(tokens.shadow.shadow_m, 'color'),
    { propertyNames: ['x', 'y', 'blur', 'spread'] },
  ),
  large: parsePixelsProperty(
    parseColorProperty(tokens.shadow.shadow_l, 'color'),
    { propertyNames: ['x', 'y', 'blur', 'spread'] },
  ),
};

const radius = {
  xsmall: parsePixels(tokens.radius.radius_4),
  small: parsePixels(tokens.radius.radius_4),
  normal: parsePixels(tokens.radius.radius_8),
  large: parsePixels(tokens.radius.radius_12),
  xlarge: parsePixels(tokens.radius.radius_16),
};

const opacity = {
  10: tokens.opacity[10],
  20: tokens.opacity[20],
  40: tokens.opacity[40],
  60: tokens.opacity[60],
  80: tokens.opacity[80],
};

export const lightTheme: Theme = {
  colors,
  typography,
  spacing,
  border,
  radius,
  shadow,
  opacity,
  breakpoints: {
    xsmall: 330,
    small: 640,
    normal: 1024,
    large: 1280,
    xlarge: 1440,
    tablet: 800,
    desktop: 1200,
  },
  containers: {
    block: {
      radius: radius.normal,
    },
  },
  actions: {
    radius: radius.normal,
    padding: { x: spacing.normal, y: spacing.normal },
  },
  toasts: {
    border: {
      radius: radius.normal,
    },
    padding: { x: spacing.normal, y: spacing.normal },
  },
  cards: {
    radius: radius.xlarge,
    padding: { x: spacing.normal, y: spacing.normal },
    shadow: shadow.large,
    generic: {
      radius: radius.xlarge,
      padding: {
        x: spacing.normal,
        y: spacing.large,
      },
    },
  },
  paddings: {
    button: { x: spacing.xlarge, y: spacing.small },
    button_small: { x: spacing.normal, y: spacing.xsmall },
  },
  inputs: {
    padding: { x: spacing.normal, y: spacing.small },
    border: border.normal,
    typography: typography.body,
  },
  tabs: {
    normal: {
      backgroundColor: 'transparent',
      typography: {
        fontWeight: typography.body_small.fontWeight,
        fontSize: typography.body_small.fontSize,
        color: colors.grey400,
      },
      border: {
        radius: spacing.xsmall,
      },
      padding: {
        x: spacing.xsmall,
        y: spacing.xsmall,
      },
      active: {
        backgroundColor: colors.grey100,
        typography: {
          color: colors.black,
          fontWeight: 'bold',
        },
      },
      hover: {
        border: {
          color: colors['shadow100'],
        },
      },
      small: {
        padding: {
          x: spacing.xsmall,
          y: spacing.xxsmall,
        },
      },
    },
    compact: {
      typography: {
        ...typography.h7,
      },
      border: {
        radius: radius.normal,
      },
      padding: {
        x: spacing.xsmall,
        y: spacing.xsmall,
      },
    },
  },
  widgets: {
    padding: { x: spacing.large, y: spacing.large },
    radius: radius.large,
  },
  sidebar: {
    backgroundImage: `url(https://assets.hedgehog-invest.com/pattern-72x72.png)`,
  },
};
