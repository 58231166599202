import { useEffect, useMemo, useState } from 'react';

import { screens } from '@hedgehog/utils/sizes';

export interface MediaQueryProps {
  readonly matches: boolean | null;
  readonly media?: string;
}

export const useMediaQuery = (query: string): MediaQueryProps => {
  const [mediaQuery, setMediaQuery] = useState<MediaQueryProps>({
    matches: null,
  });

  useEffect(() => {
    if (!window?.matchMedia) return;

    const matchMedia = window.matchMedia(query);
    setMediaQuery({
      matches: matchMedia.matches,
      media: matchMedia.media,
    });

    const update = (newMediaQuery: MediaQueryProps): void => {
      setMediaQuery(newMediaQuery);
    };

    if (matchMedia.addEventListener)
      matchMedia.addEventListener('change', update);
    return () => {
      if (matchMedia.removeEventListener)
        matchMedia.removeEventListener('change', update);
    };
  }, [query]);

  return useMemo(() => mediaQuery, [mediaQuery]);
};

export const useIsMobile = (): boolean | null => {
  const query = useMediaQuery(
    `only screen and (max-width: ${screens.medium}px)`,
  );
  return query.matches;
};
