export const DownloadIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 7H10V0H6V7L3 7V8L8 13L13 8V7Z" fill="currentColor" />
    <path d="M14 14H2V16H14V14Z" fill="currentColor" />
  </svg>
);

export default DownloadIcon;
