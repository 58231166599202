import styled from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

import { CardListItemDivider } from './card-list-item-divider.component';
import {
  CardListItemRow,
  RowContentObject,
  RowContent,
} from './card-list-item-row.component';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

const HeaderBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
`;

const FooterBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export type CardListItemContentProps = StandardProps<
  {
    body?: (RowContentObject | RowContent)[];
    footer?: (RowContentObject | RowContent)[] | null;
    loading?: boolean;
  },
  never
>;

export const CardListItemContent = styled(
  ({
    body = [],
    footer = [],
    loading = false,
    className,
  }: CardListItemContentProps): JSX.Element => {
    return (
      <Wrapper className={className}>
        {body.length ? (
          <HeaderBlock>
            {body.map((content) => (
              <CardListItemRow loading={loading} content={content} />
            ))}
          </HeaderBlock>
        ) : null}
        {body.length && footer?.length ? <CardListItemDivider /> : null}
        {footer?.length ? (
          <FooterBlock>
            {footer.map((content) => (
              <CardListItemRow loading={loading} content={content} />
            ))}
          </FooterBlock>
        ) : null}
      </Wrapper>
    );
  },
)``;
