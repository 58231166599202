import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { ReadContractResult } from '@wagmi/core';
import { useEffect } from 'react';
import { Address } from 'viem';
import { useContractRead } from 'wagmi';

import { useCacheEntry } from '@hedgehog/browser/shared/utils';

export interface UseFundReadParams {
  address: Address;
  functionName: string;
  args: Array<bigint | number | string | Address>;
  scopeKey: string;
  watch?: boolean;
  enabled?: boolean;
}

export type UseFundReadReturn = [
  (() => Promise<ReadContractResult>) | undefined,
  {
    data: ReadContractResult | undefined;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
  },
];

export const useFundRead = ({
  address,
  functionName,
  args,
  scopeKey,
  watch = false,
  enabled = true,
}: UseFundReadParams): UseFundReadReturn => {
  const [entry, { set }] = useCacheEntry(scopeKey);
  const { refetch, data, ...props } = useContractRead({
    abi: IFund.abi,
    address,
    functionName,
    args,
    scopeKey,
    watch,
    enabled,
  });

  useEffect(() => {
    if (data) {
      set(data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [entry?.validatedAt]);

  return [refetch, { data, ...props }];
};
