import { currencies } from '@hedgehog/utils/formats';

import { AssetCardCaption } from '../asset-card-caption/asset-card-caption.component';

export type AssetCardTokenPriceProps = {
  label?: string;
  amount?: number;
  currency?: currencies.Currency;
};

export const AssetCardTokenPrice = ({
  label = 'Token price',
  amount,
  currency = 'USD',
  ...otherProps
}: AssetCardTokenPriceProps): JSX.Element => {
  return (
    <AssetCardCaption
      {...otherProps}
      label={label}
      value={
        amount
          ? currencies.formatMoney(amount, {
              currency,
            })
          : undefined
      }
    />
  );
};
