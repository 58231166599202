import cuid from 'cuid';
import React, { createContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { Helmet } from 'react-helmet';
import styled, { CSSProp } from 'styled-components';

import { sendMessageToApp } from '@hedgehog/data-access/native-mobile';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { depth } from '@hedgehog/utils/sizes';

import BottomSheet from '../BottomSheet/BottomSheet';
import { useModalReducer } from '../hooks';
import {
  ModalState,
  ModalResolver,
  ModalOptions,
  ModalComponentRef,
} from '../modal.types';

import { ModalAbortError, ModalCancelError } from './errors';
import { ModalWrapper } from './modal-wrapper.component';

export interface ModalOpenParams<P = unknown> {
  componentRef: ModalComponentRef<P>;
  resolver: ModalResolver<unknown, unknown>;
  options: ModalOptions;
  initialProps?: unknown;
}

export interface ModalContextProps {
  closeAll: () => void;
  open: <P = unknown>(settings: ModalOpenParams<P>) => void;
}

export interface ModalProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export const ModalContext = createContext<ModalContextProps>({
  closeAll: () => null,
  open: () => null,
});

export const ModalProvider = ({
  children,
}: ModalProviderProps): JSX.Element => {
  const [state, dispatch] = useModalReducer();
  const [closing, setClosing] = useState(false);

  const destroy = (modal: ModalState): void => {
    sendMessageToApp('navigation.showFootBar');
    dispatch({ type: 'modal:close', payload: modal.componentRef });
  };

  function open<P>({
    componentRef,
    resolver,
    options = {},
    initialProps,
  }: ModalOpenParams<P>): void {
    if (options.closeAll) {
      dispatch({ type: 'modal:close:all' });
    }

    sendMessageToApp('navigation.hideFootBar');
    dispatch({
      type: 'modal:open',
      payload: {
        id: cuid(),
        componentRef,
        resolver,
        options,
        initialProps,
      },
    });
  }

  const closeAll = (): void => {
    dispatch({ type: 'modal:close:all' });
  };

  const abort = (modal: ModalState): void => {
    const { resolver, options } = modal;

    const dispose = (): void => {
      if (resolver && options.throwOnAbort) {
        resolver.reject(new ModalAbortError());
      }
      destroy(modal);
    };

    if (options?.delayCloseMs) {
      setClosing(true);
      setTimeout(() => {
        dispose();
        setClosing(false);
      }, options?.delayCloseMs);
    } else {
      dispose();
    }
  };

  const cancel = (value: unknown, modal: ModalState): void => {
    const { resolver, options } = modal;

    const dispose = (): void => {
      if (resolver && options.throwOnAbort) {
        resolver.reject(new ModalCancelError(value));
      }
      destroy(modal);
    };

    sendMessageToApp('navigation.showFootBar');
    if (options?.delayCloseMs) {
      setClosing(true);
      setTimeout(() => {
        dispose();
        setClosing(false);
      }, options?.delayCloseMs);
    } else {
      dispose();
    }
  };

  const submit = (value: unknown, modal: ModalState): void => {
    const { resolver } = modal;

    if (resolver) {
      resolver.resolve(value);
    }
    destroy(modal);
  };

  const contextValue = useMemo(() => ({ open, closeAll }), []);
  return (
    <ModalContext.Provider value={contextValue}>
      {state.modals.length !== 0 && (
        // Prevents the modal background being scrollable
        <Helmet>
          <style type="text/css">{`
          body {
            overflow: hidden;
          }`}</style>
        </Helmet>
      )}
      {children}
      {state.modals.map((modal) => (
        <ModalWrapper
          onBackdropClick={(): void => abort(modal)}
          closing={closing}
        >
          <modal.componentRef
            {...(modal.initialProps || {})}
            submitModal={(value: unknown): void => submit(value, modal)}
            cancelModal={(value: unknown): void => cancel(value, modal)}
            closing={closing}
          />
        </ModalWrapper>
      ))}
    </ModalContext.Provider>
  );
};
