import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications {
    notifications {
      id
      title
      content
      icon
      imageSrcUrl
      actionUrl
      mobileUrl
      type
      theme
      unread
      unopened
    }
  }
`;
