export const ThumbsDown = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 14L5 9V0H14L16 6V9H10V12C10 13.1046 9.10457 14 8 14H7Z"
      fill="currentColor"
    />
    <path d="M3 9H0V0H3V9Z" fill="currentColor" />
  </svg>
);

export default ThumbsDown;
