import { AssetRoundClassMetricType } from '@hedgehog/data-access/graphql';

type Content = Record<
  AssetRoundClassMetricType,
  { title: string; taxLeakage: boolean; tooltip?: string }
>;

export const AssetHighlightsTooltipContent: Content = {
  [AssetRoundClassMetricType.annual_income]: {
    title: 'Annual income',
    taxLeakage: true,
    tooltip:
      'Estimated average annual income from the property, which is subsequently distributed on a proportional basis to investors.\n\nDistributions from the cash flow of the asset will be made on a periodic basis.',
  },
  [AssetRoundClassMetricType.investment_period]: {
    title: 'Investment period',
    taxLeakage: false,
    tooltip: 'The scheduled term of the investment.',
  },
  [AssetRoundClassMetricType.projected_return]: {
    title: 'Projected return',
    taxLeakage: true,
    tooltip:
      'This includes the growth the investment is expected to generate. See the investment memo in the Docs section for more details.',
  },
  [AssetRoundClassMetricType.token_cost]: {
    title: 'Token cost',
    taxLeakage: true,
    tooltip:
      'Keep paying your rent on time and renewing your lease, and we will reward you with tokens at no additional cost to you.',
  },
  [AssetRoundClassMetricType.tenant_investment_period]: {
    title: 'Investment period',
    taxLeakage: false,
    tooltip:
      'This is the target scheduled term of the investment. When this ends, vested tokens will be converted to cash.',
  },
};
