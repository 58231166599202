import { CHAIN_NAMESPACES } from '@web3auth/base';
import Web3Auth from '@web3auth/single-factor-auth';
import { useEffect, useState } from 'react';

import { useEnvironment } from '@hedgehog/ui/environment';

export const useWeb3Auth = (): { instance: Web3Auth | null } => {
  const {
    web3auth: { clientId: web3AuthClientId, network: web3AuthNetwork },
    blockchain: { chainId, rpcUrl },
  } = useEnvironment();
  const [web3AuthInstance, setWeb3AuthInstance] = useState<Web3Auth | null>(
    null,
  );

  const createWeb3AuthInstance = (): Web3Auth => {
    const chainConfig = {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId,
      rpcTarget: rpcUrl,
    };

    return new Web3Auth({
      clientId: web3AuthClientId,
      chainConfig,
      web3AuthNetwork,
      enableLogging: true,
    });
  };

  useEffect(() => {
    const instance = createWeb3AuthInstance();
    instance.init();
    setWeb3AuthInstance(instance);
    /**
     * UGLY, SMELLY, HACKY, BUT IT WORKS @co-authored-by @copilot
     */
  }, []);

  return { instance: web3AuthInstance };
};
