export enum MarketTransferStatus {
  Pending = 'pending',
  InProgress = 'in-progress',
  Success = 'success',
}

export enum MarketTransferAction {
  BuyerTermsAndConditions = 'buyer-terms-and-conditions',
  SellerTermsAndConditions = 'seller-terms-and-conditions',
  AssetInEscrow = 'asset-in-escrow',
  PaymentSettled = 'payment-settled',
  TransferFinalised = 'transfer-finalised',
}

export interface MarketTransferActor {
  firstName: string;
  lastName: string;
}

export interface MarketTransferStep {
  action: MarketTransferAction;
  status: MarketTransferStatus;
  completedAt?: Date;
  actor?: MarketTransferActor;
}
