export const screens = {
  extrasmall: 375,
  verysmall: 414,
  small: 640,
  medium: 1024,
  large: 1280,
  xlarge: 1440,
  tablet: 800,
  desktop: 1200,
};

export type screenNames = keyof typeof screens;

export default screens;
