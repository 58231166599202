import styled, { CSSObject, CSSProp } from 'styled-components';

import { Logotype } from '@hedgehog/ui/icons';
import { parsePadding, shimmerGradient } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

export type BrandLogotypeProps = StandardProps<
  {
    src?: string;
    alt?: string;
    loading?: boolean;
    hedgehog?: boolean;
  },
  never
>;

const BrandWrapper = styled.div<{ loading?: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  max-height: 2rem;
  height: 100%;
  ${({ loading }): CSSProp => (loading ? shimmerGradient : undefined)}
  ${({ theme }): CSSObject => ({
    [`& > *`]: {
      padding: parsePadding({ x: theme.spacing.small, y: '0' }),
      borderRight: `1px solid ${theme.colors.shadow100}`,
      '&:first-child': {
        paddingLeft: 0,
      },
      '&:last-child': {
        paddingRight: 0,
        borderRight: 0,
      },
    },
  })}
`;

const BrandLogotypeWrapper = styled.div`
  display: flex;
`;
export const BrandLogotypeImage = styled.img`
  max-height: 2rem;
  max-width: 7.5rem;
`;

export const BrandLogotype = styled(
  ({
    src = '',
    alt = '',
    hedgehog = true,
    loading,
    className,
  }: BrandLogotypeProps) => (
    <BrandWrapper className={className} loading={loading}>
      {hedgehog && <Logotype />}
      {src && (
        <BrandLogotypeWrapper>
          <BrandLogotypeImage src={src} alt={alt} />
        </BrandLogotypeWrapper>
      )}
    </BrandWrapper>
  ),
)``;
