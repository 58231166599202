import { CardListItem } from '@hedgehog/browser/shared/funds/feature-marketplace';
import { BidStatus, Listing } from '@hedgehog/data-access/partners';
import {
  SecondaryLightButton,
  SecondaryLightLinkButton,
} from '@hedgehog/ui/buttons';
import { ErrorCard } from '@hedgehog/ui/cards';
import { Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';
import { currencies } from '@hedgehog/utils/formats';

import { LiveListingWidget } from '../../components';
import { FundPosition } from '../../hooks';

export type MarketPortfolioProps = StandardProps<{
  listings: Listing[];
  positions: FundPosition[];
  error: Error | null;
  loading: boolean;
}>;

export interface FundBid {
  bids: Listing['bids'];
  price: bigint;
}

export type FundBids = {
  [fundId: string]: FundBid;
};

export const MarketPortfolio = ({
  error,
  positions,
  listings,
  loading = false,
}: MarketPortfolioProps): JSX.Element => {
  const bids = listings
    .filter((listing) => listing.fundId)
    .reduce<FundBids>((acc, listing) => {
      const { bids = [], price = BigInt(0) } = acc[listing.fundId] ?? {};

      return {
        ...acc,
        [listing.fundId]: {
          bids: [...bids, ...listing.bids],
          price: price + listing.price,
        },
      };
    }, {});

  return (
    <>
      {error && (
        <ErrorCard>
          <Paragraph color="error">{error?.message}</Paragraph>
        </ErrorCard>
      )}
      {positions.map((fund) => {
        const isAlreadyListed = listings.some(
          ({ fundId }) => fundId === fund.fundId,
        );
        const positionBids = bids[fund?.fundId]?.bids ?? [];
        const openBids = positionBids.filter(
          ({ status }) => !['rejected', 'withdrawn'].includes(status),
        );

        return (
          <CardListItem
            loading={loading}
            key={fund?.fundId}
            title={fund?.name}
            subtitle={fund?.description}
            body={[
              [
                'Capital commitment',
                currencies.formatMoney(Number(fund.commitment || 0)),
              ],
              [
                'Capital contributed',
                currencies.formatMoney(Number(fund.contribution || 0)),
              ],
            ]}
            footer={[
              [
                'Net Asset Value',
                <Paragraph color="positive" weight="bold" loading={loading}>
                  {currencies.formatMoney(Number(fund.contribution || 0))}
                </Paragraph>,
              ],
              isAlreadyListed ? (
                <LiveListingWidget
                  bidCount={openBids.length}
                  price={Number(bids[fund.fundId]?.price)}
                />
              ) : (
                <>
                  <SecondaryLightLinkButton
                    loading={loading}
                    to={{
                      pathname: `listings/create/position`,
                      search: `?fund_id=${fund.fundId}`,
                    }}
                  >
                    List on secondary market
                  </SecondaryLightLinkButton>
                  <SecondaryLightButton loading={loading}>
                    View details
                  </SecondaryLightButton>
                </>
              ),
            ]}
          />
        );
      })}
    </>
  );
};
