import {
  NativeMobileExperiences,
  useIsNativeMobileExperience,
} from '@hedgehog/data-access/native-mobile';

type NativeMobileExperienceProps = {
  experience: NativeMobileExperiences;
  children: JSX.Element;
};

export const NativeMobileExperience = ({
  experience,
  children,
}: NativeMobileExperienceProps): JSX.Element | null => {
  // If the experience presence is found in local storage, the children should NOT be rendered. This is because
  // the native mobile experience is handling that user experience.
  return useIsNativeMobileExperience(experience) ? null : children;
};
