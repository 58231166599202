import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAuthoriseTeamMemberUserOperation,
  useIsAuthorisedQuery,
} from '@hedgehog/data-access/partners';
import { PrimaryButton } from '@hedgehog/ui/buttons';

import { Table } from '../../components';

export interface FundTeamMemberProps {
  fundHashedBlockchainRef: string;
  firstName: string;
  lastName: string;
  address: string;
}

const PermissionsRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 1rem 0;
`;

export const FundTeamMember = ({
  fundHashedBlockchainRef,
  firstName,
  lastName,
  address,
}: FundTeamMemberProps): JSX.Element => {
  const location = useLocation();

  const [reload, { data: isAuthorised, loading: loadingIsAuthorised }] =
    useIsAuthorisedQuery({ fundRef: fundHashedBlockchainRef, address });

  const [authoriseTeamMember, { isLoading: authorisingTeamMember }] =
    useAuthoriseTeamMemberUserOperation();

  const handleGrantFundAdmin = async () => {
    await authoriseTeamMember({
      fundHashedBlockchainRef,
      address,
    });

    reload();
  };

  useEffect(() => {
    reload();
  }, [location.pathname]);

  return (
    <PermissionsRow>
      <Table></Table>
      <p>
        {firstName} {lastName}
      </p>
      <PrimaryButton
        small
        disabled={loadingIsAuthorised || address === '0x' || isAuthorised}
        loading={authorisingTeamMember}
        onClick={handleGrantFundAdmin}
      >
        Grant Fund Admin
      </PrimaryButton>
    </PermissionsRow>
  );
};
