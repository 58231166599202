import { gql } from '@apollo/client';

export const COMPLETE_PARTNER_CLIENT_SIGNUP = gql`
  mutation CompleteClientSignup($authCode: String!, $isSSO: Boolean) {
    completeClientSignup(args: { authCode: $authCode, isSSO: $isSSO }) {
      __typename
      ... on CompleteClientSignupPayload {
        user {
          id
          email
          country
          firstName
          lastName
          state
          cognitoId
          createdAt
        }
      }
      ... on CompleteClientSignupError {
        message
        code
      }
    }
  }
`;
