import { gql } from '@apollo/client';

export const VOTE_ON_ASSET = gql`
  mutation VoteOnAsset($assetId: String!, $votes: Int!) {
    castVotes(args: { votableId: $assetId, votes: $votes, type: ASSET }) {
      ... on CastVotesOutput {
        success
        votesRemaining
        totalVotes
      }
      ... on CastVotesError {
        message
        code
      }
    }
  }
`;
