import { useContext, useMemo, useState } from 'react';

import context, { MarketBidBalance, MarketBidContextType } from './context';

export const useMarketBidBalanceFactory = (): [
  MarketBidBalance,
  Pick<MarketBidContextType, 'updateBalance'>,
] => {
  const [balance, setBalance] = useState<MarketBidBalance>({
    commitment: 0,
    contribution: 0,
  });

  const updateBalance = (patch: Partial<MarketBidBalance>): void => {
    const newBalance = { ...balance, ...patch };
    setBalance(newBalance);
  };

  return [balance, { updateBalance }];
};

export const useMarketBidBalance = (): [
  MarketBidBalance,
  (balance: Partial<MarketBidBalance>) => void,
] => {
  const { balance, updateBalance } = useContext(context);
  return useMemo(() => [balance, updateBalance], [balance]);
};
