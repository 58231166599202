import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { useDebounce, useMediaQuery } from '@hedgehog/data-access/hooks';
import {
  GetPartnerClientsRequest,
  useLazyClientsQuery,
} from '@hedgehog/data-access/partners';
import { useAppData } from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import { CardContent } from '@hedgehog/ui/cards';
import { TextInput } from '@hedgehog/ui/inputs';
import { FilterPill } from '@hedgehog/ui/pills';
import { parsePadding } from '@hedgehog/ui/themes';
import { Note } from '@hedgehog/ui/typography';
import { Widget, WidgetContent } from '@hedgehog/ui/widgets';

import { ClientListItem } from '../../components';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  max-height: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 1rem 0.5rem 0.25rem;
`;

const Header = styled.div`
  flex: 0 0 auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: space-between;
  gap: 1rem;
  ${({ theme }) => ({
    padding: parsePadding(theme.actions.padding),
    paddingTop: theme.spacing.large,
    paddingBottom: theme.spacing.large,
  })}
`;

const ListQueryInput = styled(TextInput)`
  flex: 1 1 100%;
  width: 100%;
`;
const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

const FilterPills = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  flex-shrink: 1;

  overflow-y: auto;
`;

const ClientListWidget = styled(Widget)`
  &,
  & ${WidgetContent} {
    height: 100%;
  }
  ${CardContent} {
    padding: 0;
  }
`;

export interface ClientsListProps {
  offset?: number;
  limit?: number;
  search?: boolean;
}

export const ClientsList = ({
  offset = 0,
  limit = 100,
  search = false,
}: ClientsListProps) => {
  const { clientId } = useParams();
  const { activePartner } = useAppData();
  const navigate = useNavigate();
  const theme = useTheme();
  const { matches: isDesktop } = useMediaQuery(
    `only screen and (min-width: ${theme.breakpoints.tablet}px)`,
  );

  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query, 500);

  const [searchParams] = useSearchParams();

  const [loadClients, { data }] = useLazyClientsQuery();

  const [declarations, setDeclarations] = useState<string | null>(
    searchParams.get('declarations'),
  );

  const [missingDeclarations, setMissingDeclarations] = useState<string | null>(
    searchParams.get('missingDeclarations'),
  );

  const [requestedDocs, setRequestedDocs] = useState<boolean | null>(
    searchParams.get('requestedDocs') === 'true'
      ? true
      : searchParams.get('requestedDocs') === 'false'
      ? false
      : null,
  );

  const [downloadedIm, setDownloadedIm] = useState<boolean | null>(
    searchParams.get('downloadedIm') === 'true'
      ? true
      : searchParams.get('downloadedIm') === 'false'
      ? false
      : null,
  );

  // TODO: It's hidden routing logic, move it to router as separate definition
  useEffect(() => {
    if (!isDesktop) return;
    if (clientId) return;
    if (data && data.paging.total > 0) {
      navigate(`${data.data[0].id}?${searchParams.toString()}`, {
        replace: true,
      });
    }
  }, [clientId, data, isDesktop]);

  useEffect(() => {
    if (debouncedQuery === undefined) return;
    if (!searchParams) return;
    if (!activePartner) return;

    const queryParams: GetPartnerClientsRequest = {
      partnerId: activePartner.id,
      limit,
      offset,
      query: debouncedQuery,
    };

    if (declarations) {
      queryParams.declarations = declarations;
    }

    if (missingDeclarations) {
      queryParams.missingDeclarations = missingDeclarations;
    }

    if (requestedDocs === true) {
      queryParams.requestedDocs = true;
    } else if (requestedDocs === false) {
      queryParams.requestedDocs = false;
    }

    if (downloadedIm === true) {
      queryParams.downloadedIm = true;
    } else if (downloadedIm === false) {
      queryParams.downloadedIm = false;
    }

    loadClients(queryParams);
  }, [
    debouncedQuery,
    declarations,
    missingDeclarations,
    requestedDocs,
    downloadedIm,
    activePartner,
  ]);

  return (
    <ClientListWidget>
      <Container>
        <Header>
          <HeaderWrapper>
            {search && (
              <ListQueryInput
                type="text"
                name="query"
                onChange={(value) => setQuery(value)}
                placeholder="Type to search..."
                value={query}
                trailingIcon="search-outline"
              />
            )}
            <FilterContainer>
              <FilterPills>
                {declarations &&
                  declarations.split(',').map((declaration) => (
                    <FilterPill
                      text={`Has ${declaration}`}
                      key={declaration}
                      onClose={() => {
                        const newDeclarations = declarations
                          .split(',')
                          .filter((d) => d !== declaration)
                          .join(',');
                        setDeclarations(newDeclarations);
                      }}
                    />
                  ))}
                {missingDeclarations &&
                  missingDeclarations.split(',').map((declaration) => (
                    <FilterPill
                      text={`Missing ${declaration}`}
                      key={declaration}
                      onClose={() => {
                        const newMissingDeclarations = missingDeclarations
                          .split(',')
                          .filter((d) => d !== declaration)
                          .join(',');
                        setMissingDeclarations(newMissingDeclarations);
                      }}
                    />
                  ))}
                {requestedDocs !== null && (
                  <FilterPill
                    text={`${
                      requestedDocs
                        ? 'Has requested info'
                        : "Hasn't requested info"
                    }`}
                    onClose={() => setRequestedDocs(null)}
                  />
                )}
                {downloadedIm !== null && (
                  <FilterPill
                    text={`${
                      downloadedIm
                        ? 'Has downloaded IM'
                        : "Hasn't downloaded IM"
                    }}`}
                    onClose={() => setDownloadedIm(null)}
                  />
                )}
              </FilterPills>
              <Note color="grey400">{data?.paging.total || 0} Client(s)</Note>
            </FilterContainer>
          </HeaderWrapper>
        </Header>

        <Wrapper>
          <Content>
            {data?.data?.map((client: PartnerClient) => (
              <ClientListItem
                key={client.id}
                to={`/clients/${client.id}?${searchParams.toString()}`}
                active={client.id === clientId}
                firstName={client.user?.firstName ?? ''}
                lastName={client.user?.lastName ?? ''}
                canInvest={client.canInvest ?? false}
                createdAt={client.createdAt}
              />
            ))}
          </Content>
        </Wrapper>
      </Container>
    </ClientListWidget>
  );
};
