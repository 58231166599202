import { createContext, ReactNode, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

import { FeatureFlag, Partner } from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import { useSelector, selectAuthToken, selectActivePartnerId } from '../store';
import {
  usePartnersQuery,
  usePartnerQuery,
  useFeatureFlagsQuery,
  useWhoamiQuery,
} from '../store/apis';
import { TPartnerTeamMember } from '../types';

type AppDataProviderContextProperties = {
  featureFlags: FeatureFlag[];
  activePartnerId?: UUID;
  activePartner?: Partner;
  teamMember?: TPartnerTeamMember;
};

export const AppDataProviderContext =
  createContext<AppDataProviderContextProperties>({
    featureFlags: [],
  });

export const useAppData = (): AppDataProviderContextProperties => {
  const context = useContext(AppDataProviderContext);
  if (context === undefined) {
    throw new Error('useAppData must be used within a AppDataProviderContext');
  }
  return context;
};

type AppDataProviderProps = {
  children: ReactNode;
};

export const AppDataProvider = ({
  children,
}: AppDataProviderProps): JSX.Element => {
  const [searchParams] = useSearchParams();
  const authToken = useSelector(selectAuthToken);
  const activePartnerId = useSelector(selectActivePartnerId);
  usePartnersQuery(undefined, {
    skip: !authToken,
  });
  const { data: activePartner } = usePartnerQuery(
    activePartnerId || searchParams.get('partnerId') || '',
    {
      skip: !(activePartnerId || searchParams.get('partnerId')),
    },
  );
  const { data: featureFlags } = useFeatureFlagsQuery();
  const { data: teamMember } = useWhoamiQuery(undefined, {
    skip: !authToken,
  });

  return (
    <AppDataProviderContext.Provider
      value={{
        featureFlags: featureFlags || [],
        activePartnerId:
          (activePartnerId || searchParams.get('partnerId')) ?? undefined,
        activePartner,
        teamMember,
      }}
    >
      {children}
    </AppDataProviderContext.Provider>
  );
};
