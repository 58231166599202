import { useAppData } from '@hedgehog/data-access/partners';
import { PresetThemeKey, ThemeProvider } from '@hedgehog/ui/themes';

import { StandardProps } from '../../types';

export type PartnerThemeProviderProps = StandardProps<{
  defaultTheme?: PresetThemeKey;
}>;

export const PartnerThemeProvider = ({
  defaultTheme = 'light',
  children,
}: PartnerThemeProviderProps) => {
  const { activePartner: { theme } = { theme: {} } } = useAppData();
  return (
    <ThemeProvider theme={defaultTheme} overrides={theme ?? {}}>
      {children}
    </ThemeProvider>
  );
};
