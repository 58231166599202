import * as Sentry from '@sentry/react';
import { SafeEventEmitterProvider } from '@web3auth/base';
import { Web3Auth } from '@web3auth/single-factor-auth';
import { Wallet } from 'ethers';
import jwtDecode from 'jwt-decode';
import { useRef, useState } from 'react';

import { useAuth } from '@hedgehog/data-access/contexts';
import { useEnvironment } from '@hedgehog/ui/environment';

import {
  throwCognitoTokenNotFound,
  throwWeb3AuthCouldntRetrievePrivateKey,
  throwWeb3AuthProviderCouldntBeInstantiated,
} from '../throwers';

export interface AccountWalletState {
  wallet: Wallet | null;
  error: unknown | null;
  loading: boolean;
}

export interface AttemptState {
  current: number;
  max: number;
}

export interface AccountWalletMethods {
  connect: (options: AccountWalletConnectOptions) => Promise<Wallet>;
  disconnect: () => Promise<void>;
}

export interface AccountWalletConnectOptions {
  web3auth: Web3Auth;
}

export const useLazyAccountWallet = (): [
  AccountWalletMethods,
  AccountWalletState,
] => {
  const {
    web3auth: { verifier },
  } = useEnvironment();
  const { accessToken, refresh } = useAuth();
  const connectionRef = useRef<Promise<Wallet> | null>(null);
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const tryGettingWeb3AuthProvider = async (
    { web3auth }: AccountWalletConnectOptions,
    attempts: AttemptState = { current: 0, max: 3 },
  ): Promise<SafeEventEmitterProvider> => {
    if (!accessToken) throwCognitoTokenNotFound();
    const { email } = jwtDecode(accessToken) as { email: string };
    const providerOrNull = await web3auth
      .connect({
        verifier,
        verifierId: email,
        idToken: accessToken,
      })
      .catch(async (err) => {
        console.error(err);
        if (
          err instanceof Error &&
          (err.message.includes('Duplicate token found') ||
            err.message.includes('paramstimesigned is more than 1m0s ago'))
        ) {
          const { accessToken } = await refresh();
          return await web3auth.connect({
            verifier,
            verifierId: email,
            idToken: accessToken,
          });
        }
        Sentry.captureException(err);
        throw err;
      });
    if (providerOrNull === null) {
      if (attempts.current >= attempts.max)
        throwWeb3AuthProviderCouldntBeInstantiated();
      return tryGettingWeb3AuthProvider(
        { web3auth },
        { current: attempts.current + 1, max: attempts.max },
      );
    }
    return providerOrNull;
  };

  const connectToWallet = async (
    options: AccountWalletConnectOptions,
  ): Promise<Wallet> => {
    const provider = await tryGettingWeb3AuthProvider(options, {
      current: 0,
      max: 3,
    });
    const privateKey = await provider.request<string>({
      method: 'private_key',
    });
    if (!privateKey) throwWeb3AuthCouldntRetrievePrivateKey();
    return new Wallet(privateKey);
  };

  const disconnect = async (): Promise<void> => {
    setWallet(null);
    setError(null);
    setLoading(false);
  };

  const connect = async (
    options: AccountWalletConnectOptions,
  ): Promise<Wallet> => {
    if (loading && connectionRef.current) return connectionRef.current;
    setLoading(true);
    try {
      const establishingConnection = connectToWallet(options);
      connectionRef.current = establishingConnection;
      const wallet = await establishingConnection;
      setError(null);
      setWallet(wallet);
      return wallet;
    } catch (err) {
      setError(err);
      setWallet(null);
      setLoading(false);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return [
    { connect, disconnect },
    { wallet, error, loading },
  ];
};
