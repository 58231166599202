import { ethers } from 'ethers';

import {
  TransactionMetadata,
  TransactionMetadataByType,
  TransactionType,
} from '../types';

export function createMetadata<T extends TransactionType = TransactionType>(
  type: T,
  metadata: TransactionMetadataByType[T],
): TransactionMetadata<T> {
  return {
    type,
    ...metadata,
  };
}
export function encodeMetadataToBytes(
  metadata: TransactionMetadata,
): ethers.Bytes {
  return ethers.utils.toUtf8Bytes(JSON.stringify(metadata));
}

export function decodeBytesToMetadata<
  T extends TransactionType = TransactionType,
>(metadata: ethers.BytesLike): TransactionMetadata<T> {
  return JSON.parse(ethers.utils.toUtf8String(metadata));
}
