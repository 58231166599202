import { sendMessageWithPayloadToApp } from '@hedgehog/data-access/native-mobile';

export function throwWeb3AuthNotInitialised(): never {
  sendMessageWithPayloadToApp(
    'accountAbstraction.error',
    'Web3Auth has not been initialised',
  );
  throw Error('Web3Auth has not been initialised');
}

export function throwCognitoTokenNotFound(): never {
  sendMessageWithPayloadToApp(
    'accountAbstraction.error',
    'Cognito ID token was not found',
  );
  throw Error('Cognito ID token was not found');
}

export function throwWeb3AuthProviderCouldntBeInstantiated(): never {
  sendMessageWithPayloadToApp(
    'accountAbstraction.error',
    'Unable to create Web3Auth provider',
  );
  throw Error('Unable to create Web3Auth provider');
}

export function throwWeb3AuthCouldntRetrievePrivateKey(): never {
  sendMessageWithPayloadToApp(
    'accountAbstraction.error',
    'Failed to request Web3Auth share private key',
  );
  throw Error('Failed to request Web3Auth share private key');
}
