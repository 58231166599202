import { gql } from '@apollo/client';

export const VOTE_ON_THEME = gql`
  mutation VoteOnTheme($themeId: String!, $votes: Int!) {
    castVotes(args: { votableId: $themeId, votes: $votes, type: THEME }) {
      ... on CastVotesOutput {
        success
        votesRemaining
        totalVotes
      }
      ... on CastVotesError {
        message
        code
      }
    }
  }
`;
