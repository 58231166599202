import { useEffect } from 'react';
import styled from 'styled-components';
import { formatEther } from 'viem';

import {
  CardListItemContent,
  CardListItem,
} from '@hedgehog/browser/shared/funds/feature-marketplace';
import { NumberInput } from '@hedgehog/ui/inputs';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { currencies } from '@hedgehog/utils/formats';

import { ModalForm, ModalFormContent } from '../../../../../components';
import {
  useMarketBidListing,
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';

const CustomModalForm = styled(ModalForm)`
  & ${ModalFormContent} {
    display: flex;
    flex-flow: column nowrap;
    gap: 1.5rem;
  }
`;

export const MarketBidCreateSetPricePage = (): JSX.Element => {
  const { navigateTo, close } = useNavigateToBidPage();
  const [params, updateParams] = useMarketBidParams();
  const [data, { isLoading }] = useMarketBidListing();

  const commissionFee = Number(params.bidPrice ?? 0) * 0.1;
  const transferFee = 2_000;

  useEffect(() => {
    if (data && data.priceAmount) {
      updateParams({ bidPrice: +formatEther(data.priceAmount) });
    }
  }, []);

  return (
    <CustomModalForm
      heading="Make a bid"
      submitModal={() => navigateTo(`create/terms-conditions`)}
      backModal={() => navigateTo(`create/select-client`)}
      closeModal={() => close()}
      submitButtonText="Submit bid"
      disabled={!params.clientWalletAddress && !params.bidPrice}
    >
      <CardListItem
        loading={isLoading}
        title={data?.fundName ?? 'placeholder for fund name'}
        subtitle={data?.fundDescription ?? 'placeholder for a fund description'}
        body={[
          [
            'Unfunded commitment',
            currencies.formatMoney(
              Number(formatEther(BigInt(data?.unfundedCommitmentAmount ?? 0))),
            ),
          ],
          [
            'Net Asset Value',
            currencies.formatMoney(
              Number(formatEther(BigInt(data?.contributionAmount ?? 0))),
            ),
          ],
        ]}
        footer={[
          [
            'Asking price',
            <Paragraph key="asking-price" loading={isLoading}>
              <strong>
                {currencies.formatMoney(
                  Number(formatEther(data?.priceAmount ?? BigInt(0))),
                )}
              </strong>
            </Paragraph>,
          ],
        ]}
      />

      <NumberInput
        label="Your bid"
        name="bid"
        prefix="$"
        precision={2}
        min={0}
        value={params.bidPrice}
        onChange={(value = 0) => {
          updateParams({ bidPrice: value });
        }}
      />

      <CardListItemContent
        loading={isLoading}
        body={[
          ['Buyer market commission', currencies.formatMoney(commissionFee)],
          ['Legal entity transfer fee', currencies.formatMoney(transferFee)],
          [
            'Total to pay',
            <Heading level="h4" key="total-amount" loading={isLoading}>
              {currencies.formatMoney(
                (params.bidPrice ?? 0) + commissionFee + transferFee,
              )}
            </Heading>,
          ],
        ]}
      />
    </CustomModalForm>
  );
};
