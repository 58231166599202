import { useEffect, useState } from 'react';

import { useContract } from '@hedgehog/browser/investors/account-abstraction/data-access';

import { useAssetOrdersQuery } from '../store/apis';
import { TokenHolderBalance } from '../types';

export const useRecipientBalances = (
  myWalletAddress: string,
  partnerId: string,
  assetId: string,
): {
  balances: TokenHolderBalance[] | undefined;
  totalSupply: number | undefined;
} => {
  const { getIssuances } = useContract();
  const { data: assetOrders } = useAssetOrdersQuery(
    {
      partnerId,
      assetId,
    },
    {
      skip: !(partnerId && assetId),
    },
  );

  const [totalSupply, setTotalSupply] = useState<number>();
  const [balances, setBalances] = useState<TokenHolderBalance[]>();

  useEffect(() => {
    if (!myWalletAddress || !assetOrders) return;

    const resolveIssuances = async (): Promise<void> => {
      const holderBalances: TokenHolderBalance[] = await Promise.all(
        assetOrders
          .filter(({ userWalletAddress }) => Boolean(userWalletAddress))
          .map(async (order): Promise<TokenHolderBalance> => {
            if (!order.userWalletAddress) {
              return {
                userId: '',
                userFirstName: '',
                userLastName: '',
                address: '',
                tokensHeld: 0,
              };
            }
            const result = await getIssuances(order.userWalletAddress);
            return {
              userId: order.userId,
              address: order.userWalletAddress,
              userFirstName: order.userFirstName,
              userLastName: order.userLastName,
              tokensHeld:
                result.data?.reduce(
                  (total, issuance) => total + issuance.amount,
                  0,
                ) || 0,
            };
          }),
      );
      setBalances(holderBalances.filter((balance) => balance.address));
      setTotalSupply(
        holderBalances.reduce(
          (total, balance) => total + balance.tokensHeld,
          0,
        ),
      );
    };
    resolveIssuances();
  }, [myWalletAddress, assetId, partnerId]);

  return { balances, totalSupply };
};
