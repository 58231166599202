import { GetFeatureFlagsResponse } from '@hedgehog/data-access/partners-types';

import { baseApi } from './base.api';

export const featureFlagsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    featureFlags: builder.query<GetFeatureFlagsResponse['data'], void>({
      query: () => 'feature-flags',
      transformResponse: (response: GetFeatureFlagsResponse) => response.data,
    }),
  }),
});

export const { useFeatureFlagsQuery, useLazyFeatureFlagsQuery } =
  featureFlagsApi;
