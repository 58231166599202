import { useState } from 'react';

interface UseTabsReturn<T> {
  activeTab: T;
  setActiveTab: (value: T) => void;
}

export const useTabs = <T>(initialTab: T): UseTabsReturn<T> => {
  const [activeTab, setActiveTab] = useState<T>(initialTab);
  return { activeTab, setActiveTab };
};
