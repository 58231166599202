import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { TextInput } from '@hedgehog/ui/inputs';

const SearchInputContainer = styled.div`
  width: 100%;
`;

type CountryPickerSearchInputProps = {
  value: string;
  onChange: (value: string) => void;
};

export const CountryPickerSearchInput = ({
  value,
  onChange,
}: CountryPickerSearchInputProps): JSX.Element => {
  const ref = useRef<HTMLInputElement>(null);

  const onKeyDown = (event: KeyboardEvent): void => {
    // Ensure that only alphanumerical characters trigger the focus.
    if (ref.current && /[a-zA-Z]+/g.test(event.key)) {
      // Focus the search input
      ref.current.focus();
    }
  };

  useEffect(() => {
    document.body.addEventListener('keydown', onKeyDown);
    return () => document.body.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <SearchInputContainer>
      <TextInput
        ref={ref}
        name="search"
        placeholder="Type your country name"
        onChange={onChange}
        value={value}
      />
    </SearchInputContainer>
  );
};

export default CountryPickerSearchInput;
