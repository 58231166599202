import {
  createContext,
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import {
  useColumnLayoutReducer,
  ColumnRef,
  ColumnSettings,
} from './column-layout.reducer';

export interface ColumnLayoutContextType {
  columns: ColumnRef[];
  mount: (column: ColumnRef) => void;
  unmount: (column: ColumnRef) => void;
  isMounted: (ref: RefObject<HTMLElement>) => boolean;
}

export const ColumnLayoutContext = createContext<ColumnLayoutContextType>({
  columns: [],
  mount: () => void 0,
  unmount: () => void 0,
  isMounted: (ref: RefObject<HTMLElement>) => false,
});

export const useColumnLayoutContext = (): ColumnLayoutContextType => {
  const [columns, dispatch] = useColumnLayoutReducer();

  return useMemo(() => {
    const mount = (column: ColumnRef): void => {
      dispatch({ type: 'mount', column });
    };

    const unmount = (column: ColumnRef): void => {
      dispatch({ type: 'unmount', column });
    };

    const isMounted = (ref: RefObject<HTMLElement>): boolean => {
      return columns.some((column) => column.ref.current === ref.current);
    };

    return {
      columns,
      mount,
      unmount,
      isMounted,
    };
  }, [columns]);
};

export const useColumnRef = (
  settings: ColumnSettings = {},
): RefObject<HTMLDivElement> => {
  const columnRef = useRef<HTMLDivElement | null>(null);
  const { mount, unmount, isMounted } = useContext(ColumnLayoutContext);

  useEffect(() => {
    const { current: columnElement } = columnRef;

    if (!columnElement) return;
    if (isMounted(columnRef)) return;

    mount({ ref: columnRef, settings });
    return () => {
      if (columnElement) unmount({ ref: columnRef, settings });
    };
  }, []);

  return columnRef;
};
