import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useEffect, useState } from 'react';
import { Address, zeroAddress } from 'viem';
import { useContractRead } from 'wagmi';

export interface UseBalanceProps {
  clientAddress: Address;
  fundAddress: Address;
}

export interface Balance {
  commitment: bigint;
  contribution: bigint;
}

export const useBalance = ({
  clientAddress,
  fundAddress,
}: UseBalanceProps): [
  (() => Promise<void>) | undefined,
  {
    data: Balance | undefined;
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
  },
] => {
  const [balance, setBalance] = useState<Balance>();

  const {
    refetch,
    data: balanceOf,
    isLoading,
    isError,
  } = useContractRead({
    abi: IFund.abi,
    address: fundAddress,
    functionName: 'balanceOf',
    args: [clientAddress],
    enabled:
      !!fundAddress &&
      fundAddress !== zeroAddress &&
      !!clientAddress &&
      clientAddress !== zeroAddress,
  });

  useEffect(() => {
    if (balanceOf) {
      const [commitment, contribution] = balanceOf as [bigint, bigint];
      setBalance({ commitment, contribution });
    }
  }, [balanceOf]);

  useEffect(() => {
    if (!fundAddress) return;
    if (fundAddress === zeroAddress) return;
    if (!clientAddress) return;
    if (clientAddress === zeroAddress) return;
    refetch();
  }, [fundAddress, clientAddress]);

  const refresh = async (): Promise<void> => {
    await refetch();
  };

  return [refresh, { data: balance, isLoading, isError, error: null }];
};
