import { ForwardedRef, forwardRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { AssetCardProps } from './asset-card.interface';
import { AssetCompactCard } from './asset-compact-card.component';
import { AssetLargeCard } from './asset-large-card.component';

export const AssetCard = styled(
  forwardRef(
    (
      { type, ...props }: AssetCardProps,
      ref: ForwardedRef<HTMLDivElement | HTMLAnchorElement>,
    ): JSX.Element => {
      switch (type) {
        case 'large':
          return <AssetLargeCard ref={ref} {...props} />;
        case 'compact':
          return <AssetCompactCard ref={ref} {...props} />;
        default:
          throw new Error(`AssetCardError: Unknown type "${type}"`);
      }
    },
  ),
)`
  ${({ theme }): CSSObject => ({
    backgroundColor: theme.colors.white,
  })}
`;
