import Emoji from 'react-emoji-render';
import styled from 'styled-components';

import { EmojiSyntax } from '@hedgehog/shared/types';
import { Chunk, ChunkProps } from '@hedgehog/ui/icons';
import { Title } from '@hedgehog/ui/typography';

export const StatusEmoji = styled(Emoji)`
  font-weight: bold;

  & > span {
    font-size: 1.5rem;
  }
`;

export const StatusText = styled(Title)`
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  font-weight: bold;

  & > * {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
`;

type StatusBadgeProps = {
  text: string;
  icon?: ChunkProps['icon'];
  emoji?: EmojiSyntax;
};

export const StatusBadge = ({
  text,
  emoji,
  icon,
}: StatusBadgeProps): JSX.Element => {
  return (
    <StatusText>
      {emoji && <StatusEmoji text={emoji} />}
      {text}
      {icon && <Chunk icon={icon} size="m" color="shadow200" />}
    </StatusText>
  );
};
