import {
  ForwardedRef,
  forwardRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';

import { ResourceSource } from '@hedgehog/shared/types';
import { screens } from '@hedgehog/utils/sizes';

const FullscreenGalleryVideo = styled.video`
  width: 100%;
  height: 40vh;
  object-fit: cover;

  @media (min-width: ${screens.medium}px) {
    height: 60vh;
  }
`;

type GalleryModalVideoProps = {
  resource: ResourceSource;
  autoPlay?: boolean;
  muted?: boolean;
  defaultVolume?: number;
};

export const GalleryModalVideo = forwardRef(
  (
    {
      resource,
      autoPlay = false,
      muted = false,
      defaultVolume = 1,
    }: GalleryModalVideoProps,
    ref: ForwardedRef<HTMLVideoElement>,
  ): JSX.Element => {
    const videoRef = useRef(ref);
    const [playing, setPlaying] = useState<boolean>(autoPlay);

    useEffect(() => {
      if (autoPlay && !playing) {
        setPlaying(true);
      } else if (!autoPlay && playing) {
        setPlaying(false);
      }
    }, [autoPlay, playing]);

    useEffect(() => {
      // Automatically play/pause the video when the user navigate to and from the slide with the video.
      const videoObj = (
        videoRef.current as unknown as RefObject<HTMLVideoElement>
      )?.current;
      if (!videoObj) return;
      if (playing) {
        videoObj.volume = defaultVolume;
        videoObj.play();
      } else {
        videoObj.pause();
      }
    }, [playing]);

    return (
      <FullscreenGalleryVideo ref={ref} controls muted={muted}>
        <source src={resource.src} />
      </FullscreenGalleryVideo>
    );
  },
);
