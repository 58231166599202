export const UserIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6Z"
      fill="currentColor"
    />
    <path
      d="M12 11C12 9.34315 10.6569 8 9 8H3C1.34315 8 0 9.34315 0 11V14H12V11Z"
      fill="currentColor"
    />
  </svg>
);

export default UserIcon;
