import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import { Input, InputProps } from '../Input/Input';

type TextInputProps = InputProps & {
  type?: string;
  multiline?: boolean;
  onChange?: (value: string) => void;
  className?: string;
};

export const TextInput = forwardRef(
  (
    { type = 'text', onChange, ...inputProps }: TextInputProps,
    ref: ForwardedRef<HTMLInputElement>,
  ): JSX.Element => {
    const handleChange = (
      event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ): void => {
      if (onChange) {
        onChange(event.target.value);
      }
    };
    return (
      <Input
        {...inputProps}
        type={type}
        ref={ref}
        onChange={onChange ? handleChange : undefined}
      />
    );
  },
);

export default TextInput;
