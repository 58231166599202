import { useRef } from 'react';
import styled, { CSSProp } from 'styled-components';

import { parseBorderToShadow } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { useRadioValueController } from '../RadioInputGroup/radio-group.context';

const RadioCheckmark = styled.span`
  position: relative;
  top: 0;
  left: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  ${({ theme }): CSSProp => ({
    boxShadow: parseBorderToShadow(theme.border.normal, { outset: false }),
    borderColor: theme.colors.shadow100,
    backgroundColor: theme.colors.grey100,
  })}

  &:after {
    position: absolute;
    display: block;
    content: '';
    opacity: 0;

    top: 0.5rem;
    left: 0.5rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: rgb(0, 0, 20);
    transition: opacity 100ms ease-out;
  }
`;

const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
  ${({ theme }): CSSProp => ({ ...theme.typography.body })}

  &:hover ${RadioCheckmark} {
    ${({ theme }): CSSProp => ({
      boxShadow: parseBorderToShadow(theme.border.normal, {
        width: '4px',
        color: theme.colors.shadow100,
        outset: false,
      }),
    })}
  }
`;

const HiddenRadioInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ ${RadioCheckmark}:after {
    opacity: 1;
  }
`;

export type RadioInputProps = StandardProps<{
  name: string;
  id: string;
  value: string;
  label?: string;
}>;

export const RadioInput = ({
  name,
  id,
  value,
  label,
  children = label,
}: RadioInputProps): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [checked, handleCheck] = useRadioValueController(inputRef);
  return (
    <RadioContainer htmlFor={id} onClick={(): void => handleCheck()}>
      <HiddenRadioInput
        type="radio"
        name={name}
        id={id}
        checked={checked.some((_name) => name === _name)}
        onChange={() => void 0}
        value={value}
        ref={inputRef}
      />
      <RadioCheckmark />
      {children || value}
    </RadioContainer>
  );
};

export default RadioInput;
