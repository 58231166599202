export const PlusCircle = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.5 10.5V6H13.5V10.5H18V13.5H13.5V18H10.5V13.5H6V10.5H10.5Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusCircle;
