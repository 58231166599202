import { Address } from 'viem';

export enum BidStatus {
  open = 0,
  withdrawn = 1,
  rejected = 2,
  accepted = 3,
  settled = 4,
}

export type BidStatusLiteral = keyof typeof BidStatus;

export enum ListingStatus {
  open = 0,
  withdrawn = 1,
  closed = 2,
  settled = 3,
}

export type ListingStatusLiteral = keyof typeof ListingStatus;

export type Bid = {
  id: number;
  bidder: Address;
  fundId: string;
  fundName: string;
  fundDescription: string;
  commitment: bigint;
  contribution: bigint;
  bidPrice: bigint;
  listingPrice: bigint;
  status: BidStatusLiteral;
};

export type Listing = {
  listingId: bigint;
  fundId: string;
  fundName: string;
  fundDescription: string;
  fundContractAddress: Address;
  commitment: bigint;
  contribution: bigint;
  price: bigint;
  status: ListingStatusLiteral;
  bids: Bid[];
};
