import { gql } from '@apollo/client';

export const SUBMIT_ASSESSMENT = gql`
  mutation SubmitAssessment($args: SaveResponseInput!) {
    saveResponse(args: $args) {
      success
      status
    }
  }
`;
