import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useAppData } from '@hedgehog/data-access/partners';
import { Button } from '@hedgehog/ui/buttons';
import { fromBreakpoint } from '@hedgehog/ui/themes';

import { NavigationBrand } from '../../components';
import { NavigationProps } from '../navigation/types';

const TopbarWrapper = styled.nav`
  position: sticky;

  top: 0;
  left: 0;
  min-height: 5.25rem;
  max-height: 5.25rem;
  width: 100%;
  ${({ theme }): CSSProp => ({
    ...fromBreakpoint(theme.breakpoints.desktop, {
      display: 'none',
      visibility: 'hidden',
    }),
  })}
`;

const TopbarInnerWrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  ${({ theme }): CSSProp => ({ padding: theme.spacing.large })}
`;

const TopbarDropdown = styled.div<{ collapsed?: boolean }>`
  position: fixed;
  width: 100%;
  z-index: 500000;

  top: 5.25rem;
  &:after {
    content: '';
    height: 100vh;
    width: 100vw;
    transform: opacity 200ms ease-in-out;
  }

  ${({ theme, collapsed }): CSSProp => ({
    [`&:after`]: {
      background: theme.colors.shadow600,
      display: collapsed ? 'none' : 'block',
      opacity: collapsed ? 0 : 1,
    },
    ...fromBreakpoint(theme.breakpoints.desktop, {
      display: 'none',
      visibility: 'hidden',
      [`&:after`]: {
        display: 'none',
      },
    }),
  })}
`;

const TopbarButton = styled(Button)`
  background-color: transparent;
`;

const TopbarDropdownWrapper = styled.div<{ collapsed?: boolean }>`
  overflow-y: auto;
  transition: max-height 200ms ease-in-out;
  height: 100%;
  ${({ collapsed, theme }): CSSProp => ({
    backgroundColor: theme.colors.white,
    maxHeight: collapsed ? '0rem' : `100vh`,
  })};
`;

const TopbarDropdownInnerWrapper = styled.div`
  ${({ theme }): CSSProp => ({
    padding: theme.spacing.large,
    paddingTop: 0,
  })}
`;

export const Topbar = ({ items }: NavigationProps): JSX.Element => {
  const { activePartner } = useAppData();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const toggle = (): void => {
    setCollapsed((prevState) => {
      const newState = !prevState;
      return newState;
    });
  };

  useEffect(() => {
    if (!collapsed) setCollapsed(true);
  }, [location]);

  const handleBackdropClick = () => {
    setCollapsed(true);
  };

  const preventFurtherPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <>
      <TopbarWrapper>
        <TopbarInnerWrapper>
          <NavigationBrand
            src={activePartner?.resources?.logotypeLightUrl || ''}
            alt={`${activePartner?.name} logo`}
            loading={!activePartner?.resources?.logotypeLightUrl}
          />

          <TopbarButton
            icon={collapsed ? 'menu' : 'cross'}
            onClick={toggle}
          ></TopbarButton>
        </TopbarInnerWrapper>
      </TopbarWrapper>
      <TopbarDropdown collapsed={collapsed} onClick={handleBackdropClick}>
        <TopbarDropdownWrapper
          collapsed={collapsed}
          onClick={preventFurtherPropagation}
        >
          <TopbarDropdownInnerWrapper>{items}</TopbarDropdownInnerWrapper>
        </TopbarDropdownWrapper>
      </TopbarDropdown>
    </>
  );
};
