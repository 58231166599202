import { useParams } from 'react-router-dom';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { AssetHighlights } from '@hedgehog/browser/shared/assets';
import { AssetPage } from '@hedgehog/data-access/asset-types';
import {
  useAssetQuery,
  useAssetRoundClass,
  useAppData,
} from '@hedgehog/data-access/partners';
import { ContentCMS } from '@hedgehog/ui/cms';
import {
  BlockContainer,
  EmptyStateContainer,
  LoadingContainer,
} from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';

import { AssetPageTab } from '../../components';

export const AssetOverviewTabPage = () => {
  const track = useAnalyticsTrack();
  const { assetId = '' } = useParams();
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';
  const { data: asset } = useAssetQuery(
    { assetId, partnerId },
    { skip: !assetId || !partnerId },
  );
  const roundClass = useAssetRoundClass(assetId);
  const page = asset?.pages.find(
    (entity: AssetPage) => entity.slug === 'overview',
  );

  if (!asset) {
    return (
      <LoadingContainer>
        <Loader />
      </LoadingContainer>
    );
  }

  if (!page) {
    return <EmptyStateContainer>Page not found</EmptyStateContainer>;
  }

  return (
    <AssetPageTab>
      {roundClass && roundClass.metrics.length !== 0 && (
        <BlockContainer>
          <AssetHighlights
            hasTaxLeakage={Boolean(roundClass.hasTaxLeakage)}
            metrics={roundClass.metrics}
          />
        </BlockContainer>
      )}
      <ContentCMS content={page.content} onAnalyticsTrack={track} />
      {/* TODO: Add sponsor card */}
    </AssetPageTab>
  );
};
