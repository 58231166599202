import {
  GetClientInvestmentMetricsRequest,
  GetClientInvestmentMetricsResponse,
  GetPartnerClientsResponse,
  CreatePartnerClientRequest,
  CreatePartnerClientResponse,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import {
  GetPartnerClientResponse,
  GetPartnerClientRequest,
  GetPartnerClientsRequest,
  SendClientWelcomeResponse,
  SendClientWelcomeRequest,
  CreateClientRewardResponse,
  CreateClientRewardRequest,
} from '../../types';

import { baseApi } from './base.api';

export const clientsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    clients: builder.query<GetPartnerClientsResponse, GetPartnerClientsRequest>(
      {
        query: ({
          partnerId,
          query,
          declarations,
          missingDeclarations,
          requestedDocs,
          downloadedIm,
          ...otherParams
        }) => ({
          url: `partners/${partnerId}/clients`,
          params: {
            ...otherParams,
            q: query,
            declarations,
            missingDeclarations,
            requestedDocs,
            downloadedIm,
          },
        }),
        providesTags: (result, error, request) =>
          result
            ? [
                ...result.data.map(
                  ({ id }) => ({ type: 'Clients' as const, id }),
                  {
                    type: 'Clients' as const,
                    id: 'LIST',
                  },
                ),
              ]
            : [{ type: 'Clients' as const, id: 'LIST' }],
      },
    ),
    client: builder.query<
      GetPartnerClientResponse['data'],
      GetPartnerClientRequest
    >({
      query: ({ partnerId, clientId }) => ({
        url: `partners/${partnerId}/clients/${clientId}`,
      }),
      providesTags: (result) =>
        result ? [{ type: 'Clients', id: result.id }] : [],
      transformResponse: (response: GetPartnerClientResponse) => response.data,
    }),
    clientInvestmentMetrics: builder.query<
      GetClientInvestmentMetricsResponse['data'],
      GetClientInvestmentMetricsRequest
    >({
      query: ({ partnerId, clientId }) => ({
        url: `partners/${partnerId}/metrics/clients/${clientId}/investments`,
      }),
      transformResponse: (response: GetClientInvestmentMetricsResponse) =>
        response.data,
    }),
    createClient: builder.mutation<
      CreatePartnerClientResponse['data'],
      CreatePartnerClientRequest & { partnerId: UUID }
    >({
      query: ({ partnerId, ...body }) => ({
        url: `partners/${partnerId}/clients`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreatePartnerClientResponse) =>
        response.data,
      invalidatesTags: () => ['Clients', 'Metrics'],
    }),
    sendClientWelcome: builder.mutation<
      SendClientWelcomeResponse,
      SendClientWelcomeRequest
    >({
      query: ({ partnerId, clientId }) => ({
        url: `partners/${partnerId}/clients/${clientId}/send-welcome-email`,
        method: 'POST',
      }),
    }),
    issueClientReward: builder.mutation<
      CreateClientRewardResponse,
      CreateClientRewardRequest
    >({
      query: ({ partnerId, assetId, ...body }) => ({
        url: `partners/${partnerId}/assets/${assetId}/issuances`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useLazyClientsQuery,
  useClientsQuery,
  useClientQuery,
  useCreateClientMutation,
  useClientInvestmentMetricsQuery,
  useSendClientWelcomeMutation,
  useIssueClientRewardMutation,
} = clientsApi;
