export const HomeIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 6V15H5V11C5 9.89543 5.89543 9 7 9C8.10457 9 9 9.89543 9 11V15H14V6L7 0L0 6Z"
      fill="currentColor"
    />
  </svg>
);

export default HomeIcon;
