import { useNavigate } from 'react-router-dom';

import { BreadcrumbsLayout } from '@hedgehog/browser/partners/shared/layouts';
import {
  useAddFundUserOperation,
  useAppData,
} from '@hedgehog/data-access/partners';
import { LoadingContainer } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph } from '@hedgehog/ui/typography';

import { CreateFundForm, CreateFundFormData } from '../../../containers';

export const CreateFundPage = (): JSX.Element | null => {
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';

  const navigate = useNavigate();

  const [createFund, { loading, error }] = useAddFundUserOperation();

  const handleSubmit = async (data: CreateFundFormData) => {
    createFund({
      ...data,
      size: parseInt(`${data.size}`),
      partnerId,
    });

    navigate('/funds/funds');
  };

  return (
    <BreadcrumbsLayout>
      {error && <Paragraph>{error}</Paragraph>}
      {!loading && (
        <CreateFundForm onSubmit={handleSubmit} isLoading={loading} />
      )}
      {loading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
    </BreadcrumbsLayout>
  );
};
