import styled, { CSSProp } from 'styled-components';

import { NavbarFooter } from '@hedgehog/browser-partners-shared-navbar';
import { useAppData } from '@hedgehog/data-access/partners';
import { fromBreakpoint } from '@hedgehog/ui/themes';

import { NavigationBrand } from '../../components/navigation-brand/navigation-brand.component';
import { NavigationProps } from '../navigation/types';

const SidebarWrapper = styled.nav`
  display: none;
  visibility: hidden;

  flex-flow: column nowrap;

  position: sticky;
  height: 100%;
  left: 0;
  top: 0;
  min-width: 15rem;
  max-width: 15rem;

  ${({ theme }): CSSProp => ({
    padding: theme.spacing.large,
    paddingTop: theme.spacing.xxxlarge,
    backgroundColor: theme.colors.white,
    ...fromBreakpoint(theme.breakpoints.desktop, {
      display: 'flex',
      visibility: 'visible',
    }),
  })}
`;
const SidebarInnerWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;

  ${({ theme }): CSSProp => ({
    backgroundColor: theme.colors.white,
  })}
`;
const SidebarUpperContent = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: 3.5rem;
`;
const SidebarLowerContent = styled.div``;

export const Sidebar = ({ items }: NavigationProps): JSX.Element => {
  const { activePartner, featureFlags } = useAppData();

  return (
    <SidebarWrapper>
      <SidebarInnerWrapper>
        <SidebarUpperContent>
          <NavigationBrand
            src={activePartner?.resources?.logotypeLightUrl || ''}
            alt={`${activePartner?.name} logo`}
            loading={!activePartner?.resources?.logotypeLightUrl}
          />

          {items}
        </SidebarUpperContent>
        <SidebarLowerContent>
          <NavbarFooter />
        </SidebarLowerContent>
      </SidebarInnerWrapper>
    </SidebarWrapper>
  );
};
