import { default as IFund } from '@hedgehoginvest/contracts/artifacts/src/fund/interfaces/IFund.sol/IFund.json';
import { useEffect, useState } from 'react';
import {
  Address,
  Hex,
  decodeAbiParameters,
  formatEther,
  getAddress,
  zeroAddress,
} from 'viem';
import { usePublicClient } from 'wagmi';

import { FundV2 } from '@hedgehog/data-access/partners-types';

import {
  BidStatus,
  BidStatusLiteral,
  Listing,
  ListingStatus,
  ListingStatusLiteral,
} from '../../types';

import { useFundRead } from './core';

export type UseListingsOutput = {
  data: Listing[];
  isLoading: boolean;
  isError: boolean;
  error: Error | null;
};

export const useFundListings = ({
  fund,
  watch = false,
}: {
  fund: FundV2 | undefined;
  watch?: boolean;
}): UseListingsOutput => {
  const [listings, setListings] = useState<UseListingsOutput['data']>([]);
  const publicClient = usePublicClient();

  const [, { data, error, isLoading: areListingsLoading, isError }] =
    useFundRead({
      address: getAddress(fund?.address ?? zeroAddress),
      functionName: 'getListings',
      args: [],
      enabled: !!fund && !!fund.address,
      scopeKey: 'funds/listings',
      watch,
    });

  const fetchListingsWithBids = async (
    listingId: bigint,
    listingHexData: Hex,
  ): Promise<Listing> => {
    const [
      commitment = BigInt(0),
      contribution = BigInt(0),
      price = BigInt(0),
      status = 0,
    ] = decodeAbiParameters(
      [
        {
          name: 'commitmentTokenAmount',
          type: 'uint256',
        },
        {
          name: 'contributionTokenAmount',
          type: 'uint256',
        },
        {
          name: 'price',
          type: 'uint256',
        },
        {
          name: 'status',
          type: 'uint8',
        },
      ],
      listingHexData,
    );

    const bids = (await publicClient.readContract({
      address: getAddress(fund?.address ?? zeroAddress),
      abi: IFund.abi,
      functionName: 'getBids',
      args: [listingId],
    })) as { id: number; bidder: Address; price: bigint; status: number }[];

    return {
      listingId: listingId,
      fundId: fund?.fundId ?? '',
      fundName: fund?.name ?? '',
      fundDescription: fund?.description ?? '',
      fundContractAddress: getAddress(fund?.address ?? zeroAddress),
      commitment,
      contribution,
      price,
      status: ListingStatus[status] as ListingStatusLiteral,
      bids: bids.map((bid) => ({
        ...bid,
        fundId: fund?.fundId ?? '',
        fundName: fund?.name ?? '',
        fundDescription: fund?.description ?? '',
        commitment,
        contribution,
        bidPrice: bid.price,
        listingPrice: price,
        status: BidStatus[bid.status] as BidStatusLiteral,
      })),
    };
  };

  const loadListings = async (): Promise<void> => {
    if (!data) return;

    // Extract typed data from the response
    const [listingIds, listingHexData] = (data ?? []) as [bigint[], Hex[]];

    // Decode the hex data, fetch the bids and return the listings as our Listing.
    const listings = await Promise.all(
      listingIds.map((id, index) =>
        fetchListingsWithBids(id, listingHexData[index]),
      ),
    );

    setListings(listings.flat());
  };

  useEffect(() => {
    if (!data) return;
    setListings([]);
    loadListings();
  }, [data]);

  if (!data) {
    return { data: [], isLoading: areListingsLoading, isError, error };
  }

  return {
    data: listings,
    isLoading: areListingsLoading,
    isError,
    error,
  };
};
