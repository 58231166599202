export const ThumbsUp = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0L5 5V14H14L16 8V5H10V2C10 0.895431 9.10457 0 8 0H7Z"
      fill="currentColor"
    />
    <path d="M3 5H0V14H3V5Z" fill="currentColor" />
  </svg>
);

export default ThumbsUp;
