import {
  ReactNode,
  RefObject,
  createContext,
  createRef,
  useMemo,
  useRef,
  useState,
} from 'react';

export type CacheEntry<V = unknown> = {
  value: V;
  createdAt: number;
  validatedAt: number;
  expiresAt: number | null;
};

export interface CacheManagerContextType {
  cache: RefObject<Record<string, CacheEntry<unknown>>>;
  seed: symbol;
  get: <V = unknown>(key: string) => CacheEntry<V> | null;
  set: <V = unknown>(key: string, value: V, durationInMs?: number) => void;
  clear: (key: string) => void;
}

export const CacheManagerContext =
  createContext<CacheManagerContextType | null>({
    cache: createRef(),
    seed: Symbol('cache:digest'),
    get: () => null,
    set: () => void 0,
    clear: () => void 0,
  });

export type CacheManagerProps = { children: ReactNode | ReactNode[] };

export const CacheManager = ({ children }: CacheManagerProps): JSX.Element => {
  const cache = useRef<Record<string, CacheEntry<unknown>>>({});
  const [seed, setSeed] = useState<symbol>(Symbol('cache:digest'));

  const updateSeed = (): void => setSeed(Symbol('cache:digest'));

  const set: CacheManagerContextType['set'] = (key, value, durationInMs) => {
    cache.current[key] = {
      value,
      createdAt: Date.now(),
      validatedAt: Date.now(),
      expiresAt: durationInMs ? Date.now() + durationInMs : null,
    };

    updateSeed();
  };

  const get = <V,>(key: string): CacheEntry<V> | null => {
    const value = cache.current[key] ?? null;
    return value as CacheEntry<V> | null;
  };

  const clear: CacheManagerContextType['clear'] = (key) => {
    delete cache.current[key];
    updateSeed();
  };

  const contextValue = useMemo(() => {
    console.debug('Cache updated', {
      next: { ...cache.current },
    });
    return { cache, seed, get, set, clear };
  }, [seed]);

  return (
    <CacheManagerContext.Provider value={contextValue}>
      {children}
    </CacheManagerContext.Provider>
  );
};
