import {
  CreatePartnerClientOrderRequest,
  CreatePartnerClientOrderResponse,
  GetPartnerClientOrdersRequest,
  GetPartnerClientOrdersResponse,
} from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';

import { baseApi } from './base.api';

export const clientOrdersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    clientOrders: builder.query<
      GetPartnerClientOrdersResponse['data'],
      GetPartnerClientOrdersRequest
    >({
      query: ({ partnerId, clientId }) => ({
        url: `partners/${partnerId}/clients/${clientId}/orders`,
      }),
      transformResponse: (response: GetPartnerClientOrdersResponse) =>
        response.data,
    }),
    createClientOrder: builder.mutation<
      CreatePartnerClientOrderResponse['data'],
      CreatePartnerClientOrderRequest & { partnerId: UUID; clientId: UUID }
    >({
      query: ({ partnerId, clientId, ...body }) => ({
        url: `partners/${partnerId}/clients/${clientId}/orders`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: CreatePartnerClientOrderResponse) =>
        response.data,
    }),
  }),
});

export const { useClientOrdersQuery, useCreateClientOrderMutation } =
  clientOrdersApi;
