import { gql } from '@apollo/client';

export const CONFIRM_ORDER_TRANSFER = gql`
  mutation ConfirmOrderTransfer($id: String!) {
    confirmOrderTransfer(args: { id: $id }) {
      id
      transferConfirmed
    }
  }
`;
