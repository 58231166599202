import { ModalForm } from '../../components';
import { ClientRewardsForm } from '../client-rewards-form/client-rewards-form.container';

export interface ClientRewardsModalProps {
  submitModal: () => void;
  cancelModal: () => void;
}

export const ClientRewardsModal = (
  props: ClientRewardsModalProps,
): JSX.Element => {
  return (
    <ModalForm
      submitModal={props.submitModal}
      closeModal={props.cancelModal}
      heading="Client Rewards"
      submitButtonText="Done"
      closeButtonText="Close"
    >
      <ClientRewardsForm />
    </ModalForm>
  );
};
