import React from 'react';
import { Link, To } from 'react-router-dom';
import styled from 'styled-components';

import { Icon, IconProps } from '@hedgehog/ui/icons';

const noop = (): void => undefined;

export type HeaderButtonProps = Omit<IconProps, 'size'> & {
  to?: To | number;
  href?: never;
};

export const HeaderButton = styled(
  ({
    to,
    href,
    icon,
    onClick = noop,
    src,
    ...otherProps
  }: HeaderButtonProps) => {
    const IconComponent = (
      <Icon {...otherProps} size="m" icon={icon} onClick={onClick} />
    );
    return to ? (
      <Link to={to as To}>{IconComponent}</Link>
    ) : href ? (
      <a href={href}>{IconComponent}</a>
    ) : (
      IconComponent
    );
  },
)``;
