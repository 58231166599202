import styled from 'styled-components';

import { BrandLogotype, BrandLogotypeImage } from '@hedgehog/ui/layouts';
import { StandardProps } from '@hedgehog/ui/utils';

export type NavigationBrandProps = StandardProps<
  {
    src?: string;
    alt?: string;
    loading?: boolean;
  },
  never
>;

export const NavigationBrand = styled(
  ({ ...props }: NavigationBrandProps): JSX.Element => (
    <BrandLogotype hedgehog={false} {...props} />
  ),
)`
  & ${BrandLogotypeImage} {
    max-height: 2rem;
    max-width: 100%;
  }
`;
