export enum LocalStorageAuthKeys {
  AccessToken = 'token',
  RefreshToken = 'refresh_token',
  User = 'user',
}

export type CognitoSessionTokens = {
  accessToken: string;
  refreshToken: string;
};
