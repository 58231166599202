import { useEffect, useState } from 'react';

import { useFundManagerContract } from './use-fund-manager-contract.hook';

type Result = {
  data: boolean;
  loading: boolean;
  error?: string;
};

type ReloadFn = () => Promise<void>;

export const useIsAuthorisedQuery = ({
  fundRef,
  address,
}: {
  fundRef: string;
  address: string;
}): [ReloadFn, Result] => {
  const fundManagerContract = useFundManagerContract();

  const [data, setData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  const query = async ({
    fundRef,
    address,
  }: {
    fundRef: string;
    address: string;
  }): Promise<void> => {
    setLoading(true);
    try {
      const isAuthorised = await fundManagerContract.callStatic[
        'isAuthorised(bytes32,address)'
      ](fundRef, address);
      console.log(isAuthorised);
      setData(isAuthorised);
    } catch (err) {
      console.log('failed to check authorisation', err);
      setError(
        `Failed to check authorisation: ${
          err instanceof Error ? err.message : 'unknown error'
        }`,
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!fundManagerContract) return;
    if (!fundRef) return;
    if (!address) return;
    if (address === '0x') return;

    query({ fundRef, address });
  }, [fundManagerContract]);

  const reload = async (): Promise<void> => {
    if (address === '0x') return;
    query({ fundRef, address });
  }

  return [reload, { data, loading, error }];
};
