import { ReactNode, useState } from 'react';
import styled from 'styled-components';

import { BlockContainer } from '@hedgehog/ui/layouts';
import { StandardProps } from '@hedgehog/ui/utils';

import { AccordionHeader } from '../AccordionHeader/AccordionHeader';

const AccordionWrapper = styled(BlockContainer)`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
`;

const AccordionContainer = styled.div<{
  collapsed: boolean;
  maxHeightRem?: number;
}>`
  overflow: hidden;
  height: auto;
  max-height: ${({ collapsed, maxHeightRem }): string =>
    collapsed ? '0rem' : `${maxHeightRem || 20}rem`};
  transition: max-height 300ms ease-in-out;
  overflow-y: auto;

  &[hidden] {
    display: block;
  }

  &:before {
    display: block;
    content: '';
    padding-top: 1rem;
  }
`;

export type AccordionProps = StandardProps<{
  title: ReactNode | ReactNode[];
  initialCollapsed?: boolean;
  /**
   * Max content height in REM
   */
  maxHeightRem?: number;
  backgroundColor?: string;
  padding?: string;
  toggleOnClick?: boolean;
  openIcon?: string;
  closeIcon?: string;
  onToggle?: (open: boolean) => void;
}>;

export const Accordion = ({
  title,
  initialCollapsed = true,
  children,
  maxHeightRem,
  backgroundColor,
  padding,
  toggleOnClick = true,
  onToggle,
  openIcon,
  closeIcon,
  className,
}: AccordionProps): JSX.Element => {
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  const toggle = (): void => {
    setCollapsed((prevState) => {
      const newState = !prevState;
      onToggle?.(newState);
      return newState;
    });
  };

  return (
    <AccordionWrapper
      direction="column"
      backgroundColor={backgroundColor}
      padding={padding}
      className={className}
    >
      <AccordionHeader
        title={title}
        collapsed={collapsed}
        onClick={toggle}
        openIcon={openIcon}
        closeIcon={closeIcon}
      />
      <AccordionContainer
        collapsed={collapsed}
        hidden={collapsed}
        maxHeightRem={maxHeightRem}
        {...(toggleOnClick && { onClick: toggle })}
      >
        {children}
      </AccordionContainer>
    </AccordionWrapper>
  );
};
