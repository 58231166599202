import styled from 'styled-components';

import { useClientEventsQuery } from '@hedgehog/data-access/partners';
import { PartnerClientEvent } from '@hedgehog/data-access/partners-types';
import { UUID } from '@hedgehog/shared/types';
import { ActionCard } from '@hedgehog/ui/cards';
import { Timeline, TimelineEntry } from '@hedgehog/ui/lists';
import { Loader } from '@hedgehog/ui/loaders';

import { ClientTimelineEntry } from '../client-timeline-entry/client-timeline-entry';

const AddonContainer = styled.div`
  margin-top: 1rem;
`;

type ClientTimelineProps = {
  partnerId: UUID;
  clientId: UUID;
};

export const ClientTimeline = ({
  partnerId,
  clientId,
}: ClientTimelineProps) => {
  const { data, isFetching } = useClientEventsQuery({
    partnerId,
    clientId,
  });

  if (isFetching) {
    return <Loader />;
  }

  const renderAddon = (event: PartnerClientEvent): JSX.Element | null => {
    if (!event) return null;
    const { fileName, fileUrl, assetId } = event.metadata;

    if (fileName && fileUrl) {
      return (
        <a href={fileUrl} target="_blank" rel="noreferrer" download>
          <ActionCard leadingIcon="download" title={fileName} />
        </a>
      );
    }
    if (assetId) {
      return <ClientTimelineEntry assetId={assetId} />;
    }
    return null;
  };

  return (
    <Timeline
      timestampFormat="dd MMM YYY"
      entries={(data || []).map((event: PartnerClientEvent): TimelineEntry => {
        const addon = renderAddon(event);
        return {
          icon: 'hedgehog',
          label: event.message,
          date: event.eventDate,
          addon: addon ? <AddonContainer>{addon}</AddonContainer> : null,
        };
      })}
    />
  );
};
