import { gql } from '@apollo/client';

import { ContentBlockDataFragment } from '../content';

export const GET_SPONSOR = gql`
  query GetSponsor($id: String, $slug: String) {
    sponsor(args: { id: $id, slug: $slug }) {
      name
      description
      slug
      imageUrl
      headline
      subheadline
      bannerUrl
      content {
        ...ContentBlockDataFragment
      }
    }
  }
  ${ContentBlockDataFragment}
`;
