import { AssetCategory } from '@hedgehog/shared/types';

import { IconType, icons } from '../icons';

type AssetCategoryIconProps = {
  category?: AssetCategory;
  className?: string;
};

export const resolveAssetCategoryIconType = (
  category?: AssetCategory,
): IconType => {
  switch (category) {
    case AssetCategory.RESIDENTIAL:
      return 'hotel';
    case AssetCategory.HOUSING:
      return 'home';
    case AssetCategory.INDUSTRIAL:
      return 'truck';
    case AssetCategory.OFFICE:
      return 'building';
    case AssetCategory.RENEWABLE:
      return 'leaf';
    case AssetCategory.CONTENT_STUDIO:
      return 'video';
    case AssetCategory.BIOMEDICAL_LIFE_SCIENCES:
      return 'heart';
    case AssetCategory.TENANT_TOKEN:
      return 'token';
    default:
      return 'help';
  }
};

export const AssetCategoryIcon = ({
  className,
  category,
}: AssetCategoryIconProps): JSX.Element | null => {
  const iconType = resolveAssetCategoryIconType(category);
  const Icon = icons[iconType];

  if (!Icon) {
    return null;
  }

  return (
    <div className={className}>
      <Icon />
    </div>
  );
};

export default AssetCategoryIcon;
