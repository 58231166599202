import styled, { CSSObject } from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { IconType, icons } from '../icons';

export type ChunkSize = 's' | 'm' | 'l';

type IconChunkProps = {
  icon?: IconType;
  alt?: never;
  src?: never;
};

type ImageChunkProps = {
  src?: string;
  alt?: string;
  icon?: never;
};

export type ChunkProps = StandardProps<
  {
    size?: ChunkSize;
    color?: ThemeColorName;
  } & (IconChunkProps | ImageChunkProps),
  never
>;

export const Chunk = styled(
  ({
    icon,
    src,
    size = 'm',
    color,
    className,
    ...otherProps
  }: ChunkProps): JSX.Element | null => {
    const MappedIcon = icon
      ? icon in icons
        ? icons[icon as IconType]
        : null
      : null;

    return (
      <div {...otherProps} className={className}>
        {MappedIcon && <MappedIcon />}
        {src && <img src={src} alt="" />}
      </div>
    );
  },
)`
  ${({ size, color, theme }): CSSObject => ({
    display: 'flex',
    color: color ? theme.colors[color] : 'currentColor',
    width: size === 's' ? '0.75rem' : size === 'm' ? '1rem' : '1.25rem',
    height: size === 's' ? '0.75rem' : size === 'm' ? '1rem' : '1.25rem',
  })};
`;
