import { MobileMessagePayload, MobileMessageType } from './mobile-message.type';

export const sendMessageToApp = (message: MobileMessageType): void => {
  const { webkit } = window;
  if (!webkit?.messageHandlers?.nativeAppMessageHandler) return;

  webkit.messageHandlers.nativeAppMessageHandler.postMessage(message);
};

export const sendMessageWithPayloadToApp = <T extends MobileMessageType>(
  message: T,
  payload: MobileMessagePayload[T] = {},
): void => {
  const { webkit } = window;
  if (!webkit?.messageHandlers?.nativeAppMessageHandler) return;

  const appMessage = { message: message, payload: payload };
  webkit.messageHandlers.nativeAppMessageHandler.postMessage(appMessage);
};
