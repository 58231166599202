import { useLocation } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { useAccountAbstraction } from '@hedgehog/browser/shared/account-abstraction';
import { NavItem } from '@hedgehog/browser-partners-shared-navbar';
import { FeatureFlagNames } from '@hedgehog/data-access/graphql';
import {
  useAppData,
  useFundManagerContract,
  usePartnerSettingsQuery,
} from '@hedgehog/data-access/partners';

import { Sidebar } from '../sidebar/sidebar.container';
import { Topbar } from '../topbar';

const NavbarContent = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: 1rem;
  padding-bottom: 1rem;
  &:not(:last-child) {
    border-bottom: 1px solid;
    ${({ theme }): CSSProp => ({ borderBottomColor: theme.colors.grey200 })}
  }
`;

const NavbarList = styled.div`
  display: flex;
  flex-flow: column nowrap;

  gap: 1rem;
`;

const StatusContent = styled.div`
  padding: 0.5rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
`;

const StatusBadge = styled.div<{ color: string }>`
  display: flex;
  background-color: ${(props): string => props.color};
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
`;

const BlockchainStatusBadges = () => {
  const { pathname } = useLocation();
  const { address: erc4337Address } = useAccountAbstraction();
  const fundManagerContract = useFundManagerContract();

  if (!pathname.includes('/funds')) return null;

  return (
    <NavbarContent>
      <StatusContent>
        Account Abstraction
        {erc4337Address && <StatusBadge color={'green'} />}
        {!erc4337Address && <StatusBadge color={'red'} />}
      </StatusContent>
      <StatusContent>
        Fund Manager Contract
        {fundManagerContract && <StatusBadge color={'green'} />}
        {!fundManagerContract && <StatusBadge color={'red'} />}
      </StatusContent>
    </NavbarContent>
  );
};

export const Navigation = (): JSX.Element => {
  const { activePartner, featureFlags } = useAppData();
  const { data } = usePartnerSettingsQuery(
    {
      partnerId: activePartner?.id ?? '',
    },
    { skip: !activePartner?.id },
  );

  const isMarketplaceAvailable = data ? data['MARKETPLACE_TOGGLE'] : false;
  const isPartnerManageTeamMembersEnabled = featureFlags.find(
    (flag) => flag.name === FeatureFlagNames.partners_manage_team_members,
  )?.enabled;

  // TODO: 🎨 UI Configurability tech debt: https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements
  const isOakglen = activePartner?.slug === 'oakglen';

  const links = (
    <NavbarList>
      <NavbarContent>
        <NavItem to="/dashboard" icon="chunk">
          Dashboard
        </NavItem>
        <NavItem to="/clients" icon="users">
          Clients
        </NavItem>
        <NavItem to="/assets" icon="business">
          Assets
        </NavItem>
        {/* // TODO: 🎨 UI Configurability tech debt: https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
        {!isOakglen && (
          <NavItem to="/reporting" icon="chart">
            Reporting
          </NavItem>
        )}
      </NavbarContent>
      {isMarketplaceAvailable && (
        <NavbarContent>
          <NavItem to="/market" icon="repeat">
            Secondary market
          </NavItem>
          <NavItem to="/funds/dashboard" icon="chunk">
            Funds dashboard
          </NavItem>
          <NavItem to="/funds/clients" icon="users">
            Clients
          </NavItem>
          <NavItem to="/funds/funds" icon="building">
            Funds
          </NavItem>
          <NavItem to="/funds/capital-calls" icon="phone">
            Capital calls
          </NavItem>
        </NavbarContent>
      )}
      <NavbarContent>
        {/* // TODO: 🎨 UI Configurability tech debt: https://linear.app/hedgehog-invest/issue/HOG-2898/%F0%9F%92%A1-get-rid-of-all-the-oakglen-if-statements */}
        {!isOakglen && (
          <NavItem to="/settings" icon="settings">
            Partner settings
          </NavItem>
        )}
        {isPartnerManageTeamMembersEnabled && (
          <NavItem to="/team" icon="users">
            Team
          </NavItem>
        )}
        <NavItem icon="logout" to="/logout">
          Sign out
        </NavItem>
      </NavbarContent>
      <BlockchainStatusBadges />
    </NavbarList>
  );
  return (
    <>
      <Topbar items={links} />
      <Sidebar items={links} />
    </>
  );
};
