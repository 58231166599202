export const EmptyPage = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 323 308"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="162" cy="187" r="109" fill="#C6BEBB" />
    <g filter="url(#filter0_d_112_1102)">
      <rect
        x="24"
        y="34.3174"
        width="161.229"
        height="193.475"
        rx="8"
        transform="rotate(-5.0948 24 34.3174)"
        fill="white"
      />
      <rect
        x="49.0176"
        y="169.676"
        width="39.8109"
        height="43.5319"
        rx="4"
        transform="rotate(-5.0948 49.0176 169.676)"
        fill="#DED9D7"
      />
      <rect
        x="96.6396"
        y="165.43"
        width="39.8109"
        height="43.5319"
        rx="4"
        transform="rotate(-5.0948 96.6396 165.43)"
        fill="#DED9D7"
      />
      <rect
        x="144.262"
        y="161.185"
        width="39.8109"
        height="43.5319"
        rx="4"
        transform="rotate(-5.0948 144.262 161.185)"
        fill="#DED9D7"
      />
      <path
        d="M24.7104 42.2858C24.3181 37.885 27.5676 33.9993 31.9684 33.607L176.624 20.71C181.025 20.3177 184.911 23.5672 185.303 27.968L192.038 103.508L31.4453 117.825L24.7104 42.2858Z"
        fill="#DED9D7"
      />
      <rect
        x="45.4381"
        y="129.527"
        width="74.9717"
        height="6.44918"
        transform="rotate(-5.0948 45.4381 129.527)"
        fill="#C6BEBB"
      />
      <rect
        x="46.4403"
        y="140.769"
        width="57.2365"
        height="3.22459"
        transform="rotate(-5.0948 46.4403 140.769)"
        fill="#C6BEBB"
      />
    </g>
    <g filter="url(#filter1_d_112_1102)">
      <rect
        x="130.616"
        y="52"
        width="170.754"
        height="204.905"
        rx="8"
        transform="rotate(8.87607 130.616 52)"
        fill="white"
      />
      <rect
        x="121.718"
        y="197.511"
        width="42.4779"
        height="46.1037"
        rx="4"
        transform="rotate(8.87607 121.718 197.511)"
        fill="#DED9D7"
      />
      <rect
        x="171.592"
        y="205.3"
        width="42.4779"
        height="46.1037"
        rx="4"
        transform="rotate(8.87607 171.592 205.3)"
        fill="#DED9D7"
      />
      <rect
        x="221.465"
        y="213.088"
        width="42.4779"
        height="46.1037"
        rx="4"
        transform="rotate(8.87607 221.465 213.088)"
        fill="#DED9D7"
      />
      <path
        d="M129.382 59.9042C130.064 55.5388 134.155 52.5527 138.521 53.2344L291.422 77.1126C295.787 77.7944 298.773 81.8859 298.092 86.2512L285.625 166.076L116.916 139.729L129.382 59.9042Z"
        fill="#DED9D7"
      />
      <rect
        x="128.305"
        y="155.333"
        width="79.4008"
        height="6.83017"
        transform="rotate(8.87607 128.305 155.333)"
        fill="#C6BEBB"
      />
      <rect
        x="126.461"
        y="167.143"
        width="60.6178"
        height="3.41509"
        transform="rotate(8.87607 126.461 167.143)"
        fill="#C6BEBB"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_112_1102"
        x="0.678345"
        y="0.677734"
        width="224.417"
        height="253.672"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.192157 0 0 0 0 0.12549 0 0 0 0 0.101961 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_112_1102"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_112_1102"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_112_1102"
        x="76.1375"
        y="33.1377"
        width="246.051"
        height="274.523"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="12" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.192157 0 0 0 0 0.12549 0 0 0 0 0.101961 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_112_1102"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_112_1102"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default EmptyPage;
