import styled from 'styled-components';

import { Paragraph } from '@hedgehog/ui/typography';

export const StatusText = styled(Paragraph)`
  display: inline-flex;
  align-items: center;
  font-weight: bold;

  & > * {
    margin-left: 0.5rem;
  }
`;

export default StatusText;
