import { gql } from '@apollo/client';

export const GET_ASSET_WAITLIST = gql`
  query GetAssetWaitlist($slug: String!) {
    assetWaitlist(slug: $slug) {
      id
      createdAt
    }
  }
`;
