import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import { useAppData } from '@hedgehog/data-access/partners';
import { Input } from '@hedgehog/ui/inputs';
import { VSpace } from '@hedgehog/ui/layouts';
import { Loader } from '@hedgehog/ui/loaders';
import { Paragraph, Heading } from '@hedgehog/ui/typography';

import { Table, TableHeader } from '../../components';

import { AddFundClientRow } from './add-fund-client-row.container';
import { ImportLimitedPartnerRow, transformFile } from './csv-parser.helpers';

const ErrorMessage = styled.div`
  ${({ theme }) => ({
    color: theme.colors.error,
  })}
`;

type AddFundClientsFormProps = {
  rows: ImportLimitedPartnerRow[];
  onChange: (rows: ImportLimitedPartnerRow[]) => void;
};

export const AddFundClientsForm = ({
  rows,
  onChange,
}: AddFundClientsFormProps): JSX.Element | null => {
  const { activePartnerId } = useAppData();
  const [error, setError] = useState<Error>();

  if (!activePartnerId) {
    return <Loader />;
  }

  const handleFileChange = async (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const target = event.target as HTMLInputElement;

    if (target.files?.length) {
      const [file] = target.files;
      file
        .text()
        .then(transformFile)
        .then((data: ImportLimitedPartnerRow[]) => {
          onChange(data);
          setError(undefined);
        })
        .catch((err: Error) => {
          onChange([]);
          setError(err);
        });
    }
  };

  return (
    <VSpace spacing="tiny">
      {rows.length === 0 && (
        <Input type="file" name="file" onChange={handleFileChange} />
      )}
      {error && (
        <ErrorMessage>
          <Heading level="h6">There are issues with that file</Heading>
          {error.message}
        </ErrorMessage>
      )}
      {rows && rows.length > 0 && (
        <Table>
          <thead>
            <tr>
              <TableHeader>
                <Paragraph small>Email</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Commitment</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Name</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Surname</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Country</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Type</Paragraph>
              </TableHeader>
              <TableHeader>
                <Paragraph small>Fund ID</Paragraph>
              </TableHeader>
              <TableHeader></TableHeader>
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <AddFundClientRow key={row.id} row={row} />
            ))}
          </tbody>
        </Table>
      )}
    </VSpace>
  );
};
