import { useState } from 'react';

import { useFundManagerUserOperation } from './use-fund-manager-contract.hook';

type CallbackParams = {
  fundRef: string;
  capitalCallNoticeId: number;
};

type Callback = (params: CallbackParams) => Promise<void>;

type Result = {
  loading: boolean;
};

export const useSettleCapitalCallNoticeUserOperation = (): [
  Callback,
  Result,
] => {
  const userOperation = useFundManagerUserOperation({
    functionName: 'settleCapitalCallNotice(bytes32,uint32)',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const callback = async ({
    fundRef,
    capitalCallNoticeId,
  }: CallbackParams): Promise<void> => {
    try {
      setLoading(true);
      return await userOperation([fundRef, capitalCallNoticeId]);
    } finally {
      setLoading(false);
    }
  };

  return [callback, { loading }];
};
