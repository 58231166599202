import { createContext } from 'react';

import { FundV2 } from '@hedgehog/data-access/partners-types';

import { MarketListingCreateLocationState } from '../../pages/market/types';

export type ClientWalletParams = { index: bigint; address: string };

export type FundContractParams = { fund?: FundV2; address: string | null };

export type MarketListingParams = {
  clientId?: string;
  clientWalletAddress?: string;
  clientWalletIndex?: bigint;
  fundId?: string;
  fund?: FundV2;
  fundContractAddress?: string | null;
  positionRatio: number;
  positionPrice: number;
};

export type MarketListingBalance = {
  commitment: number;
  contribution: number;
};

export type MarketListingContextType = {
  balance: MarketListingBalance;
  params: MarketListingParams;
  clientsLoading: boolean;
  fundsLoading: boolean;
  close(): void;
  navigateTo(path: string, state?: MarketListingCreateLocationState): void;
  updateBalance(balance: Partial<MarketListingBalance>): void;
  updateParams(params: Partial<MarketListingParams>): void;
  updateParamsWithClientWallet(params: ClientWalletParams): void;
  updateParamsWithFundAddress(params: FundContractParams): void;
  updateParamsWithSearchParams(params: URLSearchParams): void;
  updateParamsWithPathParams(
    params: Pick<MarketListingParams, 'clientId' | 'fundId'>,
  ): void;
};

export default createContext<MarketListingContextType>({
  params: {
    positionRatio: 1,
    positionPrice: 0,
  },
  balance: {
    commitment: 0,
    contribution: 0,
  },
  clientsLoading: false,
  fundsLoading: false,
  close: () => void 0,
  navigateTo: () => void 0,
  updateBalance: () => void 0,
  updateParams: () => void 0,
  updateParamsWithClientWallet: () => void 0,
  updateParamsWithFundAddress: () => void 0,
  updateParamsWithPathParams: () => void 0,
  updateParamsWithSearchParams: () => void 0,
});
