import styled from 'styled-components';

const Circle = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: white;
  transition: left 200ms ease-in-out;
`;

const Input = styled.div<{ active: boolean }>`
  align-items: ${({ active }): string => (active ? 'flex-end' : 'flex-start')};
  width: 3rem;
  height: 1.5rem;
  border-radius: 2.5rem;
  background-color: ${({ active, theme }): string =>
    active ? theme.colors.success : theme.colors.grey100};
  border: 0.25rem solid transparent;
  cursor: pointer;
  position: relative;
  transition: background-color, border-color;
  transition-duration: 100ms;
  transition-timing-function: ease-in-out;

  &:hover {
    border-color: ${({ theme }): string => theme.colors.shadow100};
  }

  ${Circle} {
    left: ${({ active }): string => (active ? '1.5rem' : '0')};
  }
`;

export type ToggleInputProps = {
  value?: boolean;
  onChange: (value: boolean) => void;
  className?: string;
};

export const ToggleInput = ({
  value,
  onChange,
  className,
}: ToggleInputProps): JSX.Element => {
  return (
    <Input
      active={Boolean(value)}
      className={className}
      onClick={(): void => onChange(!value)}
    >
      <Circle />
    </Input>
  );
};
