import { ReactNode } from 'react';
import styled from 'styled-components';

import { Heading, Paragraph } from '@hedgehog/ui/typography';

const Container = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  align-items: center;
  max-width: 20.625rem;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  padding-bottom: 1.5rem;
`;

const Image = styled.img`
  max-width: 20rem;
  margin-bottom: 0.5rem;
`;

interface FormConfirmProps {
  heading: string | ReactNode;
  subheading: string | ReactNode;
  note?: string | ReactNode;
  imageSrc?: string;
}

export const FormConfirm = ({
  heading,
  subheading,
  note,
  imageSrc,
}: FormConfirmProps): JSX.Element => (
  <Container>
    {imageSrc ? <Image src={imageSrc} /> : null}
    <Wrapper>
      {typeof heading === 'string' ? (
        <Heading level="h4">{heading}</Heading>
      ) : (
        heading
      )}
      {typeof subheading === 'string' ? (
        <Paragraph>{subheading}</Paragraph>
      ) : (
        subheading
      )}
      {typeof note === 'string' ? <Paragraph>{note}</Paragraph> : note}
    </Wrapper>
  </Container>
);
