import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { PartnerAuthPage } from '@hedgehog/browser/partners/shared/layouts';
import {
  AuthFormCardContainer,
  CognitoSocialLoginButton,
  FormLineBreak,
  SignInForm,
  SignInFormData,
} from '@hedgehog/browser/shared/auth';
import { useAuth } from '@hedgehog/data-access/contexts';
import { login, logout, useDispatch } from '@hedgehog/data-access/partners';
import { Heading } from '@hedgehog/ui/typography';

import { environment } from '../../../environments/environment';

const FormMethods = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

const FormCardNote = styled(AuthFormCardContainer)`
  margin-bottom: 1.5rem;
`;

export const SignInPage = (): JSX.Element => {
  const { signin } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [error, setError] = useState<Error>();

  const handleSubmit = async (
    formData: Required<SignInFormData>,
  ): Promise<void> => {
    try {
      const { accessToken } = await signin({
        email: formData.email,
        password: formData.password,
      });

      if (accessToken) {
        dispatch(
          login({
            authToken: accessToken,
          }),
        );
      } else {
        navigate('/logout');
      }
    } catch (err) {
      setError(err as Error);
    }
  };

  return (
    <PartnerAuthPage>
      <Heading level="h4">Sign in to Hedgehog</Heading>
      {searchParams.get('source') === 'forgot-password' && (
        <FormCardNote
          outlined
          icon="check-circle"
          title="Password changed"
          description="You can now sign in below"
          iconColour="success"
        />
      )}
      <SignInForm
        defaultEmail={searchParams.get('email')}
        onSubmit={handleSubmit}
        error={error?.message}
        forgotPasswordPath="/auth/forgot-password"
      />
      <FormLineBreak>or</FormLineBreak>
      <FormMethods>
        <CognitoSocialLoginButton
          text="Sign in with Google"
          icon="google"
          loginProvider="Google"
          cognitoClientId={environment.cognito.clientId || ''}
          cognitoUrl={environment.cognito.userPoolUrl || ''}
          redirectUrl={`${environment.baseUrl}/auth/sign-in/callback`}
        />
      </FormMethods>
    </PartnerAuthPage>
  );
};

export default SignInPage;
