import styled, { CSSProp } from 'styled-components';

type IndexBarProps = {
  index: number;
  length: number;
};

const IndexBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IndexBarElement = styled.div<{ active: boolean }>`
  width: 100%;
  height: 4px;

  border-radius: 100px;
  margin: 0px 8px;

  ${({ theme }): CSSProp => ({
    backgroundColor: theme.colors.white,
  })}
  ${({ active }): string => (active ? 'opacity: 1;' : 'opacity: 0.4;')}
`;

export const IndexBar = ({ index, length }: IndexBarProps): JSX.Element => {
  let barIndex = index;
  if (index < 0) barIndex = 0;
  if (index >= length) barIndex = length - 1;

  return (
    <IndexBarContainer>
      {Array(length)
        .fill(undefined)
        .map((e, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <IndexBarElement key={`barElement${i}`} active={i === barIndex} />
        ))}
    </IndexBarContainer>
  );
};
