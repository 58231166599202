export const AsteriskIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 2H14V11.1716L7.51473 4.6863L4.68631 7.51473L11.1716 14H2V18H11.1715L4.68622 24.4853L7.51465 27.3137L14 20.8284V30H18V20.8284L24.4853 27.3137L27.3137 24.4853L20.8284 18H30V14H20.8284L27.3136 7.51473L24.4852 4.6863L18 11.1715V2Z"
      fill="currentColor"
    />
  </svg>
);
