import styled, { CSSObject } from 'styled-components';

import { Button } from '@hedgehog/ui/buttons';
import { Avatar } from '@hedgehog/ui/icons';
import { Paragraph } from '@hedgehog/ui/typography';

const WarningButton = styled(Button)`
  ${({ theme }): CSSObject => ({
    color: theme.colors.white,
    backgroundColor: theme.colors.error,
    borderColor: theme.colors.error,
  })}
`;

export const Deactivate = ({
  firstName,
  disable,
  loading,
  cancel,
}: {
  firstName: string;
  disable: () => void;
  loading: boolean;
  cancel: () => void;
}): JSX.Element => (
  <>
    <Avatar
      size="l"
      icon="alert-circle"
      color="error"
      backgroundColor="errorLight"
    />
    <Paragraph>
      {firstName} will be logged out and no longer have access to the portal.
    </Paragraph>
    <Paragraph>
      Clients added by the member are not affected and are still accessible by
      an admin user.
    </Paragraph>
    <Paragraph>You can reactivate this team member at any time.</Paragraph>
    <WarningButton fluid onClick={disable} loading={loading}>
      Deactivate member
    </WarningButton>
    <Button fluid onClick={cancel}>
      Cancel
    </Button>
  </>
);
