import { ethers } from 'ethers';

export const parseNumber = (input: string): number => {
  return parseInt(input, 10);
};

export const weiToUsd = (wei: string): number => {
  // convert to ether, we're using wei to avoid rounding errors, so 1 USD = 10^18 wei (1 ether)
  const ether = ethers.utils.formatEther(wei);
  // get value in USD as a number
  return parseFloat(ether);
};
