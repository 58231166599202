import { createContext } from 'react';

export type ClientWalletParams = { index: bigint; address: string };

export type FundContractParams = { address: string | null };

export type MarketBidParams = {
  origin: 'explore' | 'market' | 'client';
  bidId?: number;
  clientId?: string;
  clientWalletAddress?: string;
  clientWalletIndex?: bigint;
  fundId?: string;
  fundContractAddress?: string | null;
  listingId?: string;
  bidPrice?: number;
};

export type MarketBidBalance = {
  commitment: number;
  contribution: number;
};

export type MarketBidContextType = {
  balance: MarketBidBalance;
  params: MarketBidParams;
  clientsLoading: boolean;
  fundsLoading: boolean;
  close(): void;
  navigateTo(path: string): void;
  updateBalance(balance: Partial<MarketBidBalance>): void;
  updateParams(params: Partial<MarketBidParams>): void;
  updateParamsWithClientWallet(params: ClientWalletParams): void;
  updateParamsWithFundAddress(params: FundContractParams): void;
  updateParamsWithSearchParams(params: URLSearchParams): void;
  updateParamsWithPathParams(
    params: Pick<MarketBidParams, 'clientId' | 'fundId'>,
  ): void;
};

export default createContext<MarketBidContextType>({
  params: {
    origin: 'explore',
  },
  balance: {
    commitment: 0,
    contribution: 0,
  },
  clientsLoading: false,
  fundsLoading: false,
  close: () => void 0,
  navigateTo: () => void 0,
  updateBalance: () => void 0,
  updateParams: () => void 0,
  updateParamsWithClientWallet: () => void 0,
  updateParamsWithFundAddress: () => void 0,
  updateParamsWithPathParams: () => void 0,
  updateParamsWithSearchParams: () => void 0,
});
