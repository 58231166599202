export const BellIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 0C4.23858 0 2 2.23858 2 5V7L0 9V11H14V9L12 7V5C12 2.23858 9.76142 0 7 0Z"
      fill="currentColor"
    />
    <path
      d="M4 13C4 14.6569 5.34315 16 7 16C8.65685 16 10 14.6569 10 13H4Z"
      fill="currentColor"
    />
  </svg>
);
