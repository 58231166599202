import { gql } from '@apollo/client';

/**
 * @deprecated
 * Use `GET_ONBOARDING_STATUS` instead.
 */
export const GET_KYC_STATUS = gql`
  query GetKYCStatus($assetId: ID) {
    onboardingProgress(input: { assetId: $assetId }) {
      canInvest
      status {
        identityCheck
        verification
        taxForm
        accreditation
        assessmentCheck
        declaration
      }
    }
  }
`;
