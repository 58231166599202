import { ReactNode } from 'react';
import styled from 'styled-components';

import { Avatar, AvatarProps } from '@hedgehog/ui/icons';

import { ListItem } from '../ListItem/ListItem';

const Wrapper = styled.div`
  display: flex;
`;

const Content = styled.div`
  flex: 1;
`;

const IconListItem = styled.div`
  ${({ theme }) => ({ color: theme.colors.white })}
  margin-right: 1rem;
  margin-top: 0.375rem;
`;

export type BulletListItemProps = {
  icon: AvatarProps['icon'];
  iconSize?: AvatarProps['size'];
  iconColor?: AvatarProps['color'];
  iconBackgroundColor?: AvatarProps['backgroundColor'];
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
};

export const BulletListItem = ({
  icon,
  iconSize = 's',
  iconColor,
  iconBackgroundColor,
  className,
  children,
  onClick,
}: BulletListItemProps): JSX.Element => {
  return (
    <ListItem className={className} onClick={onClick}>
      <Wrapper>
        <IconListItem>
          <Avatar
            icon={icon}
            size={iconSize}
            color={iconColor}
            backgroundColor={iconBackgroundColor}
          />
        </IconListItem>
        <Content>{children}</Content>
      </Wrapper>
    </ListItem>
  );
};

export default BulletListItem;
