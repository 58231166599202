import * as qs from 'query-string';
import styled, { CSSObject } from 'styled-components';

import { LinkButton, styles } from '@hedgehog/ui/buttons';
import { IconType } from '@hedgehog/ui/icons';

type LoginProviders = 'SignInWithApple' | 'Google';

type CognitoSocialLoginButtonProps = {
  loading?: boolean;
  text?: string;
  className?: string;
  onClick?: () => void;
  loginProvider: LoginProviders;
  icon: IconType;
  cognitoUrl: string;
  cognitoClientId: string;
  redirectUrl: string;
  scopes?: string[];
  state?: object;
  fluid?: boolean;
  small?: boolean;
};

const SocialLoginButton = styled(LinkButton)<{
  loginProvider: LoginProviders;
  $hoverColor?: string;
}>`
  ${({ loginProvider, theme: { colors, typography, spacing } }): CSSObject => ({
    fontSize: typography.button_small.fontSize,
    gap: spacing.xxxsmall,
    backgroundColor:
      loginProvider === 'SignInWithApple' ? colors.black : colors.grey100,
    color: loginProvider === 'SignInWithApple' ? colors.white : colors.black,
  })};

  &:not(:disabled):focus,
  &:not(:disabled):hover {
    ${styles.alternativeHoverAppearance}
  }
`;

export const CognitoSocialLoginButton = ({
  text,
  loginProvider,
  cognitoUrl,
  cognitoClientId,
  redirectUrl,
  scopes = ['email', 'openid', 'profile'],
  state,
  ...props
}: CognitoSocialLoginButtonProps): JSX.Element | null => {
  const queryParams = qs.stringify({
    identity_provider: loginProvider,
    redirect_uri: redirectUrl,
    response_type: 'CODE',
    client_id: cognitoClientId,
    scope: scopes.join(' '),
    state: state ? btoa(JSON.stringify(state)) : undefined,
  });
  return (
    <SocialLoginButton
      iconColor="white"
      leadingSize="m"
      $hoverColor={loginProvider === 'SignInWithApple' ? 'light200' : undefined}
      loginProvider={loginProvider}
      href={`${cognitoUrl}/oauth2/authorize?${queryParams}`}
      {...props}
    >
      {text}
    </SocialLoginButton>
  );
};
