import { UUID } from '@hedgehog/shared/types';

import { baseApi } from './base.api';

export const clientWalletsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateClientWallet: builder.mutation<
      { id: string; address: string },
      { partnerId: UUID; clientId: UUID; address: string }
    >({
      query: ({ partnerId, clientId, ...body }) => ({
        url: `partners/${partnerId}/clients/${clientId}/wallet`,
        method: 'POST',
        body,
      }),
      transformResponse: (response: {
        data: { id: string; address: string };
      }) => response.data,
    }),
  }),
});

export const { useUpdateClientWalletMutation } = clientWalletsApi;
