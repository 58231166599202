import styled, { CSSObject, CSSProp } from 'styled-components';

import { ThemeColorName } from '@hedgehog/ui/themes';
import { clamp } from '@hedgehog/utils/numbers';

const Bar = styled.div<ProgressBarProps>`
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.375rem;
    border-radius: 1.5rem;
  }

  &:before {
    ${({ theme }): CSSProp => ({
      backgroundColor: theme.colors.shadow100,
    })}
  }

  &:after {
    ${({ color, value, theme }): CSSObject => ({
      backgroundColor: color
        ? theme.colors[color] || color
        : theme.colors.secondary,
      width: value ? `${Math.round(clamp(0, 1)(value) * 100)}%` : '0%',
    })};
  }
`;
type ProgressBarProps = {
  value: number; // Number between 0 and 1, where 1 is 100%.
  color?: ThemeColorName | string;
  className?: string;
};

export const ProgressBar = ({
  value,
  color,
  className,
}: ProgressBarProps): JSX.Element => {
  // Bound the value to a max value of 1.
  if (value > 1) {
    value = 1;
  }
  return <Bar className={className} color={color} value={value} />;
};
