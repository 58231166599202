import { ReactNode } from 'react';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { Card, CardContent } from '@hedgehog/ui/cards';
import { ProgressBar } from '@hedgehog/ui/charts';
import { HeaderWithMany, HeaderButton, Header } from '@hedgehog/ui/headers';
import { Loader } from '@hedgehog/ui/loaders';
import { parseBorder, parsePadding } from '@hedgehog/ui/themes';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { StandardProps } from '@hedgehog/ui/utils';

export const WidgetHeaderWithMany = styled(HeaderWithMany)`
  background-color: transparent;
  padding: 0;
`;

export const WidgetHeader = styled(Header)`
  background-color: transparent;
  padding: 0;
`;

export type WidgetSizeProps = Partial<
  | ({ small?: boolean } & Record<'medium' | 'large', never>)
  | ({ medium?: boolean } & Record<'small' | 'large', never>)
  | ({ large?: boolean } & Record<'small' | 'medium', never>)
>;

export type WidgetProps = StandardProps<
  {
    many?: ReactNode | ReactNode[];
    actions?: ReactNode | ReactNode[];
    title?: string | ReactNode | ReactNode[];
    loading?: boolean;
  } & WidgetSizeProps
>;

export const WidgetContent = styled.div``;

const DashboardWidgetTile = styled(Card)`
  ${({ theme }): CSSObject => ({
    backgroundColor: theme.colors.white,
    padding: parsePadding(theme.widgets.padding),
    cursor: 'default',
    '&:hover': {
      cursor: 'pointer',
    },
    [`& > ${CardContent}`]: {
      justifyContent: 'space-between',
    },
    '@media screen and (max-width: 1500px)': {
      height: '150px',
    },
  })}
`;

const WidgetTile = styled(Card)<WidgetProps>`
  display: flex;
  max-height: 100%;
  overflow-y: auto;

  ${({ small, medium, large, theme }): CSSObject => ({
    backgroundColor: theme.colors.white,
    padding: 0,
    cursor: 'default',

    [`& > ${CardContent}`]: {
      padding: parsePadding(theme.widgets.padding),
      gap: theme.spacing.normal,
    },

    '&:hover, &:focus': {
      boxShadow: 'none',
    },

    gridColumn: `span ${small ? 4 : medium ? 8 : large ? 12 : 12}}`,
    [`@media screen and (max-width: ${theme.breakpoints.normal}px)`]: {
      gridColumn: `span ${small ? 6 : medium ? 12 : large ? 12 : 12}}`,
    },
    [`@media screen and (max-width: ${theme.breakpoints.normal}px)`]: {
      gridColumn: 'span 12',
    },
  })}
`;

export const WidgetInnerWrapper = styled.div`
  max-height: 100%;
  overflow: hidden;
  overflow-y: auto;
`;

const MetricHeadings = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  gap: ${({ theme }): string => theme.spacing.xsmall};
  margin-bottom: ${({ theme }): string => theme.spacing.xsmall};
`;

const StyledLoader = styled(Loader)`
  ${({ theme }): CSSObject => ({
    margin: `${theme.spacing.small} auto`,
  })}
`;

export const DashboardWidget = ({
  title,
  metric,
  progress,
  showProgress,
  loading,
  onClick,
}: {
  title: string;
  metric: number;
  progress?: number;
  showProgress?: boolean;
  loading: boolean;
  onClick?: () => void;
}): JSX.Element => {
  return (
    <DashboardWidgetTile space="lg" onClick={onClick}>
      <Paragraph>{title}</Paragraph>
      {loading ? (
        <StyledLoader />
      ) : (
        <div>
          <MetricHeadings>
            <Heading level="h3">{metric}</Heading>
            {(showProgress && progress !== undefined && (
              <Heading level="h6" color="grey400">
                {Math.round(progress * 100)}%
              </Heading>
            )) ||
              ''}
          </MetricHeadings>
          <ProgressBar value={progress || 0} color={'black'} />
        </div>
      )}
    </DashboardWidgetTile>
  );
};

export const Widget = styled(
  ({
    actions,
    title,
    many,
    loading = false,
    className,
    children,
    ...tileProps
  }: WidgetProps): JSX.Element => {
    return (
      <WidgetTile className={className} {...tileProps}>
        {(title || actions || many) && (
          <WidgetHeaderWithMany
            as={many ? WidgetHeaderWithMany : WidgetHeader}
            left={
              typeof title === 'string' ? (
                <Heading level="h6" loading={loading}>
                  {title}
                </Heading>
              ) : (
                title
              )
            }
            right={actions}
          />
        )}
        {children && (
          <WidgetInnerWrapper>
            <WidgetContent>{children}</WidgetContent>
          </WidgetInnerWrapper>
        )}
      </WidgetTile>
    );
  },
)`
  width: 100%;
  height: 100%;
`;

export const OutlineWidget = styled(Widget)`
  ${({ theme }): CSSObject => ({
    background: 'transparent',
    border: parseBorder(theme.border.normal),
  })}

  ${WidgetHeaderWithMany} > ${HeaderButton} {
    background-color: ${({ theme }): string => theme.colors.white};
  }
`;
