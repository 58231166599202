import { RefObject, useEffect } from 'react';

export type UseFocusRedirectParams = Record<
  'source' | 'target',
  RefObject<HTMLElement>
>;

/**
 * @description
 * Redirects user focus from source element to target element.
 */
export const useFocusRedirect = ({
  source,
  target,
}: UseFocusRedirectParams): void => {
  const handleFocus = (event: FocusEvent): void => {
    if (target.current) {
      target.current.focus();
    }
  };

  const attach = () => {
    const { current } = source;
    if (!current) return;

    current.addEventListener('focus', handleFocus);
  };

  const detach = () => {
    const { current } = source;
    if (!current) return;

    current.removeEventListener('focus', handleFocus);
  };

  useEffect(() => {
    attach();
    return detach;
  }, [target]);

  return;
};
