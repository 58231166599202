import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PageColumnLayout } from '@hedgehog/browser/partners/shared/layouts';
import {
  useCapitalCallsBlockchainQuery,
  useCurrentFunds,
} from '@hedgehog/data-access/partners';
import { PrimaryLinkButton } from '@hedgehog/ui/buttons';
import { ProgressBar } from '@hedgehog/ui/charts';
import { InputGroup } from '@hedgehog/ui/inputs';
import { Heading, Label, Paragraph } from '@hedgehog/ui/typography';
import { Widget, WidgetGrid } from '@hedgehog/ui/widgets';
import { currencies, weiToUsd } from '@hedgehog/utils/formats';

import { CapitalCallsTable, CapitalCallParams } from '../../../components';

const FieldGroups = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

const ProgressData = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: space-between;
`;

export const CapitalCallsPage = () => {
  const { data: capitalCalls, loading: loadingCapitalCalls } =
    useCapitalCallsBlockchainQuery();

  const { funds } = useCurrentFunds();

  const [liveCalls, setLiveCalls] = useState<CapitalCallParams[]>([]);
  const [closedCalls, setClosedCalls] = useState<CapitalCallParams[]>([]);
  const [totalSettled, setTotalSettled] = useState<ethers.BigNumber>(
    ethers.BigNumber.from(0),
  );
  const [totalCommitted, setTotalCommitted] = useState<ethers.BigNumber>(
    ethers.BigNumber.from(0),
  );

  useEffect(() => {
    if (capitalCalls?.length && funds?.length) {
      setLiveCalls(
        capitalCalls
          .filter(
            (call) =>
              weiToUsd(call.amount.toString()) >
              weiToUsd(call.settled.toString()),
          )
          .map((call) => ({
            id: call.id as number,
            fund:
              funds.find((fund) => fund.blockchainId === call.fundId)?.name ||
              '',
            committed: call.amount,
            settled: call.settled,
          })),
      );

      setClosedCalls(
        capitalCalls
          .filter(
            (call) =>
              weiToUsd(call.amount.toString()) ===
              weiToUsd(call.settled.toString()),
          )
          .map((call) => ({
            id: call.id as number,
            fund:
              funds.find((fund) => fund.blockchainId === call.fundId)?.name ||
              '',
            committed: call.amount,
            settled: call.settled,
          })),
      );
    }
  }, [capitalCalls, funds]);

  useEffect(() => {
    if (liveCalls.length) {
      setTotalCommitted(
        liveCalls.reduce<ethers.BigNumber>(
          (sum, item) => sum.add(item.committed),
          ethers.BigNumber.from(0),
        ),
      );
      setTotalSettled(
        liveCalls.reduce<ethers.BigNumber>(
          (sum, item) => sum.add(item.settled),
          ethers.BigNumber.from(0),
        ),
      );
    }
  }, [liveCalls, closedCalls]);

  const loading = funds.length === 0 || loadingCapitalCalls;
  return (
    <PageColumnLayout
      title="Capital calls"
      actions={
        <PrimaryLinkButton to="/funds/capital-calls/create">
          New capital call
        </PrimaryLinkButton>
      }
    >
      <WidgetGrid>
        <Widget large>
          <FieldGroups>
            <InputGroup>
              <Label color="grey400">Capital calls</Label>
              <Paragraph loading={loading}>
                {capitalCalls ? capitalCalls.length : 0}
              </Paragraph>
            </InputGroup>
            <InputGroup>
              <Label color="grey400">Capital outstanding</Label>
              <Paragraph loading={loading}>
                {currencies.formatMoney(
                  weiToUsd(totalCommitted.sub(totalSettled).toString()),
                  {
                    minimumFractionDigits: 2,
                  },
                )}
              </Paragraph>
            </InputGroup>
          </FieldGroups>
          <ProgressContainer>
            <ProgressData>
              <InputGroup>
                <Label color="grey400">Total paid</Label>
                <Heading level="h3" color="success" loading={loading}>
                  {currencies.formatMoney(weiToUsd(totalSettled.toString()), {
                    minimumFractionDigits: 2,
                  })}
                </Heading>
              </InputGroup>
              <InputGroup>
                <Label color="grey400">Total called</Label>
                <Heading level="h3" loading={loading}>
                  {currencies.formatMoney(weiToUsd(totalCommitted.toString()), {
                    minimumFractionDigits: 2,
                  })}
                </Heading>
              </InputGroup>
            </ProgressData>
            <ProgressBar
              value={
                weiToUsd(totalSettled.toString()) /
                weiToUsd(totalCommitted.toString())
              }
              color="success"
            />
          </ProgressContainer>
        </Widget>
        <Widget large title="Live calls">
          <CapitalCallsTable calls={liveCalls} loading={loading} />
        </Widget>
        <Widget large title="Closed calls">
          <CapitalCallsTable calls={closedCalls} loading={loading} />
        </Widget>
      </WidgetGrid>
    </PageColumnLayout>
  );
};
