export const MenuIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Chunk/Menu">
      <g id="Icon">
        <path d="M2 10H30V14H2V10Z" fill="currentColor" />
        <path d="M2 18H30V22H2V18Z" fill="currentColor" />
        <path d="M2 26H30V30H2V26Z" fill="currentColor" />
        <path d="M2 2H30V6H2V2Z" fill="currentColor" />
      </g>
    </g>
  </svg>
);
