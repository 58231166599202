import jwtDecode from 'jwt-decode';
import { Suspense, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled, { css, CSSObject } from 'styled-components';

import { useAnalyticsTrack } from '@hedgehog/browser/investors/shared/analytics';
import { useAuth } from '@hedgehog/data-access/contexts';
import { OutlineLightLinkButton } from '@hedgehog/ui/buttons';
import { useEnvironment } from '@hedgehog/ui/environment';
import { Loader } from '@hedgehog/ui/loaders';

import { CognitoSocialLoginButton, GoogleLoginButton } from '../../components';
import { usePartnerSetting } from '../../hooks';
import { usePartner } from '../../providers';
import { AnchorLoginLink } from '../landing-page/styles';

const OutlineLoginStyle = css`
  border-width: 2px;
  ${({ theme }): CSSObject => ({
    borderColor: theme.colors.grey200,
    backgroundColor: theme.colors.white,
    color: theme.colors.black,
  })};
`;

const CustomAppleLoginButton = styled(CognitoSocialLoginButton)`
  width: 100%;
`;

const CustomGoogleLoginButton = styled(GoogleLoginButton)`
  ${OutlineLoginStyle}
`;

const SignUpButton = styled(OutlineLightLinkButton)`
  ${({ theme: { typography } }): CSSObject => ({
    fontSize: typography.button_small.fontSize,
  })};
`;

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
`;

export function PartnerLandingPage(): JSX.Element {
  const {
    cognito: { userPoolUrl, clientId },
    baseUrl,
  } = useEnvironment();
  const track = useAnalyticsTrack();
  const { partner, loading: partnerLoading } = usePartner();
  const [searchParams] = useSearchParams();
  const [code, setCode] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { exchangeGoogleSession, signup } = useAuth();
  const navigate = useNavigate();
  const { enabled: isAppleSSOEnabled = true, loading: isAppleSSOLoading } =
    usePartnerSetting('apple_sso');

  useEffect(() => {
    const code = searchParams.get('deep_link_sub1') || searchParams.get('code');
    const email =
      searchParams.get('deep_link_sub2') || searchParams.get('email');
    setCode(code || undefined);
    setEmail(email || undefined);
  }, [searchParams]);

  useEffect(() => {
    // add the referral code to local storage so that the signup is attributed to the parter when the user
    // completes the signup.
    if (partner?.referralCode) {
      localStorage.setItem('referredByPartner', partner.referralCode);
    }
  }, [partner?.referralCode]);
  useEffect(() => {
    localStorage.setItem('used_referral_link', window.location.href);
  }, []);

  const generatePassword = ({ length = 32 }: { length: number }): string => {
    const charset =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%^&*()-_=+';
    let password = '';
    const randomValues = new Uint8Array(length);
    crypto.getRandomValues(randomValues);
    for (let i = 0; i < length; i++) {
      password += charset[randomValues[i] % charset.length];
    }
    return password;
  };

  const createCognitoSession = async (idToken: string): Promise<void> => {
    if (!idToken) return;

    let googleEmail;
    try {
      const { email } = jwtDecode(idToken) as { email: string };
      googleEmail = email;
    } catch {
      setError('Something went wrong, please try again later.');
      return;
    }

    if (googleEmail !== email) {
      setError(
        'Please signup with the email address that received the invitation.',
      );
      return;
    }

    if (!exchangeGoogleSession || !email) return;

    setLoading(true);

    // We first need to create a new Cognito user because our CUSTOM_AUTH flow relies on there being an existing user
    // with the same email address. Then we exchange the Google ID token for a Cognito session. Cognito will check
    // that the Google ID token is valid and ensure that the email associated with the Google account matches the email
    // the user is trying to sign up with.
    try {
      await signup({
        email,
        password: generatePassword({ length: 32 }),
      });
    } catch (e) {
      console.log(e);
    }

    // We need to perform the cognito CUSTOM_AUTH flow twice due to the way it works. The first time we call it, the user
    // will be in an `unconfirmed` state, this is a security feature from Cognito to prevent users from being able to signup
    // with emails they don't own. However, the lambda function that Cognito uses to validate a Google ID token will mark the
    // user as `confirmed` if the ID token is valid (the Google ID token prooves the user owns the email address), so the
    // second time we call it, the user will be in a `confirmed` state and we will be able successfully exchange the Google ID
    // token for a Cognito session.
    //
    // This will also ensure that users can only signin with the Google account that has an email address that matches the one
    // used by the partner when adding the user - the exchangeGoogleSession function will throw an error if the email address
    // does not match.
    try {
      await exchangeGoogleSession({
        email,
        googleIdToken: idToken,
      });

      navigate(`signup/confirm?code=${code}`);
    } catch (e) {
      try {
        await exchangeGoogleSession({
          email,
          googleIdToken: idToken,
        });

        navigate(`signup/confirm?code=${code}`);
      } catch (e) {
        if (e instanceof Error && e.name === 'NotAuthorizedException') {
          setError(
            'Please signup with the email address that received the invitation.',
          );
        } else {
          setError('Something went wrong, please try again later.');
        }
      } finally {
        setLoading(false);
      }
    }
  };

  if (partnerLoading || loading) return <Loader />;

  return (
    <Suspense fallback={<Loader />}>
      {error && <p>{error}</p>}
      <Wrapper>
        {isAppleSSOEnabled && (
          <CustomAppleLoginButton
            loading={isAppleSSOLoading}
            text="Sign in with Apple"
            icon="apple"
            onClick={(): void => track('LoginWithAppleButton', 'Clicked')}
            loginProvider="SignInWithApple"
            cognitoClientId={clientId || ''}
            cognitoUrl={userPoolUrl || ''}
            redirectUrl={`${baseUrl}/auth/signin/callback`}
          />
        )}
        <CustomGoogleLoginButton
          fluid
          loading={loading}
          icon="google"
          text="Signup With Google"
          hint={email}
          onClick={(): void => track('LoginWithGoogleButton', 'Clicked')}
          state={{ code, email }}
          onSuccess={createCognitoSession}
          onError={setError} // TODO: handle errors
        />
        <SignUpButton
          fluid
          loading={loading}
          to={`/${partner?.slug}/signup?${searchParams}`}
          icon="mail"
          iconColor="black"
        >
          Sign up with email
        </SignUpButton>

        <AnchorLoginLink to="/login" visibleAt="mobile">
          Already have an account?
        </AnchorLoginLink>
      </Wrapper>
    </Suspense>
  );
}

export default PartnerLandingPage;
