import { gql } from '@apollo/client';

export const BEGIN_SIGNUP_USER = gql`
  mutation BeginSignup(
    $firstName: String!
    $lastName: String!
    $email: String!
    $platform: SignUpPlatform
  ) {
    beginSignup(
      args: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        platform: $platform
      }
    )
  }
`;
