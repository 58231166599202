export const TruckIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 0H0V11H2C2 12.1046 2.89543 13 4 13C5.10457 13 6 12.1046 6 11H10C10 12.1046 10.8954 13 12 13C13.1046 13 14 12.1046 14 11H16V6C16 4.34315 14.6569 3 13 3H11V0ZM11 5V7H14V5H11Z"
      fill="currentColor"
    />
  </svg>
);

export default TruckIcon;
