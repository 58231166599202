export const depth = {
  layout: 10,
  footbar: 50,
  sidebar: 60, // must be higher than footbar
  backdrop: 75,
  modal: 100,
  watermark: 999,
};

export default depth;
