import styled, { CSSObject } from 'styled-components';

import { Card } from '../card/card.component';

export const ErrorCard = styled(Card)`
  ${({ theme }): CSSObject => ({
    backgroundColor: theme.colors.errorLight,
    color: theme.colors.error,
  })}
`;
