import { CSSProp, StyledObject } from 'styled-components';

export function fromBreakpointMediaQuery(fromInPixels: number): string {
  return `only screen and (min-width: ${fromInPixels}px)`;
}

export function toBreakpointMediaQuery(toInPixels: number): string {
  return `only screen and (max-width: ${toInPixels - 1}px)`;
}

export function fromBreakpoint<Props extends object = object>(
  fromInPixels: number,
  properties: CSSProp,
): StyledObject<Props> {
  return {
    [`@media ${fromBreakpointMediaQuery(fromInPixels)}`]: properties ?? {},
  } as any;
}

export function toBreakpoint<Props extends object = object>(
  toInPixels: number,
  properties: CSSProp,
): StyledObject<Props> {
  return {
    [`@media ${toBreakpointMediaQuery(toInPixels)}`]: properties ?? {},
  } as any;
}
