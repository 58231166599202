import { Address } from 'viem';

import { UseFundWriteReturn, useFundWrite } from './core';

export interface UseAcceptBidProps {
  fundAddress: Address;
  bidId: number;
  enabled?: boolean;
}

export const useAcceptBid = ({
  fundAddress,
  bidId,
  enabled = true,
}: UseAcceptBidProps): UseFundWriteReturn => {
  return useFundWrite({
    address: fundAddress,
    functionName: 'acceptBid',
    args: [bidId],
    scopeKey: `funds/${fundAddress}/bid/${bidId}`,
    enabled,
  });
};
