import styled from 'styled-components';

import { useTabs } from '@hedgehog/data-access/hooks';
import { ContentTabsBlock } from '@hedgehog/shared/types';
import { VSpace } from '@hedgehog/ui/layouts';
import { List, ListItem } from '@hedgehog/ui/lists';
import { Tabs, Tab } from '@hedgehog/ui/tabs';
import { Title, MarkdownText } from '@hedgehog/ui/typography';

import { CMSTitleBodyBlock } from '../cms-title-body-block/cms-title-body-block.component';

const MetricRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

type CMSTabsBlockProps = {
  block: ContentTabsBlock;
};

export const CMSTabsBlock = ({ block }: CMSTabsBlockProps): JSX.Element => {
  const { activeTab, setActiveTab } = useTabs<string>(
    block.tabs.length ? block.tabs[0].name : '',
  );
  const { title, description, tabs } = block;

  const renderTab = (): JSX.Element | null => {
    const tab = block.tabs.find(({ name }) => name === activeTab);
    if (!tab) {
      return null;
    }

    return (
      <List>
        {tab.items.map((item) => (
          <ListItem key={item.label}>
            <MetricRow>
              <MarkdownText>{item.label}</MarkdownText>
              <Title>{item.value || '--'}</Title>
            </MetricRow>
          </ListItem>
        ))}
      </List>
    );
  };

  return (
    <VSpace spacing="small">
      <CMSTitleBodyBlock
        block={{
          title,
          description,
        }}
      />
      <Tabs appearance="compact">
        {tabs.map(({ name }) => (
          <Tab
            key={name}
            active={activeTab === name}
            onClick={() => setActiveTab(name)}
          >
            <Title>{name}</Title>
          </Tab>
        ))}
      </Tabs>
      {renderTab()}
    </VSpace>
  );
};
