import { useEffect } from 'react';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useAppData,
  useAssetPagesQuery,
  useAssetQuery,
  useAssetRoundClass,
} from '@hedgehog/data-access/partners';
import { AssetRoundClassStatus } from '@hedgehog/shared/types';
import { Widget } from '@hedgehog/ui/widgets';

import { AssetViewer } from '../../containers';

const CustomWidget = styled(Widget)`
  height: 100%;
  position: relative;
`;

export const AssetPage = (): JSX.Element | null => {
  const isRoot = useMatch('/assets/:assetId');
  const navigate = useNavigate();
  const { assetId = '' } = useParams();
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';
  const { data: asset } = useAssetQuery(
    { assetId, partnerId },
    { skip: !assetId || !partnerId },
  );
  const {
    data: pages,
    isLoading,
    isError,
  } = useAssetPagesQuery(
    {
      assetId,
      partnerId,
    },
    { skip: !assetId || !partnerId },
  );
  const roundClass = useAssetRoundClass(asset?.id || '');

  useEffect(() => {
    if (!isRoot) return;
    if (!roundClass?.status) return;
    if (!pages?.length) return;

    const doesContainOverview = pages.some((page) => page.slug === 'overview');
    const doesContainComingSoon = pages.some(
      (page) => page.slug === 'coming-soon',
    );

    const isComingSoon =
      [
        AssetRoundClassStatus.UPCOMING,
        AssetRoundClassStatus.COMING_SOON,
      ].indexOf(roundClass.status) > -1;
    // Navigate the user to the correct tab based on the asset round class status
    navigate(
      isComingSoon && doesContainComingSoon
        ? `/assets/${asset?.id}/coming-soon`
        : doesContainOverview
        ? `/assets/${asset?.id}/overview`
        : `/assets/${asset?.id}/${pages[0].slug}`,
    );
  }, [isRoot, roundClass]);

  return (
    <CustomWidget>
      {asset && (
        <AssetViewer asset={asset} partnerId={activePartner?.id ?? ''} />
      )}
    </CustomWidget>
  );
};
