import { gql } from '@apollo/client';

export const GET_ASSET_THEMES = gql`
  query GetAssetThemes {
    allAssetThemes {
      id
      name
      category
      votes {
        totalVotes
        userVotes
      }
    }
  }
`;
