import styled from 'styled-components';

import { useAppData, useAssetsQuery } from '@hedgehog/data-access/partners';
import { Loader } from '@hedgehog/ui/loaders';

import { AssetListItem } from '../asset-list-item/asset-list-item.container';

const ListContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.625rem;
`;

export const AssetList = (): JSX.Element => {
  const { activePartner } = useAppData();

  const partnerId = activePartner ? activePartner.id : '';
  const { data: assets, isFetching } = useAssetsQuery(
    { partnerId },
    { skip: !partnerId },
  );

  if (isFetching) {
    return <Loader />;
  }

  return (
    <ListContainer>
      {assets?.map((asset) => (
        <AssetListItem key={asset.id} asset={asset} />
      ))}
    </ListContainer>
  );
};
