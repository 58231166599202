import { ClientAutocomplete } from '@hedgehog/browser/partners/clients/feature-autocomplete';
import { PartnerClient } from '@hedgehog/data-access/partners-types';

import { ModalForm } from '../../../../../components';
import {
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';

export const MarketBidCreateSelectClientPage = (): JSX.Element => {
  const { navigateTo, close } = useNavigateToBidPage();
  const [params, updateParams] = useMarketBidParams();

  const handleChange = async (client: PartnerClient): Promise<void> => {
    updateParams({ clientId: client.id });
  };

  return (
    <ModalForm
      heading="Choose client"
      submitModal={() => navigateTo(`create/set-price`)}
      closeModal={() => close()}
      submitButtonText="Continue"
      disabled={!params.clientWalletAddress}
    >
      <ClientAutocomplete
        label="Bid on behalf of"
        placeholder="Start typing"
        ariaLabel="client-selector"
        name="client"
        onChange={handleChange}
      />
    </ModalForm>
  );
};
