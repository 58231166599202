import {
  useAppData,
  useTeamMembersQuery,
} from '@hedgehog/data-access/partners';

import { FundTeamMember } from '../fund-team-member/fund-team-member.container';

export interface FundTeamMembersProps {
  fundHashedBlockchainRef: string;
}

export const FundTeamMembers = ({
  fundHashedBlockchainRef,
}: FundTeamMembersProps): JSX.Element => {
  const { activePartner } = useAppData();
  const partnerId = activePartner ? activePartner.id : '';

  // Load all of the team members for the current partner
  const { data: teamMembers } = useTeamMembersQuery({ partnerId });

  return (
    <>
      {teamMembers?.map((teamMember) => (
        <FundTeamMember
          key={teamMember.teamMemberId}
          fundHashedBlockchainRef={fundHashedBlockchainRef}
          firstName={teamMember.firstName}
          lastName={teamMember.lastName}
          address={teamMember.blockchainAddress || '0x'}
        />
      ))}
    </>
  );
};
