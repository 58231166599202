import { ContentTenantsBlock } from '@hedgehog/shared/types';

import { AssetTenantList } from '../AssetTenantList/AssetTenantList';

type CMSTenantsBlockProps = {
  block: ContentTenantsBlock;
};

export const CMSTenantsBlock = ({
  block,
}: CMSTenantsBlockProps): JSX.Element => {
  const { items } = block;
  return <AssetTenantList tenants={items} />;
};
