import { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { StandardProps } from '@hedgehog/ui/utils';

import { useColumnRef } from './column-layout.context';
import { ColumnSettings } from './column-layout.reducer';

const LayoutColumn = styled.div<{ flexible?: boolean; desktopOnly?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  flex-grow: ${({ flexible }): string => (flexible ? '0' : '1')};
  flex-shrink: 1;
  flex-basis: ${({ flexible }): string => (flexible ? 'auto' : '50%')};
`;

export type ColumnProps = StandardProps<{
  flexible?: boolean;
  desktopOnly?: boolean;
  size?: ColumnSettings['size'];
  onClick?: MouseEventHandler;
}>;

export const Column = styled(
  ({ size, className, children, ...props }: ColumnProps): JSX.Element => {
    const ref = useColumnRef({ size });
    return (
      <LayoutColumn ref={ref} className={className} {...props}>
        {children}
      </LayoutColumn>
    );
  },
)``;
