export const ChunkIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path d="M1 1H7V7H1V1Z" fill="currentColor" />
      <path d="M9 1H15V7H9V1Z" fill="currentColor" />
      <path d="M1 9H7V15H1V9Z" fill="currentColor" />
      <path d="M9 9H15V15H9V9Z" fill="currentColor" />
    </g>
  </svg>
);
