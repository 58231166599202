import { gql } from '@apollo/client';

export const COMPLETE_SIGNUP_USER = gql`
  mutation CompleteSignup(
    $signupToken: String!
    $country: String!
    $state: String!
    $referredBy: String
    $isSSO: Boolean
    $firstName: String
    $lastName: String
  ) {
    completeSignup(
      args: {
        signupToken: $signupToken
        country: $country
        state: $state
        referredBy: $referredBy
        isSSO: $isSSO
        firstName: $firstName
        lastName: $lastName
      }
    ) {
      __typename
      ... on CompleteSignupPayload {
        user {
          id
          email
          country
          firstName
          lastName
          state
          cognitoId
          createdAt
        }
      }
      ... on CompleteSignupError {
        message
        code
      }
      ... on BaseError {
        message
      }
    }
  }
`;
