import { Outlet, useMatch } from 'react-router-dom';
import styled, { CSSProp } from 'styled-components';

import { Asset } from '@hedgehog/data-access/asset-types';
import {
  useAssetPagesQuery,
  useAssetRoundClass,
} from '@hedgehog/data-access/partners';
import { AssetGallery, AssetPageHeader } from '@hedgehog/ui/assets';
import { AutoscrollProvider } from '@hedgehog/ui/autoscroll';
import { Tabs } from '@hedgehog/ui/tabs';

import { TabLink } from '../../components/tab-link/tab-link.component';
import { AssetFooter } from '../asset-footer/asset-footer.container';

const AssetInnerPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1.5rem 6rem;
`;

const PageHeader = styled.div`
  padding: 1.5rem 1.5rem 0;
  margin-bottom: 1rem;
`;

const AssetPanel = styled.section`
  padding: 0 1.5rem 1.5rem;
  border-bottom: 2px solid;
  ${({ theme }): CSSProp => ({
    color: theme.colors.black,
    borderBottomColor: theme.colors.grey100,
  })}
`;

const GalleryContainer = styled.div`
  margin-bottom: 1rem;

  & ${AssetGallery} {
    margin-right: -1.5rem;
    margin-left: -1.5rem;

    .flicking-camera {
      margin-left: 1.5rem;
    }
  }
`;

const AssetNavigation = styled.div`
  flex-grow: 1;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

type AssetViewerProps = {
  asset: Asset;
  partnerId: string;
};

export const AssetViewer = ({
  asset,
  partnerId,
}: AssetViewerProps): JSX.Element | null => {
  const { data: pages } = useAssetPagesQuery({
    assetId: asset.id,
    partnerId,
  });
  const roundClass = useAssetRoundClass(asset.id);
  const isComingSoon = useMatch('/assets/:assetId/coming-soon');
  const isReportingIncome = useMatch('/assets/:assetId/income');

  if (!roundClass || !pages) {
    return null;
  }

  return (
    <AutoscrollProvider>
      <PageHeader>
        <AssetPageHeader
          title={asset.details.assetSummary.title}
          themes={asset.themes}
          status={roundClass.status}
        />
      </PageHeader>
      <AssetPanel>
        <GalleryContainer>
          <AssetGallery thumbnails={asset.thumbnails} />
        </GalleryContainer>
        {!isComingSoon && (
          <AssetNavigation>
            <Tabs>
              {[...pages]
                .sort((a, b) => a.order - b.order)
                .map((page) => (
                  <TabLink
                    key={page.id}
                    to={`/assets/${asset.id}/${page.slug}`}
                  >
                    {page.title}
                  </TabLink>
                ))}
            </Tabs>
          </AssetNavigation>
        )}
      </AssetPanel>
      {!isReportingIncome && <AssetFooter assetId={asset.id} />}
      <AssetInnerPage>
        <Outlet />
      </AssetInnerPage>
      <div id="layout-footer" />
    </AutoscrollProvider>
  );
};
