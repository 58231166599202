import { RoleCapability } from '@hedgehog/shared/types';

import { useWhoamiQuery } from '../store/apis';

export const useUserHasCapabilities = (
  ...capabilities: RoleCapability[]
): boolean | undefined => {
  const { data } = useWhoamiQuery();

  // Data still loading
  if (!data?.role) {
    return undefined;
  }

  // When the user has no capabilities, the hook will always return false.
  const roleCapabilities = data.role.capabilities || [];
  if (roleCapabilities.length === 0) {
    return false;
  }

  // If the user has the admin capability, they can do everything.
  const isAdmin = roleCapabilities.find(
    (capability) => capability.capability === RoleCapability.ADMIN,
  );
  if (isAdmin) {
    return true;
  }

  // Ensure that the user has all the capabilities required.
  return capabilities.every((capability) =>
    roleCapabilities.some(
      (roleCapability) => roleCapability.capability === capability,
    ),
  );
};
