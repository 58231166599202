export const AngleUp = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.3549 9.52217L12.3549 9.52218L12.3585 9.52569L16.6409 13.7582C16.6837 13.8051 16.7172 13.8598 16.7396 13.9192C16.7629 13.9811 16.7736 14.0469 16.7713 14.1129C16.769 14.1789 16.7536 14.2438 16.7261 14.3039C16.6985 14.3639 16.6593 14.4179 16.6108 14.4627L16.6042 14.4688L16.5978 14.4751C16.5511 14.5214 16.4957 14.5581 16.4348 14.583C16.3739 14.6078 16.3086 14.6205 16.2428 14.6201L16.2421 14.6201C16.1112 14.6195 15.9857 14.5677 15.8927 14.4757C15.8925 14.4755 15.8923 14.4753 15.8921 14.4751L12.3535 10.9365L11.9999 10.583L11.6464 10.9365L8.10745 14.4755C8.10731 14.4756 8.10717 14.4757 8.10703 14.4759C8.01339 14.5687 7.88684 14.6209 7.75495 14.6209C7.62286 14.6209 7.49613 14.5686 7.40245 14.4755L7.40205 14.4751C7.35518 14.4286 7.31799 14.3733 7.2926 14.3124C7.26722 14.2514 7.25415 14.1861 7.25415 14.1201C7.25415 14.0541 7.26722 13.9887 7.2926 13.9278C7.31799 13.8668 7.35518 13.8115 7.40205 13.7651L7.40205 13.7651L7.4035 13.7636L11.6435 9.52362L11.6435 9.52362L11.6449 9.52217C11.6914 9.4753 11.7467 9.43811 11.8077 9.41272C11.8686 9.38734 11.9339 9.37427 11.9999 9.37427C12.066 9.37427 12.1313 9.38734 12.1922 9.41272C12.2532 9.43811 12.3085 9.4753 12.3549 9.52217Z"
      stroke="currentColor"
    />
  </svg>
);

export default AngleUp;
