import { GraphQLError } from 'graphql';

import {
  DeclarationType,
  KycCheckStatus,
  SourceOfFunds,
  GetIdentityCheckQuery as GetIdentityCheck,
  StartIdentityChecksMutation as StartIdentityChecks,
} from '@hedgehog/data-access/graphql';
import {
  GET_USER,
  GET_IDENTITY_CHECK,
  START_IDENTITY_CHECKS,
  CREATE_DECLARATION,
  UPDATE_USER_DETAILS,
} from '@hedgehog/data-access/graphql';

export const getUserMock = {
  request: {
    query: GET_USER,
  },
  result: {
    data: {
      user: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        country: 'GBR',
      },
    },
  },
};

export const makeDeclarationMock = {
  request: {
    query: CREATE_DECLARATION,
    variables: { type: DeclarationType.FATCA },
  },
  result: {
    data: {
      makeDeclaration: {
        id: 'test',
        type: DeclarationType.FATCA,
      },
    },
  },
};

export const updateUserMockForTaxOnly = {
  request: {
    query: UPDATE_USER_DETAILS,
    variables: {
      sourceOfFunds: undefined,
      dob: '',
      postcode: '',
      addressLine1: '',
      addressLine2: '',
      town: '',
      taxResidency: 'CHE',
      taxIdentifier: '1234010170',
    },
  },
  result: {
    data: { updateUser: { id: '123' } },
  },
};

export const updateUserMock = {
  request: {
    query: UPDATE_USER_DETAILS,
    variables: {
      sourceOfFunds: SourceOfFunds.BANK_LOAN,
      dob: '1993-03-29',
      postcode: 'Postcode',
      addressLine1: '11 Street',
      addressLine2: '',
      town: 'City',
      taxResidency: 'test',
      taxIdentifier: 'test',
    },
  },
  result: {
    data: { updateUser: { id: '123' } },
  },
};

export const errorMocks = [
  {
    request: {
      query: GET_IDENTITY_CHECK,
    },
    result: {
      errors: [new GraphQLError('Fetching data failed')],
    },
  },
  updateUserMock,
];

export const mocks = [
  {
    request: {
      query: GET_IDENTITY_CHECK,
    },
    result: {
      data: {
        identityCheckProgress: {
          __typename: 'IdentityCheckProgressPayload',
          status: KycCheckStatus.approved,
          reasons: [],
        },
      } as GetIdentityCheck,
    },
  },
  updateUserMock,
];

export const inProgressMocks = [
  {
    request: {
      query: GET_IDENTITY_CHECK,
    },
    result: {
      data: {
        identityCheckProgress: {
          __typename: 'IdentityCheckProgressPayload',
          status: KycCheckStatus.processing,
          reasons: [],
        },
      } as GetIdentityCheck,
    },
  },
  updateUserMock,
];

export const noIdentityCheckMock = {
  request: {
    query: GET_IDENTITY_CHECK,
  },
  result: {
    data: {
      identityCheckProgress: {
        __typename: 'IdentityCheckProgressPayload',
        status: KycCheckStatus.not_started,
        reasons: [],
      },
    } as GetIdentityCheck,
  },
};
export const startIdentityCheckMock = {
  request: {
    query: START_IDENTITY_CHECKS,
  },
  result: {
    data: {
      startIdentityCheck: null,
    } as StartIdentityChecks,
  },
};
