import { ClientAutocomplete } from '@hedgehog/browser/partners/clients/feature-autocomplete';
import { PartnerClient } from '@hedgehog/data-access/partners-types';

import { ModalForm } from '../../../../components';
import {
  useMarketListingParams,
  useNavigateToListingPage,
} from '../../../../containers';

export const MarketListingSelectClientPage = (): JSX.Element => {
  const { close, navigateTo } = useNavigateToListingPage();
  const [params, updateParams] = useMarketListingParams();

  const handleChange = (client: PartnerClient): void => {
    updateParams({ clientId: client.id });
  };

  return (
    <ModalForm
      heading="Choose client"
      submitModal={() => navigateTo(`create/position`)}
      closeModal={() => close()}
      submitButtonText="Continue"
      disabled={!params.clientWalletAddress}
    >
      <ClientAutocomplete
        label="List on behalf of"
        placeholder="Start typing"
        ariaLabel="client-selector"
        name="client"
        onChange={handleChange}
      />
    </ModalForm>
  );
};
