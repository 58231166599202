import * as Sentry from '@sentry/react';
import { ethers } from 'ethers';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { useCacheValidate } from '@hedgehog/browser/shared/utils';
import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { useCreateFundMutation, useDeleteFundMutation } from '../store/apis';

import { useFundManagerUserOperation } from './use-fund-manager-contract.hook';

type CallbackParams = {
  name: string;
  description: string;
  country: string;
  currency: string;
  size: number;
  thumbnail: string;
  partnerId: string;
};

type Callback = (params: CallbackParams) => Promise<void>;

type Result = {
  loading: boolean;
  error: string | undefined;
  success: boolean;
};

export const useAddFundUserOperation = (): [Callback, Result] => {
  const userOperation = useFundManagerUserOperation({
    functionName: 'addFund',
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<boolean>(false);

  const [createFund] = useCreateFundMutation();
  const [deleteFund] = useDeleteFundMutation();
  const clear = useCacheValidate();

  const callback = async ({
    name,
    description,
    country,
    currency,
    size,
    thumbnail,
    partnerId,
  }: CallbackParams): Promise<void> => {
    try {
      setLoading(true);

      // Create the fund in Hedgehog database first
      const fund = await createFund({
        fund: {
          name,
          description,
          country,
          currency,
          size,
          thumbnail,
        },
        partnerId,
      }).unwrap();

      // Create partnerRef and fundRef
      const partnerRef = ethers.utils.keccak256(
        ethers.utils.toUtf8Bytes(partnerId),
      );

      // Then create the fund on the blockchain
      try {
        const pendingOperation = userOperation([
          partnerRef,
          fund.hashedBlockchainReference,
          ethers.utils.toUtf8Bytes(name),
          ethers.utils.formatBytes32String(country),
          ethers.utils.formatBytes32String(currency),
        ]).then((userOp) => {
          if (userOp.args.success === false) {
            console.log('create fund userOp failed', userOp);
            throw new Error('Something went wrong, please try again later.');
          }
          return userOp;
        });

        toast.promise(
          pendingOperation,
          {
            pending: {
              render: () => (
                <Paragraph small color="grey500">
                  Talking to the blockchain, please stand by.
                </Paragraph>
              ),
            },
            success: {
              render: () => {
                clear('fundsBlockchainQuery');
                return (
                  <Paragraph small color="grey500">
                    Your fund was successfully created.
                  </Paragraph>
                );
              },
            },
            error: {
              render: () => (
                <>
                  <Heading level="h7">Sorry, something went wrong.</Heading>
                  <Paragraph small color="grey500">
                    Your fund was not created. Please try again later.
                  </Paragraph>
                </>
              ),
            },
          },
          {
            pauseOnFocusLoss: false,
            pauseOnHover: false,
          },
        );

        setSuccess(true);
      } catch (e) {
        console.log('Error creating fund on blockchain', e);
        // delete fund from database to avoid inconsistencies
        await deleteFund({ fundId: fund.id, partnerId });
        // This is unexpected, so we should report it to Sentry
        Sentry.captureException(e);
        // Set new error so that the UI can display it
        setError('Create UserOperation failed');
      }
    } catch (e: any) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  return [callback, { loading, error, success }];
};
