import styled, { CSSObject } from 'styled-components';

import { Icon } from '@hedgehog/ui/icons';
import { Loader } from '@hedgehog/ui/loaders';
import { Heading6, Paragraph } from '@hedgehog/ui/typography';

import { MarketTransferStatus } from '../../types';

export const ActionBody = styled.div`
  flex-grow: 1;
`;

export const ActionSuccess = () => (
  <Icon
    icon="check-circle"
    color="success"
    size="l"
    backgroundColor="transparent"
  />
);

export const ActionPending = styled.div`
  ${({ theme }): CSSObject => ({
    height: '1.5rem',
    width: '1.5rem',
    margin: '1rem',
    borderRadius: '50%',
    backgroundColor: theme.colors.grey300,
  })}
`;

export const ActionLoading = styled(Loader)`
  height: 1.5rem;
  width: 1.5rem;
  margin: -1rem 1rem 0;
  color: ${({ theme }): string => theme.colors.success};
`;

export const ActionItem = styled(
  ({
    icon,
    status,
    className,
    title,
    description,
    inlineButton,
  }: {
    icon: string;
    title: string;
    description?: string;
    status: MarketTransferStatus;
    className?: string;
    inlineButton?: JSX.Element;
  }) => {
    return (
      <div className={className}>
        <Icon icon={icon} size="l" backgroundColor="transparent" />
        <ActionBody>
          <Heading6>{title}</Heading6>
          {description && (
            <Paragraph small color="grey400">
              {description}
            </Paragraph>
          )}
        </ActionBody>
        {inlineButton}
        {status === MarketTransferStatus.Success && <ActionSuccess />}
        {status === MarketTransferStatus.InProgress && (
          <ActionLoading color="success" />
        )}
        {status === MarketTransferStatus.Pending && <ActionPending />}
      </div>
    );
  },
)`
  ${({ theme }): CSSObject => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing.small,
  })};
`;
