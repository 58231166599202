import { ReactNode } from 'react';
import styled from 'styled-components';

import { IconType } from '@hedgehog/ui/icons';
import { BottomSheet } from '@hedgehog/ui/modals';
import { useModal } from '@hedgehog/ui/modals';

import { TextInput } from '../TextInput/TextInput';

type MenuButtonInputProps = {
  name: string;
  label?: string;
  value?: string;
  placeholder?: string;
  trailingIcon?: IconType | null;
  trailing?: ReactNode | null;
  children: (closeFn: () => void) => ReactNode;
  checked?: boolean;
};

const InputWrapper = styled.div`
  input {
    cursor: pointer;
    caret-color: transparent;
  }
`;

export const MenuButtonInput = ({
  name,
  label,
  value,
  placeholder,
  trailingIcon = 'chevron-down',
  trailing,
  children,
  checked,
}: MenuButtonInputProps): JSX.Element => {
  const { openAndResolveWithSubmitValue } = useModal(({ submitModal }) => (
    <BottomSheet>{children(submitModal)}</BottomSheet>
  ));
  const handleClick = async (): Promise<void> => {
    try {
      await openAndResolveWithSubmitValue();
    } catch (err) {
      // Catch modal abort
    }
  };
  return (
    <InputWrapper>
      <TextInput
        autoCompleteOff
        readOnly
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onClick={handleClick}
        trailingIcon={trailingIcon}
        trailing={trailing}
        checked={checked}
      />
    </InputWrapper>
  );
};

export default MenuButtonInput;
