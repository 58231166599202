import { ReactNode } from 'react';
import styled, { CSSObject, CSSProp } from 'styled-components';

import { fromBreakpoint } from '@hedgehog/ui/themes';
import { Heading } from '@hedgehog/ui/typography';

const PageHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  justify-content: space-between;

  ${({ theme }): CSSObject => ({
    minHeight: theme.spacing.large,
    marginTop: 0,
    marginBottom: theme.spacing.xlarge,

    paddingLeft: theme.spacing.large,
    paddingRight: theme.spacing.large,
    ...fromBreakpoint(theme.breakpoints.tablet, {
      paddingLeft: 0,
      paddingRight: 0,
    }),
  })}
`;

const PageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1;
  max-height: 100%;
  overflow: hidden;

  ${({ theme }): CSSProp => ({
    gap: theme.spacing.large,
    ...fromBreakpoint(theme.breakpoints.tablet, {
      flexFlow: 'row nowrap',
    }),
  })}
`;

const PageActions = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const PageWrapper = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
`;

export interface PageColumnLayoutProps {
  title?: string;
  actions?: ReactNode | ReactNode[];
  children?: ReactNode | ReactNode[];
}

export const PageColumnLayout = ({
  title,
  actions,
  children,
}: PageColumnLayoutProps): JSX.Element => {
  return (
    <PageWrapper>
      <PageHeader>
        <Heading level="h5">{title}</Heading>
        {actions && <PageActions>{actions}</PageActions>}
      </PageHeader>
      <PageContent>{children}</PageContent>
    </PageWrapper>
  );
};
