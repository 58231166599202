import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useClientsQuery } from '@hedgehog/data-access/partners';
import { useAppData } from '@hedgehog/data-access/partners';
import { PartnerClient } from '@hedgehog/data-access/partners-types';
import {
  BreadcrumbsLayout,
  BreadcrumbsLayoutContent,
} from '@hedgehog/ui/layouts';
import { parsePadding, shimmerGradient } from '@hedgehog/ui/themes';
import { Paragraph } from '@hedgehog/ui/typography';

import { ClientListItem } from '../../components';

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Header = styled.div`
  ${({ theme }) => ({
    padding: parsePadding(theme.actions.padding),
    paddingTop: theme.spacing.large,
    paddingBottom: theme.spacing.large,
  })}
`;

const ListContainer = styled(BreadcrumbsLayout)`
  ${({ theme }) => ({
    [`& > ${BreadcrumbsLayoutContent}`]: {
      padding: `0 ${theme.spacing.xxxsmall}`,
    },
  })}
`;

const Span = styled.span<{ loading?: boolean }>`
  ${({ loading }) => (loading ? shimmerGradient : '')}
`;

export interface FundClientsListProps {
  limit?: number;
}

export const FundClientsList = ({ limit }: FundClientsListProps) => {
  const { clientId } = useParams();
  const { activePartnerId } = useAppData();
  const { data, isFetching } = useClientsQuery(
    {
      partnerId: activePartnerId || '',
      limit,
      offset: 0,
    },
    {
      skip: !activePartnerId,
    },
  );

  const orderedClients = data?.data
    ?.slice()
    .sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    .slice(0, limit);

  const isLoading = !activePartnerId || !data || isFetching;
  return (
    <ListContainer>
      <Header>
        <Paragraph color="grey400">
          <Span loading={isLoading}>{data?.paging.total || 0}</Span> Client(s)
        </Paragraph>
      </Header>
      <Content>
        {orderedClients ? (
          orderedClients.map((client: PartnerClient) => (
            <ClientListItem
              key={client.id}
              to={`/funds/clients/${client.id}`}
              active={client.id === clientId}
              firstName={client.user?.firstName ?? ''}
              lastName={client.user?.lastName ?? ''}
              createdAt={client.createdAt}
            />
          ))
        ) : (
          <ClientListItem
            loading
            key="placeholder"
            firstName="placeholder"
            lastName="placeholder"
            canInvest={false}
            createdAt={new Date().toISOString()}
          />
        )}
      </Content>
    </ListContainer>
  );
};
