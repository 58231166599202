import styled from 'styled-components';

import { Icon, IconProps } from '@hedgehog/ui/icons';
import { Heading, Paragraph } from '@hedgehog/ui/typography';
import { spacing } from '@hedgehog/utils/sizes';

const CardContainerStyle = styled.div<{
  clickable: boolean;
  outlined: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${spacing.md};
  border-radius: ${spacing.xs};

  ${({ theme, outlined }): string =>
    outlined
      ? `border: 2px solid ${theme.colors.black100};`
      : `background-color: ${theme.colors.grey100};`}

  ${({ clickable }): string => (clickable ? 'cursor: pointer;' : '')}
`;

const CardContainerInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

interface AuthFormCardContainerProps {
  outlined?: boolean;
  title: string;
  description: string;
  icon: IconProps['icon'];
  iconColour?: IconProps['color'];
  className?: string;
  onClick?: () => void;
}

export const AuthFormCardContainer = ({
  outlined = false,
  title,
  description,
  icon,
  iconColour = 'black',
  className,
  onClick,
}: AuthFormCardContainerProps): JSX.Element => {
  const handleClick = (): void => {
    if (onClick) onClick();
  };
  return (
    <CardContainerStyle
      className={className}
      outlined={outlined}
      clickable={!!onClick}
      onClick={handleClick}
    >
      <CardContainerInfo>
        <Heading level="h6" color="black">
          {title}
        </Heading>
        <Paragraph color="grey400">{description}</Paragraph>
      </CardContainerInfo>
      <Icon icon={icon} size="m" backgroundColor="white" color={iconColour} />
    </CardContainerStyle>
  );
};
