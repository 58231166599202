export const GroupIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 0H0V2H2V0Z" fill="currentColor" />
    <path d="M2 12H0V14H2V12Z" fill="currentColor" />
    <path d="M0 8H2V10H0V8Z" fill="currentColor" />
    <path d="M2 4H0V6H2V4Z" fill="currentColor" />
    <path d="M4 0H6V2H4V0Z" fill="currentColor" />
    <path d="M6 12H4V14H6V12Z" fill="currentColor" />
    <path d="M8 0H10V2H8V0Z" fill="currentColor" />
    <path d="M10 12H8V14H10V12Z" fill="currentColor" />
    <path d="M12 0H14V2H12V0Z" fill="currentColor" />
    <path d="M14 12H12V14H14V12Z" fill="currentColor" />
    <path d="M12 8H14V10H12V8Z" fill="currentColor" />
    <path d="M14 4H12V6H14V4Z" fill="currentColor" />
  </svg>
);

export default GroupIcon;
