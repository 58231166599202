import { useState } from 'react';
import { toast } from 'react-toastify';

import { Heading, Paragraph } from '@hedgehog/ui/typography';

import { useFundManagerUserOperation } from './use-fund-manager-contract.hook';

export type LimitedPartnerCommitment = {
  limitedPartnerHash: string;
  commitment: number;
  limitedPartnerType: number;
  limitedPartnerFundId: number;
};

type CallbackParams = {
  fundRef: string;
  fundId: number;
  commitments: LimitedPartnerCommitment[];
};

type Callback = (params: CallbackParams) => Promise<void>;

type Result = {
  loading: boolean;
};

export const useAddLimitedPartnersUserOperation = (): [Callback, Result] => {
  const userOperation = useFundManagerUserOperation({
    functionName: 'addLimitedPartners',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const callback = async ({
    fundRef,
    fundId,
    commitments,
  }: CallbackParams): Promise<void> => {
    try {
      setLoading(true);

      const pendingOperation = userOperation([
        fundRef,
        fundId,
        commitments.map(({ limitedPartnerHash }) => limitedPartnerHash),
        commitments.map(({ commitment }) => commitment),
        commitments.map(({ limitedPartnerType }) => limitedPartnerType), // default to individual
        commitments.map(({ limitedPartnerFundId }) => limitedPartnerFundId), // default to 0 (not a fund)
      ]).then((userOp) => {
        if (userOp.args.success === false) {
          console.log(userOp);
          throw new Error('Something went wrong, please try again later.');
        }
        return userOp;
      });

      toast.promise(
        pendingOperation,
        {
          pending: {
            render: () => (
              <Paragraph small color="grey500">
                Talking to the blockchain, please stand by.
              </Paragraph>
            ),
          },
          success: {
            render: () => (
              <Paragraph small color="grey500">
                The clients were successfully added.
              </Paragraph>
            ),
          },
          error: {
            render: () => (
              <>
                <Heading level="h7">Sorry, something went wrong.</Heading>
                <Paragraph small color="grey500">
                  The clients were not added. Please try again later.
                </Paragraph>
              </>
            ),
          },
        },
        {
          pauseOnFocusLoss: false,
          pauseOnHover: false,
        },
      );
    } finally {
      setLoading(false);
    }
  };

  return [callback, { loading }];
};
