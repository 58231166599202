import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query GetArticles($tags: [String!]) {
    articles(args: { tags: $tags }) {
      id
      title
      author
      imageUrl
      slug
      category
      tags {
        tag
      }
    }
  }
`;
