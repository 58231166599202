export const HelpIconV2 = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM16 10C14.3431 10 13 11.3431 13 13V14H9V13C9 9.13401 12.134 6 16 6C19.866 6 23 9.13401 23 13C23 16.866 19.866 20 16 20H14V16H16C17.6569 16 19 14.6569 19 13C19 11.3431 17.6569 10 16 10ZM18 22V26H14V22H18Z"
      fill="currentColor"
    />
  </svg>
);

export default HelpIconV2;
