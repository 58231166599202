import { useEffect } from 'react';
import styled from 'styled-components';
import { getAddress, parseEther } from 'viem';
import { usePublicClient } from 'wagmi';

import { useZeroDevWallet } from '@hedgehog/browser/shared/account-abstraction';
import {
  useAppData,
  useCreateBid,
  useFundsV2Query,
} from '@hedgehog/data-access/partners';
import { FundV2 } from '@hedgehog/data-access/partners-types';
import {
  ButtonBase,
  SecondaryButton,
  SecondaryLightButton,
} from '@hedgehog/ui/buttons';
import { Card, ErrorCard } from '@hedgehog/ui/cards';
import { Paragraph, MarkdownText, Note } from '@hedgehog/ui/typography';

import { FootbarActions, ModalForm } from '../../../../../components';
import {
  useMarketBidParams,
  useNavigateToBidPage,
} from '../../../../../containers';

const Strong = styled.strong`
  ${({ theme }) => ({ color: theme.colors.black })}
`;

const CustomModalForm = styled(ModalForm)`
  & ${FootbarActions} {
    width: 100%;
    flex-flow: row wrap;
  }

  & ${FootbarActions} > ${ButtonBase} {
    flex: 1 0;
  }
`;

export const MarketBidCreateTermsConditionsPage = (): JSX.Element => {
  const [params] = useMarketBidParams();
  const { navigateTo, close } = useNavigateToBidPage();

  // Get the fund contract address
  const { activePartnerId } = useAppData();
  const { data: funds, isLoading: areFundsFetching } = useFundsV2Query({
    partnerId: activePartnerId || '',
  });
  const address =
    funds?.find((fund: FundV2) => fund.fundId === params.fundId)?.address || '';

  const [
    createBid,
    {
      prepareContractWrite: {
        isLoading: prepareCreateBidLoading,
        error: prepareCreateBidError,
      },
      contractWrite: { isLoading: createBidLoading, error: createBidError },
    },
  ] = useCreateBid({
    fundAddress: getAddress(address),
    listingId: BigInt(params.listingId ?? -1),
    price: parseEther(`${params.bidPrice ?? 0}`),
    enabled: !!address && !!params.listingId,
  });

  // We use the public client to watch for transaction receipts which will tell us
  // when the tx has been included in a block.
  const publicClient = usePublicClient();

  const { autoloadWallet, address: unlockedWalletAddress } = useZeroDevWallet();

  useEffect(() => {
    autoloadWallet({ index: params.clientWalletIndex });
  }, []);

  const onSubmit = async () => {
    if (!createBid) return;
    // send userop
    const { hash } = await createBid();
    if (!createBidError) {
      // wait for receipt
      await publicClient.waitForTransactionReceipt({
        hash,
      });
      // navigate to created page
      navigateTo('created');
    } else {
      console.log('Error creating bid', createBidError, {
        ...createBidError,
      });
    }
  };

  const isLoading =
    areFundsFetching || prepareCreateBidLoading || createBidLoading;
  const shortMessage =
    (prepareCreateBidError as { shortMessage?: string })?.shortMessage ||
    (createBidError as { shortMessage?: string })?.shortMessage;

  return (
    <CustomModalForm
      heading="Marketplace terms and conditions"
      notes={
        shortMessage && (
          <ErrorCard>
            <Note>{shortMessage}</Note>
          </ErrorCard>
        )
      }
      actions={
        <>
          <SecondaryLightButton
            onClick={() => navigateTo('../../..')}
            disabled={isLoading}
          >
            Cancel a sale
          </SecondaryLightButton>
          <SecondaryButton
            onClick={onSubmit}
            loading={isLoading}
            disabled={!!shortMessage || !createBid}
          >
            Accept and Continue
          </SecondaryButton>
        </>
      }
      backModal={() => navigateTo('create/set-price')}
      closeModal={() => close()}
      disabled={!unlockedWalletAddress}
    >
      <Card>
        <Paragraph color="grey500">
          I declare that I am a self-certified sophisticated investor for the
          purposes of the restriction on promotion of non-mainstream pooled
          investments.
        </Paragraph>
        <Paragraph color="grey500">
          <Strong>
            I accept that the investments to which the promotions will relate
            may expose me to a significant risk of losing all of the money or
            other property invested.
          </Strong>{' '}
          I am aware that it is open to me to seek advice from someone who
          specialises in advising on non mainstream pooled investments.
        </Paragraph>
      </Card>

      <MarkdownText>
        {`**I understand that this means:**

1. I can receive promotional communications made by a person who is authorised by the Financial Conduct Authority which relate to investment activity in non mainstream pooled investments;
2. The investments to which the promotions will relate may expose me to a significant risk of losing all of the property invested.

**I am a self-certified sophisticated investor because at least one of the following applies:**

1. I am a member of a network or syndicate of business angels and have been so for at least the last six months prior to today;
2. I have made more than one investment in an unlisted company in the two years prior to the date below;
3. I am  working, or have worked in the two years prior to the date below, in a professional capacity in the private equity sector, or in the provision of finance for small and medium enterprises;
4. I am currently, or have been in the two years prior to the date below, a director of a company with an annual turnover of at least £1m.`}
      </MarkdownText>
    </CustomModalForm>
  );
};
