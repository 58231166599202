import { FormEvent, useState } from 'react';
import styled from 'styled-components';

import { SecondaryButton } from '@hedgehog/ui/buttons';
import { PasswordInput } from '@hedgehog/ui/inputs';
import { Paragraph } from '@hedgehog/ui/typography';
import { PasswordValidationOptions } from '@hedgehog/utils/validation';

import { usePassword } from '../hooks';

export interface ConfirmPasswordFormData {
  password?: string;
  confirmPassword?: string;
}

export interface ConfirmPasswordProps {
  loading?: boolean;
  error?: Error | string | undefined;
  validation?: PasswordValidationOptions;
  onSubmit?: (formData: Required<ConfirmPasswordFormData>) => void;
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const ErrorPrompt = styled(Paragraph)``;

export const ConfirmPasswordForm = (
  props: ConfirmPasswordProps,
): JSX.Element => {
  const [formData, updateFormData] = useState<ConfirmPasswordFormData>({
    password: '',
    confirmPassword: '',
  });
  const {
    valid: isPasswordValid,
    dirty: isPasswordDirty,
    errors: passwordErrors,
    enableValidation,
  } = usePassword({
    password: formData.password || '',
  });
  const isFormFilled = formData.password && formData.confirmPassword;
  const isPasswordConfirmed = formData.password === formData.confirmPassword;

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault();
    if (isPasswordConfirmed && isPasswordValid) {
      if (props.onSubmit) {
        props.onSubmit(formData as Required<ConfirmPasswordFormData>);
      }
    }
  };
  const handlePasswordChange = (value: string): void =>
    updateFormData((state) => ({ ...state, password: value }));
  const handleConfirmPasswordChange = (value: string): void =>
    updateFormData((state) => ({ ...state, confirmPassword: value }));

  return (
    <Form onSubmit={handleSubmit}>
      <PasswordInput
        label="Password"
        name="Password"
        value={formData.password}
        onChange={handlePasswordChange}
        placeholder="Create a new password"
        hint="At least 8 characters, one capital, one number"
        autoCompleteOff
        disabled={props.loading}
        onBlur={enableValidation}
        checked={isPasswordValid && isPasswordDirty}
        errors={passwordErrors}
      />
      <PasswordInput
        label="Repeat Password"
        name="Repeat Password"
        value={formData.confirmPassword}
        onChange={handleConfirmPasswordChange}
        placeholder="Create a new password"
        autoCompleteOff
        disabled={props.loading}
      />

      {props.error && (
        <ErrorPrompt weight="600">{`${props.error}`}</ErrorPrompt>
      )}

      <SecondaryButton
        type="submit"
        disabled={!isFormFilled || !isPasswordValid || !isPasswordConfirmed}
        loading={props.loading}
      >
        Reset Password
      </SecondaryButton>
    </Form>
  );
};
