import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from '@hedgehog/ui/loaders';

export const FundsLayout = (): JSX.Element => {
  return (
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  );
};
