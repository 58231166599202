export const LogoutIcon = (): JSX.Element => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 30 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0H16V8H6L6 20H16V28H0V0Z" fill="currentColor" />
    <path d="M20 12V6H22L30 14L22 22H20V16H10V12L20 12Z" fill="currentColor" />
  </svg>
);

export default LogoutIcon;