import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    user {
      id
      email
      country
      firstName
      lastName
      dob
      postcode
      addressLine1
      addressLine2
      town
      sourceOfFunds
      taxResidency
      taxIdentifier
      referralCode
      createdAt
      partner {
        name
        slug
        theme {
          colors {
            primary
            secondary
          }
        }
        resources {
          faviconUrl
          termsAgreementsUrl
          privacyPolicyUrl
          logotypeLightUrl
          logotypeDarkUrl
          supportUrl
          websiteUrl
          landing {
            imageUrl
            title
            caption
          }
          pages {
            portfolio {
              placeholderUrl
            }
            notifications {
              placeholderUrl
            }
            sidebar {
              backgroundUrl
              backgroundPosition
              backgroundSize
            }
          }
        }
      }
    }
    partnerSettings {
      name
      enabled
      value
    }
  }
`;
