import styled, { css, CSSObject } from 'styled-components';

import { ratioToHex } from '@hedgehog/ui/themes';
import { StandardProps } from '@hedgehog/ui/utils';

import { hoverAppearance } from './styles';

export const LoaderInnerWrapper = styled.div`
  position: relative;
  flex: 1 1 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

export const LoaderContainer = styled(
  ({ className, children }: StandardProps): JSX.Element => (
    <div className={className}>
      <LoaderInnerWrapper>{children}</LoaderInnerWrapper>
    </div>
  ),
)`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
`;

export const ButtonTypographyBase = css<{ small?: boolean }>`
  ${({ small, theme }): CSSObject => {
    const { fontSize, fontWeight, lineHeight, letterSpacing, fontFamily } =
      theme.typography.button_small;

    return {
      fontSize,
      fontWeight,
      lineHeight,
      minHeight: lineHeight,
      letterSpacing,
      fontFamily,
    };
  }};
`;

export const ButtonDefaultBase = css<{ small?: boolean }>`
  ${({ small, theme }): string => {
    const { colors, radius, opacity, paddings } = theme;
    const paddingX = small ? paddings.button_small.x : paddings.button.x;
    const paddingY = small ? paddings.button_small.y : paddings.button.y;
    return `
      color: ${colors.black};
      background-color: ${colors.grey200};

      padding: ${paddingY} ${paddingX};
      border: 0 solid transparent;
      border-radius: ${radius.normal};

      &:disabled,
      &[data-disabled]:not([data-disabled='false']) {
        background-color: ${colors.grey200}${ratioToHex(opacity[60])};
        cursor: not-allowed;
      }
    `;
  }}
`;

export const ButtonBase = styled.button<{
  fluid?: boolean;
  small?: boolean;
  $hoverColor?: string;
  $isLoading?: boolean;
}>`
  position: relative;
  display: inline-flex;
  width: ${({ fluid }): string => (fluid ? '100%' : 'auto')};
  gap: ${({ theme }): string => theme.spacing.xsmall};
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: auto;
  cursor: pointer;
  ${ButtonDefaultBase};
  ${ButtonTypographyBase};

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    ${hoverAppearance}
  }

  ${LoaderContainer} svg {
    margin: 0.25rem 0;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 0.5rem);
    max-width: calc(100% - 0.5rem);
  }

  ${LoaderContainer} > * > div {
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  ${({ $isLoading }): CSSObject => ({
    [`& > *:not(${LoaderContainer})`]: {
      opacity: $isLoading ? 0 : 1,
    },
  })}
`;
